import axios from 'axios';

export const uploadImageToCloudinary = async ({image,directory}) => {

    const data = new FormData();
    data.append('file', image);
    data.append('upload_preset', 'hgxk9yff');
    data.append('folder', directory);

    try{
        const response = await axios.post("https://api.cloudinary.com/v1_1/dsvz8cktr/image/upload", data)
        return response?.data.secure_url;
    } catch (err) {
        console.log(err)
    }
}