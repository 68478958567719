import React from 'react'
// Chakra imports
import {
    Avatar,
    Button,
    Flex,
    Text,
    useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import { useNavigate } from 'react-router-dom';


export const LittleList = ({title,arrayOfAthletes,arrayOfDescs}) => {

    // Chakra color mode
    const textColor = useColorModeValue("gray.700", "white");

    let navigate = useNavigate()

    return (
        <Card p='16px' boxShadow="rgba(99, 99, 99, 0.2) 0px 2px 4px 0px">
            <CardHeader p='12px 5px' mb='12px'>
                <Text fontSize='lg' color={textColor} fontWeight='bold'>
                    {title}
                </Text>
            </CardHeader>
            <CardBody px='5px' overflow="scroll" height="275px">
                <Flex direction='column' w='100%'>
                    <Flex direction='column' justifyContent='space-between' >
                            {
                                arrayOfAthletes.map((it,index) => {
                                    return (
                                        <>
                                            <Flex direction="row" align='center' mb='20px' key={index}>
                                                <Avatar
                                                    src={it.profile_pic}
                                                    w='50px'
                                                    h='50px'
                                                    borderRadius='15px'
                                                    me='10px'
                                                    size="cover"
                                                    onClick={()=>navigate(`/admin/athletes/show/${it.id}`)}
                                                    cursor="pointer"
                                                />
                                                <Flex direction='column'>
                                                    <Text fontSize='sm' color={textColor} fontWeight='bold' onClick={()=>navigate(`/admin/athletes/show/${it.id}`)} cursor="pointer">
                                                    {it.name}{" "}
                                                    </Text>
                                                    {
                                                        arrayOfDescs?.at(index) && (
                                                            <Text fontSize='xs' color='gray.500' fontWeight='400'>
                                                                {arrayOfDescs.at(index)}
                                                            </Text>
                                                        )
                                                    }
                                                        
                                                </Flex>
                                            </Flex>
                                        </>
                                    )
                                })
                            }
                    </Flex>
                </Flex>
            </CardBody>
        </Card>
    );
}
