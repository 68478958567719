// React imports
import React, { useContext, useEffect } from 'react'
import { useParams, Navigate } from 'react-router-dom';
// Context imports
import { DataContext } from "../../../../contexts/dataContext";
// Chackra imports
import { Flex, Text, useColorModeValue } from "@chakra-ui/react";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
// Component and assets
import ProfileBgImage from "assets/img/ProfileBackground.png";
import Card from "components/Card/Card.js";
import Header from './Header'
// Content imports
import { AthleticInfo } from '../AthleteInfoContent/AthleticInfo';
import { ShowCaseInfo } from '../AthleteInfoContent/ShowCaseInfo';
import { Education } from '../AthleteInfoContent/Education';
import { SocialNetworks } from '../AthleteInfoContent/SocialNetworks';
import { ActionsReceivedByCoaches } from '../AthleteInfoContent/ActionsReceivedByCoaches';
import { Categorization } from '../AthleteInfoContent/Categorization';
import { getNumberOfDaysBetween } from 'helpers';


export const ShowAthlete = () => {
	// Chackra color mode
	const bgProfile = useColorModeValue(
		"hsla(0,0%,100%,.8)",
		"linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
	);
	
	// Get the id of the athlete from the url 
	const { athleteId } = useParams();
  	// Take the context data needed in this component
    const dataContext = useContext(DataContext)
    const { athletes, agencies, athletesEnquirys, coachTagRelations, teams } = dataContext
    const athlete = athletes?.find(athlete => athlete.id === athleteId)
	const agencyOfAthlete = agencies?.find(agency => agency.id === athlete?.agencyID)

	const athletesWithSameCategory = athletes.filter( it => it.id !== athlete.id && it.category === athlete.category && it.current_status === 'COMMITTED' && it.destination_college).slice(0,5)

	useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


	return (
		<Flex direction='column' pt={{ base: "120px", md: "75px" }}>
			{/* Check if the context is loaded */}
			{(athletes.length + agencies.length + athletesEnquirys.length ) > 0 && (
					// Check if the athlete is found
					athlete ? (
						<>
							<Header
								id={athlete?.id}
								backgroundHeader={ProfileBgImage}
								backgroundProfile={bgProfile}
								profile_pic={athlete?.profile_pic}
								name={athlete?.name}
								gender={athlete?.gender}
								contact_email={athlete?.contact_email}
								contact_phone={athlete?.contact_phone}
								birthdate={athlete?.birthdate}
								agency={agencyOfAthlete && agencyOfAthlete}
								agent={athlete?.agent}
								isVisible={athlete?.isVisible}
								agentID={athlete?.agentID}
							/>
							{
								athlete.current_status === 'AVAILABLE' ? (
									<Text fontSize="sm" color={"gray.500"} fontWeight='bold' marginTop='10px'>
										AVAILABLE SINCE {athlete.start_availability_date ? athlete.start_availability_date : <i>Not tracked</i>}
									</Text>
								) : (
									athlete.current_status === 'COMMITTED' && (
										<Flex direction={"row"} marginTop="10px" justifyContent={"space-around"}>
											<Text fontSize="sm" color={"gray.500"} fontWeight='bold'>
												AVAILABLE SINCE: {athlete.start_availability_date ? athlete.start_availability_date : <i>Not tracked</i>}
											</Text>
											<Text fontSize="sm" color={"gray.500"} fontWeight='bold'>
												COMMITMENT DATE: {athlete.end_availability_date ? athlete.end_availability_date : <i>Not saved</i>}
											</Text>
											<Text fontSize="sm" color={"gray.500"} fontWeight='bold'>
												AVAILABLE DURING {(athlete.start_availability_date && athlete.end_availability_date) ? getNumberOfDaysBetween(athlete.start_availability_date,athlete.end_availability_date) : <i>N/A</i>} DAYS
											</Text>
										</Flex>
									)
								)
							}
							<Card overflowX={{ sm: "scroll", xl: "hidden" }}  mt={{ sm: "40%", md: "32%",lg:"10px" }}>
								<Tabs variant='soft-rounded' colorScheme={athlete?.sport === "Tennis" ? "green" : "blue"}>
									<TabList>
										<Tab>Athletic information</Tab>
										<Tab>Showcase participation</Tab>
										<Tab>Education</Tab>
										<Tab>Categorization</Tab>
										<Tab>Exposure</Tab>
										{/* <Tab>Enquiries</Tab> */}
										{/* <Tab>Favorited by</Tab> */}
									</TabList>
	
									<TabPanels>
										<TabPanel>
											<AthleticInfo athlete={athlete && athlete}/>
										</TabPanel>
	
										<TabPanel>
											<ShowCaseInfo athlete={athlete && athlete}/>
										</TabPanel>
	
										<TabPanel>
											<Education athlete={athlete && athlete}/>
										</TabPanel>
	
										<TabPanel>
											{/* <SocialNetworks athlete={athlete && athlete}/> */}
											<Categorization 
												athlete={athlete && athlete} 
												categoryExampleTeams={teams.filter(it=>((it.college_category === athlete.category) && (it.genre === athlete.gender === 'male' ? 'Men':'Women'))).slice(0,5)} 
												athletesWithSameCategory={athletesWithSameCategory}
											/>
										</TabPanel>
	
										<TabPanel>
											<ActionsReceivedByCoaches favorites={athlete && athlete.favorited?.items} enquiries={athlete && athlete.enquiries?.items} tags={athlete && athlete.tags?.items} coachTagsRel={coachTagRelations}/>
										</TabPanel>

										{/* <TabPanel>
											<AthleteEnquiries enquiries={enquiriesOfAthlete?.sort((item1,item2)=>new Date(item2.createdAt) - new Date(item1.createdAt))}/>
										</TabPanel>

										<TabPanel>
											<AthleteFavoritesBy favorites={athlete.favorited?.items?.sort((item1,item2)=>new Date(item2.createdAt) - new Date(item1.createdAt))} />
										</TabPanel> */}
									</TabPanels>
								</Tabs>
							</Card>
	
						</>
					):(
						<Navigate to={'/admin/athletes'} replace />
					)
				)
			}

		</Flex>
	);
}