export const getShowcase = /* GraphQL */ `
  query GetShowcase($id: ID!) {
    getShowcase(id: $id) {
      id
      name
      description
      contry
      address
      date
      status
      coaches_interested {
        items {
          id
          showcaseID
          coachID
          createdAt
          updatedAt
        }
        nextToken
      }
      featured_athletes {
        items {
          id
          showcaseID
          athleteID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listShowcases = /* GraphQL */ `
  query ListShowcases(
    $filter: ModelShowcaseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listShowcases(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        contry
        address
        date
        status
        coaches_interested {
          nextToken
        }
        featured_athletes {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const createShowcase = /* GraphQL */ `
  mutation CreateShowcase(
    $input: CreateShowcaseInput!
    $condition: ModelShowcaseConditionInput
  ) {
    createShowcase(input: $input, condition: $condition) {
      id
      name
      description
      contry
      address
      date
      status
      coaches_interested {
        items {
          id
          showcaseID
          coachID
          createdAt
          updatedAt
        }
        nextToken
      }
      featured_athletes {
        items {
          id
          showcaseID
          athleteID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateShowcase = /* GraphQL */ `
  mutation UpdateShowcase(
    $input: UpdateShowcaseInput!
    $condition: ModelShowcaseConditionInput
  ) {
    updateShowcase(input: $input, condition: $condition) {
      id
      name
      description
      contry
      address
      date
      status
      coaches_interested {
        items {
          id
          showcaseID
          coachID
          createdAt
          updatedAt
        }
        nextToken
      }
      featured_athletes {
        items {
          id
          showcaseID
          athleteID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteShowcase = /* GraphQL */ `
  mutation DeleteShowcase(
    $input: DeleteShowcaseInput!
    $condition: ModelShowcaseConditionInput
  ) {
    deleteShowcase(input: $input, condition: $condition) {
      id
      name
      description
      contry
      address
      date
      status
      coaches_interested {
        items {
          id
          showcaseID
          coachID
          createdAt
          updatedAt
        }
        nextToken
      }
      featured_athletes {
        items {
          id
          showcaseID
          athleteID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;

export const getShowcaseCoachRelation = /* GraphQL */ `
  query GetShowcaseCoachRelation($id: ID!) {
    getShowcaseCoachRelation(id: $id) {
      id
      showcaseID
      coachID
      showcase {
        id
        name
        description
        contry
        address
        date
        status
        coaches_interested {
          nextToken
        }
        featured_athletes {
          nextToken
        }
        createdAt
        updatedAt
      }
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          had_trial
          team_logo
          conference
          division
          collegeID
          createdAt
          updatedAt
        }
        favorites {
          nextToken
        }
        my_filters {
          nextToken
        }
        my_favorite_folders {
          nextToken
        }
        my_notifications {
          nextToken
        }
        showcases_interested_in {
          nextToken
        }
        my_athlete_tags {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listShowcaseCoachRelations = /* GraphQL */ `
  query ListShowcaseCoachRelations(
    $filter: ModelShowcaseCoachRelationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listShowcaseCoachRelations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        showcaseID
        coachID
        showcase {
          id
          name
          description
          contry
          address
          date
          status
          createdAt
          updatedAt
        }
        coach {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          disabledByUser
          is_active
          allow_whatsapp_contact
          deleteAppReason
          division
          role
          sport
          teamID
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const createShowcaseCoachRelation = /* GraphQL */ `
  mutation CreateShowcaseCoachRelation(
    $input: CreateShowcaseCoachRelationInput!
    $condition: ModelShowcaseCoachRelationConditionInput
  ) {
    createShowcaseCoachRelation(input: $input, condition: $condition) {
      id
      showcaseID
      coachID
      showcase {
        id
        name
        description
        contry
        address
        date
        status
        coaches_interested {
          nextToken
        }
        featured_athletes {
          nextToken
        }
        createdAt
        updatedAt
      }
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          had_trial
          team_logo
          conference
          division
          collegeID
          createdAt
          updatedAt
        }
        favorites {
          nextToken
        }
        my_filters {
          nextToken
        }
        my_favorite_folders {
          nextToken
        }
        my_notifications {
          nextToken
        }
        showcases_interested_in {
          nextToken
        }
        my_athlete_tags {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateShowcaseCoachRelation = /* GraphQL */ `
  mutation UpdateShowcaseCoachRelation(
    $input: UpdateShowcaseCoachRelationInput!
    $condition: ModelShowcaseCoachRelationConditionInput
  ) {
    updateShowcaseCoachRelation(input: $input, condition: $condition) {
      id
      showcaseID
      coachID
      showcase {
        id
        name
        description
        contry
        address
        date
        status
        coaches_interested {
          nextToken
        }
        featured_athletes {
          nextToken
        }
        createdAt
        updatedAt
      }
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          had_trial
          team_logo
          conference
          division
          collegeID
          createdAt
          updatedAt
        }
        favorites {
          nextToken
        }
        my_filters {
          nextToken
        }
        my_favorite_folders {
          nextToken
        }
        my_notifications {
          nextToken
        }
        showcases_interested_in {
          nextToken
        }
        my_athlete_tags {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteShowcaseCoachRelation = /* GraphQL */ `
  mutation DeleteShowcaseCoachRelation(
    $input: DeleteShowcaseCoachRelationInput!
    $condition: ModelShowcaseCoachRelationConditionInput
  ) {
    deleteShowcaseCoachRelation(input: $input, condition: $condition) {
      id
      showcaseID
      coachID
      showcase {
        id
        name
        description
        contry
        address
        date
        status
        coaches_interested {
          nextToken
        }
        featured_athletes {
          nextToken
        }
        createdAt
        updatedAt
      }
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          had_trial
          team_logo
          conference
          division
          collegeID
          createdAt
          updatedAt
        }
        favorites {
          nextToken
        }
        my_filters {
          nextToken
        }
        my_favorite_folders {
          nextToken
        }
        my_notifications {
          nextToken
        }
        showcases_interested_in {
          nextToken
        }
        my_athlete_tags {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;

export const getShowcaseAthleteRelation = /* GraphQL */ `
  query GetShowcaseAthleteRelation($id: ID!) {
    getShowcaseAthleteRelation(id: $id) {
      id
      showcaseID
      athleteID
      color
      number
      showcase {
        id
        name
        description
        contry
        address
        date
        status
        coaches_interested {
          nextToken
        }
        featured_athletes {
          nextToken
        }
        createdAt
        updatedAt
      }
      athlete {
        id
        agencyID
        agentID
        name
        isVisible
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        nationality
        country_of_competition
        target_division
        compliance
        card_pic
        other_nice_pics
        agency_assesment
        elegibility_notes
        current_club
        current_status
        start_availability_date
        end_availability_date
        destination_college
        ncaa_id
        type_of_admission
        weight
        height
        sport
        tennis_profile {
          utr
          national_ranking
          international_ranking
          DominantHand
        }
        position
        second_position
        dominant_foot
        featured_video {
          url
          metadata
        }
        other_videos {
          url
          metadata
        }
        level_of_competition
        sports_background
        showcase_participation {
          showcase_name
          city
          country
          date
        }
        start_date
        high_school_graduation_date
        sat_score
        sat_date
        act_score
        act_date
        gemat_gre_score
        gemat_gre_date
        toefl_score
        toefl_date
        duolingo
        duolingo_date
        ielts
        ielts_date
        estimated_gpa
        intended_field_of_studies
        intended_degree
        budget
        facebook_url
        twitter_user
        linkedin_url
        agency {
          id
          name
          logo
          country
          contact_email
          contact_phone
          website
          createdAt
          updatedAt
        }
        agent {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          agent_role
          agencyID
          createdAt
          updatedAt
        }
        favorited {
          nextToken
        }
        enquiries {
          nextToken
        }
        tags {
          nextToken
        }
        showcases_participation {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listShowcaseAthleteRelations = /* GraphQL */ `
  query ListShowcaseAthleteRelations(
    $filter: ModelShowcaseAthleteRelationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listShowcaseAthleteRelations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        showcaseID
        athleteID
        color
        number
        showcase {
          id
          name
          description
          contry
          address
          date
          status
          createdAt
          updatedAt
        }
        athlete {
          id
          agencyID
          agentID
          name
          isVisible
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          nationality
          country_of_competition
          target_division
          compliance
          card_pic
          other_nice_pics
          agency_assesment
          elegibility_notes
          current_club
          current_status
          start_availability_date
          end_availability_date
          destination_college
          ncaa_id
          type_of_admission
          weight
          height
          sport
          position
          second_position
          dominant_foot
          level_of_competition
          sports_background
          start_date
          high_school_graduation_date
          sat_score
          sat_date
          act_score
          act_date
          gemat_gre_score
          gemat_gre_date
          toefl_score
          toefl_date
          duolingo
          duolingo_date
          ielts
          ielts_date
          estimated_gpa
          intended_field_of_studies
          intended_degree
          budget
          facebook_url
          twitter_user
          linkedin_url
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const createShowcaseAthleteRelation = /* GraphQL */ `
  mutation CreateShowcaseAthleteRelation(
    $input: CreateShowcaseAthleteRelationInput!
    $condition: ModelShowcaseAthleteRelationConditionInput
  ) {
    createShowcaseAthleteRelation(input: $input, condition: $condition) {
      id
      showcaseID
      athleteID
      color
      number
      showcase {
        id
        name
        description
        contry
        address
        date
        status
        coaches_interested {
          nextToken
        }
        featured_athletes {
          nextToken
        }
        createdAt
        updatedAt
      }
      athlete {
        id
        agencyID
        agentID
        name
        isVisible
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        nationality
        country_of_competition
        target_division
        compliance
        card_pic
        other_nice_pics
        agency_assesment
        elegibility_notes
        current_club
        current_status
        start_availability_date
        end_availability_date
        destination_college
        ncaa_id
        type_of_admission
        weight
        height
        sport
        tennis_profile {
          utr
          national_ranking
          international_ranking
          DominantHand
        }
        position
        second_position
        dominant_foot
        featured_video {
          url
          metadata
        }
        other_videos {
          url
          metadata
        }
        level_of_competition
        sports_background
        showcase_participation {
          showcase_name
          city
          country
          date
        }
        start_date
        high_school_graduation_date
        sat_score
        sat_date
        act_score
        act_date
        gemat_gre_score
        gemat_gre_date
        toefl_score
        toefl_date
        duolingo
        duolingo_date
        ielts
        ielts_date
        estimated_gpa
        intended_field_of_studies
        intended_degree
        budget
        facebook_url
        twitter_user
        linkedin_url
        agency {
          id
          name
          logo
          country
          contact_email
          contact_phone
          website
          createdAt
          updatedAt
        }
        agent {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          agent_role
          agencyID
          createdAt
          updatedAt
        }
        favorited {
          nextToken
        }
        enquiries {
          nextToken
        }
        tags {
          nextToken
        }
        showcases_participation {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateShowcaseAthleteRelation = /* GraphQL */ `
  mutation UpdateShowcaseAthleteRelation(
    $input: UpdateShowcaseAthleteRelationInput!
    $condition: ModelShowcaseAthleteRelationConditionInput
  ) {
    updateShowcaseAthleteRelation(input: $input, condition: $condition) {
      id
      showcaseID
      athleteID
      color
      number
      showcase {
        id
        name
        description
        contry
        address
        date
        status
        coaches_interested {
          nextToken
        }
        featured_athletes {
          nextToken
        }
        createdAt
        updatedAt
      }
      athlete {
        id
        agencyID
        agentID
        name
        isVisible
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        nationality
        country_of_competition
        target_division
        compliance
        card_pic
        other_nice_pics
        agency_assesment
        elegibility_notes
        current_club
        current_status
        start_availability_date
        end_availability_date
        destination_college
        ncaa_id
        type_of_admission
        weight
        height
        sport
        tennis_profile {
          utr
          national_ranking
          international_ranking
          DominantHand
        }
        position
        second_position
        dominant_foot
        featured_video {
          url
          metadata
        }
        other_videos {
          url
          metadata
        }
        level_of_competition
        sports_background
        showcase_participation {
          showcase_name
          city
          country
          date
        }
        start_date
        high_school_graduation_date
        sat_score
        sat_date
        act_score
        act_date
        gemat_gre_score
        gemat_gre_date
        toefl_score
        toefl_date
        duolingo
        duolingo_date
        ielts
        ielts_date
        estimated_gpa
        intended_field_of_studies
        intended_degree
        budget
        facebook_url
        twitter_user
        linkedin_url
        agency {
          id
          name
          logo
          country
          contact_email
          contact_phone
          website
          createdAt
          updatedAt
        }
        agent {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          agent_role
          agencyID
          createdAt
          updatedAt
        }
        favorited {
          nextToken
        }
        enquiries {
          nextToken
        }
        tags {
          nextToken
        }
        showcases_participation {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteShowcaseAthleteRelation = /* GraphQL */ `
  mutation DeleteShowcaseAthleteRelation(
    $input: DeleteShowcaseAthleteRelationInput!
    $condition: ModelShowcaseAthleteRelationConditionInput
  ) {
    deleteShowcaseAthleteRelation(input: $input, condition: $condition) {
      id
      showcaseID
      athleteID
      color
      number
      showcase {
        id
        name
        description
        contry
        address
        date
        status
        coaches_interested {
          nextToken
        }
        featured_athletes {
          nextToken
        }
        createdAt
        updatedAt
      }
      athlete {
        id
        agencyID
        agentID
        name
        isVisible
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        nationality
        country_of_competition
        target_division
        compliance
        card_pic
        other_nice_pics
        agency_assesment
        elegibility_notes
        current_club
        current_status
        start_availability_date
        end_availability_date
        destination_college
        ncaa_id
        type_of_admission
        weight
        height
        sport
        tennis_profile {
          utr
          national_ranking
          international_ranking
          DominantHand
        }
        position
        second_position
        dominant_foot
        featured_video {
          url
          metadata
        }
        other_videos {
          url
          metadata
        }
        level_of_competition
        sports_background
        showcase_participation {
          showcase_name
          city
          country
          date
        }
        start_date
        high_school_graduation_date
        sat_score
        sat_date
        act_score
        act_date
        gemat_gre_score
        gemat_gre_date
        toefl_score
        toefl_date
        duolingo
        duolingo_date
        ielts
        ielts_date
        estimated_gpa
        intended_field_of_studies
        intended_degree
        budget
        facebook_url
        twitter_user
        linkedin_url
        agency {
          id
          name
          logo
          country
          contact_email
          contact_phone
          website
          createdAt
          updatedAt
        }
        agent {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          agent_role
          agencyID
          createdAt
          updatedAt
        }
        favorited {
          nextToken
        }
        enquiries {
          nextToken
        }
        tags {
          nextToken
        }
        showcases_participation {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;