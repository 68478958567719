// React imports
import React, { useContext, useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
// Context imports
import { DataContext } from "../../../../contexts/dataContext";
// React-select imports
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
// Chackra Components
import { Flex, useColorModeValue, Text, FormControl,FormErrorMessage, Input,Spacer, Box, Button, ButtonGroup, Textarea } from "@chakra-ui/react";
// Auxiliary imports
// Components 
import { countries } from 'helpers';
import { updateShowcaseService } from 'services/showcaseServices';



const EditShowcase = () => {

    // Chakra color mode
    const textColor = useColorModeValue("gray.700", "white");
	const animatedComponents = makeAnimated();

    // Navigate hook
    let navigate = useNavigate()

    // Get the id of the showcase from the url
	const { showcaseID } = useParams();

    // Take the context data needed in this component
    const dataContext = useContext(DataContext)
    const { showcases,setShowcases } = dataContext
    // Get the info of the showcase
    const showcase = showcases.find(show => show.id === showcaseID)


	// Initial state with mandatory fields
	const initialState = {
		id: showcase.id,
		name: showcase.name,
        description: showcase.description,
        contry: showcase.contry,
        date: showcase.date,
        address: showcase.address,
	}
    
    // State that controls the form values
	const [formValues, setFormValues] = useState(initialState)
	// State to check if form has changed and enable submit button
	const [formHasChanged, setFormHasChanged] = useState(false)
	// State that controls if the submit is loading
	const [isLoading, setIsLoading] = useState(false)
    
    // When the form values change, check that the mandatory fields are filled and enable the submit button
	useEffect(() => {
		JSON.stringify(formValues) !== JSON.stringify(initialState) && formValues.name !== '' && formValues.description !== '' && formValues.country !== '' && formValues.address !== '' ? setFormHasChanged(true) : setFormHasChanged(false)
	}, [formValues])


	const onChange = (e) => {
        e.persist()
        setFormValues(() => ({ ...formValues, [e.target.name]: e.target.value }))
    }

	const handleEdit = () => {
        setIsLoading(true)
        
        updateShowcaseService({ ...formValues }).then(() => {
            setIsLoading(false)
            setShowcases(
                showcases.map(show=>{
                    if(show.id === formValues.id){ return {...formValues} }
                    else{ return show }
                })
            )
            navigate('/admin/showcases')
        })

    }

    return (
        <>
            <Box mt={{ base: "120px", md: "90px" }} borderRadius="md" boxShadow="md" bg="white" >
                {/* Header */}
                <Flex justifyContent='left' p='15px 0px 10px 20px' >
                    <Text fontSize='xl' color={textColor} fontWeight='bold' mr="200px">
                        Edit Showcase 
                    </Text>
                </Flex>

                {/* Form of their attributes */}
                <Flex direction='column' w="100%" alignContent="center" px="20px"  > 
                    
                    {/* First row */}
                    <Flex direction={{ sm: "column", lg: "row" }} w="100%">
                        <Flex w={{ sm: "100%", lg: "47%" }} mt="20px">
                            <FormControl isInvalid={formValues.name === ""}>
                                <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mr="15px" fontWeight='semibold' textAlign="justify">
                                    Name
                                </Text>
                                <Input isRequired={true} onChange={onChange} variant='flushed' placeholder='Name' size='sm' value={formValues.name} name="name"/>
                                {formValues.name === "" ? <FormErrorMessage>This field is required</FormErrorMessage> : null}
                            </FormControl>
                        </Flex>
                        <Spacer />
                        <Flex alignItems='left' direction={'column'} w={{ sm: "100%", lg: "47%" }} mt="20px">
                            <FormControl isInvalid={formValues.contry === ""}>
                                <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mb="5px" fontWeight='semibold' textAlign="justify" mr="10px">
                                    Country
                                </Text>
                                <Select
                                    closeMenuOnSelect={true}
                                    components={animatedComponents}
                                    placeholder="Select country"
                                    isSearchable={true}
                                    defaultValue={formValues.contry && {value:formValues.contry,label:formValues.contry}}
                                    options={countries}
                                    onChange={(selectedOptions) =>  setFormValues(()=>({...formValues, contry: selectedOptions.value}))}
                                />
                                {formValues.contry === "" ? <FormErrorMessage>This field is required</FormErrorMessage> : null}
                            </FormControl>
                        </Flex>
                    </Flex>
                    
                    {/* Second row */}
                    <Flex direction={{ sm: "column", lg: "row" }} w="100%" >
                        <Flex alignItems='left' direction={'column'} w={{ sm: "100%", lg: "47%" }} mt="20px">
                            <FormControl isInvalid={formValues.address === ""}>
                                <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mr="15px" fontWeight='semibold' textAlign="justify">
                                    Address
                                </Text>
                                <Input isRequired={true}onChange={onChange} variant='flushed' placeholder='Address' size='sm' value={formValues.address} name="address"/>
                                {formValues.address === "" ? <FormErrorMessage>This field is required</FormErrorMessage> : null}
                            </FormControl>
                        </Flex>           
                        <Spacer />             
                        <Flex alignItems='left' direction={'column'} w={{ sm: "100%", lg: "47%" }} mt="20px">
                            <FormControl isInvalid={formValues.date === ""}>
                                <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mr="15px" fontWeight='semibold' textAlign="justify">
                                    Date
                                </Text>
                                <Input isRequired={true}onChange={onChange} variant='flushed' placeholder='Date' size='sm' type="date" value={formValues.date} name="date"/>
                                {formValues.date === "" ? <FormErrorMessage>This field is required</FormErrorMessage> : null}
                            </FormControl>
                        </Flex>
                    </Flex>
                    
                    {/* Third row */}
                    <Flex direction={{ sm: "column", lg: "row" }} w="100%" >
                        <Flex alignItems='left' direction={'column'} w={{ sm: "100%", lg: "100%" }} mt="20px">
                            <FormControl isInvalid={formValues.description === ""}>
                                <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mr="15px" fontWeight='semibold' textAlign="justify">
                                    Description
                                </Text>
                                <Textarea placeholder='Write some description for the showcase' w="100%" h="150px" resize="none" isRequired={true} onChange={onChange} size='sm' value={formValues.description} name="description"/>
                                {/* <Input isRequired={true} onChange={onChange} variant='flushed' placeholder='Description' size='sm' value={formValues.description} name="description"/> */}
                                {formValues.description === "" ? <FormErrorMessage>This field is required</FormErrorMessage> : null}
                            </FormControl>
                        </Flex>
                    </Flex>
                </Flex> 



                {/* Confirm and cancel buttons */}
                <Flex justifyContent='right' p='10px 40px 10px 0px' >
                    <Flex direction="column" my={5}>
                        <ButtonGroup spacing="6" justifyContent='right' mb="10px">

                            <Button
                                isLoading={isLoading}
                                loadingText='Submitting'
                                colorScheme='green'
                                variant='outline'
                                onClick={handleEdit}
                                isDisabled={!formHasChanged}
                            >
                                Submit
                            </Button>
                            <Button
                                colorScheme='red'
                                variant='outline'
                                onClick={()=>(navigate(`/admin/showcases/`))}
                                >
                                Cancel
                            </Button>

                        </ButtonGroup>
                        <Text fontSize={{ sm: "xs", lg: "sm" }} color={"gray.400"}>
                            <i>You must modify some fields to enable submit button*</i>
                        </Text>
                    </Flex>
                </Flex>
            </Box>
        </>
    )
};

export default EditShowcase;