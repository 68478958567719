import { API } from 'aws-amplify';
import { createCoachNotificationRelation } from 'custom_graphql_queries/notifications';
import { createNotification, deleteNotification, updateNotification } from '../graphql/mutations'


const createNotificationService = async (notification) => {
    try {
        const response = await API.graphql({query: createNotification, variables: { input: notification }})
        return response
    } catch (error) {
        throw new Error(error.message);
    }
}

const updateNotificationService = async (notification) => {
    try {
        const response = await API.graphql({query: updateNotification, variables: { input: notification }})
        return response
    } catch (error) {
        throw new Error(error.message);
    }
}

const deleteNotificationService = async (notificationID) => {
    try {
        const response = await API.graphql({query: deleteNotification, variables: { input: {id: notificationID }}})
        return response
    } catch (error) {
        throw new Error(error.message);
    }
}

const createCoachNotificationService = async (notification) => {
    try { 
        console.log("servicio:",notification)
        const response = await API.graphql({query:createCoachNotificationRelation,variables: { input: notification}})
        return response
    } catch (error) {
        console.log("error:",error)
        throw new Error(error.message);
    }
}

export { createNotificationService, updateNotificationService, deleteNotificationService,createCoachNotificationService };