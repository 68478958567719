import { Box, Flex, Heading, Image, Text } from '@chakra-ui/react'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import CardHeader from 'components/Card/CardHeader'
import React from 'react'
import backImage from 'assets/img/pitchLogo.png'

const ErrorPage = () => {

    return (
        <Box
            mx="auto"
            h="100vh"
            display="flex"
            justifyContent="center"
            alignItems="center"
            backgroundImage={`url(${backImage})`}
            backgroundSize="cover"
            backgroundPosition="center"
        >
            <Card w="90%">
                <CardHeader>
                    <Flex 
                        direction="column" 
                        display="flex"
                        mx="auto"  
                        alignItems="center"
                        mb="20vh"
                    >
                        <Heading
                            fontSize={"100px"}
                            letterSpacing="10px"
                            fontWeight="700"
                        >
                            404
                        </Heading>
                        <Text fontSize={"40px"}><i>Page not found</i></Text>
                        <Text>Sorry, the page you are looking for does not exist.</Text>
                    </Flex>
                </CardHeader>
                <CardBody>
                    <Flex 
                        direction="column" 
                        display="flex"
                        mx="auto"
                        alignItems="center"
                    >
                        <Image w="40%" src={"https://res.cloudinary.com/dsvz8cktr/image/upload/v1624448821/LOGOFINAL_bl0pua.png"} />
                    </Flex>
                </CardBody>

            </Card>
        </Box>
        
    )
}

export default ErrorPage