// React imports
import React, { useContext, useState, useEffect, useRef} from 'react'
import { useNavigate } from 'react-router-dom';
// Context imports
import { DataContext } from "../../../../contexts/dataContext";
// React-select imports
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
// Chackra Components
import { Flex, useColorModeValue, Text, FormControl,FormErrorMessage, Input,Spacer, Box, Button, ButtonGroup, useDisclosure, Tooltip, Icon} from "@chakra-ui/react";
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    Alert,
	AlertIcon,
    AlertTitle,
    AlertDescription,
	CloseButton,
} from "@chakra-ui/react";
import { BsQuestionCircleFill } from "react-icons/bs";

// Auxiliary imports
import { uploadImageToCloudinary } from "../../../../helpers/uploadImageToCloudinary";
import { v4 as uuidv4 } from 'uuid';
import validator from "validator";
import { createCoachService } from "../../../../services/coachServices";
import { roles, division } from 'helpers';
// Components 
import { ImageDropzone } from "../../../../components/Dropzone/ImageDropzone";


export const CreateCoach = () => {

    // Chakra color mode
    const textColor = useColorModeValue("gray.700", "white");
	const animatedComponents = makeAnimated();
    // Navigate hook
    let navigate = useNavigate()

    // Take the context data needed in this component
    const dataContext = useContext(DataContext)
    const { coaches, setCoaches, teams } = dataContext

	// Initial state with mandatory fields
	const initialState = {
		id: uuidv4(),
		name: '',
        contact_email: '',
        teamID: '',
        role: '',
        division: '',
        sport:'',
	}

    // State that controls the form values
	const [formValues, setFormValues] = useState(initialState)
	// State to check if form has changed and enable submit button
	const [formHasChanged, setFormHasChanged] = useState(false)
	// State that controls if the submit is loading
	const [isLoading, setIsLoading] = useState(false)
    // State that controls if the contact email already exists
    const [contactEmailExists, setContactEmailExists] = useState(false)
    // Hook for the confirm modal
	const { isOpen, onOpen, onClose } = useDisclosure()
	const cancelRef = useRef()
	// State with form errors 
	const [errors, setErrors] = useState({
		email: '',
		phone: '',
		birthdate: '',
	})

        // When the form values change, check that the mandatory fields are filled and enable the submit button
	useEffect(() => {
		JSON.stringify(formValues) !== JSON.stringify(initialState) && !contactEmailExists && formValues.id !== '' && formValues.name !== '' && formValues.teamID !== '' && formValues.role !== '' && formValues.division !== '' && formValues.sport !== '' ? setFormHasChanged(true) : setFormHasChanged(false)
	}, [formValues,contactEmailExists])

    
	const onChange = (e) => {
        e.persist()
        setFormValues(() => ({ ...formValues, [e.target.name]: e.target.value }))
    }

    const canCreate = () => {
        let canCreate = true
        coaches.map(coach => {
                if (coach.contact_email === formValues.contact_email) {
                    canCreate = false
                }
            }
        )

        canCreate ? onOpen() : setContactEmailExists(true)
    }

    // Handle submit, validate data, upload image to cloudinary and create agency
	const handleCreate = () => {

        onClose()

		setIsLoading(true)
		let thereAreErrors = {
            email: '',
            phone: '',
            birthdate: '',
        }

		// Validate data (contact email, contact phone, website)
		if('contact_email' in formValues && formValues.contact_email !== "" && !validator.isEmail(formValues.contact_email)){
			setIsLoading(false)
			thereAreErrors = {...thereAreErrors, email: 'Please enter a valid email'}
		}
		
		if('contact_phone' in formValues && formValues.contact_phone !== "" && !validator.isMobilePhone(formValues.contact_phone, 'any',{ strictMode: true })){
			setIsLoading(false)
			thereAreErrors = {...thereAreErrors, phone: 'Please enter a valid phone number'}
		}
		
		if('birthdate'in formValues && formValues.birthdate !== "" && !validator.isDate(formValues.birthdate, {format: 'YYYY-MM-DD',delimiters: ['-']})){
			setIsLoading(false)
			thereAreErrors = {...thereAreErrors, birthdate: 'Please enter a valid birthdate'}
		}

        setErrors(thereAreErrors)
		// If there aren't errors, check if there is an image to upload, if so, upload it to cloudinary and create agency
		if(thereAreErrors.email === "" && thereAreErrors.phone === "" && thereAreErrors.birthdate === ""){
            if(formValues.profile_pic !== initialState.profile_pic){
                uploadImageToCloudinary({image:formValues.profile_pic, directory: 'devtests'})
				.then((url) => {
					createCoachService({ ...formValues, profile_pic: url, is_active: true })
                    .then(() => {
                        setIsLoading(false)
					    setCoaches([...coaches, { ...formValues, profile_pic: url, is_active: true, team: teams.find(i=>i.id === formValues.teamID) }])
                    })
                })
			}else{
				createCoachService( {...formValues,is_active: true} )
                .then(() => {
                    setIsLoading(false)
                    setCoaches([...coaches, {...formValues,is_active: true, team: teams.find(i=>i.id === formValues.teamID)}])
                })
			}
            setIsLoading(false)
			navigate(`/admin/coaches/`)
		}

    }

    return (
        <>
            <Alert status='warning' mt={{ base: "120px", md: "90px" }} borderRadius="10px">
                <AlertIcon />
                <AlertTitle>Email must be unique.</AlertTitle>
                <AlertDescription>Please check the email address provided because it cannot be changed.</AlertDescription>
            </Alert>
            <AlertDialog
				isOpen={isOpen}
				leastDestructiveRef={cancelRef}
				onClose={onClose}
				motionPreset='scale'
			>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Granting access to the app
						</AlertDialogHeader>

						<AlertDialogBody>
                        By creating this coach it will be given access to the app using the email: <strong>{formValues.contact_email}</strong> and the temporary password: <strong>Alliance2021!</strong>
						</AlertDialogBody>

						<AlertDialogFooter>
						<Button ref={cancelRef} onClick={onClose}>
							Cancel
						</Button>
						<Button colorScheme='blue' onClick={handleCreate} ml={3}>
							Create
						</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>  
            {
				contactEmailExists && (
					<Alert status='error' borderRadius="10px" mt="70px" mb="10px">
						<AlertIcon />
						<strong>Existing email.</strong> You cannot create another coach with the same email address.
						<CloseButton position='absolute' right='8px' top='8px' onClick={()=>(setContactEmailExists(false))} />
					</Alert>
				)
			}
            <Box mt="10px" borderRadius="md" boxShadow="md" bg="white" >
                {/* Header */}
                <Flex justifyContent='left' p='15px 0px 10px 20px' >
                    <Text fontSize='xl' color={textColor} fontWeight='bold' mr="200px">
                        Create coach 
                    </Text>
                </Flex>

                {/* Form of their attributes */}
                <Flex direction='column' w="100%" alignContent="center" px="20px"  > 
                    
                    {/* First row */}
                    <Flex direction={{ sm: "column", lg: "row" }} w="100%">
                        <Flex w={{ sm: "100%", lg: "30%" }} mt="20px">
                            <FormControl isInvalid={formValues.name === ""}>
                                <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mr="15px" fontWeight='semibold' textAlign="justify">
                                    Name
                                </Text>
                                <Input isRequired={true} onChange={onChange} variant='flushed' placeholder='Name' size='sm' value={formValues.name} name="name"/>
                                {formValues.name === "" ? <FormErrorMessage>This field is mandatory</FormErrorMessage> : null}
                            </FormControl>
                        </Flex>
                        <Spacer />
                        <Flex alignItems='left' direction={'column'} w={{ sm: "100%", lg: "30%" }} mt="20px">
                            <FormControl isInvalid={(errors.email !== '' || formValues.contact_email === "")}>
                                <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mr="15px" fontWeight='semibold' textAlign="justify">
                                    Contact email
                                </Text>
                                <Input isRequired={true} onChange={onChange} variant='flushed' placeholder='Contact email' size='sm' value={formValues.contact_email} name="contact_email"/>
                                {(errors.email !== '' || formValues.contact_email === "") ? <FormErrorMessage>{errors.email !== '' ? errors.email : 'This field is mandatory'}</FormErrorMessage> : null}
                            </FormControl>
                        </Flex>
                        <Spacer />
                        <Flex alignItems='left' direction={'column'} w={{ sm: "100%", lg: "30%" }} mt="20px" >
                            <FormControl isInvalid={(formValues.sport === "")}>
                                <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mb="5px" fontWeight='semibold' textAlign="justify">
                                    Sport
                                </Text>
                                <Select
                                    closeMenuOnSelect={true}
                                    components={animatedComponents}
                                    placeholder="Select sport"
                                    isSearchable={true}
                                    options={[{value:"Soccer",label:"Soccer"},{value:"Tennis",label:"Tennis"}]}
                                    onChange={(selectedOptions) => setFormValues(()=>({...formValues, sport: selectedOptions.value}))}
                                />
                                {formValues.sport === "" ? <FormErrorMessage>This field is mandatory</FormErrorMessage> : null}
                            </FormControl>
                        </Flex>
                    </Flex>
                    
                    {/* Second row */}
                    <Flex direction={{ sm: "column", lg: "row" }} w="100%" alignItems="center" >
                        {/* <Flex alignItems='left' direction={'column'} w={{ sm: "100%", lg: "30%" }} mt="20px" >
                            
                                <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mb="5px" fontWeight='semibold' textAlign="justify">
                                    Gender
                                </Text>
                                <Select
                                    closeMenuOnSelect={true}
                                    components={animatedComponents}
                                    placeholder="Select gender"
                                    isSearchable={true}
                                    options={[{value:"male",label:"Male"},{value:"female",label:"Female"}]}
                                    onChange={(selectedOptions) => setFormValues(()=>({...formValues, gender: selectedOptions.value}))}
                                />
                           
                        </Flex>
                        <Spacer /> */}
                        <Flex alignItems='left' direction={'column'} w={{ sm: "100%", lg: "45%" }} mt="20px">
                            <FormControl isInvalid={errors.birthdate !== ''}>
                                <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mr="15px" fontWeight='semibold' textAlign="justify">
                                    Birthdate
                                </Text>
                                <Input onChange={onChange} variant='flushed' placeholder='YYYY-MM-DD' size='sm' value={formValues.birthdate} name="birthdate"/>
                                {errors.birthdate !== '' ? <FormErrorMessage>{errors.birthdate}</FormErrorMessage> : null}
                            </FormControl>
                        </Flex>
                        <Spacer />
                        <Flex alignItems='left' direction={'column'} w={{ sm: "100%", lg: "45%" }} mt="20px">
                            <FormControl isInvalid={errors.phone !== ''}>
                                <Flex direction="row" alignItems="center">
                                    <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mr="15px" fontWeight='semibold' textAlign="justify">
                                        Contact phone
                                    </Text>
                                    <Tooltip label={`Enter a phone number using the format: [+][country code][phone number]`} bg='royalblue.700'  placement='auto' >
                                        <span><Icon as={BsQuestionCircleFill} color="royalblue.700" /></span>
                                    </Tooltip>
                                </Flex>
                                <Input onChange={onChange} variant='flushed' placeholder='+16175551212' size='sm' value={formValues.contact_phone} name="contact_phone"/>
                                {errors.phone !== '' ? <FormErrorMessage>{errors.phone}</FormErrorMessage> : null}
                            </FormControl>
                        </Flex>
                        
                    </Flex>
                    {/* Third row */}
                    <Flex direction={{ sm: "column", lg: "row" }} w="100%" >
                        <Flex alignItems='left' direction={'column'} w={{ sm: "100%", lg: "30%" }} mt="20px" >
                            <FormControl isInvalid={formValues.teamID === ''}>
                                <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mb="5px" fontWeight='semibold' textAlign="justify">
                                    Team
                                </Text>
                                <Select
                                    closeMenuOnSelect={true}
                                    components={animatedComponents}
                                    placeholder="Select team"
                                    isSearchable={true}
                                    options={teams.map(team => ({value:team.id,label:`${team.name} - ${team.genre}` }))}
                                    onChange={(selectedOptions) =>  setFormValues(()=>({...formValues, teamID: selectedOptions.value}))}
                                />
                                {formValues.teamID === '' ? <FormErrorMessage>This field is mandatory</FormErrorMessage> : null}
                            </FormControl>
                           
                        </Flex>
                        <Spacer />
                        <Flex alignItems='left' direction={'column'} w={{ sm: "100%", lg: "30%" }} mt="20px">
                            <FormControl isInvalid={formValues.role === ''}>
                                <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mb="5px" fontWeight='semibold' textAlign="justify">
                                    Role
                                </Text>
                                <Select
                                    closeMenuOnSelect={true}
                                    components={animatedComponents}
                                    placeholder="Select role"
                                    isSearchable={true}
                                    options={roles}
                                    onChange={(selectedOptions) => setFormValues(()=>({...formValues, role: selectedOptions.value}))}
                                />
                                {formValues.role === '' ? <FormErrorMessage>This field is mandatory</FormErrorMessage> : null}
                            </FormControl>
                        </Flex>
                        <Spacer />
                        <Flex alignItems='left' direction={'column'} w={{ sm: "100%", lg: "30%" }} mt="20px">
                            <FormControl isInvalid={formValues.division === ''}>
                                <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mb="5px" fontWeight='semibold' textAlign="justify">
                                    Division
                                </Text>
                                <Select
                                    closeMenuOnSelect={true}
                                    components={animatedComponents}
                                    placeholder="Select division"
                                    isSearchable={true}
                                    options={division}
                                    onChange={(selectedOptions) => setFormValues(()=>({...formValues, division: selectedOptions.value}))}
                                />
                                {formValues.division === '' ? <FormErrorMessage>This field is mandatory</FormErrorMessage> : null}
                            </FormControl>
                        </Flex>
                        
                    </Flex>
                     {/* Fourth row with drag and drop for logo*/}
                     <Flex direction="column" w="100%" mt="30px">
                        <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mb="5px" fontWeight='semibold' textAlign="justify">
                            Profile picture
                        </Text>
                        
                        {/* Component with dropzone for images */}
                        <ImageDropzone values={formValues} updateValues={setFormValues} propChanged="profile_pic"/>
                    </Flex>
                </Flex> 


                {/* Confirm and cancel buttons */}
                <Flex justifyContent='right' p='10px 40px 10px 0px' >
                    <Flex direction="column" my={5}>
                        <ButtonGroup spacing="6" justifyContent='right' mb="10px">

                            <Button
                                isLoading={isLoading}
                                loadingText='Submitting'
                                colorScheme='green'
                                variant='outline'
                                onClick={canCreate}
                                isDisabled={!formHasChanged}
                            >
                                Submit
                            </Button>
                            <Button
                                colorScheme='red'
                                variant='outline'
                                onClick={()=>(navigate(`/admin/coaches/`))}
                                >
                                Cancel
                            </Button>

                        </ButtonGroup>
                        <Text fontSize={{ sm: "xs", lg: "sm" }} color={"gray.400"}>
                            <i>You must modify some fields to enable submit button*</i>
                        </Text>
                    </Flex>
                </Flex>
            </Box>
        </>
    )   
}
