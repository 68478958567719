/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCollege = /* GraphQL */ `
  mutation CreateCollege(
    $input: CreateCollegeInput!
    $condition: ModelCollegeConditionInput
  ) {
    createCollege(input: $input, condition: $condition) {
      id
      name
      country
      state
      address
      logo
      createdAt
      updatedAt
      teams {
        items {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          had_trial
          collegeID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const updateCollege = /* GraphQL */ `
  mutation UpdateCollege(
    $input: UpdateCollegeInput!
    $condition: ModelCollegeConditionInput
  ) {
    updateCollege(input: $input, condition: $condition) {
      id
      name
      country
      state
      address
      logo
      createdAt
      updatedAt
      teams {
        items {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          had_trial
          collegeID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const deleteCollege = /* GraphQL */ `
  mutation DeleteCollege(
    $input: DeleteCollegeInput!
    $condition: ModelCollegeConditionInput
  ) {
    deleteCollege(input: $input, condition: $condition) {
      id
      name
      country
      state
      address
      logo
      createdAt
      updatedAt
      teams {
        items {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          had_trial
          collegeID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const createTeam = /* GraphQL */ `
  mutation CreateTeam(
    $input: CreateTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    createTeam(input: $input, condition: $condition) {
      id
      name
      sport
      genre
      suscription_name
      suscription_recurrency
      suscription_status
      suscription_stripe_id
      suscription_premium_start_date
      suscription_premium_end_date
      team_logo
      conference
      division
      college_category
      url_domain
      had_trial
      collegeID
      college {
        id
        name
        country
        state
        address
        logo
        createdAt
        updatedAt
        teams {
          nextToken
          __typename
        }
        __typename
      }
      coaches {
        items {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          disabledByUser
          is_active
          allow_whatsapp_contact
          deleteAppReason
          division
          role
          sport
          teamID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      subscription_history {
        items {
          id
          start_date
          end_date
          suscription_name
          teamID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      subscriberID
      subscriber {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
          __typename
        }
        my_filters {
          nextToken
          __typename
        }
        my_favorite_folders {
          nextToken
          __typename
        }
        my_notifications {
          nextToken
          __typename
        }
        showcases_interested_in {
          nextToken
          __typename
        }
        my_athlete_tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          had_trial
          collegeID
          subscriberID
          subscription_initial_date
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      subscription_initial_date
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTeam = /* GraphQL */ `
  mutation UpdateTeam(
    $input: UpdateTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    updateTeam(input: $input, condition: $condition) {
      id
      name
      sport
      genre
      suscription_name
      suscription_recurrency
      suscription_status
      suscription_stripe_id
      suscription_premium_start_date
      suscription_premium_end_date
      team_logo
      conference
      division
      college_category
      url_domain
      had_trial
      collegeID
      college {
        id
        name
        country
        state
        address
        logo
        createdAt
        updatedAt
        teams {
          nextToken
          __typename
        }
        __typename
      }
      coaches {
        items {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          disabledByUser
          is_active
          allow_whatsapp_contact
          deleteAppReason
          division
          role
          sport
          teamID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      subscription_history {
        items {
          id
          start_date
          end_date
          suscription_name
          teamID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      subscriberID
      subscriber {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
          __typename
        }
        my_filters {
          nextToken
          __typename
        }
        my_favorite_folders {
          nextToken
          __typename
        }
        my_notifications {
          nextToken
          __typename
        }
        showcases_interested_in {
          nextToken
          __typename
        }
        my_athlete_tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          had_trial
          collegeID
          subscriberID
          subscription_initial_date
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      subscription_initial_date
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTeam = /* GraphQL */ `
  mutation DeleteTeam(
    $input: DeleteTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    deleteTeam(input: $input, condition: $condition) {
      id
      name
      sport
      genre
      suscription_name
      suscription_recurrency
      suscription_status
      suscription_stripe_id
      suscription_premium_start_date
      suscription_premium_end_date
      team_logo
      conference
      division
      college_category
      url_domain
      had_trial
      collegeID
      college {
        id
        name
        country
        state
        address
        logo
        createdAt
        updatedAt
        teams {
          nextToken
          __typename
        }
        __typename
      }
      coaches {
        items {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          disabledByUser
          is_active
          allow_whatsapp_contact
          deleteAppReason
          division
          role
          sport
          teamID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      subscription_history {
        items {
          id
          start_date
          end_date
          suscription_name
          teamID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      subscriberID
      subscriber {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
          __typename
        }
        my_filters {
          nextToken
          __typename
        }
        my_favorite_folders {
          nextToken
          __typename
        }
        my_notifications {
          nextToken
          __typename
        }
        showcases_interested_in {
          nextToken
          __typename
        }
        my_athlete_tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          had_trial
          collegeID
          subscriberID
          subscription_initial_date
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      subscription_initial_date
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSubscriptionPeriod = /* GraphQL */ `
  mutation CreateSubscriptionPeriod(
    $input: CreateSubscriptionPeriodInput!
    $condition: ModelSubscriptionPeriodConditionInput
  ) {
    createSubscriptionPeriod(input: $input, condition: $condition) {
      id
      start_date
      end_date
      suscription_name
      teamID
      createdAt
      updatedAt
      team {
        id
        name
        sport
        genre
        suscription_name
        suscription_recurrency
        suscription_status
        suscription_stripe_id
        suscription_premium_start_date
        suscription_premium_end_date
        team_logo
        conference
        division
        college_category
        url_domain
        had_trial
        collegeID
        college {
          id
          name
          country
          state
          address
          logo
          createdAt
          updatedAt
          __typename
        }
        coaches {
          nextToken
          __typename
        }
        subscription_history {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateSubscriptionPeriod = /* GraphQL */ `
  mutation UpdateSubscriptionPeriod(
    $input: UpdateSubscriptionPeriodInput!
    $condition: ModelSubscriptionPeriodConditionInput
  ) {
    updateSubscriptionPeriod(input: $input, condition: $condition) {
      id
      start_date
      end_date
      suscription_name
      teamID
      createdAt
      updatedAt
      team {
        id
        name
        sport
        genre
        suscription_name
        suscription_recurrency
        suscription_status
        suscription_stripe_id
        suscription_premium_start_date
        suscription_premium_end_date
        team_logo
        conference
        division
        college_category
        url_domain
        had_trial
        collegeID
        college {
          id
          name
          country
          state
          address
          logo
          createdAt
          updatedAt
          __typename
        }
        coaches {
          nextToken
          __typename
        }
        subscription_history {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteSubscriptionPeriod = /* GraphQL */ `
  mutation DeleteSubscriptionPeriod(
    $input: DeleteSubscriptionPeriodInput!
    $condition: ModelSubscriptionPeriodConditionInput
  ) {
    deleteSubscriptionPeriod(input: $input, condition: $condition) {
      id
      start_date
      end_date
      suscription_name
      teamID
      createdAt
      updatedAt
      team {
        id
        name
        sport
        genre
        suscription_name
        suscription_recurrency
        suscription_status
        suscription_stripe_id
        suscription_premium_start_date
        suscription_premium_end_date
        team_logo
        conference
        division
        college_category
        url_domain
        had_trial
        collegeID
        college {
          id
          name
          country
          state
          address
          logo
          createdAt
          updatedAt
          __typename
        }
        coaches {
          nextToken
          __typename
        }
        subscription_history {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateCoach = /* GraphQL */ `
  mutation UpdateCoach(
    $input: UpdateCoachInput!
    $condition: ModelCoachConditionInput
  ) {
    updateCoach(input: $input, condition: $condition) {
      id
      name
      profile_pic
      birthdate
      gender
      contact_email
      contact_phone
      disabledByUser
      is_active
      allow_whatsapp_contact
      deleteAppReason
      division
      role
      sport
      teamID
      favorites {
        items {
          id
          athleteID
          coachID
          rating
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      my_filters {
        items {
          id
          name
          filter_body
          type
          sport
          source_app
          coachID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      my_favorite_folders {
        items {
          id
          folder_name
          status
          coachID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      my_notifications {
        items {
          id
          is_viewed
          status
          notificationID
          coachID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      showcases_interested_in {
        items {
          id
          showcaseID
          coachID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      my_athlete_tags {
        items {
          id
          coachID
          tagID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      team {
        id
        name
        sport
        genre
        suscription_name
        suscription_recurrency
        suscription_status
        suscription_stripe_id
        suscription_premium_start_date
        suscription_premium_end_date
        team_logo
        conference
        division
        college_category
        url_domain
        had_trial
        collegeID
        college {
          id
          name
          country
          state
          address
          logo
          createdAt
          updatedAt
          __typename
        }
        coaches {
          nextToken
          __typename
        }
        subscription_history {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteCoach = /* GraphQL */ `
  mutation DeleteCoach(
    $input: DeleteCoachInput!
    $condition: ModelCoachConditionInput
  ) {
    deleteCoach(input: $input, condition: $condition) {
      id
      name
      profile_pic
      birthdate
      gender
      contact_email
      contact_phone
      disabledByUser
      is_active
      allow_whatsapp_contact
      deleteAppReason
      division
      role
      sport
      teamID
      favorites {
        items {
          id
          athleteID
          coachID
          rating
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      my_filters {
        items {
          id
          name
          filter_body
          type
          sport
          source_app
          coachID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      my_favorite_folders {
        items {
          id
          folder_name
          status
          coachID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      my_notifications {
        items {
          id
          is_viewed
          status
          notificationID
          coachID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      showcases_interested_in {
        items {
          id
          showcaseID
          coachID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      my_athlete_tags {
        items {
          id
          coachID
          tagID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      team {
        id
        name
        sport
        genre
        suscription_name
        suscription_recurrency
        suscription_status
        suscription_stripe_id
        suscription_premium_start_date
        suscription_premium_end_date
        team_logo
        conference
        division
        college_category
        url_domain
        had_trial
        collegeID
        college {
          id
          name
          country
          state
          address
          logo
          createdAt
          updatedAt
          __typename
        }
        coaches {
          nextToken
          __typename
        }
        subscription_history {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const createCoachMessage = /* GraphQL */ `
  mutation CreateCoachMessage(
    $input: CreateCoachMessageInput!
    $condition: ModelCoachMessageConditionInput
  ) {
    createCoachMessage(input: $input, condition: $condition) {
      id
      message
      agencyID
      agency {
        id
        name
        logo
        country
        contact_email
        contact_phone
        website
        sport
        athletes {
          nextToken
          __typename
        }
        agents {
          nextToken
          __typename
        }
        coach_messages {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      coachID
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
          __typename
        }
        my_filters {
          nextToken
          __typename
        }
        my_favorite_folders {
          nextToken
          __typename
        }
        my_notifications {
          nextToken
          __typename
        }
        showcases_interested_in {
          nextToken
          __typename
        }
        my_athlete_tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          had_trial
          collegeID
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCoachMessage = /* GraphQL */ `
  mutation UpdateCoachMessage(
    $input: UpdateCoachMessageInput!
    $condition: ModelCoachMessageConditionInput
  ) {
    updateCoachMessage(input: $input, condition: $condition) {
      id
      message
      agencyID
      agency {
        id
        name
        logo
        country
        contact_email
        contact_phone
        website
        sport
        athletes {
          nextToken
          __typename
        }
        agents {
          nextToken
          __typename
        }
        coach_messages {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      coachID
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
          __typename
        }
        my_filters {
          nextToken
          __typename
        }
        my_favorite_folders {
          nextToken
          __typename
        }
        my_notifications {
          nextToken
          __typename
        }
        showcases_interested_in {
          nextToken
          __typename
        }
        my_athlete_tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          had_trial
          collegeID
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCoachMessage = /* GraphQL */ `
  mutation DeleteCoachMessage(
    $input: DeleteCoachMessageInput!
    $condition: ModelCoachMessageConditionInput
  ) {
    deleteCoachMessage(input: $input, condition: $condition) {
      id
      message
      agencyID
      agency {
        id
        name
        logo
        country
        contact_email
        contact_phone
        website
        sport
        athletes {
          nextToken
          __typename
        }
        agents {
          nextToken
          __typename
        }
        coach_messages {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      coachID
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
          __typename
        }
        my_filters {
          nextToken
          __typename
        }
        my_favorite_folders {
          nextToken
          __typename
        }
        my_notifications {
          nextToken
          __typename
        }
        showcases_interested_in {
          nextToken
          __typename
        }
        my_athlete_tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          had_trial
          collegeID
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAgency = /* GraphQL */ `
  mutation CreateAgency(
    $input: CreateAgencyInput!
    $condition: ModelAgencyConditionInput
  ) {
    createAgency(input: $input, condition: $condition) {
      id
      name
      logo
      country
      contact_email
      contact_phone
      website
      sport
      athletes {
        items {
          id
          agencyID
          agentID
          name
          isVisible
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          nationality
          country_of_competition
          target_division
          compliance
          card_pic
          other_nice_pics
          agency_assesment
          elegibility_notes
          current_club
          current_status
          start_availability_date
          end_availability_date
          destination_college
          ncaa_id
          type_of_admission
          weight
          height
          sport
          position
          second_position
          dominant_foot
          level_of_competition
          sports_background
          start_date
          high_school_graduation_date
          sat_score
          sat_date
          act_score
          act_date
          gemat_gre_score
          gemat_gre_date
          toefl_score
          toefl_date
          duolingo
          duolingo_date
          ielts
          ielts_date
          estimated_gpa
          intended_field_of_studies
          intended_degree
          budget
          category
          facebook_url
          twitter_user
          linkedin_url
          reason_commitment
          date_commitment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      agents {
        items {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          agent_role
          agencyID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coach_messages {
        items {
          id
          message
          agencyID
          coachID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAgency = /* GraphQL */ `
  mutation UpdateAgency(
    $input: UpdateAgencyInput!
    $condition: ModelAgencyConditionInput
  ) {
    updateAgency(input: $input, condition: $condition) {
      id
      name
      logo
      country
      contact_email
      contact_phone
      website
      sport
      athletes {
        items {
          id
          agencyID
          agentID
          name
          isVisible
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          nationality
          country_of_competition
          target_division
          compliance
          card_pic
          other_nice_pics
          agency_assesment
          elegibility_notes
          current_club
          current_status
          start_availability_date
          end_availability_date
          destination_college
          ncaa_id
          type_of_admission
          weight
          height
          sport
          position
          second_position
          dominant_foot
          level_of_competition
          sports_background
          start_date
          high_school_graduation_date
          sat_score
          sat_date
          act_score
          act_date
          gemat_gre_score
          gemat_gre_date
          toefl_score
          toefl_date
          duolingo
          duolingo_date
          ielts
          ielts_date
          estimated_gpa
          intended_field_of_studies
          intended_degree
          budget
          category
          facebook_url
          twitter_user
          linkedin_url
          reason_commitment
          date_commitment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      agents {
        items {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          agent_role
          agencyID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coach_messages {
        items {
          id
          message
          agencyID
          coachID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAgency = /* GraphQL */ `
  mutation DeleteAgency(
    $input: DeleteAgencyInput!
    $condition: ModelAgencyConditionInput
  ) {
    deleteAgency(input: $input, condition: $condition) {
      id
      name
      logo
      country
      contact_email
      contact_phone
      website
      sport
      athletes {
        items {
          id
          agencyID
          agentID
          name
          isVisible
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          nationality
          country_of_competition
          target_division
          compliance
          card_pic
          other_nice_pics
          agency_assesment
          elegibility_notes
          current_club
          current_status
          start_availability_date
          end_availability_date
          destination_college
          ncaa_id
          type_of_admission
          weight
          height
          sport
          position
          second_position
          dominant_foot
          level_of_competition
          sports_background
          start_date
          high_school_graduation_date
          sat_score
          sat_date
          act_score
          act_date
          gemat_gre_score
          gemat_gre_date
          toefl_score
          toefl_date
          duolingo
          duolingo_date
          ielts
          ielts_date
          estimated_gpa
          intended_field_of_studies
          intended_degree
          budget
          category
          facebook_url
          twitter_user
          linkedin_url
          reason_commitment
          date_commitment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      agents {
        items {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          agent_role
          agencyID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coach_messages {
        items {
          id
          message
          agencyID
          coachID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAthlete = /* GraphQL */ `
  mutation CreateAthlete(
    $input: CreateAthleteInput!
    $condition: ModelAthleteConditionInput
  ) {
    createAthlete(input: $input, condition: $condition) {
      id
      agencyID
      agentID
      name
      isVisible
      profile_pic
      birthdate
      gender
      contact_email
      contact_phone
      nationality
      country_of_competition
      target_division
      compliance
      card_pic
      other_nice_pics
      agency_assesment
      elegibility_notes
      current_club
      current_status
      start_availability_date
      end_availability_date
      destination_college
      ncaa_id
      type_of_admission
      weight
      height
      sport
      tennis_profile {
        utr
        national_ranking
        international_ranking
        national_ranking_adult
        international_ranking_adult
        DominantHand
        __typename
      }
      position
      second_position
      dominant_foot
      featured_video {
        url
        metadata
        __typename
      }
      other_videos {
        url
        metadata
        __typename
      }
      level_of_competition
      sports_background
      showcase_participation {
        showcase_name
        city
        country
        date
        __typename
      }
      start_date
      high_school_graduation_date
      sat_score
      sat_date
      act_score
      act_date
      gemat_gre_score
      gemat_gre_date
      toefl_score
      toefl_date
      duolingo
      duolingo_date
      ielts
      ielts_date
      estimated_gpa
      intended_field_of_studies
      intended_degree
      budget
      category
      facebook_url
      twitter_user
      linkedin_url
      reason_commitment
      date_commitment
      agency {
        id
        name
        logo
        country
        contact_email
        contact_phone
        website
        sport
        athletes {
          nextToken
          __typename
        }
        agents {
          nextToken
          __typename
        }
        coach_messages {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      agent {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        agent_role
        agencyID
        agency {
          nextToken
          __typename
        }
        ahtletes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      favorited {
        items {
          id
          athleteID
          coachID
          rating
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      enquiries {
        items {
          id
          enquiry_text
          athleteID
          coachID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      tags {
        items {
          id
          athleteID
          tagID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      showcases_participation {
        items {
          id
          showcaseID
          athleteID
          color
          number
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAthlete = /* GraphQL */ `
  mutation UpdateAthlete(
    $input: UpdateAthleteInput!
    $condition: ModelAthleteConditionInput
  ) {
    updateAthlete(input: $input, condition: $condition) {
      id
      agencyID
      agentID
      name
      isVisible
      profile_pic
      birthdate
      gender
      contact_email
      contact_phone
      nationality
      country_of_competition
      target_division
      compliance
      card_pic
      other_nice_pics
      agency_assesment
      elegibility_notes
      current_club
      current_status
      start_availability_date
      end_availability_date
      destination_college
      ncaa_id
      type_of_admission
      weight
      height
      sport
      tennis_profile {
        utr
        national_ranking
        international_ranking
        national_ranking_adult
        international_ranking_adult
        DominantHand
        __typename
      }
      position
      second_position
      dominant_foot
      featured_video {
        url
        metadata
        __typename
      }
      other_videos {
        url
        metadata
        __typename
      }
      level_of_competition
      sports_background
      showcase_participation {
        showcase_name
        city
        country
        date
        __typename
      }
      start_date
      high_school_graduation_date
      sat_score
      sat_date
      act_score
      act_date
      gemat_gre_score
      gemat_gre_date
      toefl_score
      toefl_date
      duolingo
      duolingo_date
      ielts
      ielts_date
      estimated_gpa
      intended_field_of_studies
      intended_degree
      budget
      category
      facebook_url
      twitter_user
      linkedin_url
      reason_commitment
      date_commitment
      agency {
        id
        name
        logo
        country
        contact_email
        contact_phone
        website
        sport
        athletes {
          nextToken
          __typename
        }
        agents {
          nextToken
          __typename
        }
        coach_messages {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      agent {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        agent_role
        agencyID
        agency {
          nextToken
          __typename
        }
        ahtletes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      favorited {
        items {
          id
          athleteID
          coachID
          rating
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      enquiries {
        items {
          id
          enquiry_text
          athleteID
          coachID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      tags {
        items {
          id
          athleteID
          tagID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      showcases_participation {
        items {
          id
          showcaseID
          athleteID
          color
          number
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAthlete = /* GraphQL */ `
  mutation DeleteAthlete(
    $input: DeleteAthleteInput!
    $condition: ModelAthleteConditionInput
  ) {
    deleteAthlete(input: $input, condition: $condition) {
      id
      agencyID
      agentID
      name
      isVisible
      profile_pic
      birthdate
      gender
      contact_email
      contact_phone
      nationality
      country_of_competition
      target_division
      compliance
      card_pic
      other_nice_pics
      agency_assesment
      elegibility_notes
      current_club
      current_status
      start_availability_date
      end_availability_date
      destination_college
      ncaa_id
      type_of_admission
      weight
      height
      sport
      tennis_profile {
        utr
        national_ranking
        international_ranking
        national_ranking_adult
        international_ranking_adult
        DominantHand
        __typename
      }
      position
      second_position
      dominant_foot
      featured_video {
        url
        metadata
        __typename
      }
      other_videos {
        url
        metadata
        __typename
      }
      level_of_competition
      sports_background
      showcase_participation {
        showcase_name
        city
        country
        date
        __typename
      }
      start_date
      high_school_graduation_date
      sat_score
      sat_date
      act_score
      act_date
      gemat_gre_score
      gemat_gre_date
      toefl_score
      toefl_date
      duolingo
      duolingo_date
      ielts
      ielts_date
      estimated_gpa
      intended_field_of_studies
      intended_degree
      budget
      category
      facebook_url
      twitter_user
      linkedin_url
      reason_commitment
      date_commitment
      agency {
        id
        name
        logo
        country
        contact_email
        contact_phone
        website
        sport
        athletes {
          nextToken
          __typename
        }
        agents {
          nextToken
          __typename
        }
        coach_messages {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      agent {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        agent_role
        agencyID
        agency {
          nextToken
          __typename
        }
        ahtletes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      favorited {
        items {
          id
          athleteID
          coachID
          rating
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      enquiries {
        items {
          id
          enquiry_text
          athleteID
          coachID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      tags {
        items {
          id
          athleteID
          tagID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      showcases_participation {
        items {
          id
          showcaseID
          athleteID
          color
          number
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCoachAthleteFavorites = /* GraphQL */ `
  mutation CreateCoachAthleteFavorites(
    $input: CreateCoachAthleteFavoritesInput!
    $condition: ModelCoachAthleteFavoritesConditionInput
  ) {
    createCoachAthleteFavorites(input: $input, condition: $condition) {
      id
      athleteID
      coachID
      rating
      athlete {
        id
        agencyID
        agentID
        name
        isVisible
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        nationality
        country_of_competition
        target_division
        compliance
        card_pic
        other_nice_pics
        agency_assesment
        elegibility_notes
        current_club
        current_status
        start_availability_date
        end_availability_date
        destination_college
        ncaa_id
        type_of_admission
        weight
        height
        sport
        tennis_profile {
          utr
          national_ranking
          international_ranking
          national_ranking_adult
          international_ranking_adult
          DominantHand
          __typename
        }
        position
        second_position
        dominant_foot
        featured_video {
          url
          metadata
          __typename
        }
        other_videos {
          url
          metadata
          __typename
        }
        level_of_competition
        sports_background
        showcase_participation {
          showcase_name
          city
          country
          date
          __typename
        }
        start_date
        high_school_graduation_date
        sat_score
        sat_date
        act_score
        act_date
        gemat_gre_score
        gemat_gre_date
        toefl_score
        toefl_date
        duolingo
        duolingo_date
        ielts
        ielts_date
        estimated_gpa
        intended_field_of_studies
        intended_degree
        budget
        category
        facebook_url
        twitter_user
        linkedin_url
        reason_commitment
        date_commitment
        agency {
          id
          name
          logo
          country
          contact_email
          contact_phone
          website
          sport
          createdAt
          updatedAt
          __typename
        }
        agent {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          agent_role
          agencyID
          createdAt
          updatedAt
          __typename
        }
        favorited {
          nextToken
          __typename
        }
        enquiries {
          nextToken
          __typename
        }
        tags {
          nextToken
          __typename
        }
        showcases_participation {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
          __typename
        }
        my_filters {
          nextToken
          __typename
        }
        my_favorite_folders {
          nextToken
          __typename
        }
        my_notifications {
          nextToken
          __typename
        }
        showcases_interested_in {
          nextToken
          __typename
        }
        my_athlete_tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          had_trial
          collegeID
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCoachAthleteFavorites = /* GraphQL */ `
  mutation UpdateCoachAthleteFavorites(
    $input: UpdateCoachAthleteFavoritesInput!
    $condition: ModelCoachAthleteFavoritesConditionInput
  ) {
    updateCoachAthleteFavorites(input: $input, condition: $condition) {
      id
      athleteID
      coachID
      rating
      athlete {
        id
        agencyID
        agentID
        name
        isVisible
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        nationality
        country_of_competition
        target_division
        compliance
        card_pic
        other_nice_pics
        agency_assesment
        elegibility_notes
        current_club
        current_status
        start_availability_date
        end_availability_date
        destination_college
        ncaa_id
        type_of_admission
        weight
        height
        sport
        tennis_profile {
          utr
          national_ranking
          international_ranking
          national_ranking_adult
          international_ranking_adult
          DominantHand
          __typename
        }
        position
        second_position
        dominant_foot
        featured_video {
          url
          metadata
          __typename
        }
        other_videos {
          url
          metadata
          __typename
        }
        level_of_competition
        sports_background
        showcase_participation {
          showcase_name
          city
          country
          date
          __typename
        }
        start_date
        high_school_graduation_date
        sat_score
        sat_date
        act_score
        act_date
        gemat_gre_score
        gemat_gre_date
        toefl_score
        toefl_date
        duolingo
        duolingo_date
        ielts
        ielts_date
        estimated_gpa
        intended_field_of_studies
        intended_degree
        budget
        category
        facebook_url
        twitter_user
        linkedin_url
        reason_commitment
        date_commitment
        agency {
          id
          name
          logo
          country
          contact_email
          contact_phone
          website
          sport
          createdAt
          updatedAt
          __typename
        }
        agent {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          agent_role
          agencyID
          createdAt
          updatedAt
          __typename
        }
        favorited {
          nextToken
          __typename
        }
        enquiries {
          nextToken
          __typename
        }
        tags {
          nextToken
          __typename
        }
        showcases_participation {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
          __typename
        }
        my_filters {
          nextToken
          __typename
        }
        my_favorite_folders {
          nextToken
          __typename
        }
        my_notifications {
          nextToken
          __typename
        }
        showcases_interested_in {
          nextToken
          __typename
        }
        my_athlete_tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          had_trial
          collegeID
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCoachAthleteFavorites = /* GraphQL */ `
  mutation DeleteCoachAthleteFavorites(
    $input: DeleteCoachAthleteFavoritesInput!
    $condition: ModelCoachAthleteFavoritesConditionInput
  ) {
    deleteCoachAthleteFavorites(input: $input, condition: $condition) {
      id
      athleteID
      coachID
      rating
      athlete {
        id
        agencyID
        agentID
        name
        isVisible
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        nationality
        country_of_competition
        target_division
        compliance
        card_pic
        other_nice_pics
        agency_assesment
        elegibility_notes
        current_club
        current_status
        start_availability_date
        end_availability_date
        destination_college
        ncaa_id
        type_of_admission
        weight
        height
        sport
        tennis_profile {
          utr
          national_ranking
          international_ranking
          national_ranking_adult
          international_ranking_adult
          DominantHand
          __typename
        }
        position
        second_position
        dominant_foot
        featured_video {
          url
          metadata
          __typename
        }
        other_videos {
          url
          metadata
          __typename
        }
        level_of_competition
        sports_background
        showcase_participation {
          showcase_name
          city
          country
          date
          __typename
        }
        start_date
        high_school_graduation_date
        sat_score
        sat_date
        act_score
        act_date
        gemat_gre_score
        gemat_gre_date
        toefl_score
        toefl_date
        duolingo
        duolingo_date
        ielts
        ielts_date
        estimated_gpa
        intended_field_of_studies
        intended_degree
        budget
        category
        facebook_url
        twitter_user
        linkedin_url
        reason_commitment
        date_commitment
        agency {
          id
          name
          logo
          country
          contact_email
          contact_phone
          website
          sport
          createdAt
          updatedAt
          __typename
        }
        agent {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          agent_role
          agencyID
          createdAt
          updatedAt
          __typename
        }
        favorited {
          nextToken
          __typename
        }
        enquiries {
          nextToken
          __typename
        }
        tags {
          nextToken
          __typename
        }
        showcases_participation {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
          __typename
        }
        my_filters {
          nextToken
          __typename
        }
        my_favorite_folders {
          nextToken
          __typename
        }
        my_notifications {
          nextToken
          __typename
        }
        showcases_interested_in {
          nextToken
          __typename
        }
        my_athlete_tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          had_trial
          collegeID
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createFavoritesFolder = /* GraphQL */ `
  mutation CreateFavoritesFolder(
    $input: CreateFavoritesFolderInput!
    $condition: ModelFavoritesFolderConditionInput
  ) {
    createFavoritesFolder(input: $input, condition: $condition) {
      id
      folder_name
      status
      coachID
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
          __typename
        }
        my_filters {
          nextToken
          __typename
        }
        my_favorite_folders {
          nextToken
          __typename
        }
        my_notifications {
          nextToken
          __typename
        }
        showcases_interested_in {
          nextToken
          __typename
        }
        my_athlete_tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          had_trial
          collegeID
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      athletes_in_folder {
        items {
          id
          athleteID
          favoritesFolderID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateFavoritesFolder = /* GraphQL */ `
  mutation UpdateFavoritesFolder(
    $input: UpdateFavoritesFolderInput!
    $condition: ModelFavoritesFolderConditionInput
  ) {
    updateFavoritesFolder(input: $input, condition: $condition) {
      id
      folder_name
      status
      coachID
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
          __typename
        }
        my_filters {
          nextToken
          __typename
        }
        my_favorite_folders {
          nextToken
          __typename
        }
        my_notifications {
          nextToken
          __typename
        }
        showcases_interested_in {
          nextToken
          __typename
        }
        my_athlete_tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          had_trial
          collegeID
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      athletes_in_folder {
        items {
          id
          athleteID
          favoritesFolderID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteFavoritesFolder = /* GraphQL */ `
  mutation DeleteFavoritesFolder(
    $input: DeleteFavoritesFolderInput!
    $condition: ModelFavoritesFolderConditionInput
  ) {
    deleteFavoritesFolder(input: $input, condition: $condition) {
      id
      folder_name
      status
      coachID
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
          __typename
        }
        my_filters {
          nextToken
          __typename
        }
        my_favorite_folders {
          nextToken
          __typename
        }
        my_notifications {
          nextToken
          __typename
        }
        showcases_interested_in {
          nextToken
          __typename
        }
        my_athlete_tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          had_trial
          collegeID
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      athletes_in_folder {
        items {
          id
          athleteID
          favoritesFolderID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createFavoritesFolderAthleteRelation = /* GraphQL */ `
  mutation CreateFavoritesFolderAthleteRelation(
    $input: CreateFavoritesFolderAthleteRelationInput!
    $condition: ModelFavoritesFolderAthleteRelationConditionInput
  ) {
    createFavoritesFolderAthleteRelation(input: $input, condition: $condition) {
      id
      athleteID
      favoritesFolderID
      athlete {
        id
        agencyID
        agentID
        name
        isVisible
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        nationality
        country_of_competition
        target_division
        compliance
        card_pic
        other_nice_pics
        agency_assesment
        elegibility_notes
        current_club
        current_status
        start_availability_date
        end_availability_date
        destination_college
        ncaa_id
        type_of_admission
        weight
        height
        sport
        tennis_profile {
          utr
          national_ranking
          international_ranking
          national_ranking_adult
          international_ranking_adult
          DominantHand
          __typename
        }
        position
        second_position
        dominant_foot
        featured_video {
          url
          metadata
          __typename
        }
        other_videos {
          url
          metadata
          __typename
        }
        level_of_competition
        sports_background
        showcase_participation {
          showcase_name
          city
          country
          date
          __typename
        }
        start_date
        high_school_graduation_date
        sat_score
        sat_date
        act_score
        act_date
        gemat_gre_score
        gemat_gre_date
        toefl_score
        toefl_date
        duolingo
        duolingo_date
        ielts
        ielts_date
        estimated_gpa
        intended_field_of_studies
        intended_degree
        budget
        category
        facebook_url
        twitter_user
        linkedin_url
        reason_commitment
        date_commitment
        agency {
          id
          name
          logo
          country
          contact_email
          contact_phone
          website
          sport
          createdAt
          updatedAt
          __typename
        }
        agent {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          agent_role
          agencyID
          createdAt
          updatedAt
          __typename
        }
        favorited {
          nextToken
          __typename
        }
        enquiries {
          nextToken
          __typename
        }
        tags {
          nextToken
          __typename
        }
        showcases_participation {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      favorites_folder {
        id
        folder_name
        status
        coachID
        coach {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          disabledByUser
          is_active
          allow_whatsapp_contact
          deleteAppReason
          division
          role
          sport
          teamID
          createdAt
          updatedAt
          __typename
        }
        athletes_in_folder {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateFavoritesFolderAthleteRelation = /* GraphQL */ `
  mutation UpdateFavoritesFolderAthleteRelation(
    $input: UpdateFavoritesFolderAthleteRelationInput!
    $condition: ModelFavoritesFolderAthleteRelationConditionInput
  ) {
    updateFavoritesFolderAthleteRelation(input: $input, condition: $condition) {
      id
      athleteID
      favoritesFolderID
      athlete {
        id
        agencyID
        agentID
        name
        isVisible
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        nationality
        country_of_competition
        target_division
        compliance
        card_pic
        other_nice_pics
        agency_assesment
        elegibility_notes
        current_club
        current_status
        start_availability_date
        end_availability_date
        destination_college
        ncaa_id
        type_of_admission
        weight
        height
        sport
        tennis_profile {
          utr
          national_ranking
          international_ranking
          national_ranking_adult
          international_ranking_adult
          DominantHand
          __typename
        }
        position
        second_position
        dominant_foot
        featured_video {
          url
          metadata
          __typename
        }
        other_videos {
          url
          metadata
          __typename
        }
        level_of_competition
        sports_background
        showcase_participation {
          showcase_name
          city
          country
          date
          __typename
        }
        start_date
        high_school_graduation_date
        sat_score
        sat_date
        act_score
        act_date
        gemat_gre_score
        gemat_gre_date
        toefl_score
        toefl_date
        duolingo
        duolingo_date
        ielts
        ielts_date
        estimated_gpa
        intended_field_of_studies
        intended_degree
        budget
        category
        facebook_url
        twitter_user
        linkedin_url
        reason_commitment
        date_commitment
        agency {
          id
          name
          logo
          country
          contact_email
          contact_phone
          website
          sport
          createdAt
          updatedAt
          __typename
        }
        agent {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          agent_role
          agencyID
          createdAt
          updatedAt
          __typename
        }
        favorited {
          nextToken
          __typename
        }
        enquiries {
          nextToken
          __typename
        }
        tags {
          nextToken
          __typename
        }
        showcases_participation {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      favorites_folder {
        id
        folder_name
        status
        coachID
        coach {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          disabledByUser
          is_active
          allow_whatsapp_contact
          deleteAppReason
          division
          role
          sport
          teamID
          createdAt
          updatedAt
          __typename
        }
        athletes_in_folder {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteFavoritesFolderAthleteRelation = /* GraphQL */ `
  mutation DeleteFavoritesFolderAthleteRelation(
    $input: DeleteFavoritesFolderAthleteRelationInput!
    $condition: ModelFavoritesFolderAthleteRelationConditionInput
  ) {
    deleteFavoritesFolderAthleteRelation(input: $input, condition: $condition) {
      id
      athleteID
      favoritesFolderID
      athlete {
        id
        agencyID
        agentID
        name
        isVisible
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        nationality
        country_of_competition
        target_division
        compliance
        card_pic
        other_nice_pics
        agency_assesment
        elegibility_notes
        current_club
        current_status
        start_availability_date
        end_availability_date
        destination_college
        ncaa_id
        type_of_admission
        weight
        height
        sport
        tennis_profile {
          utr
          national_ranking
          international_ranking
          national_ranking_adult
          international_ranking_adult
          DominantHand
          __typename
        }
        position
        second_position
        dominant_foot
        featured_video {
          url
          metadata
          __typename
        }
        other_videos {
          url
          metadata
          __typename
        }
        level_of_competition
        sports_background
        showcase_participation {
          showcase_name
          city
          country
          date
          __typename
        }
        start_date
        high_school_graduation_date
        sat_score
        sat_date
        act_score
        act_date
        gemat_gre_score
        gemat_gre_date
        toefl_score
        toefl_date
        duolingo
        duolingo_date
        ielts
        ielts_date
        estimated_gpa
        intended_field_of_studies
        intended_degree
        budget
        category
        facebook_url
        twitter_user
        linkedin_url
        reason_commitment
        date_commitment
        agency {
          id
          name
          logo
          country
          contact_email
          contact_phone
          website
          sport
          createdAt
          updatedAt
          __typename
        }
        agent {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          agent_role
          agencyID
          createdAt
          updatedAt
          __typename
        }
        favorited {
          nextToken
          __typename
        }
        enquiries {
          nextToken
          __typename
        }
        tags {
          nextToken
          __typename
        }
        showcases_participation {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      favorites_folder {
        id
        folder_name
        status
        coachID
        coach {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          disabledByUser
          is_active
          allow_whatsapp_contact
          deleteAppReason
          division
          role
          sport
          teamID
          createdAt
          updatedAt
          __typename
        }
        athletes_in_folder {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAthleteTag = /* GraphQL */ `
  mutation CreateAthleteTag(
    $input: CreateAthleteTagInput!
    $condition: ModelAthleteTagConditionInput
  ) {
    createAthleteTag(input: $input, condition: $condition) {
      id
      text
      type
      athletes {
        items {
          id
          athleteID
          tagID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coaches_owners {
        items {
          id
          coachID
          tagID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAthleteTag = /* GraphQL */ `
  mutation UpdateAthleteTag(
    $input: UpdateAthleteTagInput!
    $condition: ModelAthleteTagConditionInput
  ) {
    updateAthleteTag(input: $input, condition: $condition) {
      id
      text
      type
      athletes {
        items {
          id
          athleteID
          tagID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coaches_owners {
        items {
          id
          coachID
          tagID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAthleteTag = /* GraphQL */ `
  mutation DeleteAthleteTag(
    $input: DeleteAthleteTagInput!
    $condition: ModelAthleteTagConditionInput
  ) {
    deleteAthleteTag(input: $input, condition: $condition) {
      id
      text
      type
      athletes {
        items {
          id
          athleteID
          tagID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coaches_owners {
        items {
          id
          coachID
          tagID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCoachTagsRelation = /* GraphQL */ `
  mutation CreateCoachTagsRelation(
    $input: CreateCoachTagsRelationInput!
    $condition: ModelCoachTagsRelationConditionInput
  ) {
    createCoachTagsRelation(input: $input, condition: $condition) {
      id
      coachID
      tagID
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
          __typename
        }
        my_filters {
          nextToken
          __typename
        }
        my_favorite_folders {
          nextToken
          __typename
        }
        my_notifications {
          nextToken
          __typename
        }
        showcases_interested_in {
          nextToken
          __typename
        }
        my_athlete_tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          had_trial
          collegeID
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      tag {
        id
        text
        type
        athletes {
          nextToken
          __typename
        }
        coaches_owners {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCoachTagsRelation = /* GraphQL */ `
  mutation UpdateCoachTagsRelation(
    $input: UpdateCoachTagsRelationInput!
    $condition: ModelCoachTagsRelationConditionInput
  ) {
    updateCoachTagsRelation(input: $input, condition: $condition) {
      id
      coachID
      tagID
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
          __typename
        }
        my_filters {
          nextToken
          __typename
        }
        my_favorite_folders {
          nextToken
          __typename
        }
        my_notifications {
          nextToken
          __typename
        }
        showcases_interested_in {
          nextToken
          __typename
        }
        my_athlete_tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          had_trial
          collegeID
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      tag {
        id
        text
        type
        athletes {
          nextToken
          __typename
        }
        coaches_owners {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCoachTagsRelation = /* GraphQL */ `
  mutation DeleteCoachTagsRelation(
    $input: DeleteCoachTagsRelationInput!
    $condition: ModelCoachTagsRelationConditionInput
  ) {
    deleteCoachTagsRelation(input: $input, condition: $condition) {
      id
      coachID
      tagID
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
          __typename
        }
        my_filters {
          nextToken
          __typename
        }
        my_favorite_folders {
          nextToken
          __typename
        }
        my_notifications {
          nextToken
          __typename
        }
        showcases_interested_in {
          nextToken
          __typename
        }
        my_athlete_tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          had_trial
          collegeID
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      tag {
        id
        text
        type
        athletes {
          nextToken
          __typename
        }
        coaches_owners {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAthleteTagsRelation = /* GraphQL */ `
  mutation CreateAthleteTagsRelation(
    $input: CreateAthleteTagsRelationInput!
    $condition: ModelAthleteTagsRelationConditionInput
  ) {
    createAthleteTagsRelation(input: $input, condition: $condition) {
      id
      athleteID
      tagID
      athlete {
        id
        agencyID
        agentID
        name
        isVisible
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        nationality
        country_of_competition
        target_division
        compliance
        card_pic
        other_nice_pics
        agency_assesment
        elegibility_notes
        current_club
        current_status
        start_availability_date
        end_availability_date
        destination_college
        ncaa_id
        type_of_admission
        weight
        height
        sport
        tennis_profile {
          utr
          national_ranking
          international_ranking
          national_ranking_adult
          international_ranking_adult
          DominantHand
          __typename
        }
        position
        second_position
        dominant_foot
        featured_video {
          url
          metadata
          __typename
        }
        other_videos {
          url
          metadata
          __typename
        }
        level_of_competition
        sports_background
        showcase_participation {
          showcase_name
          city
          country
          date
          __typename
        }
        start_date
        high_school_graduation_date
        sat_score
        sat_date
        act_score
        act_date
        gemat_gre_score
        gemat_gre_date
        toefl_score
        toefl_date
        duolingo
        duolingo_date
        ielts
        ielts_date
        estimated_gpa
        intended_field_of_studies
        intended_degree
        budget
        category
        facebook_url
        twitter_user
        linkedin_url
        reason_commitment
        date_commitment
        agency {
          id
          name
          logo
          country
          contact_email
          contact_phone
          website
          sport
          createdAt
          updatedAt
          __typename
        }
        agent {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          agent_role
          agencyID
          createdAt
          updatedAt
          __typename
        }
        favorited {
          nextToken
          __typename
        }
        enquiries {
          nextToken
          __typename
        }
        tags {
          nextToken
          __typename
        }
        showcases_participation {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      tag {
        id
        text
        type
        athletes {
          nextToken
          __typename
        }
        coaches_owners {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAthleteTagsRelation = /* GraphQL */ `
  mutation UpdateAthleteTagsRelation(
    $input: UpdateAthleteTagsRelationInput!
    $condition: ModelAthleteTagsRelationConditionInput
  ) {
    updateAthleteTagsRelation(input: $input, condition: $condition) {
      id
      athleteID
      tagID
      athlete {
        id
        agencyID
        agentID
        name
        isVisible
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        nationality
        country_of_competition
        target_division
        compliance
        card_pic
        other_nice_pics
        agency_assesment
        elegibility_notes
        current_club
        current_status
        start_availability_date
        end_availability_date
        destination_college
        ncaa_id
        type_of_admission
        weight
        height
        sport
        tennis_profile {
          utr
          national_ranking
          international_ranking
          national_ranking_adult
          international_ranking_adult
          DominantHand
          __typename
        }
        position
        second_position
        dominant_foot
        featured_video {
          url
          metadata
          __typename
        }
        other_videos {
          url
          metadata
          __typename
        }
        level_of_competition
        sports_background
        showcase_participation {
          showcase_name
          city
          country
          date
          __typename
        }
        start_date
        high_school_graduation_date
        sat_score
        sat_date
        act_score
        act_date
        gemat_gre_score
        gemat_gre_date
        toefl_score
        toefl_date
        duolingo
        duolingo_date
        ielts
        ielts_date
        estimated_gpa
        intended_field_of_studies
        intended_degree
        budget
        category
        facebook_url
        twitter_user
        linkedin_url
        reason_commitment
        date_commitment
        agency {
          id
          name
          logo
          country
          contact_email
          contact_phone
          website
          sport
          createdAt
          updatedAt
          __typename
        }
        agent {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          agent_role
          agencyID
          createdAt
          updatedAt
          __typename
        }
        favorited {
          nextToken
          __typename
        }
        enquiries {
          nextToken
          __typename
        }
        tags {
          nextToken
          __typename
        }
        showcases_participation {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      tag {
        id
        text
        type
        athletes {
          nextToken
          __typename
        }
        coaches_owners {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAthleteTagsRelation = /* GraphQL */ `
  mutation DeleteAthleteTagsRelation(
    $input: DeleteAthleteTagsRelationInput!
    $condition: ModelAthleteTagsRelationConditionInput
  ) {
    deleteAthleteTagsRelation(input: $input, condition: $condition) {
      id
      athleteID
      tagID
      athlete {
        id
        agencyID
        agentID
        name
        isVisible
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        nationality
        country_of_competition
        target_division
        compliance
        card_pic
        other_nice_pics
        agency_assesment
        elegibility_notes
        current_club
        current_status
        start_availability_date
        end_availability_date
        destination_college
        ncaa_id
        type_of_admission
        weight
        height
        sport
        tennis_profile {
          utr
          national_ranking
          international_ranking
          national_ranking_adult
          international_ranking_adult
          DominantHand
          __typename
        }
        position
        second_position
        dominant_foot
        featured_video {
          url
          metadata
          __typename
        }
        other_videos {
          url
          metadata
          __typename
        }
        level_of_competition
        sports_background
        showcase_participation {
          showcase_name
          city
          country
          date
          __typename
        }
        start_date
        high_school_graduation_date
        sat_score
        sat_date
        act_score
        act_date
        gemat_gre_score
        gemat_gre_date
        toefl_score
        toefl_date
        duolingo
        duolingo_date
        ielts
        ielts_date
        estimated_gpa
        intended_field_of_studies
        intended_degree
        budget
        category
        facebook_url
        twitter_user
        linkedin_url
        reason_commitment
        date_commitment
        agency {
          id
          name
          logo
          country
          contact_email
          contact_phone
          website
          sport
          createdAt
          updatedAt
          __typename
        }
        agent {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          agent_role
          agencyID
          createdAt
          updatedAt
          __typename
        }
        favorited {
          nextToken
          __typename
        }
        enquiries {
          nextToken
          __typename
        }
        tags {
          nextToken
          __typename
        }
        showcases_participation {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      tag {
        id
        text
        type
        athletes {
          nextToken
          __typename
        }
        coaches_owners {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAthleteEnquiry = /* GraphQL */ `
  mutation CreateAthleteEnquiry(
    $input: CreateAthleteEnquiryInput!
    $condition: ModelAthleteEnquiryConditionInput
  ) {
    createAthleteEnquiry(input: $input, condition: $condition) {
      id
      enquiry_text
      athleteID
      coachID
      athlete {
        id
        agencyID
        agentID
        name
        isVisible
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        nationality
        country_of_competition
        target_division
        compliance
        card_pic
        other_nice_pics
        agency_assesment
        elegibility_notes
        current_club
        current_status
        start_availability_date
        end_availability_date
        destination_college
        ncaa_id
        type_of_admission
        weight
        height
        sport
        tennis_profile {
          utr
          national_ranking
          international_ranking
          national_ranking_adult
          international_ranking_adult
          DominantHand
          __typename
        }
        position
        second_position
        dominant_foot
        featured_video {
          url
          metadata
          __typename
        }
        other_videos {
          url
          metadata
          __typename
        }
        level_of_competition
        sports_background
        showcase_participation {
          showcase_name
          city
          country
          date
          __typename
        }
        start_date
        high_school_graduation_date
        sat_score
        sat_date
        act_score
        act_date
        gemat_gre_score
        gemat_gre_date
        toefl_score
        toefl_date
        duolingo
        duolingo_date
        ielts
        ielts_date
        estimated_gpa
        intended_field_of_studies
        intended_degree
        budget
        category
        facebook_url
        twitter_user
        linkedin_url
        reason_commitment
        date_commitment
        agency {
          id
          name
          logo
          country
          contact_email
          contact_phone
          website
          sport
          createdAt
          updatedAt
          __typename
        }
        agent {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          agent_role
          agencyID
          createdAt
          updatedAt
          __typename
        }
        favorited {
          nextToken
          __typename
        }
        enquiries {
          nextToken
          __typename
        }
        tags {
          nextToken
          __typename
        }
        showcases_participation {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
          __typename
        }
        my_filters {
          nextToken
          __typename
        }
        my_favorite_folders {
          nextToken
          __typename
        }
        my_notifications {
          nextToken
          __typename
        }
        showcases_interested_in {
          nextToken
          __typename
        }
        my_athlete_tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          had_trial
          collegeID
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAthleteEnquiry = /* GraphQL */ `
  mutation UpdateAthleteEnquiry(
    $input: UpdateAthleteEnquiryInput!
    $condition: ModelAthleteEnquiryConditionInput
  ) {
    updateAthleteEnquiry(input: $input, condition: $condition) {
      id
      enquiry_text
      athleteID
      coachID
      athlete {
        id
        agencyID
        agentID
        name
        isVisible
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        nationality
        country_of_competition
        target_division
        compliance
        card_pic
        other_nice_pics
        agency_assesment
        elegibility_notes
        current_club
        current_status
        start_availability_date
        end_availability_date
        destination_college
        ncaa_id
        type_of_admission
        weight
        height
        sport
        tennis_profile {
          utr
          national_ranking
          international_ranking
          national_ranking_adult
          international_ranking_adult
          DominantHand
          __typename
        }
        position
        second_position
        dominant_foot
        featured_video {
          url
          metadata
          __typename
        }
        other_videos {
          url
          metadata
          __typename
        }
        level_of_competition
        sports_background
        showcase_participation {
          showcase_name
          city
          country
          date
          __typename
        }
        start_date
        high_school_graduation_date
        sat_score
        sat_date
        act_score
        act_date
        gemat_gre_score
        gemat_gre_date
        toefl_score
        toefl_date
        duolingo
        duolingo_date
        ielts
        ielts_date
        estimated_gpa
        intended_field_of_studies
        intended_degree
        budget
        category
        facebook_url
        twitter_user
        linkedin_url
        reason_commitment
        date_commitment
        agency {
          id
          name
          logo
          country
          contact_email
          contact_phone
          website
          sport
          createdAt
          updatedAt
          __typename
        }
        agent {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          agent_role
          agencyID
          createdAt
          updatedAt
          __typename
        }
        favorited {
          nextToken
          __typename
        }
        enquiries {
          nextToken
          __typename
        }
        tags {
          nextToken
          __typename
        }
        showcases_participation {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
          __typename
        }
        my_filters {
          nextToken
          __typename
        }
        my_favorite_folders {
          nextToken
          __typename
        }
        my_notifications {
          nextToken
          __typename
        }
        showcases_interested_in {
          nextToken
          __typename
        }
        my_athlete_tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          had_trial
          collegeID
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAthleteEnquiry = /* GraphQL */ `
  mutation DeleteAthleteEnquiry(
    $input: DeleteAthleteEnquiryInput!
    $condition: ModelAthleteEnquiryConditionInput
  ) {
    deleteAthleteEnquiry(input: $input, condition: $condition) {
      id
      enquiry_text
      athleteID
      coachID
      athlete {
        id
        agencyID
        agentID
        name
        isVisible
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        nationality
        country_of_competition
        target_division
        compliance
        card_pic
        other_nice_pics
        agency_assesment
        elegibility_notes
        current_club
        current_status
        start_availability_date
        end_availability_date
        destination_college
        ncaa_id
        type_of_admission
        weight
        height
        sport
        tennis_profile {
          utr
          national_ranking
          international_ranking
          national_ranking_adult
          international_ranking_adult
          DominantHand
          __typename
        }
        position
        second_position
        dominant_foot
        featured_video {
          url
          metadata
          __typename
        }
        other_videos {
          url
          metadata
          __typename
        }
        level_of_competition
        sports_background
        showcase_participation {
          showcase_name
          city
          country
          date
          __typename
        }
        start_date
        high_school_graduation_date
        sat_score
        sat_date
        act_score
        act_date
        gemat_gre_score
        gemat_gre_date
        toefl_score
        toefl_date
        duolingo
        duolingo_date
        ielts
        ielts_date
        estimated_gpa
        intended_field_of_studies
        intended_degree
        budget
        category
        facebook_url
        twitter_user
        linkedin_url
        reason_commitment
        date_commitment
        agency {
          id
          name
          logo
          country
          contact_email
          contact_phone
          website
          sport
          createdAt
          updatedAt
          __typename
        }
        agent {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          agent_role
          agencyID
          createdAt
          updatedAt
          __typename
        }
        favorited {
          nextToken
          __typename
        }
        enquiries {
          nextToken
          __typename
        }
        tags {
          nextToken
          __typename
        }
        showcases_participation {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
          __typename
        }
        my_filters {
          nextToken
          __typename
        }
        my_favorite_folders {
          nextToken
          __typename
        }
        my_notifications {
          nextToken
          __typename
        }
        showcases_interested_in {
          nextToken
          __typename
        }
        my_athlete_tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          had_trial
          collegeID
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createFeedback = /* GraphQL */ `
  mutation CreateFeedback(
    $input: CreateFeedbackInput!
    $condition: ModelFeedbackConditionInput
  ) {
    createFeedback(input: $input, condition: $condition) {
      id
      feedback_text
      type
      coachID
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
          __typename
        }
        my_filters {
          nextToken
          __typename
        }
        my_favorite_folders {
          nextToken
          __typename
        }
        my_notifications {
          nextToken
          __typename
        }
        showcases_interested_in {
          nextToken
          __typename
        }
        my_athlete_tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          had_trial
          collegeID
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateFeedback = /* GraphQL */ `
  mutation UpdateFeedback(
    $input: UpdateFeedbackInput!
    $condition: ModelFeedbackConditionInput
  ) {
    updateFeedback(input: $input, condition: $condition) {
      id
      feedback_text
      type
      coachID
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
          __typename
        }
        my_filters {
          nextToken
          __typename
        }
        my_favorite_folders {
          nextToken
          __typename
        }
        my_notifications {
          nextToken
          __typename
        }
        showcases_interested_in {
          nextToken
          __typename
        }
        my_athlete_tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          had_trial
          collegeID
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteFeedback = /* GraphQL */ `
  mutation DeleteFeedback(
    $input: DeleteFeedbackInput!
    $condition: ModelFeedbackConditionInput
  ) {
    deleteFeedback(input: $input, condition: $condition) {
      id
      feedback_text
      type
      coachID
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
          __typename
        }
        my_filters {
          nextToken
          __typename
        }
        my_favorite_folders {
          nextToken
          __typename
        }
        my_notifications {
          nextToken
          __typename
        }
        showcases_interested_in {
          nextToken
          __typename
        }
        my_athlete_tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          had_trial
          collegeID
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSearchTerm = /* GraphQL */ `
  mutation CreateSearchTerm(
    $input: CreateSearchTermInput!
    $condition: ModelSearchTermConditionInput
  ) {
    createSearchTerm(input: $input, condition: $condition) {
      id
      search_term_text
      search_type
      sport
      source_app
      coachID
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
          __typename
        }
        my_filters {
          nextToken
          __typename
        }
        my_favorite_folders {
          nextToken
          __typename
        }
        my_notifications {
          nextToken
          __typename
        }
        showcases_interested_in {
          nextToken
          __typename
        }
        my_athlete_tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          had_trial
          collegeID
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSearchTerm = /* GraphQL */ `
  mutation UpdateSearchTerm(
    $input: UpdateSearchTermInput!
    $condition: ModelSearchTermConditionInput
  ) {
    updateSearchTerm(input: $input, condition: $condition) {
      id
      search_term_text
      search_type
      sport
      source_app
      coachID
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
          __typename
        }
        my_filters {
          nextToken
          __typename
        }
        my_favorite_folders {
          nextToken
          __typename
        }
        my_notifications {
          nextToken
          __typename
        }
        showcases_interested_in {
          nextToken
          __typename
        }
        my_athlete_tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          had_trial
          collegeID
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSearchTerm = /* GraphQL */ `
  mutation DeleteSearchTerm(
    $input: DeleteSearchTermInput!
    $condition: ModelSearchTermConditionInput
  ) {
    deleteSearchTerm(input: $input, condition: $condition) {
      id
      search_term_text
      search_type
      sport
      source_app
      coachID
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
          __typename
        }
        my_filters {
          nextToken
          __typename
        }
        my_favorite_folders {
          nextToken
          __typename
        }
        my_notifications {
          nextToken
          __typename
        }
        showcases_interested_in {
          nextToken
          __typename
        }
        my_athlete_tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          had_trial
          collegeID
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSearchFilter = /* GraphQL */ `
  mutation CreateSearchFilter(
    $input: CreateSearchFilterInput!
    $condition: ModelSearchFilterConditionInput
  ) {
    createSearchFilter(input: $input, condition: $condition) {
      id
      name
      filter_body
      type
      sport
      source_app
      coachID
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
          __typename
        }
        my_filters {
          nextToken
          __typename
        }
        my_favorite_folders {
          nextToken
          __typename
        }
        my_notifications {
          nextToken
          __typename
        }
        showcases_interested_in {
          nextToken
          __typename
        }
        my_athlete_tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          had_trial
          collegeID
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSearchFilter = /* GraphQL */ `
  mutation UpdateSearchFilter(
    $input: UpdateSearchFilterInput!
    $condition: ModelSearchFilterConditionInput
  ) {
    updateSearchFilter(input: $input, condition: $condition) {
      id
      name
      filter_body
      type
      sport
      source_app
      coachID
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
          __typename
        }
        my_filters {
          nextToken
          __typename
        }
        my_favorite_folders {
          nextToken
          __typename
        }
        my_notifications {
          nextToken
          __typename
        }
        showcases_interested_in {
          nextToken
          __typename
        }
        my_athlete_tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          had_trial
          collegeID
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSearchFilter = /* GraphQL */ `
  mutation DeleteSearchFilter(
    $input: DeleteSearchFilterInput!
    $condition: ModelSearchFilterConditionInput
  ) {
    deleteSearchFilter(input: $input, condition: $condition) {
      id
      name
      filter_body
      type
      sport
      source_app
      coachID
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
          __typename
        }
        my_filters {
          nextToken
          __typename
        }
        my_favorite_folders {
          nextToken
          __typename
        }
        my_notifications {
          nextToken
          __typename
        }
        showcases_interested_in {
          nextToken
          __typename
        }
        my_athlete_tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          had_trial
          collegeID
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createNotification = /* GraphQL */ `
  mutation CreateNotification(
    $input: CreateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    createNotification(input: $input, condition: $condition) {
      id
      title
      text
      status
      type
      pic
      attachment
      coaches_notified {
        items {
          id
          is_viewed
          status
          notificationID
          coachID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateNotification = /* GraphQL */ `
  mutation UpdateNotification(
    $input: UpdateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    updateNotification(input: $input, condition: $condition) {
      id
      title
      text
      status
      type
      pic
      attachment
      coaches_notified {
        items {
          id
          is_viewed
          status
          notificationID
          coachID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteNotification = /* GraphQL */ `
  mutation DeleteNotification(
    $input: DeleteNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    deleteNotification(input: $input, condition: $condition) {
      id
      title
      text
      status
      type
      pic
      attachment
      coaches_notified {
        items {
          id
          is_viewed
          status
          notificationID
          coachID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCoachNotificationRelation = /* GraphQL */ `
  mutation CreateCoachNotificationRelation(
    $input: CreateCoachNotificationRelationInput!
    $condition: ModelCoachNotificationRelationConditionInput
  ) {
    createCoachNotificationRelation(input: $input, condition: $condition) {
      id
      is_viewed
      status
      notificationID
      coachID
      notification {
        id
        title
        text
        status
        type
        pic
        attachment
        coaches_notified {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
          __typename
        }
        my_filters {
          nextToken
          __typename
        }
        my_favorite_folders {
          nextToken
          __typename
        }
        my_notifications {
          nextToken
          __typename
        }
        showcases_interested_in {
          nextToken
          __typename
        }
        my_athlete_tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          had_trial
          collegeID
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCoachNotificationRelation = /* GraphQL */ `
  mutation UpdateCoachNotificationRelation(
    $input: UpdateCoachNotificationRelationInput!
    $condition: ModelCoachNotificationRelationConditionInput
  ) {
    updateCoachNotificationRelation(input: $input, condition: $condition) {
      id
      is_viewed
      status
      notificationID
      coachID
      notification {
        id
        title
        text
        status
        type
        pic
        attachment
        coaches_notified {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
          __typename
        }
        my_filters {
          nextToken
          __typename
        }
        my_favorite_folders {
          nextToken
          __typename
        }
        my_notifications {
          nextToken
          __typename
        }
        showcases_interested_in {
          nextToken
          __typename
        }
        my_athlete_tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          had_trial
          collegeID
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCoachNotificationRelation = /* GraphQL */ `
  mutation DeleteCoachNotificationRelation(
    $input: DeleteCoachNotificationRelationInput!
    $condition: ModelCoachNotificationRelationConditionInput
  ) {
    deleteCoachNotificationRelation(input: $input, condition: $condition) {
      id
      is_viewed
      status
      notificationID
      coachID
      notification {
        id
        title
        text
        status
        type
        pic
        attachment
        coaches_notified {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
          __typename
        }
        my_filters {
          nextToken
          __typename
        }
        my_favorite_folders {
          nextToken
          __typename
        }
        my_notifications {
          nextToken
          __typename
        }
        showcases_interested_in {
          nextToken
          __typename
        }
        my_athlete_tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          had_trial
          collegeID
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAgent = /* GraphQL */ `
  mutation CreateAgent(
    $input: CreateAgentInput!
    $condition: ModelAgentConditionInput
  ) {
    createAgent(input: $input, condition: $condition) {
      id
      name
      profile_pic
      birthdate
      gender
      contact_email
      contact_phone
      agent_role
      agencyID
      agency {
        items {
          id
          name
          logo
          country
          contact_email
          contact_phone
          website
          sport
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      ahtletes {
        items {
          id
          agencyID
          agentID
          name
          isVisible
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          nationality
          country_of_competition
          target_division
          compliance
          card_pic
          other_nice_pics
          agency_assesment
          elegibility_notes
          current_club
          current_status
          start_availability_date
          end_availability_date
          destination_college
          ncaa_id
          type_of_admission
          weight
          height
          sport
          position
          second_position
          dominant_foot
          level_of_competition
          sports_background
          start_date
          high_school_graduation_date
          sat_score
          sat_date
          act_score
          act_date
          gemat_gre_score
          gemat_gre_date
          toefl_score
          toefl_date
          duolingo
          duolingo_date
          ielts
          ielts_date
          estimated_gpa
          intended_field_of_studies
          intended_degree
          budget
          category
          facebook_url
          twitter_user
          linkedin_url
          reason_commitment
          date_commitment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAgent = /* GraphQL */ `
  mutation UpdateAgent(
    $input: UpdateAgentInput!
    $condition: ModelAgentConditionInput
  ) {
    updateAgent(input: $input, condition: $condition) {
      id
      name
      profile_pic
      birthdate
      gender
      contact_email
      contact_phone
      agent_role
      agencyID
      agency {
        items {
          id
          name
          logo
          country
          contact_email
          contact_phone
          website
          sport
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      ahtletes {
        items {
          id
          agencyID
          agentID
          name
          isVisible
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          nationality
          country_of_competition
          target_division
          compliance
          card_pic
          other_nice_pics
          agency_assesment
          elegibility_notes
          current_club
          current_status
          start_availability_date
          end_availability_date
          destination_college
          ncaa_id
          type_of_admission
          weight
          height
          sport
          position
          second_position
          dominant_foot
          level_of_competition
          sports_background
          start_date
          high_school_graduation_date
          sat_score
          sat_date
          act_score
          act_date
          gemat_gre_score
          gemat_gre_date
          toefl_score
          toefl_date
          duolingo
          duolingo_date
          ielts
          ielts_date
          estimated_gpa
          intended_field_of_studies
          intended_degree
          budget
          category
          facebook_url
          twitter_user
          linkedin_url
          reason_commitment
          date_commitment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAgent = /* GraphQL */ `
  mutation DeleteAgent(
    $input: DeleteAgentInput!
    $condition: ModelAgentConditionInput
  ) {
    deleteAgent(input: $input, condition: $condition) {
      id
      name
      profile_pic
      birthdate
      gender
      contact_email
      contact_phone
      agent_role
      agencyID
      agency {
        items {
          id
          name
          logo
          country
          contact_email
          contact_phone
          website
          sport
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      ahtletes {
        items {
          id
          agencyID
          agentID
          name
          isVisible
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          nationality
          country_of_competition
          target_division
          compliance
          card_pic
          other_nice_pics
          agency_assesment
          elegibility_notes
          current_club
          current_status
          start_availability_date
          end_availability_date
          destination_college
          ncaa_id
          type_of_admission
          weight
          height
          sport
          position
          second_position
          dominant_foot
          level_of_competition
          sports_background
          start_date
          high_school_graduation_date
          sat_score
          sat_date
          act_score
          act_date
          gemat_gre_score
          gemat_gre_date
          toefl_score
          toefl_date
          duolingo
          duolingo_date
          ielts
          ielts_date
          estimated_gpa
          intended_field_of_studies
          intended_degree
          budget
          category
          facebook_url
          twitter_user
          linkedin_url
          reason_commitment
          date_commitment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createShowcase = /* GraphQL */ `
  mutation CreateShowcase(
    $input: CreateShowcaseInput!
    $condition: ModelShowcaseConditionInput
  ) {
    createShowcase(input: $input, condition: $condition) {
      id
      name
      description
      contry
      address
      date
      status
      coaches_interested {
        items {
          id
          showcaseID
          coachID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      featured_athletes {
        items {
          id
          showcaseID
          athleteID
          color
          number
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateShowcase = /* GraphQL */ `
  mutation UpdateShowcase(
    $input: UpdateShowcaseInput!
    $condition: ModelShowcaseConditionInput
  ) {
    updateShowcase(input: $input, condition: $condition) {
      id
      name
      description
      contry
      address
      date
      status
      coaches_interested {
        items {
          id
          showcaseID
          coachID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      featured_athletes {
        items {
          id
          showcaseID
          athleteID
          color
          number
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteShowcase = /* GraphQL */ `
  mutation DeleteShowcase(
    $input: DeleteShowcaseInput!
    $condition: ModelShowcaseConditionInput
  ) {
    deleteShowcase(input: $input, condition: $condition) {
      id
      name
      description
      contry
      address
      date
      status
      coaches_interested {
        items {
          id
          showcaseID
          coachID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      featured_athletes {
        items {
          id
          showcaseID
          athleteID
          color
          number
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createShowcaseCoachRelation = /* GraphQL */ `
  mutation CreateShowcaseCoachRelation(
    $input: CreateShowcaseCoachRelationInput!
    $condition: ModelShowcaseCoachRelationConditionInput
  ) {
    createShowcaseCoachRelation(input: $input, condition: $condition) {
      id
      showcaseID
      coachID
      showcase {
        id
        name
        description
        contry
        address
        date
        status
        coaches_interested {
          nextToken
          __typename
        }
        featured_athletes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
          __typename
        }
        my_filters {
          nextToken
          __typename
        }
        my_favorite_folders {
          nextToken
          __typename
        }
        my_notifications {
          nextToken
          __typename
        }
        showcases_interested_in {
          nextToken
          __typename
        }
        my_athlete_tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          had_trial
          collegeID
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateShowcaseCoachRelation = /* GraphQL */ `
  mutation UpdateShowcaseCoachRelation(
    $input: UpdateShowcaseCoachRelationInput!
    $condition: ModelShowcaseCoachRelationConditionInput
  ) {
    updateShowcaseCoachRelation(input: $input, condition: $condition) {
      id
      showcaseID
      coachID
      showcase {
        id
        name
        description
        contry
        address
        date
        status
        coaches_interested {
          nextToken
          __typename
        }
        featured_athletes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
          __typename
        }
        my_filters {
          nextToken
          __typename
        }
        my_favorite_folders {
          nextToken
          __typename
        }
        my_notifications {
          nextToken
          __typename
        }
        showcases_interested_in {
          nextToken
          __typename
        }
        my_athlete_tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          had_trial
          collegeID
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteShowcaseCoachRelation = /* GraphQL */ `
  mutation DeleteShowcaseCoachRelation(
    $input: DeleteShowcaseCoachRelationInput!
    $condition: ModelShowcaseCoachRelationConditionInput
  ) {
    deleteShowcaseCoachRelation(input: $input, condition: $condition) {
      id
      showcaseID
      coachID
      showcase {
        id
        name
        description
        contry
        address
        date
        status
        coaches_interested {
          nextToken
          __typename
        }
        featured_athletes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
          __typename
        }
        my_filters {
          nextToken
          __typename
        }
        my_favorite_folders {
          nextToken
          __typename
        }
        my_notifications {
          nextToken
          __typename
        }
        showcases_interested_in {
          nextToken
          __typename
        }
        my_athlete_tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          had_trial
          collegeID
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createShowcaseAthleteRelation = /* GraphQL */ `
  mutation CreateShowcaseAthleteRelation(
    $input: CreateShowcaseAthleteRelationInput!
    $condition: ModelShowcaseAthleteRelationConditionInput
  ) {
    createShowcaseAthleteRelation(input: $input, condition: $condition) {
      id
      showcaseID
      athleteID
      color
      number
      showcase {
        id
        name
        description
        contry
        address
        date
        status
        coaches_interested {
          nextToken
          __typename
        }
        featured_athletes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      athlete {
        id
        agencyID
        agentID
        name
        isVisible
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        nationality
        country_of_competition
        target_division
        compliance
        card_pic
        other_nice_pics
        agency_assesment
        elegibility_notes
        current_club
        current_status
        start_availability_date
        end_availability_date
        destination_college
        ncaa_id
        type_of_admission
        weight
        height
        sport
        tennis_profile {
          utr
          national_ranking
          international_ranking
          national_ranking_adult
          international_ranking_adult
          DominantHand
          __typename
        }
        position
        second_position
        dominant_foot
        featured_video {
          url
          metadata
          __typename
        }
        other_videos {
          url
          metadata
          __typename
        }
        level_of_competition
        sports_background
        showcase_participation {
          showcase_name
          city
          country
          date
          __typename
        }
        start_date
        high_school_graduation_date
        sat_score
        sat_date
        act_score
        act_date
        gemat_gre_score
        gemat_gre_date
        toefl_score
        toefl_date
        duolingo
        duolingo_date
        ielts
        ielts_date
        estimated_gpa
        intended_field_of_studies
        intended_degree
        budget
        category
        facebook_url
        twitter_user
        linkedin_url
        reason_commitment
        date_commitment
        agency {
          id
          name
          logo
          country
          contact_email
          contact_phone
          website
          sport
          createdAt
          updatedAt
          __typename
        }
        agent {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          agent_role
          agencyID
          createdAt
          updatedAt
          __typename
        }
        favorited {
          nextToken
          __typename
        }
        enquiries {
          nextToken
          __typename
        }
        tags {
          nextToken
          __typename
        }
        showcases_participation {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateShowcaseAthleteRelation = /* GraphQL */ `
  mutation UpdateShowcaseAthleteRelation(
    $input: UpdateShowcaseAthleteRelationInput!
    $condition: ModelShowcaseAthleteRelationConditionInput
  ) {
    updateShowcaseAthleteRelation(input: $input, condition: $condition) {
      id
      showcaseID
      athleteID
      color
      number
      showcase {
        id
        name
        description
        contry
        address
        date
        status
        coaches_interested {
          nextToken
          __typename
        }
        featured_athletes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      athlete {
        id
        agencyID
        agentID
        name
        isVisible
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        nationality
        country_of_competition
        target_division
        compliance
        card_pic
        other_nice_pics
        agency_assesment
        elegibility_notes
        current_club
        current_status
        start_availability_date
        end_availability_date
        destination_college
        ncaa_id
        type_of_admission
        weight
        height
        sport
        tennis_profile {
          utr
          national_ranking
          international_ranking
          national_ranking_adult
          international_ranking_adult
          DominantHand
          __typename
        }
        position
        second_position
        dominant_foot
        featured_video {
          url
          metadata
          __typename
        }
        other_videos {
          url
          metadata
          __typename
        }
        level_of_competition
        sports_background
        showcase_participation {
          showcase_name
          city
          country
          date
          __typename
        }
        start_date
        high_school_graduation_date
        sat_score
        sat_date
        act_score
        act_date
        gemat_gre_score
        gemat_gre_date
        toefl_score
        toefl_date
        duolingo
        duolingo_date
        ielts
        ielts_date
        estimated_gpa
        intended_field_of_studies
        intended_degree
        budget
        category
        facebook_url
        twitter_user
        linkedin_url
        reason_commitment
        date_commitment
        agency {
          id
          name
          logo
          country
          contact_email
          contact_phone
          website
          sport
          createdAt
          updatedAt
          __typename
        }
        agent {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          agent_role
          agencyID
          createdAt
          updatedAt
          __typename
        }
        favorited {
          nextToken
          __typename
        }
        enquiries {
          nextToken
          __typename
        }
        tags {
          nextToken
          __typename
        }
        showcases_participation {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteShowcaseAthleteRelation = /* GraphQL */ `
  mutation DeleteShowcaseAthleteRelation(
    $input: DeleteShowcaseAthleteRelationInput!
    $condition: ModelShowcaseAthleteRelationConditionInput
  ) {
    deleteShowcaseAthleteRelation(input: $input, condition: $condition) {
      id
      showcaseID
      athleteID
      color
      number
      showcase {
        id
        name
        description
        contry
        address
        date
        status
        coaches_interested {
          nextToken
          __typename
        }
        featured_athletes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      athlete {
        id
        agencyID
        agentID
        name
        isVisible
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        nationality
        country_of_competition
        target_division
        compliance
        card_pic
        other_nice_pics
        agency_assesment
        elegibility_notes
        current_club
        current_status
        start_availability_date
        end_availability_date
        destination_college
        ncaa_id
        type_of_admission
        weight
        height
        sport
        tennis_profile {
          utr
          national_ranking
          international_ranking
          national_ranking_adult
          international_ranking_adult
          DominantHand
          __typename
        }
        position
        second_position
        dominant_foot
        featured_video {
          url
          metadata
          __typename
        }
        other_videos {
          url
          metadata
          __typename
        }
        level_of_competition
        sports_background
        showcase_participation {
          showcase_name
          city
          country
          date
          __typename
        }
        start_date
        high_school_graduation_date
        sat_score
        sat_date
        act_score
        act_date
        gemat_gre_score
        gemat_gre_date
        toefl_score
        toefl_date
        duolingo
        duolingo_date
        ielts
        ielts_date
        estimated_gpa
        intended_field_of_studies
        intended_degree
        budget
        category
        facebook_url
        twitter_user
        linkedin_url
        reason_commitment
        date_commitment
        agency {
          id
          name
          logo
          country
          contact_email
          contact_phone
          website
          sport
          createdAt
          updatedAt
          __typename
        }
        agent {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          agent_role
          agencyID
          createdAt
          updatedAt
          __typename
        }
        favorited {
          nextToken
          __typename
        }
        enquiries {
          nextToken
          __typename
        }
        tags {
          nextToken
          __typename
        }
        showcases_participation {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAuxCollegeCoachInfo = /* GraphQL */ `
  mutation CreateAuxCollegeCoachInfo(
    $input: CreateAuxCollegeCoachInfoInput!
    $condition: ModelAuxCollegeCoachInfoConditionInput
  ) {
    createAuxCollegeCoachInfo(input: $input, condition: $condition) {
      id
      conference
      state
      division
      uniqueID
      sport
      added
      removed
      school
      first_name
      last_name
      position
      contact_email
      contact_phone
      landing_pages
      individual_twitter
      team_twitter
      individual_facebook
      team_facebook
      individual_instagram
      team_instagram
      questionnaire
      school2
      state2
      city
      region
      size_of_city
      private_or_public
      religious
      hbcu
      average_gpa
      sat_reading
      sat_math
      act_composite
      acceptance_rate
      total_yearly_cost
      school3
      majors_offered
      number_of_undergrads
      us_ranking
      us_arts_ranking
      ipeds
      college_category
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAuxCollegeCoachInfo = /* GraphQL */ `
  mutation UpdateAuxCollegeCoachInfo(
    $input: UpdateAuxCollegeCoachInfoInput!
    $condition: ModelAuxCollegeCoachInfoConditionInput
  ) {
    updateAuxCollegeCoachInfo(input: $input, condition: $condition) {
      id
      conference
      state
      division
      uniqueID
      sport
      added
      removed
      school
      first_name
      last_name
      position
      contact_email
      contact_phone
      landing_pages
      individual_twitter
      team_twitter
      individual_facebook
      team_facebook
      individual_instagram
      team_instagram
      questionnaire
      school2
      state2
      city
      region
      size_of_city
      private_or_public
      religious
      hbcu
      average_gpa
      sat_reading
      sat_math
      act_composite
      acceptance_rate
      total_yearly_cost
      school3
      majors_offered
      number_of_undergrads
      us_ranking
      us_arts_ranking
      ipeds
      college_category
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAuxCollegeCoachInfo = /* GraphQL */ `
  mutation DeleteAuxCollegeCoachInfo(
    $input: DeleteAuxCollegeCoachInfoInput!
    $condition: ModelAuxCollegeCoachInfoConditionInput
  ) {
    deleteAuxCollegeCoachInfo(input: $input, condition: $condition) {
      id
      conference
      state
      division
      uniqueID
      sport
      added
      removed
      school
      first_name
      last_name
      position
      contact_email
      contact_phone
      landing_pages
      individual_twitter
      team_twitter
      individual_facebook
      team_facebook
      individual_instagram
      team_instagram
      questionnaire
      school2
      state2
      city
      region
      size_of_city
      private_or_public
      religious
      hbcu
      average_gpa
      sat_reading
      sat_math
      act_composite
      acceptance_rate
      total_yearly_cost
      school3
      majors_offered
      number_of_undergrads
      us_ranking
      us_arts_ranking
      ipeds
      college_category
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCoach = /* GraphQL */ `
  mutation CreateCoach(
    $input: CreateCoachInput!
    $condition: ModelCoachConditionInput
  ) {
    createCoach(input: $input, condition: $condition) {
      id
      name
      profile_pic
      birthdate
      gender
      contact_email
      contact_phone
      disabledByUser
      is_active
      allow_whatsapp_contact
      deleteAppReason
      division
      role
      sport
      teamID
      favorites {
        items {
          id
          athleteID
          coachID
          rating
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      my_filters {
        items {
          id
          name
          filter_body
          type
          sport
          source_app
          coachID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      my_favorite_folders {
        items {
          id
          folder_name
          status
          coachID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      my_notifications {
        items {
          id
          is_viewed
          status
          notificationID
          coachID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      showcases_interested_in {
        items {
          id
          showcaseID
          coachID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      my_athlete_tags {
        items {
          id
          coachID
          tagID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      team {
        id
        name
        sport
        genre
        suscription_name
        suscription_recurrency
        suscription_status
        suscription_stripe_id
        suscription_premium_start_date
        suscription_premium_end_date
        team_logo
        conference
        division
        college_category
        url_domain
        had_trial
        collegeID
        college {
          id
          name
          country
          state
          address
          logo
          createdAt
          updatedAt
          __typename
        }
        coaches {
          nextToken
          __typename
        }
        subscription_history {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
