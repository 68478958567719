import React, {useContext, useState, useEffect } from "react";
import { DataContext } from '../../contexts/dataContext';
import { useNavigate, useLocation } from 'react-router-dom'

// Chakra imports
import {
	Box,
	Flex,
	Button,
	FormControl,
	FormErrorMessage,
	Heading,
	Input,
	InputGroup,
	InputRightElement,
	IconButton,
	Link,
	Text,
	useColorModeValue,
	Alert,
	AlertIcon,
	AlertTitle,
	AlertDescription,
} from "@chakra-ui/react";
import {ViewIcon, ViewOffIcon} from "@chakra-ui/icons";
// Assets
import signInImage from "assets/img/signInImage.png";
import loginPage from "assets/img/loginPage.png";
// Auxiliary functions
import validator from "validator";

const initialFormState = {
    username: '',
    password: '',
    email: '',
    authCode: '',
    formType: 'SignIn',
}

function SignIn() {
	// Chakra color mode
	const titleColor = useColorModeValue('royalblue.700');
	const textColor = useColorModeValue("gray.400", "white");

	let navigate = useNavigate();
	const dataContext = useContext(DataContext)
    const { authSignIn, user, error, updateError } = dataContext
	const [formState, updateFormState] = useState(initialFormState)
	// State with show/hide password
	const [showPassword, setShowPassword] = useState(false)
	const {state} = useLocation()
	
    if (user !== 'notLoggedIn') {
        // If the user is logged in, redirect to the app
        navigate('/admin', { replace: true })
    }

	useEffect(() => {
		updateError('')
	}, [])

	const onChange = (e) => {
        e.persist()
        updateFormState(() => ({ ...formState, [e.target.name]: e.target.value }))
    }
	
    const handleLogin = (e) => {
        e.preventDefault()

		authSignIn(formState.username,formState.password)
		.then(res => {
			if(res.challengeName === 'NEW_PASSWORD_REQUIRED'){
				navigate('/auth/change-password', { state: { email: formState.username } })
			}
		})
        
    
    } 

	return (
		<Flex position='relative' mb='40px'>
			<Flex
				h={{ sm: "initial", md: "75vh", lg: "85vh" }}
				w='100%'
				maxW='1044px'
				mx='auto'
				justifyContent='space-between'
				mb='30px'
				pt={{ sm: "100px", md: "0px" }}
			>

				<Flex
					alignItems='center'
					justifyContent='start'
					style={{ userSelect: "none" }}
					w={{ base: "100%", md: "50%", lg: "42%" }}
				>

					<Flex
						direction='column'
						w='100%'
						background='transparent'
						p='48px'
						mt={{ md: "150px", lg: "80px" }}
					>

						<Heading color={titleColor} fontSize='32px' mb='10px'>
							Welcome Back
						</Heading>

						<Text
							mb='20px'
							ms='4px'
							color={textColor}
							fontWeight='bold'
							fontSize='14px'>
							Enter your email and password to sign in
						</Text>

						{error && (

							<Alert status='error' mb="15px" borderRadius="10px">
								<AlertIcon />
								<Box flex='1'>
									<AlertTitle>Sorry!</AlertTitle>
									<AlertDescription display='block'>
										{error}
									</AlertDescription>
								</Box>
							</Alert>
						)}

						{/* Success message when the user password has been reset correctly */}
						{state?.succesMessage && (

						<Alert status='success' mb="15px" borderRadius="10px">
							<AlertIcon />
							<Box flex='1'>
								<AlertTitle>Success!</AlertTitle>
								<AlertDescription display='block'>
									{state.succesMessage}
								</AlertDescription>
							</Box>
						</Alert>
						)}

							<FormControl isInvalid={formState.username !== '' && !validator.isEmail(formState.username)}>
								<Text ms='4px' fontSize='sm' fontWeight='normal'>
									Email
								</Text>
								<Input
									variant='flushed'
									fontSize='sm'
									type='text'
									placeholder='Your email adress'
									size='lg'
									value={ formState.username }
									name='username'
									onChange={onChange}
								/>
								{formState.username !== '' && !validator.isEmail(formState.username) && ( <FormErrorMessage>Please enter a correct email</FormErrorMessage> )}
							</FormControl>

							<FormControl isInvalid={formState.password !== '' && formState.password.length < 8}>
								<Text mt='10px' fontSize='sm' fontWeight='normal'>
									Password
								</Text>
								<InputGroup>
									<Input
										variant='flushed'
										fontSize='sm'
										type={showPassword ? 'text' : 'password'}
										placeholder='Your password'
										size='lg'
										value={formState.password}
										name='password'
										onChange={onChange}
									/>
									<InputRightElement>
										<IconButton
										colorScheme='gray'
										aria-label='Search database'
										icon={ showPassword ? <ViewOffIcon /> : <ViewIcon /> }
										onClick={() => setShowPassword(!showPassword)}
										/>
									</InputRightElement>
								</InputGroup>
								{formState.password !== '' && formState.password.length < 8 && ( <FormErrorMessage>Please enter a correct password</FormErrorMessage> )}
							</FormControl>

					
							<Flex
								flexDirection='column'
								maxW='100%'
								mt='20px'>
								<Text color={textColor} fontWeight='medium'>
									Forgot your password?
									<Link color={titleColor} as='span' ms='5px' onClick={()=>{navigate('/auth/forgot-password')}}>
										Reset it
									</Link>
								</Text>
							</Flex>

							<Button
								fontSize='14px'
								type='submit'
								bg='royalblue.700'
								w='100%'
								h='45'
								mb='20px'
								color='white'
								mt='20px'
								_hover={{ bg: "blue.200" }}
								_active={{ bg: "blue.400" }}
								onClick={handleLogin}
								isDisabled={formState.username === '' || !validator.isEmail(formState.username) || formState.password === '' || formState.password.length < 8}
							>
								SIGN IN
							</Button>

					</Flex>

				</Flex>
				<Box
					display={{ base: "none", md: "block" }}
					overflowX='hidden'
					h='100%'
					w='40vw'
					position='absolute'
					right='0px'>
					<Box
						bgImage={loginPage}
						w='100%'
						h='100%'
						bgSize='cover'
						position='absolute'
						borderBottomLeftRadius='20px'></Box>
				</Box>
			</Flex>
		</Flex>
	);
}

export default SignIn;
