/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCollege = /* GraphQL */ `
  query GetCollege($id: ID!) {
    getCollege(id: $id) {
      id
      name
      country
      state
      address
      logo
      createdAt
      updatedAt
      teams {
        items {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          had_trial
          collegeID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listColleges = /* GraphQL */ `
  query ListColleges(
    $filter: ModelCollegeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listColleges(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        country
        state
        address
        logo
        teams {
          items {
            id
            name
            sport
            genre
            suscription_name
            suscription_recurrency
            suscription_status
            suscription_stripe_id
            suscription_premium_start_date
            suscription_premium_end_date
            team_logo
            conference
            division
            college_category
            url_domain
            had_trial
            collegeID
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSubscriptionPeriod = /* GraphQL */ `
  query GetSubscriptionPeriod($id: ID!) {
    getSubscriptionPeriod(id: $id) {
      id
      start_date
      end_date
      suscription_name
      teamID
      createdAt
      updatedAt
      team {
        id
        name
        sport
        genre
        suscription_name
        suscription_recurrency
        suscription_status
        suscription_stripe_id
        suscription_premium_start_date
        suscription_premium_end_date
        team_logo
        conference
        division
        college_category
        url_domain
        had_trial
        collegeID
        college {
          id
          name
          country
          state
          address
          logo
          createdAt
          updatedAt
          __typename
        }
        coaches {
          nextToken
          __typename
        }
        subscription_history {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const listSubscriptionPeriods = /* GraphQL */ `
  query ListSubscriptionPeriods(
    $filter: ModelSubscriptionPeriodFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSubscriptionPeriods(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        start_date
        end_date
        suscription_name
        teamID
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          had_trial
          collegeID
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCoach = /* GraphQL */ `
  query GetCoach($id: ID!) {
    getCoach(id: $id) {
      id
      name
      profile_pic
      birthdate
      gender
      contact_email
      contact_phone
      disabledByUser
      is_active
      allow_whatsapp_contact
      deleteAppReason
      division
      role
      sport
      teamID
      favorites {
        items {
          id
          athleteID
          coachID
          rating
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      my_filters {
        items {
          id
          name
          filter_body
          type
          sport
          source_app
          coachID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      my_favorite_folders {
        items {
          id
          folder_name
          status
          coachID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      my_notifications {
        items {
          id
          is_viewed
          status
          notificationID
          coachID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      showcases_interested_in {
        items {
          id
          showcaseID
          coachID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      my_athlete_tags {
        items {
          id
          coachID
          tagID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      team {
        id
        name
        sport
        genre
        suscription_name
        suscription_recurrency
        suscription_status
        suscription_stripe_id
        suscription_premium_start_date
        suscription_premium_end_date
        team_logo
        conference
        division
        college_category
        url_domain
        had_trial
        collegeID
        college {
          id
          name
          country
          state
          address
          logo
          createdAt
          updatedAt
          __typename
        }
        coaches {
          nextToken
          __typename
        }
        subscription_history {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const listCoachs = /* GraphQL */ `
  query ListCoachs(
    $filter: ModelCoachFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCoachs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
          __typename
        }
        my_filters {
          nextToken
          __typename
        }
        my_favorite_folders {
          nextToken
          __typename
        }
        my_notifications {
          nextToken
          __typename
        }
        showcases_interested_in {
          nextToken
          __typename
        }
        my_athlete_tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          had_trial
          collegeID
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCoachMessage = /* GraphQL */ `
  query GetCoachMessage($id: ID!) {
    getCoachMessage(id: $id) {
      id
      message
      agencyID
      agency {
        id
        name
        logo
        country
        contact_email
        contact_phone
        website
        sport
        athletes {
          nextToken
          __typename
        }
        agents {
          nextToken
          __typename
        }
        coach_messages {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      coachID
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
          __typename
        }
        my_filters {
          nextToken
          __typename
        }
        my_favorite_folders {
          nextToken
          __typename
        }
        my_notifications {
          nextToken
          __typename
        }
        showcases_interested_in {
          nextToken
          __typename
        }
        my_athlete_tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          had_trial
          collegeID
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCoachMessages = /* GraphQL */ `
  query ListCoachMessages(
    $filter: ModelCoachMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCoachMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        message
        agencyID
        agency {
          id
          name
          logo
          country
          contact_email
          contact_phone
          website
          sport
          createdAt
          updatedAt
          __typename
        }
        coachID
        coach {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          disabledByUser
          is_active
          allow_whatsapp_contact
          deleteAppReason
          division
          role
          sport
          teamID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAgency = /* GraphQL */ `
  query GetAgency($id: ID!) {
    getAgency(id: $id) {
      id
      name
      logo
      country
      contact_email
      contact_phone
      website
      sport
      athletes {
        items {
          id
          agencyID
          agentID
          name
          isVisible
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          nationality
          country_of_competition
          target_division
          compliance
          card_pic
          other_nice_pics
          agency_assesment
          elegibility_notes
          current_club
          current_status
          start_availability_date
          end_availability_date
          destination_college
          ncaa_id
          type_of_admission
          weight
          height
          sport
          position
          second_position
          dominant_foot
          level_of_competition
          sports_background
          start_date
          high_school_graduation_date
          sat_score
          sat_date
          act_score
          act_date
          gemat_gre_score
          gemat_gre_date
          toefl_score
          toefl_date
          duolingo
          duolingo_date
          ielts
          ielts_date
          estimated_gpa
          intended_field_of_studies
          intended_degree
          budget
          category
          facebook_url
          twitter_user
          linkedin_url
          reason_commitment
          date_commitment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      agents {
        items {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          agent_role
          agencyID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coach_messages {
        items {
          id
          message
          agencyID
          coachID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAgencys = /* GraphQL */ `
  query ListAgencys(
    $filter: ModelAgencyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAgencys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        logo
        country
        contact_email
        contact_phone
        website
        sport
        athletes {
          nextToken
          __typename
        }
        agents {
          nextToken
          __typename
        }
        coach_messages {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAthlete = /* GraphQL */ `
  query GetAthlete($id: ID!) {
    getAthlete(id: $id) {
      id
      agencyID
      agentID
      name
      isVisible
      profile_pic
      birthdate
      gender
      contact_email
      contact_phone
      nationality
      country_of_competition
      target_division
      compliance
      card_pic
      other_nice_pics
      agency_assesment
      elegibility_notes
      current_club
      current_status
      start_availability_date
      end_availability_date
      destination_college
      ncaa_id
      type_of_admission
      weight
      height
      sport
      tennis_profile {
        utr
        national_ranking
        international_ranking
        national_ranking_adult
        international_ranking_adult
        DominantHand
        __typename
      }
      position
      second_position
      dominant_foot
      featured_video {
        url
        metadata
        __typename
      }
      other_videos {
        url
        metadata
        __typename
      }
      level_of_competition
      sports_background
      showcase_participation {
        showcase_name
        city
        country
        date
        __typename
      }
      start_date
      high_school_graduation_date
      sat_score
      sat_date
      act_score
      act_date
      gemat_gre_score
      gemat_gre_date
      toefl_score
      toefl_date
      duolingo
      duolingo_date
      ielts
      ielts_date
      estimated_gpa
      intended_field_of_studies
      intended_degree
      budget
      category
      facebook_url
      twitter_user
      linkedin_url
      reason_commitment
      date_commitment
      agency {
        id
        name
        logo
        country
        contact_email
        contact_phone
        website
        sport
        athletes {
          nextToken
          __typename
        }
        agents {
          nextToken
          __typename
        }
        coach_messages {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      agent {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        agent_role
        agencyID
        agency {
          nextToken
          __typename
        }
        ahtletes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      favorited {
        items {
          id
          athleteID
          coachID
          rating
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      enquiries {
        items {
          id
          enquiry_text
          athleteID
          coachID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      tags {
        items {
          id
          athleteID
          tagID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      showcases_participation {
        items {
          id
          showcaseID
          athleteID
          color
          number
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAthletes = /* GraphQL */ `
  query ListAthletes(
    $filter: ModelAthleteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAthletes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        agencyID
        agentID
        name
        isVisible
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        nationality
        country_of_competition
        target_division
        compliance
        card_pic
        other_nice_pics
        agency_assesment
        elegibility_notes
        current_club
        current_status
        start_availability_date
        end_availability_date
        destination_college
        ncaa_id
        type_of_admission
        weight
        height
        sport
        tennis_profile {
          utr
          national_ranking
          international_ranking
          national_ranking_adult
          international_ranking_adult
          DominantHand
          __typename
        }
        position
        second_position
        dominant_foot
        featured_video {
          url
          metadata
          __typename
        }
        other_videos {
          url
          metadata
          __typename
        }
        level_of_competition
        sports_background
        showcase_participation {
          showcase_name
          city
          country
          date
          __typename
        }
        start_date
        high_school_graduation_date
        sat_score
        sat_date
        act_score
        act_date
        gemat_gre_score
        gemat_gre_date
        toefl_score
        toefl_date
        duolingo
        duolingo_date
        ielts
        ielts_date
        estimated_gpa
        intended_field_of_studies
        intended_degree
        budget
        category
        facebook_url
        twitter_user
        linkedin_url
        reason_commitment
        date_commitment
        agency {
          id
          name
          logo
          country
          contact_email
          contact_phone
          website
          sport
          createdAt
          updatedAt
          __typename
        }
        agent {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          agent_role
          agencyID
          createdAt
          updatedAt
          __typename
        }
        favorited {
          nextToken
          __typename
        }
        enquiries {
          nextToken
          __typename
        }
        tags {
          nextToken
          __typename
        }
        showcases_participation {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCoachAthleteFavorites = /* GraphQL */ `
  query GetCoachAthleteFavorites($id: ID!) {
    getCoachAthleteFavorites(id: $id) {
      id
      athleteID
      coachID
      rating
      athlete {
        id
        agencyID
        agentID
        name
        isVisible
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        nationality
        country_of_competition
        target_division
        compliance
        card_pic
        other_nice_pics
        agency_assesment
        elegibility_notes
        current_club
        current_status
        start_availability_date
        end_availability_date
        destination_college
        ncaa_id
        type_of_admission
        weight
        height
        sport
        tennis_profile {
          utr
          national_ranking
          international_ranking
          national_ranking_adult
          international_ranking_adult
          DominantHand
          __typename
        }
        position
        second_position
        dominant_foot
        featured_video {
          url
          metadata
          __typename
        }
        other_videos {
          url
          metadata
          __typename
        }
        level_of_competition
        sports_background
        showcase_participation {
          showcase_name
          city
          country
          date
          __typename
        }
        start_date
        high_school_graduation_date
        sat_score
        sat_date
        act_score
        act_date
        gemat_gre_score
        gemat_gre_date
        toefl_score
        toefl_date
        duolingo
        duolingo_date
        ielts
        ielts_date
        estimated_gpa
        intended_field_of_studies
        intended_degree
        budget
        category
        facebook_url
        twitter_user
        linkedin_url
        reason_commitment
        date_commitment
        agency {
          id
          name
          logo
          country
          contact_email
          contact_phone
          website
          sport
          createdAt
          updatedAt
          __typename
        }
        agent {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          agent_role
          agencyID
          createdAt
          updatedAt
          __typename
        }
        favorited {
          nextToken
          __typename
        }
        enquiries {
          nextToken
          __typename
        }
        tags {
          nextToken
          __typename
        }
        showcases_participation {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
          __typename
        }
        my_filters {
          nextToken
          __typename
        }
        my_favorite_folders {
          nextToken
          __typename
        }
        my_notifications {
          nextToken
          __typename
        }
        showcases_interested_in {
          nextToken
          __typename
        }
        my_athlete_tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          had_trial
          collegeID
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCoachAthleteFavoritess = /* GraphQL */ `
  query ListCoachAthleteFavoritess(
    $filter: ModelCoachAthleteFavoritesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCoachAthleteFavoritess(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        athleteID
        coachID
        rating
        athlete {
          id
          agencyID
          agentID
          name
          isVisible
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          nationality
          country_of_competition
          target_division
          compliance
          card_pic
          other_nice_pics
          agency_assesment
          elegibility_notes
          current_club
          current_status
          start_availability_date
          end_availability_date
          destination_college
          ncaa_id
          type_of_admission
          weight
          height
          sport
          position
          second_position
          dominant_foot
          level_of_competition
          sports_background
          start_date
          high_school_graduation_date
          sat_score
          sat_date
          act_score
          act_date
          gemat_gre_score
          gemat_gre_date
          toefl_score
          toefl_date
          duolingo
          duolingo_date
          ielts
          ielts_date
          estimated_gpa
          intended_field_of_studies
          intended_degree
          budget
          category
          facebook_url
          twitter_user
          linkedin_url
          reason_commitment
          date_commitment
          createdAt
          updatedAt
          __typename
        }
        coach {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          disabledByUser
          is_active
          allow_whatsapp_contact
          deleteAppReason
          division
          role
          sport
          teamID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getFavoritesFolder = /* GraphQL */ `
  query GetFavoritesFolder($id: ID!) {
    getFavoritesFolder(id: $id) {
      id
      folder_name
      status
      coachID
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
          __typename
        }
        my_filters {
          nextToken
          __typename
        }
        my_favorite_folders {
          nextToken
          __typename
        }
        my_notifications {
          nextToken
          __typename
        }
        showcases_interested_in {
          nextToken
          __typename
        }
        my_athlete_tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          had_trial
          collegeID
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      athletes_in_folder {
        items {
          id
          athleteID
          favoritesFolderID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listFavoritesFolders = /* GraphQL */ `
  query ListFavoritesFolders(
    $filter: ModelFavoritesFolderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFavoritesFolders(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        folder_name
        status
        coachID
        coach {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          disabledByUser
          is_active
          allow_whatsapp_contact
          deleteAppReason
          division
          role
          sport
          teamID
          createdAt
          updatedAt
          __typename
        }
        athletes_in_folder {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getFavoritesFolderAthleteRelation = /* GraphQL */ `
  query GetFavoritesFolderAthleteRelation($id: ID!) {
    getFavoritesFolderAthleteRelation(id: $id) {
      id
      athleteID
      favoritesFolderID
      athlete {
        id
        agencyID
        agentID
        name
        isVisible
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        nationality
        country_of_competition
        target_division
        compliance
        card_pic
        other_nice_pics
        agency_assesment
        elegibility_notes
        current_club
        current_status
        start_availability_date
        end_availability_date
        destination_college
        ncaa_id
        type_of_admission
        weight
        height
        sport
        tennis_profile {
          utr
          national_ranking
          international_ranking
          national_ranking_adult
          international_ranking_adult
          DominantHand
          __typename
        }
        position
        second_position
        dominant_foot
        featured_video {
          url
          metadata
          __typename
        }
        other_videos {
          url
          metadata
          __typename
        }
        level_of_competition
        sports_background
        showcase_participation {
          showcase_name
          city
          country
          date
          __typename
        }
        start_date
        high_school_graduation_date
        sat_score
        sat_date
        act_score
        act_date
        gemat_gre_score
        gemat_gre_date
        toefl_score
        toefl_date
        duolingo
        duolingo_date
        ielts
        ielts_date
        estimated_gpa
        intended_field_of_studies
        intended_degree
        budget
        category
        facebook_url
        twitter_user
        linkedin_url
        reason_commitment
        date_commitment
        agency {
          id
          name
          logo
          country
          contact_email
          contact_phone
          website
          sport
          createdAt
          updatedAt
          __typename
        }
        agent {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          agent_role
          agencyID
          createdAt
          updatedAt
          __typename
        }
        favorited {
          nextToken
          __typename
        }
        enquiries {
          nextToken
          __typename
        }
        tags {
          nextToken
          __typename
        }
        showcases_participation {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      favorites_folder {
        id
        folder_name
        status
        coachID
        coach {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          disabledByUser
          is_active
          allow_whatsapp_contact
          deleteAppReason
          division
          role
          sport
          teamID
          createdAt
          updatedAt
          __typename
        }
        athletes_in_folder {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listFavoritesFolderAthleteRelations = /* GraphQL */ `
  query ListFavoritesFolderAthleteRelations(
    $filter: ModelFavoritesFolderAthleteRelationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFavoritesFolderAthleteRelations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        athleteID
        favoritesFolderID
        athlete {
          id
          agencyID
          agentID
          name
          isVisible
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          nationality
          country_of_competition
          target_division
          compliance
          card_pic
          other_nice_pics
          agency_assesment
          elegibility_notes
          current_club
          current_status
          start_availability_date
          end_availability_date
          destination_college
          ncaa_id
          type_of_admission
          weight
          height
          sport
          position
          second_position
          dominant_foot
          level_of_competition
          sports_background
          start_date
          high_school_graduation_date
          sat_score
          sat_date
          act_score
          act_date
          gemat_gre_score
          gemat_gre_date
          toefl_score
          toefl_date
          duolingo
          duolingo_date
          ielts
          ielts_date
          estimated_gpa
          intended_field_of_studies
          intended_degree
          budget
          category
          facebook_url
          twitter_user
          linkedin_url
          reason_commitment
          date_commitment
          createdAt
          updatedAt
          __typename
        }
        favorites_folder {
          id
          folder_name
          status
          coachID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAthleteTag = /* GraphQL */ `
  query GetAthleteTag($id: ID!) {
    getAthleteTag(id: $id) {
      id
      text
      type
      athletes {
        items {
          id
          athleteID
          tagID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coaches_owners {
        items {
          id
          coachID
          tagID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAthleteTags = /* GraphQL */ `
  query ListAthleteTags(
    $filter: ModelAthleteTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAthleteTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        text
        type
        athletes {
          nextToken
          __typename
        }
        coaches_owners {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCoachTagsRelation = /* GraphQL */ `
  query GetCoachTagsRelation($id: ID!) {
    getCoachTagsRelation(id: $id) {
      id
      coachID
      tagID
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
          __typename
        }
        my_filters {
          nextToken
          __typename
        }
        my_favorite_folders {
          nextToken
          __typename
        }
        my_notifications {
          nextToken
          __typename
        }
        showcases_interested_in {
          nextToken
          __typename
        }
        my_athlete_tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          had_trial
          collegeID
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      tag {
        id
        text
        type
        athletes {
          nextToken
          __typename
        }
        coaches_owners {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCoachTagsRelations = /* GraphQL */ `
  query ListCoachTagsRelations(
    $filter: ModelCoachTagsRelationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCoachTagsRelations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        coachID
        tagID
        coach {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          disabledByUser
          is_active
          allow_whatsapp_contact
          deleteAppReason
          division
          role
          sport
          teamID
          createdAt
          updatedAt
          __typename
        }
        tag {
          id
          text
          type
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAthleteTagsRelation = /* GraphQL */ `
  query GetAthleteTagsRelation($id: ID!) {
    getAthleteTagsRelation(id: $id) {
      id
      athleteID
      tagID
      athlete {
        id
        agencyID
        agentID
        name
        isVisible
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        nationality
        country_of_competition
        target_division
        compliance
        card_pic
        other_nice_pics
        agency_assesment
        elegibility_notes
        current_club
        current_status
        start_availability_date
        end_availability_date
        destination_college
        ncaa_id
        type_of_admission
        weight
        height
        sport
        tennis_profile {
          utr
          national_ranking
          international_ranking
          national_ranking_adult
          international_ranking_adult
          DominantHand
          __typename
        }
        position
        second_position
        dominant_foot
        featured_video {
          url
          metadata
          __typename
        }
        other_videos {
          url
          metadata
          __typename
        }
        level_of_competition
        sports_background
        showcase_participation {
          showcase_name
          city
          country
          date
          __typename
        }
        start_date
        high_school_graduation_date
        sat_score
        sat_date
        act_score
        act_date
        gemat_gre_score
        gemat_gre_date
        toefl_score
        toefl_date
        duolingo
        duolingo_date
        ielts
        ielts_date
        estimated_gpa
        intended_field_of_studies
        intended_degree
        budget
        category
        facebook_url
        twitter_user
        linkedin_url
        reason_commitment
        date_commitment
        agency {
          id
          name
          logo
          country
          contact_email
          contact_phone
          website
          sport
          createdAt
          updatedAt
          __typename
        }
        agent {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          agent_role
          agencyID
          createdAt
          updatedAt
          __typename
        }
        favorited {
          nextToken
          __typename
        }
        enquiries {
          nextToken
          __typename
        }
        tags {
          nextToken
          __typename
        }
        showcases_participation {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      tag {
        id
        text
        type
        athletes {
          nextToken
          __typename
        }
        coaches_owners {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAthleteTagsRelations = /* GraphQL */ `
  query ListAthleteTagsRelations(
    $filter: ModelAthleteTagsRelationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAthleteTagsRelations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        athleteID
        tagID
        athlete {
          id
          agencyID
          agentID
          name
          isVisible
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          nationality
          country_of_competition
          target_division
          compliance
          card_pic
          other_nice_pics
          agency_assesment
          elegibility_notes
          current_club
          current_status
          start_availability_date
          end_availability_date
          destination_college
          ncaa_id
          type_of_admission
          weight
          height
          sport
          position
          second_position
          dominant_foot
          level_of_competition
          sports_background
          start_date
          high_school_graduation_date
          sat_score
          sat_date
          act_score
          act_date
          gemat_gre_score
          gemat_gre_date
          toefl_score
          toefl_date
          duolingo
          duolingo_date
          ielts
          ielts_date
          estimated_gpa
          intended_field_of_studies
          intended_degree
          budget
          category
          facebook_url
          twitter_user
          linkedin_url
          reason_commitment
          date_commitment
          createdAt
          updatedAt
          __typename
        }
        tag {
          id
          text
          type
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAthleteEnquiry = /* GraphQL */ `
  query GetAthleteEnquiry($id: ID!) {
    getAthleteEnquiry(id: $id) {
      id
      enquiry_text
      athleteID
      coachID
      athlete {
        id
        agencyID
        agentID
        name
        isVisible
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        nationality
        country_of_competition
        target_division
        compliance
        card_pic
        other_nice_pics
        agency_assesment
        elegibility_notes
        current_club
        current_status
        start_availability_date
        end_availability_date
        destination_college
        ncaa_id
        type_of_admission
        weight
        height
        sport
        tennis_profile {
          utr
          national_ranking
          international_ranking
          national_ranking_adult
          international_ranking_adult
          DominantHand
          __typename
        }
        position
        second_position
        dominant_foot
        featured_video {
          url
          metadata
          __typename
        }
        other_videos {
          url
          metadata
          __typename
        }
        level_of_competition
        sports_background
        showcase_participation {
          showcase_name
          city
          country
          date
          __typename
        }
        start_date
        high_school_graduation_date
        sat_score
        sat_date
        act_score
        act_date
        gemat_gre_score
        gemat_gre_date
        toefl_score
        toefl_date
        duolingo
        duolingo_date
        ielts
        ielts_date
        estimated_gpa
        intended_field_of_studies
        intended_degree
        budget
        category
        facebook_url
        twitter_user
        linkedin_url
        reason_commitment
        date_commitment
        agency {
          id
          name
          logo
          country
          contact_email
          contact_phone
          website
          sport
          createdAt
          updatedAt
          __typename
        }
        agent {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          agent_role
          agencyID
          createdAt
          updatedAt
          __typename
        }
        favorited {
          nextToken
          __typename
        }
        enquiries {
          nextToken
          __typename
        }
        tags {
          nextToken
          __typename
        }
        showcases_participation {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
          __typename
        }
        my_filters {
          nextToken
          __typename
        }
        my_favorite_folders {
          nextToken
          __typename
        }
        my_notifications {
          nextToken
          __typename
        }
        showcases_interested_in {
          nextToken
          __typename
        }
        my_athlete_tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          had_trial
          collegeID
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAthleteEnquirys = /* GraphQL */ `
  query ListAthleteEnquirys(
    $filter: ModelAthleteEnquiryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAthleteEnquirys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        enquiry_text
        athleteID
        coachID
        athlete {
          id
          agencyID
          agentID
          name
          isVisible
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          nationality
          country_of_competition
          target_division
          compliance
          card_pic
          other_nice_pics
          agency_assesment
          elegibility_notes
          current_club
          current_status
          start_availability_date
          end_availability_date
          destination_college
          ncaa_id
          type_of_admission
          weight
          height
          sport
          position
          second_position
          dominant_foot
          level_of_competition
          sports_background
          start_date
          high_school_graduation_date
          sat_score
          sat_date
          act_score
          act_date
          gemat_gre_score
          gemat_gre_date
          toefl_score
          toefl_date
          duolingo
          duolingo_date
          ielts
          ielts_date
          estimated_gpa
          intended_field_of_studies
          intended_degree
          budget
          category
          facebook_url
          twitter_user
          linkedin_url
          reason_commitment
          date_commitment
          createdAt
          updatedAt
          __typename
        }
        coach {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          disabledByUser
          is_active
          allow_whatsapp_contact
          deleteAppReason
          division
          role
          sport
          teamID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getFeedback = /* GraphQL */ `
  query GetFeedback($id: ID!) {
    getFeedback(id: $id) {
      id
      feedback_text
      type
      coachID
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
          __typename
        }
        my_filters {
          nextToken
          __typename
        }
        my_favorite_folders {
          nextToken
          __typename
        }
        my_notifications {
          nextToken
          __typename
        }
        showcases_interested_in {
          nextToken
          __typename
        }
        my_athlete_tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          had_trial
          collegeID
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listFeedbacks = /* GraphQL */ `
  query ListFeedbacks(
    $filter: ModelFeedbackFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFeedbacks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        feedback_text
        type
        coachID
        coach {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          disabledByUser
          is_active
          allow_whatsapp_contact
          deleteAppReason
          division
          role
          sport
          teamID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSearchTerm = /* GraphQL */ `
  query GetSearchTerm($id: ID!) {
    getSearchTerm(id: $id) {
      id
      search_term_text
      search_type
      sport
      source_app
      coachID
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
          __typename
        }
        my_filters {
          nextToken
          __typename
        }
        my_favorite_folders {
          nextToken
          __typename
        }
        my_notifications {
          nextToken
          __typename
        }
        showcases_interested_in {
          nextToken
          __typename
        }
        my_athlete_tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          had_trial
          collegeID
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSearchTerms = /* GraphQL */ `
  query ListSearchTerms(
    $filter: ModelSearchTermFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSearchTerms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        search_term_text
        search_type
        sport
        source_app
        coachID
        coach {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          disabledByUser
          is_active
          allow_whatsapp_contact
          deleteAppReason
          division
          role
          sport
          teamID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSearchFilter = /* GraphQL */ `
  query GetSearchFilter($id: ID!) {
    getSearchFilter(id: $id) {
      id
      name
      filter_body
      type
      sport
      source_app
      coachID
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
          __typename
        }
        my_filters {
          nextToken
          __typename
        }
        my_favorite_folders {
          nextToken
          __typename
        }
        my_notifications {
          nextToken
          __typename
        }
        showcases_interested_in {
          nextToken
          __typename
        }
        my_athlete_tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          had_trial
          collegeID
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSearchFilters = /* GraphQL */ `
  query ListSearchFilters(
    $filter: ModelSearchFilterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSearchFilters(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        filter_body
        type
        sport
        source_app
        coachID
        coach {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          disabledByUser
          is_active
          allow_whatsapp_contact
          deleteAppReason
          division
          role
          sport
          teamID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getNotification = /* GraphQL */ `
  query GetNotification($id: ID!) {
    getNotification(id: $id) {
      id
      title
      text
      status
      type
      pic
      attachment
      coaches_notified {
        items {
          id
          is_viewed
          status
          notificationID
          coachID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        text
        status
        type
        pic
        attachment
        coaches_notified {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCoachNotificationRelation = /* GraphQL */ `
  query GetCoachNotificationRelation($id: ID!) {
    getCoachNotificationRelation(id: $id) {
      id
      is_viewed
      status
      notificationID
      coachID
      notification {
        id
        title
        text
        status
        type
        pic
        attachment
        coaches_notified {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
          __typename
        }
        my_filters {
          nextToken
          __typename
        }
        my_favorite_folders {
          nextToken
          __typename
        }
        my_notifications {
          nextToken
          __typename
        }
        showcases_interested_in {
          nextToken
          __typename
        }
        my_athlete_tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          had_trial
          collegeID
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCoachNotificationRelations = /* GraphQL */ `
  query ListCoachNotificationRelations(
    $filter: ModelCoachNotificationRelationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCoachNotificationRelations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        is_viewed
        status
        notificationID
        coachID
        notification {
          id
          title
          text
          status
          type
          pic
          attachment
          createdAt
          updatedAt
          __typename
        }
        coach {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          disabledByUser
          is_active
          allow_whatsapp_contact
          deleteAppReason
          division
          role
          sport
          teamID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAgent = /* GraphQL */ `
  query GetAgent($id: ID!) {
    getAgent(id: $id) {
      id
      name
      profile_pic
      birthdate
      gender
      contact_email
      contact_phone
      agent_role
      agencyID
      agency {
        items {
          id
          name
          logo
          country
          contact_email
          contact_phone
          website
          sport
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      ahtletes {
        items {
          id
          agencyID
          agentID
          name
          isVisible
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          nationality
          country_of_competition
          target_division
          compliance
          card_pic
          other_nice_pics
          agency_assesment
          elegibility_notes
          current_club
          current_status
          start_availability_date
          end_availability_date
          destination_college
          ncaa_id
          type_of_admission
          weight
          height
          sport
          position
          second_position
          dominant_foot
          level_of_competition
          sports_background
          start_date
          high_school_graduation_date
          sat_score
          sat_date
          act_score
          act_date
          gemat_gre_score
          gemat_gre_date
          toefl_score
          toefl_date
          duolingo
          duolingo_date
          ielts
          ielts_date
          estimated_gpa
          intended_field_of_studies
          intended_degree
          budget
          category
          facebook_url
          twitter_user
          linkedin_url
          reason_commitment
          date_commitment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAgents = /* GraphQL */ `
  query ListAgents(
    $filter: ModelAgentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAgents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        agent_role
        agencyID
        agency {
          nextToken
          __typename
        }
        ahtletes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getShowcase = /* GraphQL */ `
  query GetShowcase($id: ID!) {
    getShowcase(id: $id) {
      id
      name
      description
      contry
      address
      date
      status
      coaches_interested {
        items {
          id
          showcaseID
          coachID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      featured_athletes {
        items {
          id
          showcaseID
          athleteID
          color
          number
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listShowcases = /* GraphQL */ `
  query ListShowcases(
    $filter: ModelShowcaseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listShowcases(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        contry
        address
        date
        status
        coaches_interested {
          nextToken
          __typename
        }
        featured_athletes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getShowcaseCoachRelation = /* GraphQL */ `
  query GetShowcaseCoachRelation($id: ID!) {
    getShowcaseCoachRelation(id: $id) {
      id
      showcaseID
      coachID
      showcase {
        id
        name
        description
        contry
        address
        date
        status
        coaches_interested {
          nextToken
          __typename
        }
        featured_athletes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
          __typename
        }
        my_filters {
          nextToken
          __typename
        }
        my_favorite_folders {
          nextToken
          __typename
        }
        my_notifications {
          nextToken
          __typename
        }
        showcases_interested_in {
          nextToken
          __typename
        }
        my_athlete_tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          had_trial
          collegeID
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listShowcaseCoachRelations = /* GraphQL */ `
  query ListShowcaseCoachRelations(
    $filter: ModelShowcaseCoachRelationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listShowcaseCoachRelations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        showcaseID
        coachID
        showcase {
          id
          name
          description
          contry
          address
          date
          status
          createdAt
          updatedAt
          __typename
        }
        coach {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          disabledByUser
          is_active
          allow_whatsapp_contact
          deleteAppReason
          division
          role
          sport
          teamID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getShowcaseAthleteRelation = /* GraphQL */ `
  query GetShowcaseAthleteRelation($id: ID!) {
    getShowcaseAthleteRelation(id: $id) {
      id
      showcaseID
      athleteID
      color
      number
      showcase {
        id
        name
        description
        contry
        address
        date
        status
        coaches_interested {
          nextToken
          __typename
        }
        featured_athletes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      athlete {
        id
        agencyID
        agentID
        name
        isVisible
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        nationality
        country_of_competition
        target_division
        compliance
        card_pic
        other_nice_pics
        agency_assesment
        elegibility_notes
        current_club
        current_status
        start_availability_date
        end_availability_date
        destination_college
        ncaa_id
        type_of_admission
        weight
        height
        sport
        tennis_profile {
          utr
          national_ranking
          international_ranking
          national_ranking_adult
          international_ranking_adult
          DominantHand
          __typename
        }
        position
        second_position
        dominant_foot
        featured_video {
          url
          metadata
          __typename
        }
        other_videos {
          url
          metadata
          __typename
        }
        level_of_competition
        sports_background
        showcase_participation {
          showcase_name
          city
          country
          date
          __typename
        }
        start_date
        high_school_graduation_date
        sat_score
        sat_date
        act_score
        act_date
        gemat_gre_score
        gemat_gre_date
        toefl_score
        toefl_date
        duolingo
        duolingo_date
        ielts
        ielts_date
        estimated_gpa
        intended_field_of_studies
        intended_degree
        budget
        category
        facebook_url
        twitter_user
        linkedin_url
        reason_commitment
        date_commitment
        agency {
          id
          name
          logo
          country
          contact_email
          contact_phone
          website
          sport
          createdAt
          updatedAt
          __typename
        }
        agent {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          agent_role
          agencyID
          createdAt
          updatedAt
          __typename
        }
        favorited {
          nextToken
          __typename
        }
        enquiries {
          nextToken
          __typename
        }
        tags {
          nextToken
          __typename
        }
        showcases_participation {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listShowcaseAthleteRelations = /* GraphQL */ `
  query ListShowcaseAthleteRelations(
    $filter: ModelShowcaseAthleteRelationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listShowcaseAthleteRelations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        showcaseID
        athleteID
        color
        number
        showcase {
          id
          name
          description
          contry
          address
          date
          status
          createdAt
          updatedAt
          __typename
        }
        athlete {
          id
          agencyID
          agentID
          name
          isVisible
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          nationality
          country_of_competition
          target_division
          compliance
          card_pic
          other_nice_pics
          agency_assesment
          elegibility_notes
          current_club
          current_status
          start_availability_date
          end_availability_date
          destination_college
          ncaa_id
          type_of_admission
          weight
          height
          sport
          position
          second_position
          dominant_foot
          level_of_competition
          sports_background
          start_date
          high_school_graduation_date
          sat_score
          sat_date
          act_score
          act_date
          gemat_gre_score
          gemat_gre_date
          toefl_score
          toefl_date
          duolingo
          duolingo_date
          ielts
          ielts_date
          estimated_gpa
          intended_field_of_studies
          intended_degree
          budget
          category
          facebook_url
          twitter_user
          linkedin_url
          reason_commitment
          date_commitment
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const subscriptionByTeams = /* GraphQL */ `
  query SubscriptionByTeams(
    $teamID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelSubscriptionPeriodFilterInput
    $limit: Int
    $nextToken: String
  ) {
    subscriptionByTeams(
      teamID: $teamID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        start_date
        end_date
        suscription_name
        teamID
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          had_trial
          collegeID
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const CoachMessagesByAgencies = /* GraphQL */ `
  query CoachMessagesByAgencies(
    $agencyID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelCoachMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    CoachMessagesByAgencies(
      agencyID: $agencyID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        message
        agencyID
        agency {
          id
          name
          logo
          country
          contact_email
          contact_phone
          website
          sport
          createdAt
          updatedAt
          __typename
        }
        coachID
        coach {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          disabledByUser
          is_active
          allow_whatsapp_contact
          deleteAppReason
          division
          role
          sport
          teamID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const athletesByAgencies = /* GraphQL */ `
  query AthletesByAgencies(
    $agencyID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelAthleteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    athletesByAgencies(
      agencyID: $agencyID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        agencyID
        agentID
        name
        isVisible
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        nationality
        country_of_competition
        target_division
        compliance
        card_pic
        other_nice_pics
        agency_assesment
        elegibility_notes
        current_club
        current_status
        start_availability_date
        end_availability_date
        destination_college
        ncaa_id
        type_of_admission
        weight
        height
        sport
        tennis_profile {
          utr
          national_ranking
          international_ranking
          national_ranking_adult
          international_ranking_adult
          DominantHand
          __typename
        }
        position
        second_position
        dominant_foot
        featured_video {
          url
          metadata
          __typename
        }
        other_videos {
          url
          metadata
          __typename
        }
        level_of_competition
        sports_background
        showcase_participation {
          showcase_name
          city
          country
          date
          __typename
        }
        start_date
        high_school_graduation_date
        sat_score
        sat_date
        act_score
        act_date
        gemat_gre_score
        gemat_gre_date
        toefl_score
        toefl_date
        duolingo
        duolingo_date
        ielts
        ielts_date
        estimated_gpa
        intended_field_of_studies
        intended_degree
        budget
        category
        facebook_url
        twitter_user
        linkedin_url
        reason_commitment
        date_commitment
        agency {
          id
          name
          logo
          country
          contact_email
          contact_phone
          website
          sport
          createdAt
          updatedAt
          __typename
        }
        agent {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          agent_role
          agencyID
          createdAt
          updatedAt
          __typename
        }
        favorited {
          nextToken
          __typename
        }
        enquiries {
          nextToken
          __typename
        }
        tags {
          nextToken
          __typename
        }
        showcases_participation {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const athletesByAgent = /* GraphQL */ `
  query AthletesByAgent(
    $agentID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelAthleteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    athletesByAgent(
      agentID: $agentID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        agencyID
        agentID
        name
        isVisible
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        nationality
        country_of_competition
        target_division
        compliance
        card_pic
        other_nice_pics
        agency_assesment
        elegibility_notes
        current_club
        current_status
        start_availability_date
        end_availability_date
        destination_college
        ncaa_id
        type_of_admission
        weight
        height
        sport
        tennis_profile {
          utr
          national_ranking
          international_ranking
          national_ranking_adult
          international_ranking_adult
          DominantHand
          __typename
        }
        position
        second_position
        dominant_foot
        featured_video {
          url
          metadata
          __typename
        }
        other_videos {
          url
          metadata
          __typename
        }
        level_of_competition
        sports_background
        showcase_participation {
          showcase_name
          city
          country
          date
          __typename
        }
        start_date
        high_school_graduation_date
        sat_score
        sat_date
        act_score
        act_date
        gemat_gre_score
        gemat_gre_date
        toefl_score
        toefl_date
        duolingo
        duolingo_date
        ielts
        ielts_date
        estimated_gpa
        intended_field_of_studies
        intended_degree
        budget
        category
        facebook_url
        twitter_user
        linkedin_url
        reason_commitment
        date_commitment
        agency {
          id
          name
          logo
          country
          contact_email
          contact_phone
          website
          sport
          createdAt
          updatedAt
          __typename
        }
        agent {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          agent_role
          agencyID
          createdAt
          updatedAt
          __typename
        }
        favorited {
          nextToken
          __typename
        }
        enquiries {
          nextToken
          __typename
        }
        tags {
          nextToken
          __typename
        }
        showcases_participation {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const athletesByName = /* GraphQL */ `
  query AthletesByName(
    $name: String
    $sortDirection: ModelSortDirection
    $filter: ModelAthleteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    athletesByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        agencyID
        agentID
        name
        isVisible
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        nationality
        country_of_competition
        target_division
        compliance
        card_pic
        other_nice_pics
        agency_assesment
        elegibility_notes
        current_club
        current_status
        start_availability_date
        end_availability_date
        destination_college
        ncaa_id
        type_of_admission
        weight
        height
        sport
        tennis_profile {
          utr
          national_ranking
          international_ranking
          national_ranking_adult
          international_ranking_adult
          DominantHand
          __typename
        }
        position
        second_position
        dominant_foot
        featured_video {
          url
          metadata
          __typename
        }
        other_videos {
          url
          metadata
          __typename
        }
        level_of_competition
        sports_background
        showcase_participation {
          showcase_name
          city
          country
          date
          __typename
        }
        start_date
        high_school_graduation_date
        sat_score
        sat_date
        act_score
        act_date
        gemat_gre_score
        gemat_gre_date
        toefl_score
        toefl_date
        duolingo
        duolingo_date
        ielts
        ielts_date
        estimated_gpa
        intended_field_of_studies
        intended_degree
        budget
        category
        facebook_url
        twitter_user
        linkedin_url
        reason_commitment
        date_commitment
        agency {
          id
          name
          logo
          country
          contact_email
          contact_phone
          website
          sport
          createdAt
          updatedAt
          __typename
        }
        agent {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          agent_role
          agencyID
          createdAt
          updatedAt
          __typename
        }
        favorited {
          nextToken
          __typename
        }
        enquiries {
          nextToken
          __typename
        }
        tags {
          nextToken
          __typename
        }
        showcases_participation {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const athletesByGPA = /* GraphQL */ `
  query AthletesByGPA(
    $estimated_gpa: Float
    $sortDirection: ModelSortDirection
    $filter: ModelAthleteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    athletesByGPA(
      estimated_gpa: $estimated_gpa
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        agencyID
        agentID
        name
        isVisible
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        nationality
        country_of_competition
        target_division
        compliance
        card_pic
        other_nice_pics
        agency_assesment
        elegibility_notes
        current_club
        current_status
        start_availability_date
        end_availability_date
        destination_college
        ncaa_id
        type_of_admission
        weight
        height
        sport
        tennis_profile {
          utr
          national_ranking
          international_ranking
          national_ranking_adult
          international_ranking_adult
          DominantHand
          __typename
        }
        position
        second_position
        dominant_foot
        featured_video {
          url
          metadata
          __typename
        }
        other_videos {
          url
          metadata
          __typename
        }
        level_of_competition
        sports_background
        showcase_participation {
          showcase_name
          city
          country
          date
          __typename
        }
        start_date
        high_school_graduation_date
        sat_score
        sat_date
        act_score
        act_date
        gemat_gre_score
        gemat_gre_date
        toefl_score
        toefl_date
        duolingo
        duolingo_date
        ielts
        ielts_date
        estimated_gpa
        intended_field_of_studies
        intended_degree
        budget
        category
        facebook_url
        twitter_user
        linkedin_url
        reason_commitment
        date_commitment
        agency {
          id
          name
          logo
          country
          contact_email
          contact_phone
          website
          sport
          createdAt
          updatedAt
          __typename
        }
        agent {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          agent_role
          agencyID
          createdAt
          updatedAt
          __typename
        }
        favorited {
          nextToken
          __typename
        }
        enquiries {
          nextToken
          __typename
        }
        tags {
          nextToken
          __typename
        }
        showcases_participation {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const favoriteFolderByCoach = /* GraphQL */ `
  query FavoriteFolderByCoach(
    $coachID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelFavoritesFolderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    favoriteFolderByCoach(
      coachID: $coachID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        folder_name
        status
        coachID
        coach {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          disabledByUser
          is_active
          allow_whatsapp_contact
          deleteAppReason
          division
          role
          sport
          teamID
          createdAt
          updatedAt
          __typename
        }
        athletes_in_folder {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const enquiriesByAthlete = /* GraphQL */ `
  query EnquiriesByAthlete(
    $athleteID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelAthleteEnquiryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    enquiriesByAthlete(
      athleteID: $athleteID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        enquiry_text
        athleteID
        coachID
        athlete {
          id
          agencyID
          agentID
          name
          isVisible
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          nationality
          country_of_competition
          target_division
          compliance
          card_pic
          other_nice_pics
          agency_assesment
          elegibility_notes
          current_club
          current_status
          start_availability_date
          end_availability_date
          destination_college
          ncaa_id
          type_of_admission
          weight
          height
          sport
          position
          second_position
          dominant_foot
          level_of_competition
          sports_background
          start_date
          high_school_graduation_date
          sat_score
          sat_date
          act_score
          act_date
          gemat_gre_score
          gemat_gre_date
          toefl_score
          toefl_date
          duolingo
          duolingo_date
          ielts
          ielts_date
          estimated_gpa
          intended_field_of_studies
          intended_degree
          budget
          category
          facebook_url
          twitter_user
          linkedin_url
          reason_commitment
          date_commitment
          createdAt
          updatedAt
          __typename
        }
        coach {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          disabledByUser
          is_active
          allow_whatsapp_contact
          deleteAppReason
          division
          role
          sport
          teamID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const enquiriesByCoach = /* GraphQL */ `
  query EnquiriesByCoach(
    $coachID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelAthleteEnquiryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    enquiriesByCoach(
      coachID: $coachID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        enquiry_text
        athleteID
        coachID
        athlete {
          id
          agencyID
          agentID
          name
          isVisible
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          nationality
          country_of_competition
          target_division
          compliance
          card_pic
          other_nice_pics
          agency_assesment
          elegibility_notes
          current_club
          current_status
          start_availability_date
          end_availability_date
          destination_college
          ncaa_id
          type_of_admission
          weight
          height
          sport
          position
          second_position
          dominant_foot
          level_of_competition
          sports_background
          start_date
          high_school_graduation_date
          sat_score
          sat_date
          act_score
          act_date
          gemat_gre_score
          gemat_gre_date
          toefl_score
          toefl_date
          duolingo
          duolingo_date
          ielts
          ielts_date
          estimated_gpa
          intended_field_of_studies
          intended_degree
          budget
          category
          facebook_url
          twitter_user
          linkedin_url
          reason_commitment
          date_commitment
          createdAt
          updatedAt
          __typename
        }
        coach {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          disabledByUser
          is_active
          allow_whatsapp_contact
          deleteAppReason
          division
          role
          sport
          teamID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const feedbackByCoach = /* GraphQL */ `
  query FeedbackByCoach(
    $coachID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelFeedbackFilterInput
    $limit: Int
    $nextToken: String
  ) {
    feedbackByCoach(
      coachID: $coachID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        feedback_text
        type
        coachID
        coach {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          disabledByUser
          is_active
          allow_whatsapp_contact
          deleteAppReason
          division
          role
          sport
          teamID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchTermByCoach = /* GraphQL */ `
  query SearchTermByCoach(
    $coachID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelSearchTermFilterInput
    $limit: Int
    $nextToken: String
  ) {
    searchTermByCoach(
      coachID: $coachID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        search_term_text
        search_type
        sport
        source_app
        coachID
        coach {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          disabledByUser
          is_active
          allow_whatsapp_contact
          deleteAppReason
          division
          role
          sport
          teamID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchFilterByCoach = /* GraphQL */ `
  query SearchFilterByCoach(
    $coachID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelSearchFilterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    searchFilterByCoach(
      coachID: $coachID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        filter_body
        type
        sport
        source_app
        coachID
        coach {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          disabledByUser
          is_active
          allow_whatsapp_contact
          deleteAppReason
          division
          role
          sport
          teamID
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTeam = /* GraphQL */ `
  query GetTeam($id: ID!) {
    getTeam(id: $id) {
      id
      name
      sport
      genre
      suscription_name
      suscription_recurrency
      suscription_status
      suscription_stripe_id
      suscription_premium_start_date
      suscription_premium_end_date
      team_logo
      conference
      division
      college_category
      url_domain
      had_trial
      collegeID
      college {
        id
        name
        country
        state
        address
        logo
        createdAt
        updatedAt
        teams {
          nextToken
          __typename
        }
        __typename
      }
      coaches {
        items {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          disabledByUser
          is_active
          allow_whatsapp_contact
          deleteAppReason
          division
          role
          sport
          teamID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      subscription_history {
        items {
          id
          start_date
          end_date
          suscription_name
          teamID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      subscriberID
      subscriber {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
          __typename
        }
        my_filters {
          nextToken
          __typename
        }
        my_favorite_folders {
          nextToken
          __typename
        }
        my_notifications {
          nextToken
          __typename
        }
        showcases_interested_in {
          nextToken
          __typename
        }
        my_athlete_tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          had_trial
          collegeID
          subscriberID
          subscription_initial_date
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      subscription_initial_date
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTeams = /* GraphQL */ `
  query ListTeams(
    $filter: ModelTeamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeams(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        sport
        genre
        suscription_name
        suscription_recurrency
        suscription_status
        suscription_stripe_id
        suscription_premium_start_date
        suscription_premium_end_date
        team_logo
        conference
        division
        college_category
        url_domain
        had_trial
        collegeID
        college {
          id
          name
          country
          state
          address
          logo
          __typename
        }
        coaches {
          items {
            id
            name
            profile_pic
            birthdate
            gender
            contact_email
            contact_phone
            disabledByUser
            is_active
            allow_whatsapp_contact
            deleteAppReason
            division
            role
            sport
            teamID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        subscription_history {
          nextToken
          __typename
        }
        subscriberID
        subscriber {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          disabledByUser
          is_active
          allow_whatsapp_contact
          deleteAppReason
          division
          role
          sport
          teamID
          team {
            id
            name
            sport
            genre
            division
            college_category
            __typename
          }
          __typename
        }
        subscription_initial_date
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const teamsByCollege = /* GraphQL */ `
  query TeamsByCollege(
    $collegeID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelTeamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    teamsByCollege(
      collegeID: $collegeID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        sport
        genre
        suscription_name
        suscription_recurrency
        suscription_status
        suscription_stripe_id
        suscription_premium_start_date
        suscription_premium_end_date
        team_logo
        conference
        division
        college_category
        url_domain
        had_trial
        collegeID
        college {
          id
          name
          country
          state
          address
          logo
          createdAt
          updatedAt
          __typename
        }
        coaches {
          nextToken
          __typename
        }
        subscription_history {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAuxCollegeCoachInfo = /* GraphQL */ `
  query GetAuxCollegeCoachInfo($id: ID!) {
    getAuxCollegeCoachInfo(id: $id) {
      id
      conference
      state
      division
      uniqueID
      sport
      added
      removed
      school
      first_name
      last_name
      position
      contact_email
      contact_phone
      landing_pages
      individual_twitter
      team_twitter
      individual_facebook
      team_facebook
      individual_instagram
      team_instagram
      questionnaire
      school2
      state2
      city
      region
      size_of_city
      private_or_public
      religious
      hbcu
      average_gpa
      sat_reading
      sat_math
      act_composite
      acceptance_rate
      total_yearly_cost
      school3
      majors_offered
      number_of_undergrads
      us_ranking
      us_arts_ranking
      ipeds
      college_category
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAuxCollegeCoachInfos = /* GraphQL */ `
  query ListAuxCollegeCoachInfos(
    $filter: ModelAuxCollegeCoachInfoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAuxCollegeCoachInfos(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        conference
        state
        division
        uniqueID
        sport
        added
        removed
        school
        first_name
        last_name
        position
        contact_email
        contact_phone
        landing_pages
        individual_twitter
        team_twitter
        individual_facebook
        team_facebook
        individual_instagram
        team_instagram
        questionnaire
        school2
        state2
        city
        region
        size_of_city
        private_or_public
        religious
        hbcu
        average_gpa
        sat_reading
        sat_math
        act_composite
        acceptance_rate
        total_yearly_cost
        school3
        majors_offered
        number_of_undergrads
        us_ranking
        us_arts_ranking
        ipeds
        college_category
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
