import React, { useState }  from "react";
// Chakra imports
import {
    Flex,
    Icon,
    Table,
    Tbody,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    Radio, RadioGroup, ScaleFade, Button
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import DashboardRankingCoachTableRow from "components/Tables/DashboardRankingCoachTableRow";
import { CSVLink } from "react-csv";
import { HiDownload } from "react-icons/hi";
  
const RankingsForCoaches = ({ title,subtitle,captions,athletesEnquirys,coachAthleteFavorites,searchTerms,coaches,orderAscendant,dataToChange }) => {

    // console.warn("[RankingsForCoaches] Me renderizo: ",dataToChange)

    const [hasRender, setRender] = useState(false);
    const onShow = React.useCallback(() => setRender(true), []);

    const [sportFilter, setSportFilter] = useState({
		'MostActive':'Total',
		'LeastActive':'Total',
	})

    const getCoachActivityStatistics = () => {
		let stats = []

		athletesEnquirys?.filter(item=>(item.coach?.sport === sportFilter[dataToChange] || sportFilter[dataToChange] === 'Total') && item.coach?.is_active && new Date(item.createdAt).getMonth() === new Date().getMonth() && new Date(item.createdAt).getFullYear() === new Date().getFullYear()).map(item => {
			const exist = stats.find(it => it.coach === item.coachID)
			if(exist){exist.messages += 1}
			else{stats.push({coach: item.coachID,messages: 1})}
		})
		coachAthleteFavorites?.filter(item=>(item.coach?.sport === sportFilter[dataToChange] || sportFilter[dataToChange] === 'Total') && item.coach?.is_active && new Date(item.createdAt).getMonth() === new Date().getMonth() && new Date(item.createdAt).getFullYear() === new Date().getFullYear()).map(item => {
			const exist = stats.find(it => it.coach === item.coachID)
			if(exist){if(exist.favorites){exist.favorites += 1}else{exist.favorites = 1}}
			else{stats.push({coach: item.coachID,favorites: 1})}
		})
		searchTerms?.filter(item=>(item.coach?.sport === sportFilter[dataToChange] || sportFilter[dataToChange] === 'Total') && item.coach?.is_active && new Date(item.createdAt).getMonth() === new Date().getMonth() && new Date(item.createdAt).getFullYear() === new Date().getFullYear()).map(item => {
			const exist = stats.find(it => it.coach === item.coachID)
			if(exist){if(exist.searches){exist.searches += 1}else{exist.searches = 1}}
			else{stats.push({coach: item.coachID,searches: 1})}
		})
		return stats

	}

    // const getCoachActivityByMonth = () => {
    //     let stats = []

	// 	athletesEnquirys?.filter(item=> item.coach?.is_active && new Date(item.createdAt).getMonth() === new Date().getMonth()-4 && new Date(item.createdAt).getFullYear() === new Date().getFullYear()).map(item => {
	// 		const exist = stats.find(it => it.Coach === item.coachID)
	// 		if(exist){
    //             exist.messages += 1
    //             exist.msgLastDate = (item.createdAt > exist.msgLastDate) ? item.createdAt : exist.msgLastDate
    //             exist.Coach = (item.createdAt > exist.msgLastDate) ? item.coachID : exist.Coach
    //             exist['Coach Name'] = (item.createdAt > exist.msgLastDate) ? item.coach?.name : exist['Coach Name']
    //             exist['Coach University'] = (item.createdAt > exist.msgLastDate) ? item.coach?.team?.name : exist['Coach University']
    //             exist['Coach Division'] = (item.createdAt > exist.msgLastDate) ? item.coach?.division : exist['Coach Division']
    //         }
	// 		else{
    //             stats.push(
    //                 {
    //                     Coach: item.coachID,
    //                     'Coach Name': item.coach?.name,
    //                     'Coach University': item.coach?.team?.name,
    //                     'Coach Division': item.coach?.division,
    //                     messages: 1,
    //                     msgLastDate: item.createdAt,
    //                 }
    //             )
    //         }
	// 	})
	// 	coachAthleteFavorites?.filter(item=> item.coach?.is_active && new Date(item.createdAt).getMonth() === new Date().getMonth()-4 && new Date(item.createdAt).getFullYear() === new Date().getFullYear()).map(item => {
	// 		const exist = stats.find(it => it.Coach === item.coachID)
	// 		if(exist){
    //             if(exist.favorites){
    //                 exist.favorites += 1
    //                 exist.favLastDate = (item.createdAt > exist.favLastDate) ? item.createdAt : exist.favLastDate
    //                 exist.Coach = (item.createdAt > exist.favLastDate) ? item.coachID : exist.Coach
    //                 exist['Coach Name'] = (item.createdAt > exist.favLastDate) ? item.coach?.name : exist['Coach Name']
    //                 exist['Coach University'] = (item.createdAt > exist.favLastDate) ? item.coach?.team?.name : exist['Coach University']
    //                 exist['Coach Division'] = (item.createdAt > exist.favLastDate) ? item.coach?.division : exist['Coach Division']
    //             }else{
    //                 exist.favorites = 1
    //                 exist.favLastDate = item.createdAt
    //                 exist.Coach = item.coachID
    //                 exist['Coach Name'] = item.coach?.name
    //                 exist['Coach University'] = item.coach?.team?.name
    //                 exist['Coach Division'] = item.coach?.division
    //             }
    //         }
	// 		else{
    //             stats.push(
    //                 {
    //                     Coach: item.coachID,
    //                     'Coach Name': item.coach?.name,
    //                     'Coach University': item.coach?.team?.name,
    //                     'Coach Division': item.coach?.division,
    //                     favorites: 1,
    //                     favLastDate: item.createdAt,
    //                 }
    //             )
    //         }
	// 	})
	// 	searchTerms?.filter(item=> item.coach?.is_active && new Date(item.createdAt).getMonth() === new Date().getMonth()-4 && new Date(item.createdAt).getFullYear() === new Date().getFullYear()).map(item => {
	// 		const exist = stats.find(it => it.Coach === item.coachID)
	// 		if(exist){
    //             if(exist.searches){
    //                 exist.searches += 1
    //                 exist.searchLastDate = (item.createdAt > exist.searchLastDate) ? item.createdAt : exist.searchLastDate
    //                 exist.Coach = (item.createdAt > exist.searchLastDate) ? item.coachID : exist.Coach
    //                 exist['Coach Name'] = (item.createdAt > exist.searchLastDate) ? item.coach?.name : exist['Coach Name']
    //                 exist['Coach University'] = (item.createdAt > exist.searchLastDate) ? item.coach?.team?.name : exist['Coach University']
    //                 exist['Coach Division'] = (item.createdAt > exist.searchLastDate) ? item.coach?.division : exist['Coach Division']

    //             }else{
    //                 exist.searches = 1
    //                 exist.searchLastDate = item.createdAt
    //                 exist.Coach = item.coachID
    //                 exist['Coach Name'] = item.coach?.name
    //                 exist['Coach University'] = item.coach?.team?.name
    //                 exist['Coach Division'] = item.coach?.division
    //             }
    //         }
	// 		else{
    //             stats.push(
    //                 {
    //                     Coach: item.coachID,
    //                     'Coach Name': item.coach?.name,
    //                     'Coach University': item.coach?.team?.name,
    //                     'Coach Division': item.coach?.division,
    //                     searches: 1,
    //                     searchLastDate: item.createdAt,
    //                 }
    //             )
    //         }
	// 	})
	// 	return stats.sort(
    //         (it1,it2)=>
    //             orderAscendant ? (
    //                 ((it1.messages ? it1.messages : 0) +(it1.favorites ? it1.favorites : 0)+(it1.searches ? it1.searches : 0))
    //                 -
    //                 ((it2.messages ? it2.messages : 0 )+(it2.favorites ? it2.favorites : 0)+(it2.searches ? it2.searches : 0))
    //             ):(
    //                 ((it2.messages ? it2.messages : 0 )+(it2.favorites ? it2.favorites : 0)+(it2.searches ? it2.searches : 0))
    //                 -
    //                 ((it1.messages ? it1.messages : 0) +(it1.favorites ? it1.favorites : 0)+(it1.searches ? it1.searches : 0))
    //             )
    //         )

	// }

    const textColor = useColorModeValue("gray.700", "white");  

    return (
        <>
            { hasRender && (
				<ScaleFade in={hasRender} >
                    <Card p='16px' overflowX={{ sm: "scroll", xl: "hidden" }}>
                        {/* <CSVLink
							data={getCoachActivityByMonth()}
							filename={"CoachActivityJanuary2023.csv"}
						>
							<Button
								colorScheme='green'
								variant='ghost'
								leftIcon={<Icon as={HiDownload}/>}
								size='sm'
							>
								Export to CSV
							</Button>
						</CSVLink> */}
                        <CardHeader p='12px 0px 15px 0px'>
                            <Flex direction='column'>
                                <Text fontSize='lg' color={textColor} fontWeight='bold' pb='.5rem'>
                                {title}
                                </Text>
                                <Text fontSize='md' fontWeight='medium' color='gray.400'>
                                {subtitle}
                                </Text>
                                <RadioGroup onChange={(e)=>setSportFilter({...sportFilter,[dataToChange]:e})} value={sportFilter && sportFilter[dataToChange]}>
                                    <Flex direction='row'>
                                        <Radio value='Total' mr="20px">
                                            <Text fontSize='md' fontWeight='medium' color='gray.400'>
                                            Total
                                            </Text>
                                        </Radio>
                                        <Radio value='Soccer' mr="20px">
                                            <Text fontSize='md' fontWeight='medium' color='gray.400'>
                                            Soccer
                                            </Text>
                                        </Radio>
                                        <Radio value='Tennis' mr="20px">
                                            <Text fontSize='md' fontWeight='medium' color='gray.400'>
                                            Tennis
                                            </Text>
                                        </Radio>
                                    </Flex>
                                </RadioGroup>
                            </Flex>
                        </CardHeader>
                        <Table variant='simple' color={textColor}>
                            <Thead>
                                <Tr my='.4rem' ps='0px'>
                                {captions.map((caption, idx) => {
                                    return (
                                    <Th color='gray.400' key={idx} ps={idx === 0 ? "0px" : null}>
                                        {caption}
                                    </Th>
                                    );
                                })}
                                </Tr>
                            </Thead>
                            <Tbody>
                                {getCoachActivityStatistics()
                                .sort(
                                    (it1,it2)=>
                                        orderAscendant ? (
                                            ((it1.messages ? it1.messages : 0) +(it1.favorites ? it1.favorites : 0)+(it1.searches ? it1.searches : 0))
                                            -
                                            ((it2.messages ? it2.messages : 0 )+(it2.favorites ? it2.favorites : 0)+(it2.searches ? it2.searches : 0))
                                        ):(
                                            ((it2.messages ? it2.messages : 0 )+(it2.favorites ? it2.favorites : 0)+(it2.searches ? it2.searches : 0))
                                            -
                                            ((it1.messages ? it1.messages : 0) +(it1.favorites ? it1.favorites : 0)+(it1.searches ? it1.searches : 0))
                                        )
                                    )
                                .slice(0,5)
                                .map((row,idx) => {
                                    return (
                                        <DashboardRankingCoachTableRow
                                            coach={coaches?.find(coach => coach.id === row.coach)}
                                            messages={row.messages ? row.messages : 0}
                                            favorites={row.favorites ? row.favorites : 0}
                                            searches={row.searches ? row.searches : 0}
                                            key={idx}
                                        />
                                    );
                                })}
                            </Tbody>
                        </Table>
                    </Card>
                </ScaleFade>
			)}
            { !hasRender && (
				<ScaleFade in={!hasRender} >
					<Card p='28px 10px 16px 0px'>
						<CardHeader pl='22px' w="100%">
                            <Flex direction='row' alignSelf='flex-start' w="100%" justifyContent={'space-between'} alignItems="center">
								<Flex direction='column' alignSelf='flex-start' w="50%">
                                    <Text fontSize='lg' color={"gray.700"} fontWeight='bold' mb='6px'>
                                        {title}
                                    </Text>
                                    <Text fontSize='md' fontWeight='medium' color='gray.400'>
                                        {subtitle}
                                    </Text>
                                </Flex>
								<Button w="40%" colorScheme='blue' onClick={onShow} my="10px" mr="5%">
									Load chart
								</Button>
							</Flex>
						</CardHeader>
					</Card>
				</ScaleFade>
			)}
        </>
    );
};

const myPropsChanged = (prevProps, nextProps) => {
    if ( prevProps !== nextProps) {
        return false;
    }
    return true;
}

export default React.memo(RankingsForCoaches, myPropsChanged);
  

  