import awsmobile from "../aws-exports";
var AWS = require("aws-sdk");

export const createUserCognito = async ({ email, profileID }) => {
  let response = {};

  let params = {
    UserPoolId: awsmobile.aws_user_pools_id,
    Username: email,
    TemporaryPassword: "Alliance2021!",
    DesiredDeliveryMediums: ["EMAIL"],
    MessageAction: "SUPPRESS", // Para cancelar el envio de SMS y superar el limite
    UserAttributes: [
      {
        Name: "email",
        Value: email,
      },
      {
        Name: "custom:profile_id",
        Value: profileID,
      },
      {
        Name: "custom:disabledByUser",
        Value: "False",
      },
      {
        Name: "email_verified",
        Value: "True",
      },
      {
        Name: "custom:isActive",
        Value: "True",
      },
    ],
  };

  let cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider({
    region: "eu-west-1",
    apiVersion: "2016-04-18",
  });
  try {
    response = await cognitoidentityserviceprovider
      .adminCreateUser(params)
      .promise();
    return response;
  } catch (e) {
    console.log("[ERROR] Create user cognito " + e);
  }
};

export const deleteUserCognito = async (email) => {
  let response = {};
  let params = {
    UserPoolId: awsmobile.aws_user_pools_id,
    Username: email,
  };

  let cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider({
    region: "eu-west-1",
    apiVersion: "2016-04-18",
  });
  try {
    response = await cognitoidentityserviceprovider
      .adminDeleteUser(params)
      .promise();
    return response;
  } catch (e) {
    throw new Error(e);
  }
};

export const updateActiveCoachCognito = (email) => {
  let response = {};
  let params = {
    UserPoolId: awsmobile.aws_user_pools_id,
    Username: email,
    UserAttributes: [
      {
        Name: "email_verified",
        Value: "True",
      },
      {
        Name: "custom:isActive",
        Value: "True",
      },
    ],
  };

  let cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider({
    region: "eu-west-1",
    apiVersion: "2016-04-18",
  });
  try {
    response = cognitoidentityserviceprovider
      .adminUpdateUserAttributes(params)
      .promise();
    return response;
  } catch (e) {
    throw new Error(e);
  }
};

export const addUserToGroupCognito = async (email, groupName) => {
  let response = {};
  let params = {
    UserPoolId: awsmobile.aws_user_pools_id,
    Username: email,
    GroupName: groupName,
  };

  let cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider({
    region: "eu-west-1",
    apiVersion: "2016-04-18",
  });
  try {
    response = await cognitoidentityserviceprovider
      .adminAddUserToGroup(params)
      .promise();
    return response;
  } catch (e) {
    throw new Error(e);
  }
};
// adminConfirmSignUp
export const confirmUserCognito = async (email) => {
  let response = {};
  let params = {
    UserPoolId: awsmobile.aws_user_pools_id,
    Username: email,
  };

  let cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider({
    region: "eu-west-1",
    apiVersion: "2016-04-18",
  });
  try {
    response = await cognitoidentityserviceprovider
      .adminConfirmSignUp(params)
      .promise();
    return response;
  } catch (e) {
    throw new Error(e);
  }
};

// Remove user from group
export const removeUserFromGroupCognito = async (email, groupName) => {
  let response = {};
  let params = {
    UserPoolId: awsmobile.aws_user_pools_id,
    Username: email,
    GroupName: groupName,
  };

  let cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider({
    region: "eu-west-1",
    apiVersion: "2016-04-18",
  });
  try {
    response = await cognitoidentityserviceprovider
      .adminRemoveUserFromGroup(params)
      .promise();
    return response;
  } catch (e) {
    throw new Error(e);
  }
};

export const updateSDKToken = async (user) => {
  // Get idToken
  const idToken = user.signInUserSession.idToken.jwtToken;
  // console.log("idToken: ", idToken);
  // Get the id for user from identity pool
  const cognitoidentity = new AWS.CognitoIdentity({
    apiVersion: "2014-06-30",
    region: "eu-west-1",
  });

  try {
    cognitoidentity.getId(
      {
        IdentityPoolId: awsmobile.aws_cognito_identity_pool_id,
        Logins: {
          [`cognito-idp.eu-west-1.amazonaws.com/${awsmobile.aws_user_pools_id}`]: idToken,
        },
      },
      (err, data) => {
        if (err) {
          console.log("Error getting identity id: ", err, err.message);
          // Check if the error is token expired, then we need to refresh the token
          if (
            err.code === "NotAuthorizedException" &&
            err.message.includes("expired")
          ) {
            Auth.currentAuthenticatedUser();
          }
        } else {
          // console.log("Data: ", data);
          cognitoidentity.getCredentialsForIdentity(
            {
              // CustomRoleArn: `arn:aws:iam::706639107723:role/${role}`, Change role from groups (User with 2+ groups)
              IdentityId: data.IdentityId,
              Logins: {
                [`cognito-idp.eu-west-1.amazonaws.com/${awsmobile.aws_user_pools_id}`]: idToken,
              },
            },
            (error, data2) => {
              if (error) {
                console.log(
                  "[ERROR] Error getting credentials for identity id",
                  error,
                  error.message
                );
              } else {
                // console.log("[OK] Credentials: ", data2);
                // Update aws-sdk with credentials that allow to assume role
                AWS.config.update({
                  region: "eu-west-1",
                  credentials: new AWS.Credentials(
                    data2.Credentials.AccessKeyId,
                    data2.Credentials.SecretKey,
                    data2.Credentials.SessionToken
                  ),
                });
              }
            }
          );
        }
      }
    );
  } catch (error) {
    console.log("Error updating SDK token: ", error);
  }
};

export const checkExpiredAndRefreshToken = async (user) => {
  const cognitoidentity = new AWS.CognitoIdentity({
    apiVersion: "2014-06-30",
    region: "eu-west-1",
  });

  if (user.signInUserSession) {
    cognitoidentity.getId(
      {
        IdentityPoolId: awsmobile.aws_cognito_identity_pool_id,
        Logins: {
          [`cognito-idp.eu-west-1.amazonaws.com/${awsmobile.aws_user_pools_id}`]: user
            .signInUserSession.idToken.jwtToken,
        },
      },
      (err, data) => {
        if (err) {
          // Check if the error is token expired
          if (
            err.code === "NotAuthorizedException" &&
            err.message.includes("expired")
          ) {
            updateSDKToken(user);
          }
        } else {
          updateSDKToken(user);
        }
      }
    );
  }
};
