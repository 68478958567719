import { Avatar, AvatarGroup, Button, Checkbox, Flex, Link, Td, Text, Tr, useColorModeValue, useDisclosure } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'

export const NotificationTrackerRow = ({row,selectItemTracker,handleSelectItemTracker}) => {

    const textColor = useColorModeValue("gray.700", "white");

    const [seeMore, setSeeMore] = useState(false);

    const processText = (text) => {

		if(!seeMore && text.length > 100){
			let sliceText = text.slice(0,100).replaceAll("\n",' ')
			sliceText = sliceText + '...'
			return sliceText.split("\n").map(it=>{return <>
				{it}<br />
			</>})
		}
		else{
			return text.split("\n").map(it=>{return <>
				{it}<br />
			</>})
		}
	}

    const [isSelected, setIsSelected] = useState(false)
	useEffect(() => {
		selectItemTracker.find((item) => JSON.stringify(item) === JSON.stringify(row)) ? setIsSelected(true) : setIsSelected(false)
	}, [selectItemTracker])


    return (
        <>
            <Tr>
                <Td>
                    <Checkbox colorScheme='blue' value={row} onChange={(e)=>(handleSelectItemTracker(e,row))} isChecked={isSelected} />
                </Td>
                <Td>
                    <Text
                        fontSize="sm"
                        color={textColor}
                        fontWeight="bold"
                    >
                        {row.title}
                    </Text>
                </Td>
                <Td>
                    <Flex direction={"column"}>
                        <Text
                            fontSize="sm"
                            color={textColor}
                            >
                            {processText(row.content)} 

                        </Text>

                        {
                            row.content.length > 100 && (
                                <Link 
                                    color="blue" 
                                    fontSize="sm"
                                    mt={"10px"}
                                    onClick={()=>setSeeMore(!seeMore)}
                                >
                                    Read {seeMore ? 'less' : 'more'}
                                </Link>
                            )
                        }
                    </Flex>
                </Td>
                <Td>
                    <Text
                        fontSize="sm"
                        color={textColor}
                    >
                        {new Date(row.date).toLocaleDateString()}
                    </Text>
                </Td>
                <Td>
                    {
                        row.sentTo?.length > 0 ? (
                            <AvatarGroup size='sm' max={4}>
                                {row.sentTo?.map((it,idx) => {
                                    return (
                                        <Avatar key={idx} name={it.coach?.name}  />
                                    )
                                })}
                            </AvatarGroup>	
                        ) : (
                            <Text
                                fontSize="sm"
                                color={"gray.400"}
                                >
                                    Recipient/s may delete this notification.
                            </Text>
                        )
                    }
                </Td>
                <Td>
                    <Text
                        fontSize="sm"
                        color={textColor}
                    >
                        {row.seen}
                    </Text>
                </Td>
            </Tr>
        </>
    )
}
