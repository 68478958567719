// 
// ATHLETES
// 
export const getAthleteTag = /* GraphQL */ `
  query GetAthleteTag($id: ID!) {
    getAthleteTag(id: $id) {
      id
      text
      type
      athletes {
        items {
          id
          athleteID
          tagID
          createdAt
          updatedAt
        }
        nextToken
      }
      coaches_owners {
        items {
          id
          coachID
          tagID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listAthleteTags = /* GraphQL */ `
  query ListAthleteTags(
    $filter: ModelAthleteTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAthleteTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        text
        type
        athletes {
          nextToken
        }
        coaches_owners {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const createAthleteTag = /* GraphQL */ `
  mutation CreateAthleteTag(
    $input: CreateAthleteTagInput!
    $condition: ModelAthleteTagConditionInput
  ) {
    createAthleteTag(input: $input, condition: $condition) {
      id
      text
      type
      athletes {
        items {
          id
          athleteID
          tagID
          createdAt
          updatedAt
        }
        nextToken
      }
      coaches_owners {
        items {
          id
          coachID
          tagID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateAthleteTag = /* GraphQL */ `
  mutation UpdateAthleteTag(
    $input: UpdateAthleteTagInput!
    $condition: ModelAthleteTagConditionInput
  ) {
    updateAthleteTag(input: $input, condition: $condition) {
      id
      text
      type
      athletes {
        items {
          id
          athleteID
          tagID
          createdAt
          updatedAt
        }
        nextToken
      }
      coaches_owners {
        items {
          id
          coachID
          tagID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteAthleteTag = /* GraphQL */ `
  mutation DeleteAthleteTag(
    $input: DeleteAthleteTagInput!
    $condition: ModelAthleteTagConditionInput
  ) {
    deleteAthleteTag(input: $input, condition: $condition) {
      id
      text
      type
      athletes {
        items {
          id
          athleteID
          tagID
          createdAt
          updatedAt
        }
        nextToken
      }
      coaches_owners {
        items {
          id
          coachID
          tagID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;

export const getAthleteTagsRelation = /* GraphQL */ `
  query GetAthleteTagsRelation($id: ID!) {
    getAthleteTagsRelation(id: $id) {
      id
      athleteID
      tagID
      athlete {
        id
        agencyID
        agentID
        name
        isVisible
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        nationality
        country_of_competition
        target_division
        compliance
        card_pic
        other_nice_pics
        agency_assesment
        elegibility_notes
        current_club
        current_status
        start_availability_date
        end_availability_date
        destination_college
        ncaa_id
        type_of_admission
        weight
        height
        sport
        tennis_profile {
          utr
          national_ranking
          international_ranking
          DominantHand
        }
        position
        second_position
        dominant_foot
        featured_video {
          url
          metadata
        }
        other_videos {
          url
          metadata
        }
        level_of_competition
        sports_background
        showcase_participation {
          showcase_name
          city
          country
          date
        }
        start_date
        high_school_graduation_date
        sat_score
        sat_date
        act_score
        act_date
        gemat_gre_score
        gemat_gre_date
        toefl_score
        toefl_date
        duolingo
        duolingo_date
        ielts
        ielts_date
        estimated_gpa
        intended_field_of_studies
        intended_degree
        budget
        facebook_url
        twitter_user
        linkedin_url
        agency {
          id
          name
          logo
          country
          contact_email
          contact_phone
          website
          sport
          createdAt
          updatedAt
        }
        agent {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          agent_role
          agencyID
          createdAt
          updatedAt
        }
        favorited {
          nextToken
        }
        enquiries {
          nextToken
        }
        tags {
          nextToken
        }
        showcases_participation {
          nextToken
        }
        createdAt
        updatedAt
      }
      tag {
        id
        text
        type
        athletes {
          nextToken
        }
        coaches_owners {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listAthleteTagsRelations = /* GraphQL */ `
  query ListAthleteTagsRelations(
    $filter: ModelAthleteTagsRelationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAthleteTagsRelations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        athleteID
        tagID
        athlete {
          id
          agencyID
          agentID
          name
          isVisible
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          nationality
          country_of_competition
          target_division
          compliance
          card_pic
          other_nice_pics
          agency_assesment
          elegibility_notes
          current_club
          current_status
          start_availability_date
          end_availability_date
          destination_college
          ncaa_id
          type_of_admission
          weight
          height
          sport
          position
          second_position
          dominant_foot
          level_of_competition
          sports_background
          start_date
          high_school_graduation_date
          sat_score
          sat_date
          act_score
          act_date
          gemat_gre_score
          gemat_gre_date
          toefl_score
          toefl_date
          duolingo
          duolingo_date
          ielts
          ielts_date
          estimated_gpa
          intended_field_of_studies
          intended_degree
          budget
          facebook_url
          twitter_user
          linkedin_url
          createdAt
          updatedAt
        }
        tag {
          id
          text
          type
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const createAthleteTagsRelation = /* GraphQL */ `
  mutation CreateAthleteTagsRelation(
    $input: CreateAthleteTagsRelationInput!
    $condition: ModelAthleteTagsRelationConditionInput
  ) {
    createAthleteTagsRelation(input: $input, condition: $condition) {
      id
      athleteID
      tagID
      athlete {
        id
        agencyID
        agentID
        name
        isVisible
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        nationality
        country_of_competition
        target_division
        compliance
        card_pic
        other_nice_pics
        agency_assesment
        elegibility_notes
        current_club
        current_status
        start_availability_date
        end_availability_date
        destination_college
        ncaa_id
        type_of_admission
        weight
        height
        sport
        tennis_profile {
          utr
          national_ranking
          international_ranking
          DominantHand
        }
        position
        second_position
        dominant_foot
        featured_video {
          url
          metadata
        }
        other_videos {
          url
          metadata
        }
        level_of_competition
        sports_background
        showcase_participation {
          showcase_name
          city
          country
          date
        }
        start_date
        high_school_graduation_date
        sat_score
        sat_date
        act_score
        act_date
        gemat_gre_score
        gemat_gre_date
        toefl_score
        toefl_date
        duolingo
        duolingo_date
        ielts
        ielts_date
        estimated_gpa
        intended_field_of_studies
        intended_degree
        budget
        facebook_url
        twitter_user
        linkedin_url
        agency {
          id
          name
          logo
          country
          contact_email
          contact_phone
          website
          sport
          createdAt
          updatedAt
        }
        agent {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          agent_role
          agencyID
          createdAt
          updatedAt
        }
        favorited {
          nextToken
        }
        enquiries {
          nextToken
        }
        tags {
          nextToken
        }
        showcases_participation {
          nextToken
        }
        createdAt
        updatedAt
      }
      tag {
        id
        text
        type
        athletes {
          nextToken
        }
        coaches_owners {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateAthleteTagsRelation = /* GraphQL */ `
  mutation UpdateAthleteTagsRelation(
    $input: UpdateAthleteTagsRelationInput!
    $condition: ModelAthleteTagsRelationConditionInput
  ) {
    updateAthleteTagsRelation(input: $input, condition: $condition) {
      id
      athleteID
      tagID
      athlete {
        id
        agencyID
        agentID
        name
        isVisible
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        nationality
        country_of_competition
        target_division
        compliance
        card_pic
        other_nice_pics
        agency_assesment
        elegibility_notes
        current_club
        current_status
        start_availability_date
        end_availability_date
        destination_college
        ncaa_id
        type_of_admission
        weight
        height
        sport
        tennis_profile {
          utr
          national_ranking
          international_ranking
          DominantHand
        }
        position
        second_position
        dominant_foot
        featured_video {
          url
          metadata
        }
        other_videos {
          url
          metadata
        }
        level_of_competition
        sports_background
        showcase_participation {
          showcase_name
          city
          country
          date
        }
        start_date
        high_school_graduation_date
        sat_score
        sat_date
        act_score
        act_date
        gemat_gre_score
        gemat_gre_date
        toefl_score
        toefl_date
        duolingo
        duolingo_date
        ielts
        ielts_date
        estimated_gpa
        intended_field_of_studies
        intended_degree
        budget
        facebook_url
        twitter_user
        linkedin_url
        agency {
          id
          name
          logo
          country
          contact_email
          contact_phone
          website
          sport
          createdAt
          updatedAt
        }
        agent {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          agent_role
          agencyID
          createdAt
          updatedAt
        }
        favorited {
          nextToken
        }
        enquiries {
          nextToken
        }
        tags {
          nextToken
        }
        showcases_participation {
          nextToken
        }
        createdAt
        updatedAt
      }
      tag {
        id
        text
        type
        athletes {
          nextToken
        }
        coaches_owners {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteAthleteTagsRelation = /* GraphQL */ `
  mutation DeleteAthleteTagsRelation(
    $input: DeleteAthleteTagsRelationInput!
    $condition: ModelAthleteTagsRelationConditionInput
  ) {
    deleteAthleteTagsRelation(input: $input, condition: $condition) {
      id
      athleteID
      tagID
      athlete {
        id
        agencyID
        agentID
        name
        isVisible
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        nationality
        country_of_competition
        target_division
        compliance
        card_pic
        other_nice_pics
        agency_assesment
        elegibility_notes
        current_club
        current_status
        start_availability_date
        end_availability_date
        destination_college
        ncaa_id
        type_of_admission
        weight
        height
        sport
        tennis_profile {
          utr
          national_ranking
          international_ranking
          DominantHand
        }
        position
        second_position
        dominant_foot
        featured_video {
          url
          metadata
        }
        other_videos {
          url
          metadata
        }
        level_of_competition
        sports_background
        showcase_participation {
          showcase_name
          city
          country
          date
        }
        start_date
        high_school_graduation_date
        sat_score
        sat_date
        act_score
        act_date
        gemat_gre_score
        gemat_gre_date
        toefl_score
        toefl_date
        duolingo
        duolingo_date
        ielts
        ielts_date
        estimated_gpa
        intended_field_of_studies
        intended_degree
        budget
        facebook_url
        twitter_user
        linkedin_url
        agency {
          id
          name
          logo
          country
          contact_email
          contact_phone
          website
          sport
          createdAt
          updatedAt
        }
        agent {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          agent_role
          agencyID
          createdAt
          updatedAt
        }
        favorited {
          nextToken
        }
        enquiries {
          nextToken
        }
        tags {
          nextToken
        }
        showcases_participation {
          nextToken
        }
        createdAt
        updatedAt
      }
      tag {
        id
        text
        type
        athletes {
          nextToken
        }
        coaches_owners {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;

// 
// COACHES 
// 

export const getCoachTagsRelation = /* GraphQL */ `
  query GetCoachTagsRelation($id: ID!) {
    getCoachTagsRelation(id: $id) {
      id
      coachID
      tagID
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
        }
        my_filters {
          nextToken
        }
        my_favorite_folders {
          nextToken
        }
        my_notifications {
          nextToken
        }
        showcases_interested_in {
          nextToken
        }
        my_athlete_tags {
          nextToken
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          had_trial
          team_logo
          conference
          division
          college_category
          url_domain
          collegeID
          createdAt
          updatedAt
        }
      }
      tag {
        id
        text
        type
        athletes {
          nextToken
        }
        coaches_owners {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCoachTagsRelations = /* GraphQL */ `
  query ListCoachTagsRelations(
    $filter: ModelCoachTagsRelationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCoachTagsRelations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        coachID
        tagID
        coach {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          disabledByUser
          is_active
          allow_whatsapp_contact
          deleteAppReason
          division
          role
          sport
          teamID
          team {
            name
          }
          createdAt
          updatedAt
        }
        tag {
          id
          text
          type
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const createCoachTagsRelation = /* GraphQL */ `
  mutation CreateCoachTagsRelation(
    $input: CreateCoachTagsRelationInput!
    $condition: ModelCoachTagsRelationConditionInput
  ) {
    createCoachTagsRelation(input: $input, condition: $condition) {
      id
      coachID
      tagID
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
        }
        my_filters {
          nextToken
        }
        my_favorite_folders {
          nextToken
        }
        my_notifications {
          nextToken
        }
        showcases_interested_in {
          nextToken
        }
        my_athlete_tags {
          nextToken
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          had_trial
          team_logo
          conference
          division
          college_category
          url_domain
          collegeID
          createdAt
          updatedAt
        }
      }
      tag {
        id
        text
        type
        athletes {
          nextToken
        }
        coaches_owners {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCoachTagsRelation = /* GraphQL */ `
  mutation UpdateCoachTagsRelation(
    $input: UpdateCoachTagsRelationInput!
    $condition: ModelCoachTagsRelationConditionInput
  ) {
    updateCoachTagsRelation(input: $input, condition: $condition) {
      id
      coachID
      tagID
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
        }
        my_filters {
          nextToken
        }
        my_favorite_folders {
          nextToken
        }
        my_notifications {
          nextToken
        }
        showcases_interested_in {
          nextToken
        }
        my_athlete_tags {
          nextToken
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          had_trial
          team_logo
          conference
          division
          college_category
          url_domain
          collegeID
          createdAt
          updatedAt
        }
      }
      tag {
        id
        text
        type
        athletes {
          nextToken
        }
        coaches_owners {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCoachTagsRelation = /* GraphQL */ `
  mutation DeleteCoachTagsRelation(
    $input: DeleteCoachTagsRelationInput!
    $condition: ModelCoachTagsRelationConditionInput
  ) {
    deleteCoachTagsRelation(input: $input, condition: $condition) {
      id
      coachID
      tagID
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
        }
        my_filters {
          nextToken
        }
        my_favorite_folders {
          nextToken
        }
        my_notifications {
          nextToken
        }
        showcases_interested_in {
          nextToken
        }
        my_athlete_tags {
          nextToken
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          had_trial
          team_logo
          conference
          division
          college_category
          url_domain
          collegeID
          createdAt
          updatedAt
        }
      }
      tag {
        id
        text
        type
        athletes {
          nextToken
        }
        coaches_owners {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;

