import React from 'react';
import styled from 'styled-components'
import Lottie from 'react-lottie'
import lottieAnimationData from './loadingAnimation.json';

const Loading = (props) => {
    const { visible } = props
    const lottieDefaultOptions = {
        loop: true,
        autoplay: true,
        animationData: lottieAnimationData,
    };
    return (
        <SLoading visible={visible}>
            <Lottie 
                options={lottieDefaultOptions}
                height={100}
                width={100}
            />
            <TextLoading>Loading...<br /><br />We're preparing all the data. <br />Please, wait.</TextLoading>
        </SLoading>
    )
}
export default Loading

const SLoading = styled.div`
    position: fixed;
    display: ${props => props.visible ? 'flex' : 'none'};
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 16px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(32,73,218,1);
    z-index: 1000;
    opacity: 0.8;
`
const TextLoading = styled.div`
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    margin-top: 24px;
`