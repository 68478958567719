// React imports
import React, { useState } from 'react'
// React-select imports
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
// Chackra Components
import { Flex, useColorModeValue, Text, FormControl,FormLabel,FormErrorMessage, Input,Spacer} from "@chakra-ui/react";
import { Divider, Tooltip, Icon } from '@chakra-ui/react'
import { NumberInput,NumberInputField,NumberInputStepper,NumberIncrementStepper,NumberDecrementStepper } from '@chakra-ui/react'
import { GoAlert } from 'react-icons/go';
import { BsQuestionCircleFill } from "react-icons/bs";
// Auxiliary imports
import { startDate, athleteTypeAdmission, IntendFields } from "../../../../helpers"


const EducationFields = ({formValues,setFormValues, formErrors}) => {

    const textColor = useColorModeValue("gray.700", "white");
	const animatedComponents = makeAnimated();

	const onChange = (e) => {
        e.persist()
        setFormValues(() => ({ ...formValues, [e.target.name]: e.target.value }))
    }


    return (
        <>
            {/* 1 row */}
            <Flex direction={{ sm: "column", lg: "row" }} w="100%" alignItems="center">
                <Flex direction="column" w={{ sm: "100%", lg: "45%" }} mt="20px">
                    <Flex direction="row" alignItems="center">
                        <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mb="5px" mr="10px" fontWeight='semibold' textAlign="justify">
                            Start date
                        </Text>
                        <Tooltip label={`This field is required.`} bg='orange.300'  placement='auto' >
                            <span><Icon as={GoAlert} color="orange"/></span>
                        </Tooltip>
                    </Flex>
                    <Select
                        closeMenuOnSelect={true}
                        components={animatedComponents}
                        placeholder="Select start date"
                        isSearchable={false}
                        defaultValue={formValues.start_date && startDate.find(item => item.value === formValues.start_date)}
                        options={startDate}
                        onChange={(selectedOptions) =>  setFormValues(()=>({...formValues, start_date: selectedOptions.value}))}
                    />
                </Flex>
                <Spacer />
                <Flex direction="column" w={{ sm: "100%", lg: "45%" }} mt="20px">
                    <FormControl isInvalid={formErrors.high_school_graduation_date !== ''}>
                        <Flex direction="row" alignItems="center">
                            <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mr="15px" fontWeight='semibold' textAlign="justify">
                                High school graduation date
                            </Text>
                            <Tooltip label={`This field is required.`} bg='orange.300'  placement='auto' >
                                <span><Icon as={GoAlert} color="orange"/></span>
                            </Tooltip>
                        </Flex>
                        <Input onChange={onChange} variant='flushed' placeholder='YYYY-MM-DD' size='sm' value={formValues.high_school_graduation_date || ''} name="high_school_graduation_date"/>
                        {formErrors.high_school_graduation_date !== '' ? <FormErrorMessage>{formErrors.high_school_graduation_date}</FormErrorMessage> : null}
                    </FormControl>
                </Flex>
            </Flex>
            {/* 2 row */}
            <Flex direction={{ sm: "column", lg: "row" }} w="100%" alignItems="center">
                <Flex direction="column" w={{ sm: "100%", lg: "45%" }} mt="20px">
                    <Flex direction="row" alignItems="center">
                        <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mb="5px" mr="10px" fontWeight='semibold' textAlign="justify">
                            Intent Field of Studies
                        </Text>
                        <Tooltip label={`This field is required.`} bg='orange.300'  placement='auto' >
                            <span><Icon as={GoAlert} color="orange"/></span>
                        </Tooltip>
                    </Flex>
                    <Select
                        closeMenuOnSelect={true}
                        components={animatedComponents}
                        placeholder="Select intended field of studies"
                        isSearchable={false}
                        defaultValue={formValues.intended_field_of_studies && IntendFields.find(item => item.value === formValues.intended_field_of_studies)}
                        options={IntendFields}
                        onChange={(selectedOptions) =>  setFormValues(()=>({...formValues, intended_field_of_studies: selectedOptions.value}))}
                    />
                </Flex>
                <Spacer />
                <Flex direction="column" w={{ sm: "100%", lg: "45%" }} mt="20px">
                    <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mr="15px" fontWeight='semibold' textAlign="justify">
                        Intended degree
                    </Text>
                    <Input onChange={onChange} variant='flushed' placeholder='Intended degree' size='sm' value={formValues.intended_degree || ''} name="intended_degree"/>

                </Flex>
            </Flex>
            {/* 3 row */}
            <Flex direction={{ sm: "column", lg: "row" }} w="100%" alignItems="center">
                <Flex direction="column" w="100%" mt="20px">
                    <Flex direction="row" alignItems="center">
                        <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mb="5px" mr="10px" fontWeight='semibold' textAlign="justify">
                            Type of admission
                        </Text>
                        <Tooltip label={`This field is required.`} bg='orange.300'  placement='auto' >
                            <span><Icon as={GoAlert} color="orange"/></span>
                        </Tooltip>
                    </Flex>
                    <Select
                        closeMenuOnSelect={true}
                        components={animatedComponents}
                        placeholder="Select admission"
                        isSearchable={false}
                        defaultValue={formValues.type_of_admission && athleteTypeAdmission.find(item => item.value === formValues.type_of_admission)}
                        options={athleteTypeAdmission}
                        onChange={(selectedOptions) =>  setFormValues(()=>({...formValues, type_of_admission: selectedOptions.value}))}
                    />
                </Flex>
            </Flex>
            <Divider mt="25px" />
            {/* 4 row */}
            <Flex direction={{ sm: "column", lg: "row" }} w="100%" alignItems="center">
                <Flex direction="column" w={{ sm: "100%", lg: "45%" }} mt="20px">
                    <Flex direction="row" alignItems="center">
                        <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mb="5px" fontWeight='semibold' textAlign="justify" mr="10px">
                            SAT Score
                        </Text>
                        <Tooltip label={`Numerical field, values between 800 - 1600 allowed.`} bg='royalblue.700'  placement='auto' >
                            <span><Icon as={BsQuestionCircleFill} color="royalblue.700" /></span>
                        </Tooltip>
                    </Flex>
                    <NumberInput defaultValue={formValues.sat_score && formValues.sat_score} step={1} min={800} max={1600} onChange={(value)=>setFormValues({...formValues,sat_score:parseInt(value,10)})} name="sat_score">
                        <NumberInputField />
                        <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                        </NumberInputStepper>
                    </NumberInput>
                </Flex>
                <Spacer />
                <Flex direction="column" w={{ sm: "100%", lg: "45%" }} mt="20px">
                    <FormControl isInvalid={formErrors.sat_date !== ''}>
                        <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mr="15px" fontWeight='semibold' textAlign="justify">
                            SAT Date
                        </Text>
                        <Input onChange={onChange} variant='flushed' placeholder='YYYY-MM-DD' size='sm' value={formValues.sat_date} name="sat_date"/>
                        {formErrors.sat_date !== '' ? <FormErrorMessage>{formErrors.sat_date}</FormErrorMessage> : null}
                    </FormControl>
                </Flex>
            </Flex>
            {/* 5 row */}
            <Flex direction={{ sm: "column", lg: "row" }} w="100%" alignItems="center">
                <Flex direction="column" w={{ sm: "100%", lg: "45%" }} mt="20px">
                    <Flex direction="row" alignItems="center">
                        <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mb="5px" fontWeight='semibold' textAlign="justify" mr="10px">
                            ACT Score
                        </Text>
                        <Tooltip label={`Numerical field, values between 1 - 36 allowed.`} bg='royalblue.700'  placement='auto' >
                            <span><Icon as={BsQuestionCircleFill} color="royalblue.700" /></span>
                        </Tooltip>
                    </Flex>
                    <NumberInput defaultValue={formValues.act_score && formValues.act_score} step={1} min={1} max={36} onChange={(value)=>setFormValues({...formValues,act_score:parseInt(value,10)})} name="act_score">
                        <NumberInputField />
                        <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                        </NumberInputStepper>
                    </NumberInput>
                </Flex>
                <Spacer />
                <Flex direction="column" w={{ sm: "100%", lg: "45%" }} mt="20px">
                    <FormControl isInvalid={formErrors.act_date !== ''}>
                        <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mr="15px" fontWeight='semibold' textAlign="justify">
                            ACT Date
                        </Text>
                        <Input onChange={onChange} variant='flushed' placeholder='YYYY-MM-DD' size='sm' value={formValues.act_date || ''} name="act_date"/>
                        {formErrors.act_date !== '' ? <FormErrorMessage>{formErrors.act_date}</FormErrorMessage> : null}
                    </FormControl>
                </Flex>
            </Flex>
            {/* 6 row */}
            <Flex direction={{ sm: "column", lg: "row" }} w="100%" alignItems="center">
                <Flex direction="column" w={{ sm: "100%", lg: "45%" }} mt="20px">
                    <Flex direction="row" alignItems="center">
                        <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mb="5px" fontWeight='semibold' textAlign="justify" mr="10px">
                            Germat GRE Score
                        </Text>
                        <Tooltip label={`Numerical field, values between 0 - 800 allowed.`} bg='royalblue.700'  placement='auto' >
                            <span><Icon as={BsQuestionCircleFill} color="royalblue.700" /></span>
                        </Tooltip>
                    </Flex>
                    <NumberInput defaultValue={formValues.gemat_gre_score && formValues.gemat_gre_score} step={1} min={0} max={800} onChange={(value)=>setFormValues({...formValues,gemat_gre_score:parseInt(value,10)})} name="gemat_gre_score">
                        <NumberInputField />
                        <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                        </NumberInputStepper>
                    </NumberInput>
                </Flex>
                <Spacer />
                <Flex direction="column" w={{ sm: "100%", lg: "45%" }} mt="20px">
                    <FormControl isInvalid={formErrors.gemat_gre_date !== ''}>
                        <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mr="15px" fontWeight='semibold' textAlign="justify">
                            Germat GRE Date
                        </Text>
                        <Input onChange={onChange} variant='flushed' placeholder='YYYY-MM-DD' size='sm' value={formValues.gemat_gre_date || ''} name="gemat_gre_date"/>
                        {formErrors.gemat_gre_date !== '' ? <FormErrorMessage>{formErrors.gemat_gre_date}</FormErrorMessage> : null}
                    </FormControl>
                </Flex>
            </Flex>

            {/* 7 row */}
            <Flex direction={{ sm: "column", lg: "row" }} w="100%" alignItems="center">
                <Flex direction="column" w={{ sm: "100%", lg: "45%" }} mt="20px">
                    <Flex direction="row" alignItems="center">
                        <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mb="5px" fontWeight='semibold' textAlign="justify" mr="10px">
                            TOEFL Score
                        </Text>
                        <Tooltip label={`Numerical field, values between 0 - 120 allowed.`} bg='royalblue.700'  placement='auto' >
                            <span><Icon as={BsQuestionCircleFill} color="royalblue.700" /></span>
                        </Tooltip>
                    </Flex>
                    <NumberInput defaultValue={formValues.toefl_score && formValues.toefl_score} step={1} min={0} max={120} onChange={(value)=>setFormValues({...formValues,toefl_score:parseInt(value,10)})} name="toefl_score">
                        <NumberInputField />
                        <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                        </NumberInputStepper>
                    </NumberInput>
                </Flex>
                <Spacer />
                <Flex direction="column" w={{ sm: "100%", lg: "45%" }} mt="20px">
                    <FormControl isInvalid={formErrors.toefl_date !== ''}>
                        <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mr="15px" fontWeight='semibold' textAlign="justify">
                            TOEFL Date
                        </Text>
                        <Input onChange={onChange} variant='flushed' placeholder='YYYY-MM-DD' size='sm' value={formValues.toefl_date || ''} name="toefl_date"/>
                        {formErrors.toefl_date !== '' ? <FormErrorMessage>{formErrors.toefl_date}</FormErrorMessage> : null}
                    </FormControl>
                </Flex>
            </Flex>
            {/* 8 row */}
            <Flex direction={{ sm: "column", lg: "row" }} w="100%" alignItems="center">
                <Flex direction="column" w={{ sm: "100%", lg: "45%" }} mt="20px">
                    <Flex direction="row" alignItems="center">
                        <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mb="5px" fontWeight='semibold' textAlign="justify" mr="10px">
                            Duolingo Score
                        </Text>                        
                        <Tooltip label={`Numerical field, values between 0 - 160 allowed.`} bg='royalblue.700'  placement='auto' >
                            <span><Icon as={BsQuestionCircleFill} color="royalblue.700" /></span>
                        </Tooltip>
                    </Flex>
                    <NumberInput defaultValue={formValues.duolingo && formValues.duolingo} step={1} min={0} max={160} onChange={(value)=>setFormValues({...formValues,duolingo:parseInt(value,10)})} name="duolingo">
                        <NumberInputField />
                        <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                        </NumberInputStepper>
                    </NumberInput>
                </Flex>
                <Spacer />
                <Flex direction="column" w={{ sm: "100%", lg: "45%" }} mt="20px">
                    <FormControl isInvalid={formErrors.duolingo_date !== ''}>
                        <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mr="15px" fontWeight='semibold' textAlign="justify">
                            Duolingo Date
                        </Text>
                        <Input onChange={onChange} variant='flushed' placeholder='YYYY-MM-DD' size='sm' value={formValues.duolingo_date || ''} name="duolingo_date"/>
                        {formErrors.duolingo_date !== '' ? <FormErrorMessage>{formErrors.duolingo_date}</FormErrorMessage> : null}
                    </FormControl>
                </Flex>
            </Flex>

            {/* 9 row */}
            <Flex direction={{ sm: "column", lg: "row" }} w="100%" alignItems="center">
                <Flex direction="column" w={{ sm: "100%", lg: "45%" }} mt="20px">
                    <Flex direction="row" alignItems="center">
                        <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mb="5px" fontWeight='semibold' textAlign="justify" mr="10px">
                            IELTS Score
                        </Text>
                        <Tooltip label={`Numerical field, values between 0 - 9 allowed.`} bg='royalblue.700'  placement='auto' >
                            <span><Icon as={BsQuestionCircleFill} color="royalblue.700" /></span>
                        </Tooltip>
                    </Flex>
                    <NumberInput defaultValue={formValues.ielts && formValues.ielts} step={1} min={0} max={9} onChange={(value)=>setFormValues({...formValues,ielts:parseInt(value,10)})} name="ielts">
                        <NumberInputField />
                        <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                        </NumberInputStepper>
                    </NumberInput>
                </Flex>
                <Spacer />
                <Flex direction="column" w={{ sm: "100%", lg: "45%" }} mt="20px">
                    <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mr="15px" fontWeight='semibold' textAlign="justify">
                    IELTS Date
                    </Text>
                    <FormControl isInvalid={formErrors.ielts_date !== ''}>
                        <Input onChange={onChange} variant='flushed' placeholder='YYYY-MM-DD' size='sm' value={formValues.ielts_date || ''} name="ielts_date"/>
                        {formErrors.ielts_date !== '' ? <FormErrorMessage>{formErrors.ielts_date}</FormErrorMessage> : null}
                    </FormControl>
                </Flex>
            </Flex>
            <Divider mt="25px" />
            {/* 10 row */}
            <Flex direction={{ sm: "column", lg: "row" }} w="100%" alignItems="center">
                <Flex direction="column" w={{ sm: "100%", lg: "45%" }} mt="20px">
                    <Flex direction="row" alignItems="center">
                        <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mb="5px" fontWeight='semibold' textAlign="justify" mr="10px">
                            Estimated GPA
                        </Text>
                        <Tooltip label={`Numerical field, values between 0.00 - 4.00 allowed.`} bg='royalblue.700'  placement='auto' >
                            <span><Icon as={BsQuestionCircleFill} color="royalblue.700"  mr="10px"/></span>
                        </Tooltip>
                        <Tooltip label={`This field is required.`} bg='orange.300'  placement='auto' >
                            <span><Icon as={GoAlert} color="orange"/></span>
                        </Tooltip>
                    </Flex>
                    <NumberInput defaultValue={formValues.estimated_gpa && formValues.estimated_gpa} precision={2} step={0.1} min={0} max={4} onChange={(value)=>setFormValues({...formValues,estimated_gpa:Number(value)})} name="estimated_gpa">
                        <NumberInputField />
                        <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                        </NumberInputStepper>
                    </NumberInput>
                </Flex>
                <Spacer />
                <Flex direction="column" w={{ sm: "100%", lg: "45%" }} mt="20px">

                    <Flex direction="row" alignItems="center">
                        <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mr="15px" fontWeight='semibold' textAlign="justify">
                            Estimated budget
                        </Text>
                        <Tooltip label={`This field is required.`} bg='orange.300'  placement='auto' >
                            <span><Icon as={GoAlert} color="orange"/></span>
                        </Tooltip>
                    </Flex>
                    <NumberInput defaultValue={formValues.budget && formValues.budget} step={1} min={0} max={10000000} onChange={(value)=>setFormValues({...formValues,budget:parseInt(value,10)})} name="budget">
                        <NumberInputField />
                        <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                        </NumberInputStepper>
                    </NumberInput>
                </Flex>
            </Flex>
        </>
    )
}

const myPropsChanged = (prevProps, nextProps) => {
    if ( prevProps.formValues !== nextProps.formValues || prevProps.formErrors !== nextProps.formErrors) {
        return false;
    }
    return true;
}

export default React.memo(EducationFields, myPropsChanged);