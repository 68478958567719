// React imports
import React, { useContext, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
// Context imports
import { DataContext } from "../../../../contexts/dataContext";
// Chakra imports
import {
	Image,
	Box,
	Button,
	Flex,
	Text,
	useColorModeValue,
	Icon,
	Badge,
	Alert,
	AlertIcon,
	CloseButton,
	useDisclosure,
	Avatar,
	Divider,
} from "@chakra-ui/react";
// React-icons imports
import { FaPen } from "react-icons/fa";
import { BsGenderFemale, BsGenderMale } from "react-icons/bs";
// Component 
import { ConfirmModal } from "../../../../components/ConfirmModal/ConfirmModal";
import { DeleteButton } from "../../../../components/Buttons/DeleteButton";
// Auxiliary imports
import { changeUserGroupToAgencyId } from "../../../../helpers";
import { deleteAthleteService, deleteAthleteTagsRelationService, deleteFavoritesFolderAthleteTagsRelationService } from "../../../../services/athleteServices";
import { deleteCoachAthleteFavoritesService } from 'services/coachServices';
import { deleteAthleteEnquiryService } from 'services/coachServices';


const Header = ({
	id,
	backgroundHeader,
	backgroundProfile,
	profile_pic,
	name,
	gender,
	contact_email,
	contact_phone,
	birthdate,
	agency,
	agent,
	isVisible,
	agentID,
}) => {
	// Chakra color mode
	const textColor = useColorModeValue("gray.700", "white");
	const borderProfileColor = useColorModeValue("white","rgba(255, 255, 255, 0.31)");
	const emailColor = useColorModeValue("gray.400", "gray.300","royalblue.700");

	// Get genre icon 
	const getGenderIcon = (genre) => {
		return genre === 'male' ? <Icon as={BsGenderMale} h="15px" /> : <Icon as={BsGenderFemale} h="15px" />
	}

	// Take the context data needed in this component
    const dataContext = useContext(DataContext)
    const { 
		user,
		userCoachData,
		athletes,
		setAthletes,
		agents,
		coachAthleteFavorites,
		setCoachAthleteFavorites,
		athleteTagRelations,
		setAthleteTagRelation,
		favoriteFolderAthleteRelation,
		setFavoriteFolderAthleteRelation,
		athletesEnquirys,
		setAthletesEnquirys,
	} = dataContext
	// State that controls if the user has permission to do an action
	const [noPermission, setNoPermission] = useState(false)
	// Navigation hook
	let navigate = useNavigate()
	
	// Hook for the confirm modal
	const { isOpen, onOpen, onClose } = useDisclosure()
	const cancelRef = useRef()
	// Cognito user group
	const userGroup = changeUserGroupToAgencyId(user.signInUserSession.idToken.payload["cognito:groups"][0])


	const handleEdit = () => {

		// (agents.find(ag => ag.id === agentID)?.contact_email === userCoachData.contact_email && userCoachData.agent_role === "ASSOCIATE") || (userGroup === agency.id && userCoachData.agent_role === "LEADER") || userGroup === "superadmins" 
		userGroup === agency.id  || userGroup === "superadmins" 
		? navigate(`/admin/athletes/edit/${id}`) 
		: setNoPermission(true)
	}

	// Function that handles the delete action
	const handleDelete = () => {
		(userGroup === agency.id && userCoachData.agent_role === "LEADER") || userGroup === "superadmins" 
		? onOpen()
		: setNoPermission(true)
	}
	
	// Function that handles the confirm delete action
	const handleConfirmDelete = () => {
		onClose()
		deleteAthleteService(id).then((res)=>{
			// delete ATHLETES relations
				// Delete all the favorites-athlete relations of this athlete
			coachAthleteFavorites.map(caf=>{
				if(caf.athleteID === id){
					deleteCoachAthleteFavoritesService(caf.id).catch(() => { hasFailed=true })
				}}
			)
			setCoachAthleteFavorites(coachAthleteFavorites.filter(caf=>caf.athleteID !== id))
				// Delete all the athlete-tags relation associated to this athlete
			athleteTagRelations.map(ff=>{
				if(ff.athleteID === id){
					deleteAthleteTagsRelationService(ff.id).catch(() => { hasFailed=true })
				}}
			)
			setAthleteTagRelation(athleteTagRelations.filter(ff=>ff.athleteID !== id))
				// Delete all favorites folder relation that contains this athlete
			favoriteFolderAthleteRelation.map(ctr=>{
				if(ctr.athleteID === id){
					deleteFavoritesFolderAthleteTagsRelationService(ctr.id).catch(() => { hasFailed=true })
				}}
			)
			setFavoriteFolderAthleteRelation(favoriteFolderAthleteRelation.filter(ctr=>ctr.athleteID !== id))
			
			athletesEnquirys.map(athEnq=>{
				if(athEnq.athleteID === id){
					deleteAthleteEnquiryService(athEnq.id).catch(() => { hasFailed=true })
				}}
			)
			setAthletesEnquirys(athletesEnquirys.filter(athEnq=>athEnq.athleteID !== id))
	
		})
		setAthletes(athletes.filter(athlete => athlete.id !== id))
		navigate(`/admin/athletes/`)
	}

	return (
		<>
			<ConfirmModal
				title={`Delete ${name}`}
				description="Are you sure? You can't undo this action afterwards."
				button1="Cancel"
				button2="Delete"
				isOpen={isOpen}
				onClose={onClose}
				cancelRef={cancelRef}
				handleDelete={handleConfirmDelete}
			/>
			<Flex justifyContent='right' m="0px 10px 10px 0">
				<DeleteButton handleDelete={handleDelete} />
			</Flex>
			{
				noPermission
				? (
					<Alert status='error' borderRadius="10px" mb="20px">
						<AlertIcon />
						You don't have enough permissions to perform this action on this athlete.
						<CloseButton position='absolute' right='8px' top='8px' onClick={()=>(setNoPermission(false))} />
					</Alert>
				)
				: null
			}
			<Box
				mb={{ sm: "20px", md: "30px", xl: "40px" }}
				borderRadius='15px'
				px='0px'
				display='flex'
				flexDirection='column'
				justifyContent='center'
				align='center'
			>
				<Box
				bgImage={backgroundHeader}
				bgSize='cover'
				w='100%'
				h='200px'
				borderRadius='25px'
				bgPosition='50%'
				bgRepeat='no-repeat'
				position='relative'
				display='flex'
				justifyContent='center'>
					<Flex
						direction={{ sm: "column", md: "column",lg:"row" }}
						mx='1.5rem'
						// maxH='330px'
						w={{ sm: "95%", xl: "97%" }}
						justifyContent={{ sm: "center", md: "space-between" }}
						align='center'
						backdropFilter='saturate(200%) blur(50px)'
						position='absolute'
						boxShadow='0px 2px 5.5px rgba(0, 0, 0, 0.02)'
						border='2px solid'
						borderColor={borderProfileColor}
						bg={backgroundProfile}
						p='10px'
						borderRadius='20px'
						transform={{
							sm: "translateY(10%)",
							md: "translateY(15%)",
							lg: "translateY(30%)",
						}}
					>
						
						<Flex direction='column' w="100%">

							<Flex
								align='center'
								mb={{ sm: "10px", md: "0px" }}
								direction={{ sm: "column", md: "column",lg:"row" }}
								w="100%"
								textAlign={{ sm: "center", md: "center",lg:"start" }}
								justifyContent="center"
							>
								{/* <Image
									me={{ md: "22px" }}
									src={profile_pic}
									w='120px'
									h='150px'
									borderRadius='15px'
									fit='cover'
								/> */}
								<Avatar src={profile_pic} w='150px' h='150px' me={{ md: "22px" }} fit='cover'/>
								<Flex direction='column' w='70%' my={{ sm: "10px" }} mx={{sm:"10px"}}>
									<Text
									fontSize={{ sm: "md", lg: "lg" }}
									color={textColor}
									fontWeight='bold'
									ms={{ sm: "8px", md: "0px" }}>
										{name} | {getGenderIcon(gender)}
									</Text>
									<Text
									fontSize={contact_email ? { sm: "sm", md: "md" } : "sm"}
									color={emailColor}
									fontWeight='semibold'>
										{contact_email ? contact_email : (<i>No contact email provided</i>)}
									</Text>
									<Text
									fontSize={contact_phone ? { sm: "sm", md: "md" } : "sm"}
									color={emailColor}
									fontWeight='semibold'>
										{contact_phone ? contact_phone : (<i>No contact phone provided</i>)}
									</Text>
									<Text
									fontSize={{ sm: "sm", md: "md" }}
									color={emailColor}
									fontWeight='semibold'>
										{birthdate}
									</Text>
									<Flex direction="row" justifyContent={{ sm: "center", md: "center",lg:"start" }}>
										<Text
											fontSize={{ sm: "sm", md: "md" }}
											color={emailColor}
											fontWeight='semibold'
											mr="10px"
										>
											Status: 
										</Text>
										<Badge variant='outline' my="auto" colorScheme={isVisible ? 'green' : 'red'} textAlign="center" >
											{isVisible ? 'Visible' : 'Hidden'}
										</Badge>
									</Flex>
									
								</Flex>
								<Flex direction='column' w={{lg:'50%'}} mx={{sm:"10px"}} justifyContent="center">
									<Text
									fontSize="sm"
									color={textColor}
									fontWeight='bold'>
										Agency information
									</Text>
									<Flex direction="row" mt="5px">
										<Avatar size="sm" src={agency.logo} onClick={()=>(navigate(`/admin/agencies/show/${agency.id}`))} style={{cursor:'pointer'}}/>
										<Text
											fontSize={{ sm: "sm", md: "md" }}
											color={emailColor}
											fontWeight='semibold'
											m={{ sm: "3px", md: "5px" }}
											onClick={()=>(navigate(`/admin/agencies/show/${agency.id}`))} style={{cursor:'pointer'}}	
											>
											{agency.name}
										</Text>
									</Flex>
									<Flex direction="row">
										<Avatar size="sm" src={agents.find(ag => ag.id === agentID)?.profile_pic} />
										<Text
											fontSize={{ sm: "sm", md: "md" }}
											color={emailColor}
											fontWeight='semibold'
											m={{ sm: "3px", md: "5px" }}
											onClick={()=>(navigate(`/admin/agents/show/${agent.id}`))} style={{cursor:'pointer'}}	
											>
											{agents.find(ag => ag.id === agentID)?.name}
										</Text>
									</Flex>
								</Flex>
							</Flex>
						</Flex>


						<Flex
							direction={{ sm: "column", lg: "row" }}
							w={{ sm: "100%", md: "70%", lg: "auto" }}
						>	
							<Button p='0px' bg='transparent' _hover={{ bg: "none" }} onClick={handleEdit}>
								<Flex
								align='center'
								w={{ sm: "100%",md:"80%",lg: "135px" }}
								bg='hsla(0,0%,100%,.3)'
								borderRadius='15px'
								justifyContent='center'
								py='10px'
								boxShadow='inset 0 0 1px 1px hsl(0deg 0% 100% / 90%), 0 20px 27px 0 rgb(0 0 0 / 5%)'
								border='1px solid gray.200'
								cursor='pointer'>
									<FaPen w='100%' h='100%' />
									<Text
										fontSize='xs'
										color={textColor}
										fontWeight='bold'
										ms='6px'>
										EDIT
									</Text>
								</Flex>
							</Button>
						</Flex>
					</Flex>
				</Box>
			</Box>
		</>
	);
};
  
  export default Header;
  