import { API } from 'aws-amplify';
import { createShowcaseAthleteRelation } from 'custom_graphql_queries/showcases';
import { updateShowcaseAthleteRelation } from 'custom_graphql_queries/showcases';
import { deleteShowcaseAthleteRelation } from 'custom_graphql_queries/showcases';
import { deleteShowcaseCoachRelation } from 'custom_graphql_queries/showcases';
import { deleteShowcase, updateShowcase, createShowcase } from 'custom_graphql_queries/showcases';

const updateShowcaseService = async (showcase) => {
    try {
        API.graphql({query: updateShowcase, variables: { input: showcase }})
        .catch(error => {console.log(error)})
    } catch (error) {
        throw new Error(error.message);
    }
}
const createShowcaseService = async (showcase) => {
    try {
        API.graphql({query: createShowcase, variables: { input: showcase }})
        .catch(error => {console.log(error)})
    } catch (error) {
        throw new Error(error.message);
    }
}

const deleteShowcaseService = async (showcaseId) => {
    try {
        const response = await API.graphql({query: deleteShowcase, variables: { input: {id: showcaseId }}})
        return response
    } catch (error) {
        throw new Error(error.message);
    }
}


const deleteShowcaseCoachRelationService = async (showcaseCoachRelationID) => {
    try {
        const response = await API.graphql({query: deleteShowcaseCoachRelation, variables: { input: {id: showcaseCoachRelationID }}})
        return response
    } catch (error) {
        throw new Error(error.message);
    }
}

const createShowcaseAthleteRelationService = async (showcaseAthleteRelation) => {
    try {
        const response = await API.graphql({query: createShowcaseAthleteRelation, variables: { input: showcaseAthleteRelation }})
        return response
    } catch (error) {
        throw new Error(error.message);
    }
}

const updateShowcaseAthleteRelationService = async (showcaseAthleteRelation) => {
    try {
        const response = await API.graphql({query: updateShowcaseAthleteRelation, variables: { input: showcaseAthleteRelation }})
        return response
    } catch (error) {
        console.log("error:",error)
        throw new Error(error.message);
    }
}

const deleteShowcaseAthleteRelationService = async (showcaseAthleteRelationID) => {
    try {
        const response = await API.graphql({query: deleteShowcaseAthleteRelation, variables: { input: {id: showcaseAthleteRelationID }}})
        return response
    } catch (error) {
        throw new Error(error.message);
    }
}

export { updateShowcaseService, createShowcaseService, deleteShowcaseService,deleteShowcaseCoachRelationService,createShowcaseAthleteRelationService,deleteShowcaseAthleteRelationService,updateShowcaseAthleteRelationService };