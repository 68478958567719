// A modern JavaScript utility library delivering modularity, performance & extras
import { find } from 'lodash'

export const positionAcronym = (position) => {

    const acronymPositions = {
        "GOALKEEPER": "GK",
        "CENTER_BACK": "CB",
        "RIGHT_BACK": "RB",
        "LEFT_BACK": "LB",
        "DEFENSIVE_MIDFIELDER": "DCM",
        "CENTER_MIDFIELDER": "CM",
        "ATTACKING_MIDFIELDER": "ACM",
        "RIGHT_WINGER": "RW",
        "LEFT_WINGER": "LW",
        "RIGHT": "RW",
        "FORWARD_STRIKER": "FWD"
    }

    return acronymPositions[position] ? acronymPositions[position] : ''
}
  
  
export const positionHumanize = (position) => {
    const humanizedPositions = [
        ["GOALKEEPER","Goalkeeper"],
        ["CENTER_BACK","Center back"],
        ["RIGHT_BACK","Right back"],
        ["LEFT_BACK","Left back"],
        ["DEFENSIVE_MIDFIELDER","Defensive Midfielder"],
        ["CENTER_MIDFIELDER","Center Midfielder"],
        ["ATTACKING_MIDFIELDER","Attacking Midfielder"],
        ["RIGHT_WINGER","Right Winger"],
        ["LEFT_WINGER","Left Winger"],
        ["RIGHT","Right"],
        ["FORWARD_STRIKER","Forward"]
    ]
    const humanizedTextArray = find(humanizedPositions, hum => {
        return hum[0] === position
    })
    const humanizedText = humanizedTextArray ? humanizedTextArray[1] : position
    return humanizedText
}

export const division = [
    {value:"NCAA_DI",label:"NCAA DI"},
    {value:"NCAA_DII",label:"NCAA DII"},
    {value:"NCAA_DIII",label:"NCAA DIII"},
    {value:"NAIA",label:"NAIA"},
    {value:"JUCO",label:"JUCO"},
    {value:"USCAA",label:"USCAA"},
    {value:"USPORT_CANADA",label:"USPORT CANADA"},
]
export const startDate = [
    // {value:"SPRING_2022",label:"Spring 2022"},
    // {value:"FALL_2022",label:"Fall 2022"},
    // {value:"SPRING_2023",label:"Spring 2023"},
    {value:"FALL_2023",label:"Fall 2023"},
    {value:"SPRING_2024",label:"Spring 2024"},
    {value:"FALL_2024",label:"Fall 2024"},
    {value:"SPRING_2025",label:"Spring 2025"},
    {value:"FALL_2025",label:"Fall 2025"},
    {value:"SPRING_2026",label:"Spring 2026"},
    {value:"FALL_2026",label:"Fall 2026"},
]
export const position = [
    {value:"GOALKEEPER",label:"Goalkeeper"},
    {value:"CENTER_BACK",label:"Center back"},
    {value:"RIGHT_BACK",label:"Right back"},
    {value:"LEFT_BACK",label:"Left back"},
    {value:"DEFENSIVE_MIDFIELDER",label:"Defensive Midfielder"},
    {value:"CENTER_MIDFIELDER",label:"Center Midfielder"},
    {value:"ATTACKING_MIDFIELDER",label:"Attacking Midfielder"},
    {value:"RIGHT_WINGER",label:"Right Winger"},
    {value:"LEFT_WINGER",label:"Left Winger"},
    {value:"FORWARD_STRIKER",label:"Forward"},
]
export const suscription_name_values = [
    {value:"SEASON_PASS",label:"Season pass"},
    {value:"PREMIUM_PASS",label:"Premium pass"},
    {value:"FREE_PERIOD",label:"Free period"},
]
export const suscription_recurrency_values = [
    {value:"NONE",label:"None"},
    {value:"MONTHLY",label:"Monthly"},
    {value:"SIX_MONTHLY",label:"Six monthly"},
    {value:"ANNUAL",label:"Annual"},
]
export const suscription_status_values = [
    {value:"ACTIVE",label:"Active"},
    {value:"INACTIVE",label:"Inactive"},
    {value:"SUSPENDED",label:"Suspended"},
]
export const roles =  [
    {value:"HEAD_COACH",label:"Head coach"},
    {value:"ASSOCIATE_HEAD_COACH",label:"Associate head coach"},
    {value:"ASSISTANT_COACH",label:"Assistant coach"},
    {value:"GRADUATE_ASSISTANT_COACH",label:"Graduate assistant coach"},
    {value:"GOALKEEPER_COACH",label:"Goalkeeper coach"},
    {value:"VOLUNTEER_COACH",label:"Volunteer coach"},
]

export const agentRoles = [
    {value:"LEADER",label:"Leader"},
    {value:"ASSOCIATE",label:"Associate"},
]

export const athleteStatus = [
    {value:"AVAILABLE",label:"Available"},
    {value:"COMMITTED",label:"Committed"},
    {value:"ON_HOLD",label:"On hold"},
]
export const athleteTypeAdmission = [
    {value:"INTERNATIONAL_TRANSFER",label:"International transfer"},
    {value:"FRESHMAN",label:"Freshman"},
    {value:"US_TRANSFER",label:"US transfer"},
    {value:"GRADUATE",label:"Graduate"},
    {value:"OTHER",label:"Other"},
]
export const IntendFields = [
    {value: 'Architecture', label: 'Architecture'},
    {value: 'Arts and Design', label: 'Arts and Design'},
    {value: 'Athletic Trainning', label: 'Athletic Trainning'},
    {value: 'Biology', label: 'Biology'},
    {value: 'Biotech', label: 'Biotech'},
    {value: 'Business and Finances', label: 'Business and Finances'},
    {value: 'Chemistry', label: 'Chemistry'},
    {value: 'Communication/Journalism', label: 'Communication/Journalism'},
    {value: 'Computer and Information Technologies', label: 'Computer and Information Technologies'},
    {value: 'Education', label: 'Education'},
    {value: 'Engineering', label: 'Engineering'},
    {value: 'English', label: 'English'},
    {value: 'Health Science and Medicine', label: 'Health Science and Medicine'},
    {value: 'Humanities', label: 'Humanities'},
    {value: 'Law', label: 'Law'},
    {value: 'Marketing', label: 'Marketing'},
    {value: 'Mathematics', label: 'Mathematics'},
    {value: 'Nutrition and Dietetics', label: 'Nutrition and Dietetics'},
    {value: 'Physics', label: 'Physics'},
    {value: 'Psychology', label: 'Psychology'},
    {value: 'Sciences', label: 'Sciences'},
    {value: 'Undefined', label: 'Undefined'},
    
]

export const categories = [
    {value: 'A1', label: 'A1'},
    {value: 'A2', label: 'A2'},
    {value: 'A3', label: 'A3'},
    {value: 'A4', label: 'A4'},
    {value: 'A5', label: 'A5'},
    {value: 'B1', label: 'B1'},
    {value: 'B2', label: 'B2'},
    {value: 'B3', label: 'B3'},
    {value: 'B4', label: 'B4'},
    {value: 'B5', label: 'B5'},
    {value: 'C1', label: 'C1'},
    {value: 'C2', label: 'C2'},
    {value: 'C3', label: 'C3'},
    {value: 'C4', label: 'C4'},
    {value: 'C5', label: 'C5'},
    {value: 'C6', label: 'C6'},
    {value: 'C7', label: 'C7'},
    {value: 'C8', label: 'C8'},
    {value: 'D1', label: 'D1'},
    {value: 'D2', label: 'D2'},
    {value: 'D3', label: 'D3'},
    {value: 'D4', label: 'D4'},
    {value: 'D5', label: 'D5'},
    {value: 'D6', label: 'D6'},
    {value: 'D7', label: 'D7'},
    {value: 'D8', label: 'D8'},
    {value: 'E1', label: 'E1'},
    {value: 'E2', label: 'E2'},
    {value: 'E3', label: 'E3'},
    {value: 'E4', label: 'E4'},
    {value: 'E5', label: 'E5'},
    {value: 'E6', label: 'E6'},
    {value: 'E7', label: 'E7'},
    {value: 'E8', label: 'E8'},
];

export const changeUserGroupToAgencyId = (userGroup) => {
    switch (userGroup) {
        case "agency_agm":
            return "1788336e-93cf-4f50-9200-4854fa4ea836"
        case "agency_cli":
            return "0c3317ab-67df-48dd-a76b-b9b85e701c89"
        case "agency_csusa":
            return "54565a95-32cd-4f32-a319-4edcd8bcda01"
        case "agency_ea":
            return "da0b0542-2f76-4bc2-a808-6e62d5fac3be"
        case "agency_nls":
            return "54cf602e-1543-4c57-a56e-3dacee8c11fe"
        case "agency_seusa":
            return "2e961d7e-8eeb-4e0c-aee8-95b8384e7fb1"
        case "agency_ss":
            return "c4b840eb-d3f1-4c32-b333-638292074657"
        case "agency_ue":
            return "375936a6-c12b-4a25-b455-9c48b7e2b65e"
        case "agency_ics":
            return "451017f0-e5be-496a-a8f5-137af93f10fe"
        case "agency_ts":
            return "f34a9833-2d4d-440d-bc14-34477952fed7"
        case "agency_fes":
            return "822a46e5-e772-46f5-9815-1efbead99c72"
        case "superadmins":
            return "superadmins"
        default:
            return -1
    }
}

export const changeAgencyIdToUserGroup = (agencyId) => {
    switch (agencyId) {
        case "1788336e-93cf-4f50-9200-4854fa4ea836":
            return "agency_agm"
        case "0c3317ab-67df-48dd-a76b-b9b85e701c89":
            return "agency_cli"
        case "54565a95-32cd-4f32-a319-4edcd8bcda01":
            return "agency_csusa"
        case "da0b0542-2f76-4bc2-a808-6e62d5fac3be":
            return "agency_ea"
        case "54cf602e-1543-4c57-a56e-3dacee8c11fe":
            return "agency_nls"
        case "2e961d7e-8eeb-4e0c-aee8-95b8384e7fb1":
            return "agency_seusa"
        case "c4b840eb-d3f1-4c32-b333-638292074657":
            return "agency_ss"
        case "375936a6-c12b-4a25-b455-9c48b7e2b65e":
            return "agency_ue"
        case "451017f0-e5be-496a-a8f5-137af93f10fe":
            return "agency_ics"
        case "f34a9833-2d4d-440d-bc14-34477952fed7":
            return "agency_ts"
        case "822a46e5-e772-46f5-9815-1efbead99c72":
            return "agency_fes"
        case "superadmins":
            return "superadmins"
        default:
            return -1
    }
}

export const getAgenciesUtilities = () => {
    return [
        {
            id: "1788336e-93cf-4f50-9200-4854fa4ea836",
            name: "AGM SPORTS",
            acronym: "AGM",
        },
        {
            id: "0c3317ab-67df-48dd-a76b-b9b85e701c89",
            name: "College Life Italia",
            acronym: "CLI",
        },
        {
            id: "54565a95-32cd-4f32-a319-4edcd8bcda01",
            name: "College Scholarships USA",
            acronym: "CSUSA",
        },
        {
            id: "da0b0542-2f76-4bc2-a808-6e62d5fac3be",
            name: "Elite Athletes",
            acronym: "EA",
        },
        {
            id: "54cf602e-1543-4c57-a56e-3dacee8c11fe",
            name: "Next Level Sports",
            acronym: "NLS",
        },
        {
            id: "2e961d7e-8eeb-4e0c-aee8-95b8384e7fb1",
            name: "Soccer & Education USA",
            acronym: "SEUSA",
        },
        {
            id: "c4b840eb-d3f1-4c32-b333-638292074657",
            name: "Sport Schorlarships",
            acronym: "SS",
        },
        {
            id: "375936a6-c12b-4a25-b455-9c48b7e2b65e",
            name: "Uniexperts",
            acronym: "UE",
        },
        {
            id: "f34a9833-2d4d-440d-bc14-34477952fed7",
            name: "Tennis Smart",
            acronym: "TS",
        },
        {
            id: "451017f0-e5be-496a-a8f5-137af93f10fe",
            name: "I-Con Sports",
            acronym: "ICS",
        },
        {
            id: "822a46e5-e772-46f5-9815-1efbead99c72",
            name: "Future Elite Sports",
            acronym: "FES",
        }
    ]
}

export const defaultTemplates = [
    {value:"Welcome",label:"Hi coach! Here Alliance Athletes, hope you are well. Welcome to our App. Cheers"},
    {value:"New information",label:"Hi coach! Here Alliance Athletes, hope you are well. Have you seen our new DC PSAs for Fall 2023? Cheers"},
    {value:"New event",label:"Hi coach! Here Alliance Athletes, hope you are well. We're happy to announce you our new event in Madrid the next April. Cheers"},
    {value:"New feature",label:"Hi coach! Here Alliance Athletes, hope you are well. We have just implement save favorites in folders, try it out. Cheers"},
]


export const defaultLevelOfCompetition = [
    {value:"1 / Tier one",label:"1 / Tier one"},
    {value:"2 / Tier two",label:"2 / Tier two"},
    {value:"3 / Tier three",label:"3 / Tier three"},
    {value:"4 / Tier four",label:"4 / Tier four"},
    {value:"5 / Tier five",label:"5 / Tier five"},
]
export const colorsOptions = [
    {value:"Red",label:"Red"},
    {value:"Blue",label:"Blue"},
    {value:"Green",label:"Green"},
    {value:"White",label:"White"},
    {value:"Yellow",label:"Yellow"},
    {value:"Purple",label:"Purple"},
    {value:"Pink",label:"Pink"},
    {value:"Napoli",label:"Napoli"},
    {value:"Black",label:"Black"},
    {value:"Grey",label:"Grey"},
    {value:"Dark Red",label:"Dark Red"},
    {value:"Light Blue",label:"Light Blue"},
]

export const levelOfCompetitionsByCountryGender = [
    {country:"Norway", gender:"male", value:"1 / Norwegian National U19 league",label:"1 / Norwegian National U19 league"},
    {country:"Norway", gender:"male", value:"2 / Norwegian Regional U19 league",label:"2 / Norwegian Regional U19 league"},
    {country:"Norway", gender:"male", value:"3 / Norwegian Local U19 league",label:"3 / Norwegian Local U19 league"},
    {country:"Norway", gender:"male", value:"1 / Norwegian Eliteserien",label:"1 / Norwegian Eliteserien"},
    {country:"Norway", gender:"male", value:"2 / Norwegian Obosliga",label:"2 / Norwegian Obosliga"},
    {country:"Norway", gender:"male", value:"3 / Norwegian 2nd division",label:"3 / Norwegian 2nd division"},
    {country:"Norway", gender:"male", value:"4 / Norwegian 3rd division",label:"4 / Norwegian 3rd division"},
    {country:"Norway", gender:"male", value:"5 / Norwegian 4th division",label:"5 / Norwegian 4th division"},
    {country:"Norway", gender:"male", value:"6 / Norwegian 5th division",label:"6 / Norwegian 5th division"},
    {country:"Norway", gender:"male", value:"7 / Norwegian 6th division",label:"7 / Norwegian 6th division"},
    {country:"Norway", gender:"female", value:"1 / Norwegian National U19 league",label:"1 / Norwegian National U19 league"},
    {country:"Norway", gender:"female", value:"2 / Norwegian Regional U19 league",label:"2 / Norwegian Regional U19 league"},
    {country:"Norway", gender:"female", value:"3 / Norwegian Local U19 league",label:"3 / Norwegian Local U19 league"},
    {country:"Norway", gender:"female", value:"1 / Norwegian Toppserie",label:"1 / Norwegian Toppserie"},
    {country:"Norway", gender:"female", value:"2 / Norwegian 1st division",label:"2 / Norwegian 1st division"},
    {country:"Norway", gender:"female", value:"3 / Norwegian 2nd division",label:"3 / Norwegian 2nd division"},
    {country:"Norway", gender:"female", value:"4 / Norwegian 3rd division",label:"4 / Norwegian 3rd division"},
    {country:"Norway", gender:"female", value:"5 / Norwegian 4th division",label:"5 / Norwegian 4th division"},
    
    {country:"Sweden", gender:"male", value:"1 / Swedish National P19 league",label:"1 / Swedish National P19 league"},
    {country:"Sweden", gender:"male", value:"2 / Swedish Regional P19 league",label:"2 / Swedish Regional P19 league"},
    {country:"Sweden", gender:"male", value:"3 / Swedish Local P19 league",label:"3 / Swedish Local P19 league"},
    {country:"Sweden", gender:"male", value:"1 / Swedish Allsvenskan",label:"1 / Swedish Allsvenskan"},
    {country:"Sweden", gender:"male", value:"2 / Swedish Superettan",label:"2 / Swedish Superettan"},
    {country:"Sweden", gender:"male", value:"3 / Swedish Ettan",label:"3 / Swedish Ettan"},
    {country:"Sweden", gender:"male", value:"4 / Swedish 2nd division",label:"4 / Swedish 2nd division"},
    {country:"Sweden", gender:"male", value:"5 / Swedish 3rd division",label:"5 / Swedish 3rd division"},
    {country:"Sweden", gender:"male", value:"6 / Swedish 4th division",label:"6 / Swedish 4th division"},
    {country:"Sweden", gender:"male", value:"7 / Swedish 5th division",label:"7 / Swedish 5th division"},
    {country:"Sweden", gender:"male", value:"8 / Swedish 6th division",label:"8 / Swedish 6th division"},
    {country:"Sweden",gender:"female",value:"1 / Swedish National F19 league",label:"1 / Swedish National F19 league"},
    {country:"Sweden",gender:"female",value:"2 / Swedish Regional F19 league",label:"2 / Swedish Regional F19 league"},
    {country:"Sweden",gender:"female",value:"3 / Swedish Local F19 league",label:"3 / Swedish Local F19 league"},
    {country:"Sweden",gender:"female",value:"1 / Swedish Damallsvenskan",label:"1 / Swedish Damallsvenskan"},
    {country:"Sweden",gender:"female",value:"2 / Swedish Elitettan",label:"1 / 2wedish Elitettan"},
    {country:"Sweden",gender:"female",value:"3 / Swedish 1st division",label:"3 / Swedish 1st division"},
    {country:"Sweden",gender:"female",value:"4 / Swedish 2nd division",label:"4 / Swedish 2nd division"},
    {country:"Sweden",gender:"female",value:"5 / Swedish 3rd division",label:"5 / Swedish 3rd division"},
    {country:"Sweden",gender:"female",value:"6 / Swedish 4th division",label:"6 / Swedish 4th division"},
    {country:"Sweden",gender:"female",value:"7 / Swedish 5th division",label:"7 / Swedish 5th division"},
    
    {country:"Denmark",gender:"male",value:"1 / Danish 1st tier National U19 league",label:"1 / Danish 1st tier National U19 league"},
    {country:"Denmark",gender:"male",value:"2 / Danish 2nd tier National U19 league",label:"2 / Danish 2nd tier National U19 league"},
    {country:"Denmark",gender:"male",value:"3 / Danish 3rd tier Regional U19 league",label:"3 / Danish 3rd tier Regional U19 league"},
    {country:"Denmark",gender:"male",value:"4 / Danish 4th tier Local U19 league",label:"1 / 4anish 4th tier Local U19 league"},
    {country:"Denmark",gender:"male",value:"1 / Danish SuperLiga",label:"1 / Danish SuperLiga"},
    {country:"Denmark",gender:"male",value:"2 / Danish 1st Division",label:"2 / Danish 1st Division"},
    {country:"Denmark",gender:"male",value:"3 / Danish 2nd Division",label:"3 / Danish 2nd Division"},
    {country:"Denmark",gender:"male",value:"4 / Danish Denmarksserie",label:"4 / Danish Denmarksserie"},
    {country:"Denmark",gender:"male",value:"5 / Danish Local Series",label:"5 / Danish Local Series"},
    {country:"Denmark",gender:"male",value:"6 / Danish Serie 1",label:"6 / Danish Serie 1"},
    {country:"Denmark",gender:"male",value:"7 / Danish Serie 2",label:"7 / Danish Serie 2"},
    {country:"Denmark",gender:"male",value:"8 / Danish Serie 3",label:"8 / Danish Serie 3"},
    {country:"Denmark",gender:"female",value:"1 / Danish National U18 League",label:"1 / Danish National U18 League"},
    {country:"Denmark",gender:"female",value:"2 / Danish Regional U18 League",label:"2 / Danish Regional U18 League"},
    {country:"Denmark",gender:"female",value:"1 / Danish Kvindeliga",label:"1 / Danish Kvindeliga"},
    {country:"Denmark",gender:"female",value:"2 / Danish 1st Division",label:"2 / Danish 1st Division"},
    {country:"Denmark",gender:"female",value:"3 / Danish Kvindeserie East/West",label:"3 / Danish Kvindeserie East/West"},
    {country:"Denmark",gender:"female",value:"4 / Danish Women's Serie 1",label:"4 / Danish Women's Serie 1"},
    {country:"Denmark",gender:"female",value:"5 / Danish Women's Serie 2",label:"5 / Danish Women's Serie 2"},

    {country:"England", gender:"male",value:"1/ Elite Academy",label:"1 / Elite Academy"},
    {country:"England", gender:"male",value:"2/ National Youth FL",label:"2 / National Youth FL"},
    {country:"England", gender:"male",value:"3/ English Schools FA",label:"3 / English Schools FA"},
    {country:"England", gender:"male",value:"4/ AOC English Colleges",label:"4 / AOC English Colleges"},
    {country:"England", gender:"male",value:"1/ EPL",label:"1 / EPL"},
    {country:"England", gender:"male",value:"2/ Championship",label:"2 / Championship"},
    {country:"England", gender:"male",value:"3/ League 1",label:"3 / League 1"},
    {country:"England", gender:"male",value:"4/ League 2",label:"4 / League 2"},
    {country:"England", gender:"male",value:"5/ National League",label:"5 / National League"},
    {country:"England", gender:"male",value:"6/ National League South / North",label:"6 / National League South / North"},
    {country:"England", gender:"male",value:"7/ Regional Leagues",label:"7 / Regional Leagues"},
    {country:"England", gender:"male",value:"8/ Regional Leagues",label:"8 / Regional Leagues"},
    {country:"England", gender:"male",value:"9/ County Leagues",label:"9 / County Leagues"},
    {country:"England", gender:"male",value:"10 / County Leagues",label:"10 / County Leagues"},
    {country:"England", gender:"male",value:"11 / County Leagues",label:"11 / County Leagues"},
    {country:"England",gender:"female",value:"1 / Elite Academy",label:"1 / Elite Academy"},
    {country:"England",gender:"female",value:"2 / National Youth FL",label:"2 / National Youth FL"},
    {country:"England",gender:"female",value:"3 / English Schools FA",label:"3 / English Schools FA"},
    {country:"England",gender:"female",value:"4 / AOC English Colleges",label:"4 / AOC English Colleges"},
    {country:"England",gender:"female",value:"1 / FA Womens Super League",label:"1 / FA Womens Super League"},
    {country:"England",gender:"female",value:"2 / FA Women's Championship",label:"2 / FA Women's Championship"},
    {country:"England",gender:"female",value:"3 / FA Women's National League Premier Division",label:"3 / FA Women's National League Premier Division"},
    {country:"England",gender:"female",value:"4 / FA Women's National League 1",label:"4 / FA Women's National League 1"},
    {country:"England",gender:"female",value:"5 / Regional Premier Division",label:"5 / Regional Premier Division"},
    {country:"England",gender:"female",value:"6 / Regional League 1",label:"6 / Regional League 1"},
    {country:"England",gender:"female",value:"7 / County League Premier Division",label:"7 / County League Premier Division"},
    {country:"England",gender:"female",value:"8 / County League Division 1",label:"8 / County League Division 1"},
    {country:"England",gender:"female",value:"9 / County League Division 2",label:"9 / County League Division 2"},
    
    {country:"Republic of Ireland",gender:"male",value:"1 / League of Ireland U19",label:"1 / League of Ireland U19"},
    {country:"Republic of Ireland",gender:"male",value:"2 / County and District League",label:"2 / County and District League"},
    {country:"Republic of Ireland",gender:"male",value:"3 / County and District League",label:"3 / County and District League"},
    {country:"Republic of Ireland",gender:"male",value:"4 / County and District League",label:"4 / County and District League"},
    {country:"Republic of Ireland",gender:"male",value:"1 / League of Ireland Premier Division",label:"1 / League of Ireland Premier Division"},
    {country:"Republic of Ireland",gender:"male",value:"2 / League of Ireland First Division",label:"2 / League of Ireland First Division"},
    {country:"Republic of Ireland",gender:"male",value:"3 / Leinster Senior League, Ulster Senior League, Munster Senior League",label:"3 / Leinster Senior League, Ulster Senior League, Munster Senior League"},
    {country:"Republic of Ireland",gender:"male",value:"4 / Amateur League",label:"4 / Amateur League"},
    {country:"Republic of Ireland",gender:"male",value:"5 / County and District League",label:"5 / County and District League"},
    {country:"Republic of Ireland",gender:"female",value:"1 / Womens National League U19",label:"1 / Womens National League U19"},
    {country:"Republic of Ireland",gender:"female",value:"2 / Womens County and District League",label:"2 / Womens County and District League"},
    {country:"Republic of Ireland",gender:"female",value:"3 / Womens County and District League",label:"3 / Womens County and District League"},
    {country:"Republic of Ireland",gender:"female",value:"4 / Womens County and District League",label:"4 / Womens County and District League"},
    {country:"Republic of Ireland",gender:"female",value:"1 / Womens National League",label:"1 / Womens National League"},
    {country:"Republic of Ireland",gender:"female",value:"2 / Womens County and District League",label:"2 / Womens County and District League"},

    {country:"Northern Ireland",gender:"male",value:"1 / NIFL Premiership Development League",label:"1 / NIFL Premiership Development League"},
    {country:"Northern Ireland",gender:"male",value:"2 / NI Regional league",label:"2 / NI Regional league"},
    {country:"Northern Ireland",gender:"male",value:"3 / NI Regional league",label:"3 / NI Regional league"},
    {country:"Northern Ireland",gender:"male",value:"4 / NI Regional league",label:"4 / NI Regional league"},
    {country:"Northern Ireland",gender:"male",value:"1 / NIFL Irish Premiership",label:"1 / NIFL Irish Premiership"},
    {country:"Northern Ireland",gender:"male",value:"2 / NIFL Championship",label:"2 / NIFL Championship"},
    {country:"Northern Ireland",gender:"male",value:"3 / Northern Amateur League",label:"3 / Northern Amateur League"},
    {country:"Northern Ireland",gender:"female",value:"1 / NI Womens Academy League",label:"1 / NI Womens Academy League"},
    {country:"Northern Ireland",gender:"female",value:"2 / NI Regional League",label:"2 / NI Regional League"},
    {country:"Northern Ireland",gender:"female",value:"3 / NI Regional League",label:"3 / NI Regional League"},
    {country:"Northern Ireland",gender:"female",value:"4 / NI Womens Premiership",label:"4 / NI Womens Premiership"},
    
    {country:"Wales",gender:"male",value:"1 / Welsh FAW Academy",label:"1 / Welsh FAW Academy"},
    {country:"Wales",gender:"male",value:"2 / Welsh Youth Grassroots",label:"2 / Welsh Youth Grassroots"},
    {country:"Wales",gender:"male",value:"1 / Cymru Prem",label:"1 / Cymru Prem"},
    {country:"Wales",gender:"male",value:"2 / Cymru Division 1",label:"2 / Cymru Division 1"},
    {country:"Wales",gender:"male",value:"3 / Ardal League",label:"3 / Ardal League"},
    {country:"Wales",gender:"male",value:"4 / Wales Divison 1",label:"4 / Wales Divison 1"},
    {country:"Wales",gender:"male",value:"5 / Wales Regional Alliance",label:"5 / Wales Regional Alliance"},
    {country:"Wales",gender:"female",value:"1 / Welsh National U19 league",label:"1 / Welsh National U19 league"},
    {country:"Wales",gender:"female",value:"2 / Welsh Regional U19 league",label:"2 / Welsh Regional U19 league"},
    {country:"Wales",gender:"female",value:"3 / Welsh Local U19 league",label:"3 / Welsh Local U19 league"},
    {country:"Wales",gender:"female",value:"1 / Welsh Premier",label:"1 / Welsh Premier"},
    {country:"Wales",gender:"female",value:"2 / Ceredion League",label:"2 / Ceredion League"},
    {country:"Wales",gender:"female",value:"3 / Womens Division 1",label:"3 / Womens Division 1"},
    {country:"Wales",gender:"female",value:"4 / Womens Division 2",label:"4 / Womens Division 2"},

    {country:"Scotland",gender:"male",value:"1 / Scottish National U19 league",label:"1 / Scottish National U19 league"},
    {country:"Scotland",gender:"male",value:"2 / Scottish Regional U19 league",label:"2 / Scottish Regional U19 league"},
    {country:"Scotland",gender:"male",value:"3 / Scottish Local U19 league",label:"3 / Scottish Local U19 league"},
    {country:"Scotland",gender:"male",value:"1 / Scottish Premiership",label:"1 / Scottish Premiership"},
    {country:"Scotland",gender:"male",value:"2 / Scottish Championship",label:"2 / Scottish Championship"},
    {country:"Scotland",gender:"male",value:"3 / Scottish Div 1",label:"3 / Scottish Div 1"},
    {country:"Scotland",gender:"male",value:"4 / Scottish Div 2",label:"4 / Scottish Div 2"},
    {country:"Scotland",gender:"male",value:"5 / Scottish Regional Premier",label:"5 / Scottish Regional Premier"},
    {country:"Scotland",gender:"male",value:"6 / Scottish Regional Div 1",label:"6 / Scottish Regional Div 1"},
    {country:"Scotland",gender:"female",value:"1 / Scottish National U19 league",label:"1 / Scottish National U19 league"},
    {country:"Scotland",gender:"female",value:"2 / Scottish Regional U19 league",label:"2 / Scottish Regional U19 league"},
    {country:"Scotland",gender:"female",value:"3 / Scottish Local U19 league",label:"3 / Scottish Local U19 league"},
    {country:"Scotland",gender:"female",value:"1 / Scottish Women's Premier League",label:"1 / Scottish Women's Premier League"},
    {country:"Scotland",gender:"female",value:"2 / Scottish Women's Premier League 2",label:"2 / Scottish Women's Premier League 2"},
    {country:"Scotland",gender:"female",value:"3 / SWFL Division One",label:"3 / SWFL Division One"},
    {country:"Scotland",gender:"female",value:"4 / SWFL Regional Division",label:"4 / SWFL Regional Division"},
        
    {country:"Australia",gender:"male",value:"1 / Australian National U19 league",label:"1 / Australian National U19 league"},
    {country:"Australia",gender:"male",value:"2 / Australian Regional U19 league",label:"2 / Australian Regional U19 league"},
    {country:"Australia",gender:"male",value:"3 / Australian Local U19 league",label:"3 / Australian Local U19 league"},
    {country:"Australia",gender:"male",value:"1 / A League",label:"1 / A League"},
    {country:"Australia",gender:"male",value:"2 / National Premier League",label:"2 / National Premier League"},
    {country:"Australia",gender:"male",value:"3 / National Premier League ACT 2",label:"3 / National Premier League ACT 2"},
    {country:"Australia",gender:"male",value:"4 / Regional Premier League",label:"4 / Regional Premier League"},
    {country:"Australia",gender:"female",value:"1 / Australian National U19 league",label:"1 / Australian National U19 league"},
    {country:"Australia",gender:"female",value:"2 / Australian Regional U19 league",label:"2 / Australian Regional U19 league"},
    {country:"Australia",gender:"female",value:"3 / Australian Local U19 league",label:"3 / Australian Local U19 league"},
    {country:"Australia",gender:"female",value:"1 / A League",label:"1 / A League"},
    {country:"Australia",gender:"female",value:"2 / National Premier League",label:"2 / National Premier League"},
    {country:"Australia",gender:"female",value:"3 / National Premier League ACT 2",label:"3 / National Premier League ACT 2"},
    {country:"Australia",gender:"female",value:"4 / Regional Premier League",label:"4 / Regional Premier League"},
    
    {country:"Spain",gender:"male",value:"1 / División de Honor",label:"1 / División de Honor"},
    {country:"Spain",gender:"male",value:"2 / Liga Nacional",label:"2 / Liga Nacional"},
    {country:"Spain",gender:"male",value:"3 / Prefente Juvenil",label:"3 / Prefente Juvenil"},
    {country:"Spain",gender:"male",value:"4 / 1ª Regional Juvenil",label:"4 / 1ª Regional Juvenil"},
    {country:"Spain",gender:"male",value:"5 / 2ª Regional Juvenil",label:"5 / 2ª Regional Juvenil"},
    {country:"Spain",gender:"male",value:"1 / 1ª División",label:"1 / 1ª División"},
    {country:"Spain",gender:"male",value:"2 / 2ª División",label:"2 / 2ª División"},
    {country:"Spain",gender:"male",value:"3 / 1ª RFEF",label:"3 / 1ª RFEF"},
    {country:"Spain",gender:"male",value:"4 / 2ª RFEF",label:"4 / 2ª RFEF"},
    {country:"Spain",gender:"male",value:"5 / 3ª RFEF",label:"5 / 3ª RFEF"},
    {country:"Spain",gender:"male",value:"6 / 1ª Regional",label:"6 / 1ª Regional"},
    {country:"Spain",gender:"male",value:"7 / 2ª Regional",label:"7 / 2ª Regional"},
    {country:"Spain",gender:"female",value:"1 / Preferente junior",label:"1 / Preferente junior"},
    {country:"Spain",gender:"female",value:"2 / Primera Juvenil",label:"2 / Primera Juvenil"},
    {country:"Spain",gender:"female",value:"3 / Segunda Juvenil",label:"3 / Segunda Juvenil"},
    {country:"Spain",gender:"female",value:"1 / 1ª Iberdrola",label:"1 / 1ª Iberdrola"},
    {country:"Spain",gender:"female",value:"2 / 1ªRFEF ",label:"2 / 1ªRFEF "},
    {country:"Spain",gender:"female",value:"3 / 2ª RFEF",label:"3 / 2ª RFEF"},
    {country:"Spain",gender:"female",value:"4 / 3ª RFEF",label:"4 / 3ª RFEF"},
    {country:"Spain",gender:"female",value:"5 / Preferente senior",label:"5 / Preferente senior"},
    
    {country:"Germany",gender:"male",value:"1 / U19 Bundesliga",label:"1 / U19 Bundesliga"},
    {country:"Germany",gender:"male",value:"2 / Regionalliga",label:"2 / Regionalliga"},
    {country:"Germany",gender:"male",value:"3 / Verbandsliga",label:"3 / Verbandsliga"},
    {country:"Germany",gender:"male",value:"4 / Landesliga",label:"4 / Landesliga"},
    {country:"Germany",gender:"male",value:"5 / Bezirksliga",label:"5 / Bezirksliga"},
    {country:"Germany",gender:"male",value:"6 / Kreisliga",label:"6 / Kreisliga"},
    {country:"Germany",gender:"male",value:"7 / 1. Kreisklasse",label:"7 / 1. Kreisklasse"},
    {country:"Germany",gender:"male",value:"8 / 2. Kreisklasse",label:"8 / 2. Kreisklasse"},
    {country:"Germany",gender:"male",value:"9 / 3. Kreisklasse",label:"9 / 3. Kreisklasse"},
    {country:"Germany",gender:"male",value:"10 / 4. Kreisklasse",label:"10 / 4. Kreisklasse"},
    {country:"Germany",gender:"male",value:"1 / 1. Bundesliga",label:"1 / 1. Bundesliga"},
    {country:"Germany",gender:"male",value:"2 / 2. Bundesliga",label:"2 / 2. Bundesliga"},
    {country:"Germany",gender:"male",value:"3 / 3. Liga",label:"3 / 3. Liga"},
    {country:"Germany",gender:"male",value:"4 / Regionalliga",label:"4 / Regionalliga"},
    {country:"Germany",gender:"male",value:"5 / Oberliga",label:"5 / Oberliga"},
    {country:"Germany",gender:"male",value:"6 / Verbandsliga",label:"6 / Verbandsliga"},
    {country:"Germany",gender:"male",value:"7 / Landesliga",label:"7 / Landesliga"},
    {country:"Germany",gender:"male",value:"8 / Bezirksliga",label:"8 / Bezirksliga"},
    {country:"Germany",gender:"male",value:"9 / Kreisliga",label:"9 / Kreisliga"},
    {country:"Germany",gender:"male",value:"10 / 1. Kreisklasse",label:"10 / 1. Kreisklasse"},
    {country:"Germany",gender:"male",value:"11 / 2. Kreisklasse",label:"11 / 3. Kreisklasse"},
    {country:"Germany",gender:"male",value:"12 / 3. Kreisklasse",label:"12 / 4. Kreisklasse"},
    {country:"Germany",gender:"male",value:"13 / 4. Kreisklasse",label:"13 / 4. Kreisklasse"},
    {country:"Germany",gender:"female",value:"1 / U17 Bundesliga",label:"1 / U17 Bundesliga"},
    {country:"Germany",gender:"female",value:"2 / Verbandsliga",label:"2 / Verbandsliga"},
    {country:"Germany",gender:"female",value:"3 / Landesliga",label:"3 / Landesliga"},
    {country:"Germany",gender:"female",value:"4 / Bezirksliga",label:"4 / Bezirksliga"},
    {country:"Germany",gender:"female",value:"5 / Kreisliga",label:"5 / Kreisliga"},
    {country:"Germany",gender:"female",value:"1 / 1. Bundesliga",label:"1 / 1. Bundesliga"},
    {country:"Germany",gender:"female",value:"2 / 2. Bundesliga",label:"2 / 2. Bundesliga"},
    {country:"Germany",gender:"female",value:"3 / Regionalliga",label:"3 / Regionalliga"},
    {country:"Germany",gender:"female",value:"4 / Verbandsliga/Oberliga",label:"4 / Verbandsliga/Oberliga"},
    {country:"Germany",gender:"female",value:"5 / Landesliga",label:"5 / Landesliga"},
    {country:"Germany",gender:"female",value:"6 / Bezirksliga",label:"6 / Bezirksliga"},
    {country:"Germany",gender:"female",value:"7 / Kreisliga",label:"7 / Kreisliga"},
    
    {country:"Austria",gender:"male",value:"1 / OFB-Jugendliga U18",label:"1 / OFB-Jugendliga U18"},
    {country:"Austria",gender:"male",value:"2 / U18 Landesliga",label:"2 / U18 Landesliga"},
    {country:"Austria",gender:"male",value:"3 / 1. Gruppe",label:"3 / 1. Gruppe"},
    {country:"Austria",gender:"male",value:"4 / 2. Gruppe",label:"4 / 2. Gruppe"},
    {country:"Austria",gender:"male",value:"5 / 3. Gruppe",label:"5 / 3. Gruppe"},
    {country:"Austria",gender:"male",value:"1 / Tipico Bundesliga",label:"1 / Tipico Bundesliga"},
    {country:"Austria",gender:"male",value:"2 / 2. Liga",label:"2 / 2. Liga"},
    {country:"Austria",gender:"male",value:"3 / Regionalliga",label:"3 / Regionalliga"},
    {country:"Austria",gender:"male",value:"4 / Landesliga",label:"4 / Landesliga"},
    {country:"Austria",gender:"male",value:"5 / Bezirksliga",label:"5 / Bezirksliga"},
    {country:"Austria",gender:"male",value:"6 / 1. Klasse",label:"6 / 1. Klasse"},
    {country:"Austria",gender:"male",value:"7 / 2. Klasse",label:"7 / 2. Klasse"},
    {country:"Austria",gender:"female",value:"1 / Uniqua Maedchen Fussballliga",label:"1 / Uniqua Maedchen Fussballliga"},
    {country:"Austria",gender:"female",value:"2 / Landesliga",label:"2 / Landesliga"},
    {country:"Austria",gender:"female",value:"1 / Planet Pure Frauen Bundesliga",label:"1 / Planet Pure Frauen Bundesliga"},
    {country:"Austria",gender:"female",value:"2 / 2. Liga Frauen",label:"2 / 2. Liga Frauen"},
    {country:"Austria",gender:"female",value:"3 / Landesliga",label:"3 / Landesliga"},

    {country:"Iceland",gender:"male",value:"1 / Icelandic National U19 League (A league)",label:"1 / Icelandic National U19 League (A league)"},
    {country:"Iceland",gender:"male",value:"2 / Icelandic National U19 League (B league)",label:"2 / Icelandic National U19 League (B league)"},
    {country:"Iceland",gender:"male",value:"3 / Icelandic National U19 League (C league)",label:"3 / Icelandic National U19 League (C league)"},
    {country:"Iceland",gender:"male",value:"4 / Icelandic National U19 League B teams",label:"4 / Icelandic National U19 League B teams"},
    {country:"Iceland",gender:"male",value:"1 / Premier league",label:"1 / Premier league"},
    {country:"Iceland",gender:"male",value:"2 / 1st Division",label:"2 / 1st Division"},
    {country:"Iceland",gender:"male",value:"3 / 2nd Division",label:"3 / 2nd Division"},
    {country:"Iceland",gender:"male",value:"4 / 3rd Division",label:"4 / 3rd Division"},
    {country:"Iceland",gender:"male",value:"5 / 4th Division",label:"5 / 4th Division"},
    {country:"Iceland",gender:"female",value:"1 / Icelandic National U19 League (A league)",label:"1 / Icelandic National U19 League (A league)"},
    {country:"Iceland",gender:"female",value:"2 / Icelandic National U19 League (B league)",label:"2 / Icelandic National U19 League (B league)"},
    {country:"Iceland",gender:"female",value:"1 / Premier League",label:"1 / Premier League"},
    {country:"Iceland",gender:"female",value:"2 / 1st Division",label:"2 / 1st Division"},
    {country:"Iceland",gender:"female",value:"3 / 2nd Division",label:"3 / 2nd Division"},

    {country:"Switzerland",gender:"male",value:"1 / Elite Youth League",label:"1 / Elite Youth League"},
    {country:"Switzerland",gender:"male",value:"2 / Coca Cola Junior League",label:"2 / Coca Cola Junior League"},
    {country:"Switzerland",gender:"male",value:"3 / 1. Stärkeklasse",label:"3 / 1. Stärkeklasse"},
    {country:"Switzerland",gender:"male",value:"4 / 2. Stärkeklasse",label:"4 / 2. Stärkeklasse"},
    {country:"Switzerland",gender:"male",value:"1 / Super League Swiss",label:"1 / Super League Swiss"},
    {country:"Switzerland",gender:"male",value:"2 / Challenge League ",label:"2 / Challenge League "},
    {country:"Switzerland",gender:"male",value:"3 / Promotion League",label:"3 / Promotion League"},
    {country:"Switzerland",gender:"male",value:"4 / 1. Liga Classic",label:"4 / 1. Liga Classic"},
    {country:"Switzerland",gender:"male",value:"5 / 2. Liga Interregional",label:"5 / 2. Liga Interregional"},
    {country:"Switzerland",gender:"male",value:"6 / 2. Liga Regional ",label:"6 / 2. Liga Regional "},
    {country:"Switzerland",gender:"male",value:"7 / 3. Liga ",label:"7 / 3. Liga "},
    {country:"Switzerland",gender:"male",value:"8 / 4. Liga ",label:"8 / 4. Liga "},
    {country:"Switzerland",gender:"male",value:"9 / 5. Liga",label:"9 / 5. Liga"},
    {country:"Switzerland",gender:"female",value:"1 / Elite Youth League",label:"1 / Elite Youth League"},
    {country:"Switzerland",gender:"female",value:"2 / 2. Liga Regional",label:"2 / 2. Liga Regional"},
    {country:"Switzerland",gender:"female",value:"3 / 3. Liga Regional",label:"3 / 3. Liga Regional"},
    {country:"Switzerland",gender:"female",value:"1 / Nationalliga A",label:"1 / Nationalliga A"},
    {country:"Switzerland",gender:"female",value:"2 / Nationalliga B",label:"2 / Nationalliga B"},
    {country:"Switzerland",gender:"female",value:"3 / 1. Liga",label:"3 / . Liga"},
    {country:"Switzerland",gender:"female",value:"4 / 2. Liga",label:"4 / 2. Liga"},
    {country:"Switzerland",gender:"female",value:"5 / 3. Liga",label:"5 / 3. Liga"},
    {country:"Switzerland",gender:"female",value:"6 / 4. Liga",label:"6 / 4. Liga"},

    {country:"Italy",gender:"male",value:"1 / Primavera 1 U19s",label:"1 / Primavera 1 U19s"},
    {country:"Italy",gender:"male",value:"2 / Primavera 2 U19s",label:"2 / Primavera 2 U19s"},
    {country:"Italy",gender:"male",value:"3 / Primavera 3 U19s",label:"3 / Primavera 3 U19s"},
    {country:"Italy",gender:"male",value:"4 / Primavera 4 U19s",label:"4 / Primavera 4 U19s"},
    {country:"Italy",gender:"male",value:"5 / Juniores Nazionale",label:"5 / Juniores Nazionale"},
    {country:"Italy",gender:"male",value:"6 / Juniores Regionale Elite",label:"6 / Juniores Regionale Elite"},
    {country:"Italy",gender:"male",value:"7 / Juniores Regionale",label:"7 / Juniores Regionale"},
    {country:"Italy",gender:"male",value:"8 / Juniores Provinciale",label:"8 / Juniores Provinciale"},
    {country:"Italy",gender:"male",value:"1 / Allievi Nazionali Serie A and B",label:"1 / Allievi Nazionali Serie A and B"},
    {country:"Italy",gender:"male",value:"2 / Allievi Nazionali Serie C",label:"2 / Allievi Nazionali Serie C"},
    {country:"Italy",gender:"male",value:"3 / Allievi Regionali Elite",label:"3 / Allievi Regionali Elite"},
    {country:"Italy",gender:"male",value:"4 / Allievi Regionali",label:"4 / Allievi Regionali"},
    {country:"Italy",gender:"male",value:"5 / Allievi Provinciali",label:"5 / Allievi Provinciali"},
    {country:"Italy",gender:"male",value:"1 / Serie A",label:"1 / Serie A"},
    {country:"Italy",gender:"male",value:"2 / Serie B",label:"2 / Serie B"},
    {country:"Italy",gender:"male",value:"3 / Serie C",label:"3 / Serie C"},
    {country:"Italy",gender:"male",value:"4 / Serie D",label:"4 / Serie D"},
    {country:"Italy",gender:"male",value:"5 / Eccellenza",label:"5 / Eccellenza"},
    {country:"Italy",gender:"male",value:"6 / Promozione",label:"6 / Promozione"},
    {country:"Italy",gender:"male",value:"7 / 1 categoria",label:"7 / 1 categoria"},
    {country:"Italy",gender:"male",value:"8 / 2 categoria",label:"8 / 2 categoria"},
    {country:"Italy",gender:"male",value:"9 / 3 categoria",label:"9 / 3 categoria"},
    {country:"Italy",gender:"female",value:"1 / Primavera 1",label:"1 / Primavera 1"},
    {country:"Italy",gender:"female",value:"2 / Juniores U19",label:"2 / Juniores U19"},
    {country:"Italy",gender:"female",value:"1 / Allieve Nazionali",label:"1 / Allieve Nazionali"},
    {country:"Italy",gender:"female",value:"2 / Allieve Regionali ",label:"2 / Allieve Regionali "},
    {country:"Italy",gender:"female",value:"1 / Serie A",label:"1 / Serie A"},
    {country:"Italy",gender:"female",value:"2 / Serie B",label:"2 / Serie B"},
    {country:"Italy",gender:"female",value:"3 / Serie C",label:"3 / Serie C"},
    {country:"Italy",gender:"female",value:"4 / Eccellenza",label:"4 / Eccellenza"},
    {country:"Italy",gender:"female",value:"5 / Promozione",label:"5 / Promozione"},

    {country:"Portugal",gender:"male",value:"1 / 1ª nacional",label:"1 / 1ª nacional"},
    {country:"Portugal",gender:"male",value:"2 / 2ª nacional",label:"2 / 2ª nacional"},
    {country:"Portugal",gender:"male",value:"3 / 1ª regional",label:"3 / 1ª regional"},
    {country:"Portugal",gender:"male",value:"4 / 2ª regional",label:"4 / 2ª regional"},
    {country:"Portugal",gender:"male",value:"5 / 3ª regional",label:"5 / 3ª regional"},
    {country:"Portugal",gender:"male",value:"1 / Primeira divisão",label:"1 / Primeira divisão"},
    {country:"Portugal",gender:"male",value:"2 / Segunda divisão",label:"2 / Segunda divisão"},
    {country:"Portugal",gender:"male",value:"3 / Terceira divisão",label:"3 / Terceira divisão"},
    {country:"Portugal",gender:"male",value:"4 / Campeonato Nacional de Seniores",label:"4 / Campeonato Nacional de Seniores"},
    {country:"Portugal",gender:"male",value:"5 / 1ª distrital",label:"5 / 1ª distrital"},
    {country:"Portugal",gender:"male",value:"6 / 2ª distrital",label:"6 / 2ª distrital"},
    {country:"Portugal",gender:"male",value:"7 / 3ª distrital",label:"7 / 3ª distrital"},
    {country:"Portugal",gender:"female",value:"1 / 1ª nacional junior",label:"1 / 1ª nacional junior"},
    {country:"Portugal",gender:"female",value:"2 / 2ª nacional junior",label:"2 / 2ª nacional junior"},
    {country:"Portugal",gender:"female",value:"1 / 1ª nacional senior",label:"1 / 1ª nacional senior"},
    {country:"Portugal",gender:"female",value:"2 / 2ª nacional senior",label:"2 / 2ª nacional senior"},
    {country:"Portugal",gender:"female",value:"3 / 3ª nacional senior",label:"3 / 3ª nacional senior"},

    {country:"France",gender:"male",value:"1 / U19 National",label:"1 / U19 National"},
    {country:"France",gender:"male",value:"2 / U19 Regional 1",label:"2 / U19 Regional 1"},
    {country:"France",gender:"male",value:"3 / U19 Regional 2",label:"3 / U19 Regional 2"},
    {country:"France",gender:"male",value:"4 / U19 Regional 3",label:"4 / U19 Regional 3"},
    {country:"France",gender:"male",value:"1 / U17 National",label:"1 / U17 National"},
    {country:"France",gender:"male",value:"2 / U17 Regional 1",label:"2 / U17 Regional 1"},
    {country:"France",gender:"male",value:"3 / U17 Regional 2",label:"3 / U17 Regional 2"},
    {country:"France",gender:"male",value:"4 / U17 Regional 3",label:"4 / U17 Regional 3"},
    {country:"France",gender:"male",value:"1 / Ligue 1",label:"1 / Ligue 1"},
    {country:"France",gender:"male",value:"2 / Ligue 2",label:"2 / Ligue 2"},
    {country:"France",gender:"male",value:"3 / National 1",label:"3 / National 1"},
    {country:"France",gender:"male",value:"4 / National 2",label:"4 / National 2"},
    {country:"France",gender:"male",value:"5 / National 3",label:"5 / National 3"},
    {country:"France",gender:"male",value:"6 / Regional 1",label:"6 / Regional 1"},
    {country:"France",gender:"male",value:"7 / Regional 2",label:"7 / Regional 2"},
    {country:"France",gender:"male",value:"8 / Regional 3",label:"8 / Regional 3"},
    {country:"France",gender:"male",value:"9 / Departement 1",label:"9 / Departement 1"},
    {country:"France",gender:"female",value:"1 / U19 National",label:"1 / U19 National"},
    {country:"France",gender:"female",value:"2 / U19 Regional",label:"2 / U19 Regional"},
    {country:"France",gender:"female",value:"1 / U17 National",label:"1 / U17 National"},
    {country:"France",gender:"female",value:"2 / U17 Regional",label:"2 / U17 Regional"},
    {country:"France",gender:"female",value:"1 / Division 1",label:"1 / Division 1"},
    {country:"France",gender:"female",value:"2 / Division 2",label:"2 / Division 2"},
    {country:"France",gender:"female",value:"3 / Regional 1",label:"3 / Regional 1"},
    {country:"France",gender:"female",value:"4 / Regional 2",label:"4 / Regional 2"},

    {country:"Mexico",gender:"male",value:"1 / Liga MX Fuerzas básicas U20",label:"1 / Liga MX Fuerzas básicas U20"},
    {country:"Mexico",gender:"male",value:"2 / Liga MX Fuerzas básicas U18",label:"2 / Liga MX Fuerzas básicas U18"},
    {country:"Mexico",gender:"male",value:"3 / Liga TDP",label:"3 / Liga TDP"},
    {country:"Mexico",gender:"male",value:"4 / Campeonato Universitario TELMEX",label:"4 / Campeonato Universitario TELMEX"},
    {country:"Mexico",gender:"male",value:"5 / CONADE",label:"5 / CONADE"},
    {country:"Mexico",gender:"male",value:"6 / CONDE",label:"6 / CONDE"},
    {country:"Mexico",gender:"male",value:"7 / CONADEIP",label:"7 / CONADEIP"},
    {country:"Mexico",gender:"male",value:"1 / Liga MX",label:"1 / Liga MX"},
    {country:"Mexico",gender:"male",value:"2 / Liga Expansion",label:"2 / Liga Expansion"},
    {country:"Mexico",gender:"male",value:"3 / Liga Premier",label:"3 / Liga Premier"},
    {country:"Mexico",gender:"female",value:"1 / Liga MX Fuerzas básicas U17",label:"1 / Liga MX Fuerzas básicas U17"},
    {country:"Mexico",gender:"female",value:"2 / Campeonato Universitario TELMEX",label:"2 / Campeonato Universitario TELMEX"},
    {country:"Mexico",gender:"female",value:"3 / CONADE",label:"3 / CONADE"},
    {country:"Mexico",gender:"female",value:"4 / CONADEIP",label:"4 / CONADEIP"},
    {country:"Mexico",gender:"female",value:"1 / Liga MX",label:"1 / Liga MX"},

]


export const countries = [
    {value: "Afghanistan", label: "Afghanistan"},
    {value: "Åland Islands", label: "Åland Islands"},
    {value: "Albania", label: "Albania"},
    {value: "Algeria", label: "Algeria"},
    {value: "American Samoa", label: "American Samoa"},
    {value: "Andorra", label: "Andorra"},
    {value: "Angola", label: "Angola"},
    {value: "Anguilla", label: "Anguilla"},
    {value: "Antarctica", label: "Antarctica"},
    {value: "Antigua and Barbuda", label: "Antigua and Barbuda"},
    {value: "Argentina", label: "Argentina"},
    {value: "Armenia", label: "Armenia"},
    {value: "Aruba", label: "Aruba"},
    {value: "Australia", label: "Australia"},
    {value: "Austria", label: "Austria"},
    {value: "Azerbaijan", label: "Azerbaijan"},
    {value: "Bahamas", label: "Bahamas"},
    {value: "Bahrain", label: "Bahrain"},
    {value: "Bangladesh", label: "Bangladesh"},
    {value: "Barbados", label: "Barbados"},
    {value: "Belarus", label: "Belarus"},
    {value: "Belgium", label: "Belgium"},
    {value: "Belize", label: "Belize"},
    {value: "Benin", label: "Benin"},
    {value: "Bermuda", label: "Bermuda"},
    {value: "Bhutan", label: "Bhutan"},
    {value: "Bolivia", label: "Bolivia"},
    {value: "Bonaire, Sint Eustatius and Saba", label: "Bonaire, Sint Eustatius and Saba"},
    {value: "Bosnia and Herzegovina", label: "Bosnia and Herzegovina"},
    {value: "Botswana", label: "Botswana"},
    {value: "Cameroon", label: "Cameroon"},
    {value: "Bouvet Island", label: "Bouvet Island"},
    {value: "Cabo Verde", label: "Cabo Verde"},
    {value: "Brazil", label: "Brazil"},
    {value: "Central African Republic", label: "Central African Republic"},
    {value: "British Indian Ocean Territory", label: "British Indian Ocean Territory"},
    {value: "Chile", label: "Chile"},
    {value: "United States Minor Outlying Islands", label: "United States Minor Outlying Islands"},
    {value: "Virgin Islands (British)", label: "Virgin Islands (British)"},
    {value: "Virgin Islands (U.S.)", label: "Virgin Islands (U.S.)"},
    {value: "Brunei Darussalam", label: "Brunei Darussalam"},
    {value: "Bulgaria", label: "Bulgaria"},
    {value: "Burkina Faso", label: "Burkina Faso"},
    {value: "Burundi", label: "Burundi"},
    {value: "Cambodia", label: "Cambodia"},
    {value: "Canada", label: "Canada"},
    {value: "Cayman Islands", label: "Cayman Islands"},
    {value: "Chad", label: "Chad"},
    {value: "China", label: "China"},
    {value: "Christmas Island", label: "Christmas Island"},
    {value: "Cocos (Keeling) Islands", label: "Cocos (Keeling) Islands"},
    {value: "Colombia", label: "Colombia"},
    {value: "Comoros", label: "Comoros"},
    {value: "Congo", label: "Congo"},
    {value: "Congo", label: "Congo"},
    {value: "Cook Islands", label: "Cook Islands"},
    {value: "Costa Rica", label: "Costa Rica"},
    {value: "Croatia", label: "Croatia"},
    {value: "Cuba", label: "Cuba"},
    {value: "Curaçao", label: "Curaçao"},
    {value: "Cyprus", label: "Cyprus"},
    {value: "Czech Republic", label: "Czech Republic"},
    {value: "Denmark", label: "Denmark"},
    {value: "Djibouti", label: "Djibouti"},
    {value: "Dominica", label: "Dominica"},
    {value: "Dominican Republic", label: "Dominican Republic"},
    {value: "Ecuador", label: "Ecuador"},
    {value: "Egypt", label: "Egypt"},
    {value: "El Salvador", label: "El Salvador"},
    {value: "England", label: "England"},
    {value: "Equatorial Guinea", label: "Equatorial Guinea"},
    {value: "Eritrea", label: "Eritrea"},
    {value: "Estonia", label: "Estonia"},
    {value: "Ethiopia", label: "Ethiopia"},
    {value: "Falkland Islands (Malvinas)", label: "Falkland Islands (Malvinas)"},
    {value: "Faroe Islands", label: "Faroe Islands"},
    {value: "Fiji", label: "Fiji"},
    {value: "Finland", label: "Finland"},
    {value: "France", label: "France"},
    {value: "French Guiana", label: "French Guiana"},
    {value: "French Polynesia", label: "French Polynesia"},
    {value: "French Southern Territories", label: "French Southern Territories"},
    {value: "Gabon", label: "Gabon"},
    {value: "Ghana", label: "Ghana"},
    {value: "Gambia", label: "Gambia"},
    {value: "Greece", label: "Greece"},
    {value: "Georgia", label: "Georgia"},
    {value: "Grenada", label: "Grenada"},
    {value: "Germany", label: "Germany"},
    {value: "Guam", label: "Guam"},
    {value: "Gibraltar", label: "Gibraltar"},
    {value: "Guernsey", label: "Guernsey"},
    {value: "Greenland", label: "Greenland"},
    {value: "Guinea-Bissau", label: "Guinea-Bissau"},
    {value: "Guadeloupe", label: "Guadeloupe"},
    {value: "Haiti", label: "Haiti"},
    {value: "Guatemala", label: "Guatemala"},
    {value: "Holy See", label: "Holy See"},
    {value: "Guinea", label: "Guinea"},
    {value: "Guyana", label: "Guyana"},
    {value: "Heard Island and McDonald Islands", label: "Heard Island and McDonald Islands"},
    {value: "Honduras", label: "Honduras"},
    {value: "Hong Kong", label: "Hong Kong"},
    {value: "Hungary", label: "Hungary"},
    {value: "Iceland", label: "Iceland"},
    {value: "India", label: "India"},
    {value: "Indonesia", label: "Indonesia"},
    {value: "Côte d'Ivoire", label: "Côte d'Ivoire"},
    {value: "Iran (Islamic Republic of)", label: "Iran (Islamic Republic of)"},
    {value: "Iraq", label: "Iraq"},
    {value: "Republic of Ireland", label: "Republic of Ireland"},
    {value: "Isle of Man", label: "Isle of Man"},
    {value: "Israel", label: "Israel"},
    {value: "Italy", label: "Italy"},
    {value: "Jamaica", label: "Jamaica"},
    {value: "Japan", label: "Japan"},
    {value: "Jersey", label: "Jersey"},
    {value: "Jordan", label: "Jordan"},
    {value: "Kazakhstan", label: "Kazakhstan"},
    {value: "Kenya", label: "Kenya"},
    {value: "Kiribati", label: "Kiribati"},
    {value: "Kuwait", label: "Kuwait"},
    {value: "Kyrgyzstan", label: "Kyrgyzstan"},
    {value: "Lao People's Democratic Republic", label: "Lao People's Democratic Republic"},
    {value: "Latvia", label: "Latvia"},
    {value: "Lebanon", label: "Lebanon"},
    {value: "Lesotho", label: "Lesotho"},
    {value: "Liberia", label: "Liberia"},
    {value: "Libya", label: "Libya"},
    {value: "Liechtenstein", label: "Liechtenstein"},
    {value: "Lithuania", label: "Lithuania"},
    {value: "Luxembourg", label: "Luxembourg"},
    {value: "Macao", label: "Macao"},
    {value: "Macedonia", label: "Macedonia"},
    {value: "Madagascar", label: "Madagascar"},
    {value: "Malawi", label: "Malawi"},
    {value: "Malaysia", label: "Malaysia"},
    {value: "Maldives", label: "Maldives"},
    {value: "Mali", label: "Mali"},
    {value: "Malta", label: "Malta"},
    {value: "Marshall Islands", label: "Marshall Islands"},
    {value: "Martinique", label: "Martinique"},
    {value: "Mauritania", label: "Mauritania"},
    {value: "Mauritius", label: "Mauritius"},
    {value: "Mayotte", label: "Mayotte"},
    {value: "Mexico", label: "Mexico"},
    {value: "Micronesia (Federated States of)", label: "Micronesia (Federated States of)"},
    {value: "Moldova (Republic of)", label: "Moldova (Republic of)"},
    {value: "Monaco", label: "Monaco"},
    {value: "Mongolia", label: "Mongolia"},
    {value: "Montenegro", label: "Montenegro"},
    {value: "Montserrat", label: "Montserrat"},
    {value: "Morocco", label: "Morocco"},
    {value: "Mozambique", label: "Mozambique"},
    {value: "Myanmar", label: "Myanmar"},
    {value: "Namibia", label: "Namibia"},
    {value: "Nauru", label: "Nauru"},
    {value: "Nepal", label: "Nepal"},
    {value: "Netherlands", label: "Netherlands"},
    {value: "New Caledonia", label: "New Caledonia"},
    {value: "New Zealand", label: "New Zealand"},
    {value: "Nicaragua", label: "Nicaragua"},
    {value: "Niger", label: "Niger"},
    {value: "Nigeria", label: "Nigeria"},
    {value: "Niue", label: "Niue"},
    {value: "Norfolk Island", label: "Norfolk Island"},
    {value: "Korea (Democratic People's Republic of)", label: "Korea (Democratic People's Republic of)"},
    {value: "Northern Ireland", label: "Northern Ireland"},
    {value: "Northern Mariana Islands", label: "Northern Mariana Islands"},
    {value: "Norway", label: "Norway"},
    {value: "Oman", label: "Oman"},
    {value: "Pakistan", label: "Pakistan"},
    {value: "Palau", label: "Palau"},
    {value: "Palestine, State of", label: "Palestine, State of"},
    {value: "Panama", label: "Panama"},
    {value: "Papua New Guinea", label: "Papua New Guinea"},
    {value: "Paraguay", label: "Paraguay"},
    {value: "Peru", label: "Peru"},
    {value: "Philippines", label: "Philippines"},
    {value: "Pitcairn", label: "Pitcairn"},
    {value: "Poland", label: "Poland"},
    {value: "Portugal", label: "Portugal"},
    {value: "Puerto Rico", label: "Puerto Rico"},
    {value: "Qatar", label: "Qatar"},
    {value: "Republic of Kosovo", label: "Republic of Kosovo"},
    {value: "Réunion", label: "Réunion"},
    {value: "Romania", label: "Romania"},
    {value: "Russian Federation", label: "Russian Federation"},
    {value: "Rwanda", label: "Rwanda"},
    {value: "Saint Barthélemy", label: "Saint Barthélemy"},
    {value: "Saint Helena, Ascension and Tristan da Cunha", label: "Saint Helena, Ascension and Tristan da Cunha"},
    {value: "Saint Kitts and Nevis", label: "Saint Kitts and Nevis"},
    {value: "Saint Lucia", label: "Saint Lucia"},
    {value: "Saint Martin (French part)", label: "Saint Martin (French part)"},
    {value: "Saint Pierre and Miquelon", label: "Saint Pierre and Miquelon"},
    {value: "Saint Vincent and the Grenadines", label: "Saint Vincent and the Grenadines"},
    {value: "Samoa", label: "Samoa"},
    {value: "San Marino", label: "San Marino"},
    {value: "Sao Tome and Principe", label: "Sao Tome and Principe"},
    {value: "Saudi Arabia", label: "Saudi Arabia"},
    {value: "Senegal", label: "Senegal"},
    {value: "Serbia", label: "Serbia"},
    {value: "Seychelles", label: "Seychelles"},
    {value: "Sierra Leone", label: "Sierra Leone"},
    {value: "Singapore", label: "Singapore"},
    {value: "Sint Maarten (Dutch part)", label: "Sint Maarten (Dutch part)"},
    {value: "Slovakia", label: "Slovakia"},
    {value: "Slovenia", label: "Slovenia"},
    {value: "Solomon Islands", label: "Solomon Islands"},
    {value: "Somalia", label: "Somalia"},
    {value: "South Africa", label: "South Africa"},
    {value: "South Georgia and the South Sandwich Islands", label: "South Georgia and the South Sandwich Islands"},
    {value: "Korea (Republic of)", label: "Korea (Republic of)"},
    {value: "Scotland", label: "Scotland"},
    {value: "South Sudan", label: "South Sudan"},
    {value: "Spain", label: "Spain"},
    {value: "Sri Lanka", label: "Sri Lanka"},
    {value: "Sudan", label: "Sudan"},
    {value: "Suriname", label: "Suriname"},
    {value: "Svalbard and Jan Mayen", label: "Svalbard and Jan Mayen"},
    {value: "Swaziland", label: "Swaziland"},
    {value: "Sweden", label: "Sweden"},
    {value: "Switzerland", label: "Switzerland"},
    {value: "Syrian Arab Republic", label: "Syrian Arab Republic"},
    {value: "Taiwan", label: "Taiwan"},
    {value: "Tajikistan", label: "Tajikistan"},
    {value: "Tanzania, United Republic of", label: "Tanzania, United Republic of"},
    {value: "Thailand", label: "Thailand"},
    {value: "Timor-Leste", label: "Timor-Leste"},
    {value: "Togo", label: "Togo"},
    {value: "Tokelau", label: "Tokelau"},
    {value: "Tonga", label: "Tonga"},
    {value: "Trinidad and Tobago", label: "Trinidad and Tobago"},
    {value: "Tunisia", label: "Tunisia"},
    {value: "Turkey", label: "Turkey"},
    {value: "Turkmenistan", label: "Turkmenistan"},
    {value: "Turks and Caicos Islands", label: "Turks and Caicos Islands"},
    {value: "Tuvalu", label: "Tuvalu"},
    {value: "Uganda", label: "Uganda"},
    {value: "Ukraine", label: "Ukraine"},
    {value: "United Arab Emirates", label: "United Arab Emirates"},
    {value: "United Kingdom", label: "United Kingdom"},
    {value: "United States of America", label: "United States of America"},
    {value: "Uruguay", label: "Uruguay"},
    {value: "Uzbekistan", label: "Uzbekistan"},
    {value: "Vanuatu", label: "Vanuatu"},
    {value: "Venezuela", label: "Venezuela"},
    {value: "Viet Nam", label: "Viet Nam"},
    {value: "Wales", label: "Wales"},
    {value: "Wallis and Futuna", label: "Wallis and Futuna"},
    {value: "Western Sahara", label: "Western Sahara"},
    {value: "Yemen", label: "Yemen"},
    {value: "Zambia", label: "Zambia"},
    {value: "Zimbabwe", label: "Zimbabwe"},
    ];

export const athleteHasAllRequiredFields = (athlete) => {
    if(athlete.sport === "Soccer"){
        const returnedSoccer = 'birthdate' in athlete && 'contact_email' in athlete && 'nationality' in athlete && 'gender' in athlete && 'profile_pic' in athlete && 'sport' in athlete && 'position' in athlete && 'second_position' in athlete && 'height' in athlete && 'target_division' in athlete && 'compliance' in athlete && 'current_status' in athlete && 'start_date' in athlete && 'high_school_graduation_date' in athlete && 'intended_field_of_studies' in athlete && 'type_of_admission' in athlete && 'estimated_gpa' in athlete && 'budget' in athlete
        return returnedSoccer
    }
    else{
        const returnedTennis = 'birthdate' in athlete && 'contact_email' in athlete && 'nationality' in athlete && 'gender' in athlete && 'profile_pic' in athlete && 'sport' in athlete && 'tennis_profile' in athlete && 'height' in athlete && 'target_division' in athlete && 'compliance' in athlete && 'current_status' in athlete && 'start_date' in athlete && 'high_school_graduation_date' in athlete && 'intended_field_of_studies' in athlete && 'type_of_admission' in athlete && 'estimated_gpa' in athlete && 'budget' in athlete
        let checkTennis = false
        if ('tennis_profile' in athlete){
            checkTennis = 'utr' in athlete.tennis_profile && 'national_ranking' in athlete.tennis_profile && 'international_ranking' in athlete.tennis_profile && 'DominantHand' in athlete.tennis_profile
        }
        return returnedTennis && checkTennis
    }
}

export const changeNameFieldToString = (variable) => {
    const stringNameForVariables = {
        "isVisible":"Athlete visible in the app",
        "name":"Name",
        "agencyID":"Agency",
        "agentID":"Agent",
        "birthdate":"Birthdate",
        "contact_email":"Contact email",
        "nationality":"Nationality",
        "gender":"Gender",
        "profile_pic":"Profile picture",
        "sport":"Sport",
        "country_of_competition":"Country of competition",
        "current_club":"Current club",
        "height":"Height",
        "weight":"Weight",
        "target_division":"Target division",
        "compliance":"Compliance",
        "current_status":"Current status",
        "sports_background":"Sport background",
        "agency_assesment":"Agency assesment",
        "level_of_competition":"Level of competition",
        "start_date":"Start date",
        "high_school_graduation_date":"High school graduation date",
        "intended_field_of_studies":"Intended field of studies",
        "type_of_admission":"Type of admission",
        "estimated_gpa":"Estimated GPA",
        "budget":"Budget",
        "position":"Position",
        "second_position":"Second position",
        "dominant_foot":"Dominant foot",
        "utr":"UTR",
        "national_ranking":"National ranking",
        "international_ranking":"International ranking",
        "DominantHand":"Dominant hand",
    }

    return stringNameForVariables[variable]
}


export const whichFieldsAreNotFilledFromAthlete = (athlete) => {
    
    // The following fields are now not require (25 - 10 - 2022)
        // "current_club"
        // "sports_background"
        // "agency_assesment"
        // "level_of_competition"
    
    // All common & mandatory fields
    // let fields = ["isVisible","name","agencyID","agentID","birthdate","contact_email","nationality","gender","profile_pic","sport","height","target_division","compliance","current_status","start_date","high_school_graduation_date","intended_field_of_studies","type_of_admission","estimated_gpa","budget","category"]
    let fields = ["isVisible","name","agencyID","agentID","birthdate","contact_email","nationality","gender","profile_pic","sport","height","target_division","compliance","current_status","start_date","high_school_graduation_date","intended_field_of_studies","type_of_admission","estimated_gpa","budget"]
    if(athlete.sport === "Soccer"){fields.push("position","second_position","dominant_foot")}
    
    let keys = Object.keys(athlete)
    // Need to check tennis fields
    if(athlete.sport === "Tennis"){
        fields.push("DominantHand")
        athlete.tennis_profile && keys.push(...Object.keys(athlete.tennis_profile))
    }
    
    return fields.filter( f => !keys.includes(f) || athlete[f] === undefined)

}

export const getAuxDataVariable = (variable) => {
    const changeVariable = {
        "In App":"in_app",
        "Registered":"registered",
        "UniqueID":"uniqueID",
        "Added":"added",
        "Removed":"removed",
        "First name":"first_name",
        "Last name":"last_name",
        "Position":"position",
        "Contact email":"contact_email",
        "Contact phone":"contact_phone",
        "Individual twitter":"individual_twitter",
        "Individual facebook":"individual_facebook",
        "Individual instagram":"individual_instagram",
        "Conference":"conference",
        "Division":"division",
        "Sport":"sport",
        "Landing pages":"landing_pages",
        "Team twitter":"team_twitter",
        "Team facebook":"team_facebook",
        "Team instagram":"team_instagram",
        "Questionnaire":"questionnaire",
        "Region":"region",
        "State":"state",
        "School":"school",
        "School 2":"school2",
        "State 2":"state2",
        "City":"city",
        "Size of city":"size_of_city",
        "Private/public":"private_or_public",
        "Religious":"religious",
        "Average GPA":"average_gpa",
        "SAT reading":"sat_reading",
        "SAT math":"sat_math",
        "ACT composite":"act_composite",
        "Acceptance rate":"acceptance_rate",
        "Total yearly cost":"total_yearly_cost",
        "School 3":"school3",
        "Majors offered":"majors_offered",
        "No undergrads":"number_of_undergrads",
        "US ranking":"us_ranking",
        "US arts ranking":"us_arts_ranking",
        "IPEDS":"ipeds",
    }
    

    return changeVariable[variable]

}

export const getNumberOfTeams = (coaches, teams, genre, sport) => {
    const uniqueTeams = []

    coaches.map(it =>{
        if(it.sport === sport && teams.find(t=> t.id === it.teamID)?.genre === genre && !(uniqueTeams.includes(it.teamID))){
            uniqueTeams.push(it.teamID)
        }
    })

    return uniqueTeams.length

}

export const getNumberOfDaysBetween = (date1, date2) => {
    // To set two dates to two variables
    const standardDate1 = new Date(date1);
    const standardDate2 = new Date(date2);
        
    // To calculate the time difference of two dates
    const Difference_In_Time = standardDate2.getTime() - standardDate1.getTime();
        
    // To calculate the no. of days between two dates
    const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
        
    return Difference_In_Days

}

export const getActualSeason = () => {

    const today = new Date()
    // Date when we pass from spring to fall
    const underLimit = new Date(`${today.getFullYear()}-01-30`)
    // Date when we pass from fall to spring
    const upperLimit = new Date(`${today.getFullYear()}-08-30`)

    if(upperLimit > today && today > underLimit){ return `FALL_${today.getFullYear()}` }
    else if ( underLimit > today ){ return `SPRING_${today.getFullYear()}` }
    else { return `SPRING_${today.getFullYear()+1}` }
}

export const getActiveSeasons = () => {

    const startDates = [
        "SPRING_2023",
        "FALL_2023",
        "SPRING_2024",
        "FALL_2024",
        "SPRING_2025",
        "FALL_2025",
        "SPRING_2026",
        "FALL_2026",
    ]
    
    const today = new Date()
    // Date when we pass from spring to fall
    const underLimit = new Date(`${today.getFullYear()}-01-30`)
    // Date when we pass from fall to spring
    const upperLimit = new Date(`${today.getFullYear()}-08-30`)

    if(upperLimit > today && today > underLimit){
        return startDates.slice(startDates.indexOf(`FALL_${today.getFullYear()}`))
    }
    else if ( underLimit > today ){
        return startDates.slice(startDates.indexOf(`SPRING_${today.getFullYear()}`))
    }
    else {
        return startDates.slice(startDates.indexOf(`SPRING_${today.getFullYear()+1}`))
    }
}


export const countryListAlpha2 = {
    "Afghanistan" : "AF",
    "Albania" : "AL",
    "Algeria" : "DZ",
    "American Samoa" : "AS",
    "Andorra" : "AD",
    "Angola" : "AO",
    "Anguilla" : "AI",
    "Antarctica" : "AQ",
    "Antigua and Barbuda" : "AG",
    "Argentina" : "AR",
    "Armenia" : "AM",
    "Aruba" : "AW",
    "Australia" : "AU",
    "Austria" : "AT",
    "Azerbaijan" : "AZ",
    "Bahamas (the)" : "BS",
    "Bahrain" : "BH",
    "Bangladesh" : "BD",
    "Barbados" : "BB",
    "Belarus" : "BY",
    "Belgium" : "BE",
    "Belize" : "BZ",
    "Benin" : "BJ",
    "Bermuda" : "BM",
    "Bhutan" : "BT",
    "Bolivia (Plurinational State of)" : "BO",
    "Bonaire, Sint Eustatius and Saba" : "BQ",
    "Bosnia and Herzegovina" : "BA",
    "Botswana" : "BW",
    "Bouvet Island" : "BV",
    "Brazil" : "BR",
    "British Indian Ocean Territory (the)" : "IO",
    "Brunei Darussalam" : "BN",
    "Bulgaria" : "BG",
    "Burkina Faso" : "BF",
    "Burundi" : "BI",
    "Cabo Verde" : "CV",
    "Cambodia" : "KH",
    "Cameroon" : "CM",
    "Canada" : "CA",
    "Cayman Islands (the)" : "KY",
    "Central African Republic (the)" : "CF",
    "Chad" : "TD",
    "Chile" : "CL",
    "China" : "CN",
    "Christmas Island" : "CX",
    "Cocos (Keeling) Islands (the)" : "CC",
    "Colombia" : "CO",
    "Comoros (the)" : "KM",
    "Congo (the Democratic Republic of the)" : "CD",
    "Congo (the)" : "CG",
    "Cook Islands (the)" : "CK",
    "Costa Rica" : "CR",
    "Croatia" : "HR",
    "Cuba" : "CU",
    "Curaçao" : "CW",
    "Cyprus" : "CY",
    "Czechia" : "CZ",
    "Côte d'Ivoire" : "CI",
    "Denmark" : "DK",
    "Djibouti" : "DJ",
    "Dominica" : "DM",
    "Dominican Republic (the)" : "DO",
    "Ecuador" : "EC",
    "Egypt" : "EG",
    "El Salvador" : "SV",
    "Equatorial Guinea" : "GQ",
    "Eritrea" : "ER",
    "Estonia" : "EE",
    "Eswatini" : "SZ",
    "Ethiopia" : "ET",
    "Falkland Islands (the) [Malvinas]" : "FK",
    "Faroe Islands (the)" : "FO",
    "Fiji" : "FJ",
    "Finland" : "FI",
    "France" : "FR",
    "French Guiana" : "GF",
    "French Polynesia" : "PF",
    "French Southern Territories (the)" : "TF",
    "Gabon" : "GA",
    "Gambia (the)" : "GM",
    "Georgia" : "GE",
    "Germany" : "DE",
    "Ghana" : "GH",
    "Gibraltar" : "GI",
    "Greece" : "GR",
    "Greenland" : "GL",
    "Grenada" : "GD",
    "Guadeloupe" : "GP",
    "Guam" : "GU",
    "Guatemala" : "GT",
    "Guernsey" : "GG",
    "Guinea" : "GN",
    "Guinea-Bissau" : "GW",
    "Guyana" : "GY",
    "Haiti" : "HT",
    "Heard Island and McDonald Islands" : "HM",
    "Holy See (the)" : "VA",
    "Honduras" : "HN",
    "Hong Kong" : "HK",
    "Hungary" : "HU",
    "Iceland" : "IS",
    "India" : "IN",
    "Indonesia" : "ID",
    "Iran (Islamic Republic of)" : "IR",
    "Iraq" : "IQ",
    "Republic of Ireland" : "IE",
    "Isle of Man" : "IM",
    "Israel" : "IL",
    "Italy" : "IT",
    "Jamaica" : "JM",
    "Japan" : "JP",
    "Jersey" : "JE",
    "Jordan" : "JO",
    "Kazakhstan" : "KZ",
    "Kenya" : "KE",
    "Kiribati" : "KI",
    "Korea (the Democratic People's Republic of)" : "KP",
    "Korea (the Republic of)" : "KR",
    "Kuwait" : "KW",
    "Kyrgyzstan" : "KG",
    "Lao People's Democratic Republic (the)" : "LA",
    "Latvia" : "LV",
    "Lebanon" : "LB",
    "Lesotho" : "LS",
    "Liberia" : "LR",
    "Libya" : "LY",
    "Liechtenstein" : "LI",
    "Lithuania" : "LT",
    "Luxembourg" : "LU",
    "Macao" : "MO",
    "Madagascar" : "MG",
    "Malawi" : "MW",
    "Malaysia" : "MY",
    "Maldives" : "MV",
    "Mali" : "ML",
    "Malta" : "MT",
    "Marshall Islands (the)" : "MH",
    "Martinique" : "MQ",
    "Mauritania" : "MR",
    "Mauritius" : "MU",
    "Mayotte" : "YT",
    "Mexico" : "MX",
    "Micronesia (Federated States of)" : "FM",
    "Moldova (the Republic of)" : "MD",
    "Monaco" : "MC",
    "Mongolia" : "MN",
    "Montenegro" : "ME",
    "Montserrat" : "MS",
    "Morocco" : "MA",
    "Mozambique" : "MZ",
    "Myanmar" : "MM",
    "Namibia" : "NA",
    "Nauru" : "NR",
    "Nepal" : "NP",
    "Netherlands (the)" : "NL",
    "New Caledonia" : "NC",
    "New Zealand" : "NZ",
    "Nicaragua" : "NI",
    "Niger (the)" : "NE",
    "Nigeria" : "NG",
    "Niue" : "NU",
    "Norfolk Island" : "NF",
    "Northern Mariana Islands (the)" : "MP",
    "Norway" : "NO",
    "Oman" : "OM",
    "Pakistan" : "PK",
    "Palau" : "PW",
    "Palestine, State of" : "PS",
    "Panama" : "PA",
    "Papua New Guinea" : "PG",
    "Paraguay" : "PY",
    "Peru" : "PE",
    "Philippines (the)" : "PH",
    "Pitcairn" : "PN",
    "Poland" : "PL",
    "Portugal" : "PT",
    "Puerto Rico" : "PR",
    "Qatar" : "QA",
    "Republic of North Macedonia" : "MK",
    "Romania" : "RO",
    "Russian Federation (the)" : "RU",
    "Rwanda" : "RW",
    "Réunion" : "RE",
    "Saint Barthélemy" : "BL",
    "Saint Helena, Ascension and Tristan da Cunha" : "SH",
    "Saint Kitts and Nevis" : "KN",
    "Saint Lucia" : "LC",
    "Saint Martin (French part)" : "MF",
    "Saint Pierre and Miquelon" : "PM",
    "Saint Vincent and the Grenadines" : "VC",
    "Samoa" : "WS",
    "San Marino" : "SM",
    "Sao Tome and Principe" : "ST",
    "Saudi Arabia" : "SA",
    "Senegal" : "SN",
    "Serbia" : "RS",
    "Seychelles" : "SC",
    "Sierra Leone" : "SL",
    "Singapore" : "SG",
    "Sint Maarten (Dutch part)" : "SX",
    "Slovakia" : "SK",
    "Slovenia" : "SI",
    "Solomon Islands" : "SB",
    "Somalia" : "SO",
    "South Africa" : "ZA",
    "South Georgia and the South Sandwich Islands" : "GS",
    "South Sudan" : "SS",
    "Spain" : "ES",
    "Sri Lanka" : "LK",
    "Sudan (the)" : "SD",
    "Suriname" : "SR",
    "Svalbard and Jan Mayen" : "SJ",
    "Sweden" : "SE",
    "Switzerland" : "CH",
    "Syrian Arab Republic" : "SY",
    "Taiwan" : "TW",
    "Tajikistan" : "TJ",
    "Tanzania, United Republic of" : "TZ",
    "Thailand" : "TH",
    "Timor-Leste" : "TL",
    "Togo" : "TG",
    "Tokelau" : "TK",
    "Tonga" : "TO",
    "Trinidad and Tobago" : "TT",
    "Tunisia" : "TN",
    "Turkey" : "TR",
    "Turkmenistan" : "TM",
    "Turks and Caicos Islands (the)" : "TC",
    "Tuvalu" : "TV",
    "Uganda" : "UG",
    "Ukraine" : "UA",
    "United Arab Emirates (the)" : "AE",
    "United Kingdom" : "GB",
    "United States Minor Outlying Islands (the)" : "UM",
    "United States of America (the)" : "US",
    "Uruguay" : "UY",
    "Uzbekistan" : "UZ",
    "Vanuatu" : "VU",
    "Venezuela" : "VE",
    "Viet Nam" : "VN",
    "Virgin Islands (British)" : "VG",
    "Virgin Islands (U.S.)" : "VI",
    "Wallis and Futuna" : "WF",
    "Western Sahara" : "EH",
    "Yemen" : "YE",
    "Zambia" : "ZM",
    "Zimbabwe" : "ZW",
    "Åland Islands" : "AX",
};