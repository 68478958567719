// Chakra imports
import {
	Box,
	Button,
	Flex,
	FormControl,
	HStack,
	Input,
	InputGroup,
	InputRightElement,
	IconButton,
	Link,
	Switch,
	Text,
	useColorModeValue,
	Alert,
	AlertIcon,
	AlertTitle,
	AlertDescription,
	FormErrorMessage,
} from "@chakra-ui/react";
import {ViewIcon, ViewOffIcon} from "@chakra-ui/icons";

// Assets
import BgSignUp from "assets/img/BgSignUp.png";
import React, {useContext, useState, useEffect} from "react";
import { DataContext } from '../../contexts/dataContext';
import { useNavigate } from 'react-router-dom'
import validator from "validator";

const initialFormState = {
    username: '',
    code: '',
    password: '',
	confirm_password: '',
    authCode: '',
    formType: 'ResetPassword',
}

function ResetPassword() {

	let navigate = useNavigate();

	const titleColor = useColorModeValue("royalblue.700");
	const textColor = useColorModeValue("gray.400", "white");
	const bgColor = useColorModeValue("white", "gray.700");
	const bgIcons = useColorModeValue("teal.200", "rgba(255, 255, 255, 0.5)");

	const dataContext = useContext(DataContext)
    const { authResetPassword, user, error, updateError} = dataContext
	const [formState, updateFormState] = useState(initialFormState)
	// State with show/hide password
	const [showPassword, setShowPassword] = useState({
		new_password: false,
		confirm_password: false,
	})

	if (user !== 'notLoggedIn') {
        // If the user is logged in, redirect to the app
        navigate('/admin', { replace: true })
    }

	useEffect(() => {
		updateError('')
	}, [])

	const onChange = (e) => {
        e.persist()
        updateFormState(() => ({ ...formState, [e.target.name]: e.target.value }))
    }

	const handleResetPassword = (e) => {
		e.preventDefault();

	
		authResetPassword(formState.username,formState.code,formState.password)
		.then(() => {
			navigate('/auth/signin', { state: {succesMessage: "Your password has been successfully reset."} })
		})
    

	}

	return (
		<Flex
			direction='column'
			alignSelf='center'
			justifySelf='center'
			overflow='hidden'
		>

			<Box
				position='absolute'
				minH={{ base: "70vh", md: "50vh" }}
				w={{ md: "calc(100vw - 50px)" }}
				borderRadius={{ md: "15px" }}
				left='0'
				right='0'
				bgRepeat='no-repeat'
				overflow='hidden'
				zIndex='-1'
				top='0'
				bgImage={BgSignUp}
				bgSize='cover'
				mx={{ md: "auto" }}
				mt={{ md: "14px" }} />


			<Flex
				direction='column'
				textAlign='center'
				justifyContent='center'
				align='center'
				mt='3rem'
			>

				<Text fontSize='4xl' color='white' fontWeight='bold'>
					Reset Password
				</Text>

				<Text
					fontSize='md'
					color='white'
					fontWeight='normal'
					mt='10px'
					w={{ base: "90%", sm: "60%", lg: "40%", xl: "30%" }}>
					Enter your email address, the verification code received and your new password.
				</Text>

			</Flex>

			<Flex alignItems='center' justifyContent='center' mb='20px' mt='10px'>

				<Flex
					direction='column'
					w='445px'
					background='transparent'
					borderRadius='15px'
					p='40px'
					mx={{ base: "100px" }}
					bg={bgColor}
					boxShadow='0 20px 27px 0 rgb(0 0 0 / 5%)'
				>
					
					{error && (
					<Alert status='error' mb="15px" borderRadius="10px">
						<AlertIcon />
						<Box flex='1'>
							<AlertTitle>Sorry!</AlertTitle>
							<AlertDescription display='block'>
								{error}
							</AlertDescription>
						</Box>
					</Alert>
					)}
					<FormControl isInvalid={formState.username!== '' && !validator.isEmail(formState.username)}>
						<Text ms='4px' fontSize='sm' fontWeight='normal'>
							Email
						</Text>
						<Input
							fontSize='sm'
							variant='flushed'
							type='email'
							placeholder='Your email address'
							size='lg'
							value={formState.username || ''}
							onChange={onChange}
							name='username'
						/>
						{formState.username!== '' && !validator.isEmail(formState.username) && (<FormErrorMessage>Please enter a valid email address</FormErrorMessage>)}
					</FormControl>

					<FormControl isInvalid={formState.code!== '' && formState.code.length < 6}>
						<Text mt='10px' fontSize='sm' fontWeight='normal'>
							Code
						</Text>
						<Input
							fontSize='sm'
							variant='flushed'
							type='text'
							placeholder='Received code'
							size='lg'
							value={formState.code || ''}
							onChange={onChange}
							name='code'
						/>
						{formState.code!== '' && formState.code.length < 6 && (<FormErrorMessage>Please enter the 6 digit code</FormErrorMessage>)}
					</FormControl>

					<FormControl isInvalid={formState.password!== '' && formState.password.length < 8}>
						<Text mt='10px' fontSize='sm' fontWeight='normal'>
							Password
						</Text>
						<InputGroup>
							<Input
								fontSize='sm'
								ms='4px'
								variant='flushed'
								type={showPassword.new_password ? 'text' : 'password'}
								placeholder='Your new password'
								size='lg'
								value={formState.password || ''}
								onChange={onChange}
								name='password'
							/>
							<InputRightElement>
								<IconButton
								colorScheme='gray'
								aria-label='Search database'
								icon={ showPassword.new_password ? <ViewOffIcon /> : <ViewIcon /> }
								onClick={() => setShowPassword({...showPassword, new_password: !showPassword.new_password})}
								/>
							</InputRightElement>
						</InputGroup>
						{formState.password!== '' && formState.password.length < 8 && (<FormErrorMessage>Password must be at least 8 characters long</FormErrorMessage>)}
					</FormControl>


					<FormControl isInvalid={formState.confirm_password!== '' && formState.confirm_password !== formState.password }>
						<Text mt='10px' fontSize='sm' fontWeight='normal'>
							Confirm Password
						</Text>
						<InputGroup>
							<Input
								fontSize='sm'
								variant='flushed'
								type={showPassword.confirm_password ? 'text' : 'password'}
								placeholder='Your new password'
								size='lg'
								value={formState.confirm_password || ''}
								onChange={onChange}
								name='confirm_password'
							/>
							<InputRightElement>
								<IconButton
								colorScheme='gray'
								aria-label='Search database'
								icon={ showPassword.confirm_password ? <ViewOffIcon /> : <ViewIcon /> }
								onClick={() => setShowPassword({...showPassword, confirm_password: !showPassword.confirm_password})}
								/>
							</InputRightElement>
						</InputGroup>
						{formState.confirm_password!== '' && formState.confirm_password !== formState.password && (<FormErrorMessage>Passwords do not match</FormErrorMessage>)}
					</FormControl>



					<Button
						type='submit'
						bg='royalblue.700'
						fontSize='14px'
						color='white'
						mt='23px'
						fontWeight='bold'
						w='100%'
						h='45'
						_hover={{
							bg: "blue.200",
						}}
						_active={{
							bg: "blue.400",
						}}
						isDisabled={formState.username === '' || formState.password === '' || formState.confirm_password === '' || formState.code === '' || formState.password !== formState.confirm_password || formState.password.length < 8 || formState.code.length < 6 || !validator.isEmail(formState.username)}
						onClick={handleResetPassword}
					>
						RESET PASSWORD
					</Button>

					<Flex
						flexDirection='column'
						justifyContent='center'
						alignItems='center'
						maxW='100%'
						mt='15px'>
						<Text color={textColor} fontWeight='medium'>
							Have an account?
							<Link
								color={titleColor}
								as='span'
								ms='5px'
								onClick={()=>{navigate('/auth/signin')}}
							>
								Log in
							</Link>
						</Text>
					</Flex>
					
				</Flex>
			</Flex>
		</Flex>
	);
}

export default ResetPassword;
