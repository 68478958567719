const createTeam = /* GraphQL */ `
  mutation CreateTeam(
    $input: CreateTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    createTeam(input: $input, condition: $condition) {
      id
      name
      sport
      genre
      suscription_name
      suscription_recurrency
      suscription_status
      suscription_premium_start_date
      suscription_premium_end_date
      team_logo
      conference
      division
      college_category
      url_domain
      had_trial
      collegeID
      college_category
      createdAt
      updatedAt
    }
  }
`;
const updateTeam = /* GraphQL */ `
  mutation UpdateTeam(
    $input: UpdateTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    updateTeam(input: $input, condition: $condition) {
      id
      name
      sport
      genre
      suscription_name
      suscription_recurrency
      suscription_status
      suscription_premium_start_date
      suscription_premium_end_date
      team_logo
      conference
      division
      collegeID
      college_category
      url_domain
      had_trial
    }
  }
`;
const deleteTeam = /* GraphQL */ `
  mutation DeleteTeam(
    $input: DeleteTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    deleteTeam(input: $input, condition: $condition) {
      id
      name
      sport
      genre
      suscription_name
      suscription_recurrency
      suscription_status
      team_logo
      conference
      division
      collegeID
      college_category
      url_domain
      had_trial
      createdAt
      updatedAt
    }
  }
`;

export { createTeam, updateTeam, deleteTeam };