// React imports
import React, { useContext, useState,useEffect, useRef } from 'react'
import { useNavigate } from "react-router-dom";
// Context imports
import { DataContext } from '../../../../contexts/dataContext'
// Chakra imports
import {
	Table,
	Tbody,
	Text,
	Th,
	Thead,
	Tr,
	useColorModeValue,
	Flex,
	useDisclosure,
	Checkbox,
	Alert,
	AlertIcon,
	CloseButton,
	Select,
	Icon,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { Pagination } from "components/Pagination/Pagination";
import { SearchBar } from "components/SearchBar/SearchBar";
import TeamsTableRow from "./TeamsTableRow";
import { DeleteButton } from "../../../../components/Buttons/DeleteButton";
import { CreateButton } from "../../../../components/Buttons/CreateButton";
import { ConfirmModal } from "../../../../components/ConfirmModal/ConfirmModal";
// Auxiliary functions
import { getSearchedData } from "helpers/getSearchedData";
import { changeUserGroupToAgencyId } from "../../../../helpers";
import { deleteTeamService } from "../../../../services/teamServices";
import { BsArrowDown, BsArrowUp } from 'react-icons/bs';

const Teams = ({ title, captions, data,showMoreInfo }) => {
	// Chakra color mode
	const textColor = useColorModeValue("gray.700", "white");
	
	// Take the context data needed in this component    
	const dataContext = useContext(DataContext)
	const { pagination,updatePagination, user, teams, setTeams, colleges } = dataContext
	// Cognito user group
	const userGroup = changeUserGroupToAgencyId(user?.signInUserSession.idToken.payload["cognito:groups"][0])
	// State with the searched text
	const [searchState, updateSearchState] = useState({searchText: ''})
	// State that handles the selection of items
	const [ selectItem, setSelectItem ] = useState([])
	// State that controls if the user has permission to do an action
	const [ noPermission, setNoPermission ] = useState(false)
	// State with action feedback
	const [ actionStatus, setActionStatus ] = useState({
        done: false,
        status: '',
        message: ''
    })

	const uniqueValuesCategories = [... new Set(teams.filter(it=>it.college_category).map(team => {return team.college_category}))].sort((a,b) => {return a.toLowerCase().localeCompare(b.toLowerCase())})
	const [ filters, setFilters ] = useState({
		category:'0',
		categoryData: [{value:'0',label:'ALL'}, ...uniqueValuesCategories.map(it => {return {value:it,label:it}})],
		suscription: '0',
		suscriptionData: [{value:'0',label:'ALL'},{value:'PREMIUM_PASS',label:'PREMIUM'},{value:'FREE_PERIOD',label:'FREEMIUM'}, ,{value:'FREE_TRIAL',label:'FREE TRIAL'}]
	})
	// State that control if sort is ascending or descending
	const [ sortAscending, setSortAscending ] = useState(
		{
			'Total actions (last 30 days)':true,
		}
	)
	const prevSortStatus = useRef();
	useEffect(() => {
		//assign the ref's current value to the sort Hook
		prevSortStatus.current = sortAscending;
	}, [sortAscending]);

	const filterData = (data) => {
		let newData = data
		if (filters.category === '0' || filters.suscription === '0') {newData = data}
		if (filters.category !== '0') {newData = newData.filter(item => item.college_category === filters.category)}

		if (filters.suscription > '0') {
			if(filters.suscription === 'FREE_TRIAL'){
				newData = newData.filter(item => item.suscription_name === 'SEASON_PASS' && item.had_trial)
			}else{
				newData = newData.filter(item => item.suscription_name === filters.suscription)
			}
		}
		
		if(prevSortStatus?.current){
			if(prevSortStatus?.current['Total actions (last 30 days)'] !== sortAscending['Total actions (last 30 days)']){newData = newData.sort((a,b) => {
				if(sortAscending['Total actions (last 30 days)']){return a.actions - b.actions}
				else{return b.actions - a.actions}
			})}
		}
		return newData
	}

	// Hook for the confirm modal
	const { isOpen, onOpen, onClose } = useDisclosure()
	const cancelRef = useRef()
	// Navigation hook 
	let navigate = useNavigate()

	useEffect(() => {
		updatePagination({
			total:getSearchedData(data,searchState.searchText).length,
			page: 1,
			rowsPerPage: 10,
			lower: 1})
		setSelectItem([])
	}, [])

	// Function that handles the selection of all items
	const handleSelectAll = (e) => {
		e.target.checked ? setSelectItem(data.map(item => item.id)) : setSelectItem([])
	}

	// Function that handles the delete action
	const handleDelete = () => {
		// Only if the user is admin
		userGroup === "superadmins" 
		? onOpen()
		: setNoPermission(true)
	}

	// Function that handles the confirm delete action
	const handleConfirmDelete = () => {
		onClose()
		let hasFailed = false
		selectItem.forEach((item) => {
			deleteTeamService(item)
			.catch(error => { hasFailed = true })
		})
		setTeams(teams.filter(college => !selectItem.includes(college.id)))
		setSelectItem([])
		!hasFailed ? setActionStatus({
			done: true,
			status: 'success',
			message: 'Team(s) deleted successfully'
		}): setActionStatus({
			done: true,
			status: 'error',
			message: 'An error has ocurred while deleting the team(s)'
		})
		navigate(`/admin/teams/`)
	}

	return (
		<>
			<ConfirmModal
				title={selectItem.length === 1 ? "Delete Team" : "Delete Teams"}
				description="Are you sure? You can't undo this action afterwards."
				button1="Cancel"
				button2="Delete"
				isOpen={isOpen}
				onClose={onClose}
				cancelRef={cancelRef}
				handleDelete={handleConfirmDelete}
			/>
			{
				showMoreInfo && (
					<Flex justifyContent='right' m="0px 10px 10px 0">
						
						<CreateButton handleCreate={() => { userGroup === "superadmins" ? navigate(`/admin/teams/create`) : setNoPermission(true) }}/>
						{
							selectItem.length > 0 
							? (<DeleteButton handleDelete={handleDelete} />)
							: null
						}
					</Flex>
				)
			}
			{
				noPermission
				? (
					<Alert status='error' borderRadius="10px" mb="20px">
						<AlertIcon />
						You don't have enough permissions to perform this action on teams.
						<CloseButton position='absolute' right='8px' top='8px' onClick={()=>(setNoPermission(false))} />
					</Alert>
				)
				: null
			}
			{
				actionStatus.done && (
					<Alert status={actionStatus.status} borderRadius="10px" mb="20px" >
						<AlertIcon />
						{actionStatus.message}
						<CloseButton position='absolute' right='8px' top='8px' onClick={()=>(setActionStatus({...actionStatus,done:false}))} />
					</Alert>
				)
			}
			<Card overflowX={{ sm: "scroll", xl: "hidden" }}>
				<CardHeader p='6px 0px 22px 0px'>
					<Flex direction='row' w="100%">
						<Flex justifyContent='left' w="40%">
							<Text fontSize='xl' color={textColor} fontWeight='bold'>
								{title} ({searchState.searchText!== '' ? `Filtered result ${filterData(getSearchedData(data,searchState.searchText)).length}` : filterData(data).length} - {selectItem.length} selected)
							</Text>
						</Flex>
						<Flex justifyContent='right' w="60%">
							<SearchBar text={searchState} update={updateSearchState}/>
						</Flex>
					</Flex>
				</CardHeader>
				<CardBody>
					<Table variant='simple' color={textColor}>
						<Thead>
							<Tr my='.8rem' pl='0px' color='gray.400'>
								{
									showMoreInfo && (
										<Th color='gray.400' ><Checkbox colorScheme='blue' onChange={(e)=>(handleSelectAll(e))} /></Th>
									)
								}
								{captions.map((caption, idx) => {
									return (
										caption === 'Category' ? (
											<Th color='gray.400'>
												{caption}<br />
												<Select variant='unstyled' size="xs" value={filters.category} onChange={(e)=>setFilters({...filters, category:e.target.value})}>
													{filters.categoryData.map((opt,index) => {return (<option key={index} value={opt.value} color="royalblue">{opt.label}</option>)})}
												</Select>
											</Th>
										):(
											caption === 'Suscription' ? (
												<Th color='gray.400'>
													{caption}<br />
													<Select variant='unstyled' size="xs" value={filters.suscription} onChange={(e)=>setFilters({...filters, suscription:e.target.value})}>
														{filters.suscriptionData.map((opt,index) => {return (<option key={index} value={opt.value} color="royalblue">{opt.label}</option>)})}
													</Select>
												</Th>
											) : (
												caption === 'Total actions (last 30 days)' ? (
													<Th key={idx} color='gray.400' cursor="pointer" onClick={()=>setSortAscending({...sortAscending,[caption]:!sortAscending[caption]})}>
														{caption} {sortAscending[caption] ? <Icon as={BsArrowUp} color="gray.400" /> : <Icon as={BsArrowDown} color="gray.400" />}
													</Th>
												) : (
													<Th color='gray.400' key={idx} ps={idx === 0 ? "0px" : null}>
														{caption}
													</Th>
												)
											)
										)
									);
								})}
							</Tr>
						</Thead>
						<Tbody>
							{filterData(getSearchedData(data,searchState.searchText)).slice(pagination.lower-1,pagination.lower+pagination.rowsPerPage-1).map((row) => {
								return (
										showMoreInfo
										? (
											<TeamsTableRow
												key={`${row.id}`}
												id={row.id}
												team_logo={row.team_logo}
												name={row.name}
												college={colleges.find(college => college.id === row.collegeID)}
												coaches={row.coaches?.items}
												actions={row.actions}
												suscription_name={row.suscription_name}
												suscription_premium_end_date={row.suscription_premium_end_date}
												sport={row.sport}
												genre={row.genre}
												category={row.college_category}
												selectItem={selectItem}
												setSelectItem={setSelectItem}
											/>
										)
										: (
											<TeamsTableRow
												key={`${row.id}`}
												id={row.id}
												team_logo={row.team_logo}
												name={row.name}
												suscription_name={row.suscription_name}
												sport={row.sport}
												genre={row.genre}
											/>
										)
								);
							})}
						</Tbody>
					</Table>
				</CardBody>
				<Pagination totalData={filterData(getSearchedData(data,searchState.searchText)).length} rowsPerPageValues={[10,20,30,40,50]} />
			</Card>

		</>
	);
};

export default Teams;
