// React imports
import React, { useContext, useEffect } from 'react'
import { useParams, Navigate } from 'react-router-dom';
// Context imports
import { DataContext } from "../../../../contexts/dataContext";
// Chakra imports
import { Flex, SimpleGrid, Text, useColorModeValue } from "@chakra-ui/react";
// Component and assets
import ProfileBgImage from "assets/img/ProfileBackground.png";
import Header from './Header'
import CoachesTables from "../../Coaches/components/CoachesTables";
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import AthletesTables from 'views/Dashboard/Athletes/components/AthletesTables';


export const ShowTeam = () => {
	// Chakra color mode
	const bgProfile = useColorModeValue(
		"hsla(0,0%,100%,.8)",
		"linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
	);

	// Get the id of the team from the url					
	const { teamId } = useParams();

  	// Take the context data needed in this component
    const dataContext = useContext(DataContext)
    const { teams, colleges, auxCoaches, athletes, coaches } = dataContext
    const team = teams.find(team => team.id === teamId)
	const auxDataTeam = auxCoaches.find(it => team.name.includes(it.school) && it.sport.includes(team.genre))
	const athleteInThisSchool = athletes.filter(it => it.current_status === 'COMMITTED' && it.destination_college && team.name.includes(it.destination_college))

	useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

	return (
		<Flex direction='column' pt={{ base: "120px", md: "75px" }}>
			{/* Check if the context is loaded */}
			{(teams?.length+colleges?.length) > 0 && (
				// Check if the team is found
				team ? (
					<>
						<Header
							id={team.id}
							backgroundHeader={ProfileBgImage}
							backgroundProfile={bgProfile}
							name={team.name}
							sport={team.sport}
							genre={team.genre}
							suscription_name={team.suscription_name}
							suscription_recurrency={team.suscription_premium_start_date}
							suscription_status={team.suscription_premium_end_date}
							had_trial={team.had_trial}
							team_logo={team.team_logo}
							college={colleges.find(college => college.id === team.collegeID)}
							category={team.college_category}
						/>

						{
							auxDataTeam && (
								<Card overflowX={{ sm: "scroll", xl: "hidden" }} mt={{ base: "100px", md: "60px" }}>
									<CardHeader p='6px 0px 15px 0px'>
										<Flex direction='row' w="100%">
											<Text fontSize='xl' color={useColorModeValue("gray.700", "white")} fontWeight='bold'>
												Aditional information
											</Text>
										</Flex>
									</CardHeader>
									<CardBody>

										<SimpleGrid columns={{ sm: 1, md: 2, xl: 2 }} spacing='24px' w="100%">
											<Flex direction="column">
												<Text fontSize="md" color="gray.400" fontWeight="normal">
													Conference
												</Text>
												<Text fontSize="md" color="gray.700" fontWeight="normal">
													{auxDataTeam.conference!=='<empty>' ? auxDataTeam.conference : 'Not specified'}
												</Text>
											</Flex>
											<Flex direction="column">
												<Text fontSize="md" color="gray.400" fontWeight="normal">
													Region
												</Text>
												<Text fontSize="md" color="gray.700" fontWeight="normal">
													{auxDataTeam.region !== '<empty>' ? auxDataTeam.region : 'Not specified'}
												</Text>
											</Flex>
											<Flex direction="column">
												<Text fontSize="md" color="gray.400" fontWeight="normal">
													Division
												</Text>
												<Text fontSize="md" color="gray.700" fontWeight="normal">
													{auxDataTeam.division!=='<empty>' ? auxDataTeam.division : 'Not specified'}
												</Text>
											</Flex>
											<Flex direction="column">
												<Text fontSize="md" color="gray.400" fontWeight="normal">
													Landing page
												</Text>
												<Text fontSize="md" color="gray.700" fontWeight="normal">
													{auxDataTeam.landing_pages !== '<empty>' ? <a href={auxDataTeam.landing_pages} target="_blank">{auxDataTeam.landing_pages}</a> : 'Not specified'}
												</Text>
											</Flex>
											<Flex direction="column">
												<Text fontSize="md" color="gray.400" fontWeight="normal">
													Twitter
												</Text>
												<Text fontSize="md" color="gray.700" fontWeight="normal">
													{auxDataTeam.team_twitter !== '<empty>' ? <a href={auxDataTeam.team_twitter} target="_blank">{auxDataTeam.team_twitter}</a> : 'Not specified'}
												</Text>
											</Flex>
											<Flex direction="column">
												<Text fontSize="md" color="gray.400" fontWeight="normal">
													Facebook
												</Text>
												<Text fontSize="md" color="gray.700" fontWeight="normal">
													{auxDataTeam.team_facebook !== '<empty>' ? <a href={auxDataTeam.team_facebook} target="_blank">{auxDataTeam.team_facebook}</a> : 'Not specified'}
												</Text>
											</Flex>
											<Flex direction="column">
												<Text fontSize="md" color="gray.400" fontWeight="normal">
													Instagram
												</Text>
												<Text fontSize="md" color="gray.700" fontWeight="normal">
													{auxDataTeam.team_instagram !== '<empty>' ? <a href={auxDataTeam.team_instagram} target="_blank">{auxDataTeam.team_instagram}</a> : 'Not specified'}
												</Text>
											</Flex>
											<Flex direction="column">
												<Text fontSize="md" color="gray.400" fontWeight="normal">
													Questionnaire
												</Text>
												<Text fontSize="md" color="gray.700" fontWeight="normal">
													{auxDataTeam.questionnaire !== '<empty>' ? <a href={auxDataTeam.questionnaire} target="_blank">{auxDataTeam.questionnaire}</a> : 'Not specified'}
												</Text>
											</Flex>
											
										</SimpleGrid>
										
									</CardBody>
								</Card>
							)
						}

						{
							team.coaches?.items?.length > 0 &&
							<CoachesTables coaches={coaches.filter(c => c.teamID === team.id)} showMoreInfo={false} withMargin={auxDataTeam ? true : false}/>
							// I need to filter coaches array because team.coaches.items don't have actions fields
						}
						{
							athleteInThisSchool?.length > 0 && 
							<AthletesTables athletes={athleteInThisSchool} showMoreInfo={true} withMargin={true} noCreateButton={true}/>
						}
					</>

				) :(
					<Navigate to={'/admin/teams'} replace />
				)
			)}
		
		</Flex>
		
	);
}
