// React imports
import React, { useContext, useEffect } from 'react'
// Chakra imports
import { Flex } from "@chakra-ui/react";
// Components
import Athletes from './Athletes'
import AthletesWithTransfer from './AthletesWithTransfer';

function AthletesTables({athletes, showMoreInfo,  withTransfer=false, withMargin, noCreateButton=false}) {

	let myCaptions = []
	let tableTitle = ""
	
	showMoreInfo
	// If we are showing the favorites athletes, we do not need to show more info
	? (
		myCaptions = ["Profile pic", "Name","Agency","Agent", "Nationality", "Sport", "Position", "Status", "Visibility"],
		tableTitle = "Athletes"
	)
	: (
		myCaptions = ["Profile pic", "Name", "Agency", "Agent"],
		tableTitle = "Favorite Athletes"
	)

	withTransfer ? (myCaptions = ["Profile pic", "Name","Status", "Transfer"],tableTitle = "Associated PSAs") : null

	useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

	return (
		<Flex direction='column' pt={{ base: withMargin ? "30px" : "120px", md: withMargin ? "20px" : "75px" }}>
			{
				withTransfer ? (
					<AthletesWithTransfer
						title={tableTitle}
						captions={myCaptions}
						data={athletes}
					/>
					) : (
						<Athletes
							title={tableTitle}
							captions={myCaptions}
							data={athletes}
							showMoreInfo={showMoreInfo}
							noCreateButton={noCreateButton}
						/>
					)
			}
			
		</Flex>
	);
}

export default AthletesTables;
