// React imports
import React, { useContext, useEffect } from 'react'
import { useParams, Navigate } from 'react-router-dom';
// Context imports
import { DataContext } from "../../../../contexts/dataContext";
// Chackra imports
import { Flex, useColorModeValue } from "@chakra-ui/react";
// Component and assets
import Header from './Header'
import ProfileBgImage from "assets/img/ProfileBackground.png";
import AthletesTables from '../../Athletes/components/AthletesTables';



export const ShowAgent = () => {

	const bgProfile = useColorModeValue(
		"hsla(0,0%,100%,.8)",
		"linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
	);

	// Get the id of the agency from the url 
	const {agentId} = useParams();

  	// Take the context data needed in this component
    const dataContext = useContext(DataContext)
    const { agencies, agents, athletes } = dataContext
    const agent = agents?.find(agent => agent.id === agentId)
	const agency = agencies?.find(agency => agency.id === agent?.agencyID)
	const agentPSAs = athletes?.filter(athlete => athlete.agentID === agentId)

	useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

	return (
		<Flex direction='column' pt={{ base: "120px", md: "75px" }}>
			{/* Check if the context is loaded */}
			{ (agencies.length + agencies.length + agentPSAs.length) > 0 && (
				// Check if the agency is found
				agent ? (
					<>
					<Header
						id={agent.id}
						backgroundHeader={ProfileBgImage}
						backgroundProfile={bgProfile}
						profilePic={agent.profile_pic}
						name={agent.name}
						email={agent.contact_email}
						phone={agent.contact_phone}
						gender={agent.gender}
						agency={agency && agency}
						agent_role={agent.agent_role}
					/>

					{agentPSAs && (
						<AthletesTables athletes={agentPSAs} showMoreInfo={false} withTransfer={true}/>	
					)}
					</>
					
				):(
					<Navigate to={'/admin/agents'} replace />
				)
			)}		  	
		</Flex>
	);
}
