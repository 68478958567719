import React from 'react'
import { Button, Icon } from '@chakra-ui/react'
import { MdDelete } from "react-icons/md";

export const DeleteButton = ({handleDelete}) => {
    return (
        <>
            <Button
                colorScheme='red'
                variant='ghost'
                leftIcon={<Icon as={MdDelete} />}
                size='sm'
                onClick={handleDelete}
                >
                DELETE
            </Button>
        </>
    )
}
