import React from 'react'			
import { 
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Flex,
    ListItem,
    Text,
    UnorderedList,
} from '@chakra-ui/react'
import { CategoryInfoAccordion } from '../components/CategoryInfoAccordion'

export const Categorization = ({athlete, categoryExampleTeams, athletesWithSameCategory}) => {
    return (
        <>
            <Flex direction="column" w="50%">
                <Text fontSize={{ sm: "sm", lg: "md" }} color='gray.400' mb="3px">
                    Athlete category
                </Text>
                
                {athlete.category ? (
                    <Text fontSize={{ sm: "sm", lg: "md" }} color='gray.700' fontWeight={"bold"} mb="20px">
                        {athlete.category}
                    </Text>
                ) : (
                    <Text fontSize='xs' color='gray.700' fontWeight={"italic"} mb="20px">
                        No category provided
                    </Text>
                )}
                
            </Flex>
            <CategoryInfoAccordion gender={athlete.gender} athletesWithSameCategory={athletesWithSameCategory} categoryExampleTeams={categoryExampleTeams} />

        
        </>
    )
}
