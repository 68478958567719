import React, {useEffect} from 'react'
import { Routes, Route, Navigate } from 'react-router-dom';
import AgenciesTables from './components/AgenciesTables';
import {ShowAgency} from './components/ShowAgency';
import {EditAgency} from './components/EditAgency';
import {CreateAgency} from './components/CreateAgency';

export const AgenciesRoutes = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <Routes>
                <Route path="/" element={<AgenciesTables />} />
                <Route path="/show/:agencyId" element={<ShowAgency />} />
                <Route path="/edit/:agencyId" element={<EditAgency />} />
                <Route path="/create" element={<CreateAgency />} />
                <Route path="*" element={<Navigate to="/admin/agencies" replace />} />
            </Routes>
        </>
    )
}
