import React, {useState} from 'react'
// Chackra Components
import { 
    Box,
    Flex, 
    Text, 
    List,
    Image,
    ListItem,
    ListIcon,	
    Alert,
	AlertIcon,
	AlertTitle,
	AlertDescription,
} from "@chakra-ui/react";
import {BsFileEarmarkImageFill} from 'react-icons/bs'
import {IoMdAlert} from 'react-icons/io'
// Drag and drop component
import {useDropzone} from 'react-dropzone'

export const ImageDropzone = ({values,updateValues, propChanged}) => {

    const [files, setFiles] = useState([]);
    // const [percentage, setPercentage] = useState(0);
	const {acceptedFiles,fileRejections, getRootProps, getInputProps} = useDropzone({
        multiple:false,
        maxSize: 100000000, 
        accept: 'image/*',
        onDrop: acceptedFiles => {
            setFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
            updateValues({...values, [propChanged]: acceptedFiles[0]})
        }
    });

	const thumb = files.map(file => (
        <ListItem key={file.path}>
            <Flex alignItems="center">
                <Image src={file.preview} h="50px" mr="20px"/>
                {file.path}
            </Flex>
		</ListItem>
	));
    
    const rejectedFiles = fileRejections.map(({file}) => (
		<ListItem key={file.path}>
            <ListIcon as={IoMdAlert} color='red.500' />
		    {file.path}
		</ListItem>
	));


    return (
        <>

            {
                rejectedFiles.length>0
                ?(

                    <Alert status='error' mb="15px" borderRadius="10px">
                        <Box flex='1'>
                            <AlertTitle>The following files are not allowed:</AlertTitle>
                            <AlertDescription display='block'>
                                <List spacing={2}>
                                    {rejectedFiles}
                                </List>
                            </AlertDescription>
                        </Box>
                    </Alert>
                )
                :null
            }
            <Flex 
                borderRadius="sm" 
                border="3px dashed #bdc3c7 "
                boxShadow="sm" 
                bg="#f2f3f4" 
                justifyContent={"center"} 
                alignItems="center" 
                h="70px" 
                cursor="pointer"
                {...getRootProps({className: 'dropzone'})}
                m="10px"
                direction="column"
            >
                <input {...getInputProps()} />
                <Text fontSize='sm' fontWeight='semibold'><i>Drag file</i> or <i>Click here</i> to browse from your computer</Text>
                <Text fontSize='xs'>Max file size: 100MB</Text>
            </Flex>
            {
                files.length > 0 
                ?(
                    <>
                        <Text fontSize={{ sm: "sm", lg: "sm" }} mb="5px" fontWeight='semibold' textAlign="justify">
                            Uploaded files
                        </Text>
                        <List spacing={4}>
                            {thumb}
                        </List>
                    </>
                )
                : null
            }
        
        </>
    )
}
