// React imports
import React, { useContext, useState,useEffect, useRef } from 'react'
import { useNavigate } from "react-router-dom";
// Context imports
import { DataContext } from '../../../../contexts/dataContext'
// Chakra imports
import {
	Table,
	Tbody,
	Text,
	Th,
	Thead,
	Tr,
	useColorModeValue,
	Flex,
	useDisclosure,
	Checkbox,
	Alert,
	AlertIcon,
	CloseButton,
	Icon,
} from "@chakra-ui/react";

import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    Button,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { Pagination } from "components/Pagination/Pagination";
import { SearchBar } from "components/SearchBar/SearchBar";
import { DeleteButton } from "../../../../components/Buttons/DeleteButton";
import { CreateButton } from "../../../../components/Buttons/CreateButton";
import { ConfirmModal } from "../../../../components/ConfirmModal/ConfirmModal";
import ShowcaseTableRow from './ShowcaseTableRow';
// Auxiliary functions
import { getSearchedData } from "helpers/getSearchedData";
import { changeUserGroupToAgencyId } from "../../../../helpers";
import { deleteShowcaseAthleteRelationService } from 'services/showcaseServices';
// import { deleteShowcaseCoachRelationService } from 'services/showcaseServices';
import { deleteShowcaseService } from 'services/showcaseServices';
import { BsArrowDown, BsArrowUp } from 'react-icons/bs';

const ShowcasesTables = () => {

	// Chakra color mode
	const textColor = useColorModeValue("gray.700", "white");
	
	// Take the context data needed in this component    
	const dataContext = useContext(DataContext)
	const { pagination,updatePagination, user, showcases, setShowcases,showcasesAthleteRelation,showcasesCoachRelation,setShowcasesAthleteRelation } = dataContext
	
	// Cognito user group
	const userGroup = changeUserGroupToAgencyId(user?.signInUserSession.idToken.payload["cognito:groups"][0])
	
    // State with the searched text
	const [searchState, updateSearchState] = useState({searchText: ''})
	// State that handles the selection of items
	const [ selectItem, setSelectItem ] = useState([])
	// State that control if sort is ascending or descending
	const [ sortAscending, setSortAscending ] = useState(true)
	
    // State that controls if the user has permission to do an action
	const [ noPermission, setNoPermission ] = useState(false)
	
    // State with action feedback
	const [ actionStatus, setActionStatus ] = useState({
        done: false,
        status: '',
        message: ''
    })

	// Hook for the confirm modal --> delete button
	const { isOpen, onOpen, onClose } = useDisclosure()
	const cancelRef = useRef()

	// Hook for the confirm modal --> has participant alert
	const withParticipantsAlert = useDisclosure()
	const cancelRefParticipants = useRef()

	// Navigation hook 
	let navigate = useNavigate()

	useEffect(() => {
		updatePagination({
			total:getSearchedData(showcases,searchState.searchText).length,
			page: 1,
			rowsPerPage: 10,
			lower: 1})
		setSelectItem([])
	}, [])

	// Function that handles the selection of all items
	const handleSelectAll = (e) => {
		e.target.checked ? setSelectItem(showcases.map(item => item.id)) : setSelectItem([])
	}

	// Function that handles if the user can use the delete action
	const handleDelete = () => {

		let hasParticipants = false
		selectItem.forEach((item)=>{
			hasParticipants = (showcasesAthleteRelation.filter(it=>it.showcaseID === item).length + showcasesCoachRelation.filter(it=>it.showcaseID === item).length) > 0
		})

		// Only if the user is admin
		hasParticipants && userGroup === "superadmins" 
		? withParticipantsAlert.onOpen()
		: (!hasParticipants && userGroup === "superadmins") ? onOpen() : setNoPermission(true)
	}

	// Function that handles the confirm delete action
	const handleConfirmDelete = () => {
		onClose()
		withParticipantsAlert.onClose()
				
		let hasFailed = false
		selectItem.map((item) => {
			// Get associated athletes to the relation with the showcases
			const associatedAthleteRelation = showcasesAthleteRelation.filter(it=>it.showcaseID === item);
			// const associatedCoachRelation = showcasesCoachRelation.filter(it=>it.showcaseID === item);

			// If there is any associated athlete, we need to delete this relation.
			((associatedAthleteRelation?.length) > 0 ) //  + associatedCoachRelation?.length
			&& (
				// associatedCoachRelation.forEach((coach)=>{deleteShowcaseCoachRelationService(coach.id).catch(error=>hasFailed = true)}),
				// setShowcasesCoachRelation(showcasesCoachRelation.filter(showRel => !associatedCoachRelation.includes(showRel.id))),
				associatedAthleteRelation.forEach((athlete)=>{deleteShowcaseAthleteRelationService(athlete.id).catch(error=>hasFailed = true)}),
				setShowcasesAthleteRelation(showcasesAthleteRelation.filter(showRel => !associatedAthleteRelation.includes(showRel.id)))
			) 
			// Delete showcase
			deleteShowcaseService(item).catch(error => { hasFailed = true })
		})

		setShowcases(showcases.filter(show => !selectItem.includes(show.id)))
		setSelectItem([])
		// Action feedback
		!hasFailed ? setActionStatus({
			done: true,
			status: 'success',
			message: 'Showcase(s) deleted successfully'
		}): setActionStatus({
			done: true,
			status: 'error',
			message: 'An error has ocurred while deleting the showcase(s)'
		})
		navigate(`/admin/showcases/`)
	}


	return (
		<Flex direction='column' pt={{ base: "120px", md: "75px" }}>

			<ConfirmModal
				title={selectItem.length === 1 ? "Delete Showcase" : "Delete Showcase"}
				description="Are you sure? You can't undo this action afterwards."
				button1="Cancel"
				button2="Delete"
				isOpen={isOpen}
				onClose={onClose}
				cancelRef={cancelRef}
				handleDelete={handleConfirmDelete}
			/>
			{/* Confirm deletion of showcase with participants */}
			<AlertDialog
				isOpen={withParticipantsAlert.isOpen}
				leastDestructiveRef={cancelRefParticipants}
				onClose={withParticipantsAlert.onClose}
				motionPreset='scale'
			>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize='lg' fontWeight='bold'>
						Attention! Showcase with participants.
						</AlertDialogHeader>

						<AlertDialogBody>
						You are about to delete a showcase with confirmed participants and this action cannot be recovered afterwards. Do you want to continue?
						</AlertDialogBody>

						<AlertDialogFooter>
						<Button ref={cancelRef} onClick={()=>withParticipantsAlert.onClose()}>
							Cancel
						</Button>
						<Button colorScheme={"red"} onClick={handleConfirmDelete} ml={3}>
							Confirm delete
						</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>

            <Flex justifyContent='right' m="0px 10px 10px 0">
                
                <CreateButton handleCreate={() => { userGroup === "superadmins" ? navigate(`/admin/showcases/create`) : setNoPermission(true) }}/>
                {
                    selectItem.length > 0 
                    ? (<DeleteButton handleDelete={handleDelete} />)
                    : null
                }
            </Flex>

			{
				noPermission
				? (
					<Alert status='error' borderRadius="10px" mb="20px">
						<AlertIcon />
						You don't have enough permissions to perform this action on showcases.
						<CloseButton position='absolute' right='8px' top='8px' onClick={()=>(setNoPermission(false))} />
					</Alert>
				)
				: null
			}
			
			{
				actionStatus.done && (
					<Alert status={actionStatus.status} borderRadius="10px" mb="20px" >
						<AlertIcon />
						{actionStatus.message}
						<CloseButton position='absolute' right='8px' top='8px' onClick={()=>(setActionStatus({...actionStatus,done:false}))} />
					</Alert>
				)
			}
			<Card overflowX={{ sm: "scroll", xl: "hidden" }}>
				<CardHeader p='6px 0px 22px 0px'>
					<Flex direction='row' w="100%">
						<Flex justifyContent='left' w="40%">
							<Text fontSize='xl' color={textColor} fontWeight='bold'>
								Showcases ({searchState.searchText!== '' ? `Filtered result ${getSearchedData(showcases,searchState.searchText).length}` : showcases.length} - {selectItem.length} selected)
							</Text>
						</Flex>
						<Flex justifyContent='right' w="60%">
							<SearchBar text={searchState} update={updateSearchState}/>
						</Flex>
					</Flex>
				</CardHeader>
				<CardBody>
					<Table variant='simple' color={textColor}>
						<Thead>
							<Tr my='.8rem' pl='0px' color='gray.400'>
                                <Th color='gray.400' ><Checkbox colorScheme='blue' onChange={(e)=>(handleSelectAll(e))} /></Th>
								<Th color='gray.400' cursor="pointer" onClick={()=>setSortAscending(!sortAscending)}>
									Showcase {sortAscending ? <Icon as={BsArrowUp} color="gray.400" /> : <Icon as={BsArrowDown} color="gray.400" />}
								</Th>
                                <Th color='gray.400'>Location / Date</Th>
                                <Th color='gray.400'>Athletes</Th>
                                <Th color='gray.400'>Add athletes</Th>
							</Tr>
						</Thead>
						<Tbody>

							{getSearchedData(showcases,searchState.searchText).sort((a,b) => {
									if(sortAscending){return a.name.toLowerCase().localeCompare(b.name.toLowerCase())}
									else{return b.name.toLowerCase().localeCompare(a.name.toLowerCase())}
								}).slice(pagination.lower-1,pagination.lower+pagination.rowsPerPage-1).map((row) => {
								return (
                                    <ShowcaseTableRow
                                        key={`${row.id}`}
                                        id={row.id}
                                        name={row.name}
                                        description={row.description}
                                        country={row.country}
                                        address={row.address}
                                        date={row.date.split("-").reverse().join("-")}
                                        status={row.status}
                                        selectItem={selectItem}
                                        setSelectItem={setSelectItem}
										setActionStatus={setActionStatus}
                                    />
								);
							})}
						</Tbody>
					</Table>
				</CardBody>
				<Pagination totalData={getSearchedData(showcases,searchState.searchText).length} rowsPerPageValues={[10,20,30,40,50]} />
			</Card>

		</Flex>
	);
};

export default ShowcasesTables;
