// React imports
import React, { useContext, useEffect } from 'react'
import { Routes, Route } from 'react-router-dom';
// Context imports
import { DataContext } from '../../../contexts/dataContext'
import CreateShowcase from './components/CreateShowcase';
import EditShowcase from './components/EditShowcase';
// Component imports
import ShowcasesTables from './components/ShowcasesTables';
import { ShowShowcase } from './components/ShowShowcase';

export const ShowcasesRoutes = () => {
    const dataContext = useContext(DataContext)
    const { showcases } = dataContext

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <Routes>
                <Route path="/" element={<ShowcasesTables />} />
                <Route path="/show/:showcaseID" element={<ShowShowcase />} />
                <Route path="/edit/:showcaseID" element={<EditShowcase />} />
                <Route path="/create" element={<CreateShowcase />} /> 
            </Routes>
        </>
    )
}
