// React imports
import React, { useContext,useState, useEffect, useRef } from 'react'
import { useNavigate } from "react-router-dom";
// Context imports
import { DataContext } from '../../../../contexts/dataContext'
// Chakra imports
import {
	Table,
	Tbody,
	Text,
	Th,
	Thead,
	Tr,
	useColorModeValue,
	Flex,
	Checkbox,
	useDisclosure,
	Alert,
	AlertIcon,
	CloseButton,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CollegesTableRow from "./CollegesTableRow";
import { Pagination } from "components/Pagination/Pagination";
import { SearchBar } from "components/SearchBar/SearchBar";
import { DeleteButton } from "../../../../components/Buttons/DeleteButton";
import { CreateButton } from "../../../../components/Buttons/CreateButton";
import { ConfirmModal } from "../../../../components/ConfirmModal/ConfirmModal";

// Auxiliary functions
import { getSearchedData } from "helpers/getSearchedData";
import { changeUserGroupToAgencyId } from "../../../../helpers";
import { deleteCollegeService } from "../../../../services/collegeServices";


const Colleges = ({ title, captions, data}) => {
	// Chakra color mode
	const textColor = useColorModeValue("gray.700", "white");
	
	// Take the context data needed in this component
	const dataContext = useContext(DataContext)
	const { pagination, updatePagination, user, colleges, setColleges } = dataContext
	// Cognito user group
	const userGroup = changeUserGroupToAgencyId(user?.signInUserSession.idToken.payload["cognito:groups"][0])
	// State with the searched text
	const [ searchState, updateSearchState ] = useState({searchText: ''})
	// State that handles the selection of items
	const [ selectItem, setSelectItem ] = useState([])
	// State that controls if the user has permission to do an action
	const [ noPermission, setNoPermission ] = useState(false)
	// State with action feedback
	const [ actionStatus, setActionStatus ] = useState({
        done: false,
        status: '',
        message: ''
    })

	// Hook for the confirm modal
	const { isOpen, onOpen, onClose } = useDisclosure()
	const cancelRef = useRef()
	// Navigation hook 
	let navigate = useNavigate()

	// When the component is mounted, the pagination is reseted
	useEffect(() => {
		updatePagination({
			total:getSearchedData(data,searchState.searchText).length,
			page: 1,
			rowsPerPage: 10,
			lower: 1})
		setSelectItem([])
	}, [])

	// Function that handles the selection of all items
	const handleSelectAll = (e) => {
		e.target.checked ? setSelectItem(data.map(item => item.id)) : setSelectItem([])
	}

	// Function that handles the delete action
	const handleDelete = () => {

		// Only if the user want to delete his agency or the user is admin
		userGroup === "superadmins" 
		? onOpen()
		: setNoPermission(true)
	}

	// Function that handles the confirm delete action
	const handleConfirmDelete = () => {
		onClose()
		let hasFailed = false
		selectItem.forEach((item) => {
			deleteCollegeService(item)
			.catch(() => { hasFailed = true })
		})
		setColleges(colleges.filter(college => !selectItem.includes(college.id)))
		setSelectItem([])
		hasFailed ? setActionStatus({
			done: true,
			status: 'error',
			message: 'An error has ocurred while deleting the college(s)'
		}) : setActionStatus({
			done: true,
			status: 'success',
			message: 'College(s) deleted successfully'
		})
		navigate(`/admin/colleges/`)
	}


	return (
		<>
			<ConfirmModal
				title={selectItem.length === 1 ? "Delete College" : "Delete Colleges"}
				description="Are you sure? You can't undo this action afterwards."
				button1="Cancel"
				button2="Delete"
				isOpen={isOpen}
				onClose={onClose}
				cancelRef={cancelRef}
				handleDelete={handleConfirmDelete}
			/>
			<Flex justifyContent='right' m="0px 10px 10px 0">
				
				<CreateButton handleCreate={() => { userGroup === "superadmins" ? navigate(`/admin/colleges/create`) : setNoPermission(true)}}/>
				{
					selectItem.length > 0 
					? (<DeleteButton handleDelete={handleDelete} />)
					: null
				}
			</Flex>
			{
				noPermission
				? (
					<Alert status='error' borderRadius="10px" mb="20px">
						<AlertIcon />
						You don't have enough permissions to perform this action on colleges.
						<CloseButton position='absolute' right='8px' top='8px' onClick={()=>(setNoPermission(false))} />
					</Alert>
				)
				: null
			}
			{
				actionStatus.done && (
					<Alert status={actionStatus.status} borderRadius="10px" mb="20px" >
						<AlertIcon />
						{actionStatus.message}
						<CloseButton position='absolute' right='8px' top='8px' onClick={()=>(setActionStatus({...actionStatus,done:false}))} />
					</Alert>
				)
			}
			<Card overflowX={{ sm: "scroll", xl: "hidden" }}>
				<CardHeader p='6px 0px 22px 0px'>
					<Flex direction='row' w="100%">
						<Flex justifyContent='left' w="40%">
							<Text fontSize='xl' color={textColor} fontWeight='bold'>
							{title} ({searchState.searchText!== '' ? `Filtered result ${getSearchedData(data,searchState.searchText).length}` : data.length} - {selectItem.length} selected)
							</Text>
						</Flex>
						<Flex justifyContent='right' w="60%">
							<SearchBar text={searchState} update={updateSearchState}/>
						</Flex>
					</Flex>
				</CardHeader>
				<CardBody>
					<Table variant='simple' color={textColor}>
						<Thead>
							<Tr my='.8rem' pl='0px' color='gray.400'>
								<Th color='gray.400' ><Checkbox colorScheme='blue' onChange={(e)=>(handleSelectAll(e))} /></Th>
								{captions.map((caption, idx) => {
									return (
										<Th color='gray.400' key={idx} ps={idx === 0 ? "0px" : null}>
											{caption}
										</Th>
									);
								})}
							</Tr>
						</Thead>
						<Tbody>
							{getSearchedData(data,searchState.searchText).slice(pagination.lower-1,pagination.lower+pagination.rowsPerPage-1).map((row) => {
								return (
									<CollegesTableRow
										key={`${row.email}-${row.name}`}
										id={row.id}
										name={row.name}
										logo={row.logo}
										state={row.state}
										teams={row.teams?.items}
										selectItem={selectItem}
										setSelectItem={setSelectItem}
									/>
								);
							})}
						</Tbody>
					</Table>
				</CardBody>
				<Pagination totalData={getSearchedData(data,searchState.searchText).length} rowsPerPageValues={[10,20,30,40,50]} />
			</Card>
		</>
	);
};

export default Colleges;
