import React, { useContext,useState }  from "react";
import { DataContext } from '../../../../../contexts/dataContext'
// Chakra imports
import {
    Flex,
    Image,
    Table,
    Tbody,
    Text,
    Th,
    Thead,
    Tr,
    Td,
    useColorModeValue,
    Avatar,
    ScaleFade,
    Button,
} from "@chakra-ui/react";
import { Skeleton } from '@chakra-ui/react'
// Custom components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import { changeUserGroupToAgencyId } from "helpers";
import CardBody from "components/Card/CardBody";
import { getActualSeason } from "helpers";
  
const RankingsForAgents = ({ title,subtitle,order }) => {
    const textColor = useColorModeValue("gray.700", "white");
    const dataContext = useContext(DataContext)
	const { agents, athletes,agencies, athletesEnquirys, user } = dataContext

    const [hasRender, setRender] = useState(false);
    const onShow = React.useCallback(() => setRender(true), []);

    // const [isLoading, setIsLoading] = useState(true)
    const userGroup = changeUserGroupToAgencyId(user.signInUserSession.idToken.payload["cognito:groups"][0])

    const getAgentActivityStatistics = () => {
        // setIsLoading(true)
		let newStats = []
        if(userGroup === 'superadmins'){
            athletes.filter(item => new Date(item.createdAt).getMonth() === new Date().getMonth() && new Date(item.createdAt).getFullYear() === new Date().getFullYear()).map(item => {
                const exist = newStats.find(it => it.agent === item.agentID)
                if(exist){exist.psaCreated += 1}
                else{newStats.push({agent: item.agentID,psaCreated: 1})}
            })
            athletesEnquirys.filter(item => new Date(item.createdAt).getMonth() === new Date().getMonth() && new Date(item.createdAt).getFullYear() === new Date().getFullYear()).map(item => {
                const exist = newStats.find(it => it.agent === agents.find(ag => ag.id === item.athlete?.agentID)?.id)
                if(exist){if(exist.enquiries){exist.enquiries += 1}else{exist.enquiries = 1}}
                else{newStats.push({agent:agents.find(ag => ag.id === item.athlete?.agentID)?.id,enquiries: 1})}
            })
            athletes.filter(item => item.start_date === getActualSeason() && item.current_status === 'COMMITTED' && new Date(item.end_availability_date).getMonth() === new Date().getMonth() && new Date(item.end_availability_date).getFullYear() === new Date().getFullYear()).map(item => {
                const exist = newStats.find(it => it.agent === item.agentID)
                if(exist){if(exist.psaComm){exist.psaComm += 1}else{exist.psaComm = 1}}
                else{newStats.push({agent: item.agentID,psaComm: 1})}
            })
        }
        else{
            athletes.filter(item => item.agencyID === userGroup && new Date(item.createdAt).getMonth() === new Date().getMonth() && new Date(item.createdAt).getFullYear() === new Date().getFullYear()).map(item => {
                const exist = newStats.find(it => it.agent === item.agentID)
                if(exist){
                    exist.psaCreated += 1
                }
                else{
                    newStats.push({agent: item.agentID,psaCreated: 1})
                }
            })
            athletesEnquirys.filter(item => agents.find(ag => ag.id === item.coachID).agencyID === userGroup && agents.find(ag => ag.id === item.coachID) && new Date(item.createdAt).getMonth() === new Date().getMonth() && new Date(item.createdAt).getFullYear() === new Date().getFullYear()).map(item => {
                const exist = newStats.find(it => it.agent === agents.find(ag => ag.id === athletes.find(ath => ath.id === item.athleteID)?.agentID)?.id)
                if(exist){
                    if(exist.enquiries){
                        exist.enquiries += 1
                    }else{
                        exist.enquiries = 1
                    }
                }
                else{
                    newStats.push({agent:agents.find(ag => ag.id === athletes.find(ath => ath.id === item.athleteID)?.agentID).id,enquiries: 1})
                }
            })
            athletes.filter(item => item.start_date === getActualSeason() && item.current_status === 'COMMITTED' && item.agencyID === userGroup && new Date(item.end_availability_date).getMonth() === new Date().getMonth() && new Date(item.end_availability_date).getFullYear() === new Date().getFullYear()).map(item => {
                const exist = newStats.find(it => it.agent === item.agentID)
                if(exist){if(exist.psaComm){exist.psaComm += 1}else{exist.psaComm = 1}}
                else{newStats.push({agent: item.agentID,psaComm: 1})}
            })
        }
		return newStats

	}

    return (
        <>
            { hasRender && (
                <Skeleton isLoaded={agents.length + athletes.length + agencies.length + athletesEnquirys.length > 0 }>
                    <ScaleFade in={hasRender} >
                        <Card p='16px' overflowX={{ sm: "scroll", xl: "hidden" }} h='700px'>
                            <CardHeader p='12px 0px 28px 0px'>
                                <Flex direction='column'>
                                    <Text fontSize='lg' color={textColor} fontWeight='bold' pb='.5rem'>
                                    {title}
                                    </Text>
                                    <Text fontSize='md' fontWeight='medium' color='gray.400'>
                                    {subtitle}
                                    </Text>
                                </Flex>
                            </CardHeader>
                            <CardBody overflowY="scroll">
                                <Table variant='simple' color={textColor} >
                                    <Thead>
                                        <Tr my='.4rem' ps='0px'>
                                            <Th color='gray.400'>
                                                AGENT
                                            </Th>
                                            <Th color='gray.400'>
                                                NO PROFILES CREATED
                                            </Th>
                                            <Th color='gray.400'>
                                                NO ENQUIRIES RECEIVED
                                            </Th>
                                            <Th color='gray.400'>
                                                NO COMMITTMENTS
                                            </Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {
                                            getAgentActivityStatistics().sort((it1,it2)=>(
                                                order === 'most' ? (
                                                    ((it2.psaCreated ? it2.psaCreated : 0 )+(it2.enquiries ? it2.enquiries : 0)+(it2.psaComm ? it2.psaComm : 0))
                                                    -
                                                    ((it1.psaCreated ? it1.psaCreated : 0) +(it1.enquiries ? it1.enquiries : 0)+(it1.psaComm ? it1.psaComm : 0))
                                                    ): (
                                                        ((it1.psaCreated ? it1.psaCreated : 0) +(it1.enquiries ? it1.enquiries : 0)+(it1.psaComm ? it1.psaComm : 0))
                                                        -
                                                        ((it2.psaCreated ? it2.psaCreated : 0 )+(it2.enquiries ? it2.enquiries : 0)+(it2.psaComm ? it2.psaComm : 0))
                                                        )
                                                        
                                                        )).slice(0,10).map( (item,idx) => (
                                                            agents.find(ag => ag.id === item.agent) && (
                                                                <Tr  key={idx}>
                                                                    <Td maxWidth={{ sm: "300px" }} pl="0px">
                                                                        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
                                                                            {/* <Image src={agents.find(ag => ag.id === item.agent)?.profile_pic} h={"50px"} w={"50px"} pe="5px" borderRadius="12px" mr="10px"/> */}
                                                                            <Avatar src={agents.find(ag => ag.id === item.agent)?.profile_pic}  h={"50px"} w={"50px"} mr="10px"/>
                                                                            <Flex direction="column">
                                                                                <Text
                                                                                    fontSize="md"
                                                                                    color={textColor}
                                                                                    fontWeight="bold"
                                                                                    minWidth="100%"
                                                                                    cursor="pointer"
                                                                                    onClick={()=>navigate(`/admin/agents/show/${agents.find(ag => ag.id === item.agent)?.id}`)}
                                                                                    >
                                                                                    {agents.find(ag => ag.id === item.agent)?.name}
                                                                                </Text>
                                                                                <Text fontSize="sm" color="gray.400" fontWeight="normal">
                                                                                    {agents.find(ag => ag.id === item.agent)?.contact_email}
                                                                                </Text>
                                                                                <Text fontSize="sm" color="gray.400" fontWeight="normal">
                                                                                    {agencies.find(agn=>agn.id === agents.find(ag => ag.id === item.agent)?.agencyID)?.name}
                                                                                </Text>
                                                                            </Flex>
                                                                        </Flex>
                                                                    </Td>

                                                                    <Td>
                                                                        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
                                                                            <Flex direction="column">
                                                                                <Text
                                                                                    fontSize="md"
                                                                                    color={textColor}
                                                                                    fontWeight="bold"
                                                                                    minWidth="100%"
                                                                                    >
                                                                                    {item.psaCreated ? item.psaCreated : 0}
                                                                                </Text>
                                                                                <Text fontSize="sm" color="gray.400" fontWeight="normal">
                                                                                    PSAs created
                                                                                </Text>
                                                                            </Flex>
                                                                        </Flex>
                                                                    </Td>
                                                                    <Td>
                                                                        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
                                                                            <Flex direction="column">
                                                                                <Text
                                                                                    fontSize="md"
                                                                                    color={textColor}
                                                                                    fontWeight="bold"
                                                                                    minWidth="100%"
                                                                                    >
                                                                                    {item.enquiries ? item.enquiries : 0}
                                                                                </Text>
                                                                                <Text fontSize="sm" color="gray.400" fontWeight="normal">
                                                                                    Enquiries received
                                                                                </Text>
                                                                            </Flex>
                                                                        </Flex>
                                                                    </Td>
                                                                    <Td>
                                                                        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
                                                                            <Flex direction="column">
                                                                                <Text
                                                                                    fontSize="md"
                                                                                    color={textColor}
                                                                                    fontWeight="bold"
                                                                                    minWidth="100%"
                                                                                    >
                                                                                    {item.psaComm ? item.psaComm : 0}
                                                                                </Text>
                                                                                <Text fontSize="sm" color="gray.400" fontWeight="normal">
                                                                                    Commitments for Recruiting Cycle
                                                                                </Text>
                                                                            </Flex>
                                                                        </Flex>
                                                                    </Td>
                                                                </Tr>
                                                            )
                                            ) )
                                        }
                                    </Tbody>
                                </Table>
                            </CardBody>
                        </Card>
                    </ScaleFade>
                </Skeleton>
            )}
            { !hasRender && (
                <ScaleFade in={!hasRender} >
                    <Card p='28px 10px 16px 0px'>
                        <CardHeader pl='22px' w="100%">
                            <Flex direction='row' alignSelf='flex-start' w="100%" justifyContent={'space-between'} alignItems="center">
								<Flex direction='column' alignSelf='flex-start' w="50%">
                                    <Text fontSize='lg' color={"gray.700"} fontWeight='bold' mb='6px'>
                                    {title}
                                    </Text>
                                    <Text fontSize='md' fontWeight='medium' color='gray.400'>
                                    {subtitle}
                                    </Text>
                                </Flex>
                                <Button w="40%" colorScheme='blue' onClick={onShow} my="10px" mr="5%">
                                    Load chart
                                </Button>
                            </Flex>
                        </CardHeader>
                    </Card>
                </ScaleFade>
            )}
        </>
    );
};

const myPropsChanged = (prevProps, nextProps) => {
    if ( prevProps !== nextProps) {
        return false;
    }
    return true;
}

export default React.memo(RankingsForAgents, myPropsChanged);
  

