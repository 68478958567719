import { Box, ChakraProvider, Portal, useDisclosure } from '@chakra-ui/react';
import Sidebar from 'components/Sidebar';
import React from 'react'
import theme from 'theme/theme';

import AdminNavbar from "components/Navbars/AdminNavbar.js";

// Routes to load
import routes from "routes.js";
import MainPanel from 'components/Layout/MainPanel';
import { Navigate, Route, Routes } from 'react-router-dom';
import ErrorPage from 'views/NewTab/ErrorPage';
import ShowUniqueAthlete from 'views/NewTab/ShowUniqueAthlete';
import { EditAthlete } from 'views/Dashboard/Athletes/components/EditAthlete';

export default function NewTabLayout(props) {
	const { ...rest } = props;
	// ref for the wrapper div
	const wrapper = React.createRef();

	React.useEffect(() => {
		document.body.style.overflow = "unset";
		// Specify how to clean up after this effect:
		return function cleanup() {};
	});
	
    
    const navRef = React.useRef();
    const { isOpen, onOpen, onClose } = useDisclosure();
	document.documentElement.dir = "ltr";
	
    
    return (
		<ChakraProvider theme={theme} resetCss={false}>

			{/* Side bar with menu */}
			{/* <Sidebar
				routes={routes}
				logoText={"Alliance Athletes"}
				display="none"
				// sidebarVariant={sidebarVariant}
				{...rest}
			/> */}

			{/* Main Panel */}
			<MainPanel
				w={{
					base: "100%",
				}}
				h={{
					base: "100vh",
				}}
			>
				{/* Main content of the layout */}
				<Routes>
					<Route path="/show/:athleteId" element={<ShowUniqueAthlete />} />
                	<Route path="/edit/:athleteId" element={
						<Box
							w="90%"
							mx="auto"
							my={{ base: "3%", md: "2%", lg: "1%"}}
						>
							<EditAthlete navigatePath="/unique/show" />
						</Box>
					} />	
					<Route path="*" element={<ErrorPage />} />
				</Routes>

			</MainPanel>
			
		</ChakraProvider>
	);
}


