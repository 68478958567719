// React imports
import React from "react";
import { useNavigate } from 'react-router-dom'
// Chakra imports
import {
	Image,
	Icon,
	Button,
	Flex,
	Td,
	Text,
	Tr,
	useColorModeValue,
	Checkbox,
} from "@chakra-ui/react";
// React-icons 
import { MdSchool } from "react-icons/md";
import { GiSoccerBall } from "react-icons/gi";
import { BsGenderFemale, BsGenderMale } from "react-icons/bs";





function CollegesTableRow({ id, name, logo, state,teams, selectItem, setSelectItem }) {
	// Chakra color mode
	const textColor = useColorModeValue("gray.700", "white");
	const titleColor = useColorModeValue('royalblue.700');
	// Navigate hook
	let navigate = useNavigate()

	// Function that handles the selection of an item
	const handleSelectItem = (e) => {
		setSelectItem(e.target.checked ? [...selectItem, id] : selectItem.filter(item => item !== id))
	}

	// Function that return the icon for the genre of the team
	const getGenderIcon = (genre) => {
		return genre === 'Men' ? <Icon as={BsGenderMale} h="15px" /> : <Icon as={BsGenderFemale} h="15px" />
	}

	return (
		<Tr>
			{/* First column */}
			<Td>
				<Checkbox colorScheme='blue' onChange={(e)=>(handleSelectItem(e))} isChecked={selectItem.includes(id)} />
			</Td>
			{/* Second column */}
			<Td minWidth={{ sm: "30px" }} pl="0px"  onClick={()=>(navigate(`/admin/colleges/show/${id}`))} style={{cursor:'pointer'}}>
				<Flex align="center" py=".2rem" minWidth="100%" flexWrap="nowrap">
					{logo
						? <Image src={logo} w="90px" borderRadius="12px" />
						: <Icon as={MdSchool} />
					}
				</Flex>
			</Td>

			{/* Third column */}
			<Td maxWidth={{ sm: "300px" }} onClick={()=>(navigate(`/admin/colleges/show/${id}`))} style={{cursor:'pointer'}}>
				<Text
					fontSize="sm"
					color={textColor}
					fontWeight="bold"
					minWidth="100%"
				>
					{name}
				</Text>
			</Td>

			{/* Fouth column */}
			<Td maxWidth={{ sm: "150px" }}>
				<Text fontSize="sm" color="gray.400" fontWeight="normal">
					{state}
				</Text>
			</Td>

			{/* Fifth column */}
			<Td>
				{
					teams?.map((team,idx)=>{
						return(

							<Flex
								align='center'
								bg='hsla(0,0%,100%,.3)'
								borderRadius='15px'
								justifyContent='center'
								py='5px'
								boxShadow='inset 0 0 1px 1px hsl(0deg 0% 100% / 90%), 0 20px 27px 0 rgb(0 0 0 / 5%)'
								border='1px solid gray.200'
								m='5px'
								cursor='pointer'
								key={idx}
							>
								<Button p='0px' bg='transparent' _hover={{ bg: "none" }} leftIcon={<Icon as={GiSoccerBall} w='20px' />} onClick={()=>(navigate(`/admin/teams/show/${team.id}`))}>
									<Flex direction="column" alignItems='start' w="100%">
										<Text
											fontSize='sm'
											color={textColor}
											fontWeight='bold'
											ms='6px'
											>
											{team.name}
										</Text>
										<Text fontSize="sm" ms='6px' mt="2px" color="gray.400" fontWeight="normal">
											<i>{team.genre}'s Team</i>
										</Text>
									</Flex>
								</Button>
							</Flex>
						)
					})
				}
			</Td>

		</Tr>
	);
}

export default CollegesTableRow;
