import React from "react";
import ReactApexChart  from "react-apexcharts";

export const LineChart = ({data,options}) => {
	return (
		<ReactApexChart 
			options={options}
			series={data}
			type="area"
			width="100%"
			height="100%"
		/>
	)
}

export default LineChart;
