// React imports
import React, { useContext, useEffect } from 'react'
// Context imports
import { DataContext } from '../../../../contexts/dataContext'
// Chakra imports
import { Flex } from "@chakra-ui/react";
// Components
import Agents from './Agents'


function AgentsTables({agents, showAgency}) {
	// Take the context data needed in this component
	const dataContext = useContext(DataContext)
	const { agencies } = dataContext

	let myCaptions = []
	// If we are showing the agents of an agency, we do not need to show the agency name
	showAgency
	? myCaptions = ["Profile pic", "Name", "Contact email", "Phone number", "Agency"]
	: myCaptions = ["Profile pic", "Name", "Contact email", "Phone number"]

	useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

	return (
		<Flex direction='column' pt={{ base: "120px", md: "75px" }}>
			<Agents
				title={"Agents"}
				captions={myCaptions}
				data={agents}
				supData={agencies}
				showAgency={showAgency}
			/>
		</Flex>
	);
}

export default AgentsTables;
