import React from 'react'
import { Button } from '@chakra-ui/react'
import { AddIcon } from "@chakra-ui/icons";


export const CreateButton = ({handleCreate}) => {
    return (
        <>
            <Button
                colorScheme='blue'
                variant='ghost'
                leftIcon={<AddIcon />}
                size='sm'
                onClick={handleCreate}
            >
                CREATE
            </Button>
        </>
    )
}
