const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:d87012a7-1c1d-4acf-b001-0d77c74d812e",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_15jQREW7i",
    "aws_user_pools_web_client_id": "5gt75i7apveipt6m4dkqfq4hja",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://we4olltqkvda7bysfm2zhz5u5e.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-fakeApiId123456"
};

export default awsmobile;
