
// Data Context
import React, { useContext }  from "react";
import DataContextProvider, { DataContext } from './contexts/dataContext'

// Navigation of the app
import ReactDOM from "react-dom";
import { Routes, Route, Navigate, BrowserRouter } from "react-router-dom";

//Layouts
import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";

// Components
import Loading from './components/Loading'

//Amplify
import Amplify from 'aws-amplify';
import awsmobile from './aws-exports';
import NewTabLayout from "layouts/NewTab";
import SubscriptionProvider from "contexts/subscriptionProvider";
Amplify.configure(awsmobile)


// Main component of the application
// Here, we define the main routes of the application (public and private)
// Also, show the loading screen while the context is loading

const TheRoutes = ({ children }) => {

	const dataContext = useContext(DataContext)
	const { user, isLoading } = dataContext
	const actualPath = window.location.pathname
	// If the context is loading, show the loading screen
	if (isLoading && !actualPath.includes('unique')) {
		return <Loading visible={true} />
	}


	return (
		<Routes style={{transition: "all 0.5s ease-out"}}>
			{/* If the user is logged in, they can see this screens */}
			{user !== 'notLoggedIn' && (
				<>
					<Route path={'/admin/*'} element={<AdminLayout/>} />
					<Route path={'/unique/*'} element={<NewTabLayout/>} />
				</>
			)}
			{/* If the user is not logged in, they only can see this screens */}
			<Route path={'/auth/*'} element={<AuthLayout/>} />
			<Route path="*" element={<Navigate to="/auth/" replace />} />
		</Routes>

	)

}

// We render the main component of the application by applying 
// the navigation context and DataContextProvider to it

ReactDOM.render(
    <BrowserRouter>
      	<DataContextProvider>
			<SubscriptionProvider>
        		<TheRoutes />
			</SubscriptionProvider>
      	</DataContextProvider>
    </BrowserRouter>,
  	document.getElementById("root")
);
