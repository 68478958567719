// React imports
import React, { useContext,useState, useEffect, useRef } from 'react'
import { useNavigate } from "react-router-dom";
// Context imports
import { DataContext } from '../../../../contexts/dataContext'
// Chakra imports
import {
	Table,
	Tbody,
	Text,
	Th,
	Thead,
	Tr,
	useColorModeValue,
	Flex,
	Checkbox,
	useDisclosure,
	Alert,
	AlertIcon,
	CloseButton,
	Select,Spacer,Icon, Button,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { Pagination } from "components/Pagination/Pagination";
import { SearchBar } from "components/SearchBar/SearchBar";
import AthletesTableRow from "./AthletesTableRow";
import { DeleteButton } from "../../../../components/Buttons/DeleteButton";
import { CreateButton } from "../../../../components/Buttons/CreateButton";
import { ConfirmModal } from "../../../../components/ConfirmModal/ConfirmModal";
// Auxiliary functions
import { getSearchedData } from "helpers/getSearchedData";
import {getAgenciesUtilities} from "helpers"
import { changeUserGroupToAgencyId } from "../../../../helpers";
import { deleteAthleteService, deleteAthleteTagsRelationService, deleteFavoritesFolderAthleteTagsRelationService } from "../../../../services/athleteServices";
import {positionAcronym,startDate} from "helpers"
import {BsArrowDown, BsArrowUp} from 'react-icons/bs'
import { deleteCoachAthleteFavoritesService } from 'services/coachServices';
import { CSVLink } from "react-csv";
import { HiDownload } from 'react-icons/hi';
import { ImNewTab } from 'react-icons/im';
import { deleteAthleteEnquiryService } from 'services/coachServices';


const Athletes = ({ title, captions, data,showMoreInfo, noCreateButton=false}) => {
	// Chakra color mode
	const textColor = useColorModeValue("gray.700", "white");

	// Take the context data needed in this component
	const dataContext = useContext(DataContext)
	const { 
		pagination,
		updatePagination,
		user,
		athletes,
		setAthletes,
		agencies,
		userCoachData,
		agents, 
		coachAthleteFavorites,
		setCoachAthleteFavorites,
		athleteTagRelations,
		setAthleteTagRelation,
		favoriteFolderAthleteRelation,
		setFavoriteFolderAthleteRelation,
		athletesFilters,
		setAthletesFilters,
		athletesEnquirys,
		setAthletesEnquirys,
	} = dataContext
	// State with the searched text
	const [ searchState, updateSearchState ] = useState({searchText: ''})
	// State that handles the selection of items
	const [ selectItem, setSelectItem ] = useState([])
	console.log("selectItem",selectItem)
	// State that controls if the user has permission to do an action
	const [ noPermission, setNoPermission ] = useState(false)
	// State that controls if the user has selected to show only his athletes
	const [ myAthletes, setMyAthletes ] = useState(false)
	// State with isVisible filter: 0 - All, 1 - Active, 2 - Inactive
	// State that control if sort is ascending or descending
	const [ sortAscending, setSortAscending ] = useState(true)

	// // Options for agent filter, this will change if the user filter by agency
	let agentsOption = 	agents.map(agent => { return {value:agent.id,label:agent.name}})
	// const [ athletesFilters, setAthletesFilters ] = useState({
	// 	visible:0,
	// 	visibleData: ['ALL', 'Active', 'Hidden'],
	// 	status:0,
	// 	statusData:['ALL','AVAILABLE','ON_HOLD','COMMITTED'],
	// 	position: 0,
	// 	positionData:[ 'ALL', "GOALKEEPER","CENTER_BACK","RIGHT_BACK","LEFT_BACK","DEFENSIVE_MIDFIELDER","CENTER_MIDFIELDER","ATTACKING_MIDFIELDER","RIGHT_WINGER","LEFT_WINGER","FORWARD_STRIKER" ],
	// 	sport: 0,
	// 	sportData: ['ALL','Soccer', 'Tennis', 'Not specified'],
	// 	agency:0,
	// 	agencyData: ['ALL',...getAgenciesUtilities().map(ag => ag.acronym)],
	// 	semester: 0,
	// 	semesterData: ['ALL', ...startDate.map(it => it.label)],
	// 	agent: '0',
	// 	agentData: [{value:0,label:'ALL'}, ...agentsOption],
	// })

	useEffect(() => {
		// If the user filter by agency then the options for agents change.
		athletesFilters.agency !== 0 && (
			agentsOption = agents.filter(item => item.agencyID === getAgenciesUtilities().find(it => it.acronym === athletesFilters.agencyData[athletesFilters.agency])?.id).map(agent => { return {value:agent.id,label:agent.name}}),
			setAthletesFilters({...athletesFilters,agentData: [{value:0,label:'ALL'}, ...agentsOption]})
		)
	}, [athletesFilters.agency])
	

	// State with action feedback
	const [ actionStatus, setActionStatus ] = useState({
        done: false,
        status: '',
        message: ''
    })

	// Hook for the confirm modal
	const { isOpen, onOpen, onClose } = useDisclosure()
	const cancelRef = useRef()
	// Navigation hook 
	let navigate = useNavigate()

	useEffect(() => {
		updatePagination({
			total:getSearchedData(data,searchState.searchText).length,
			page: 1,
			rowsPerPage: 30,
			lower: 1})
		setSelectItem([])
	}, [])

	const filterData = (data) => {
		let newData = data
		// No athletesFilters applied
		if (athletesFilters.visible === 0 && athletesFilters.status===0 && athletesFilters.position === 0 && athletesFilters.sport === 0 && athletesFilters.agency === 0 && athletesFilters.semester === 0 && athletesFilters.agent === '0') {newData = data}
		// Visible filter
		if (athletesFilters.visible > 0) {newData = newData.filter(item => item.isVisible === (athletesFilters.visible === 1 ? true : false))}
		// Current status filter
		if (athletesFilters.status>0){ newData = newData.filter(item => item.current_status === athletesFilters.statusData[athletesFilters.status]) }
		// Position filter
		if (athletesFilters.position>0){ newData = newData.filter(item => item.position === athletesFilters.positionData[athletesFilters.position]) }
		// Sport filter
		if (athletesFilters.sport > 0 && athletesFilters.sport < 3){newData = newData.filter(item => item.sport === athletesFilters.sportData[athletesFilters.sport])}
		if (athletesFilters.sport === 3){newData = newData.filter(item => !item.sport)}
		// Agency/agent filter
		if (athletesFilters.agent !== '0'){newData = newData.filter(item => item.agentID === athletesFilters.agent)}
		if (athletesFilters.agency !== 0 ){newData = newData.filter(item => item.agencyID === getAgenciesUtilities().find(it => it.acronym === athletesFilters.agencyData[athletesFilters.agency]).id)}
		// Start_date filtere
		if (athletesFilters.semester > 0 ){newData = newData.filter(item => item.start_date === startDate.find(it => it.label === athletesFilters.semesterData[athletesFilters.semester]).value)}
		// User athletes
		if (myAthletes){ newData = newData.filter(item => item.agentID === (agents.find(item => item.contact_email === userCoachData.contact_email))?.id) }
		// Return sorted athletes
		return newData.sort((a,b) => {
            if(sortAscending){return a.name.toLowerCase().localeCompare(b.name.toLowerCase())}
            else{return b.name.toLowerCase().localeCompare(a.name.toLowerCase())}
        })
	}

	// Function that handles the selection of all items
	const handleSelectAll = (e) => {
		e.target.checked ? setSelectItem(data.map(item => item)) : setSelectItem([])
	}

	// Function that handles the delete action
	const handleDelete = () => {
		const userGroup = changeUserGroupToAgencyId(user.signInUserSession.idToken.payload["cognito:groups"][0])
		let allAllowPSAs = true

		// Only if the user want to delete athlete/s of his agency or the user is admin
		selectItem.forEach ( (item) => {
			if(item.agencyID !== userGroup){allAllowPSAs = false}
		})

		// If all PSAs are from their agency or the user is superadmin.
		allAllowPSAs || userGroup === "superadmins" 
		? onOpen()
		: setNoPermission(true)
	}

	// Function that handles the confirm delete action
	const handleConfirmDelete = () => {
		onClose()
		let hasFailed = false
		selectItem.forEach((item) => {
			deleteAthleteService(item.id).catch(error => { hasFailed = true })
			// delete ATHLETES relations
				// Delete all the favorites-athlete relations of this athlete
			coachAthleteFavorites.map(caf=>{
				if(caf.athleteID === item.id){
					deleteCoachAthleteFavoritesService(caf.id).catch(() => { hasFailed=true })
				}}
			)
			setCoachAthleteFavorites(coachAthleteFavorites.filter(caf=>caf.athleteID !== item.id))
				// Delete all the athlete-tags relation associated to this athlete
			athleteTagRelations.map(ff=>{
				if(ff.athleteID === item.id){
					deleteAthleteTagsRelationService(ff.id).catch(() => { hasFailed=true })
				}}
			)
			setAthleteTagRelation(athleteTagRelations.filter(ff=>ff.athleteID !== item.id))
				// Delete all favorites folder relation that contains this athlete
			favoriteFolderAthleteRelation.map(ctr=>{
				if(ctr.athleteID === item.id){
					deleteFavoritesFolderAthleteTagsRelationService(ctr.id).catch(() => { hasFailed=true })
				}}
			)
			setFavoriteFolderAthleteRelation(favoriteFolderAthleteRelation.filter(ctr=>ctr.athleteID !== item.id))
		
			athletesEnquirys.map(athEnq=>{
				if(athEnq.athleteID === item.id){
					deleteAthleteEnquiryService(athEnq.id).catch(() => { hasFailed=true })
				}}
			)
			setAthletesEnquirys(athletesEnquirys.filter(athEnq=>athEnq.athleteID !== item.id))
		})
		setAthletes(athletes.filter(item => !selectItem.find(selectedItem => selectedItem.id === item.id)))
		setSelectItem([])
		!hasFailed ? setActionStatus({
			done: true,
			status: 'success',
			message: 'Athlete(s) deleted successfully'
		}): setActionStatus({
			done: true,
			status: 'error',
			message: 'An error has ocurred while deleting the athlete(s)'
		})
		navigate(`/admin/athletes/`)
	}
	const handleChangeSelect = (e) => {
		console.log(e.target.value)
	}

	const downloadData = () => {
		return data?.map(item => { return {
			"Name" : item.name ? item.name : 'Not provided',
			"Agency" : item.agency ? item.agency.name : 'Not provided',
			"Agent" : item.agent ? item.agent.name : 'Not provided',
			"Contact email" : item.contact_email ? item.contact_email : 'Not provided',
			"Visible in app" : item.isVisible ? item.isVisible : 'Not provided',
			"Profile pic" : item.profile_pic ? item.profile_pic : 'Not provided',
			"Card pic" : item.card_pic ? item.card_pic : 'Not provided',
			"Birthdate" : item.birthdate ? item.birthdate : 'Not provided',
			"Gender" : item.gender ? item.gender : 'Not provided',
			"Contact phone" : item.contact_phone ? item.contact_phone : 'Not provided',
			"Nationality" : item.nationality ? item.nationality : 'Not provided',
			"Country of competition" : item.country_of_competition ? item.country_of_competition : 'Not provided',
			"Target division" : item.target_division ? item.target_division.toString() : 'Not provided',
			"Compliance" : item.compliance ? item.compliance.toString() : 'Not provided',
			// "Agency assesment" : item.agency_assesment ? item.agency_assesment.replaceAll("\n", "") : 'Not provided',
			// "Elegibility notes" : item.elegibility_notes ? item.elegibility_notes.replaceAll("\n", "") : 'Not provided',
			// "Sports background" : item.sports_background ? item.sports_background.repl∫aceAll("\n", " ") : 'Not provided',
			"Reason of commitment" : item.reason_commitment ? item.reason_commitment : 'Not provided',
			"Current club" : item.current_club ? item.current_club : 'Not provided',
			"Current status" : item.current_status ? item.current_status : 'Not provided',
			"Destination college" : item.destination_college ? item.destination_college : 'Not provided',
			"NCAA ID" : item.ncaa_id ? item.ncaa_id : 'Not provided',
			"Type of admission" : item.type_of_admission ? item.type_of_admission : 'Not provided',
			"Weight" : item.weight ? item.weight : 'Not provided',
			"Height" : item.height ? item.height : 'Not provided',
			"Sport" : item.sport ? item.sport : 'Not provided',
			"Position" : item.position ? item.position : 'Not provided',
			"Second position" : item.second_position ? item.second_position : 'Not provided',
			"Dominant foot" : item.dominant_foot ? item.dominant_foot : 'Not provided',
			"Featured video" : item.featured_video ? item.featured_video?.url : 'Not provided',
			"Level of competition" : item.level_of_competition ? item.level_of_competition : 'Not provided',
			"Start date" : item.start_date ? item.start_date : 'Not provided',
			"High school graduation date" : item.high_school_graduation_date ? item.high_school_graduation_date : 'Not provided',
			"SAT score" : item.sat_score ? item.sat_score : 'Not provided',
			"SAT date" : item.sat_date ? item.sat_date : 'Not provided',
			"ACT score" : item.act_score ? item.act_score : 'Not provided',
			"ACT date" : item.act_date ? item.act_date : 'Not provided',
			"GEMAT GRE score" : item.gemat_gre_score ? item.gemat_gre_score : 'Not provided',
			"GEMAT GRE date" : item.gemat_gre_date ? item.gemat_gre_date : 'Not provided',
			"TOEFL score" : item.toefl_score ? item.toefl_score : 'Not provided',
			"TOEFL date" : item.toefl_date ? item.toefl_date : 'Not provided',
			"Duolingo" : item.duolingo ? item.duolingo : 'Not provided',
			"Duolingo date" : item.duolingo_date ? item.duolingo_date : 'Not provided',
			"Ielts" : item.ielts ? item.ielts : 'Not provided',
			"Ielts date" : item.ielts_date ? item.ielts_date : 'Not provided',
			"Estimated gpa" : item.estimated_gpa ? item.estimated_gpa : 'Not provided',
			"Intended field of studies" : item.intended_field_of_studies ? item.intended_field_of_studies : 'Not provided',
			"Intended degree" : item.intended_degree ? item.intended_degree : 'Not provided',
			"Estimated budget" : item.budget ? item.budget : 'Not provided',
			"Facebook url" : item.facebook_url ? item.facebook_url : 'Not provided',
			"Twitter user" : item.twitter_user ? item.twitter_user : 'Not provided',
			"Linkedin url" : item.linkedin_url ? item.linkedin_url : 'Not provided',
		}})

	}

	// Function that handles the open in new tab action
	const handleOpenNewTab = () => {
		// Only if the user want to delete athlete/s of his agency or the user is admin
		selectItem.forEach ( (item) => {
			window.open(`/unique/show/${item.id}`,'_blank')
		})
	}


	return (
		<>
				<ConfirmModal
				title={selectItem.length === 1 ? "Delete Athlete" : "Delete Athletes"}
				description="Are you sure? You can't undo this action afterwards."
				button1="Cancel"
				button2="Delete"
				isOpen={isOpen}
				onClose={onClose}
				cancelRef={cancelRef}
				handleDelete={handleConfirmDelete}
			/>
			{
				showMoreInfo && (
					<Flex justifyContent='right' m="0px 10px 10px 0">
						<CSVLink 
							data={downloadData()}
							filename={"Athletes-AllianceAthletes.csv"}
						>
							<Button
								colorScheme='green'
								variant='ghost'
								leftIcon={<Icon as={HiDownload}/>}
								size='sm'
							>
								Export to CSV
							</Button>
						</CSVLink>
						{!noCreateButton && <CreateButton handleCreate={() => { navigate(`/admin/athletes/create`) }}/>}
						{
							selectItem.length > 0 && !noCreateButton 
							? (<>
								<DeleteButton handleDelete={handleDelete} />
								<Button
									colorScheme='yellow'
									variant='ghost'
									leftIcon={<Icon as={ImNewTab} />}
									size='sm'
									onClick={handleOpenNewTab}
                >
      	          Open in new tab
  		          </Button>
								</>
							)
							: null
						}
					</Flex>
				)
			}
			{
				noPermission
				? (
					<Alert status='error' borderRadius="10px" mb="20px">
						<AlertIcon />
						You don't have enough permissions to perform this action on athletes.
						<CloseButton position='absolute' right='8px' top='8px' onClick={()=>(setNoPermission(false))} />
					</Alert>
				)
				: null
			}
			{
				actionStatus.done && (
					<Alert status={actionStatus.status} borderRadius="10px" mb="20px" >
						<AlertIcon />
						{actionStatus.message}
						<CloseButton position='absolute' right='8px' top='8px' onClick={()=>(setActionStatus({...actionStatus,done:false}))} />
					</Alert>
				)
			}
			<Card overflowX={{ sm: "scroll", xl: "hidden" }}>
				<CardHeader p='6px 0px 15px 0px'>
					<Flex direction='row' w="100%">
						<Flex justifyContent='left' direction="column" w="50%" >
							<Text fontSize='xl' color={textColor} fontWeight='bold'>
							{title} ({searchState.searchText!== '' ? `Filtered result ${filterData(getSearchedData(data,searchState.searchText)).length}` : filterData(data).length} - {selectItem.length} selected)
							</Text>
							{
								showMoreInfo && (
									<Checkbox colorScheme='blue' onChange={()=>setMyAthletes(!myAthletes)} mt="10px">
										<Text color='gray.400' ><i> See only my athletes </i></Text>
									</Checkbox>
								)
							}

						</Flex>
						<Spacer />
						<Flex direction="column" alignItems="end">
							<SearchBar text={searchState} update={updateSearchState}/>
							<Text 
								color='royalblue.700' 
								m="10px 10% 0 0" 
								cursor="pointer" 
								onClick={()=>(setAthletesFilters({...athletesFilters, visible:0,sport:0,status:0,position:0,agency:0, semester:0,agent:'0'}))}
								fontSize="xs"
							><i> Reset all filters </i></Text>
						</Flex>
					</Flex>
				</CardHeader>
				<CardBody>
					<Table variant='simple' color={textColor}>
						<Thead>
							<Tr my='.8rem' pl='0px' color='gray.400'>
								{
									showMoreInfo && (
										<Th color='gray.400' ><Checkbox colorScheme='blue' onChange={(e)=>(handleSelectAll(e))} /></Th>
									)
								}

								<Th color='gray.400'>
									Profile pic
								</Th>
								<Th color='gray.400' cursor="pointer" onClick={()=>setSortAscending(!sortAscending)}>
									Name {sortAscending ? <Icon as={BsArrowUp} color="gray.400" /> : <Icon as={BsArrowDown} color="gray.400" />}
								</Th>
								<Th color='gray.400'>
									Agency
									<Select variant='unstyled' size="xs" value={athletesFilters.agency} onChange={(e)=>setAthletesFilters({...athletesFilters, agency:parseInt(e.target.value)})}>
										{athletesFilters.agencyData.map((opt,index) => {return (<option key={index} value={index} color="royalblue">{opt}</option>)})}
									</Select>
								</Th>
								{ showMoreInfo ? (
									<>
										<Th color='gray.400'>
											Agent<br />
											<Select variant='unstyled' size="xs" value={athletesFilters.agent} onChange={(e)=>setAthletesFilters({...athletesFilters, agent:e.target.value})}>
												{athletesFilters.agentData.map((opt,index) => {return (<option key={index} value={opt.value} color="royalblue">{opt.label}</option>)})}
											</Select>
										</Th>
										<Th color='gray.400'>
											Semester<br />
											<Select variant='unstyled' size="xs" value={athletesFilters.semester} onChange={(e)=>setAthletesFilters({...athletesFilters, semester:parseInt(e.target.value)})}>
												{athletesFilters.semesterData.map((opt,index) => {return (<option key={index} value={index} color="royalblue">{opt}</option>)})}
											</Select>
										</Th>
										<Th color='gray.400' >
											Sport<br />
											<Select variant='unstyled' size="xs" value={athletesFilters.sport} onChange={(e)=>setAthletesFilters({...athletesFilters, sport:parseInt(e.target.value)})}>
												{athletesFilters.sportData.map((opt,index) => {return (<option value={index} key={index} color="royalblue">{opt}</option>)})}
											</Select>
										</Th>
										<Th color='gray.400' >
											Position<br />
											<Select variant='unstyled' size="xs" value={athletesFilters.position} onChange={(e)=>setAthletesFilters({...athletesFilters, position:parseInt(e.target.value)})}>
												{athletesFilters.positionData.map((opt,index) => {return (<option value={index} key={index} color="royalblue">{positionAcronym(opt)||'ALL'}</option>)})}
											</Select>
										</Th>
										<Th color='gray.400' >
											Status<br />
											<Select variant='unstyled' size="xs" value={athletesFilters.status} onChange={(e)=>setAthletesFilters({...athletesFilters, status:parseInt(e.target.value)})}>
												{athletesFilters.statusData.map((opt,index) => {return (<option value={index} key={index} color="royalblue">{opt}</option>)})}
											</Select>
										</Th>
										<Th color='gray.400' >
											Visibility<br />
											<Select variant='unstyled' size="xs" value={athletesFilters.visible} onChange={(e)=>setAthletesFilters({...athletesFilters, visible:parseInt(e.target.value)})}>
												{athletesFilters.visibleData.map((opt,index) => {return (<option value={index} key={index} color="royalblue">{opt}</option>)})}
											</Select>
										</Th>	
									</>
									) : (
										<Th color='gray.400'>
											Agent
										</Th>
									)
									
								}
							</Tr>
						</Thead>
						<Tbody>
							{filterData(getSearchedData(data,searchState.searchText)).slice(pagination.lower-1,pagination.lower+pagination.rowsPerPage-1).map((row) => {
								return (
										showMoreInfo
										? ( 
											<AthletesTableRow
												key={`${row.id}`}
												id={row.id}
												profile_pic={row.profile_pic}
												name={row.name}
												start_date={startDate.find(it => it.value ===row.start_date)?.label}
												sport={row.sport}
												position={row.position}
												currentStatus={row.current_status}
												visible={row.isVisible ? 'Yes':'No'}
												agency={agencies.find(agency => agency.id === row.agencyID)}
												agent={agents.find(agent => agent.id === row.agentID)}
												selectItem={selectItem}
												setSelectItem={setSelectItem}
											/>
										)
										: (
											<AthletesTableRow
												key={`${row.id}`}
												id={row.id}
												profile_pic={row.profile_pic}
												name={row.name}
												agency={agencies.find(agency => agency.id === row.agencyID)}
												agent={row.agent}
											/>
										)
								);
							})}
						</Tbody>
					</Table>
				</CardBody>
				<Pagination totalData={filterData(getSearchedData(data,searchState.searchText)).length} rowsPerPageValues={[10,20,30,40,50]} />
			</Card>
		</>
	);
};

export default Athletes;
