import React, { useState } from "react";
// Chakra imports
import {
    Button,
    Flex,
    Icon,
    Radio,
    RadioGroup,
    ScaleFade,
    Table,
    Tbody,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import DashboardRankingTableRow from "components/Tables/DashboardRankingTableRow";
import { IoCheckmarkDoneCircleSharp } from "react-icons/io5";
  
const RankingsForAthletes = ({ title, subtitle, captions, athletes,coaches, dataToUse, dataToChange}) => {

    // console.warn("[RankingsForAthletes] Me renderizo: ",dataToChange)
    const [hasRender, setRender] = useState(false);
    const onShow = React.useCallback(() => setRender(true), []);

    const textColor = useColorModeValue("gray.700", "white");

    const [sportFilter, setSportFilter] = useState({
		'AthletesEnquiries':'Total',
		'AthletesFavorites':'Total',
	})

    const prepareData = (source) => {
		const data = []
		const seasonAthletes = athletes?.filter(athlete => athlete.sport === sportFilter[dataToChange] || sportFilter[dataToChange] === 'Total').filter(item => 
			{if (new Date().getMonth()>6) {return parseInt(item.start_date?.split("_")[1]) >= (new Date().getFullYear()+1)}
			else{return item.start_date === `SPRING_${new Date().getFullYear()}` || parseInt(item.start_date?.split("_")) >= (new Date().getFullYear()+1)}})
		const newSource = source?.filter(s => seasonAthletes.find(it => it.id === s.athleteID))

		newSource?.map(item => {
			// Sum all the enquiries
			const exist = data.find(it => it.athlete === item.athleteID)
			if(exist){
				exist.count += 1
				exist.coach = (item.createdAt > exist.lastDate) ? item.coachID : exist.coach
				exist.lastDate = (item.createdAt > exist.lastDate) ? item.createdAt : exist.lastDate
			}
			else{
				data.push({
					athlete: item.athleteID,
					coach: item.coachID,
					lastDate: new Date(item.createdAt),
					count: 1
				})
			}

		})
		return data?.sort((item1,item2)=>item2.count - item1.count)

	}

    const totalAmount= prepareData(dataToUse).length
    const lastMonthAmount=prepareData(dataToUse).filter(item => item.lastDate.getMonth() === new Date().getMonth() && item.lastDate.getFullYear() === new Date().getFullYear()).length
    const data=prepareData(dataToUse).slice(0,5)

  
    return (
        <>
            { hasRender && (
                <ScaleFade in={hasRender} >
                    <Card p='16px' overflowX={{ sm: "scroll", xl: "hidden" }}>
                        <CardHeader p='12px 0px 28px 0px'>
                            <Flex direction='column'>
                                <Text fontSize='lg' color={textColor} fontWeight='bold' pb='.5rem'>
                                {title}
                                </Text>
                                <RadioGroup onChange={(e)=>setSportFilter({...sportFilter,[dataToChange]:e})} value={sportFilter && sportFilter[dataToChange]} mb="10px">
                                    <Flex direction='row'>
                                        <Radio value='Total' mr="20px">
                                            <Text fontSize='md' fontWeight='medium' color='gray.400'>
                                            Total
                                            </Text>
                                        </Radio>
                                        <Radio value='Soccer' mr="20px">
                                            <Text fontSize='md' fontWeight='medium' color='gray.400'>
                                            Soccer
                                            </Text>
                                        </Radio>
                                        <Radio value='Tennis' mr="20px">
                                            <Text fontSize='md' fontWeight='medium' color='gray.400'>
                                            Tennis
                                            </Text>
                                        </Radio>
                                    </Flex>
                                </RadioGroup>
                                <Flex direction={{ sm: "column", xl: "row" }}>
                                    <Flex align='center' mr="20px">
                                        <Icon
                                            as={IoCheckmarkDoneCircleSharp}
                                            color='royalblue.700'
                                            w={7}
                                            h={7}
                                            pe='3px'
                                        />
                                        <Text fontSize='sm' color='gray.400' fontWeight='normal'>
                                            Total
                                            {" "}<Text fontWeight='bold' as='span'>
                                            {totalAmount ? totalAmount : 0} done
                                            </Text>
                                        </Text>
                                    </Flex>
                                    <Flex align='center'>
                                        <Icon
                                            as={IoCheckmarkDoneCircleSharp}
                                            color='royalblue.700'
                                            w={7}
                                            h={7}
                                            pe='3px'
                                        />
                                        <Text fontSize='sm' color='gray.400' fontWeight='normal'>
                                            <Text fontWeight='bold' as='span'>
                                            {lastMonthAmount ? lastMonthAmount : 0} done
                                            </Text>{" "}
                                            this month.
                                        </Text>
                                    </Flex>
                                </Flex>
                                
                            </Flex>
                        </CardHeader>
                        <Table variant='simple' color={textColor}>
                            <Thead>
                                <Tr my='.4rem' ps='0px'>
                                {captions.map((caption, idx) => {
                                    return (
                                    <Th color='gray.400' key={idx} ps={idx === 0 ? "0px" : null}>
                                        {caption}
                                    </Th>
                                    );
                                })}
                                </Tr>
                            </Thead>
                            <Tbody>
                                {data.map((row,idx) => {
                                    return (
                                        <DashboardRankingTableRow
                                            athlete={athletes.find(athlete => athlete.id === row.athlete)}
                                            coach={coaches.find(coach => coach.id === row.coach)}
                                            lastDate={row.lastDate}
                                            count={row.count}
                                            porcentage={((row.count/totalAmount)*100).toFixed(3)}
                                            key={idx}
                                        />
                                    );
                                })}
                            </Tbody>
                        </Table>
                    </Card>
                </ScaleFade>
            )}
            { !hasRender && (
                <ScaleFade in={!hasRender} >
                    <Card p='28px 10px 16px 0px'>
                        <CardHeader pl='22px' w="100%">
                            <Flex direction='row' alignSelf='flex-start' w="100%" justifyContent={'space-between'} alignItems="center">
								<Flex direction='column' alignSelf='flex-start' w="50%">
									<Text fontSize='lg' color={"gray.700"} fontWeight='bold' mb='6px'>
										{title}
									</Text>
									<Text fontSize='md' fontWeight='medium' color='gray.400'>
										{subtitle}
									</Text>
								</Flex>
								<Button w="40%" colorScheme='blue' onClick={onShow} my="10px" mr="5%">
									Load chart
								</Button>
							</Flex>
                        </CardHeader>
                    </Card>
                </ScaleFade>
            )}
        </>
    );
};

const myPropsChanged = (prevProps, nextProps) => {
    if ( prevProps !== nextProps) {
        return false;
    }
    return true;
}

export default React.memo(RankingsForAthletes, myPropsChanged);
  