// React imports
import React, { useContext, useEffect } from 'react'
import { Routes, Route } from 'react-router-dom';
// Component imports
import AuxCoachesTables from './components/AuxCoachesTables';
import { DataContext } from '../../../contexts/dataContext'

export const AuxCoachesRoutes = () => {
    // Get data from context
    const dataContext = useContext(DataContext)
    const { auxCoaches, coaches, coachesCognito } = dataContext

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const getAuxDataWithExistingCoaches = () => {
        let newData = []
        let is_in_app = 'NO'
        let is_registered = 'NO'

        auxCoaches.map(item =>{
            // console
            // console.log("Correo.    _",item.contact_email,'_')
            // console.log("Encuentro su correo en coaches.    ",coaches.find(it=>it.contact_email === item.contact_email))
            // console.log("Encuentro su correo en cognito.    ",coachesCognito.find(it=>it.Attributes.find(it => it['Name']==="email")['Value'] === item.contact_email))
            // console.log("Veo si está activo.    ",coachesCognito.find(it=>it.Attributes.find(it => it['Name']==="email")['Value'] === item.contact_email)?.Attributes.find(it => it['Name']==="custom:isActive")['Value'])

            if(coaches.find(c=>c.contact_email.includes(item.contact_email))){
                is_in_app = 'YES'
            }
            if( coachesCognito.find(it=>it.Attributes.find(it => it['Name']==="email")['Value'].includes(item.contact_email) && it.Attributes.find(it => it['Name']==="custom:isActive")['Value']) ){
                
                is_registered = 'YES'
            }
            newData.push({in_app: is_in_app, registered: is_registered,...item})
            is_in_app = 'NO'
            is_registered = 'NO'
        })

        return newData
    }

    return (
        <>
            <Routes>
                <Route path="/" element={<AuxCoachesTables coaches={getAuxDataWithExistingCoaches()}/>} />
                {/* <Route path="/show/:coachId" element={<ShowCoach />} />
                <Route path="/edit/:coachId" element={<EditCoach />} />
                <Route path="/activity/:coachId" element={<Activity />} />
                <Route path="/create" element={<CreateCoach />} /> */}
            </Routes>
        </>
    )
}
