import React from 'react'
import Card from "components/Card/Card";
import Chart from "react-apexcharts";

const BarChart = ({data,options}) => {

	return (
	  <Card
		py="1rem"
		height={{ sm: "350px" }}
		width="100%"
		// bg="linear-gradient(0deg,rgba(39,67,150,1) 0%, rgba(65,105,225,1) 10%, rgba(152,166,251,1) 100%)"
		position="relative"
	  >
		<Chart
		  options={options}
		  series={data}
		  type="bar"
		  width="100%"
		  height="100%"
		/>
	  </Card>
	);
}

const myPropsChanged = (prevProps, nextProps) => {
    if ( prevProps.data !== nextProps.data ) {
        return false;
    }
    return true;
}

export default React.memo(BarChart, myPropsChanged);
  