// React imports
import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom'
// Chakra imports
import {
	Image,
	Icon,
	Button,
	Flex,
	Td,
	Text,
	Tr,
	useColorModeValue,
	Checkbox,
} from "@chakra-ui/react";
import {roles} from "../../../../helpers";



function CoachesTableRow({ id, name, contact_email, role, sport,division,team,team_category,gender,createdAt, status, activeProfile, actions,selectItem, setSelectItem}) {
	// Chakra color mode
	const textColor = useColorModeValue("gray.700", "white");
	const titleColor = useColorModeValue('royalblue.700');
	// navigate hook
	let navigate = useNavigate()

	// Function that handles the selection of an item
	const handleSelectItem = (e) => {
		setSelectItem(e.target.checked ? [...selectItem, id] : selectItem.filter(item => item !== id))
	}

	const changeText = {
		'FORCE_CHANGE_PASSWORD': 'Not login yet',
		'UNCONFIRMED': 'Unconfirmed',
		'CONFIRMED': 'Confirmed',
		'FREE_PERIOD': 'Free user',
		'PREMIUM_PASS': 'Premium user',
		'SEASON_PASS': 'Trial user'
	}

	const [isSelected, setIsSelected] = useState(false)
	useEffect(() => {
		selectItem?.find((item) => item === id) ? setIsSelected(true) : setIsSelected(false)
	}, [selectItem])
	
	return (
		<Tr>
			{
				selectItem && (
					<Td>
						<Checkbox colorScheme='blue' onChange={(e)=>(handleSelectItem(e))} isChecked={isSelected} />
					</Td>
				)
			}
			{/* First column */}
			<Td onClick={()=>(navigate(`/admin/coaches/show/${id}`))} style={{cursor:"pointer"}}>
				<Text fontSize="sm" color={textColor} fontWeight="bold" >
					{name}
				</Text>
				{
					sport && (
						<>
							<Text fontSize="sm" color="gray.400" fontWeight="normal">
								{contact_email}
							</Text>
							<Text fontSize="sm" color="gray.400" fontWeight="normal">
								Role: {roles.map((r) => (r.value === role ? r.label : null))}
							</Text>
							<Text fontSize="sm" color="gray.400" fontWeight="normal">
								Division: {division}
							</Text>
						</>
					)
				}
			</Td>

			{/* Second column */}
			{
				sport && (
					<>
						<Td>
							<Text fontSize="sm" color="gray.400" fontWeight="normal">
								{actions ? actions : '0'}
							</Text>
						</Td>
						<Td >
							<Text fontSize="sm" color="gray.400" fontWeight="normal">
								{sport}
							</Text>
						</Td>
						{/* <Td >
							<Text fontSize="sm" color="gray.400" fontWeight="normal">
								{division}
							</Text>
						</Td> */}
						<Td onClick={()=>( team?.id && navigate(`/admin/teams/show/${team?.id}`))} style={{cursor:"pointer"}}>
							<Text fontSize="sm" color="gray.600" fontWeight="normal">
								{team?.name || 'Not specified'}
							</Text>
							<Text fontSize="sm" color="gray.400" fontWeight="normal">
								{gender ? `${gender[0].toUpperCase()+gender.slice(1)}'s Sport` : "Not especified"}
							</Text>
						</Td>
						<Td >
							<Text fontSize="sm" color="gray.400" fontWeight="normal">
								{team_category ? (team_category.includes('Not found') ? 'Not specified' : team_category) : 'Not specified'}
							</Text>
						</Td>
						<Td >
							<Text fontSize="sm" color="gray.400" fontWeight="normal">
								{new Date(createdAt).toLocaleDateString()}
							</Text>
						</Td>
						<Td >
							<Text fontSize="sm" color="gray.500" fontWeight="normal">
								<strong>{changeText[status]}</strong>
							</Text>
							<Text fontSize="sm" color="gray.400" fontWeight="normal">
								Profile <strong>{activeProfile}</strong>
							</Text>
							<Text fontSize="sm" color="gray.400" fontWeight="normal">
								{changeText[team?.suscription_name] || 'Not specified'}
							</Text>
						</Td>
					</>
				)
			}
			{/* When we do not need more info about the coach --> teams section */}
			{
				!sport && (
					<>
						<Td maxWidth={{ sm: "270px" }} onClick={()=>(navigate(`/admin/coaches/show/${id}`))} style={{cursor:"pointer"}}>
							<Text fontSize="sm" color="gray.400" fontWeight="normal">
								{contact_email}
							</Text>
						</Td>
						<Td>
							<Text fontSize="sm" color="gray.400" fontWeight="normal">
								{roles.map((r) => (r.value === role ? r.label : null))}
							</Text>
						</Td>
						<Td>
							<Text fontSize="sm" color="gray.400" fontWeight="normal">
								{actions}
							</Text>
						</Td>
					</>
				)
			}

		</Tr>
	);
}

export default CoachesTableRow;
