export const listAgencys = /* GraphQL */ `
  query ListAgencys(
    $filter: ModelAgencyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAgencys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        logo
        country
        contact_email
        contact_phone
        website
        sport
        agents {
          items {
            id
            name
            profile_pic
            birthdate
            gender
            contact_email
            contact_phone
            agencyID
            agent_role
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;

export const getAgency = /* GraphQL */ `
  query GetAgency($id: ID!) {
    getAgency(id: $id) {
      id
      name
      logo
      country
      contact_email
      contact_phone
      website
      sport
      agents {
        items {
          id
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;

export const createAgency = /* GraphQL */ `
  mutation CreateAgency(
    $input: CreateAgencyInput!
    $condition: ModelAgencyConditionInput
  ) {
    createAgency(input: $input, condition: $condition) {
      id
      name
      logo
      country
      contact_email
      contact_phone
      website
      sport      
      createdAt
      updatedAt
    }
  }
`;

export const updateAgency = /* GraphQL */ `
  mutation UpdateAgency(
    $input: UpdateAgencyInput!
    $condition: ModelAgencyConditionInput
  ) {
    updateAgency(input: $input, condition: $condition) {
      id
      name
      logo
      country
      contact_email
      contact_phone
      website
      sport
      createdAt
      updatedAt
    }
  }
`;

export const deleteAgency = /* GraphQL */ `
  mutation DeleteAgency(
    $input: DeleteAgencyInput!
    $condition: ModelAgencyConditionInput
  ) {
    deleteAgency(input: $input, condition: $condition) {
      id
      name
      logo
      country
      contact_email
      contact_phone
      website
      sport
      createdAt
      updatedAt
    }
  }
`;