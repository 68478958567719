export const tutorialsData = [
    {
        title:"7th Alliance Athletes App Workshop (February 2024)",
        description: "Recording of the Alliance Athletes App Workshop (held on 8th February 2024). This meeting was focused (i) App overview & news (ii) Agent experience & issues.",
        item:"https://res.cloudinary.com/dsvz8cktr/video/upload/v1707812181/Corporate%20videos/Alliance_Athletes_App_Workshop_-_Soccer_February_2024_-20240208_160042-Meeting_Recording_fggfih.mp4",
        date:"2024-02-13",
        category:"Updates",
        type:"green",
        author:"Adrian K.",
    },
    {
        title:"6th Alliance Athletes App Workshop (November 2023)",
        description: "Recording of the Alliance Athletes App Workshop (held on 2nd November 2023). This meeting was focused on (i) App overview (ii) Coach Issues & (ii) News & updates.",
        item:"https://res.cloudinary.com/dsvz8cktr/video/upload/v1698923453/Corporate%20videos/Alliance_Atheltes_App_-_Agent_Workshop_Soccer_-20231102_110140-Meeting_Recording_fzg2ek.mp4",
        date:"2023-05-16",
        category:"Updates",
        type:"green",
        author:"Adrian K.",
    },
    {
        title:"5th Alliance Athletes App Workshop (May 2023)",
        description: "Recording of the Alliance Athletes App Workshop (held on 16 May 2023). This meeting was focused on (i) Dashboard/App updates and functionality and (ii) Athlete & Coach data/statistics",
        item:"https://res.cloudinary.com/dsvz8cktr/video/upload/v1684751386/Corporate%20videos/Alliance_Athletes_App_Workshop-20230516_160144-Meeting_Recording_mmumy9.mp4",
        date:"2023-05-16",
        category:"Updates",
        type:"green",
        author:"Adrian K.",
    },
    {
        title:"Profile Management - COMMITTED PSA",
        description: "In this video, we explain how to manage profiles of your committed PSAs.",
        item:"https://res.cloudinary.com/dsvz8cktr/video/upload/v1678361866/Corporate%20videos/Committed_PSA_wt26n9.mp4",
        date:"2023-03-09",
        category:"Guidelines",
        type:"orange",
        author:"Adrian K.",
    },
    {
        title:"4th Alliance Athletes App Workshop (February 2023)",
        description: "Recording of the Alliance Athletes App Workshop (held on 1 February 2023). This meeting was focused on (i) Premium Pass and (ii) Sales Plan",
        item:"https://res.cloudinary.com/dsvz8cktr/video/upload/v1675328746/Corporate%20videos/Alliance_Athletes_App_-_Premium_Pass_Sales_Plan-20230201_170127-Meeting_Recording_lhoyrc.mp4",
        date:"2023-02-01",
        category:"Premium Pass",
        type:"yellow",
        author:"Adrian K.",
    },
    {
        title:"3rd Alliance Athletes App Workshop (October 2022)",
        description: "Recording of the Alliance Athletes App Workshop (held on 27th October 2022). This meeting was focused on (i) Updates & new features (ii) PSA Profiles (iii) PSA & Coach trends (iv) Coach registrations (v) Tutorials (vi) Q&A.",
        item:"https://res.cloudinary.com/dsvz8cktr/video/upload/v1667208273/Corporate%20videos/Alliance_Athletes_App_Workshop_October_-20221027_160200-Meeting_Recording_n9xo41.mp4",
        date:"2022-10-27",
        author:"Adrian K.",
    },
    {
        title:"Alliance Athletes App Workshop - Tennis (October 2022)",
        description:"Recording of the Alliance Athletes App Workshop - Tennis (held on 11th October 2022). This meeting was focused on (i) Tennis app updates (ii) PSA profile creation (iii) Deep-linking (iv) Coach registrations (v) Profiles updates for 22/23 (vi) Q&A.",
        item:"https://res.cloudinary.com/dsvz8cktr/video/upload/v1665659354/Corporate%20videos/Alliance_Athletes_App_-_Tennis-20221011_170247-Meeting_Recording_jlsxj8.mp4",
        date:"2022-10-12",
        author:"Adrian K.",
    },
    {
        title:"2nd Alliance Athletes App Workshop (September 2022)",
        description:"Recording of the Alliance Athletes App Workshop (held on 22nd September 2022). This meeting was focused on (i) Coach Activities & Athlete Enquiries (ii) Tutorials (iii) Coach registration + 'Privacy Terms' (iv) Deep-linking (v) Profiles updates for 22/23 (vi) Q&A.",
        item:"https://res.cloudinary.com/dsvz8cktr/video/upload/v1664176242/Corporate%20videos/Alliance_Athletes_App-20220922_140404-Meeting_Recording_qtrkt1.mp4",
        date:"2022-09-22",
        author:"Adrian K.",
    },
    {
        title:"Alliance Athletes App Workshop",
        description:"Recording of the Alliance Athletes App Workshop (held on 18th August 2022). This meeting was focused on (i) new coach registrations, (ii) setting up new athlete profiles (on the dashboard) and (iii) Q&A section.",
        item:"https://res.cloudinary.com/dsvz8cktr/video/upload/v1661413936/Corporate%20videos/Alliance_Athletes_App_Workshop-20220818_140307-Meeting_Recording_fveka7.mp4",
        date:"2022-08-25",
        author:"Adrian K.",
    },
    {
        title:"Changes to the Level of competition",
        description:"There has been a couple of changes regarding the Level of competition field. These changes are: 1. Level of competition is now required field. 2. Level of competition field will now automatically list all the levels/options based on the country of competition and gender of an athlete you selected.",
        category:"New update",
        type:"green",
        item:"https://res.cloudinary.com/dsvz8cktr/video/upload/v1660640426/Corporate%20videos/Level_of_competition_btuc8i.mp4",
        date:"2022-08-16",
        author:"Adrian K.",
    },
    {
        title:"Athlete profile guidelines",
        description:"The following document explains in detail the purpose of some of the main fields as well as provides a recommendation on how to fill them in.",
        category:"Guidelines",
        type:"orange",
        item:"https://res.cloudinary.com/dsvz8cktr/image/upload/v1657197242/Corporate%20videos/Alliance_Athletes_Profile_Guidelines_v.2.docx_-_Documentos_de_Google_jkjfpi.pdf",
        date:"2022-07-07",
        author:"Oscar González",
        format:"pdf"
    },            
    {
        title:"Deep Linking",
        description:"Announcing a new feature, Deep Linking. With this feature, we can share and visualise the information of any PSA on any device.",
        category:"New feature",
        type:"green",
        item:"https://res.cloudinary.com/dsvz8cktr/video/upload/v1655808065/Corporate%20videos/Deep-linking_kqrrcq_cjqmfw.mp4",
        date:"2022-06-14",
        author:"Adrian K."
    },            
    {
        title:"Data analytics from the app",
        description:"Let's learn from the data. The new dashboard will show you some charts and graphs to help you understand the data about interactions with PSAs, how coaches use the app and so on.",
        category:"Interesting",
        type:"telegram",
        item:"https://res.cloudinary.com/dsvz8cktr/video/upload/v1654596756/Corporate%20videos/Using%20data%20analytics%20from%20the%20app.mp4",
        date:"2022-06-07",
        author:"Adrian K."
    },
    {
        title:"Standards for athlete profiles",
        description:"In this video we explain how to create a profile for an athlete. We need to provide some standard information for the athlete to be able to improve the quality of the application.",
        category:"Guidelines",
        type:"orange",
        item:"https://res.cloudinary.com/dsvz8cktr/video/upload/v1655806038/Corporate%20videos/Standard_for_Athlete_Profiles_qroqot_q04xap.mp4",
        date:"2022-06-07",
        author:"Adrian K.",
    }, 
    {
        title:"Introduction of the new dashboard",
        description:"Welcome to the new dashboard. This dashboard is a new way to see the data analytics from the application. We provide a new user experience for the dashboard that is more user friendly and easy to use.",
        item:"https://res.cloudinary.com/dsvz8cktr/video/upload/v1654596751/Corporate%20videos/Introduction%20of%20the%20new%20dashboard.mp4",
        date:"2022-06-07",
        author:"Adrian K.",
    },
    {
        title:"Alliance Athletes video",
        description:"We’re gathering all the qualified international recruits from college recruiting agencies on one platform. A true game-changer for coaches. Download the Alliance Athletes App for free to find the most suitable international student-athletes to your team.",
        item:"https://res.cloudinary.com/dsvz8cktr/video/upload/v1632988089/Corporate%20videos/AAApp_Video_V2-2_Final_ysv0rp.mp4",
        date:"2021-10-31",
        author:"Alliance Athletes",
    },
];
