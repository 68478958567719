// React imports
import React, { useContext, useState, useEffect} from 'react'
import { useParams, useNavigate } from 'react-router-dom';
// Context imports
import { DataContext } from "../../../../contexts/dataContext";
// React-select imports
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
// Chackra Components
import { Flex, useColorModeValue, Text, FormControl,FormErrorMessage, Input,Spacer, Box, Button, ButtonGroup, Tooltip, Icon } from "@chakra-ui/react";
import { BsQuestionCircleFill } from "react-icons/bs";

// Auxiliary imports
import { countries } from "../../../../helpers"
import { uploadImageToCloudinary } from "../../../../helpers/uploadImageToCloudinary";
import { updateAgencyService } from "../../../../services/agencyServices";
import validator from "validator";
// Component 
import { ImageDropzone } from "../../../../components/Dropzone/ImageDropzone";

export const EditAgency = () => {

	const textColor = useColorModeValue("gray.700", "white");
	const animatedComponents = makeAnimated();

	const {agencyId} = useParams();
	let navigate = useNavigate()

	// Take the context data needed in this component
    const dataContext = useContext(DataContext)
    const { agencies, setAgencies } = dataContext
    const agency = agencies.find(agency => agency.id === agencyId)

	// The initial state of the form with the data of the agency
	const initialState = {
		id: agency.id,
		name: agency.name,
		logo: agency.logo,
		website: agency.website ? agency.website : '',
		contact_email: agency.contact_email ? agency.contact_email : '',
		contact_phone: agency.contact_phone ? agency.contact_phone : '',
		country: agency.country,
		sport: agency.sport,
		createdAt: agency.createdAt,
		updatedAt: agency.updatedAt,
		agents:agency.agents
	}

	// State that controls the form values
	const [formValues, setFormValues] = useState(initialState)
	// State to check if form has changed and enable submit button
	const [formHasChanged, setFormHasChanged] = useState(false)
	// State that controls if the submit is loading
	const [isLoading, setIsLoading] = useState(false)
	// State with form errors 
	const [errors, setErrors] = useState({
		email: '',
		phone: '',
		website: '',
	})

	// When the form values change, check that the mandatory fields are filled and enable the submit button
	useEffect(() => {
		JSON.stringify(formValues) !== JSON.stringify(initialState) && formValues.name !== '' ? setFormHasChanged(true) : setFormHasChanged(false)
	}, [formValues])

	// Function that handles the change of the country select
	const handleChange = (selectedOptions) => {
		setFormValues(()=>({...formValues, country: selectedOptions.map(c => c.value)}))
	}

	const onChange = (e) => {
        e.persist()
        setFormValues(() => ({ ...formValues, [e.target.name]: e.target.value }))
    }

	// Function that submit the form. It checks if the user has provided a new logo and upload it to cloudinary
	const handleEdit = () => {

		setIsLoading(true)
		let thereAreErrors = {
			email: '',
			phone: '',
			website: '',
		}
		// Validate data (contact email, contact phone, website)
		if(formValues['contact_email'] && formValues.contact_email !== "" && !validator.isEmail(formValues.contact_email)){
			setIsLoading(false)
			thereAreErrors = {...thereAreErrors, email: 'Please enter a valid email'}
		}
		
		
		if(formValues['contact_phone'] && formValues.contact_phone !== "" && !validator.isMobilePhone(formValues.contact_phone, 'any',{ strictMode: true })){
			setIsLoading(false)
			thereAreErrors = {...thereAreErrors, phone: 'Please enter a valid phone number'}
		}
		
		if(formValues['website'] && formValues.website !== "" && !validator.isURL(formValues.website)){
			setIsLoading(false)
			thereAreErrors = {...thereAreErrors, website: 'Please enter a valid website'}
		}


		setErrors(thereAreErrors)
		// If there aren't errors, check if there is an image to upload, if so, upload it to cloudinary and create agency
		if(thereAreErrors.email === "" && thereAreErrors.phone === "" && thereAreErrors.website === ""){
			
			// If the user leaves fields blank, we set it to null so that the dynamo update is correct. 
			const {createdAt,updatedAt,agents,...agencyWithoutEmptyValues} = Object.keys(formValues).reduce((obj, key) => {
				formValues[key] !== "" ? obj[key] = formValues[key] : obj[key] = null
				return obj
			}, {})

			
			if(formValues.logo !== initialState.logo){
				uploadImageToCloudinary({image:formValues.logo,directory:'agency_logos'})
				.then((url) => {
					updateAgencyService({ ...agencyWithoutEmptyValues, logo: url })
					setAgencies(
						agencies.map(agency => {
							if(agency.id === agencyWithoutEmptyValues.id){
								return{ ...agencyWithoutEmptyValues, logo: url,createdAt:createdAt,updatedAt:updatedAt,agents:agents }
							}
							return agency
						})
					)
				})
				
			}
			else{
				updateAgencyService({ ...agencyWithoutEmptyValues})
				setAgencies(
					agencies.map(agency => {
						if(agency.id === agencyWithoutEmptyValues.id){
							return {...agencyWithoutEmptyValues,createdAt:createdAt,updatedAt:updatedAt,agents:agents }
						}
						return agency
					})
				)
			}
	
			setIsLoading(false)
			navigate(`/admin/agencies/show/${agencyId}`)
		}
		
	}


	return (
		<Box mt={{ base: "120px", md: "90px" }} borderRadius="md" boxShadow="md" bg="white" >
			{/* Header */}
			<Flex justifyContent='left' p='15px 0px 10px 20px' >
				<Text fontSize='xl' color={textColor} fontWeight='bold' mr="200px">
					Edit agency - <i>{agency.name}</i>
				</Text>
			</Flex>

			{/* Form of their attributes */}
			<Flex direction='column' w="100%" alignContent="center" px="20px"  > 
				
				{/* First row */}
				<Flex direction={{ sm: "column", lg: "row" }} w="100%">
					<Flex w={{ sm: "100%", lg: "45%" }} mt="20px">
						<FormControl isInvalid={formValues.name === ""}>
							<Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mr="15px" fontWeight='semibold' textAlign="justify">
								Name
							</Text>
							<Input isRequired={true} onChange={onChange} variant='flushed' placeholder='Name' size='sm' value={formValues.name} name="name"/>
							{formValues.name === "" ? <FormErrorMessage>This field is mandatory</FormErrorMessage> : null}
						</FormControl>
					</Flex>
					<Spacer />
					<Flex alignItems='left' direction={'column'} w={{ sm: "100%", lg: "45%" }} mt="20px">
						<FormControl isInvalid={errors.email !== ''}>
							<Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mr="15px" fontWeight='semibold' textAlign="justify">
								Email
							</Text>
							<Input onChange={onChange} variant='flushed' placeholder='Contact email' size='sm' value={formValues.contact_email} name="contact_email"/>
							{errors.email !== '' ? <FormErrorMessage>{errors.email}</FormErrorMessage> : null}
						</FormControl>
					</Flex>
				</Flex>


				{/* Second row */}
				<Flex direction={{ sm: "column", lg: "row" }} w="100%" >
					<Flex alignItems='left' direction={'column'} w={{ sm: "100%", lg: "45%" }} mt="20px">
						<FormControl isInvalid={errors.phone !== ''}>
							<Flex direction="row" alignItems="center">
								<Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mr="15px" fontWeight='semibold' textAlign="justify">
									Phone
								</Text>
								<Tooltip label={`Enter a phone number using the format: [+][country code][phone number]`} bg='royalblue.700'  placement='auto' >
									<span><Icon as={BsQuestionCircleFill} color="royalblue.700" /></span>
								</Tooltip>
							</Flex>
							<Input onChange={onChange} variant='flushed' placeholder='+16175551212' size='sm' value={formValues.contact_phone} name="contact_phone"/>
							{errors.phone !== '' ? <FormErrorMessage>{errors.phone}</FormErrorMessage> : null}
						</FormControl>
					</Flex>
					<Spacer />
					<Flex alignItems='left' direction={'column'} w={{ sm: "100%", lg: "45%" }} mt="20px">
						<FormControl isInvalid={errors.website !== ''}>
							<Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mr="15px" fontWeight='semibold' textAlign="justify">
								Website
							</Text>
							<Input onChange={onChange} variant='flushed' placeholder='Website' size='sm' value={formValues.website} name="website"/>
							{errors.website !== '' ? <FormErrorMessage>{errors.website}</FormErrorMessage> : null}
						</FormControl>
					</Flex>
				</Flex>


				{/* Third row with country selector */}
				<Flex direction={{ sm: "column", lg: "row" }} w="100%" >
					<Flex direction="column" w={{ sm: "100%", lg: "45%" }} mt="20px">
						<Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mb="5px" fontWeight='semibold' textAlign="justify">
							Countries
						</Text>
						<Select
							closeMenuOnSelect={false}
							components={animatedComponents}
							defaultValue={formValues.country && formValues.country.map(c => ({value: c,label: c}))}
							placeholder="Select countries"
							isSearchable={true}
							isMulti
							options={countries}
							onChange={(selectedOptions) => handleChange(selectedOptions)}
						/>
					</Flex>
					<Spacer />
					<Flex direction="column" w={{ sm: "100%", lg: "45%" }} mt="20px">
						<Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mb="5px" fontWeight='semibold' textAlign="justify">
							Sport
						</Text>
						<Select
							closeMenuOnSelect={false}
							components={animatedComponents}
							placeholder="Select sport"
							isSearchable={true}
							defaultValue={formValues.sport && {value:formValues.sport,label:formValues.sport}}
							options={[{value:"Soccer",label:"Soccer"},{value:"Tennis",label:"Tennis"},{value:"Both",label:"Both"}]}
							onChange={(selectedOptions) => setFormValues(()=>({...formValues, sport: selectedOptions.value}))}
						/>
					</Flex>
				</Flex>


				{/* Fourth row with drag and drop for logo*/}
				<Flex direction="column" w="100%" mt="30px">
					<Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mb="5px" fontWeight='semibold' textAlign="justify">
						Logo
					</Text>
					
					{/* Component with dropzone for images */}
					<ImageDropzone values={formValues} updateValues={setFormValues}/>
				</Flex>
			</Flex> 


			{/* Confirm and cancel buttons */}
			<Flex justifyContent='right' p='10px 40px 10px 0px' >
				<Flex direction="column" my={5}>
					<ButtonGroup spacing="6" justifyContent='right' mb="10px">

						<Button
							isLoading={isLoading}
							loadingText='Submitting'
							colorScheme='green'
							variant='outline'
							onClick={handleEdit}
							isDisabled={!formHasChanged}
						>
							Submit
						</Button>
						<Button
							colorScheme='red'
							variant='outline'
							onClick={()=>(navigate(`/admin/agencies/show/${agencyId}`))}
							>
							Cancel
						</Button>

					</ButtonGroup>
					<Text fontSize={{ sm: "xs", lg: "sm" }} color={"gray.400"}>
						<i>You must modify some fields to enable submit button*</i>
					</Text>
				</Flex>
			</Flex>
		</Box>
				
	);
}
