// React imports
import React, { useContext, useEffect } from 'react'
import { Routes, Route } from 'react-router-dom';
// Context imports
import { DataContext } from '../../../contexts/dataContext'
// Component imports
import TeamsTables from './components/TeamsTables';
import { ShowTeam } from './components/ShowTeam';
import { EditTeam } from './components/EditTeam';
import { CreateTeam } from './components/CreateTeam';

export const TeamsRoutes = () => {
    const dataContext = useContext(DataContext)
    const { teams } = dataContext

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <Routes>
                <Route path="/" element={<TeamsTables teams={teams} showMoreInfo={true} />} />
                <Route path="/show/:teamId" element={<ShowTeam />} />
                <Route path="/edit/:teamId" element={<EditTeam />} />
                <Route path="/create" element={<CreateTeam />} />
            </Routes>
        </>
    )
}
