// Chakra imports
import {
	Box,
	Button,
	Flex,
	FormControl,
	FormLabel,
	FormErrorMessage ,
	Input,
	Link,
	Text,
	useColorModeValue,
	Alert,
	AlertIcon,
	AlertTitle,
	AlertDescription,
} from "@chakra-ui/react";
// Assets
import BgSignUp from "assets/img/BgSignUp.png";
import React, {useContext, useState, useEffect} from "react";
import { DataContext } from '../../contexts/dataContext';
import { useNavigate } from 'react-router-dom'
import validator from "validator";

const initialFormState = {
    username: '',
    formType: 'ForgotPassword',
}

function ForgotPassword() {


	const titleColor = useColorModeValue("royalblue.700");
	const textColor = useColorModeValue("gray.400", "white");
	const bgColor = useColorModeValue("white", "gray.700");

	let navigate = useNavigate();

	const dataContext = useContext(DataContext)
    const { authForgotPassword, user, error, updateError} = dataContext
	const [formState, updateFormState] = useState(initialFormState)

	if (user !== 'notLoggedIn') {
        // If the user is logged in, redirect to the app
        navigate('/admin', { replace: true })
    }

	const onChange = (e) => {
        e.persist()
        updateFormState(() => ({ ...formState, [e.target.name]: e.target.value }))
    }

	useEffect(() => {
		updateError('')
	}, [])


	const handleSubmit = (e) => {
		e.preventDefault();

		authForgotPassword(formState.username)
		.then( navigate('/auth/reset-password') )
        
	}


	return (
		<Flex
			direction='column'
			alignSelf='center'
			justifySelf='center'
			overflow='hidden'
		>

			<Box
				position='absolute'
				minH={{ base: "70vh", md: "50vh" }}
				w={{ md: "calc(100vw - 50px)" }}
				borderRadius={{ md: "15px" }}
				left='0'
				right='0'
				bgRepeat='no-repeat'
				overflow='hidden'
				zIndex='-1'
				top='0'
				bgImage={BgSignUp}
				bgSize='cover'
				mx={{ md: "auto" }}
				mt={{ md: "14px" }} />


			<Flex
				direction='column'
				textAlign='center'
				justifyContent='center'
				align='center'
				mt='6.5rem'
			>

				<Text fontSize='4xl' color='white' fontWeight='bold'>
					Forgot Password
				</Text>

				<Text
					fontSize='md'
					color='white'
					fontWeight='normal'
					mt='10px'
					w={{ base: "90%", sm: "60%", lg: "40%", xl: "30%" }}>
					Enter your email address to send you a verification code. 
					<br/>If you already have one, you can proceed.
				</Text>

			</Flex>

			<Flex alignItems='center' justifyContent='center' mb='60px' mt='20px'>

				<Flex
					direction='column'
					w='445px'
					background='transparent'
					borderRadius='15px'
					p='40px'
					mx={{ base: "100px" }}
					bg={bgColor}
					boxShadow='0 20px 27px 0 rgb(0 0 0 / 5%)'
				>

					{error && (

					<Alert status='error' mb="15px" borderRadius="10px">
						<AlertIcon />
						<Box flex='1'>
							<AlertTitle>Sorry!</AlertTitle>
							<AlertDescription display='block'>
								{error}
							</AlertDescription>
						</Box>
					</Alert>
					)}		
					<FormControl isInvalid={formState.username!=="" && !validator.isEmail(formState.username)}>

						<FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
							Email
						</FormLabel>
						<Input
							fontSize='sm'
							ms='4px'
							variant='flushed'
							type='email'
							placeholder='Your email address'
							size='lg'
							name="username"
							value={formState.username}
							onChange={onChange}
						/>
						{formState.username!=="" && !validator.isEmail(formState.username) && (<FormErrorMessage>Please enter a valid email</FormErrorMessage>)}
					</FormControl>
					<Button
						type='submit'
						bg='royalblue.700'
						fontSize='14px'
						color='white'
						fontWeight='bold'
						w='100%'
						h='45'
						my='24px'
						_hover={{
							bg: "blue.200",
						}}
						_active={{
							bg: "blue.400",
						}}
						isDisabled={formState.username==="" || !validator.isEmail(formState.username)}
						onClick={handleSubmit}	
					>
						SEND CODE
					</Button>

					<Flex
						flexDirection='column'
						justifyContent='center'
						alignItems='center'
						maxW='100%'
						mt='0px'>
						<Text color={textColor} fontWeight='medium'>
							Already have a code?
							<Link
								color={titleColor}
								as='span'
								ms='5px'
								onClick={()=>{navigate('/auth/reset-password')}}
							>
								Proceed
							</Link>
						</Text>
					</Flex>
					<Flex
						flexDirection='column'
						justifyContent='center'
						alignItems='center'
						maxW='100%'
						mt='5px'>
						<Text color={textColor} fontWeight='medium'>
							Have an account?
							<Link
								color={titleColor}
								as='span'
								ms='5px'
								onClick={()=>{navigate('/auth/signin')}}
							>
								Log in
							</Link>
						</Text>
					</Flex>
				</Flex>
			</Flex>
		</Flex>
	);
}

export default ForgotPassword;
