import React from 'react'
// Chakra imports
import { Box, Flex, Text, useColorModeValue, Icon, RadioGroup, Radio } from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import { IoCheckmarkDoneCircleSharp, IoCalendarSharp } from "react-icons/io5";
import { MdFiberNew } from "react-icons/md";

const LineChartSummary = ({title, amount, chart, sport, setSport,dataToChange}) => {
    const textColor = useColorModeValue("gray.700", "white");

    // console.log("me estoy renderizando [Line chart Summary]:",sport && sport[dataToChange])

    return (
        <Card p='28px 10px 16px 0px' mb="30px" >
            <CardHeader mb='20px' pl='22px'>
                <Flex direction='column' alignSelf='flex-start'>
                    <Text fontSize='lg' color={textColor} fontWeight='bold' mb='6px'>
                        {title}
                    </Text>
                    <RadioGroup onChange={(e)=>setSport({...sport,[dataToChange]:e})} value={sport && sport[dataToChange]} mb="10px">
                        <Flex direction='row'>
                            <Radio value='Total' mr="20px">
                                <Text fontSize='md' fontWeight='medium' color='gray.400'>
                                Total
                                </Text>
                            </Radio>
                            <Radio value='Soccer' mr="20px">
                                <Text fontSize='md' fontWeight='medium' color='gray.400'>
                                Soccer
                                </Text>
                            </Radio>
                            <Radio value='Tennis' mr="20px">
                                <Text fontSize='md' fontWeight='medium' color='gray.400'>
                                Tennis
                                </Text>
                            </Radio>
                        </Flex>
                    </RadioGroup>
                    {
                        amount.map((item, index) => (
                            <Flex direction={{ sm: "column",md:"row", xl: "row" }} justifyContent="space-between" key={index} >
                                <Flex align='center' mr="20px">
                                    <Icon
                                        as={IoCheckmarkDoneCircleSharp}
                                        color={item.color} w={7} h={7} pe='3px'
                                    />
                                    <Text fontSize='sm' color='gray.400' fontWeight='normal'>
                                        <Text fontWeight='bold' as='span'>{item.total} {item.title}</Text>
                                    </Text>
                                </Flex>
                                <Flex align='center' mr="20px">
                                    <Icon
                                        as={IoCalendarSharp}
                                        color={item.color} w={7} h={7} pe='3px'
                                    />
                                    <Text fontSize='sm' color='gray.400' fontWeight='normal'>
                                        <Text fontWeight='bold' as='span'>{item.totalThisYear} {item.title}</Text>{" "}this year
                                    </Text>
                                </Flex>
                                <Flex align='center' mr="20px">
                                    <Icon
                                        as={IoCalendarSharp}
                                        color={item.color} w={7} h={7} pe='3px'
                                    />
                                    <Text fontSize='sm' color='gray.400' fontWeight='normal'>
                                        <Text fontWeight='bold' as='span'>{item.totalThisMonth} {item.title}</Text>{" "}this month
                                    </Text>
                                </Flex>
                                <Flex align='center' mr="20px">
                                    <Icon
                                        as={MdFiberNew}
                                        color={item.color} w={7} h={7} pe='3px'
                                    />
                                    <Text fontSize='sm' color='gray.400' fontWeight='normal'>
                                        <Text fontWeight='bold' as='span'>{item.totalToday} {item.title}</Text>{" "}today
                                    </Text>
                                </Flex>
                            </Flex>
                        ))
                    }
                    
                </Flex>
            </CardHeader>
            <Box w='100%' h={{ sm: "300px" }} ps='8px'>
                {chart}
            </Box>
      </Card>
    )
}
const myPropsChanged = (prevProps, nextProps) => {
    if ( prevProps.chart !== nextProps.chart || prevProps.amout !== nextProps.amout) {
        return false;
    }
    return true;
}

export default React.memo(LineChartSummary, myPropsChanged);
  