// React imports
import React, { useContext, useEffect } from 'react'
// Chakra imports
import { Flex } from "@chakra-ui/react";
// Components
import Teams from './Teams'

function TeamsTables({teams, showMoreInfo, withMargin}) {

	let myCaptions = []
	// If we are showing the teams of a college, we do not need to show more info
	showMoreInfo
	? myCaptions = ["Logo", "Name", "Category", "College", "Coaches", "Total actions (last 30 days)", "Suscription", "Sport"]
	: myCaptions = ["Logo", "Name", "Suscription", "Sport"]

	useEffect(() => {
        window.scrollTo(0, 0)
	}, [])

	return (
		<Flex direction='column' pt={{ base: withMargin ? "30px" : "120px", md: withMargin ? "20px" : "75px" }}>
			<Teams
				title={"Teams"}
				captions={myCaptions}
				data={teams}
				showMoreInfo={showMoreInfo}
			/>
		</Flex>
	);
}

export default TeamsTables;
