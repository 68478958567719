// React imports
import React from "react";
import { useNavigate } from 'react-router-dom'
// Chakra imports
import {
	Td,
	Text,
	Tr,
} from "@chakra-ui/react";
import { getAuxDataVariable } from "helpers";



function AuxCoachesTableRow({coachInfo,captions}) {
	
	return (
		<Tr>
			{
				captions.map((info,index)=>{
					return (
						(info !== 'id' && info !== 'createdAt' && info !== 'updatedAt') && (
							<Td key={index}>
								<Text fontSize="sm" color="gray.400" fontWeight="normal">
									{coachInfo[getAuxDataVariable(info)]}
								</Text>
							</Td>
						)
					)
				})
			}
		</Tr>
	);
}

export default AuxCoachesTableRow;
