// React imports
import React, { useContext, useEffect } from 'react'
import { useParams, Navigate } from 'react-router-dom';
// Context imports
import { DataContext } from "../../../../contexts/dataContext";
// Chakra imports
import { Flex, SimpleGrid, Text, useColorModeValue } from "@chakra-ui/react";
// Component and assets
import ProfileBgImage from "assets/img/ProfileBackground.png";
import Header from './Header'
import AthletesTables from '../../Athletes/components/AthletesTables';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';


export const ShowCoach = () => {

	const bgProfile = useColorModeValue(
		"hsla(0,0%,100%,.8)",
		"linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
	);

	// Get the id of the coach from the url
	const {coachId} = useParams();

	// Take the context data needed in this component
    const dataContext = useContext(DataContext)
    const { coaches, athletes, teams, coachesCognito,auxCoaches} = dataContext
    const coach = coaches?.find(coach => coach.id === coachId)
	const teamOfCoach = teams?.find(team => team.id === coach?.teamID)
	const auxDataCoach = auxCoaches.find(item => item.contact_email === coach?.contact_email)
	// From coach data, get their favorite athletes to display in the AthletesTables
	const favAthletes = coach?.favorites?.items?.map(fav => {
		return athletes?.find(athlete => athlete.id === fav.athleteID)
	})

	useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

	return (
		<Flex direction='column' pt={{ base: "120px", md: "75px" }}>
			{/* Check if the context is loaded */}
			{(coaches?.length + teams?.length + coachesCognito?.length + athletes?.length ) > 0 && (
				// Check if the coach is found
				coach ? (
					<>
					<Header
						id={coach.id}
						backgroundHeader={ProfileBgImage}
						backgroundProfile={bgProfile}
						name={coach.name}
						profile_pic={coach.profile_pic}
						birthdate={coach.birthdate}
						contact_email={coach.contact_email}
						contact_phone={coach.contact_phone}
						disabledByUser={coach.disabledByUser}
						deleteAppReason={coach.deleteAppReason}
						role={coach.role}
						team={teamOfCoach}
						status={coachesCognito.find(item => item.Attributes.at(-1)?.Value.toLowerCase() === coach.contact_email.toLowerCase())?.UserStatus === 'CONFIRMED' ? "Active" : "Pending change password"} // Cognito
					/>
					{
						auxDataCoach && (
							<Card overflowX={{ sm: "scroll", xl: "hidden" }} mt={{ base: "100px", md: "75px" }}>
								<CardHeader p='6px 0px 15px 0px'>
									<Flex direction='row' w="100%">
										<Text fontSize='xl' color={useColorModeValue("gray.700", "white")} fontWeight='bold'>
											Aditional information
										</Text>
									</Flex>
								</CardHeader>
								<CardBody>

								<SimpleGrid columns={{ sm: 1, md: 2, xl: 2 }} spacing='24px' w="100%">
									<Flex direction="column">
										<Text fontSize="md" color="gray.400" fontWeight="normal">
											Phone number
										</Text>
										<Text fontSize="md" color="gray.700" fontWeight="normal">
											{auxDataCoach.contact_phone ? auxDataCoach.contact_phone : 'Not specified'}
										</Text>
									</Flex>
									<Flex direction="column">
										<Text fontSize="md" color="gray.400" fontWeight="normal">
											Twitter
										</Text>
										<Text fontSize="md" color="gray.700" fontWeight="normal">
											{auxDataCoach.individual_twitter!=='<empty>' ? <a href={auxDataCoach.individual_twitter} target="_blank">{auxDataCoach.individual_twitter}</a> : 'Not specified'}
										</Text>
									</Flex>
									<Flex direction="column">
										<Text fontSize="md" color="gray.400" fontWeight="normal">
											Facebook
										</Text>
										<Text fontSize="md" color="gray.700" fontWeight="normal">
											{auxDataCoach.individual_facebook !== '<empty>' ? <a href={auxDataCoach.individual_facebook} target="_blank">{auxDataCoach.individual_facebook}</a> : 'Not specified'}
										</Text>
									</Flex>
									<Flex direction="column">
										<Text fontSize="md" color="gray.400" fontWeight="normal">
											Instagram
										</Text>
										<Text fontSize="md" color="gray.700" fontWeight="normal">
											{auxDataCoach.individual_instagram !== '<empty>' ? <a href={auxDataCoach.individual_instagram} target="_blank">{auxDataCoach.individual_instagram}</a> : 'Not specified'}
										</Text>
									</Flex>
									
								</SimpleGrid>
									
								</CardBody>
							</Card>
						)
					}

					{favAthletes && (
					<AthletesTables athletes={favAthletes} showMoreInfo={false} withMargin={auxDataCoach ? true : false}/>	)}
					</>
				) :(
					<Navigate to={'/admin/coaches'} replace />
				)
			)}
		</Flex>
		
	);
}