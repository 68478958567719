// React imports
import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom'
// Chakra imports
import {
	Image,
	Button,
	Flex,
	Td,
	Text,
	Tr,
	useColorModeValue,
	Checkbox,
	Avatar
} from "@chakra-ui/react";



function AgentsTableRow({ id, name, profile_pic, contact_email,contact_phone, gender, agency, selectItem, setSelectItem }) {
	// Chakra color mode
	const textColor = useColorModeValue("gray.700", "white");
	const titleColor = useColorModeValue('royalblue.700');
	// navigate hook
	let navigate = useNavigate()

	// Function that handles the selection of an item
	const handleSelectItem = (e) => {
		setSelectItem(e.target.checked ? [...selectItem, {id, name, agencyID: agency.id, contact_email}] : selectItem.filter(item => item.id !== id))
	}
	
	const [isSelected, setIsSelected] = useState(false)
	useEffect(() => {
		selectItem?.find((item) => item.id === id) ? setIsSelected(true) : setIsSelected(false)
	}, [selectItem])

	return (
		<Tr>
			{
				agency && (
					<Td>
						<Checkbox colorScheme='blue' onChange={(e)=>(handleSelectItem(e))} isChecked={isSelected} />
					</Td>
				)
			}
			{/* First column */}
			<Td minWidth={{ sm: "100px" }} pl="0px" onClick={()=>(navigate(`/admin/agents/show/${id}`))} style={{cursor:'pointer'}}>
				<Flex align="center" py=".2rem" minWidth="100%" flexWrap="nowrap">
					<Avatar src={profile_pic} size="xl" bg='royalblue.700'/>
				</Flex>
			</Td>

			{/* Second column */}
			<Td maxWidth={{ sm: "150px" }} onClick={()=>(navigate(`/admin/agents/show/${id}`))} style={{cursor:'pointer'}}>
				<Text
					fontSize="sm"
					color={textColor}
					fontWeight="bold"
					minWidth="100%"
				>
					{name}
				</Text>
			</Td>

			{/* Third column */}
			<Td maxWidth={{ sm: "250px" }}>
				<Text fontSize="sm" color="gray.400" fontWeight="normal">
					{contact_email}
				</Text>
			</Td>
			
			{/* Fourth column */}
			<Td>
				<Text fontSize="sm" color="gray.400" fontWeight="normal">
					{contact_phone}
				</Text>
			</Td>

			{/* Fifth column */}
			{agency && (
				
				<Td onClick={()=>(navigate(`/admin/agencies/show/${agency.id}`))}>
					<Flex
					align='center'
					bg='hsla(0,0%,100%,.3)'
					borderRadius='15px'
					justifyContent='center'
					py='5px'
					boxShadow='inset 0 0 1px 1px hsl(0deg 0% 100% / 90%), 0 20px 27px 0 rgb(0 0 0 / 5%)'
					border='1px solid gray.200'
					mr='5px'
					cursor='pointer'>
						<Button p='0px' bg='transparent' _hover={{ bg: "none" }} leftIcon={<Image src={agency.logo} w='20px' />} >
							<Text
								fontSize='xs'
								color={textColor}
								fontWeight='bold'
								ms='6px'>
								{agency.name}
							</Text>
						</Button>
					</Flex>
				</Td>
			)}
		</Tr>
	);
}

export default AgentsTableRow;
