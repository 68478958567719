import React, {useState, useEffect} from 'react'
// Chackra Components
import { 
    Box,
    Flex, 
    Text, 
    List,
    ListItem,
    ListIcon,	
    Alert,
	AlertTitle,
	AlertDescription,
} from "@chakra-ui/react";
import {IoMdAlert} from 'react-icons/io'
import {FaVideo} from 'react-icons/fa'
import {MdVideoLibrary} from 'react-icons/md'
// Drag and drop component
import {useDropzone} from 'react-dropzone'

const VideoDropzone = ({values,updateValues}) => {

	const {acceptedFiles,fileRejections, getRootProps, getInputProps} = useDropzone({
        multiple:false,
        maxSize: 734000000, 
        accept: 'video/mp4'
    });
    useEffect(() => {
        acceptedFiles.length > 0 && updateValues({...values, featured_video: {metadata:"Featured video", url: acceptedFiles[0]}})
    }, [acceptedFiles])
    
	const thumb = acceptedFiles.map(file => (
        <ListItem key={file.path}>
            <ListIcon as={MdVideoLibrary} color='blue.500' />
            {file.path}
		</ListItem>
	));
    
    const rejectedFiles = fileRejections.map(({file}) => (
		<ListItem key={file.path}>
            <ListIcon as={IoMdAlert} color='red.500' />
		    {file.path}
		</ListItem>
	));


    return (
        <>

            {
                rejectedFiles.length>0
                ?(

                    <Alert status='error' mb="15px" borderRadius="10px">
                        <Box flex='1'>
                            <AlertTitle>The following files are not allowed:</AlertTitle>
                            <AlertDescription display='block'>
                                <List spacing={2}>
                                    {rejectedFiles}
                                </List>
                            </AlertDescription>
                        </Box>
                    </Alert>
                )
                :null
            }
            <Flex 
                borderRadius="sm" 
                border="3px dashed #bdc3c7 "
                boxShadow="sm" 
                bg="#f2f3f4" 
                justifyContent={"center"} 
                alignItems="center" 
                h="70px" 
                cursor="pointer"
                {...getRootProps({className: 'dropzone'})}
                m="10px"
                direction="column"
            >
                <input {...getInputProps()} />
                <Text fontSize='sm' fontWeight='semibold'><i>Drag file</i> or <i>Click here</i> to browse from your computer</Text>
                <Text fontSize='xs'>Max file size: 700MB</Text>
            </Flex>
            {
                acceptedFiles.length > 0 
                ?(
                    <>
                        <Text fontSize={{ sm: "sm", lg: "sm" }} mb="5px" fontWeight='semibold' textAlign="justify">
                            Uploaded files
                        </Text>
                        <List spacing={4}>
                            {thumb}
                        </List>
                    </>
                )
                : null
            }
        
        </>
    )
}

const myPropsChanged = (prevProps, nextProps) => {
    if ( prevProps.values !== nextProps.values ) {
        return false;
    }
    return true;
}

export default React.memo(VideoDropzone, myPropsChanged);