import React, { useContext } from 'react'

import { Flex, Text, Image,useColorModeValue, Tag, Link, Table, Th, Tr, Td, Thead, Tbody, Icon, Spacer, Divider} from "@chakra-ui/react";
import active2x from "assets/img/foot/active2x.png";
import foot2x from "assets/img/foot/foot2x.png";


import { IoHandLeft, IoHandRight }   from 'react-icons/io5';
import { FaRegFutbol } from 'react-icons/fa'
import { GiBodyHeight,GiWeight, GiTennisBall } from 'react-icons/gi'

import { position } from 'helpers'
import { countryListAlpha2 } from 'helpers';

export const AthleticInfo = ({athlete}) => {

    const bgProfile = useColorModeValue(
		"hsla(0,0%,100%,.8)",
		"linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
	);
	const textColor = useColorModeValue("gray.700", "white");
	const emailColor = useColorModeValue("gray.400", "gray.300","royalblue.700");


    return (
        <>
            {/* First row */}
            <Flex direction={{ sm: "column", lg: "row" }} w="100%">
                <Flex direction="column" w={{ sm: "100%", lg: "30%" }} mt="20px">
                    <Flex direction="column">
                        <Text fontSize={{ sm: "sm", lg: "md" }} color={emailColor} >
                            Sport
                        </Text>
                        {
                            athlete.sport
                            ? (
                                <Flex direction="row" alignItems="center">
                                    <Text fontSize={{ sm: "md", lg: "lg" }} color={textColor} mb={{ sm: "8px", md: "15px" }}>
                                    <Icon as={athlete.sport === 'Soccer' ? FaRegFutbol : GiTennisBall } size="xs" me="5px" color={athlete.sport === 'Soccer' ? 'royalblue.700' : 'green'}/>
                                        {athlete.sport}
                                    </Text>
                                </Flex>
                            )
                            : (<Text fontSize="xs" color={textColor} mb={{ sm: "8px", md: "15px" }}>
                                No Sport information provided
                            </Text>)
                        }
                    </Flex>                    
                </Flex>
                <Spacer />
                <Flex direction="column" w={{ sm: "100%", lg: "30%" }} mt="20px">
                    <Flex direction="column">
                        <Text fontSize={{ sm: "sm", lg: "md" }} color={emailColor} >
                            Competition country
                        </Text>
                        {
                            athlete.country_of_competition
                            ? (
                                <Flex direction="row" alignItems="baseline">
                                    <Image src={`https://www.flagcdn.com/w40/${countryListAlpha2[athlete.country_of_competition]?.toLowerCase()}.png`} h='15px' me="10px" color="inherit" />
                                    <Text fontSize={{ sm: "md", lg: "lg" }} color={textColor} mb={{ sm: "8px", md: "15px" }}>
                                        {athlete.country_of_competition}
                                    </Text>
                                </Flex>
                            )
                            : (<Text fontSize="xs" color={textColor} mb={{ sm: "8px", md: "15px" }}>
                                No Competition country information provided
                            </Text>)
                        }
                    </Flex>
                </Flex>
                <Spacer />
                <Flex direction="column" w={{ sm: "100%", lg: "30%" }} mt="20px">
                    <Flex direction="column">
                        <Text fontSize={{ sm: "sm", lg: "md" }} color={emailColor} >
                            Current club
                        </Text>
                        {
                            athlete.current_club
                            ? (<Text fontSize={{ sm: "md", lg: "lg" }} color={textColor} mb={{ sm: "8px", md: "15px" }}>
                                {athlete.current_club}
                            </Text>)
                            : (<Text fontSize="xs" color={textColor} mb={{ sm: "8px", md: "15px" }}>
                                No Current club information provided
                            </Text>)
                        }
                    </Flex>
                </Flex>
            </Flex>

            {/* Second row */}
            <Flex direction={{ sm: "column", lg: "row" }} w="100%">
                <Flex direction="column" w={{ sm: "100%", lg: athlete.sport==="Soccer" ? "30%" : "15%" }} mt="20px">
                    <Flex direction="column">
                        <Text fontSize={{ sm: "sm", lg: "md" }} color={emailColor} >
                            Dominant {athlete.sport==="Soccer" ? "foot" : "hand"}
                        </Text>
                        <Flex direction="row" mb={{ sm: "8px", md: "15px" }} alignItems="center">
                            Left
                            {
                                athlete.sport === "Soccer" && (
                                    <>
                                        <Tag variant="solid" colorScheme={athlete.dominant_foot === 'left' || athlete.dominant_foot === 'both' ? "blue" : "blackAlpha"} p="10px" mx="5px" onClick={()=>handleClickHandFoot('left')} cursor="pointer">
                                            <Image src={(athlete.dominant_foot === 'left' || athlete.dominant_foot === 'both' )? active2x : foot2x} w="35px"/>
                                        </Tag>
                                        <Tag variant="solid" colorScheme={athlete.dominant_foot === 'right' || athlete.dominant_foot === 'both' ? "blue" : "blackAlpha"} p="10px" mx="5px" onClick={()=>handleClickHandFoot('right')} cursor="pointer">
                                            <Image src={(athlete.dominant_foot === 'right' || athlete.dominant_foot === 'both') ? active2x : foot2x} w="35px" style={{transform:"scaleX(-1)"}}/>
                                        </Tag>
                                    </>
                                )
                            }
                            {
                                
                                athlete.sport === "Tennis" && (
                                    <>
                                        <Tag variant="solid" colorScheme={athlete.tennis_profile?.DominantHand === 'left' || athlete.tennis_profile?.DominantHand === 'both' ? "green" : "blackAlpha"} p="10px" mx="5px" onClick={()=>handleClickHandFoot('left')} cursor="pointer">
                                            <Icon as={IoHandLeft} boxSize={5} />
                                        </Tag>
                                        <Tag variant="solid" colorScheme={athlete.tennis_profile?.DominantHand === 'right' || athlete.tennis_profile?.DominantHand === 'both' ? "green" : "blackAlpha"} p="10px" mx="5px" onClick={()=>handleClickHandFoot('right')} cursor="pointer">
                                            <Icon as={IoHandRight} boxSize={5} />
                                        </Tag>
                                    </>
                                )
                            }
                            Right
                        </Flex>
                    </Flex>                    
                </Flex>
                <Spacer />
                {
                    athlete.sport === "Soccer" && (
                        <>
                            <Flex direction="column" w={{ sm: "100%", lg: "30%" }} mt="20px">
                                <Flex direction="column">
                                    <Text fontSize={{ sm: "sm", lg: "md" }} color={emailColor} >
                                        Position
                                    </Text>
                                    {
                                        athlete.position
                                        ? (<Text fontSize={{ sm: "md", lg: "lg" }} color={textColor} mb={{ sm: "8px", md: "15px" }}>
                                            {position.find(pos => pos.value === athlete.position).label}
                                        </Text>)
                                        : (<Text fontSize="xs" color={textColor} mb={{ sm: "8px", md: "15px" }}>
                                            No Position information provided
                                        </Text>)
                                    }
                                </Flex>
                            </Flex>
                            <Spacer />
                            <Flex direction="column" w={{ sm: "100%", lg: "30%" }} mt="20px">
                                <Flex direction="column">
                                    <Text fontSize={{ sm: "sm", lg: "md" }} color={emailColor} >
                                        Secondary position
                                    </Text>
                                    {
                                        athlete.second_position
                                        ? (<Text fontSize={{ sm: "md", lg: "lg" }} color={textColor} mb={{ sm: "8px", md: "15px" }}>
                                            {position.find(pos => pos.value === athlete.second_position)?.label}
                                        </Text>)
                                        : (<Text fontSize="xs" color={textColor} mb={{ sm: "8px", md: "15px" }}>
                                            No Second position information provided
                                        </Text>)
                                    }
                                </Flex>
                            </Flex>
                        </>
                    )
                }
                {
                    
                    athlete.sport === "Tennis" && (
                        <>
                            <Flex direction="column" w={{ sm: "100%", lg: "15%" }} mt="20px">
                                <Flex direction="column">
                                    <Text fontSize={{ sm: "sm", lg: "md" }} color={emailColor} >
                                        Tennis profile - UTR
                                    </Text>
                                    {
                                        athlete.tennis_profile?.utr
                                        ? (<Text fontSize={{ sm: "md", lg: "lg" }} color={textColor} mb={{ sm: "8px", md: "15px" }}>
                                            {athlete.tennis_profile?.utr}
                                        </Text>)
                                        : (<Text fontSize="xs" color={textColor} mb={{ sm: "8px", md: "15px" }}>
                                            No UTR information provided
                                        </Text>)
                                    }
                                </Flex>
                            </Flex>
                            <Spacer />
                            <Flex direction="column" w={{ sm: "100%", lg: "25%" }} mt="20px">
                                <Flex direction="column">
                                    <Text fontSize={{ sm: "sm", lg: "md" }} color={emailColor} >
                                        Tennis profile - National ranking
                                    </Text>
                                    {
                                        athlete.tennis_profile?.national_ranking
                                        ? (<Text fontSize={{ sm: "md", lg: "lg" }} color={textColor} mb={{ sm: "8px", md: "15px" }}>
                                            {athlete.tennis_profile?.national_ranking}
                                        </Text>)
                                        : (<Text fontSize="xs" color={textColor} mb={{ sm: "8px", md: "15px" }}>
                                            No National ranking information provided
                                        </Text>)
                                    }
                                </Flex>
                            </Flex>
                            <Spacer />
                            <Flex direction="column" w={{ sm: "100%", lg: "25%" }} mt="20px">
                                <Flex direction="column">
                                    <Text fontSize={{ sm: "sm", lg: "md" }} color={emailColor} >
                                        Tennis profile - International ranking
                                    </Text>
                                    {
                                        athlete.tennis_profile?.international_ranking
                                        ? (<Text fontSize={{ sm: "md", lg: "lg" }} color={textColor} mb={{ sm: "8px", md: "15px" }}>
                                            {athlete.tennis_profile?.international_ranking}
                                        </Text>)
                                        : (<Text fontSize="xs" color={textColor} mb={{ sm: "8px", md: "15px" }}>
                                            No International ranking information provided
                                        </Text>)
                                    }
                                </Flex>
                            </Flex>
                        </>
                    )
                }
            </Flex>

            {/* Third row */}
            <Flex direction={{ sm: "column", lg: "row" }} w="100%">
                <Flex direction="column" w={{ sm: "100%", lg: "30%" }} mt="20px">
                    <Flex direction="column">
                        <Text fontSize={{ sm: "sm", lg: "md" }} color={emailColor} >
                            Height
                        </Text>
                        {
                            athlete.height
                            ? (
                                <Flex direction="row" alignItems="center">
                                    <Text fontSize={{ sm: "md", lg: "lg" }} color={textColor} mb={{ sm: "8px", md: "15px" }}>
                                    <Icon as={GiBodyHeight} size="xs" me="5px" />
                                        {athlete.height} cm
                                    </Text>
                                </Flex>
                            )
                            : (<Text fontSize="xs" color={textColor} mb={{ sm: "8px", md: "15px" }}>
                                No Height information provided
                            </Text>)
                        }
                    </Flex>                    
                </Flex>
                <Spacer />
                <Flex direction="column" w={{ sm: "100%", lg: "65%" }} mt="20px">
                    <Flex direction="column">
                        <Text fontSize={{ sm: "sm", lg: "md" }} color={emailColor} >
                            Weight
                        </Text>
                        {
                            athlete.weight
                            ? (
                                <Flex direction="row" alignItems="center">
                                    <Text fontSize={{ sm: "md", lg: "lg" }} color={textColor} mb={{ sm: "8px", md: "15px" }}>
                                    <Icon as={GiWeight} size="xs" me="5px" />
                                        {athlete.weight} Kg
                                    </Text>
                                </Flex>
                           )
                            : (<Text fontSize="xs" color={textColor} mb={{ sm: "8px", md: "15px" }}>
                                No Weight information provided
                            </Text>)
                        }
                    </Flex>
                </Flex>
            </Flex>

            <Divider />

            {/* Fourth row */}
            <Flex direction={{ sm: "column", lg: "row" }} w="100%">
                <Flex direction="column" w={{ sm: "100%", lg: "100%" }} mt="20px">
                    <Flex direction="row">
                        <Text fontSize={{ sm: "sm", lg: "md" }} color={emailColor} mr="20px">
                            Type of admission
                        </Text>
                        <Flex direction="row">
                        {
                            athlete.type_of_admission
                            ? (
                                <Text fontSize={{ sm: "md", lg: "lg" }} color={textColor} mb={{ sm: "8px", md: "10px" }}>
                                    {athlete.type_of_admission}
                                </Text>
                                )
                                : (<Text fontSize="xs" color={textColor} mb={{ sm: "8px", md: "10px" }}>
                                No Type of admission information provided
                            </Text>)
                        }
                        </Flex>
                    </Flex>                    
                </Flex>
            </Flex>

            {/* 5 row */}
            <Flex direction={{ sm: "column", lg: "row" }} w="100%">
                <Flex direction="column" w={{ sm: "100%", lg: "45%" }} mt="20px">
                    <Flex direction="column">
                        <Text fontSize={{ sm: "sm", lg: "md" }} color={emailColor} >
                            Target division
                        </Text>
                        <Flex direction="row">
                        {
                            athlete.target_division
                            ? (
                                athlete.target_division.map((division,idx) => {return (
                                    <Tag key={idx} variant="subtle" fontSize={{ sm: "md", lg: "lg" }} colorScheme={athlete.sport === 'Soccer' ? "blue" : 'green'} mr="5px" mb={{ sm: "8px", md: "15px" }}>
                                        {division}
                                    </Tag>
                                )})
                                )
                                : (<Text fontSize="xs" color={textColor} mb={{ sm: "8px", md: "15px" }}>
                                No Target Division information provided
                            </Text>)
                        }
                        </Flex>
                    </Flex>                    
                </Flex>
                <Spacer />
                <Flex direction="column" w={{ sm: "100%", lg: "45%" }} mt="20px">
                    <Flex direction="column">
                        <Text fontSize={{ sm: "sm", lg: "md" }} color={emailColor} >
                            Compliance
                        </Text>
                        <Flex direction="row">
                        {
                            athlete.compliance
                            ? (
                                athlete.compliance.map(compliance => {return (
                                    <Tag variant="subtle" fontSize={{ sm: "md", lg: "lg" }} colorScheme={athlete.sport === 'Soccer' ? "blue" : 'green'} mr="5px" mb={{ sm: "8px", md: "15px" }}>
                                        {compliance}
                                    </Tag>
                                )})
                            )
                            : (<Text fontSize={{ sm: "xs" }} color={textColor} mb={{ sm: "8px", md: "15px" }}>
                                No Compliance information provided
                            </Text>)
                        }
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>

            {/* 6 row */}
            <Flex direction={{ sm: "column", lg: "row" }} w="100%">
                <Flex direction="column" w={{ sm: "100%", lg: "45%" }} mt="20px">
                    <Flex direction="column">
                        <Text fontSize={{ sm: "sm", lg: "md" }} color={emailColor} >
                            Athlete status
                        </Text>
                        {
                            athlete.current_status
                            ? (<Tag w="30%" variant="subtle" colorScheme={athlete.current_status === 'AVAILABLE' ? 'blue' : athlete.current_status === 'ON_HOLD' ? 'orange' : 'red'} fontSize={{ sm: "md", lg: "lg" }}  mr="5px" mb={{ sm: "8px", md: "15px" }}>
                                    {athlete.current_status}
                            </Tag>)
                            : (<Text fontSize={{ sm: "xs" }} color={textColor} mb={{ sm: "8px", md: "15px" }} >
                                    No Athlete status information provided
                                </Text>)
                        }
                    </Flex>                    
                </Flex>
                <Spacer />
                <Flex direction="column" w={{ sm: "100%", lg: "45%" }} mt="20px">
                    <Flex direction="column">
                        <Text fontSize={{ sm: "sm", lg: "md" }} color={emailColor} >
                            Level of competition
                        </Text>
                        <Flex direction="row">
                        {
                            athlete.level_of_competition
                            ? (
                                <Text fontSize={{ sm: "md", lg: "lg" }} color={textColor} mb={{ sm: "8px", md: "15px" }}>
                                {athlete.level_of_competition}
                            </Text>
                            )
                            : (<Text fontSize={{ sm: "xs" }} color={textColor} mb={{ sm: "8px", md: "15px" }}>
                                No Level of competition information provided
                            </Text>)
                        }
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
            
            {/* 7 row */}
            <Flex direction={{ sm: "column", lg: "row" }} w="100%">
                <Flex direction="column" w={{ sm: "100%", lg: "45%" }} mt="20px">
                    <Flex direction="column">
                        <Text fontSize={{ sm: "sm", lg: "md" }} color={emailColor} >
                            Destination college
                        </Text>
                        {
                            athlete.destination_college
                            ? (<Text fontSize={{ sm: "md", lg: "lg" }} color={textColor} mb={{ sm: "8px", md: "15px" }}>
                                {athlete.destination_college}
                            </Text>)
                            : (<Text fontSize={{ sm: "xs" }} color={textColor} mb={{ sm: "8px", md: "15px" }} >
                                    No destination college information provided when PSA is committed
                                </Text>)
                        }
                    </Flex>                    
                </Flex>
                <Spacer />
                <Flex direction="column" w={{ sm: "100%", lg: "45%" }} mt="20px">
                    <Flex direction="column">
                        <Text fontSize={{ sm: "sm", lg: "md" }} color={emailColor} >
                        Reason for commitment
                        </Text>
                        {
                            athlete.reason_commitment
                            ? (<Text fontSize={{ sm: "md", lg: "lg" }} color={textColor} mb={{ sm: "8px", md: "15px" }}>
                                {athlete.reason_commitment}
                            </Text>)
                            : (<Text fontSize={{ sm: "xs" }} color={textColor} mb={{ sm: "8px", md: "15px" }} >
                                    No reason for commitment information provided when PSA is committed
                                </Text>
                            )
                        }
                    </Flex>                    
                </Flex>

            </Flex>
            <Flex direction={{ sm: "column", lg: "row" }} w="100%">
                <Flex direction="column" w={{ sm: "100%", lg: "45%" }} mt="20px">
                    <Flex direction="column">
                        <Text fontSize={{ sm: "sm", lg: "md" }} color={emailColor} >
                            Date of commitment
                        </Text>
                        {
                            athlete.date_commitment
                            ? (<Text fontSize={{ sm: "md", lg: "lg" }} color={textColor} mb={{ sm: "8px", md: "15px" }}>
                                {athlete.date_commitment}
                            </Text>)
                            : (<Text fontSize={{ sm: "xs" }} color={textColor} mb={{ sm: "8px", md: "15px" }} >
                                    No date of commitment information provided when PSA is committed
                                </Text>)
                        }
                    </Flex>                    
                </Flex>
                <Spacer />
            </Flex>

            {/* 8 row */}
            <Flex direction={{ sm: "column", lg: "row" }} w="100%">
                <Flex direction="column" w={{ sm: "100%", lg: "45%" }} mt="20px">
                    <Flex direction="column">
                        <Text fontSize={{ sm: "sm", lg: "md" }} color={emailColor} >
                            NCAA ID
                        </Text>
                        <Flex direction="row">
                        {
                            athlete.ncaa_id
                            ? (
                                <Text fontSize={{ sm: "md", lg: "lg" }} color={textColor} mb={{ sm: "8px", md: "15px" }}>
                                {athlete.ncaa_id}
                            </Text>
                            )
                            : (<Text fontSize={{ sm: "xs" }} color={textColor} mb={{ sm: "8px", md: "15px" }}>
                                No NCAA ID information provided
                            </Text>)
                        }
                        </Flex>
                    </Flex>
                </Flex>
                <Spacer />
                <Flex direction="column" w={{ sm: "100%", lg: "45%" }} mt="20px">
                    <Flex direction="column">
                        <Text fontSize={{ sm: "sm", lg: "md" }} color={emailColor} >
                            Agency assessment
                        </Text>
                        {
                            athlete.agency_assesment
                            ? (
                                <Flex direction="row">
                                    <Divider orientation='vertical' variant="dashed" mr="10px"/>
                                    <Text fontSize={{ sm: "md", lg: "lg" }} color={textColor} mb={{ sm: "8px", md: "15px" }}>
                                    {athlete.agency_assesment}
                                    </Text>
                                </Flex>
                            )
                            : (<Text fontSize={{ sm: "xs" }} color={textColor} mb={{ sm: "8px", md: "15px" }} >
                                    No Agency assessment information provided
                                </Text>)
                        }
                    </Flex>                    
                </Flex>
            </Flex>

            {/* 9 row */}
            <Flex direction={{ sm: "column", lg: "row" }} w="100%">
                <Flex direction="column" w={{ sm: "100%", lg: "45%" }} mt="20px">
                    <Flex direction="column">
                        <Text fontSize={{ sm: "sm", lg: "md" }} color={emailColor} >
                            Elegibility notes
                        </Text>
                        <Flex direction="row">
                        {
                            athlete.elegibility_notes
                            ? (
                                <Flex direction="row">
                                    <Divider orientation='vertical' variant="dashed" mr="10px"/>
                                    <Text fontSize={{ sm: "md", lg: "lg" }} color={textColor} mb={{ sm: "8px", md: "15px" }}>
                                    {athlete.elegibility_notes}
                                    </Text>
                                </Flex>
                            )
                            : (<Text fontSize={{ sm: "xs" }} color={textColor} mb={{ sm: "8px", md: "15px" }}>
                                No Elegibility notes information provided
                            </Text>)
                        }
                        </Flex>
                    </Flex>
                </Flex>
                <Spacer />
                <Flex direction="column" w={{ sm: "100%", lg: "45%" }} mt="20px">
                    <Flex direction="column">
                        <Text fontSize={{ sm: "sm", lg: "md" }} color={emailColor} >
                            Sport background
                        </Text>
                        {
                            athlete.sports_background
                            ? (
                                <Flex direction="row">
                                    <Divider orientation='vertical' variant="dashed" mr="10px"/>
                                    <Text fontSize={{ sm: "md", lg: "lg" }} color={textColor} mb={{ sm: "8px", md: "15px" }}>
                                    {athlete.sports_background}
                                    </Text>
                                </Flex>
                            )
                            : (<Text fontSize={{ sm: "xs" }} color={textColor} mb={{ sm: "8px", md: "15px" }} >
                                    No Sport background information provided
                                </Text>)
                        }
                    </Flex>                    
                </Flex>
            </Flex>

            <Divider mt="10px"/>            
            <Flex direction={{ sm: "column", lg: "row" }} w="100%">
                <Flex direction="column" w={{ sm: "100%", lg: "20%" }} mt="20px">
                    <Flex direction="column">
                        <Text fontSize={{ sm: "sm", lg: "md" }} color={emailColor} >
                            Card pic
                        </Text>
                        {
                            athlete.card_pic
                            ? (
                                <Image
                                    me={{ md: "22px" }}
                                    mt={{ sm: "8px", md: "15px" }}
                                    src={athlete.card_pic}
                                    h='170px'
                                    borderRadius='10px'
                                    fit='cover'
                                />
                            )
                            : (<Text fontSize={{ sm: "xs" }} color={textColor} mb={{ sm: "8px", md: "15px" }} >
                                    No Card pic information provided
                                </Text>)
                        }
                    </Flex>                    
                </Flex>
                <Spacer />
                <Flex direction="column" w={{ sm: "100%", lg: "75%" }} mt="20px">
                    <Flex direction="column">
                        <Table variant='simple' color={textColor}>
                            <Thead>
                                <Tr my='.8rem' pl='0px' color='gray.400'>
                                    <Th color='gray.400'>
                                        Title
                                    </Th>
                                    <Th color='gray.400'>
                                        URL
                                    </Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {	<>
                                        <Tr>

                                        <Td minWidth={{ sm: "250px" }} pl="0px">
                                            <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} fontWeight='semibold' mb={{ sm: "8px", md: "15px" }} textAlign="justify">
                                                Featured video
                                            </Text>
                                        </Td>
                                        {
                                            athlete.featured_video
                                            ? (
                                                <Td minWidth={{ sm: "250px" }} pl="0px">
                                                    <a href={athlete.featured_video.url} target="blank">
                                                        <Link fontSize={{ sm: "sm", lg: "sm" }} color="royalblue.700">
                                                            {athlete.featured_video.url}
                                                        </Link>
                                                    </a>
                                                </Td>
                                            )
                                            : (
                                                <Td minWidth={{ sm: "250px" }} pl="0px">
                                                    <Text fontSize={{ sm: "xs" }} color={textColor} mb={{ sm: "8px", md: "15px" }}>
                                                        No featured video available
                                                    </Text>
                                                </Td>
                                            )
                                        }
                                        </Tr>
                                        {
                                            athlete.other_videos
                                            ? (
                                                athlete.other_videos.map((video, idx) => {
                                                    return (
                                                        <>
                                                            <Tr key={idx}>
                                                                <Td minWidth={{ sm: "250px" }} pl="0px">
                                                                    <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} fontWeight='semibold' mb={{ sm: "8px", md: "15px" }} textAlign="justify">
                                                                        {video.metadata ? video.metadata : 'Video ' + (idx + 1)}
                                                                    </Text>
                                                                </Td>
                                                                <Td minWidth={{ sm: "250px" }} pl="0px">
                                                                    <a href={video.url} target="blank">
                                                                        <Link fontSize={{ sm: "sm", lg: "sm" }} color="royalblue.700">
                                                                            {video.url}
                                                                        </Link>
                                                                    </a>
                                                                </Td>
                                                            </Tr>
                                                        </>
                                                    )
                                                })
                                            )
                                            : null
                                        }			
                                    </>
                                }
                            </Tbody>
                        </Table>
                    </Flex>                    
                </Flex>
            </Flex>
        </>
    )
}
