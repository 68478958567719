/*eslint-disable*/
// chakra imports
import {
  Box, useColorModeValue, Flex, Image
} from "@chakra-ui/react";
import React from "react";
import { Separator } from "components/Separator/Separator";
import SidebarContent from "./SidebarContent";

// FUNCTIONS

function Sidebar(props) {
  // to check for active links and opened collapses
  const mainPanel = React.useRef();
  let variantChange = "0.2s linear";

  const { logoText, routes, sidebarVariant } = props;

  //  BRAND
  //  Chakra Color Mode
  let sidebarBg = "none";
  let sidebarRadius = "0px";
  let sidebarMargins = "0px";
  if (sidebarVariant === "opaque") {
    sidebarBg = useColorModeValue("white", "gray.700");
    sidebarRadius = "16px";
    sidebarMargins = "16px 0px 16px 16px";
  }

  // SIDEBAR
  return (
    <Box ref={mainPanel}>
      <Box display={{ sm: "none", xl: "block" }} position="fixed">
        <Flex direction="column" p="20px" alignItems="center">
          <Image h="60px" mb="20px"
              src="https://res.cloudinary.com/dsvz8cktr/image/upload/v1624448821/LOGOFINAL_bl0pua.png"
              alt=""
          />
          
          <Separator />
        </Flex>
        <Box
          bg={sidebarBg}
          transition={variantChange}
          w="260px"
          maxW="260px"
          ms={{
            sm: "16px",
          }}
          my={{
            sm: "16px",
          }}
          h="calc(100vh - 32px)"
          ps="20px"
          pe="20px"
          m={sidebarMargins}
          borderRadius={sidebarRadius}
          overflowY="auto"
        >
          <SidebarContent routes={routes}
            logoText={logoText}
            display="none"
            sidebarVariant={sidebarVariant}
            />
        </Box>
      </Box>
    </Box>
  );
}




export default Sidebar;
