import React, { useState, useContext } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
// Context imports
import { DataContext } from '../contexts/dataContext'

// Chakra imports
import { ChakraProvider, Portal, useDisclosure } from "@chakra-ui/react";
import theme from "theme/theme.js";

// Routes to load
import routes from "routes.js";
// Aux
import { changeUserGroupToAgencyId } from "../helpers";

// Layout components
// import Footer from "components/Footer/Footer.js";
import Configurator from "components/Configurator/Configurator";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar";
// Custom components
import FixedPlugin from "../components/FixedPlugin/FixedPlugin";
import MainPanel from "../components/Layout/MainPanel";
import PanelContainer from "../components/Layout/PanelContainer";
import PanelContent from "../components/Layout/PanelContent";


// This component only defines the layout of the screen. 
// It uses the sidebar and navbar. 
// Finally, it defines the paths to the components that can be used within this layout.


export default function Dashboard(props) {
	const { ...rest } = props;
	// states for the sidebar and the fixed plugin
	const [sidebarVariant, setSidebarVariant] = useState("transparent");
	const [fixed, setFixed] = useState(false);

	// Take the context data needed in this component
	const dataContext = useContext(DataContext)
	const { user, userCoachData } = dataContext

	const userGroup = changeUserGroupToAgencyId(user?.signInUserSession.idToken.payload["cognito:groups"][0])
	
	// functions for changing the states from components
	const getRoute = () => {
		return window.location.pathname !== "/admin/full-screen-maps";
	};

	// Function to change the navbar variant
	const getActiveRoute = (routes) => {
		let activeRoute = "Default Brand Text";
		for (let i = 0; i < routes.length; i++) {
			if (routes[i].collapse) {
				let collapseActiveRoute = getActiveRoute(routes[i].views);
				if (collapseActiveRoute !== activeRoute) {
					return collapseActiveRoute;
				}
			} else if (routes[i].category) {
				let categoryActiveRoute = getActiveRoute(routes[i].views);
				if (categoryActiveRoute !== activeRoute) {
					return categoryActiveRoute;
				}
			} else {
				if (
					window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
				) {
					return routes[i].name;
				}
			}
		}
		return activeRoute;
	};

	// This changes navbar state(fixed or not)
	const getActiveNavbar = (routes) => {
		let activeNavbar = false;
		for (let i = 0; i < routes.length; i++) {
			if (routes[i].category) {
				let categoryActiveNavbar = getActiveNavbar(routes[i].views);
				if (categoryActiveNavbar !== activeNavbar) {
					return categoryActiveNavbar;
				}
			} else {
				if (
					window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
				) {
					if (routes[i].secondaryNavbar) {
						return routes[i].secondaryNavbar;
					}
				}
			}
		}
		return activeNavbar;
	};

	// Declares the paths of the components that may be used within this layout.
	const getRoutes = (routes) => {
		return routes.map((prop, key) => {
			if (prop.collapse) {
				return getRoutes(prop.views);
			}
			if (prop.category === "account") {
				return getRoutes(prop.views);
			}
			if (prop.layout === "/admin") {
				return (
					<>	
						{
							prop.superadmin === true ? (
								((prop.name === "Pending coaches" && userCoachData?.agent_role === "LEADER") || changeUserGroupToAgencyId(user?.signInUserSession.idToken.payload["cognito:groups"][0])==='superadmins') && (
								<Route
									path={prop.path}
									element={<prop.component />}
									key={key}
								/>)
							)
							: (
								<Route
									path={prop.path}
									element={<prop.component />}
									key={key}
								/>
							)
						}
							
						{/* prop.subroutes enable to load subroutes, example: admin/agents/<agentId> */}
						
						{
							prop.subroutes &&(
								prop.superadmin === true ? (
									(prop.name === "Pending coaches" && userCoachData?.agent_role === "LEADER") || changeUserGroupToAgencyId(user?.signInUserSession.idToken.payload["cognito:groups"][0])==='superadmins' && (
									<Route
									path={prop.path+"/*"}
									element={<prop.component />}
									key={key}
									/>)
								)
								: (
									<Route
									path={prop.path+"/*"}
									element={<prop.component />}
									key={key}
									/>
								)
							) 
						}
					</>
				);
			} else {
				return null;
			}
		});
	};


	const { isOpen, onOpen, onClose } = useDisclosure();
	document.documentElement.dir = "ltr";

	// Chakra Color Mode
	return (
		<ChakraProvider theme={theme} resetCss={false}>

			{/* Side bar with menu */}
			<Sidebar
				routes={routes}
				logoText={"Alliance Athletes"}
				display="none"
				sidebarVariant={sidebarVariant}
				{...rest}
			/>

			{/* Main Panel */}
			<MainPanel
				w={{
					base: "100%",
					xl: "calc(100% - 275px)",
				}}
			>
				{/* Top bar with current location, search, user */}
				<Portal>
					<AdminNavbar
						onOpen={onOpen}
						brandText={getActiveRoute(routes)}
						secondary={getActiveNavbar(routes)}
						fixed={fixed}
						{...rest}
					/>
				</Portal>

				{/* Main content of the layout */}
				{getRoute() ? (
					<PanelContent>
						<PanelContainer>
							<Routes>
								{/* Load the different views */}
								{getRoutes(routes)}
								{userGroup !== -1 && userGroup !== "superadmins" 
									? <Route path="/" element={<Navigate to="/admin/athletes" replace />} /> 
									: <Route path="/" element={<Navigate to="/admin/dashboard" replace />} />
								}
								{/* <Route path="/" element={<Navigate to="/admin/dashboard" replace />} /> */}
								<Route path="*" element={<Navigate to="/admin/" replace />} />
							</Routes>
						</PanelContainer>
					</PanelContent>
				) : null}

				{/* <Footer /> */}
				
				{/* <Portal>
					<FixedPlugin
						secondary={getActiveNavbar(routes)}
						fixed={fixed}
						onOpen={onOpen}
					/>
				</Portal> */}
				
				{/* <Configurator
					secondary={getActiveNavbar(routes)}
					isOpen={isOpen}
					onClose={onClose}
					isChecked={fixed}
					onSwitch={(value) => {
						setFixed(value);
					}}
					onOpaque={() => setSidebarVariant("opaque")}
					onTransparent={() => setSidebarVariant("transparent")}
				/> */}

			</MainPanel>
			
		</ChakraProvider>
	);
}
