import { Icon, SimpleGrid, useColorModeValue } from '@chakra-ui/react'
import React from 'react'
import {ProfileIcon} from "components/Icons/Icons.js";
import {MdAccountBalance,MdRecordVoiceOver,MdDirectionsRun, MdPersonAdd, MdOutlineFilterList, MdManageSearch,MdSchool} from "react-icons/md";
import MiniStatistics from '../MiniStatistics'
import { getNumberOfTeams } from 'helpers';
import { getActualSeason, getActiveSeasons } from 'helpers';

const KPIs = ({athletes,agents,agencies,athletesEnquirys,coaches,searchTerms,teams}) => {

    // console.warn("[KPIs] Me renderizo: ")

    const iconBoxInside = useColorModeValue("white", "white");

    const newPercentage = (newValue, oldValue) => {
		if (oldValue === newValue) {
			return 0
		}

		if (oldValue === 0) {
			return newValue*100
		}
		if (newValue === 0) {
			return 0
		}

		return (((newValue*100)/oldValue) -100).toFixed(2)

	}

	
    return (
        <>
            {/* Grid for mini stadistics */}
			<SimpleGrid columns={{ sm: 1, md: 2, xl: 3 }} spacing='24px' >
				<MiniStatistics
					title={"Athletes in Progress "}
					amount={athletes.filter(item => getActiveSeasons().includes(item.start_date)).length}
					secondaryAmount={athletes.length}
					icon={<Icon as={MdDirectionsRun} h={"24px"} w={"24px"} color={iconBoxInside} />}
				/>
				<MiniStatistics
					title={"Total Agencies"}
					amount={agencies.length}
					icon={<Icon as={MdAccountBalance} h={"24px"} w={"24px"} color={iconBoxInside} />}
				/>
				<MiniStatistics
					title={"Total Agents"}
					amount={agents.length}
					icon={<ProfileIcon h={"24px"} w={"24px"} color={iconBoxInside} />}
				/>
				<MiniStatistics
					title={"Total Coaches"}
					amount={coaches.length}
					icon={<Icon as={MdRecordVoiceOver} h={"24px"} w={"24px"} color={iconBoxInside} />}
				/>
				<MiniStatistics
					title={"Soccer Coaches"}
					amount={coaches.filter(item=> item.sport === 'Soccer').length}
					icon={<Icon as={MdRecordVoiceOver} h={"24px"} w={"24px"} color={iconBoxInside} />}
				/>
				<MiniStatistics
					title={"Tennis Coaches"}
					amount={coaches.filter(item=> item.sport === 'Tennis').length}
					icon={<Icon as={MdRecordVoiceOver} h={"24px"} w={"24px"} color={iconBoxInside} />}
				/>
			</SimpleGrid>

			<SimpleGrid columns={{ sm: 1, md: 2, xl: 2 }} spacing='24px' my="30px">
				<MiniStatistics
					title={"New Enquiries this month"}
					amount={athletesEnquirys.filter(item => new Date(item.createdAt).getMonth() === new Date().getMonth() && new Date(item.createdAt).getFullYear() === new Date().getFullYear()).length}
					percentage={
						newPercentage( 
							athletesEnquirys.filter(item => new Date(item.createdAt).getMonth() === new Date().getMonth() && new Date(item.createdAt).getFullYear() === new Date().getFullYear()).length, 
							new Date().getMonth() === 0 
							? athletesEnquirys.filter(item => new Date(item.createdAt).getMonth() === 11 && new Date(item.createdAt).getFullYear() === (new Date().getFullYear()-1)).length
							: athletesEnquirys.filter(item => new Date(item.createdAt).getMonth() === (new Date().getMonth() -1) && new Date(item.createdAt).getFullYear() === new Date().getFullYear()).length
						)
					}
					icon={<Icon as={MdPersonAdd} h={"24px"} w={"24px"} color={iconBoxInside} />}
					side="left"
				/>
				<MiniStatistics
					title={"New Coaches this month"}
					amount={coaches.filter(item => new Date(item.createdAt).getMonth() === new Date().getMonth() && new Date(item.createdAt).getFullYear() === new Date().getFullYear()).length}
					percentage={
						newPercentage( 
							coaches.filter(item => new Date(item.createdAt).getMonth() === new Date().getMonth() && new Date(item.createdAt).getFullYear() === new Date().getFullYear()).length, 
							new Date().getMonth() === 0 
							? coaches.filter(item => new Date(item.createdAt).getMonth() === 11 && new Date(item.createdAt).getFullYear() === (new Date().getFullYear()-1)).length
							: coaches.filter(item => new Date(item.createdAt).getMonth() === (new Date().getMonth() -1) && new Date(item.createdAt).getFullYear() === new Date().getFullYear()).length						
							)
					}
					icon={<Icon as={MdPersonAdd} h={"24px"} w={"24px"} color={iconBoxInside} />}
					side="left"
				/>
				<MiniStatistics
					title={"Search Terms (Soccer)"}
					amount={
						searchTerms.filter(item => item.search_type !== 'FILTER_SEARCH' && item.sport !== 'Tennis').filter(item => new Date(item.createdAt).getMonth() === new Date().getMonth() && new Date(item.createdAt).getFullYear() === new Date().getFullYear()).length
					}
					percentage={
						newPercentage( 
							searchTerms.filter(item => item.search_type !== 'FILTER_SEARCH' && item.sport !== 'Tennis').filter(item => new Date(item.createdAt).getMonth() === new Date().getMonth() && new Date(item.createdAt).getFullYear() === new Date().getFullYear()).length, 
							new Date().getMonth() === 0 
							? searchTerms.filter(item => item.search_type !== 'FILTER_SEARCH' && item.sport !== 'Tennis').filter(item => new Date(item.createdAt).getMonth() === 11 && new Date(item.createdAt).getFullYear() === (new Date().getFullYear()-1)).length
							: searchTerms.filter(item => item.search_type !== 'FILTER_SEARCH' && item.sport !== 'Tennis').filter(item => new Date(item.createdAt).getMonth() === (new Date().getMonth() -1) && new Date(item.createdAt).getFullYear() === new Date().getFullYear()).length
						)
					}
					icon={<Icon as={MdManageSearch} h={"24px"} w={"24px"} color={iconBoxInside}/>}
					side="left"
				/>
				<MiniStatistics
					title={"Filtered Searchs (Soccer)"}
					amount={
						searchTerms.filter(item => item.search_type === 'FILTER_SEARCH' && item.sport !== 'Tennis').filter(item => new Date(item.createdAt).getMonth() === new Date().getMonth() && new Date(item.createdAt).getFullYear() === new Date().getFullYear()).length
					}
					percentage={
						newPercentage( 
							searchTerms.filter(item => item.search_type === 'FILTER_SEARCH' && item.sport !== 'Tennis').filter(item => new Date(item.createdAt).getMonth() === new Date().getMonth() && new Date(item.createdAt).getFullYear() === new Date().getFullYear()).length, 
							new Date().getMonth() === 0 
							? searchTerms.filter(item => item.search_type === 'FILTER_SEARCH' && item.sport !== 'Tennis').filter(item => new Date(item.createdAt).getMonth() === 11 && new Date(item.createdAt).getFullYear() === (new Date().getFullYear()-1)).length
							: searchTerms.filter(item => item.search_type === 'FILTER_SEARCH' && item.sport !== 'Tennis').filter(item => new Date(item.createdAt).getMonth() === (new Date().getMonth() -1) && new Date(item.createdAt).getFullYear() === new Date().getFullYear()).length
						)
					}
					icon={<Icon as={MdOutlineFilterList} h={"24px"} w={"24px"} color={iconBoxInside}/>}
					side="left"
				/>
				<MiniStatistics
					title={"Search Terms (Tennis)"}
					amount={
						searchTerms.filter(item => item.search_type !== 'FILTER_SEARCH' && item.sport === 'Tennis').filter(item => new Date(item.createdAt).getMonth() === new Date().getMonth() && new Date(item.createdAt).getFullYear() === new Date().getFullYear()).length
					}
					percentage={
						newPercentage( 
							searchTerms.filter(item => item.search_type !== 'FILTER_SEARCH' && item.sport === 'Tennis').filter(item => new Date(item.createdAt).getMonth() === new Date().getMonth() && new Date(item.createdAt).getFullYear() === new Date().getFullYear()).length, 
							new Date().getMonth() === 0 
							? searchTerms.filter(item => item.search_type !== 'FILTER_SEARCH' && item.sport === 'Tennis').filter(item => new Date(item.createdAt).getMonth() === 11 && new Date(item.createdAt).getFullYear() === (new Date().getFullYear()-1)).length
							: searchTerms.filter(item => item.search_type !== 'FILTER_SEARCH' && item.sport === 'Tennis').filter(item => new Date(item.createdAt).getMonth() === (new Date().getMonth() -1) && new Date(item.createdAt).getFullYear() === new Date().getFullYear()).length
						)
					}
					icon={<Icon as={MdManageSearch} h={"24px"} w={"24px"} color={iconBoxInside}/>}
					side="left"
				/>
				<MiniStatistics
					title={"Filtered Searchs (Tennis)"}
					amount={
						searchTerms.filter(item => item.search_type === 'FILTER_SEARCH' && item.sport === 'Tennis').filter(item => new Date(item.createdAt).getMonth() === new Date().getMonth() && new Date(item.createdAt).getFullYear() === new Date().getFullYear()).length
					}
					percentage={
						newPercentage( 
							searchTerms.filter(item => item.search_type === 'FILTER_SEARCH' && item.sport === 'Tennis').filter(item => new Date(item.createdAt).getMonth() === new Date().getMonth() && new Date(item.createdAt).getFullYear() === new Date().getFullYear()).length, 
							new Date().getMonth() === 0 
							? searchTerms.filter(item => item.search_type === 'FILTER_SEARCH' && item.sport === 'Tennis').filter(item => new Date(item.createdAt).getMonth() === 11 && new Date(item.createdAt).getFullYear() === (new Date().getFullYear()-1)).length
							: searchTerms.filter(item => item.search_type === 'FILTER_SEARCH' && item.sport === 'Tennis').filter(item => new Date(item.createdAt).getMonth() === (new Date().getMonth() -1) && new Date(item.createdAt).getFullYear() === new Date().getFullYear()).length
						)
					}
					icon={<Icon as={MdOutlineFilterList} h={"24px"} w={"24px"} color={iconBoxInside}/>}
					side="left"
				/>
			</SimpleGrid>

			<SimpleGrid columns={{ sm: 1, md: 2, xl: 2 }} spacing='24px' mb="30px">
				<MiniStatistics
					title={"Men's Soccer Teams"}
					amount={getNumberOfTeams(coaches, teams, 'Men', 'Soccer')}
					icon={<Icon as={MdSchool} h={"24px"} w={"24px"} color={iconBoxInside} />}
					side="right"
				/>
				<MiniStatistics
					title={"Men's Tennis Teams"}
					amount={getNumberOfTeams(coaches, teams, 'Men', 'Tennis')}
					icon={<Icon as={MdSchool} h={"24px"} w={"24px"} color={iconBoxInside} />}
					side="right"
				/>
				<MiniStatistics
					title={"Women's Soccer Teams"}
					amount={getNumberOfTeams(coaches, teams, 'Women', 'Soccer')}
					icon={<Icon as={MdSchool} h={"24px"} w={"24px"} color={iconBoxInside}/>}
					side="right"
				/>
				<MiniStatistics
					title={"Women's Tennis Teams"}
					amount={getNumberOfTeams(coaches, teams, 'Women', 'Tennis')}
					icon={<Icon as={MdSchool} h={"24px"} w={"24px"} color={iconBoxInside}/>}
					side="right"
				/>
			</SimpleGrid>
        </>
    )
}

const myPropsChanged = (prevProps, nextProps) => {
    if ( prevProps !== nextProps) {
        return false;
    }
    return true;
}

export default React.memo(KPIs, myPropsChanged);