import React from 'react'
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    Button,
} from "@chakra-ui/react";

export const ConfirmModal = ({title, description, button1, button2,color="red", isOpen, onClose, cancelRef, handleDelete}) => {
    return (
        <>			
            <AlertDialog
				isOpen={isOpen}
				leastDestructiveRef={cancelRef}
				onClose={onClose}
				motionPreset='scale'
			>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize='lg' fontWeight='bold'>
						    {title}
						</AlertDialogHeader>

						<AlertDialogBody>
                            {description}
						</AlertDialogBody>

						<AlertDialogFooter>
						<Button ref={cancelRef} onClick={onClose}>
							{button1}
						</Button>
						<Button colorScheme={color} onClick={handleDelete} ml={3}>
							{button2}
						</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
        </>
    )
}
