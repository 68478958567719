import React, { useContext, useState,useMemo, useEffect }  from "react";
import { DataContext } from '../../../contexts/dataContext'


// Chakra imports
import {
	Flex,
	Grid,
	Image,
	SimpleGrid,
	useColorModeValue,
	Icon, Divider, Box, Text, Alert, AlertIcon
} from "@chakra-ui/react";
// assets
import backAA from "assets/img/backAA.jpg";

// Custom icons
import {ProfileIcon} from "components/Icons/Icons.js";
import {MdAccountBalance,MdRecordVoiceOver,MdDirectionsRun, MdPersonAdd, MdFeedback} from "react-icons/md";
import TextWithBackgroundImage from "./components/TextWithBackgroundImage";
import MiniStatistics from "./components/MiniStatistics";
import RankingsForAthletes from "./components/Rankings/RankingsForAthletes";
import AgencySummaryWithChart from "./components/AgencySummaryWithChart";
import TextWithSideImage from "./components/TextWithSideImage";
import BarChart from "components/Charts/BarChart";
import OrdersOverview from "./components/OrdersOverview";
import OrdersOverviewForSearchTerms from "./components/OrdersOverviewForSearchTerms";
import { stackedBarChartAgenciesOptions } from "variables/charts";
import  LineChartSummary  from "./components/LineChartSummary";
import { lineChartOptions } from "variables/charts";
import { LineChart } from "components/Charts/LineChart";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import stadiumLogo from "assets/img/stadiumLogo.png";
import RankingsForCoaches from "./components/Rankings/RankingsForCoaches";
import CoachesAthletesCreated from "./components/MonthlyCharts/CoachesAthletesCreated";
import KPIs from "./components/GeneralCharts/KPIs";
import { useNavigate } from "react-router-dom";
import { changeUserGroupToAgencyId } from "helpers";

export function DashboardForAgents() {

	const dataContext = useContext(DataContext)
	const { athletes, agencies, agents, coaches, athletesEnquirys, searchTerms, teams, user } = dataContext
	const iconBoxInside = useColorModeValue("white", "white");

	const navigate = useNavigate()

	const userGroup = changeUserGroupToAgencyId(user?.signInUserSession.idToken.payload["cognito:groups"][0])
	useEffect(() => {
		if (userGroup !== "superadmins") {
			navigate("/admin/athletes")
		}
	}, [])



	return (
		<Flex flexDirection='column' pt={{ base: "120px", md: "75px" }}>
			<Alert status='info' variant='left-accent' mb='20px' borderRadius="5px" >
				<AlertIcon />
				<Flex direction="column">
					<b>Section in maintenance.</b>
					IT team is working on improving the performance of this section.
				</Flex>
			</Alert>
			{/* Grid for mini stadistics */}
			<KPIs
                athletes={athletes}
                agencies={agencies}
                agents={agents}
                coaches={coaches.filter(item => item.is_active === true)}
                teams={teams}
                athletesEnquirys={athletesEnquirys}
                searchTerms={searchTerms} 
            />
				
			<Divider my="30px" />
			<Grid
				templateColumns={{ md: "1fr", lg: "1.8fr 1.2fr" }}
				templateRows={{ md: "1fr auto", lg: "1fr" }}

				gap='24px'>
				<TextWithSideImage
					title={"Also available on"}
					name={"Alliance Athletes App"}
					description={
						"Get immediate access to all the prospective soccer student-athletes represented by International Alliance members through the Alliance Athletes App."
					}
					image={
						<Image
							src={stadiumLogo}
							alt='chakra image'
							minWidth={{ md: "300px", lg: "auto" }}
							bgSize={"contain"}
						/>
					}
					url={"https://www.allianceathletes.com/"}
				/>
				<TextWithBackgroundImage
					backgroundImage={backAA}
					title={"The International Alliance"}
					description={
						"We are a collection of elite college sports recruiters united to help student-athletes around the world have the opportunity to study and play sports in American Universities."
					}
				/>
			</Grid>
			

		</Flex>
	);
}