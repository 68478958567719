// Chakra imports
import { Flex, Text, useColorModeValue, Icon, ScaleFade, Button, Skeleton } from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import TitleCoachDateRow from "components/Tables/TitleCoachDateRow";
import React, { useContext, useEffect, useState }  from "react";
import { DataContext } from '../../../../contexts/dataContext'
import {MdManageSearch, MdOutlineFilterList} from 'react-icons/md'
// import { HiDownload } from "react-icons/hi";
// import { CSVLink } from "react-csv";

const OrdersOverviewForSearchTerms = ({ title, subtitle, type, amount, data }) => {
	const textColor = useColorModeValue("gray.700", "white");

	const dataContext = useContext(DataContext)
	const { coaches, agents } = dataContext

	const [hasRender, setRender] = useState(false);
	const [loaded, setLoaded] = useState(false);
	const [loadedData, setLoadedData] = useState([]);
	const [loadedAmount, setLoadedAmount] = useState([]);
    const onShow = React.useCallback(() => setRender(true), []);

	useEffect(() => {
		if(hasRender){

			switch (type) {
				case "agents":
					setLoadedData(data.filter(item=>(agents.find(a=>a.contact_email === item?.coach?.contact_email && item.coach?.is_active))).sort((a,b) => new Date(b.createdAt) - new Date(a.createdAt)).slice(0,data.length / 5))
					setLoadedAmount(data.filter(item=>(agents.find(a=>a.contact_email === item?.coach?.contact_email && item.coach?.is_active)) && new Date(item.createdAt).getMonth() === new Date().getMonth() && new Date(item.createdAt).getFullYear() === new Date().getFullYear()).length)
					setLoaded(true)
				case "coaches":
					setLoadedData(data.filter(item=>!(agents.find(a=>a.contact_email === item?.coach?.contact_email && item.coach?.is_active))).sort((a,b) => new Date(b.createdAt) - new Date(a.createdAt)).slice(0,data.length / 5))
					setLoadedAmount(data.filter(item=>!(agents.find(a=>a.contact_email === item?.coach?.contact_email && item.coach?.is_active)) && new Date(item.createdAt).getMonth() === new Date().getMonth() && new Date(item.createdAt).getFullYear() === new Date().getFullYear()).length)
					setLoaded(true)
			}
		}
	}, [hasRender])

	// const getListTerms = () => {
	// 	return data.sort((a,b) => new Date(b.createdAt) - new Date(a.createdAt)).map(item => {
	// 		return {
	// 			"Search Term": item.search_term_text,
	// 			"Search Type": item.search_type,
	// 			"Coach": item.coach?.name,
	// 			"Coach University": item.coach?.team?.name,
	// 			"Coach Division": item.coach?.division,
	// 			"Coach Genre": item.coach?.team?.genre === 'Men' ? 'Mens Soccer Team' : 'Women Soccer Team',
	// 			"Source App": item.source_app,
	// 			"Sport": item.sport,
	// 			"Date": new Date(item.createdAt).toLocaleDateString(),
	// 		}
	// 	})
	// }

	return (
		<>
			{ hasRender && (
				<Skeleton isLoaded={loaded}>
					<ScaleFade in={hasRender} >
						{/* <CSVLink
							data={getListTerms()}
							filename={"SearchTermsByCoaches.csv"}
						>
							<Button
								colorScheme='green'
								variant='ghost'
								leftIcon={<Icon as={HiDownload}/>}
								size='sm'
							>
								Export to CSV
							</Button>
						</CSVLink>  */}
						<Card h='700px' maxW='100%'>
							<CardHeader p='22px 0px 35px 14px'>
								<Flex direction='column'>
									<Text fontSize='lg' color={textColor} fontWeight='bold' pb='.5rem'>
										{title}
									</Text>
									<Text fontSize='sm' color='gray.400' fontWeight='normal'>
										<Text fontWeight='bold' as='span' color='royalblue.700'>
											{loadedAmount} {title}
										</Text>{" "}
										this month.
									</Text>
								</Flex>
							</CardHeader>
							<CardBody ps='20px' pe='0px' overflowY="scroll" mb='31px' position='relative'>
								<Flex direction='column'>
									{loadedData.map((row, index, arr) => {
										return (
											<TitleCoachDateRow
											key={`${row.title}-${index}`}
											icon={<Icon as={row.search_type === 'FILTER_SEARCH' ? MdOutlineFilterList : MdManageSearch} color={row.sport === 'Tennis' ? 'green' : '#2049DA'} h={"35px"} w={"31px"} pe="6px" zIndex="1" position="relative" right={document.documentElement.dir === "rtl" ? "-8px" : ""} left={document.documentElement.dir === "rtl" ? "" : "-8px"}/>}
											title={row.search_term_text}
											coach={coaches.find(coach => coach.id === row.coachID)}
											date={new Date(row.createdAt).toLocaleDateString()}
											text={row.feedback_text && row.feedback_text}
											index={index}
											arrLength={arr.length}
											/>
											);
										})}
								</Flex>
							</CardBody>
						</Card>
					</ScaleFade>
				</Skeleton>
			)}
			{ !hasRender && (
                <ScaleFade in={!hasRender} >
                    <Card p='28px 10px 16px 0px'>
                        <CardHeader pl='22px' w="100%">
							<Flex direction='row' alignSelf='flex-start' w="100%" justifyContent={'space-between'}>
								<Flex direction='column' alignSelf='flex-start' w="50%" >
									<Text fontSize='lg' color={"gray.700"} fontWeight='bold' mb='6px'>
										{title}
									</Text>
									<Text fontSize='md' fontWeight='medium' color='gray.400'>
										{subtitle}
									</Text>
								</Flex>
								<Button w="40%" colorScheme='blue' onClick={onShow} my="10px" mr="5%">
									Load chart
								</Button>
							</Flex>
                        </CardHeader>
                    </Card>
                </ScaleFade>
            )}
		</>
	);
};

export default OrdersOverviewForSearchTerms;
