import { API } from 'aws-amplify';
import { DataContext } from 'contexts/dataContext';
import { deleteFavoritesFolderAthleteRelation } from 'custom_graphql_queries/athletes';
import { deleteCoachTagsRelation } from 'custom_graphql_queries/tags';
import { useContext } from 'react';
import { updateCoach, createCoach, deleteCoach, deleteCoachAthleteFavorites ,createNotification, deleteCoachMessage, deleteCoachNotificationRelation, deleteFeedback, deleteSearchTerm, deleteAthleteEnquiry } from '../custom_graphql_queries/coaches'
import { createUserCognito } from './cognitoServices';

const updateCoachService = async (coach) => {
    try {
        const response = await API.graphql({query: updateCoach, variables: { input: coach }})
        return response
    } catch (err) {
        throw new Error(err);
    }
}
const createCoachService = async (coach) => {
    try {
        const response =  await API.graphql({query: createCoach, variables: { input: coach }})
        .then(async (response) => {
           const res = await createUserCognito({email: coach.contact_email, profileID: response.data.createCoach.id})
            return res
        } )
        .catch(error => {console.log(error)})
        return response
    } catch (error) {
        throw new Error(error.message);
    }
}

const deleteCoachService = async (coachId) => {
    try {
        const response = await API.graphql({query: deleteCoach, variables: { input: {id: coachId }}})
        return response
    } catch (error) {
        throw new Error(error.message);
    }
}

const deleteFavoritesFolderService = async (folderId) => {
    try {
        const response = await API.graphql({query: deleteFavoritesFolderAthleteRelation, variables: { input: {id: folderId }}})
        return response
    } catch (error) {
        throw new Error(error.message);
    }
}

const deleteCoachAthleteFavoritesService = async (relationId) => {
    try {
        const response = await API.graphql({query: deleteCoachAthleteFavorites, variables: { input: {id: relationId }}})
        return response
    } catch (error) {
        throw new Error(error.message);
    }
}

const deleteCoachATagsRelationService = async (relationId) => {
    try {
        const response = await API.graphql({query: deleteCoachTagsRelation, variables: { input: {id: relationId }}})
        return response
    } catch (error) {
        throw new Error(error.message);
    }
}
const deleteCoachMessageService = async (relationId) => {
    try {
        const response = await API.graphql({query: deleteCoachMessage, variables: { input: {id: relationId }}})
        return response
    } catch (error) {
        throw new Error(error.message);
    }
}
const deleteCoachNotificationRelationService = async (relationId) => {
    try {
        const response = await API.graphql({query: deleteCoachNotificationRelation, variables: { input: {id: relationId }}})
        return response
    } catch (error) {
        throw new Error(error.message);
    }
}
const deleteFeedbackService = async (relationId) => {
    try {
        const response = await API.graphql({query: deleteFeedback, variables: { input: {id: relationId }}})
        return response
    } catch (error) {
        throw new Error(error.message);
    }
}
const deleteSearchTermService = async (relationId) => {
    try {
        const response = await API.graphql({query: deleteSearchTerm, variables: { input: {id: relationId }}})
        return response
    } catch (error) {
        throw new Error(error.message);
    }
}
const deleteAthleteEnquiryService = async (relationId) => {
    try {
        const response = await API.graphql({query: deleteAthleteEnquiry, variables: { input: {id: relationId }}})
        return response
    } catch (error) {
        throw new Error(error.message);
    }
}

const deleteCoachRelations = async (dataContext,coachID) => {
    // delete COACH relations
    let hasFailed = false
    // Delete all the athlete enquiries relations of this coach
    dataContext.athletesEnquirys.map(ae=>{
        if(ae.coachID === coachID){
            deleteAthleteEnquiryService(ae.id).catch(() => { hasFailed=true })
        }}
    )
    dataContext.setAthletesEnquirys(dataContext.athletesEnquirys.filter(ae=>ae.coachID !== coachID))
    // Delete all the coach-athlete favorites relations of this coach
    dataContext.coachAthleteFavorites.map(caf=>{
        if(caf.coachID === coachID){
            deleteCoachAthleteFavoritesService(caf.id).catch(() => { hasFailed=true })
        }}
    )
    dataContext.setCoachAthleteFavorites(dataContext.coachAthleteFavorites.filter(caf=>caf.coachID !== coachID))

    // Delete all the coach messages relations of this coach
    dataContext.coachMessages.map(cm=>{
        if(cm.coachID === coachID){
            deleteCoachMessageService(cm.id).catch(() => { hasFailed=true })
        }}
    )
    dataContext.setCoachMessages(dataContext.coachMessages.filter(cm=>cm.coachID !== coachID))

    // Delete all the coach notifications relations of this coach
    dataContext.coachNotificationRelations.map(cnr=>{
        if(cnr.coachID === coachID){
            deleteCoachNotificationRelationService(cnr.id).catch(() => { hasFailed=true })
        }}
    )
    dataContext.setCoachNotificationRelations(dataContext.coachNotificationRelations.filter(cnr=>cnr.coachID !== coachID))

    // Delete all the coach tags relations of this coach
    dataContext.coachTagRelations.map(ctr=>{
        if(ctr.coachID === coachID){
            deleteCoachATagsRelationService(ctr.id).catch(() => { hasFailed=true })
        }}
    )
    dataContext.setCoachTagRelation(dataContext.coachTagRelations.filter(ctr=>ctr.coachID !== coachID))

    // Delete all the coach tags relations of this coach
    dataContext.favoriteFolderAthleteRelation.map(ffar=>{
        if(ffar.coachID === coachID){
            deleteFavoritesFolderService(ffar.id).catch(() => { hasFailed=true })
        }}
    )
    dataContext.setFavoriteFolderAthleteRelation(dataContext.favoriteFolderAthleteRelation.filter(ffar=>ffar.coachID !== coachID))

    // Delete all the coach tags relations of this coach
    dataContext.feedbacks.map(f=>{
        if(f.coachID === coachID){
            deleteFeedbackService(f.id).catch(() => { hasFailed=true })
        }}
    )
    dataContext.setFeedbacks(dataContext.feedbacks.filter(f=>f.coachID !== coachID))

    // Delete all the coach tags relations of this coach
    dataContext.searchTerms.map(st=>{
        if(st.coachID === coachID){
            deleteSearchTermService(st.id).catch(() => { hasFailed=true })
        }}
    )
    dataContext.setSearchTerms(dataContext.searchTerms.filter(st=>st.coachID !== coachID))

    return hasFailed

}


export { updateCoachService, createCoachService, deleteCoachService, deleteCoachAthleteFavoritesService, deleteCoachATagsRelationService,deleteFavoritesFolderService, deleteCoachRelations, deleteAthleteEnquiryService };