import React, { useState, useEffect } from 'react'
import validator from "validator";
// Chackra Components
import { Flex,useColorModeValue, Text, Input, InputGroup,Spacer, FormControl,FormErrorMessage } from "@chakra-ui/react";
import { Icon } from '@chakra-ui/react'
import {FaFacebook, FaTwitter, FaLinkedin} from "react-icons/fa";

const SocialNetworksFields = ({formValues, setFormValues, formErrors}) => {
	const textColor = useColorModeValue("gray.700", "white");


    const onChange = (e) => {
        e.persist()
        setFormValues(() => ({ ...formValues, [e.target.name]: e.target.value }))
    }


    return (
        <>
            <Flex direction="column" w="100%">
                <Flex direction="column" w="100%" mt="20px">
                    <FormControl isInvalid={formErrors.twitter_user !== ""}>
                        <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mb="5px" fontWeight='semibold' textAlign="justify">
                            Twitter
                        </Text>
                        <InputGroup>
                            <Flex w="100%" direction="row" alignItems="center"> 
                                <Icon as={FaTwitter} color='gray.300' mr="10px" />
                                <Input onChange={onChange} variant='flushed' placeholder='Twitter user' size='sm' value={formValues.twitter_user || ''} name="twitter_user" />
                            </Flex>
                        </InputGroup>
                        {formErrors.twitter_user !== "" ? <FormErrorMessage>{formErrors.twitter_user}</FormErrorMessage> : null}
                    </FormControl>
                    
                </Flex>
                <Spacer />
                <Flex direction="column" w="100%" mt="20px">
                    <FormControl isInvalid={formErrors.facebook_url !== ""}>
                        <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mb="5px" fontWeight='semibold' textAlign="justify">
                            Facebook
                        </Text>
                        <InputGroup>
                            <Flex w="100%" direction="row" alignItems="center"> 
                                <Icon as={FaFacebook} color='gray.300' mr="10px" />
                                <Input onChange={onChange} variant='flushed' placeholder='Facebook url' size='sm' value={formValues.facebook_url || ''} name="facebook_url" />
                            </Flex>
                        </InputGroup>
                        {formErrors.facebook_url !== "" ? <FormErrorMessage>{formErrors.facebook_url}</FormErrorMessage> : null}
                    </FormControl>
                </Flex>
                <Spacer />
                <Flex direction="column" w="100%" mt="20px">
                    <FormControl isInvalid={formErrors.linkedin_url !== ""}>
                        <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mb="5px" fontWeight='semibold' textAlign="justify">
                            LinkedIn
                        </Text>
                        <InputGroup>
                            <Flex w="100%" direction="row" alignItems="center"> 
                                <Icon as={FaLinkedin} color='gray.300' mr="10px" />
                                <Input onChange={onChange} variant='flushed' placeholder='LinkedIn url' size='sm' value={formValues.linkedin_url || ''} name="linkedin_url" />
                            </Flex>
                        </InputGroup>
                        {formErrors.linkedin_url !== "" ? <FormErrorMessage>{formErrors.linkedin_url}</FormErrorMessage> : null}
                    </FormControl>
                </Flex>
            </Flex>
        </>
    )
}

const myPropsChanged = (prevProps, nextProps) => {
    if ( prevProps.formValues !== nextProps.formValues || prevProps.formErrors !== nextProps.formErrors) {
        return false;
    }
    return true;
}

export default React.memo(SocialNetworksFields, myPropsChanged);