import React from 'react'
// Chakra imports
import { Flex, Icon, Link, Text, useColorModeValue } from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";

const InformationCard = ({title, text, captions, values, cardColor, name,email, date, team}) => {

    // Chakra color mode
    const textColor = useColorModeValue("gray.700", "white");

    return (
        <Card p='16px' my={{ sm: "24px", xl: "0px" }} boxShadow="rgba(99, 99, 99, 0.2) 0px 2px 4px 0px" backgroundColor={cardColor}>
            <CardHeader p='12px 5px' mb='12px'>
                <Text fontSize='lg' color={textColor} fontWeight='bold'>
                {title}
                </Text>
            </CardHeader>
            <CardBody px='5px'>
                <Flex direction='column'>
                    <Text fontSize='md' color='gray.500' fontWeight='400' mb='30px'>
                        {text ? text : "No enquiry text provided"}
                    </Text>
                    {
                        captions.map((caption,index) => {
                            return (
                                <Flex align='center' mb='10px' key={index}>
                                    <Text fontSize='md' color={textColor} fontWeight='bold' me='10px'>
                                    {caption}{" "}
                                    </Text>
                                    <Text fontSize='md' color='gray.500' fontWeight='400'>
                                    {values.at(index)}
                                    </Text>
                                </Flex>
                            )
                        })
                    }
                </Flex>
            </CardBody>
        </Card>
    );
}

const myPropsChanged = (prevProps, nextProps) => {
    if ( prevProps.values !== nextProps.values ) {
        return false;
    }
    return true;
}

export default React.memo(InformationCard, myPropsChanged);
