// React imports
import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom'
// Chakra imports
import {
	Image,
	Icon,
	Button,
	Flex,
	Td,
	Text,
	Tr,
	useColorModeValue,
	Checkbox
} from "@chakra-ui/react";
// React-icons
import { FaRegFutbol } from 'react-icons/fa'


function TeamsTableRow({ id, name, team_logo, college,coaches, actions, suscription_name, suscription_premium_end_date,sport,genre,category, selectItem, setSelectItem }) {
	// Chakra color mode
	const textColor = useColorModeValue("gray.700", "white");
	const titleColor = useColorModeValue('royalblue.700');
	// navigate hook
	let navigate = useNavigate()

	// Function that handles the selection of an item
	const handleSelectItem = (e) => {
		setSelectItem(e.target.checked ? [...selectItem, id] : selectItem.filter(item => item !== id))
	}

	const [isSelected, setIsSelected] = useState(false)
	useEffect(() => {
		selectItem?.find((item) => item === id) ? setIsSelected(true) : setIsSelected(false)
	}, [selectItem])



	return (
		<Tr>
			{
				selectItem &&(
					<Td>
						<Checkbox colorScheme='blue' onChange={(e)=>(handleSelectItem(e))} isChecked={isSelected} />
					</Td>
				)
			}
			{/* First column */}
			<Td pl="0px" onClick={()=>(navigate(`/admin/teams/show/${id}`))} style={{cursor:"pointer"}}>
				<Flex align="center" py=".2rem" minWidth="100%" flexWrap="nowrap">
					{team_logo
						? <Image src={team_logo} w="90px" borderRadius="12px" />
						: <Icon as={FaRegFutbol} />
					}
				</Flex>
			</Td>
			{/* Second column */}
			<Td onClick={()=>(navigate(`/admin/teams/show/${id}`))} style={{cursor:"pointer"}}>
				<Text
					fontSize="sm"
					color={textColor}
					fontWeight="bold"
					minWidth="100%"
				>
					{name}
				</Text>
				<Text fontSize="sm" color="gray.400" fontWeight="normal">
					{genre}'s {sport} Team
				</Text>
			</Td>
			{/* Third & fourth column */}
			{
				college && (
					<>
					<Td >
						<Text fontSize="sm" color="gray.400" fontWeight="normal">
							{category ? (category.includes('Not found') ? 'Not specified' : category) : 'Not specified'}
						</Text>
					</Td>
					<Td onClick={()=>(navigate(`/admin/colleges/show/${college.id}`))} style={{cursor:"pointer"}}>
						<Text fontSize="sm" color="gray.400" fontWeight="normal">
							{college.name}
						</Text>
					</Td>
					<Td>
						{
							coaches 
							? coaches?.slice(0,5).map((coach,idx)=>{
								return(
									
									<Flex
									align='center'
									bg='hsla(0,0%,100%,.3)'
									borderRadius='15px'
									justifyContent='center'
									py='5px'
										boxShadow='inset 0 0 1px 1px hsl(0deg 0% 100% / 90%), 0 20px 27px 0 rgb(0 0 0 / 5%)'
										border='1px solid gray.200'
										m='5px'
										cursor='pointer'
										key={idx}
									>
										<Button p='0px' bg='transparent' _hover={{ bg: "none" }} onClick={()=>(navigate(`/admin/coaches/show/${coach.id}`))}>
											<Text
												fontSize='sm'
												color="gray.400"
												fontWeight='bold'
												ms='6px'
												padding={5}
												>
												{coach.name}
											</Text>
										</Button>
									</Flex>
								)
							})
							: null
						}
					</Td>
					</>
				)	
			}
			<Td >
				<Text fontSize="sm" color="gray.400" fontWeight="normal">
					{actions}
				</Text>
			</Td>
			{/* Fifth column */}
			<Td >
				<Text fontSize="sm" color="gray.400" fontWeight="normal">
					{suscription_name}
				</Text>
				<Text fontSize="sm" color="gray.400" fontWeight="normal">
					{suscription_premium_end_date}
				</Text>
			</Td>
			{/* Sixth column */}
			<Td >
				<Text fontSize="sm" color="gray.400" fontWeight="normal">
					{sport}
				</Text>
			</Td>

		</Tr>
	);
}

export default TeamsTableRow;
