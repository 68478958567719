import React, { useContext }  from "react";
import { DataContext } from '../../contexts/dataContext'
import { Box, Flex, Icon, Text, useColorModeValue } from "@chakra-ui/react";
import {MdNewReleases} from 'react-icons/md' 
import {useNavigate} from 'react-router-dom'

function TitleCoachDateRow(props) {
	const { coach, title, date, index, arrLength, icon, text } = props;
	const textColor = useColorModeValue("gray.700", "white.300");
	const bgIconColor = useColorModeValue("white.300", "gray.700");

	let navigate = useNavigate();

	const dataContext = useContext(DataContext)
	const { teams } = dataContext

	return (
		<Flex alignItems="center" justifyContent="start" mb="5px">
			<Flex direction="column" h="100%">
				{icon}
				<Box
					w="2px"
					bg="gray.200"
					h={index === arrLength - 1 ? "40px" : "100%"}
				></Box>
			</Flex>
			<Flex direction="column" justifyContent="flex-start" h="100%">
				<Text fontSize="sm" color={textColor} fontWeight="bold">
					{title}
				</Text>
				<Text fontSize="sm" color={text ? textColor : "gray.400"} fontWeight={text ? "bold" : "normal"} cursor="pointer" onClick={()=>navigate(`/admin/coaches/activity/${coach.id}`)}>
					{coach?.name} - {coach?.contact_email}
				</Text>
				<Text fontSize="sm" color="gray.400" fontWeight="normal">
					{teams.find(team => team.id === coach?.teamID)?.name}
				</Text>
				{
					text &&
					<Text fontSize="sm" color="gray.400" fontWeight="normal">
						<i><strong>Feedback:</strong></i> {text}
					</Text>
				}
				<Text fontSize="sm" color="gray.400" fontWeight="normal">
					{date}
				</Text>
			</Flex>
		</Flex>
	);
}

export default TitleCoachDateRow;
