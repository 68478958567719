// React imports
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom'
// Datacontext
import { DataContext } from "contexts/dataContext";
// Chakra imports
import {
	Image,
	Icon,
	Button,
	Flex,
	Td,
	Text,
	Tr,
	useColorModeValue,
	Checkbox,
    AvatarGroup,
    Avatar,
	useDisclosure,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	NumberIncrementStepper,
	NumberDecrementStepper
} from "@chakra-ui/react";
// React-icons
import { ImUserPlus } from 'react-icons/im'
// Helpers
import { changeUserGroupToAgencyId } from "helpers";
import { colorsOptions } from "helpers";
import { createShowcaseAthleteRelationService } from "services/showcaseServices";
import { v4 as uuidv4 } from 'uuid';
// React-select imports
import Select from 'react-select';
import makeAnimated from 'react-select/animated';


function ShowcaseTableRow({ id, name, description, country,address, date, selectItem, setSelectItem ,setActionStatus}) {
	// Chakra color mode
	const textColor = useColorModeValue("gray.700", "white");
	const animatedComponents = makeAnimated();

    // navigate hook
	let navigate = useNavigate()

	// Add participant modal actions
	const addParticipantModal = useDisclosure()

	// State that handles the selection of items (athlete, number and color)
	const [ selectParticipant, setSelectParticipant ] = useState([])

	
	// Take the context data needed in this component    
	const dataContext = useContext(DataContext)
	const { user,userCoachData,showcases,showcasesAthleteRelation,setShowcasesAthleteRelation,athletes,agents } = dataContext	
	
	// Cognito user group
	const userGroup = changeUserGroupToAgencyId(user?.signInUserSession.idToken.payload["cognito:groups"][0])

	// Associate participants
	const showcase = showcases.find(show=>show.id === id)
	let athletesOfShowcase = athletes.filter(ath => showcasesAthleteRelation.find(showAth => showAth.athleteID === ath.id && showAth.showcaseID === id))
	// const coachesOfShowcase = coaches.filter(coach => showcasesCoachRelation.find(showCoach => showCoach.coachID === coach.id && showCoach.showcaseID === id))
	let participants = userGroup === 'superadmins' ? [...athletesOfShowcase] : athletesOfShowcase.filter(ath => ath.agencyID === userGroup)
	
	// I need to search again the participants if showcasesAthleteRelation changes in other screen
	useEffect(() => {
		athletesOfShowcase = athletes.filter(ath => showcasesAthleteRelation.find(showAth => showAth.athleteID === ath.id && showAth.showcaseID === id))
		// const coachesOfShowcase = coaches.filter(coach => showcasesCoachRelation.find(showCoach => showCoach.coachID === coach.id && showCoach.showcaseID === id))
		participants = userGroup === 'superadmins' ? [...athletesOfShowcase] : athletesOfShowcase.filter(ath => ath.agencyID === userGroup)	
	}, [showcasesAthleteRelation])
	
	// Function that handles the selection of an item
	const handleSelectItem = (e) => {
		setSelectItem(e.target.checked ? [...selectItem, id] : selectItem.filter(item => item !== id))
	}

	const [isSelected, setIsSelected] = useState(false)
	useEffect(() => {
		selectItem?.find((item) => item === id) ? setIsSelected(true) : setIsSelected(false)
	}, [selectItem])

	const handleAddParticipant = () => {
		addParticipantModal.onClose()

		let hasFailed = false
		let newRels = [...showcasesAthleteRelation]
		selectParticipant.forEach(p => {
			const newRelation = {
				id: uuidv4(),
				athleteID:p,
				showcaseID:showcase?.id,
			}
			
			// Create and store in context the new athlete-showcase relation
			createShowcaseAthleteRelationService(newRelation).catch((err)=>{
				console.log("[ERROR] Create showcase athlete relation:",err)
				hasFailed = true
			})
			newRels = [...newRels, newRelation]
		});

		hasFailed ? (
			setActionStatus({
				done: true,
				status: 'error',
				message: 'An error has ocurred while adding participant/s to the showcase'
			})
		) : (
			setShowcasesAthleteRelation([...newRels]),
			setActionStatus({
				done: true,
				status: 'success',
				message: 'Participant/s added successfully'
			})
		)
	}

	const getOptions=()=>{
		let options = []
		if (userGroup === 'superadmins'){
			// User is superadmin
			options = athletes?.filter(it => !showcasesAthleteRelation.find(rel=>rel.athleteID === it.id && rel.showcaseID === id)).map((opt,index) => ({ value: opt.id, label: opt.name }))
		}else if (userCoachData.agent_role==="LEADER"){
			// User is agent leader
			options = athletes?.filter(ath=>ath.agencyID === userGroup && !showcasesAthleteRelation.find(rel=>rel.athleteID === ath.id && rel.showcaseID === id) ).map((opt,index) => ({ value: opt.id, label: opt.name }))
		}else if(userCoachData.agent_role==="ASSOCIATE"){
			// User is agent associate
			// ath.agentID === agents.find(it=>it.contact_email === userCoachData.contact_email)?.id
			options = athletes?.filter(ath=>ath.agencyID === userGroup && !showcasesAthleteRelation.find(rel=>rel.athleteID === ath.id && rel.showcaseID === id) ).map((opt,index) => ({ value: opt.id, label: opt.name }))
		}
		
		return options
	}

	return (
		<>
			<Modal
				isCentered
				onClose={addParticipantModal.onClose}
				isOpen={addParticipantModal.isOpen}
				motionPreset='slideInBottom'
			>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Add participant</ModalHeader>
					<ModalCloseButton onClick={()=>{addParticipantModal.onClose();setSelectParticipant([])}}/>
					<ModalBody>
						Then add a participant and complete the required information.<br/>
						<strong>Select one or more participants from the following athletes: </strong>
						{/* <Select placeholder="Select PSAs" my="10px" bg="#EBF5FB" variant='Filled' value={selectParticipant.athlete} onChange={(e)=>setSelectParticipant({...selectParticipant, athlete:e.target.value})}>
							{userGroup === 'superadmins' && athletes?.filter(it => !showcasesAthleteRelation.find(rel=>rel.athleteID === it.id)).map((opt,index) => {return (<option key={index} value={opt.id} color="royalblue">{opt.name}</option>)})}
							{userCoachData.agent_role==="LEADER" && athletes?.filter(ath=>ath.agencyID === userGroup && !showcasesAthleteRelation.find(rel=>rel.athleteID === ath.id) ).map((opt,index) => {return (<option key={index} value={opt.id} color="royalblue">{opt.name}</option>)})}
							{userCoachData.agent_role==="ASSOCIATE" && athletes?.filter(ath=>ath.agentID === agents.find(it=>it.contact_email === userCoachData.contact_email)?.id  && !showcasesAthleteRelation.find(rel=>rel.athleteID === ath.id) ).map((opt,index) => {return (<option key={index} value={opt.id} color="royalblue">{opt.name}</option>)})}
						</Select>  */}

						<Select
							closeMenuOnSelect={false}
							isMulti
							components={animatedComponents}
							placeholder="Select participants"
							isSearchable={true}
							options={getOptions()}
							onChange={(selectedOptions) => setSelectParticipant(()=>(selectedOptions.map(c => c.value)))}
						/>

						{/* <strong>Enter the number that the player will wear: </strong>
						<NumberInput min={1} max={25} onChange={(value)=>setSelectParticipant({...selectParticipant, number:Number(value)})}>
							<NumberInputField />
							<NumberInputStepper>
								<NumberIncrementStepper />
								<NumberDecrementStepper />
							</NumberInputStepper>
						</NumberInput>

						<strong>Enter the color that the player will wear: </strong>
						<Select placeholder="Select color" my="10px" bg="#EBF5FB" variant='Filled' value={selectParticipant.color} onChange={(e)=>setSelectParticipant({...selectParticipant, color:e.target.value})}>
							{colorsOptions.map((opt,index) => {return (<option key={index} value={opt.value} color="royalblue">{opt.label}</option>)})}
						</Select> */}

					</ModalBody>
					<ModalFooter>
						<Button colorScheme='red' mr={3} onClick={()=>{addParticipantModal.onClose();setSelectParticipant([])}}>
							Cancel
						</Button>
						<Button variant='ghost' colorScheme='blue' onClick={handleAddParticipant} isDisabled={selectParticipant.length === 0}>Add participant</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>

			<Tr>
				<Td w="2%">
					<Checkbox colorScheme='blue' onChange={(e)=>(handleSelectItem(e))} isChecked={isSelected} />
				</Td>
				{/* First column */}
				<Td w="40%" onClick={()=>(navigate(`/admin/showcases/show/${id}`))} style={{cursor:"pointer"}}>
					<Text
						fontSize="sm"
						color={textColor}
						fontWeight="bold"
						minWidth="100%"
					>
						{name}
					</Text>
					<Text fontSize="sm" color="gray.400" fontWeight="normal">
						{description}
					</Text>
				</Td>
				{/* Second column */}
				<Td w="20%">
					<Flex direction="column">
						<Text fontSize="sm" color="gray.400" fontWeight="normal">
							{country}
						</Text>
						<Text fontSize="sm" color="gray.400" fontWeight="normal">
							{address}
						</Text>
						<Text fontSize="sm" color="gray.400" fontWeight="normal">
							{date}
						</Text>
					</Flex>
				</Td>
				{/* Fourth column */}
				<Td w="20%">
					<AvatarGroup size='md' max={2}>
						{participants.map(it => {
							return (
								<Avatar key={it.id} name={it.name} src={it.profile_pic} />
							)
						})}
					</AvatarGroup>
				</Td>
				{/* Fifth column */}
				<Td w="10%">
					<Button variant='ghost' colorScheme="blue" leftIcon={<Icon as={ImUserPlus} />} onClick={addParticipantModal.onOpen}>Add PSA</Button>
				</Td>

			</Tr>
		</>
	);
}

export default ShowcaseTableRow;
