// React imports
import React, { useContext, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom';
// Context imports
import { DataContext } from "../../../../contexts/dataContext";
// Chakra imports
import {
	Image,
	Box,
	Button,
	Flex,
	Text,
	useColorModeValue,
	Icon,
	Alert,
	AlertIcon,
	CloseButton,
	useDisclosure,
} from "@chakra-ui/react";
import { FaPen } from "react-icons/fa";
import { BsGenderFemale, BsGenderMale } from "react-icons/bs";
// Component 
import { ConfirmModal } from "../../../../components/ConfirmModal/ConfirmModal";
import { DeleteButton } from "../../../../components/Buttons/DeleteButton";
// Auxiliary imports
import { changeUserGroupToAgencyId } from "../../../../helpers";
import { deleteAgentService } from "../../../../services/agentServices";
import { deleteCoachRelations, deleteCoachService } from "../../../../services/coachServices";
import { deleteUserCognito } from "../../../../services/cognitoServices";
import { deleteSesIdentity } from 'services/sesServices';
  
const Header = ({
	id,
	backgroundHeader,
	backgroundProfile,
	profilePic,
	name,
	email,
	phone,
	gender,
	agency,
	agent_role
}) => {
	// Chakra color mode
	const textColor = useColorModeValue("gray.700", "white");
	const borderProfileColor = useColorModeValue("white","rgba(255, 255, 255, 0.31)");
	const emailColor = useColorModeValue("gray.400", "gray.300","royalblue.700");
	
	// Variable with the gender icon of the agent
	const genderIcon = gender === 'male' ? <Icon as={BsGenderMale} h="15px" /> : <Icon as={BsGenderFemale} h="15px" />
	
	// Take the context data needed in this component
    const dataContext = useContext(DataContext)
    const { user,userCoachData, agents, setAgents, coaches, setCoaches, coachesCognito, setCoachesCognito, athletes } = dataContext
	// State that controls if the user has permission to do an action
	const [noPermission, setNoPermission] = useState(false)
	// State that controls if the agent can be deleted or not.
	const [canDelete, setCanDelete] = useState(true)
	// Navigate hook
	let navigate = useNavigate()

	// Hook for the confirm modal
	const { isOpen, onOpen, onClose } = useDisclosure()
	const cancelRef = useRef()

	// Function that handles the edit action
	const handleEdit = () => {
		const userGroup = changeUserGroupToAgencyId(user.signInUserSession.idToken.payload["cognito:groups"][0])
		console.log(userGroup === agency.id && userCoachData.agent_role==="LEADER")
		console.log(userGroup )
		console.log(userCoachData.agent_role)
		userGroup === "superadmins" ||(userGroup === agency.id && userCoachData.agent_role==="LEADER")
		? navigate(`/admin/agents/edit/${id}`) 
		: setNoPermission(true)

	}

	// Function that handles the delete action
	const handleDelete = () => {
		const userGroup = changeUserGroupToAgencyId(user.signInUserSession.idToken.payload["cognito:groups"][0])
		if(athletes.find(ath => ath.agentID === id)){
			setCanDelete(false)
		}else{
			userGroup === "superadmins" 
			? onOpen()
			: setNoPermission(true)
		}
	}
	// Function that handles the confirm delete action
	const handleConfirmDelete = () => {
		onClose()
		deleteAgentService(id)
		.then(res => {
			let associatedCoach = coaches.find(coach => coach.contact_email === email)
			deleteCoachService(associatedCoach.id)
			.then(res => {
				deleteUserCognito(coachesCognito.find(it=>it.Attributes.find(it => it['Name']==="email")['Value'] === associatedCoach.contact_email).Username)
				deleteCoachRelations(dataContext,associatedCoach.id).then(res=>res && (hasFailed = true))
			})
			.catch(err => console.log(err))
			setCoaches(coaches.filter(coach => coach.id !== associatedCoach.id))
			setCoachesCognito(coachesCognito.filter(item => item.Attributes.at(-2).Value !== associatedCoach.id))
			deleteSesIdentity(associatedCoach.contact_email)
				.then(()=>console.log("[Delete agent]: SES identity deleted."))
				.catch((err)=>console.log(`[Delete agent]: SES deletion error. ${err}`))
		})
		.catch(err => console.log(err))
		setAgents(agents.filter(agent => agent.id !== id))
		navigate(`/admin/agents/`)
	}

	return (
		<>
			<ConfirmModal
				title={`Delete ${name}`}
				description="Are you sure? You can't undo this action afterwards."
				button1="Cancel"
				button2="Delete"
				isOpen={isOpen}
				onClose={onClose}
				cancelRef={cancelRef}
				handleDelete={handleConfirmDelete}
			/>	
			<Flex justifyContent='right' m="0px 10px 10px 0">
				<DeleteButton handleDelete={handleDelete} />
			</Flex>
			{
				!canDelete
				&& (
					<Alert status='error' borderRadius="10px" mb="20px">
						<AlertIcon />
						Warning! One of the agents you wish to delete still has PSAs associated with it and this action is going to be canceled.
						<CloseButton position='absolute' right='8px' top='8px' onClick={()=>(setCanDelete(true))} />
					</Alert>
				)
			}
			{
				noPermission
				&& (
					<Alert status='error' borderRadius="10px" mb="20px">
						<AlertIcon />
						You don't have enough permissions to perform this action on this agency.
						<CloseButton position='absolute' right='8px' top='8px' onClick={()=>(setNoPermission(false))} />
					</Alert>
				)
			}
			<Box
				mb={{ sm: "20px", md: "30px", xl: "40px" }}
				borderRadius='15px'
				px='0px'
				display='flex'
				flexDirection='column'
				justifyContent='center'
				align='center'
			>
				<Box
				bgImage={backgroundHeader}
				bgSize='cover'
				w='100%'
				h='200px'
				borderRadius='25px'
				bgPosition='50%'
				bgRepeat='no-repeat'
				position='relative'
				display='flex'
				justifyContent='center'>
					<Flex
						direction={{ sm: "column", md: "row" }}
						mx='1.5rem'
						maxH='330px'
						w={{ sm: "90%", xl: "95%" }}
						justifyContent={{ sm: "center", md: "space-between" }}
						align='center'
						backdropFilter='saturate(200%) blur(50px)'
						position='absolute'
						boxShadow='0px 2px 5.5px rgba(0, 0, 0, 0.02)'
						border='2px solid'
						borderColor={borderProfileColor}
						bg={backgroundProfile}
						p='24px'
						borderRadius='20px'
						transform={{
							md: "translateY(35%)",
							lg: "translateY(40%)",
						}}
					>
						
						<Flex direction='column'>

							<Flex
								align='center'
								mb={{ sm: "10px", md: "0px" }}
								direction={{ sm: "column", md: "row" }}
								w={{ sm: "100%" }}
								textAlign={{ sm: "center", md: "start" }}
							>
								<Image
									me={{ md: "22px" }}
									src={profilePic}
									w='150px'
									h='150px'
									borderRadius='15px'
									fit='cover'
								/>
								<Flex direction='column' maxWidth='100%' my={{ sm: "10px" }}>
									<Text
									fontSize={{ sm: "lg", lg: "xl" }}
									color={textColor}
									fontWeight='bold'
									ms={{ sm: "8px", md: "0px" }}>
										{name} | {genderIcon}
									</Text>
									<Text
									fontSize={{ sm: "sm", md: "md" }}
									color={emailColor}
									fontWeight='semibold'>
										{email}
									</Text>
									<Text
									fontSize={{ sm: "sm", md: "md" }}
									color={emailColor}
									fontWeight='semibold'>
										{phone}
									</Text>
									<Text
									fontSize={{ sm: "sm", md: "md" }}
									color={emailColor}
									fontWeight='semibold'>
										{agent_role ? `${agent_role.charAt(0)+agent_role.slice(1).toLowerCase()} agent` : "Don't set yet." }
									</Text>
									<Flex direction={{ sm: "column", md: "column", lg:"row" }}>
										<Image src={agency.logo} w='25px' m={{ sm: "3px", md: "5px" }} onClick={()=>(navigate(`/admin/agencies/show/${agency.id}`))} style={{cursor:'pointer'}}/>
										<Text
											fontSize={{ sm: "sm", md: "md" }}
											color={textColor}
											fontWeight='semibold'
											m={{ sm: "3px", md: "5px" }}
											onClick={()=>(navigate(`/admin/agencies/show/${agency.id}`))} style={{cursor:'pointer'}}	
											>
											{agency.name}
										</Text>
									</Flex>
								</Flex>
							</Flex>
						</Flex>


						<Flex
							direction={{ sm: "column", lg: "row" }}
							w={{ sm: "100%", md: "50%", lg: "auto" }}
							
						>	
							<Button p='0px' bg='transparent' _hover={{ bg: "none" }} onClick={handleEdit} >
								<Flex
								align='center'
								w={{ sm: "100%", lg: "135px" }}
								bg='hsla(0,0%,100%,.3)'
								borderRadius='15px'
								justifyContent='center'
								py='10px'
								boxShadow='inset 0 0 1px 1px hsl(0deg 0% 100% / 90%), 0 20px 27px 0 rgb(0 0 0 / 5%)'
								border='1px solid gray.200'
								cursor='pointer'>
									<FaPen w='100%' h='100%' />
									<Text
										fontSize='xs'
										color={textColor}
										fontWeight='bold'
										ms='6px'>
										EDIT
									</Text>
								</Flex>
							</Button>
						</Flex>
					</Flex>
				</Box>
			</Box>
		</>
	);
};
  
  export default Header;
  