import React, { useContext } from 'react'
import Header from './Header'
import ProfileBgImage from "assets/img/ProfileBackground.png";
import { Box, CircularProgress, Flex, Image, Tab, TabList, TabPanel, TabPanels, Tabs, Text, useColorModeValue } from '@chakra-ui/react';
import Card from 'components/Card/Card';
import { AthleticInfo } from 'views/Dashboard/Athletes/AthleteInfoContent/AthleticInfo';
import { ShowCaseInfo } from 'views/Dashboard/Athletes/AthleteInfoContent/ShowCaseInfo';
import { Education } from 'views/Dashboard/Athletes/AthleteInfoContent/Education';
import { ActionsReceivedByCoaches } from 'views/Dashboard/Athletes/AthleteInfoContent/ActionsReceivedByCoaches';
import { DataContext } from 'contexts/dataContext';
import { Categorization } from 'views/Dashboard/Athletes/AthleteInfoContent/Categorization';


const UniqueAthleteInfo = ({athlete, allowUser}) => {

    const dataContext = useContext(DataContext)
    const { athletes, teams, coachTagRelations } = dataContext

    const bgProfile = useColorModeValue(
		"hsla(0,0%,100%,.8)",
		"linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
	);

    const athletesWithSameCategory = athletes?.filter( it => it.id !== athlete.id && it.category === athlete.category && it.current_status === 'COMMITTED' && it.destination_college).slice(0,5)

    return (
        <Box
            w="90%"
            mx="auto"
            my={{ base: "3%", md: "2%", lg: "1%"}}
        >
            <Image 
                h="60px" mb="20px"alt="Alliance Athletes Logo"
                src="https://res.cloudinary.com/dsvz8cktr/image/upload/v1624448821/LOGOFINAL_bl0pua.png"
            />
            <Header 
                id={athlete?.id}
                backgroundHeader={ProfileBgImage}
                backgroundProfile={bgProfile}
                profile_pic={athlete?.profile_pic}
                name={athlete?.name}
                gender={athlete?.gender}
                contact_email={athlete?.contact_email}
                contact_phone={athlete?.contact_phone}
                birthdate={athlete?.birthdate}
                agency={athlete.agency}
                agent={athlete?.agent}
                isVisible={athlete?.isVisible}
                agentID={athlete?.agentID}
                allowUser={allowUser}
                isLoaded={teams.length > 0 && athletes.length > 0}
            />
            <Card overflowX={{ sm: "scroll", xl: "hidden" }}  mt={{ sm: "40%", md: "32%",lg:"10px" }}>
                <Tabs variant='soft-rounded' colorScheme={athlete?.sport === "Tennis" ? "green" : "blue"}>
                    <TabList>
                        <Tab>Athletic information</Tab>
                        <Tab>Showcase participation</Tab>
                        <Tab>Education</Tab>
                        <Tab>Categorization</Tab>
                        <Tab>Exposure</Tab>
                    </TabList>

                    <TabPanels>
                        <TabPanel>
                            <AthleticInfo athlete={athlete && athlete}/>
                        </TabPanel>

                        <TabPanel>
                            <ShowCaseInfo athlete={athlete && athlete}/>
                        </TabPanel>

                        <TabPanel>
                            <Education athlete={athlete && athlete}/>
                        </TabPanel>

                        <TabPanel>
                            {/* <SocialNetworks athlete={athlete && athlete}/> */}
                            {
                                (teams.length > 0 && athletes.length > 0) ? (

                                    <Categorization
                                    athlete={athlete && athlete} 
                                    categoryExampleTeams={teams.filter(it=>((it.college_category === athlete.category) && (it.genre === athlete.gender === 'male' ? 'Men':'Women'))).slice(0,5)} 
                                    athletesWithSameCategory={athletesWithSameCategory}
                                    />
                                ) : (
                                    <Flex
                                        w="100%"
                                        h="100%"
                                        justifyContent="center"
                                        alignItems="center"
                                        display="flex"
                                        flexDirection="column"
                                        textAlign="center"
                                    >
                                        <CircularProgress isIndeterminate color='royalblue' />
                                        <Text fontSize={{ sm: "sm", lg: "md" }} color='gray.400' fontWeight="600" letterSpacing="2px" mt="10px">
                                        Fetching categorization <br />information...
                                        </Text>
                                    </Flex>
                                )
                            }
                        </TabPanel>

                        <TabPanel>
                            {coachTagRelations.length > 0 &&  <ActionsReceivedByCoaches favorites={athlete && athlete.favorited?.items} enquiries={athlete && athlete.enquiries?.items} tags={athlete && athlete.tags?.items} coachTagsRel={coachTagRelations} />} 
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Card>
        </Box>
    )
}

export default UniqueAthleteInfo