//Context
import React, { createContext, useState, useEffect } from "react";

import { API, Auth, Hub } from "aws-amplify";
var AWS = require("aws-sdk");
import awsmobile from "../aws-exports";
import { useNavigate } from "react-router-dom";
import {
  shuffle,
  filter,
  each,
  find,
  uniqBy,
  sortBy,
  toLower,
  size,
  indexOf,
} from "lodash";

//Grapql Queries
import { listAgencys, getAgency } from "../custom_graphql_queries/agencies";
import { listAgents } from "../custom_graphql_queries/agents";
import {
  listCoachs,
  listColleges,
  listTeams,
  listFeedbacks,
  listNotifications,
  listCoachNotificationRelations,
} from "../graphql/queries";
import {
  listAthletes,
  listAthleteEnquirys,
  listFavoritesFolderAthleteRelations,
  listFavoritesFolders,
} from "../custom_graphql_queries/athletes";
import {
  listCoachMessages,
  listCoachAthleteFavoritess,
  listAuxCollegeCoachInfo,
} from "../custom_graphql_queries/coaches";
import { listSearchTerms } from "../custom_graphql_queries/searchTerms";
import { getCoach } from "../custom_graphql_queries/coaches";

// import Fuse from 'fuse.js'
import selectorsContentRaw from "./selectorsContent";
import { listShowcases } from "custom_graphql_queries/showcases";
import { listShowcaseAthleteRelations } from "custom_graphql_queries/showcases";
import { listShowcaseCoachRelations } from "custom_graphql_queries/showcases";
import { listAthleteTagsRelations } from "custom_graphql_queries/tags";
import { listCoachTagsRelations } from "custom_graphql_queries/tags";
import { startDate } from "helpers";
import { getAgenciesUtilities } from "helpers";
import { updateSDKToken } from "services/cognitoServices";
import { checkExpiredAndRefreshToken } from "services/cognitoServices";

export const DataContext = createContext();

const DataContextProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [errorTextValue, setErrorTextValue] = useState(null);
  const [userCoachData, setUserCoachData] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [agencies, setAgencies] = useState([]);
  const [numberOfAgencies, setNumberOfAgencies] = useState(-1); //Need for checking loaded. We can remove if upgrade api
  const [agents, setAgents] = useState([]);
  const [colleges, setColleges] = useState([]);
  const [teams, setTeams] = useState([]);
  const [coaches, setCoaches] = useState([]);
  const [auxCoaches, setAuxCoaches] = useState([]); // Auxiliar info for coaches
  const [coachesCognito, setCoachesCognito] = useState([]);
  const [athletes, setAthletes] = useState([]);
  const [athletesEnquirys, setAthletesEnquirys] = useState([]);
  const [searchTerms, setSearchTerms] = useState([]);
  const [feedbacks, setFeedbacks] = useState([]);
  const [coachMessages, setCoachMessages] = useState([]);
  const [coachAthleteFavorites, setCoachAthleteFavorites] = useState([]);
  const [templatesNotifications, setTemplatesNotifications] = useState([]);
  const [showcases, setShowcases] = useState([]);
  const [showcasesAthleteRelation, setShowcasesAthleteRelation] = useState([]);
  const [showcasesCoachRelation, setShowcasesCoachRelation] = useState([]);
  const [athleteTagRelations, setAthleteTagRelation] = useState([]);
  const [coachTagRelations, setCoachTagRelation] = useState([]);
  const [favoritesFolders, setFavoritesFolder] = useState([]);
  const [
    favoriteFolderAthleteRelation,
    setFavoriteFolderAthleteRelation,
  ] = useState([]);
  const [coachNotificationRelations, setCoachNotificationRelations] = useState(
    []
  );
  const [notifications, setNotifications] = useState([]);

  const [isDataProcessed, setIsDataProcessed] = useState(false);
  const [isActionsProcessed, setIsActionsProcessed] = useState(false);
  const [isActionsTeamsProcessed, setIsActionsTeamsProcessed] = useState(false);
  const [searchIdx, setSearchIdx] = useState(null);

  const [filteredAthletes, setFilteredAthletes] = useState([]); //Athletes after filtering and search
  const [athletesFilters, setAthletesFilters] = useState({
    visible: 0,
    visibleData: ["ALL", "Active", "Hidden"],
    status: 0,
    statusData: ["ALL", "AVAILABLE", "ON_HOLD", "COMMITTED"],
    position: 0,
    positionData: [
      "ALL",
      "GOALKEEPER",
      "CENTER_BACK",
      "RIGHT_BACK",
      "LEFT_BACK",
      "DEFENSIVE_MIDFIELDER",
      "CENTER_MIDFIELDER",
      "ATTACKING_MIDFIELDER",
      "RIGHT_WINGER",
      "LEFT_WINGER",
      "FORWARD_STRIKER",
    ],
    sport: 0,
    sportData: ["ALL", "Soccer", "Tennis", "Not specified"],
    agency: 0,
    agencyData: ["ALL", ...getAgenciesUtilities().map((ag) => ag.acronym)],
    semester: 0,
    semesterData: ["ALL", ...startDate.map((it) => it.label)],
    agent: "0",
    agentData: [{ value: 0, label: "ALL" }],
  });

  const [selectorsContent, setSelectorContent] = useState({});
  const [premiumCoaches, setPremiumCoaches] = useState([]);

  const [pagination, setPagination] = useState({
    total: 1,
    page: 1,
    rowsPerPage: 1,
    lower: 1,
  });

  const updateUser = (newUser) => {
    setUser(newUser);
  };

  const updateLoading = (newLoading) => {
    setLoading(newLoading);
  };

  const navigate = useNavigate();

  //     _____ _               _
  //    / ____| |             | |
  //   | |    | |__   ___  ___| | __  _   _ ___  ___ _ __
  //   | |    | '_ \ / _ \/ __| |/ / | | | / __|/ _ \ '__|
  //   | |____| | | |  __/ (__|   <  | |_| \__ \  __/ |
  //    \_____|_| |_|\___|\___|_|\_\  \__,_|___/\___|_|
  //

  //This effect executes once, when user enter to the app.
  useEffect(() => {
    //First we check if user is logged in.
    authCheckUser();
    // then we listen auth changes thanks to AWS Hub tool.
    setAuthListener();
  }, []);

  //is user is loaded, load data
  useEffect(() => {
    if (user !== null && user !== "notLoggedIn") {
      if ("cognito:groups" in user.signInUserSession.idToken.payload) {
        loadAllData();
      } else {
        localStorage.clear();
        setErrorTextValue(
          "You don't have enough permission to access this page."
        );
      }
    }
  }, [user]);

  const setAuthListener = async () => {
    Hub.listen("auth", (data) => {
      switch (data.payload.event) {
        case "signIn":
          //If user change a loged in, usualy he is in /login. We update user in dataContext and goes to /app
          if (
            "cognito:groups" in
            data.payload.data.signInUserSession.idToken.payload
          ) {
            updateUser(data.payload.data);
            navigate("/admin/");
          } else {
            localStorage.clear();
            setErrorTextValue(
              "You don't have enough permission to access this page."
            );
          }
          break;
        case "signOut":
          //If user change a loged out, we update user in dataContext. After this change, Router automaticaly moves user to /login
          updateUser("notLoggedIn");
          break;

        default:
          break;
      }
    });
  };

  //    _                     _       _       _
  //   | |                   | |     | |     | |
  //   | |     ___   __ _  __| |   __| | __ _| |_ __ _
  //   | |    / _ \ / _` |/ _` |  / _` |/ _` | __/ _` |
  //   | |___| (_) | (_| | (_| | | (_| | (_| | || (_| |
  //   |______\___/ \__,_|\__,_|  \__,_|\__,_|\__\__,_|
  //

  //When user is logged in, load all data, an then process the data to link connect information between
  const loadAllData = async () => {
    updateLoading(true);
    fetchUserCoachData();
    fetchAgencies();
    fetchColleges();
    fetchTeams()
      .then((teams) => fetchPremiumCoaches(teams))
      .catch((error) => console.error("Error in sequence", error));
    fetchCoaches();
    fetchAuxCoaches();

    setTimeout(() => {
      fetchCognitoUsers();
    }, 1000);
    fetchAgents();
    fetchAthletes();
    fetchAthletesEnquirys();
    fetchSearchTerms();
    fetchFeedback();
    fetchCoachMessages();
    fetchCoachAthleteFavorites();
    fetchTemplatesNotifications();
    fetchShowcases();
    fetchCoachAthleteTagRelation();
    fetchFavoriteFolderAthleteRelation();
    fetchFavoritesFolder();
    fetchCoachNotificationRelations();
  };

  //Check if all data is loaded before process it
  useEffect(() => {
    checkAllLoaded();
  }, [agencies, agents, colleges, coaches, athletes, userCoachData]);

  const checkAllLoaded = () => {
    const agenciesLoaded =
      agencies.length === numberOfAgencies && numberOfAgencies > 0;
    const agentsLoaded = agents.length > 0;
    const collegesLoaded = colleges.length > 0;
    const teamsLoaded = teams.length > 0;
    const coachesLoaded = coaches.length > 0;
    const athletesLoaded = athletes.length > 0;
    const userCoachDataLoaded = userCoachData !== null;
    const athletesEnquirysLoaded = athletesEnquirys.length > 0;

    if (
      athletesEnquirysLoaded &&
      agenciesLoaded &&
      agentsLoaded &&
      collegesLoaded &&
      teamsLoaded &&
      coachesLoaded &&
      athletesLoaded &&
      userCoachDataLoaded
    ) {
      if (!isDataProcessed) {
        setIsDataProcessed(true);
        processAllData();
      } else {
        updateLoading(false);
      }
    }
  };

  //Check if all data is loaded before process it
  useEffect(() => {
    checkAllActionsCoachesLoaded();
    checkAllActionsTeamsLoaded();
  }, [
    athletesEnquirys,
    feedbacks,
    coachMessages,
    coachAthleteFavorites,
    searchTerms,
    coaches,
    teams,
  ]);

  const checkAllActionsCoachesLoaded = () => {
    const athletesEnquirysLoaded = athletesEnquirys.length > 0;
    const feedbacksLoaded = feedbacks.length > 0;
    const coachMessagesLoaded = coachMessages.length > 0;
    const coachAthleteFavoritesLoaded = coachAthleteFavorites.length > 0;
    const searchTermsLoaded = searchTerms.length > 0;
    const coachesLoaded = coaches.length > 0;

    if (
      athletesEnquirysLoaded &&
      feedbacksLoaded &&
      coachMessagesLoaded &&
      coachAthleteFavoritesLoaded &&
      searchTermsLoaded &&
      coachesLoaded
    ) {
      if (!isActionsProcessed) {
        setIsActionsProcessed(true);
        let coachesWithActions = coaches.map((it) => {
          const today = new Date();
          const priorDate = new Date(new Date().setDate(today.getDate() - 30));

          const coachEnquiries = athletesEnquirys
            ?.filter(
              (enq) =>
                enq.coachID === it.id && new Date(enq.createdAt) >= priorDate
            )
            .map((res) => ({ ...res, typeOfAction: "Enquiry" }))
            .filter((it) => athletes.find((ath) => ath.id === it.athleteID));
          const coachFeedbacks = feedbacks
            ?.filter(
              (feed) =>
                feed.coachID === it.id && new Date(feed.createdAt) >= priorDate
            )
            .map((res) => ({ ...res, typeOfAction: "Feedback" }));
          const coachMsgs = coachMessages
            ?.filter(
              (msg) =>
                msg.coachID === it.id && new Date(msg.createdAt) >= priorDate
            )
            .map((res) => ({ ...res, typeOfAction: "Message" }));
          const coachFavs = coachAthleteFavorites
            ?.filter(
              (fav) =>
                fav.coachID === it.id && new Date(fav.createdAt) >= priorDate
            )
            .map((res) => ({ ...res, typeOfAction: "Favorite" }))
            .filter((it) => athletes.find((ath) => ath.id === it.athleteID));
          const coachSearches = searchTerms
            ?.filter(
              (search) =>
                search.coachID === it.id &&
                new Date(search.createdAt) >= priorDate
            )
            .map((res) => ({ ...res, typeOfAction: "Search" }));

          const coachEnquiriesTotal = athletesEnquirys
            ?.filter((enq) => enq.coachID === it.id)
            .map((res) => ({ ...res, typeOfAction: "Enquiry" }))
            .filter((it) => athletes.find((ath) => ath.id === it.athleteID));
          const coachFeedbacksTotal = feedbacks
            ?.filter((feed) => feed.coachID === it.id)
            .map((res) => ({ ...res, typeOfAction: "Feedback" }));
          const coachMsgsTotal = coachMessages
            ?.filter((msg) => msg.coachID === it.id)
            .map((res) => ({ ...res, typeOfAction: "Message" }));
          const coachFavsTotal = coachAthleteFavorites
            ?.filter((fav) => fav.coachID === it.id)
            .map((res) => ({ ...res, typeOfAction: "Favorite" }))
            .filter((it) => athletes.find((ath) => ath.id === it.athleteID));
          const coachSearchesTotal = searchTerms
            ?.filter((search) => search.coachID === it.id)
            .map((res) => ({ ...res, typeOfAction: "Search" }));

          return {
            ...it,
            actions: [
              ...coachEnquiries,
              ...coachFeedbacks,
              ...coachMsgs,
              ...coachFavs,
              ...coachSearches,
            ].length,
            totalActions: [
              ...coachEnquiriesTotal,
              ...coachFeedbacksTotal,
              ...coachMsgsTotal,
              ...coachFavsTotal,
              ...coachSearchesTotal,
            ].length,
          };
        });
        setCoaches(coachesWithActions);
      }
    }
  };
  const checkAllActionsTeamsLoaded = () => {
    const athletesEnquirysLoaded = athletesEnquirys.length > 0;
    const feedbacksLoaded = feedbacks.length > 0;
    const coachMessagesLoaded = coachMessages.length > 0;
    const coachAthleteFavoritesLoaded = coachAthleteFavorites.length > 0;
    const searchTermsLoaded = searchTerms.length > 0;
    const teamsLoaded = teams.length > 0;

    if (
      athletesEnquirysLoaded &&
      feedbacksLoaded &&
      coachMessagesLoaded &&
      coachAthleteFavoritesLoaded &&
      searchTermsLoaded &&
      teamsLoaded
    ) {
      if (!isActionsTeamsProcessed) {
        setIsActionsTeamsProcessed(true);
        const today = new Date();
        const priorDate = new Date(new Date().setDate(today.getDate() - 30));
        let teamsWithActions = teams.map((t) => {
          let total = 0;
          t.coaches?.items?.map((c) => {
            const coachEnquiries = athletesEnquirys
              ?.filter(
                (enq) =>
                  enq.coachID === c.id && new Date(enq.createdAt) >= priorDate
              )
              .filter((it) => athletes.find((ath) => ath.id === it.athleteID));
            const coachFeedbacks = feedbacks?.filter(
              (feed) =>
                feed.coachID === c.id && new Date(feed.createdAt) >= priorDate
            );
            const coachMsgs = coachMessages?.filter(
              (msg) =>
                msg.coachID === c.id && new Date(msg.createdAt) >= priorDate
            );
            const coachFavs = coachAthleteFavorites
              ?.filter(
                (fav) =>
                  fav.coachID === c.id && new Date(fav.createdAt) >= priorDate
              )
              .filter((it) => athletes.find((ath) => ath.id === it.athleteID));
            const coachSearches = searchTerms?.filter(
              (search) =>
                search.coachID === c.id &&
                new Date(search.createdAt) >= priorDate
            );
            total =
              total +
              [
                ...coachEnquiries,
                ...coachFeedbacks,
                ...coachMsgs,
                ...coachFavs,
                ...coachSearches,
              ].length;
          });
          return { ...t, actions: total };
        });
        setTeams(teamsWithActions);
      }
    }
  };

  //In these queries we get all the data, not only for listing

  //Coach
  //User Coach Data. Cognito user is linked to coach by custom field profileId
  const fetchUserCoachData = async () => {
    if (user.attributes) {
      try {
        const coach = await API.graphql({
          query: getCoach,
          variables: { id: user.attributes["custom:profile_id"] },
        });
        if (coach.data.getCoach) {
          setUserCoachData(coach.data.getCoach);
        }
      } catch (e) {
        console.log("[ERROR] fetchUserCoachData " + e);
      }
    } else {
      authCheckUser();
    }
  };

  //Agencies
  const fetchAgencies = async () => {
    let agencies_res = [];
    let response = {};
    let params = {
      query: listAgencys,
      variables: { limit: 500 },
    };
    try {
      response = await API.graphql(params); // He make the first request to API
      agencies_res = response.data.listAgencys.items;
      while (response.data.listAgencys.nextToken) {
        //Then repeat the same request with the nexttoken until it's nul'
        params.variables.nextToken = response.data.listAgencys.nextToken;
        response = await API.graphql(params);
        agencies_res = [...agencies_res, ...response.data.listAgencys.items];
      }

      setNumberOfAgencies(agencies_res.length); //to check loading
      setAgencies(agencies_res);
    } catch (e) {
      throw ("fetchagencies ERROR!!!!!", e);
    }
  };

  //Agents
  const fetchAgents = async () => {
    let agents = [];
    let response = {};
    let params = {
      query: listAgents,
      variables: { limit: 500 },
    };
    try {
      response = await API.graphql(params); // He make the first request to API
      agents = response.data.listAgents.items;
      while (response.data.listAgents.nextToken) {
        //Then repeat the same request with the nexttoken until it's nul'
        params.variables.nextToken = response.data.listAgents.nextToken;
        response = await API.graphql(params);
        agents = [...agents, ...response.data.listAgents.items];
      }
      setAthletesFilters({
        ...athletesFilters,
        agentData: [
          ...athletesFilters.agentData,
          agents.map((agent) => {
            return { value: agent.id, label: agent.name };
          }),
        ],
      });
      setAgents(agents);
    } catch (e) {
      throw ("fetchAgents ERROR!!!!!", e);
    }
  };
  //Colleges
  const fetchColleges = async () => {
    let colleges = [];
    let response = {};
    let params = {
      query: listColleges,
      variables: { limit: 500 },
    };
    try {
      response = await API.graphql(params); // He make the first request to API
      colleges = response.data.listColleges.items;
      while (response.data.listColleges.nextToken) {
        //Then repeat the same request with the nexttoken until it's nul'
        params.variables.nextToken = response.data.listColleges.nextToken;
        response = await API.graphql(params);
        colleges = [...colleges, ...response.data.listColleges.items];
      }
      setColleges(colleges);
    } catch (e) {
      throw ("fetchColleges ERROR!!!!!", e);
    }
  };

  //Teams
  const fetchTeams = async () => {
    let teams = [];
    let response = {};
    let params = {
      query: listTeams,
      variables: { limit: 500 },
    };
    try {
      response = await API.graphql(params); // He make the first request to API
      teams = response.data.listTeams.items;
      while (response.data.listTeams.nextToken) {
        //Then repeat the same request with the nexttoken until it's nul'
        params.variables.nextToken = response.data.listTeams.nextToken;
        response = await API.graphql(params);
        teams = [...teams, ...response.data.listTeams.items];
      }
      setTeams(teams);
      return teams;
    } catch (e) {
      throw ("fetchTeams ERROR!!!!!", e);
    }
  };

  const fetchPremiumCoaches = (teams) => {
    let filteredTeams = [];
    if (teams.length > 0) {
      filteredTeams = teams.filter((team) => team.subscriber !== null);
    }

    setPremiumCoaches(
      filteredTeams.map((r) => ({
        ...r.subscriber,
        suscription_recurrency: r.suscription_recurrency,
        subscription_initial_date: r.subscription_initial_date,
      }))
    );

    return filteredTeams;
  };

  //Coaches
  const fetchCoaches = async () => {
    let coaches = [];
    let response = {};
    let params = {
      query: listCoachs,
      variables: { limit: 500 },
    };
    try {
      response = await API.graphql(params);
      coaches = response.data.listCoachs.items;
      while (response.data.listCoachs.nextToken) {
        //Then repeat the same request with the nexttoken until it's nul'
        params.variables.nextToken = response.data.listCoachs.nextToken;
        response = await API.graphql(params);
        coaches = [...coaches, ...response.data.listCoachs.items];
      }
      setCoaches(coaches);
    } catch (e) {
      throw ("fetchCoaches ERROR!!!!!", e);
    }
  };

  //Auxiliar information Coaches
  const fetchAuxCoaches = async () => {
    let aux_coaches = [];
    let response = {};
    let params = {
      query: listAuxCollegeCoachInfo,
      variables: { limit: 500 },
    };
    try {
      response = await API.graphql(params);
      aux_coaches = response.data.listAuxCollegeCoachInfos.items;
      while (response.data.listAuxCollegeCoachInfos.nextToken) {
        //Then repeat the same request with the nexttoken until it's nul'
        params.variables.nextToken =
          response.data.listAuxCollegeCoachInfos.nextToken;
        response = await API.graphql(params);
        aux_coaches = [
          ...aux_coaches,
          ...response.data.listAuxCollegeCoachInfos.items,
        ];
      }
      setAuxCoaches(aux_coaches);
    } catch (e) {
      throw ("fetchAuxCoaches ERROR!!!!!", e);
    }
  };

  const fetchCognitoUsers = async () => {
    let coachesCog = [];
    let response = {};
    let params = {
      UserPoolId: awsmobile.aws_user_pools_id,
    };

    let cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider(
      { region: "eu-west-1", apiVersion: "2016-04-18" }
    );
    try {
      response = await cognitoidentityserviceprovider
        .listUsers(params)
        .promise();
      coachesCog = response.Users;
      while (response.PaginationToken) {
        //Then repeat the same request with the PaginationToken until it's nul'
        params.PaginationToken = response.PaginationToken;
        response = await cognitoidentityserviceprovider
          .listUsers(params)
          .promise();
        coachesCog = [...coachesCog, ...response.Users];
      }
      setCoachesCognito(coachesCog);
    } catch (e) {
      console.log("[ERROR] fetchCognitoUsers " + e);
    }
  };

  //Athletes
  const fetchAthletes = async () => {
    let athletes = [];
    let response = {};
    let params = {
      query: listAthletes,
      variables: {
        limit: 400,
      },
    };
    response = await API.graphql(params); // He make the first request to API
    athletes = response.data.listAthletes.items;
    while (response.data.listAthletes.nextToken) {
      //Then repeat the same request with the nexttoken until it's nul'
      params.variables.nextToken = response.data.listAthletes.nextToken;
      response = await API.graphql(params);
      athletes = [...athletes, ...response.data.listAthletes.items];
    }
    setAthletes(athletes);
  };

  const fetchAthletesEnquirys = async () => {
    let athletesEnquirys = [];
    let response = {};
    let params = {
      query: listAthleteEnquirys,
      variables: {
        limit: 500,
      },
    };
    response = await API.graphql(params); // He make the first request to API
    athletesEnquirys = response.data.listAthleteEnquirys.items;
    while (response.data.listAthleteEnquirys.nextToken) {
      //Then repeat the same request with the nexttoken until it's nul'
      params.variables.nextToken = response.data.listAthleteEnquirys.nextToken;
      response = await API.graphql(params);
      athletesEnquirys = [
        ...athletesEnquirys,
        ...response.data.listAthleteEnquirys.items,
      ];
    }
    setAthletesEnquirys(athletesEnquirys);
  };
  const fetchSearchTerms = async () => {
    let searchTerms = [];
    let response = {};
    let params = {
      query: listSearchTerms,
      variables: {
        limit: 500,
      },
    };
    response = await API.graphql(params); // He make the first request to API
    searchTerms = response.data.listSearchTerms.items;
    while (response.data.listSearchTerms.nextToken) {
      //Then repeat the same request with the nexttoken until it's nul'
      params.variables.nextToken = response.data.listSearchTerms.nextToken;
      response = await API.graphql(params);
      searchTerms = [...searchTerms, ...response.data.listSearchTerms.items];
    }
    setSearchTerms(searchTerms);
  };
  const fetchFeedback = async () => {
    let feeds = [];
    let response = {};
    let params = {
      query: listFeedbacks,
      variables: {
        limit: 500,
      },
    };
    response = await API.graphql(params); // He make the first request to API
    feeds = response.data.listFeedbacks.items;
    while (response.data.listFeedbacks.nextToken) {
      //Then repeat the same request with the nexttoken until it's nul'
      params.variables.nextToken = response.data.listFeedbacks.nextToken;
      response = await API.graphql(params);
      feeds = [...feeds, ...response.data.listFeedbacks.items];
    }
    setFeedbacks(feeds);
  };
  const fetchCoachMessages = async () => {
    let cm = [];
    let response = {};
    let params = {
      query: listCoachMessages,
      variables: {
        limit: 500,
      },
    };
    response = await API.graphql(params); // He make the first request to API
    cm = response.data.listCoachMessages.items;
    while (response.data.listCoachMessages.nextToken) {
      //Then repeat the same request with the nexttoken until it's nul'
      params.variables.nextToken = response.data.listCoachMessages.nextToken;
      response = await API.graphql(params);
      cm = [...cm, ...response.data.listCoachMessages.items];
    }
    setCoachMessages(cm);
  };

  const fetchCoachAthleteFavorites = async () => {
    let caf = [];
    let response = {};
    let params = {
      query: listCoachAthleteFavoritess,
      variables: {
        limit: 500,
      },
    };
    response = await API.graphql(params); // He make the first request to API
    caf = response.data.listCoachAthleteFavoritess.items;
    while (response.data.listCoachAthleteFavoritess.nextToken) {
      //Then repeat the same request with the nexttoken until it's nul'
      params.variables.nextToken =
        response.data.listCoachAthleteFavoritess.nextToken;
      response = await API.graphql(params);
      caf = [...caf, ...response.data.listCoachAthleteFavoritess.items];
    }
    setCoachAthleteFavorites(caf);
  };

  const fetchTemplatesNotifications = async () => {
    let templates = [];
    let response = {};
    let params = {
      query: listNotifications,
      variables: {
        limit: 500,
        filter: { type: { eq: "template" } },
      },
    };
    response = await API.graphql(params); // He make the first request to API
    templates = response.data.listNotifications.items;
    while (response.data.listNotifications.nextToken) {
      //Then repeat the same request with the nexttoken until it's nul'
      params.variables.nextToken = response.data.listNotifications.nextToken;
      response = await API.graphql(params);
      templates = [...templates, ...response.data.listNotifications.items];
    }
    setTemplatesNotifications(templates);
  };

  const fetchCoachAthleteTagRelation = async () => {
    let atr = [];
    let response = {};
    let params = {
      query: listAthleteTagsRelations,
      variables: {
        limit: 500,
      },
    };
    response = await API.graphql(params); // He make the first request to API
    atr = response.data.listAthleteTagsRelations.items;
    while (response.data.listAthleteTagsRelations.nextToken) {
      //Then repeat the same request with the nexttoken until it's nul'
      params.variables.nextToken =
        response.data.listAthleteTagsRelations.nextToken;
      response = await API.graphql(params);
      atr = [...atr, ...response.data.listAthleteTagsRelations.items];
    }
    setAthleteTagRelation(atr);

    let ctr = [];
    let response2 = {};
    let params2 = {
      query: listCoachTagsRelations,
      variables: {
        limit: 500,
      },
    };
    response2 = await API.graphql(params2); // He make the first request to API
    ctr = response2.data.listCoachTagsRelations.items;
    while (response2.data.listCoachTagsRelations.nextToken) {
      //Then repeat the same request with the nexttoken until it's nul'
      params2.variables.nextToken =
        response2.data.listCoachTagsRelations.nextToken;
      response2 = await API.graphql(params2);
      ctr = [...ctr, ...response2.data.listCoachTagsRelations.items];
    }
    setCoachTagRelation(ctr);
  };

  const fetchFavoritesFolder = async () => {
    let ff = [];
    let response = {};
    let params = {
      query: listFavoritesFolders,
      variables: {
        limit: 500,
      },
    };
    response = await API.graphql(params); // He make the first request to API
    ff = response.data.listFavoritesFolders.items;
    while (response.data.listFavoritesFolders.nextToken) {
      //Then repeat the same request with the nexttoken until it's nul'
      params.variables.nextToken = response.data.listFavoritesFolders.nextToken;
      response = await API.graphql(params);
      ff = [...ff, ...response.data.listFavoritesFolders.items];
    }
    setFavoriteFolderAthleteRelation(ff);
  };

  const fetchFavoriteFolderAthleteRelation = async () => {
    let ffar = [];
    let response = {};
    let params = {
      query: listFavoritesFolderAthleteRelations,
      variables: {
        limit: 500,
      },
    };
    response = await API.graphql(params); // He make the first request to API
    ffar = response.data.listFavoritesFolderAthleteRelations.items;
    while (response.data.listFavoritesFolderAthleteRelations.nextToken) {
      //Then repeat the same request with the nexttoken until it's nul'
      params.variables.nextToken =
        response.data.listFavoritesFolderAthleteRelations.nextToken;
      response = await API.graphql(params);
      ffar = [
        ...ffar,
        ...response.data.listFavoritesFolderAthleteRelations.items,
      ];
    }
    setFavoritesFolder(ffar);
  };

  const fetchShowcases = async () => {
    let show = [];
    let showAth = [];
    let showCoach = [];
    let response = {};

    // Showcases
    let params = {
      query: listShowcases,
      variables: {
        limit: 500,
      },
    };
    response = await API.graphql(params); // He make the first request to API
    show = response.data.listShowcases.items;
    while (response.data.listShowcases.nextToken) {
      //Then repeat the same request with the nexttoken until it's nul'
      params.variables.nextToken = response.data.listShowcases.nextToken;
      response = await API.graphql(params);
      show = [...show, ...response.data.listShowcases.items];
    }
    setShowcases(show);

    // Showcase athlete relation
    params = {
      query: listShowcaseAthleteRelations,
      variables: {
        limit: 500,
      },
    };
    response = await API.graphql(params); // He make the first request to API
    showAth = response.data.listShowcaseAthleteRelations.items;
    while (response.data.listShowcaseAthleteRelations.nextToken) {
      //Then repeat the same request with the nexttoken until it's nul'
      params.variables.nextToken =
        response.data.listShowcaseAthleteRelations.nextToken;
      response = await API.graphql(params);
      showAth = [
        ...showAth,
        ...response.data.listShowcaseAthleteRelations.items,
      ];
    }
    setShowcasesAthleteRelation(showAth);

    // Showcase coach relation
    params = {
      query: listShowcaseCoachRelations,
      variables: {
        limit: 500,
      },
    };
    response = await API.graphql(params); // He make the first request to API
    showCoach = response.data.listShowcaseCoachRelations.items;
    while (response.data.listShowcaseCoachRelations.nextToken) {
      //Then repeat the same request with the nexttoken until it's nul'
      params.variables.nextToken =
        response.data.listShowcaseCoachRelations.nextToken;
      response = await API.graphql(params);
      showCoach = [
        ...showCoach,
        ...response.data.listShowcaseCoachRelations.items,
      ];
    }
    setShowcasesCoachRelation(showCoach);
  };

  const fetchCoachNotificationRelations = async () => {
    let coachNotRel = [];
    let response = {};

    // Showcases
    let params = {
      query: listCoachNotificationRelations,
      variables: {
        limit: 500,
      },
    };
    response = await API.graphql(params); // He make the first request to API
    coachNotRel = response.data.listCoachNotificationRelations.items;
    while (response.data.listCoachNotificationRelations.nextToken) {
      //Then repeat the same request with the nexttoken until it's nul'
      params.variables.nextToken =
        response.data.listCoachNotificationRelations.nextToken;
      response = await API.graphql(params);
      coachNotRel = [
        ...coachNotRel,
        ...response.data.listCoachNotificationRelations.items,
      ];
    }
    setCoachNotificationRelations(coachNotRel);

    let not = [];
    response = {};

    // Showcases
    params = {
      query: listNotifications,
      variables: {
        limit: 500,
      },
    };
    response = await API.graphql(params); // He make the first request to API
    not = response.data.listNotifications.items;
    while (response.data.listNotifications.nextToken) {
      //Then repeat the same request with the nexttoken until it's nul'
      params.variables.nextToken = response.data.listNotifications.nextToken;
      response = await API.graphql(params);
      not = [...not, ...response.data.listNotifications.items];
    }
    setNotifications(not);
  };

  //    _____                               _____        _
  //   |  __ \                             |  __ \      | |
  //   | |__) | __ ___   ___ ___  ___ ___  | |  | | __ _| |_ __ _
  //   |  ___/ '__/ _ \ / __/ _ \/ __/ __| | |  | |/ _` | __/ _` |
  //   | |   | | | (_) | (_|  __/\__ \__ \ | |__| | (_| | || (_| |
  //   |_|   |_|  \___/ \___\___||___/___/ |_____/ \__,_|\__\__,_|
  //

  const processAllData = () => {
    // AGENTS
    const actualAgent = agents.find(
      (agent) => agent.contact_email === userCoachData.contact_email
    );
    setUserCoachData({
      ...userCoachData,
      agent_role: actualAgent?.agent_role
        ? actualAgent?.agent_role
        : "noAgentRole",
    });

    //
    //  AGENCIES
    //

    //Number of athletes in agencies
    const tempAgencies = [...agencies];
    each(tempAgencies, (agency) => {
      agency.athleteCount = filter(athletes, { agencyID: agency.id }).length;
    });

    //Complete data for form selector Pickers form API
    // completeSelectorContent() ==> Unused
    updateLoading(false);
  };

  // const completeSelectorContent = () => {
  // 	//Agencies
  // 	selectorsContentRaw.agencyID.valueSets = agencies.map((agency) => {
  // 		return [agency.id, agency.name]
  // 	})
  // 	//Countries
  // 	const countries = []
  // 	each(athletes, (athlete) => {
  // 		if (athlete.country_of_competition !== null) {
  // 			countries.push({ name: athlete.country_of_competition })
  // 		}
  // 	})
  // 	const countriesUniq = uniqBy(countries, 'name')
  // 	const countriesSorted = sortBy(countriesUniq, 'name')
  // 	selectorsContentRaw.country_of_competition.valueSets = countriesSorted.map(
  // 		(country) => {
  // 			return [country.name, country.name]
  // 		}
  // 	)

  // 	setSelectorContent(selectorsContentRaw)
  // }

  //AUTH
  const authCheckUser = async () => {
    //This function check us user is logged in the session. The time of session can by modified in AWS console.log()
    try {
      const authUser = await Auth.currentAuthenticatedUser();
      checkExpiredAndRefreshToken(authUser);
      updateUser(authUser);
    } catch (error) {
      updateUser("notLoggedIn");
    }
  };

  const authSignIn = async (username, password) => {
    try {
      const response = await Auth.signIn(username, password);
      updateSDKToken(response);
      return response;
    } catch (error) {
      setErrorTextValue(JSON.stringify(error.message).replace(/"/g, ""));
      throw new Error(error.message);
    }
  };

  const authSignOut = async () => {
    try {
      await Auth.signOut();
    } catch (error) {
      //console.log('error signing out', error);
    }
  };

  const authForgotPassword = async (email) => {
    try {
      const response = await Auth.forgotPassword(email);
      return response;
    } catch (error) {
      setErrorTextValue(JSON.stringify(error.message).replace(/"/g, ""));
      throw new Error(error.message);
    }
  };

  const authResetPassword = async (email, code, new_password) => {
    try {
      const response = await Auth.forgotPasswordSubmit(
        email,
        code,
        new_password
      );
      return response;
    } catch (error) {
      setErrorTextValue(JSON.stringify(error.message).replace(/"/g, ""));
      throw new Error(error.message);
    }
  };

  const authChangePassword = async (old_password, new_password) => {
    try {
      const response = await Auth.currentAuthenticatedUser().then((user) => {
        return Auth.changePassword(user, old_password, new_password);
      });
    } catch (error) {
      throw new Error(error.message);
    }
  };

  const authCompleteNewPassword = async (user, new_password) => {
    try {
      const response = await Auth.completeNewPassword(user, new_password);
      return response;
    } catch (error) {
      setErrorTextValue(JSON.stringify(error.message).replace(/"/g, ""));
      throw new Error(error.message);
    }
  };

  return (
    <DataContext.Provider
      value={{
        user: user,
        updateUser: updateUser,
        error: errorTextValue,
        updateError: setErrorTextValue,

        userCoachData: userCoachData,
        isLoading: isLoading,
        updateLoading: updateLoading,
        isDataProcessed: isDataProcessed,

        athletes: athletes,
        setAthletes: setAthletes,
        athletesEnquirys: athletesEnquirys,
        setAthletesEnquirys: setAthletesEnquirys,
        searchTerms: searchTerms,
        setSearchTerms: setSearchTerms,
        feedbacks: feedbacks,
        setFeedbacks: setFeedbacks,
        coachMessages: coachMessages,
        setCoachMessages: setCoachMessages,
        // filteredAthletes: filteredAthletes,==> UNUSED
        // filters: filters,==> UNUSED
        // updateFilters: setFilters,==> UNUSED
        // selectorsContent: selectorsContent, ==> UNUSED

        authCheckUser: authCheckUser,
        authSignIn: authSignIn,
        authSignOut: authSignOut,
        authForgotPassword: authForgotPassword,
        authResetPassword: authResetPassword,
        authChangePassword: authChangePassword,
        authCompleteNewPassword: authCompleteNewPassword,

        agencies: agencies,
        setAgencies: setAgencies,
        agents: agents,
        setAgents: setAgents,
        colleges: colleges,
        setColleges: setColleges,
        teams: teams,
        setTeams: setTeams,
        coaches: coaches,
        setCoaches: setCoaches,
        auxCoaches: auxCoaches,
        setAuxCoaches: setAuxCoaches,
        coachesCognito: coachesCognito,
        setCoachesCognito: setCoachesCognito,

        templatesNotifications: templatesNotifications,
        setTemplatesNotifications: setTemplatesNotifications,

        showcases: showcases,
        setShowcases: setShowcases,
        showcasesAthleteRelation: showcasesAthleteRelation,
        setShowcasesAthleteRelation: setShowcasesAthleteRelation,
        showcasesCoachRelation: showcasesCoachRelation,
        setShowcasesCoachRelation: setShowcasesCoachRelation,

        // Relations
        coachAthleteFavorites: coachAthleteFavorites,
        setCoachAthleteFavorites: setCoachAthleteFavorites,

        athleteTagRelations: athleteTagRelations,
        setAthleteTagRelation: setAthleteTagRelation,

        coachTagRelations: coachTagRelations,
        setCoachTagRelation: setCoachTagRelation,

        favoritesFolders: favoritesFolders,
        setFavoritesFolder: setFavoritesFolder,

        favoriteFolderAthleteRelation: favoriteFolderAthleteRelation,
        setFavoriteFolderAthleteRelation: setFavoriteFolderAthleteRelation,

        notifications: notifications,
        setNotifications: setNotifications,
        coachNotificationRelations: coachNotificationRelations,
        setCoachNotificationRelations: setCoachNotificationRelations,

        athletesFilters: athletesFilters,
        setAthletesFilters: setAthletesFilters,

        pagination: pagination,
        updatePagination: setPagination,

        premiumCoaches: premiumCoaches,
        setPremiumCoaches: setPremiumCoaches,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
export default DataContextProvider;
