// React imports
import React, { useContext, useEffect } from 'react'
import { Routes, Route } from 'react-router-dom';
// Component 
import CollegesTables from './components/CollegesTables';
import { ShowCollege } from './components/ShowCollege';
import { EditCollege } from './components/EditCollege';
import { CreateCollege } from './components/CreateCollege';
export const CollegesRoutes = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <Routes>
                <Route path="/" element={<CollegesTables />} />
                <Route path="/show/:collegeId" element={<ShowCollege />} />
                <Route path="/edit/:collegeId" element={<EditCollege />} />
                <Route path="/create" element={<CreateCollege />} />
            </Routes>
        </>
    )
}
