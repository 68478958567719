import React, {useContext} from 'react'


// Chakra imports
import { Flex,Grid,Image, Divider, Alert, AlertIcon, Icon, Spacer, Heading, AlertTitle, Box, AlertDescription, Button } from "@chakra-ui/react";
import { DataContext } from '../../../contexts/dataContext'
// assets
import backAA from "assets/img/backAA.jpg";
// Custom icons
import TextWithBackgroundImage from "./components/TextWithBackgroundImage";
import TextWithSideImage from "./components/TextWithSideImage";
import stadiumLogo from "assets/img/stadiumLogo.png";
import AgencySummaryWithChart from './components/AgencySummaryWithChart';
import RankingsForCoaches from './components/Rankings/RankingsForCoaches';
import RankingsForAthletes from './components/Rankings/RankingsForAthletes';
import KPIs from './components/GeneralCharts/KPIs';
import CoachesAthletesCreated from './components/MonthlyCharts/CoachesAthletesCreated';
import SearchesEnquiriesDone from './components/MonthlyCharts/SearchesEnquiriesDone';
import EnquiriesByAgencies from './components/GeneralCharts/EnquiriesByAgencies';
import RankingsForAgents from './components/Rankings/RankingForAgents';
import { MdFeedback } from 'react-icons/md';
import OrdersOverview from './components/OrdersOverview';
import OrdersOverviewForSearchTerms from './components/OrdersOverviewForSearchTerms';
import { HiDownload } from 'react-icons/hi';
import { CSVLink } from 'react-csv';



const DashboardForAdmins = () => {

	const dataContext = useContext(DataContext)
	const { athletes, agencies, agents, coaches, teams, athletesEnquirys, searchTerms, feedbacks, coachAthleteFavorites } = dataContext

	const getSearchTerms = () => {

		// Order by date
		const orderedSearchTerms = searchTerms.sort((a,b) => new Date(b.createdAt) - new Date(a.createdAt))
		return orderedSearchTerms.map(item => ({
			'Search Term': item.search_term_text,
			'Search Type': item.search_type,
			'Coach': item.coach?.name,
			'Coach Email': item.coach?.contact_email,
			'Coach phone': item.coach?.contact_phone,
			'Coach Division': item.coach?.division,
			'Coach University': teams?.find(team => team.id === item.coach?.teamID)?.name,
			'University Program': teams?.find(team => team.id === item.coach?.teamID)?.genre,
			'Sport': item.sport,
			'Source App': item.source_app,
			'Date': new Date(item.createdAt).toLocaleDateString()
		}))
	}


	const getAthletesEnquiriesFor30Days = () => {
		// Get the last 30 days
		const last30Days = new Date().setDate(new Date().getDate() - 30)
		// Get the athletes enquiries for the last 30 days
		const athletesEnquiriesFor30Days = athletesEnquirys.filter(item => new Date(item.createdAt) >= new Date(last30Days))
		// Order by date
		const orderedAthletesEnquiriesFor30Days = athletesEnquiriesFor30Days.sort((a,b) => new Date(b.createdAt) - new Date(a.createdAt))
		// Count the number of enquiries for each athlete and store the last enquiry date
		
		const athletesEnquiriesCount = []
		// array of objects like {id, count, lastEnquiryDate}
		orderedAthletesEnquiriesFor30Days.map((item) => {
			// If the athlete already exists, add one to the count and update the last enquiry date
			const athlete = athletesEnquiriesCount.find(athlete => athlete.id === item.athleteID)
			if (athlete) {
				athlete.count += 1
				if(new Date(athlete.lastEnquiryDate) < new Date(item.createdAt)) {
					athlete.lastEnquiryDate = item.createdAt
				}
			} else {
				// If the athlete doesn't exist, add the athlete and the count
					athletesEnquiriesCount.push({
						id: item.athleteID,
						count: 1,
						lastEnquiryDate: item.createdAt
					})
			}
			
			
		})
		// Return data
		return athletesEnquiriesCount.map(item => {
			const athlete = athletes.find(athlete => athlete.id === item.id)
			return {
				'Id': item.id,
				'Name': athlete.name,
				'Agency': athlete.agency?.name,
				'Agent': athlete.agent?.name,
				'Enquiries': item.count,
				'Last Enquiry': new Date(item.lastEnquiryDate).toLocaleDateString(),
			}
		}).sort((a,b) => b.Enquiries - a.Enquiries)

	}


	return (
		<Flex flexDirection='column' pt={{ base: "120px", md: "75px" }}>
            {/* Title of the section */}
			<Alert status='success' borderRadius="10px" mb="20px">
				<AlertIcon />
				<Box>
					<AlertTitle>New experience for this section!</AlertTitle>
					<AlertDescription>
					Performance improvement, load the charts you need to see at the moment.
					</AlertDescription>
				</Box>
			</Alert>
			<Heading as='h4' size='md' mb="10px" color="blue.700">KEY PERFORMANCE INDICATORS</Heading>
			<KPIs
                athletes={athletes}
                agencies={agencies}
                agents={agents}
                coaches={coaches.filter(item => item.is_active === true)}
                teams={teams}
                athletesEnquirys={athletesEnquirys}
                searchTerms={searchTerms} 
            />
			<Spacer my="15px"/>
			<CoachesAthletesCreated athletes={athletes} coaches={coaches.filter(item => item.is_active === true)} />
			<Spacer my="15px"/>
			{
				searchTerms.length > 0 && (
					<Flex
						justifyContent="flex-end"
						alignItems="center"
						mb="10px"
						mr="10px"
					>
						<CSVLink
							data={getSearchTerms()}
							filename={"SearchTermsByCoaches.csv"}
						>
							<Button
								colorScheme='green'
								variant='ghost'
								leftIcon={<Icon as={HiDownload}/>}
								size='sm'
							>
								Download Search Terms
							</Button>
						</CSVLink> 
					</Flex>
				)
			}
			<SearchesEnquiriesDone
                // Just the searchTerms done by coaches, searchTerm.coachID doesn't exist on agents
                searchTerms={searchTerms}
                athletesEnquirys={athletesEnquirys} 
				agents={agents}
            />
			<Spacer my="15px"/>
			<Heading as='h4' size='md' mb="10px" color="blue.700" style={{textShadow: "1px 1px #2B6CB0"}}>AGENCY ACTIVITY</Heading>
			<AgencySummaryWithChart title={"Agencies summary"} agencies={agencies} athletes={athletes} />
			<Spacer my="15px"/>
			<EnquiriesByAgencies agencies={agencies} athletesEnquirys={athletesEnquirys} athletes={athletes}/>
			<Spacer my="15px"/>
			<RankingsForAgents
				title={"Most Active Agents this month"}
				subtitle={"This chart shows a ranking of the most active agent."}
				order="most"
			/>
			<Spacer my="15px"/>
			<RankingsForAgents
				title={"Least Active Agents this month"}
				subtitle={"This chart shows a ranking of the least active agents."}
				order="least"
			/>
			<Spacer my="15px"/>
			<OrdersOverview
				title={"Feedbacks by agents"}
				subtitle={"List of all feedbacks made by agents, sorted by date."}
				amount={feedbacks.filter(item=>(agents.find(a=>a.contact_email === coaches.find(c=>c.id===item.coachID)?.contact_email)) && new Date(item.createdAt).getMonth() === new Date().getMonth() && new Date(item.createdAt).getFullYear() === new Date().getFullYear()).length}
				data={feedbacks.filter(item=>(agents.find(a=>a.contact_email === coaches.find(c=>c.id===item.coachID)?.contact_email))).sort((a,b) => new Date(b.createdAt) - new Date(a.createdAt)).slice(0,feedbacks.length / 2)}
				icon={<Icon as={MdFeedback} color="#2049DA" h={"35px"} w={"31px"} pe="6px" zIndex="1" position="relative" right={document.documentElement.dir === "rtl" ? "-8px" : ""} left={document.documentElement.dir === "rtl" ? "" : "-8px"}/>}
			/> 
			<Spacer my="15px"/>
		
			<OrdersOverviewForSearchTerms
				title={"Search terms by agents"}
				subtitle={"List of all searches performed by agents in the application, sorted by date. "}
				type={"agents"}
				amount={searchTerms.length}
				data={searchTerms}
			/> 
			
			<Spacer my="15px"/>
			<Heading as='h4' size='md' mb="10px" color="blue.700" style={{textShadow: "1px 1px #2B6CB0"}}>PSA TRENDS</Heading>
			{/* Download Top Athlete Enquiries for the last 30 days */}
			{
				athletesEnquirys.length > 0 && (
					<Flex
						justifyContent="flex-end"
						alignItems="center"
						mb="10px"
						mr="10px"
					>
						<CSVLink
							data={getAthletesEnquiriesFor30Days()}
							filename={"TopAthleteEnquiriesForLast30Days.csv"}
						>
							<Button
								colorScheme='green'
								variant='ghost'
								leftIcon={<Icon as={HiDownload}/>}
								size='sm'
							>
								Download Top Athlete Enquiries (Last 30 days)
							</Button>
						</CSVLink> 
					</Flex>
				)
			}
			<RankingsForAthletes
                title={"Top Athletes Enquiries"}
                subtitle={"List of the most enquired athletes, with the last enquiry received, number of enquiries received, and percentage of enquiries received with respect to the total. "}
                captions={["Athlete","Latest","No Enquiries", "% of enquiries"]}
                athletes={athletes}
                coaches={coaches}
                dataToUse={athletesEnquirys}
                dataToChange={"AthletesEnquiries"}
            />
			<Spacer my="15px"/>
			<Heading as='h4' size='md' mb="10px" color="blue.700" style={{textShadow: "1px 1px #2B6CB0"}}>COACHES ACTIVITY</Heading>
			<RankingsForCoaches
				title={"Most Active Coaches this month"}
				subtitle={"This chart shows a ranking of the most active coaches."}
				captions={["Coach","No Messages","No Favorites", "No Searches"]}
				athletesEnquirys={athletesEnquirys.filter(item=>!(agents.find(a=>a.contact_email === item.coach?.contact_email && item.coach?.is_active)))}
				coachAthleteFavorites={coachAthleteFavorites.filter(item=>!(agents.find(a=>a.contact_email === item.coach?.contact_email && item.coach?.is_active)))}
				searchTerms={searchTerms.filter(item=>!(agents.find(a=>a.contact_email === item.coach?.contact_email && item.coach?.is_active)))}
				coaches={coaches}
				orderAscendant={false}
				dataToChange={"MostActive"}
			/>
			<Spacer my="15px"/>
			<RankingsForCoaches
				title={"Least Active Coaches this month"}
				subtitle={"This chart shows a ranking of the least active coaches."}
				captions={["Coach","No Messages","No Favorites", "No Searches"]}
				athletesEnquirys={athletesEnquirys.filter(item=>!(agents.find(a=>a.contact_email === item.coach?.contact_email && item.coach?.is_active)))}
				coachAthleteFavorites={coachAthleteFavorites.filter(item=>!(agents.find(a=>a.contact_email === item.coach?.contact_email && item.coach?.is_active)))}
				searchTerms={searchTerms.filter(item=>!(agents.find(a=>a.contact_email === item.coach?.contact_email && item.coach?.is_active)))}
				coaches={coaches}
				orderAscendant={true}
				dataToChange={"LeastActive"}
			/>
			<Spacer my="15px"/>
			<OrdersOverview 
				title={"Feedbacks by coaches"}
				subtitle={"List of all feedbacks made by agents, sorted by date."}
				amount={feedbacks.filter(item=>!(agents.find(a=>a.contact_email === coaches.find(c=>c.id===item.coachID)?.contact_email)) && new Date(item.createdAt).getMonth() === new Date().getMonth() && new Date(item.createdAt).getFullYear() === new Date().getFullYear()).length}
				data={feedbacks.filter(item=>!(agents.find(a=>a.contact_email === coaches.find(c=>c.id===item.coachID)?.contact_email))).sort((a,b) => new Date(b.createdAt) - new Date(a.createdAt)).slice(0,feedbacks.length / 2)}
				icon={<Icon as={MdFeedback} color="#2049DA" h={"35px"} w={"31px"} pe="6px" zIndex="1" position="relative" right={document.documentElement.dir === "rtl" ? "-8px" : ""} left={document.documentElement.dir === "rtl" ? "" : "-8px"}/>}
			/> 
			<Spacer my="15px"/>
			<OrdersOverviewForSearchTerms 
				title={"Search terms by coaches"}
				subtitle={"List of all searches performed by coaches in the application, sorted by date."}
				type={"coaches"}
				amount={searchTerms.length}
				data={searchTerms}
			/>

			<Divider my="30px" />
			
			
			<Grid
				templateColumns={{ md: "1fr", lg: "1.8fr 1.2fr" }}
				templateRows={{ md: "1fr auto", lg: "1fr" }}

				gap='24px'>
				<TextWithSideImage
					title={"Also available on"}
					name={"Alliance Athletes App"}
					description={
						"Get immediate access to all the prospective soccer student-athletes represented by International Alliance members through the Alliance Athletes App."
					}
					image={
						<Image
							src={stadiumLogo}
							alt='chakra image'
							minWidth={{ md: "300px", lg: "auto" }}
							bgSize={"contain"}
						/>
					}
					url={"https://www.allianceathletes.com/"}
				/>
				<TextWithBackgroundImage
					backgroundImage={backAA}
					title={"The International Alliance"}
					description={
						"We are a collection of elite college sports recruiters united to help student-athletes around the world have the opportunity to study and play sports in American Universities."
					}
				/>
			</Grid>
			

		</Flex>
	);
}

const myPropsChanged = (prevProps, nextProps) => {
    if ( prevProps !== nextProps ) {
        return false;
    }
    return true;
}

export default React.memo(DashboardForAdmins, myPropsChanged);
  