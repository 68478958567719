// React imports
import React, { useState, useContext, useRef, useEffect } from 'react'
// Context imports
import { DataContext } from '../../../contexts/dataContext'
// Chakra imports
import {
    Flex,
	Td,
	Text,
	Tr,
	useColorModeValue,
    Table,
	Tbody,
	Th,
	Thead,
    useDisclosure,
    Button,
    Alert,
	AlertIcon,
	CloseButton,
    Icon,
    Input,
    Textarea,
    Checkbox,
	AvatarGroup,
	Avatar
} from "@chakra-ui/react";
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
  } from '@chakra-ui/react'
import { MdEdit } from 'react-icons/md';
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { Pagination } from "components/Pagination/Pagination";
import { SearchBar } from "components/SearchBar/SearchBar";

import { getSearchedData } from "helpers/getSearchedData";
import { changeUserGroupToAgencyId } from "../../../helpers";
import { CreateButton } from 'components/Buttons/CreateButton';
import { DeleteButton } from 'components/Buttons/DeleteButton';
import { v4 as uuidv4 } from 'uuid';
import { createNotificationService } from 'services/notificationServices';
import { updateNotificationService } from 'services/notificationServices';
import { deleteNotificationService } from 'services/notificationServices';
import { CSVLink } from 'react-csv';
import { HiDownload } from 'react-icons/hi';
import { NotificationTemplatesRow } from './components/NotificationTemplatesRow';
import { NotificationTrackerRow } from './components/NotificationTrackerRow';


export const ManageTemplates = () => {

    // Chakra color mode
	const textColor = useColorModeValue("gray.700", "white");

    // Take the context data needed in this component
	const dataContext = useContext(DataContext)
	const { pagination,updatePagination, user, templatesNotifications,setTemplatesNotifications, notifications } = dataContext

	// Cognito user group
    const userGroup = changeUserGroupToAgencyId(user?.signInUserSession.idToken.payload["cognito:groups"][0])

    // state for new template
	const templateInitialState = {
		id:uuidv4(),
		text: '',
		title: '',
	}
	const [newTemplate,setNewTemplate] = useState(templateInitialState)

	// Disclosure for Drawer
	const createTemplateModal = useDisclosure();

    const onChangeCreateTemplate = (e) => {
		e.persist()
        setNewTemplate(() => ({ ...newTemplate , [e.target.name]: e.target.value }))
	}

    
	// State with the searched text
	const [searchState, updateSearchState] = useState({searchText: ''})

    const [ actionStatus, setActionStatus ] = useState({
        done: false,
        status: '',
        message: ''
    })
    
    // State that handles the selection of items
    const [ selectItem, setSelectItem ] = useState([])
    // State that handles the selection of items in tracker
    const [ selectItemTracker, setSelectItemTracker ] = useState([])

    
    // Function that handles the selection of an item
	const handleSelectItem = (e) => {setSelectItem(e.target.checked ? [...selectItem, e.target.value] : selectItem.filter(item => item !== e.target.value))}
    // Function that handles the selection of all items
    const handleSelectAll = (e) => {e.target.checked ? setSelectItem(templatesNotifications.map(item => item.id)) : setSelectItem([])}
    
	// Function that handles the selection of an item --> Tracker
	const handleSelectItemTracker = (e,row) => {setSelectItemTracker(e.target.checked ? [...selectItemTracker, row] : selectItemTracker.filter(item => JSON.stringify(item) !== JSON.stringify(row)))}
    // Function that handles the selection of all items--> Tracker
    const handleSelectAllTracker = (e) => {e.target.checked ? setSelectItemTracker(getNotificationsTracked().map(item => item)) : setSelectItemTracker([])}

	const handleCreateTemplateNotification = () => {
        
		createNotificationService({ ...newTemplate, type: 'template' })
		.then(() => {
			// set action status with success message
			setActionStatus({
				done: true,
				status: 'success',
				message: "The template has been successfully created."
			})
			setTemplatesNotifications([...templatesNotifications, { ...newTemplate, type: 'template' }])
			createTemplateModal.onClose()
		})
		.catch(err => {
			// set action status with error message
			setActionStatus({
				done: true,
				status: 'error',
				message: '[Templates] Something went wrong. Please, contact the admin.'
			})
		})
		
	}
	

	const handleDeleteTemplateNotification = () => {

		let hasFailed = false
		selectItem.forEach((item) => {
			deleteNotificationService(item)
			.catch(error => { hasFailed = true })
			setTemplatesNotifications(templatesNotifications.filter(template => !selectItem.includes(template.id)))

		})

		setSelectItem([])
		!hasFailed ? setActionStatus({
			done: true,
			status: 'success',
			message: 'Template(s) deleted successfully'
		}): setActionStatus({
			done: true,
			status: 'error',
			message: 'An error has ocurred while deleting the template(s)'
		})
	}

	// {console.log("notifications:",notifications)}
	const getNotificationsTracked = () => {
		let track = []

		notifications.map(not => {
			not.type === 'regular' && (
			track.push({
				title: not.title,
				content: not.text,
				date: not.createdAt,
				sentTo: not.coaches_notified.items,
				seen: `Seen by ${not.coaches_notified?.items?.filter(it=>it.is_viewed)?.length} out of ${not.coaches_notified?.items?.length} recipients`
			})
		)})
		return track.sort((a,b)=>{return new Date(b.date) - new Date(a.date)})
	}

	useEffect(() => {
		updatePagination({
			total:getNotificationsTracked().length,
			page: 1,
			rowsPerPage: 10,
			lower: 1})
		setSelectItem([])
		setSelectItemTracker([])
	}, [])

	const downloadTrackedData = () => {
		let file = []
		selectItemTracker.map(item => {
			let row = {
				"Title": item.title,
				"Content": item.content,
				"Date": new Date(item.date).toLocaleDateString(),
				"seen": item.seen
			}
			item.sentTo?.length > 0 ? (
				item.sentTo?.map((it,idx)=>{
					row[`Recipient ${idx}`] = it.coach?.name
				})
			) : (
				row['Recipient 0'] = 'Recipient/s may delete this notification'
			)
			file.push(row)
			
		})
		return file
	}

	const processText = (text) => {

		if(!seeMore && text.length > 50){
			let sliceText = text.slice(0,50).replaceAll("\n",' ')
			sliceText = sliceText + '...'
			return sliceText.split("\n").map(it=>{return <>
				{it}<br />
			</>})
		}
		else{
			return text.split("\n").map(it=>{return <>
				{it}<br />
			</>})
		}
	}

	

    return (
        <>
            <Modal
				isCentered
				onClose={createTemplateModal.onClose}
				isOpen={createTemplateModal.isOpen}
				motionPreset='slideInBottom'
			>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Create new template </ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						You will create a new template to be used for notifications. Remember, a template can be used for a general notification and therefore should not contain coach-specific data.<br /><br />
						<strong>Enter the name for the template: </strong>
						<Input
							variant='flushed'
							fontSize='sm'
							placeholder='Title for the template'
							size='md'
							value={newTemplate?.title || ''}
							onChange={onChangeCreateTemplate}
							name='title'
							mb="20px"
						/>
						<Text fontSize='md'  color={textColor} mb="5px">
							<strong>Enter the text</strong> you wish to be in the template:
						</Text>
						<Textarea
							placeholder='Type here some text...' 
							resize={"none"} 
							size="md"
							h={{sm:"150px",lg:"200px"}} 
							value={newTemplate?.text}
							onChange={onChangeCreateTemplate}
							name='text'	
						/>
					</ModalBody>
					<ModalFooter>
						<Button colorScheme='red' mr={3} onClick={createTemplateModal.onClose}>
							Cancel
						</Button>
						<Button variant='ghost' colorScheme='blue' onClick={handleCreateTemplateNotification} isDisabled={newTemplate.text === '' && newTemplate.title === ''}>Save template</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>


            <Flex direction='column' pt={!actionStatus.done && { base: "120px", md: "120px",lg:"90px" }} mt={actionStatus.done &&{ base: "120px", md: "100px" }}>
                <Flex justifyContent='right' m="0px 10px 10px 0">
                    {selectItem.length > 0 && (<DeleteButton handleDelete={handleDeleteTemplateNotification} />)}
                    <CreateButton handleCreate={createTemplateModal.onOpen}/>
                </Flex>
				{/* Alert with the status of the action that has been done. */}
				{
					actionStatus.done && (
						<Alert status={actionStatus.status} borderRadius="10px" mb="20px" >
							<AlertIcon />
							{actionStatus.message}
							<CloseButton position='absolute' right='8px' top='8px' onClick={()=>(setActionStatus({...actionStatus,done:false}))} />
						</Alert>
					)
				}
				
                <Card overflowX={{ sm: "scroll", xl: "hidden" }}>
                    <CardHeader p='6px 0px 22px 0px'>
                        <Flex direction='row' w="100%">
                            <Flex justifyContent='left' w="40%">
                                <Text fontSize='xl' color={textColor} fontWeight='bold'>
                                    Notification templates ( {selectItem.length} selected )
                                </Text>
                            </Flex>
                            <Flex justifyContent='right' w="60%">
                                <SearchBar text={searchState} update={updateSearchState}/>
                            </Flex>
                        </Flex>
                    </CardHeader>
					{
					templatesNotifications.length > 0 && (
							<CardBody>
								<Table variant='simple' color={textColor}>
									<Thead>
										<Tr my='.8rem' pl='0px' color='gray.400'>
											<Th color='gray.400' >
												<Checkbox colorScheme='blue' onChange={(e)=>(handleSelectAll(e))} />
											</Th>
											<Th color='gray.400'>
												Title
											</Th>
											<Th color='gray.400'>
												Text
											</Th>
											<Th color='gray.400'>
												Edit
											</Th>
										</Tr>
									</Thead>
									<Tbody>
										{
											// Need the data with name or make a function that search depends on a given atributte
											// sortData(getSearchedData(data,searchState.searchText)).map((row) => {
												templatesNotifications.map((row) => {
												return(
													<NotificationTemplatesRow row={row} selectItem={selectItem} handleSelectItem={handleSelectItem}  />
													// <Tr>
													// 	<Td>
													// 		<Checkbox colorScheme='blue' value={row.id} onChange={(e)=>(handleSelectItem(e))} isChecked={selectItem.find((item) => item === row.value)} />
													// 	</Td>
													// 	<Td>
													// 		<Text
													// 			fontSize="sm"
													// 			color={textColor}
													// 			fontWeight="bold"
													// 		>
													// 			{row.title}
													// 		</Text>
													// 	</Td>
													// 	<Td>
													// 		<Text
													// 			fontSize="sm"
													// 			color={textColor}
													// 		>
													// 			{processText(row.text)} {row.text.length > 50 && <a onClick={()=>setSeeMore(!seeMore)}>See {seeMore ? 'less' : 'more'}</a>}

													// 		</Text>
													// 	</Td>
													// 	<Td>
													// 		<Icon 
													// 			as={MdEdit} 
													// 			onClick={()=>(
													// 				setTemplateToEdit({id:row.id,text:row.text,title:row.id}),
													// 				editTemplateModal.onOpen()
													// 			)}
													// 			cursor="pointer"
													// 		/>
													// 	</Td>
													// </Tr>
												)
											})
										}
										
									</Tbody>
								</Table>
							</CardBody>
						)
					}
                    {/* <Pagination totalData={getSearchedData(templatesNotifications,searchState.searchText).length} rowsPerPageValues={[10,20,30,40,50]} /> */}
                </Card>
                <Flex justifyContent='right' m="20px 10px 10px 0">
					<CSVLink 
						data={downloadTrackedData()}
						filename={"Notification-Tracker.csv"}
					>
						<Button
							colorScheme='green'
							variant='ghost'
							leftIcon={<Icon as={HiDownload}/>}
							size='sm'
							isDisabled={selectItemTracker.length === 0}
						>
							Export to CSV
						</Button>
					</CSVLink>
				</Flex>
				<Card overflowX={{ sm: "scroll", xl: "hidden" }} >
                    <CardHeader p='6px 0px 22px 0px'>
                        <Flex direction='row' w="100%">
                            <Flex justifyContent='left' w="40%">
                                <Text fontSize='xl' color={textColor} fontWeight='bold'>
                                    Notification tracker
                                </Text>
                            </Flex>
                            {/* <Flex justifyContent='right' w="60%">
                                <SearchBar text={searchState} update={updateSearchState}/>
                            </Flex> */}
                        </Flex>
                    </CardHeader>
                    <CardBody>
                        <Table variant='simple' color={textColor}>
                            <Thead>
                                <Tr my='.8rem' pl='0px' color='gray.400'>
									<Th color='gray.400' >
										<Checkbox colorScheme='blue' onChange={(e)=>(handleSelectAllTracker(e))} />
									</Th>
                                    <Th color='gray.400'>
                                        Title
                                    </Th>
                                    <Th color='gray.400'>
                                        Content
                                    </Th>
                                    <Th color='gray.400'>
                                        Date
                                    </Th>
                                    <Th color='gray.400'>
                                        Sent to
                                    </Th>
                                    <Th color='gray.400'>
                                        Seen by X out of Y recipients
                                    </Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {
									getNotificationsTracked().slice(pagination.lower-1,pagination.lower+pagination.rowsPerPage-1).map((row) => {
                                        return(
											<NotificationTrackerRow row={row} selectItemTracker={selectItemTracker} handleSelectItemTracker={handleSelectItemTracker} />
                                            // <Tr>
											// 	<Td>
											// 		<Checkbox colorScheme='blue' value={row} onChange={(e)=>(handleSelectItemTracker(e,row))} isChecked={selectItemTracker.find((item) => JSON.stringify(item) === JSON.stringify(row))} />
											// 	</Td>
                                            //     <Td>
                                            //         <Text
                                            //             fontSize="sm"
                                            //             color={textColor}
                                            //             fontWeight="bold"
                                            //         >
                                            //             {row.title}
                                            //         </Text>
                                            //     </Td>
                                            //     <Td>
                                            //         <Text
                                            //             fontSize="sm"
                                            //             color={textColor}
                                            //         >
                                            //             {row.content}
                                            //         </Text>
                                            //     </Td>
                                            //     <Td>
                                            //         <Text
                                            //             fontSize="sm"
                                            //             color={textColor}
                                            //         >
                                            //             {new Date(row.date).toLocaleDateString()}
                                            //         </Text>
                                            //     </Td>
                                            //     <Td>
											// 		{
											// 			row.sentTo.length > 0 ? (
											// 				<AvatarGroup size='sm' max={4}>
											// 					{row.sentTo?.map((it,idx) => {
											// 						return (
											// 							<Avatar key={idx} name={it.coach?.name}  />
											// 						)
											// 					})}
											// 				</AvatarGroup>	
											// 			) : (
											// 				<Text
											// 					fontSize="sm"
											// 					color={"gray.400"}
											// 					>
											// 						Recipient/s may delete this notification.
											// 				</Text>
											// 			)
											// 		}
                                            //     </Td>
											// 	<Td>
											// 		<Text
											// 			fontSize="sm"
											// 			color={textColor}
											// 		>
											// 			{row.seen}
											// 		</Text>
											// 	</Td>
                                            // </Tr>
                                        )
                                    })
                                }
                                
                            </Tbody>
                        </Table>
                    </CardBody>
                    <Pagination totalData={getSearchedData(templatesNotifications,searchState.searchText).length} rowsPerPageValues={[10,20,30,40,50]} />
                </Card>
		    </Flex>
        </>
    )
}
