// React imports
import React, { useState, useContext, useRef, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
// Context imports
import { DataContext } from '../../../contexts/dataContext'
// Chakra imports
import {
	Image,
    Flex,
	Td,
	Text,
	Tr,
	useColorModeValue,
    Table,
	Tbody,
	Th,
	Thead,
    useDisclosure,
    Button,
    Alert,
	AlertIcon,
	CloseButton,
    Icon
} from "@chakra-ui/react";
import {BsArrowDown, BsArrowUp} from 'react-icons/bs'
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { Pagination } from "components/Pagination/Pagination";
import { SearchBar } from "components/SearchBar/SearchBar";

import { getSearchedData } from "helpers/getSearchedData";
import { ConfirmModal } from "../../../components/ConfirmModal/ConfirmModal";
import { confirmUserCognito, deleteUserCognito, updateActiveCoachCognito } from "../../../services/cognitoServices";
import { deleteCoachService, updateCoachService } from "../../../services/coachServices";
import { changeUserGroupToAgencyId } from "../../../helpers";


export const PendingCoaches = () => {

    // Chakra color mode
	const textColor = useColorModeValue("gray.700", "white");

    // Take the context data needed in this component
	const dataContext = useContext(DataContext)
	const { pagination,updatePagination, user,userCoachData, coaches, setCoaches, coachesCognito, setCoachesCognito } = dataContext
    // Cognito user group
    const userGroup = changeUserGroupToAgencyId(user?.signInUserSession.idToken.payload["cognito:groups"][0])

    const getDataSplited = (coaches,coachesCognito) => {
        let noActive = []
        let unconfirmed = []
        let auxCoach = {}
        coaches.forEach(coach => {
            // Get the cognito profile for the given coach
            auxCoach = coachesCognito.find(c => c.Attributes.find(it => it['Name']==="custom:profile_id")['Value']===coach.id)
            // if the coach is unconfirmed then show on unconfirmed table. 
            // coach.is_active === false && [Changed cause of active coaches not enter confirmation code]
            if(auxCoach && auxCoach?.UserStatus === "UNCONFIRMED"){unconfirmed.push(coach)}
            else if(auxCoach && coach.is_active === false){noActive.push(coach)}
        });

        // console.log("No active: ",noActive)
        // console.log("No active and unconfirmed: ",unconfirmed)
        return [noActive, unconfirmed]
    }

    let [data, unconfirmedData] = getDataSplited(coaches,coachesCognito)


    // State that controls if the user has permission to do an action
	const [ noPermission, setNoPermission ] = useState(false)
    
	// State with the searched text
	const [searchState, updateSearchState] = useState({searchText: ''})
    const [ action, setAction ] = useState({
        coach: {},
        title: '',
        description: '',
        color:'',
        handler: () => {}
    })

    const [ actionStatus, setActionStatus ] = useState({
        done: false,
        status: '',
        message: ''
    })

	// Hook for the confirm modal
	const { isOpen, onOpen, onClose } = useDisclosure()
	const cancelRef = useRef()
	// Navigation hook 
	let navigate = useNavigate()
    // State that control if sort is ascending or descending
    const [ sortAscending, setSortAscending ] = useState(true)

	useEffect(() => {
		updatePagination({
			total:getSearchedData(data,searchState.searchText).length,
			page: 1,
			rowsPerPage: 10,
			lower: 1})
	}, [])


    const handleAction = (action,coach) => {
        userGroup === 'superadmins' || userCoachData.agent_role==="LEADER"
        ? (

            action === 'activate' 
            ? setAction({
                coach: coach,
                title: 'Activate Coach',
                description: "Are you sure you want to activate this coach? Once activated, the coach will be able to access the app.",
                color: 'blue',
                handler: () => { handleConfirmActivate(coach) }
            })
            :  action === 'confirm'
                ? setAction({
                    coach: coach,
                    title: 'Confirm Coach',
                    description: "Are you sure you want to confirm this coach? Once confirm, the coach will appear on Pending Coaches table to be accepted.",
                    color: 'red',
                    handler: () => { handleConfirmCognito(coach) }
                })
                : setAction({
                    coach: coach,
                    title: 'Decline Coach',
                    description: "Are you sure you want to decline this coach? Once declined, the coach will not be able to access the app.",
                    color: 'red',
                    handler: () => { handleConfirmDecline(coach) }
                })
        ) : setNoPermission(true)

        onOpen()
    }

    const sortData = (data) => {
        return data.sort((a,b) => {
            if(sortAscending){
                return new Date(a.createdAt)-new Date(b.createdAt)
            }
            else{
                return new Date(b.createdAt)-new Date(a.createdAt)
            }
        })
    }

    const handleConfirmCognito = (coach) => {
        onClose()
        confirmUserCognito(coachesCognito.find(it=>it.Attributes.find(it => it['Name']==="email")['Value'] === coach.contact_email)?.Username)
        .then(res => {
            setActionStatus({
                done: true,
                status: 'success',
                message: 'Coach confirmed successfully'
            })
            // Update the coaches cognito state
            setCoachesCognito(coachesCognito.map(c => {
                if(c.Attributes.find(it => it['Name']==="custom:profile_id")['Value'] === coach.id){
                    return {
                        ...c,
                        UserStatus: "CONFIRMED"
                    }
                }
                return c
            }))

        })
        .catch(err => { 
            console.log(err)
            setActionStatus({
                done: true,
                status: 'error',
                message: '[Auth] Confirmation failed. Please, contact the admin.'
            })
        })

    }

    const handleConfirmActivate = (coach) => {
        onClose()

        // Activate the coach in cognito
        updateActiveCoachCognito(coachesCognito.find(it=>it.Attributes.find(a => a['Name']==="email")['Value'] === coach.contact_email)?.Username)
        .then(res => {
            // Activate the coach in dynamodb
            updateCoachService({id: coach.id,is_active: true})
            .then(res => {
                setActionStatus({
                    done: true,
                    status: 'success',
                    message: 'Coach activated successfully'
                })
                // Update the coaches state
                setCoaches(coaches.map(c => {
                    if(c.id === coach.id){ return { ...coach, is_active: true } }
                    return c
                }))
                // Update the coaches cognito state
                setCoachesCognito(coachesCognito.map(c => {
                    if(c.Attributes.find(it => it['Name']==="email")['Value'] === coach.contact_email){
                        let newAttributes = c.Attributes
                        newAttributes.find(it => it['Name']==="custom:isActive")['Value'] = 'true'
                        return {
                            ...c,
                            Attributes: newAttributes
                        }
                    }
                    return c
                }))
            })
            .catch(err => {
                console.log("error:",err)
                // set action status with error message
                setActionStatus({
                    done: true,
                    status: 'error',
                    message: '[Profile] Something went wrong. Please, contact the admin.'
                })
            })
        })
        .catch(err => {
            console.log("error:",err)
            // set action status with error message
            setActionStatus({
                done: true,
                status: 'error',
                message: '[Auth] Something went wrong. Please, contact the admin.'
            })
        })
        // Clean the action state
        setAction({})

    }
    
    const handleConfirmDecline = (coach) => {
        onClose()
        // Delete user from cognito
        deleteUserCognito(coachesCognito.find(it=>it.Attributes.find(it => it['Name']==="email")['Value'] === coach.contact_email)?.Username)
        .then(() => {
            // Delete the coach profile in dynamodb
            deleteCoachService(coach.id)
            .then(res => {
                // set action status with success message
                setActionStatus({
                    done: true,
                    status: 'success',
                    message: "The coach's request has been successfully declined."
                })
                // Update the coaches & cognito states
                setCoaches(coaches.filter(c => c.id !== coach.id))
                setCoachesCognito(coachesCognito.filter(coachCog => coachCog.id !== coach.id))
            })
            .catch(err => {
                // set action status with error message
                setActionStatus({
                    done: true,
                    status: 'error',
                    message: '[Profile] Something went wrong. Please, contact the admin.'
                })
            })
        })
        .catch((err) => {
            // set action status with error message
            setActionStatus({
                done: true,
                status: 'error',
                message: '[Auth] Something went wrong 1. Please, contact the admin.'
            })

        }) 
        // Clean the action state
        setAction({})
    }


    return (
        <>
            <ConfirmModal
                title={action.title}
                description={action.description}
                button1="Cancel"
                button2="Confirm"
                color={action.color}
                isOpen={isOpen}
                onClose={onClose}
                cancelRef={cancelRef}
                handleDelete={action.handler}
            />
            {
				actionStatus.done && (
					<Alert status={actionStatus.status} borderRadius="10px" mb="20px" mt={{ base: "120px", md: "100px" }}>
						<AlertIcon />
						{actionStatus.message}
						<CloseButton position='absolute' right='8px' top='8px' onClick={()=>(setActionStatus({...actionStatus,done:false}))} />
					</Alert>
				)
			}
            {
				noPermission && (
					<Alert status='error' borderRadius="10px" mb="20px">
						<AlertIcon />
						You don't have enough permissions to perform this action on agencies.
						<CloseButton position='absolute' right='8px' top='8px' onClick={()=>(setNoPermission(false))} />
					</Alert>
				)

			}
            <Flex direction='column' pt={!actionStatus.done && { base: "120px", md: "120px" }}>
                <Card overflowX={{ sm: "scroll", xl: "hidden" }}>
                    <CardHeader p='6px 0px 22px 0px'>
                        <Flex direction='row' w="100%">
                            <Flex justifyContent='left' w="40%">
                                <Text fontSize='xl' color={textColor} fontWeight='bold'>
                                    Pending Coaches
                                </Text>
                            </Flex>
                            <Flex justifyContent='right' w="60%">
                                <SearchBar text={searchState} update={updateSearchState}/>
                            </Flex>
                        </Flex>
                    </CardHeader>
                    <CardBody>
                        <Table variant='simple' color={textColor}>
                            <Thead>
                                <Tr my='.8rem' pl='0px' color='gray.400'>
                                    <Th color='gray.400'>
                                        Name
                                    </Th>
                                    <Th color='gray.400'>
                                        Contact email
                                    </Th>
                                    <Th color='gray.400' cursor="pointer" onClick={()=>setSortAscending(!sortAscending)}>
                                        Date {sortAscending ? <Icon as={BsArrowUp} color="gray.400" /> : <Icon as={BsArrowDown} color="gray.400" />}
                                    </Th>
                                    <Th color='gray.400'>
                                        Action
                                    </Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {
                                    sortData(getSearchedData(data,searchState.searchText)).map((row,idx) => {
                                        return(
                                            <Tr key={idx}>
                                                <Td>
                                                    <Text
                                                        fontSize="sm"
                                                        color={textColor}
                                                        fontWeight="bold"
                                                    >
                                                        {row.name}
                                                    </Text>
                                                </Td>
                                                <Td>
                                                    <Text
                                                        fontSize="sm"
                                                        color={textColor}
                                                        fontWeight="bold"
                                                    >
                                                        {row.contact_email}
                                                    </Text>
                                                </Td>
                                                <Td>
                                                    <Text
                                                        fontSize="sm"
                                                        color={textColor}
                                                        fontWeight="bold"
                                                    >
                                                        {new Date(row.createdAt).toLocaleDateString()}
                                                    </Text>
                                                </Td>
                                                <Td>
                                                    <Button
                                                        loadingText='Submitting'
                                                        colorScheme='blue'
                                                        variant='outline'
                                                        mr="10px"
                                                        onClick={()=>(handleAction('activate',row))}
                                                    >
                                                        Activate coach
                                                    </Button>
                                                    <Button
                                                        loadingText='Submitting'
                                                        colorScheme='red'
                                                        variant='outline'
                                                        onClick={()=>(handleAction('decline',row))}
                                                    >
                                                        Decline coach
                                                    </Button>
                                                </Td>
                                            </Tr>
                                        )
                                    })
                                }
                                
                            </Tbody>
                        </Table>
                    </CardBody>
                    <Pagination totalData={getSearchedData(data,searchState.searchText).length} rowsPerPageValues={[10,20,30,40,50]} />
                </Card>

                {/* FOR UNCONFIRMED COACHES */}

                <Card overflowX={{ sm: "scroll", xl: "hidden" }} mt="20px">
                    <CardHeader p='6px 0px 22px 0px'>
                        <Flex direction='row' w="100%">
                            <Flex justifyContent='left' w="40%">
                                <Text fontSize='xl' color={textColor} fontWeight='bold'>
                                    Unconfirmed Coaches
                                </Text>
                            </Flex>
                            <Flex justifyContent='right' w="60%">
                                <SearchBar text={searchState} update={updateSearchState}/>
                            </Flex>
                        </Flex>
                    </CardHeader>
                    <CardBody>
                        <Table variant='simple' color={textColor}>
                            <Thead>
                                <Tr my='.8rem' pl='0px' color='gray.400'>
                                    <Th color='gray.400'>
                                        Name
                                    </Th>
                                    <Th color='gray.400'>
                                        Contact email
                                    </Th>
                                    <Th color='gray.400' cursor="pointer" onClick={()=>setSortAscending(!sortAscending)}>
                                        Date {sortAscending ? <Icon as={BsArrowUp} color="gray.400" /> : <Icon as={BsArrowDown} color="gray.400" />}
                                    </Th>
                                    <Th color='gray.400'>
                                        Action
                                    </Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {
                                    sortData(unconfirmedData).map((row) => {
                                        return(
                                            <Tr>
                                                <Td>
                                                    <Text
                                                        fontSize="sm"
                                                        color={textColor}
                                                        fontWeight="bold"
                                                    >
                                                        {row.name}
                                                    </Text>
                                                </Td>
                                                <Td>
                                                    <Text
                                                        fontSize="sm"
                                                        color={textColor}
                                                        fontWeight="bold"
                                                    >
                                                        {row.contact_email}
                                                    </Text>
                                                </Td>
                                                <Td>
                                                    <Text
                                                        fontSize="sm"
                                                        color={textColor}
                                                        fontWeight="bold"
                                                    >
                                                        {new Date(row.createdAt).toLocaleDateString()}
                                                    </Text>
                                                </Td>
                                                <Td>
                                                    <Button
                                                        loadingText='Submitting'
                                                        colorScheme='blue'
                                                        variant='outline'
                                                        mr="10px"
                                                        onClick={()=>(handleAction('confirm',row))}
                                                    >
                                                        Confirm coach
                                                    </Button>
                                                    <Button
                                                        loadingText='Submitting'
                                                        colorScheme='red'
                                                        variant='outline'
                                                        onClick={()=>(handleAction('decline',row))}
                                                    >
                                                        Decline coach
                                                    </Button>
                                                </Td>
                                            </Tr>
                                        )
                                    })
                                }
                                
                            </Tbody>
                        </Table>
                    </CardBody>
                </Card>
		    </Flex>
        </>
    )
}
