// React imports
import React, { useContext, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
// Context imports
import { DataContext } from "../../../../contexts/dataContext";
// Chakra imports
import {
	Image,
	Box,
	Button,
	Flex,
	Text,
	useColorModeValue,
	Alert,
	AlertIcon,
	CloseButton,
	useDisclosure,
} from "@chakra-ui/react";
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
} from "@chakra-ui/react";
// React-icons
import { FaPen } from "react-icons/fa";
// Assets
import LoginLogo from "assets/img/LoginLogo.png"
// Component 
import { ConfirmModal } from "../../../../components/ConfirmModal/ConfirmModal";
import { DeleteButton } from "../../../../components/Buttons/DeleteButton";
// Auxiliary functions
import { changeUserGroupToAgencyId } from "../../../../helpers";
import { deleteShowcaseAthleteRelationService } from 'services/showcaseServices';
// import { deleteShowcaseCoachRelationService } from 'services/showcaseServices';
import { deleteShowcaseService } from 'services/showcaseServices';


const Header = ({
	id,
	backgroundHeader,
	backgroundProfile,
	name,
	description,
	country,
	address,
	date,
	status,
}) => {
	// Chakra color mode
	const textColor = useColorModeValue("gray.700", "white");
	const borderProfileColor = useColorModeValue("white","rgba(255, 255, 255, 0.31)");
	const emailColor = useColorModeValue("gray.400", "gray.300","royalblue.700");

	// Take the context data needed in this component
    const dataContext = useContext(DataContext)
    const { user, showcases, setShowcases, showcasesAthleteRelation, showcasesCoachRelation,setShowcasesAthleteRelation } = dataContext

	// State that controls if the user has permission to do an action
	const [noPermission, setNoPermission] = useState(false)

	// Navigate hook
	let navigate = useNavigate()

	const userGroup = changeUserGroupToAgencyId(user.signInUserSession.idToken.payload["cognito:groups"][0])

	// Hook for the confirm modal --> delete button
	const { isOpen, onOpen, onClose } = useDisclosure()
	const cancelRef = useRef()

	// Hook for the confirm modal --> has participant alert
	const withParticipantsAlert = useDisclosure()
	const cancelRefParticipants = useRef()

	// Function that handles if the user can do the edit action
	const handleEdit = () => {

		userGroup === "superadmins" 
		? navigate(`/admin/showcases/edit/${id}`) 
		: setNoPermission(true)

	}

	// Function that handles the delete action
	const handleDelete = () => {
		// Check if the showcase has confirmed participants
		let hasParticipants = (showcasesAthleteRelation.filter(it=>it.showcaseID === id).length + showcasesCoachRelation.filter(it=>it.showcaseID === id).length) > 0

		// Only if the user is admin
		hasParticipants && userGroup === "superadmins" 
		? withParticipantsAlert.onOpen()
		: (!hasParticipants && userGroup === "superadmins") ? onOpen() : setNoPermission(true)
	}
	// Function that handles the confirm delete action
	const handleConfirmDelete = () => {
		onClose()
		withParticipantsAlert.onClose()
	
		const associatedAthleteRelation = showcasesAthleteRelation.filter(it=>it.showcaseID === id);
		// const associatedCoachRelation = showcasesCoachRelation.filter(it=>it.showcaseID === id);

		// If the showcase has confirmed participants, we need to delete the relation first
		((associatedAthleteRelation?.length ) > 0 ) // + associatedCoachRelation?.length
		&& (
			// associatedCoachRelation.forEach((coach)=>{deleteShowcaseCoachRelationService(coach.id).catch(error=>hasFailed = true)}),
			// setShowcasesCoachRelation(showcasesCoachRelation.filter(showRel => !associatedCoachRelation.includes(showRel.id))),

			associatedAthleteRelation.forEach((athlete)=>{deleteShowcaseAthleteRelationService(athlete.id).catch(error=>console.log("[ERROR deleting athlete showcase relation]:",error))}),
			setShowcasesAthleteRelation(showcasesAthleteRelation.filter(showRel => !associatedAthleteRelation.includes(showRel.id)))
		) 
		// Delete showcase
		deleteShowcaseService(id).catch(error => { console.log("[ERROR deleting showcase]:",error) })

		setShowcases(showcases.filter(show => show.id !== id))
		navigate(`/admin/showcases/`)
	}

	return (
		<>
			<ConfirmModal
				title={`Delete ${name}`}
				description="Are you sure? You can't undo this action afterwards."
				button1="Cancel"
				button2="Delete"
				isOpen={isOpen}
				onClose={onClose}
				cancelRef={cancelRef}
				handleDelete={handleConfirmDelete}
			/>	


			<AlertDialog
				isOpen={withParticipantsAlert.isOpen}
				leastDestructiveRef={cancelRefParticipants}
				onClose={withParticipantsAlert.onClose}
				motionPreset='scale'
			>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize='lg' fontWeight='bold'>
						Attention! Showcase with participants.
						</AlertDialogHeader>

						<AlertDialogBody>
						You are about to delete a showcase with confirmed participants and this action cannot be recovered afterwards. Do you want to continue?
						</AlertDialogBody>

						<AlertDialogFooter>
						<Button ref={cancelRef} onClick={()=>withParticipantsAlert.onClose()}>
							Cancel
						</Button>
						<Button colorScheme={"red"} onClick={handleConfirmDelete} ml={3}>
							Confirm delete
						</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>


			<Flex justifyContent='right' m="0px 10px 10px 0">
				<DeleteButton handleDelete={handleDelete} />
			</Flex>

			{
				noPermission
				&& (
					<Alert status='error' borderRadius="10px" mb="20px">
						<AlertIcon />
						You don't have enough permissions to perform this action on this team.
						<CloseButton position='absolute' right='8px' top='8px' onClick={()=>(setNoPermission(false))} />
					</Alert>
				)
			}
			
			<Box
				mb={{ sm: "20px", md: "30px", xl: "40px" }}
				borderRadius='15px'
				px='0px'
				display='flex'
				flexDirection='column'
				justifyContent='center'
				align='center'
			>
				<Box
				bgImage={backgroundHeader}
				w='100%'
				h='200px'
				borderRadius='25px'
				bgPosition='50%'
				bgRepeat='no-repeat'
				position='relative'
				display='flex'
				justifyContent='center'>
					<Flex
						direction={{ sm: "column", md: "row" }}
						mx='1.5rem'
						maxH='330px'
						w={{ sm: "90%", xl: "95%" }}
						justifyContent={{ sm: "center", md: "space-between" }}
						align='center'
						backdropFilter='saturate(200%) blur(50px)'
						position='absolute'
						boxShadow='0px 2px 5.5px rgba(0, 0, 0, 0.02)'
						border='2px solid'
						borderColor={borderProfileColor}
						bg={backgroundProfile}
						p='24px'
						borderRadius='20px'
						transform={{
							md: "translateY(55%)",
							lg: "translateY(70%)",
						}}
					>
						
						<Flex direction='column'>

							<Flex
								align='center'
								mb={{ sm: "10px", md: "0px" }}
								direction={{ sm: "column", md: "row" }}
								w={{ sm: "100%" }}
								textAlign={{ sm: "center", md: "start" }}
							>
                                <Image
                                    me={{ md: "22px" }}
                                    src={LoginLogo}
                                    w='200px'
                                    borderRadius='15px'
                                    fit='cover'
                                />	
								
								<Flex direction='column' maxWidth='100%' my={{ sm: "10px" }} mx={{sm:"10px"}} >
									<Text
									fontSize={{ sm: "md", lg: "lg" }}
									color={textColor}
									fontWeight='bold'
									ms={{ sm: "8px", md: "0px" }}>
										{name}
									</Text>
									<Text
									fontSize={{ sm: "sm", md: "md" }}
									color={emailColor}
									fontWeight='semibold'>
										{description}
									</Text>
									
								</Flex>
								<Flex direction='column' maxWidth='100%' my={{ sm: "10px" }} ml="70px">
                                    <Text
									fontSize={{ sm: "sm", md: "md" }}
									color={textColor}
									fontWeight='semibold'>
										{country}
									</Text>
                                    <Text
									fontSize={{ sm: "sm", md: "md" }}
									color={emailColor}
									fontWeight='semibold'>
										{address}
									</Text>
                                    <Text
									fontSize={{ sm: "sm", md: "md" }}
									color={emailColor}
									fontWeight='semibold'>
										{date}
									</Text>

								</Flex>
							</Flex>
						</Flex>


						<Flex
							direction={{ sm: "column", lg: "row" }}
							w={{ sm: "100%", md: "50%", lg: "auto" }}
							
						>	
							<Button p='0px' bg='transparent' _hover={{ bg: "none" }} onClick={handleEdit}>
								<Flex
								align='center'
								w={{ sm: "100%", lg: "135px" }}
								bg='hsla(0,0%,100%,.3)'
								borderRadius='15px'
								justifyContent='center'
								py='10px'
								boxShadow='inset 0 0 1px 1px hsl(0deg 0% 100% / 90%), 0 20px 27px 0 rgb(0 0 0 / 5%)'
								border='1px solid gray.200'
								cursor='pointer'>
									<FaPen w='100%' h='100%' />
									<Text
										fontSize='xs'
										color={textColor}
										fontWeight='bold'
										ms='6px'>
										EDIT
									</Text>
								</Flex>
							</Button>
							
						</Flex>
					</Flex>
				</Box>
			</Box>
		</>
	);
};
  
export default Header;
  