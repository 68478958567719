// React imports
import React, { useEffect } from "react";
// Chakra imports
import { Flex } from "@chakra-ui/react";
// Components
import PremiumCoaches from "./PremiumCoaches";

function PremiumCoachesTables({ coaches, showMoreInfo, withMargin }) {
  const myCaptions = [
    "Information",
    "Team",
    "Subscription date",
    "Subscription Period",
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Flex
      direction="column"
      pt={{
        base: withMargin ? "30px" : "120px",
        md: withMargin ? "20px" : "75px",
      }}
    >
      <PremiumCoaches
        title={"Premium Coaches"}
        captions={myCaptions}
        data={coaches}
        showMoreInfo={showMoreInfo}
      />
    </Flex>
  );
}

const myPropsChanged = (prevProps, nextProps) => {
  if (prevProps.coaches !== nextProps.coaches) {
    return false;
  }
  return true;
};

export default React.memo(PremiumCoachesTables, myPropsChanged);
