import { Button, Checkbox, Flex, Icon, Input, Link, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Td, Text, Textarea, Tr, useColorModeValue, useDisclosure } from '@chakra-ui/react'
import React, { useState } from 'react'
import { MdEdit } from 'react-icons/md'

export const NotificationTemplatesRow = ({row, selectItem, handleSelectItem}) => {

    const textColor = useColorModeValue("gray.700", "white");

    const editTemplateModal = useDisclosure()
    const [seeMore, setSeeMore] = useState(false);
    // state to edit template
	const [templateToEdit,setTemplateToEdit] = useState({
		id:'',
		title: '',
		text: '',
	})

    const handleUpdateTemplateNotification = () => {
		updateNotificationService({ id:templateToEdit.id,text:templateToEdit.text })
		.then(() => {
			// set action status with success message
			setActionStatus({
				done: true,
				status: 'success',
				message: "The template has been successfully created."
			})
			setTemplatesNotifications(
				templatesNotifications.map(template => {
					if(template.id === templateToEdit.id){
						return {...templateToEdit}
					}
					return template
				})
			)
			editTemplateModal.onClose()
		})
		.catch(err => {
			// set action status with error message
			setActionStatus({
				done: true,
				status: 'error',
				message: '[Templates] Something went wrong. Please, contact the admin.'
			})
		})
	}
    
    
    const onChangeEditTemplate = (e) => {
		e.persist()
        setTemplateToEdit(() => ({ ...templateToEdit , [e.target.name]: e.target.value }))
	}


    const processText = (text) => {

		if(!seeMore && text.length > 70){
			let sliceText = text.slice(0,70).replaceAll("\n",' ')
			sliceText = sliceText + '...'
			return sliceText.split("\n").map(it=>{return <>
				{it}<br />
			</>})
		}
		else{
			return text.split("\n").map(it=>{return <>
				{it}<br />
			</>})
		}
	}

    return (
        <>
            {/* Modal to edit an existing template */}
            <Modal
				isCentered
				onClose={editTemplateModal.onClose}
				isOpen={editTemplateModal.isOpen}
				motionPreset='slideInBottom'
			>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Edit template </ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						You will edit an existing template to be used for notifications. Remember, a template can be used for a general notification and therefore should not contain coach-specific data.<br /><br />
						<strong>Enter the name for the template: </strong>
						<Input
							variant='flushed'
							fontSize='sm'
							placeholder='Title for the template'
							size='md'
							value={templateToEdit?.title || ''}
							onChange={onChangeEditTemplate}
							name='title'
							mb="20px"
						/>
						<Text fontSize='md'  color={textColor} mb="5px">
							<strong>Enter the text</strong> you wish to be in the template:
						</Text>
						<Textarea
							placeholder='Type here some text...' 
							resize={"none"} 
							size="md"
							h={{sm:"150px",lg:"200px"}} 
							value={templateToEdit?.text}
							onChange={onChangeEditTemplate}
							name='text'	
						/>
					</ModalBody>
					<ModalFooter>
						<Button colorScheme='red' mr={3} onClick={editTemplateModal.onClose}>
							Cancel
						</Button>
						<Button variant='ghost' colorScheme='blue' onClick={handleUpdateTemplateNotification} isDisabled={templateToEdit.text === '' && templateToEdit.title === ''}>Save template</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
            <Tr>
                <Td>
                    <Checkbox colorScheme='blue' value={row.id} onChange={(e)=>(handleSelectItem(e))} isChecked={selectItem.find((item) => item === row.value)} />
                </Td>
                <Td>
                    <Text
                        fontSize="sm"
                        color={textColor}
                        fontWeight="bold"
                    >
                        {row.title}
                    </Text>
                </Td>
                <Td>
                    <Flex direction={"column"}>
                        <Text
                            fontSize="sm"
                            color={textColor}
                            >
                            {processText(row.text)} 

                        </Text>

                        {
                            row.text.length > 70 && (
                                <Link 
                                    color="blue" 
                                    fontSize="sm"
                                    mt="10px"
                                    onClick={()=>setSeeMore(!seeMore)}
                                >
                                    Read {seeMore ? 'less' : 'more'}
                                </Link>
                            )
                        }
                    </Flex>
                </Td>
                <Td>
                    <Icon 
                        as={MdEdit} 
                        onClick={()=>(
                            setTemplateToEdit({id:row.id,text:row.text,title:row.id}),
                            editTemplateModal.onOpen()
                        )}
                        cursor="pointer"
                    />
                </Td>
            </Tr>
        </>
    )
}
