// React imports
import React, { useContext, useState,useEffect, useRef } from 'react'
import { useNavigate } from "react-router-dom";
// Context imports
import { DataContext } from '../../../../contexts/dataContext'
// Chakra imports
import {
	Table,
	Tbody,
	Text,
	Th,
	Thead,
	Tr,
	useColorModeValue,
	Flex,
	useDisclosure,
	Checkbox,
	Alert,
	AlertIcon,
	CloseButton,
	Icon,
	Select,
	Button,
	Input,
	Divider,
	Textarea,
} from "@chakra-ui/react";
import {
	Drawer,
	DrawerBody,
	DrawerFooter,
	DrawerHeader,
	DrawerOverlay,
	DrawerContent,
	DrawerCloseButton,
} from '@chakra-ui/react'
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
  } from '@chakra-ui/react'
// Auxiliar functions
import { getSearchedData } from "helpers/getSearchedData";
import { changeUserGroupToAgencyId, defaultTemplates} from "../../../../helpers";
import { deleteCoachATagsRelationService, deleteCoachAthleteFavoritesService, deleteCoachRelations, deleteCoachService, deleteFavoritesFolderService } from "../../../../services/coachServices";
import { deleteUserCognito } from "../../../../services/cognitoServices";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { Pagination } from "components/Pagination/Pagination";
import { SearchBar } from "components/SearchBar/SearchBar";
import CoachesTableRow from "./CoachesTableRow";
import { DeleteButton } from "../../../../components/Buttons/DeleteButton";
import { CreateButton } from "../../../../components/Buttons/CreateButton";
import { ConfirmModal } from "../../../../components/ConfirmModal/ConfirmModal";
import {BsArrowDown, BsArrowUp} from 'react-icons/bs'
import { MdNotifications } from 'react-icons/md';
import { v4 as uuidv4 } from 'uuid';
import { createNotificationService } from 'services/notificationServices';
import { createCoachNotificationService } from 'services/notificationServices';
import { CSVLink } from 'react-csv';
import { HiDownload } from 'react-icons/hi';



const Coaches = ({ title, captions, data,showMoreInfo}) => {
	// Chakra color mode
	const textColor = useColorModeValue("gray.700", "white");
	
	// Take the context data needed in this component
	const dataContext = useContext(DataContext)
	const { 
		pagination,
		updatePagination, 
		user,
		userCoachData,
		coaches,
		setCoaches, 
		teams, 
		coachesCognito, 
		setCoachesCognito,
		coachAthleteFavorites,
		setCoachAthleteFavorites,
    templatesNotifications,
    setTemplatesNotifications,
		coachTagRelations,
		setCoachTagRelation,
		favoritesFolders,
		setFavoritesFolder,
		athletes,
		athletesEnquirys,
		feedbacks,
		coachMessages,
		searchTerms
	} = dataContext

	// Cognito user group
	const userGroup = changeUserGroupToAgencyId(user?.signInUserSession.idToken.payload["cognito:groups"][0])
	// State with the searched text
	const [searchState, updateSearchState] = useState({searchText: ''})
	// State that handles the selection of items
	const [ selectItem, setSelectItem ] = useState([])
	// State that controls if the user has permission to do an action
	const [ noPermission, setNoPermission ] = useState(false)
	// State that control if sort is ascending or descending
	const [ sortAscending, setSortAscending ] = useState(
		{
			'Information':true,
			'Creation date':true,
			'Actions (last 30 days)':true,
		}
	)

	const prevSortStatus = useRef();
	useEffect(() => {
		//assign the ref's current value to the sort Hook
		prevSortStatus.current = sortAscending;
	}, [sortAscending]);


	const uniqueValuesCategories = [... new Set(teams.filter(it=>it.college_category).map(team => {return team.college_category}))].sort((a,b) => {return a.toLowerCase().localeCompare(b.toLowerCase())})

	const [ filters, setFilters ] = useState({
		sport: 0,
		sportData: ['ALL','Soccer', 'Tennis'],
		teamGender:0,
		teamGenderData:['ALL','Male','Female','Team Base'],
		category:'0',
		categoryData: [{value:'0',label:'ALL'}, ...uniqueValuesCategories.map(it => {return {value:it,label:it}})],
		status: '0',
		statusData: [
			{value:'0',label:'ALL'},
			// {value:'CONFIRMED',label:'Confirmed'},
			// {value:'UNCONFIRMED',label:'Unconfirmed'},
			{value:'PREMIUM_PASS',label:'Premium user'},
			{value:'SEASON_PASS',label:'Trial user'},
			{value:'FREE_PERIOD',label:'Free user'},
			// {value:'FORCE_CHANGE_PASSWORD',label:'Not login yet'},
			// {value:'FIX',label:'Fix profile'},
		]
	})

	// State with action feedback
	const [ actionStatus, setActionStatus ] = useState({
		done: false,
        status: '',
        message: ''
    })
	// state for notification
	const notificationInitialState = {
		text: '',
	}
	const [notification,setNotification] = useState(notificationInitialState)
	// state for new template
	const [newTemplate,setNewTemplate] = useState({
		id:uuidv4(),
		template_title: '',
		text: '',
	})

	// Hook for the confirm modal
	const { isOpen, onOpen, onClose } = useDisclosure()
	const cancelRef = useRef()

	// Disclosure for Drawer
	const sendNotificationDrawer = useDisclosure();
	const btnRef = useRef();

	// Disclosure for Drawer
	const createTemplateModal = useDisclosure();

	

	const onChangeTypeNotification = (e) => {
		e.target.value === "template" 
		?  	setNotification({
				text:'Select a template and edit it.', 
				type:"template",
			})
		: setNotification({
			text:'', 
			type:"regular"
		})
	}
	const onChangeNotificaction = (e) => {
        e.persist()
        setNotification(() => ({ ...notification , [e.target.name]: e.target.value }))
    }
	const onChangeTemplateNotification = (e) => {
        e.persist()
        setNotification(() => ({ ...notification , 'title':templatesNotifications.find(opt => opt.id === e.target.value)?.title,'text': templatesNotifications.find(opt => opt.id === e.target.value)?.text }))
    }


	const handleSendNotification = () => {
		sendNotificationDrawer.onClose()

		let hasFailed = false
		const createdNotificationID = uuidv4()
		

		createNotificationService({
			id:createdNotificationID,
			title:notification.title,
			text:notification.text,
			status:"ACTIVE",
			type:'regular',
		})
		.then(()=>(
			selectItem.forEach((item) => {
				createCoachNotificationService({
					id:uuidv4(),
					notificationID:createdNotificationID,
					coachID:item,
					is_viewed:false,
					status:"ACTIVE",
				})
				.catch(()=>{hasFailed = true})
			})
		))
		.catch(err => console.log(err))

		// setSelectItem([])
		!hasFailed ? setActionStatus({
			done: true,
			status: 'success',
			message: 'Notification sent successfully'
		}): setActionStatus({
			done: true,
			status: 'error',
			message: 'An error has ocurred while sending the notification'
		})

	}

	const onChangeCreateTemplate = (e) => {
		e.persist()
        setNewTemplate(() => ({ ...newTemplate , [e.target.name]: e.target.value }))
	}


	// Navigation hook 
	let navigate = useNavigate()

	useEffect(() => {
		showMoreInfo && (data = [...coaches])
	}, [coaches])

	useEffect(() => {
		updatePagination({
			total:getSearchedData(data,searchState.searchText).length,
			page: 1,
			rowsPerPage: 10,
			lower: 1})
		setSelectItem([])
	}, [])

	// Function that handles the selection of all items
	const handleSelectAll = (e) => {
		e.target.checked ? setSelectItem(data.map(item => item.id)) : setSelectItem([])
	}

	const filterData = (data) => {
		let newData = data
		if (filters.sport === 0 || filters.teamGender === 0 || filters.category === '0' || filters.status === '0') {newData = data}
		if (filters.sport > 0){newData = newData.filter(item => item.sport === filters.sportData[filters.sport])}
		if (filters.teamGender > 0 && filters.teamGender < 3){newData = newData.filter(item => item.gender === filters.teamGenderData[filters.teamGender].toLowerCase())}
		if (filters.teamGender === 3){newData = newData.filter(item => teams.find(team => team.id === item.teamID)?.name === 'Team Base')}
		if (filters.category !== '0') {newData = newData.filter(item => item.team?.college_category === filters.category)}

		switch (filters.status) {
			case 'CONFIRMED':
				newData = newData.filter( it => coachesCognito.find(item => item.Attributes.at(-1).Value.toLowerCase() === it.contact_email.toLowerCase())?.UserStatus	=== 'CONFIRMED')
				break;
			case 'UNCONFIRMED':
				newData = newData.filter( it => coachesCognito.find(item => item.Attributes.at(-1).Value.toLowerCase() === it.contact_email.toLowerCase())?.UserStatus	=== 'UNCONFIRMED')
				break;
			case 'PREMIUM_PASS':
				newData = newData.filter(item=> item.team.suscription_name === 'PREMIUM_PASS')
				break;
			case 'SEASON_PASS':
				newData = newData.filter(item=> item.team.suscription_name === 'SEASON_PASS')
				break;
			case 'FREE_PERIOD':
				newData = newData.filter(item=> item.team.suscription_name === 'FREE_PERIOD')
				break;
			case 'FORCE_CHANGE_PASSWORD':
				newData = newData.filter( it => coachesCognito.find(item => item.Attributes.at(-1).Value.toLowerCase() === it.contact_email.toLowerCase())?.UserStatus	=== 'FORCE_CHANGE_PASSWORD')
				break;
			case 'FIX':
				newData = newData.filter(item=> item.is_active.toString().toLowerCase() != coachesCognito.find(it=>it.Attributes.find(it => it['Name']==="email")['Value'] === item.contact_email)?.Attributes?.find(it => it['Name']==="custom:isActive")['Value'].toLowerCase())
				break;
		}

		if(prevSortStatus?.current){
			// console.log(`name -> prev: ${prevSortStatus.current['Information']} actual:${sortAscending['Information']}`)
			// console.log(`createdAt -> prev: ${prevSortStatus.current['Creation date']} actual:${sortAscending['Creation date']}`)
			if(prevSortStatus?.current['Creation date'] !== sortAscending['Creation date']){newData = newData.sort((a,b) => {
				if(sortAscending['Creation date']){return new Date(a.createdAt) - new Date(b.createdAt)}
				else{return new Date(b.createdAt) - new Date(a.createdAt)}
			})}
			if(prevSortStatus?.current['Information'] !== sortAscending['Information']){newData = newData.sort((a,b) => {
				if(sortAscending['Information']){return a.name.toLowerCase().localeCompare(b.name.toLowerCase())}
				else{return b.name.toLowerCase().localeCompare(a.name.toLowerCase())}
			})}
			if(prevSortStatus?.current['Actions (last 30 days)'] !== sortAscending['Actions (last 30 days)']){newData = newData.sort((a,b) => {
				if(sortAscending['Actions (last 30 days)']){return a.actions - b.actions}
				else{return b.actions - a.actions}
			})}
		}
		return newData
	}
	
	// Function that handles the delete action
	const handleDelete = () => {
		// Only if the user want to delete an agent of his agency or the user is admin
		userGroup === "superadmins"
		? onOpen()
		: setNoPermission(true)
	}

	// Function that handles the confirm delete action
	const handleConfirmDelete = () => {
		onClose()

		let hasFailed = false
		// Delete the selected items
		selectItem.forEach((item) => {
			deleteCoachService(item).catch(() => { hasFailed = true })
			deleteUserCognito(coachesCognito.find(it=>it.Attributes.find(it => it['Name']==="email")['Value'] === coaches.find(it => it.id === item).contact_email).Username).catch(() => { hasFailed = true })
			deleteCoachRelations(dataContext,item).then(res=>res && (hasFailed = true))

			// // delete coach relations
			// 	// Delete all the favorites-athlete relations of this coach
			// coachAthleteFavorites.map(caf=>{
			// 	if(caf.coachID === item){
			// 		deleteCoachAthleteFavoritesService(caf.id).catch(() => { hasFailed=true })
			// 	}}
			// )
			// setCoachAthleteFavorites(coachAthleteFavorites.filter(caf=>caf.coachID !== item))
			// 	// Delete all the favorites folder created by this coach
			// favoritesFolders.map(ff=>{
			// 	if(ff.coachID === item){
			// 		deleteFavoritesFolderService(ff.id).catch(() => { hasFailed=true })
			// 	}}
			// )
			// setFavoritesFolder(favoritesFolders.filter(ff=>ff.coachID !== item))
			// 	// Delete all the coach-tags relation
			// coachTagRelations.map(ctr=>{
			// 	if(ctr.coachID === item){
			// 		deleteCoachATagsRelationService(ctr.id).catch(() => { hasFailed=true })
			// 	}}
			// )
			// setCoachTagRelation(coachTagRelations.filter(ctr=>ctr.coachID !== item))

		})
		setCoaches(coaches.filter(item => !selectItem.includes(item.id)))
		setCoachesCognito(coachesCognito.filter(item => !selectItem.includes(item.Attributes.at(-2).Value)))
		setSelectItem([])
		!hasFailed ? setActionStatus({
			done: true,
			status: 'success',
			message: 'Coach(es) deleted successfully'
		}): setActionStatus({
			done: true,
			status: 'error',
			message: 'An error has ocurred while deleting the coach(es)'
		})
		navigate(`/admin/coaches/`)
	}

	const handleCreateTemplateNotification = () => {

		createNotificationService({ title: newTemplate.template_title, text:newTemplate.text, id: newTemplate.id, type: 'template' })
		.then(() => {
			// set action status with success message
			setActionStatus({
				done: true,
				status: 'success',
				message: "The template has been successfully created."
			})
			setTemplatesNotifications([...templatesNotifications, { title: newTemplate.template_title, text:newTemplate.text, id: newTemplate.id, type: 'template' }])
			createTemplateModal.onClose()
		})
		.catch(err => {
			// set action status with error message
			setActionStatus({
				done: true,
				status: 'error',
				message: '[Templates] Something went wrong. Please, contact the admin.'
			})
		})
	}


	const downloadData = () => {
		return coaches?.filter(it => it.teamID !== '0118da1d-2951-4fa0-8fd2-840e9e833b33').map(item => { return {
			"Name" : item.name ? item.name : 'Not provided',
			"Contact email" : item.contact_email ? item.contact_email : 'Not provided',
			"Contact phone" : item.contact_phone ? item.contact_phone : 'Not provided',
			"University" : item.team?.name ? item.team?.name : 'Not provided',
			"Category" : (item.team?.college_category || !item.team?.college_category?.includes('Not found')) ? item.team?.college_category : 'Not provided',
			"Division" : item.division ? item.division : 'Not provided',
			"Role" : item.role ? item.role : 'Not provided',
			"Sport" : item.sport ? item.sport : 'Not provided',
			"Gender" : item.team?.genre ? item.team.genre : 'Not provided',
			"Actual subscription" : item.team?.suscription_name ? item.team?.suscription_name.replace('_',' ') : 'Not provided',
			"Actions (last 30 days)" : item.actions ? item.actions : '0',
			"Total Actions" : item.totalActions && item.totalActions > 0 ? item.totalActions : '0',
			"Creation date" : item.createdAt ? item.createdAt.split('T')[0] : 'Not provided',
		}})
	} 

	return (
		<>	
			{/* 
				Delete coach confirm modal
			*/}
			<ConfirmModal
				title={selectItem.length === 1 ? "Delete Coach" : "Delete Coaches"}
				description="Are you sure? You can't undo this action afterwards."
				button1="Cancel"
				button2="Delete"
				isOpen={isOpen}
				onClose={onClose}
				cancelRef={cancelRef}
				handleDelete={handleConfirmDelete}
			/>
			

			{/* 
				Send notification drawer
			*/}

			<Drawer
				isOpen={sendNotificationDrawer.isOpen}
				placement='right'
				onClose={sendNotificationDrawer.onClose}
				finalFocusRef={btnRef}
			>
				<DrawerOverlay />
				<DrawerContent>
					<DrawerCloseButton onClick={()=>(setNotification({text:'',type:''}))} />
					<DrawerHeader>
						Send notification
						<Divider mt="2px"/>
					</DrawerHeader>

					<DrawerBody>
						{
							selectItem.length === 1 ? (
								<Text fontSize='md' color={textColor} mb="10px">
									<strong>To:</strong> <i>{coaches.find(it=>it.id === selectItem[0])?.name}</i>
								</Text>
							) : (
								<Text fontSize='md' color={textColor} mb="10px">
									<strong>To:</strong> <i>{selectItem.length} coaches</i>
								</Text>
							)
						}
						<Text fontSize='md' color={textColor} fontWeight="bold">
							Type of notification:
						</Text>
						<Select
							variant='flushed' 
							size="sm" 
							placeholder='Select option'
							value={notification.type} onChange={onChangeTypeNotification}
							mb="10px"
						>
							<option key="1" color="royalblue" value="template">Template</option>
							<option key="2" color="royalblue" value="regular">Regular notification</option>
						</Select>

						{
							notification.type === "template" ? (
								<>
									<Text fontSize='md' color={textColor} mt="20px" fontWeight="bold">
										Available templates:
									</Text>
									<Select
										variant='flushed' 
										size="sm" 
										placeholder='Select option'
										value={notification.template} 
										onChange={onChangeTemplateNotification}
										mb="10px"
									>
										{
											templatesNotifications.map((opt, idx)=>(
												<option key={idx} color="royalblue" value={opt.id}>{opt.title}</option>
											))
										}
									</Select>
									{userGroup === "superadmins" && (
										<Flex direction="row">
											<Text fontSize='sm' color={useColorModeValue("gray.300", "white")} mb="20px">
												<i>Need new template?</i> 
											</Text>
											<Text fontSize='sm' color={useColorModeValue("gray.300", "white")} m="0 0 20px 5px" cursor={"pointer"} onClick={()=>(sendNotificationDrawer.onClose(),createTemplateModal.onOpen())}>
												<i><strong>Create it.</strong></i> 
											</Text>
										</Flex>
									)}
								</>
							)
							: (
								<>
									<Text fontSize='md' color={textColor} mt="20px" fontWeight="bold">
										Enter title:
									</Text>
									<Input
										variant='flushed'
										fontSize='sm'
										placeholder='Title for the template'
										size='md'
										value={notification?.title || ''}
										onChange={onChangeNotificaction}
										name='title'
										mb="20px"
									/>
								</>
							)
						}
						<Text fontSize='md'  color={textColor}>
							<strong>Enter the notification</strong> you wish to send to the selected coaches.
						</Text>
						<Textarea 
							placeholder='Type here some text...' 
							resize={"none"} 
							h={{sm:"150px",lg:"300px"}} 
							value={notification?.text}
							onChange={onChangeNotificaction}
							name='text'	
						/>

					</DrawerBody>

					<DrawerFooter>
						<Flex direction="column">
							<Text fontSize='sm' color={useColorModeValue("gray.300", "white")}>
								<i>You need to enter all the fields in order to send the notification.</i>
							</Text>
							<Flex direction="row" mt="5px">
								<Button variant='outline' w="100%" mr={3} onClick={()=>(sendNotificationDrawer.onClose(), setNotification({text:'',type:''}))}>
									Cancel
								</Button>
								<Button 
									colorScheme='teal' 
									w="100%" 
									isDisabled={notification.text === '' || notification.type === ''}
									onClick={handleSendNotification}
								>
									Send
								</Button>
							</Flex>
						</Flex>
					</DrawerFooter>
				</DrawerContent>
			</Drawer>
			{/* 
				END Send notification drawer
			*/}

			{/* 
				Create new template modal
			*/}
			<Modal
				isCentered
				onClose={createTemplateModal.onClose}
				isOpen={createTemplateModal.isOpen}
				motionPreset='slideInBottom'
			>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Create new template </ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						You will create a new template to be used for notifications. Remember, a template can be used for a general notification and therefore should not contain coach-specific data.<br /><br />
						<strong>Enter the name for the template: </strong>
						<Input
							variant='flushed'
							fontSize='sm'
							placeholder='Title for the template'
							size='md'
							value={newTemplate?.template_title || ''}
							onChange={onChangeCreateTemplate}
							name='template_title'
							mb="20px"
						/>
						<Text fontSize='md'  color={textColor} mb="5px">
							<strong>Enter the text</strong> you wish to be in the template:
						</Text>
						<Textarea 
							placeholder='Type here some text...' 
							resize={"none"} 
							size="md"
							h={{sm:"150px",lg:"200px"}} 
							value={newTemplate?.text}
							onChange={onChangeCreateTemplate}
							name='text'	
						/>
					</ModalBody>
					<ModalFooter>
						<Button colorScheme='red' mr={3} onClick={createTemplateModal.onClose}>
							Cancel
						</Button>
						<Button 
							variant='ghost' 
							colorScheme='blue' 
							onClick={()=>(
								userGroup === "superadmins"
								? handleCreateTemplateNotification()
								: setNoPermission(true)
							)} 
							isDisabled={newTemplate.text === '' && newTemplate.template_title === ''}
						>
							Save template
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
			{/* 
				END Create new template modal
			*/}


			{
				showMoreInfo && (
					<Flex justifyContent='right' m="0px 10px 10px 0">
						<CSVLink
							data={downloadData()}
							filename={"AA-CoachesData.csv"}
						>
							<Button
								colorScheme='green'
								variant='ghost'
								leftIcon={<Icon as={HiDownload}/>}
								size='sm'
							>
								Download Coaches Data
							</Button>
						</CSVLink>
						{
							selectItem.length > 0 
							? (
								<>
								
									<Button 
										ref={btnRef} 
										colorScheme='teal' 
										onClick={sendNotificationDrawer.onOpen}
										variant='ghost'
										leftIcon={<Icon as={MdNotifications} />}
										size='sm'
									>
										SEND NOTIFICATION
									</Button>
									<DeleteButton handleDelete={handleDelete} />
								</>
							)
							: null
						}
						<CreateButton handleCreate={() => { userGroup === "superadmins" ? navigate(`/admin/coaches/create`) : setNoPermission(true) }}/>
					</Flex>
				)
			}
			{
				noPermission && (
					<Alert status='error' borderRadius="10px" mb="20px">
						<AlertIcon />
						You don't have enough permissions to perform this action on coaches.
						<CloseButton position='absolute' right='8px' top='8px' onClick={()=>(setNoPermission(false))} />
					</Alert>
				)
			}
			{
				actionStatus.done && (
					<Alert status={actionStatus.status} borderRadius="10px" mb="20px" >
						<AlertIcon />
						{actionStatus.message}
						<CloseButton position='absolute' right='8px' top='8px' onClick={()=>(setActionStatus({...actionStatus,done:false}))} />
					</Alert>
				)
			}

			<Card overflowX={{ sm: "scroll", xl: "hidden" }}>
				<CardHeader p='6px 0px 22px 0px'>
					<Flex direction='row' w="100%">
						<Flex justifyContent='left' w="40%">
							<Text fontSize='xl' color={textColor} fontWeight='bold'>
								{/* {title} ({searchState.searchText!== '' ? `Filtered result ${getSearchedData(data,searchState.searchText,'coach').length}` : data.length} - {selectItem.length} selected) */}
								{title} ({searchState.searchText!== '' ? `Filtered result ${filterData(getSearchedData(data,searchState.searchText)).length}` : filterData(data).length} - {selectItem.length} selected)

							</Text>
						</Flex>
						<Flex justifyContent='right' w="60%">
							<SearchBar text={searchState} update={updateSearchState}/>
						</Flex>
					</Flex>
				</CardHeader>
				<CardBody>
					<Table variant='simple' color={textColor}>
						<Thead>
							<Tr my='.8rem' pl='0px' color='gray.400'>
								{
									showMoreInfo && (
										<Th color='gray.400' ><Checkbox colorScheme='blue' onChange={(e)=>(handleSelectAll(e))} /></Th>
									)
								}
								{captions.map((caption, idx) => {
									return (
										caption === 'Information' || caption === 'Creation date' || caption === 'Actions (last 30 days)' ? (
											<Th key={idx} color='gray.400' cursor="pointer" onClick={()=>setSortAscending({...sortAscending,[caption]:!sortAscending[caption]})}>
												{caption} {sortAscending[caption] ? <Icon as={BsArrowUp} color="gray.400" /> : <Icon as={BsArrowDown} color="gray.400" />}
											</Th>
										):(
											caption === 'Sport' ? (
												<Th color='gray.400' >
													Sport<br />
													<Select variant='unstyled' size="xs" value={filters.sport} onChange={(e)=>setFilters({...filters, sport:parseInt(e.target.value)})}>
														{filters.sportData.map((opt,index) => {return (<option value={index} color="royalblue">{opt}</option>)})}
													</Select>
												</Th>
											) : (
												caption === "Team" ? (
													<Th color='gray.400' >
														Team<br />
														<Select variant='unstyled' size="xs" value={filters.teamGender} onChange={(e)=>setFilters({...filters, teamGender:parseInt(e.target.value)})}>
															{filters.teamGenderData.map((opt,index) => {return (<option value={index} color="royalblue">{opt}</option>)})}
														</Select>
													</Th>
												) : (
													caption === 'Category' ? (
														<Th color='gray.400'>
															{caption}<br />
															<Select variant='unstyled' size="xs" value={filters.category} onChange={(e)=>setFilters({...filters, category:e.target.value})}>
																{filters.categoryData.map((opt,index) => {return (<option key={index} value={opt.value} color="royalblue">{opt.label}</option>)})}
															</Select>
														</Th>
													):(
														caption === 'User status' ? (
															<Th color='gray.400' key={idx} >
															{caption}<br />
															<Select variant='unstyled' size="xs" value={filters.status} onChange={(e)=>setFilters({...filters, status:e.target.value})}>
																{filters.statusData.map((opt,index) => {return (<option value={opt.value} color="royalblue">{opt.label}</option>)})}
															</Select>
															</Th>
														) : (
															<Th color='gray.400' key={idx} >
															{caption}
															</Th>
														)
													)
												)
												
											)
										)
									);
								})}
							</Tr>
						</Thead>
						<Tbody>
							{filterData(getSearchedData(data,searchState.searchText)).slice(pagination.lower-1,pagination.lower+pagination.rowsPerPage-1).map((row) => {
								return (
									// row.is_active && row.is_active === true ?(
										showMoreInfo
										? ( 
											<CoachesTableRow
											key={`${row.id}`}
											id={row.id}
											name={row.name}
											contact_email={row.contact_email}
											role={row.role}
											sport={row.sport ? row.sport : 'Not especified'}
											division={row.division ? row.division : 'Not especified'}
											team={teams.find(team => team.id === row.teamID)}
											team_category={teams.find(team => team.id === row.teamID)?.college_category}
											gender={teams.find(team => team.id === row.teamID)?.genre}
											createdAt={ row.createdAt ? row.createdAt : 'Not especified' }
											status={coachesCognito.find(item => item.Attributes.at(-1)?.Value.toLowerCase() === row.contact_email.toLowerCase())?.UserStatus}
											activeProfile={row.is_active.toString().toLowerCase() == coachesCognito.find(it=>it.Attributes.find(it => it['Name']==="email")?.Value === row.contact_email)?.Attributes.find(it => it['Name']==="custom:isActive")?.Value.toLowerCase() ? (row.is_active ? 'Active' : 'Pending') : 'Fix profile'}
											actions={row.actions}
											selectItem={selectItem}
											setSelectItem={setSelectItem}
											/>
										)
											: (
											<CoachesTableRow
											key={`${row.id}`}
											id={row.id}
											name={row.name}
											contact_email={row.contact_email}
											role={row.role}
											actions={row.actions}
											/>
										)
									// ) : null
								);
							})}
						</Tbody>
					</Table>
				</CardBody>
				<Pagination totalData={filterData(getSearchedData(data,searchState.searchText)).length} rowsPerPageValues={[10,20,30,40,50]} />
			</Card>
		</>
	);
};

const myPropsChanged = (prevProps, nextProps) => {
    if ( prevProps.data !== nextProps.data || prevProps.captions !== nextProps.caption) {
        return false;
    }
    return true;
}

export default React.memo(Coaches, myPropsChanged);