// React imports
import React, { useContext, useState,useEffect, useRef } from 'react'
// Context imports
import { DataContext } from '../../../../contexts/dataContext'
// Chakra imports
import {
	Table,
	Tbody,
	Text,
	Th,
	Thead,
	Tr,
	useColorModeValue,
	Flex,
	Spacer,
	Icon,
	Button,
} from "@chakra-ui/react";
// Auxiliar functions
import { getSearchedData } from "helpers/getSearchedData";
import { changeUserGroupToAgencyId } from "../../../../helpers";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { Pagination } from "components/Pagination/Pagination";
import { SearchBar } from "components/SearchBar/SearchBar";
import AuxCoachesTableRow from './AuxCoachesTableRow';
import {HiDownload} from 'react-icons/hi'
import { CSVLink, CSVDownload } from "react-csv";

const AuxCoaches = ({ title, captions, data}) => {
	// Chakra color mode
	const textColor = useColorModeValue("gray.700", "white");
	
	// Take the context data needed in this component
	const dataContext = useContext(DataContext)
	const { pagination,updatePagination, user,userCoachData, coaches, setCoaches, teams, coachesCognito, setCoachesCognito } = dataContext
	// Cognito user group
	const userGroup = changeUserGroupToAgencyId(user?.signInUserSession.idToken.payload["cognito:groups"][0])
	// State with the searched text
	const [searchState, updateSearchState] = useState({searchText: ''})

	// State that control if sort is ascending or descending
	const [ sortAscending, setSortAscending ] = useState(
		{
			'Information':true,
			'Creation date':true,
		}
	)
	const prevSortStatus = useRef();
	useEffect(() => {
		//assign the ref's current value to the sort Hook
		prevSortStatus.current = sortAscending;
	}, [sortAscending]);

	useEffect(() => {
		updatePagination({
			total:getFilteredData(data,searchState.searchText).length,
			page: 1,
			rowsPerPage: 10,
			lower: 1})
	}, [])


	const getFilteredData = (data, text) => {

		let filteredInfo = []

		text!== '' ? (data.map((item) => {
			Object.keys(item).map((key,idx)=>{
				// If any key value includes text then push them to the result
				if(item[key].includes(text)){
					filteredInfo.push(item)
				}
			})
		})) : data

		return text!== '' ? filteredInfo : data
	}	

	return (
		<>	
			<Flex justifyContent='right' m="0px 10px 10px 0">
				
				<CSVLink 
					data={data}
					filename={"AuxDataAllianceAthletes.csv"}
				>
					<Button
						colorScheme='green'
						variant='ghost'
						leftIcon={<Icon as={HiDownload}/>}
						size='sm'
					>
						Export to CSV
					</Button>
				</CSVLink>
			</Flex>
		
			<Card>
				<CardHeader p='6px 0px 22px 0px'>
					<Flex direction='row' w="100%">
						<Flex justifyContent='left' w="60%">
							<Text fontSize='xl' color={textColor} fontWeight='bold'>
								{title} ({searchState.searchText!== '' ? `Filtered result ${getFilteredData(data,searchState.searchText).length}` : data.length})

							</Text>
						</Flex>
						<Spacer />
						<Flex direction="column" alignItems="end" >
								<SearchBar text={searchState} update={updateSearchState}/>
								<Text fontSize='xs' color='gray.400' m="5px 10% 0 0" >
									<i>*This search bar will search for any attribute shown below</i>
								</Text>
						</Flex>
					</Flex>
				</CardHeader>
				<CardBody overflowX={{ sm: "scroll", xl: "scroll" }}>
					<Table variant='simple' color={textColor}>
						<Thead>
							<Tr my='.8rem' pl='0px' color='gray.400'>
								{captions.map((caption, idx) => {
									return (
										<Th color='gray.400' key={idx} >
											{caption}
										</Th>
												
									);
								})}
							</Tr>
						</Thead>
						<Tbody>
							{getFilteredData(data,searchState.searchText).slice(pagination.lower-1,pagination.lower+pagination.rowsPerPage-1).map((row) => {
								return (
									<AuxCoachesTableRow
										key={`${row.id}`}
										coachInfo={row}
										captions={captions}
									/>
								);
							})}
						</Tbody>
					</Table>
				</CardBody>
				<Pagination totalData={getFilteredData(data,searchState.searchText).length} rowsPerPageValues={[10,20,30,40,50]} />
			</Card>
		</>
	);
};

const myPropsChanged = (prevProps, nextProps) => {
    if ( prevProps.data !== nextProps.data || prevProps.captions !== nextProps.caption) {
        return false;
    }
    return true;
}

export default React.memo(AuxCoaches, myPropsChanged);