// Chakra imports
import { Button, Flex, ScaleFade, Text, useColorModeValue } from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import TitleCoachDateRow from "components/Tables/TitleCoachDateRow";
import React, { useContext, useState }  from "react";
import { DataContext } from '../../../../contexts/dataContext'

const OrdersOverview = ({ title, subtitle, amount, data, icon }) => {
	const textColor = useColorModeValue("gray.700", "white");

	const dataContext = useContext(DataContext)
	const { coaches } = dataContext

	const [hasRender, setRender] = useState(false);
    const onShow = React.useCallback(() => setRender(true), []);

	return (
		<>
			{ hasRender && (
                <ScaleFade in={hasRender} >
					<Card maxH='700px' maxW='100%'>
						<CardHeader p='22px 0px 35px 14px'>
							<Flex direction='column'>
								<Text fontSize='lg' color={textColor} fontWeight='bold' pb='.5rem'>
									{title}
								</Text>
								<Text fontSize='sm' color='gray.400' fontWeight='normal'>
									<Text fontWeight='bold' as='span' color='royalblue.700'>
										{amount} {title}
									</Text>{" "}
									this month.
								</Text>
							</Flex>
						</CardHeader>
						<CardBody ps='20px' pe='0px' overflowY="scroll" mb='31px' position='relative'>
							<Flex direction='column'>
								{data.map((row, index, arr) => {
									return (
										<TitleCoachDateRow
											key={`${row.title}-${index}`}
											icon={icon}
											title={row.search_term_text}
											coach={coaches.find(coach => coach.id === row.coachID)}
											date={new Date(row.createdAt).toLocaleDateString()}
											text={row.feedback_text && row.feedback_text}
											index={index}
											arrLength={arr.length}
										/>
									);
								})}
							</Flex>
						</CardBody>
					</Card>
				</ScaleFade>
			)}
			{ !hasRender && (
                <ScaleFade in={!hasRender} >
                    <Card p='28px 10px 16px 0px'>
                        <CardHeader pl='22px' w="100%">
							<Flex direction='row' alignSelf='flex-start' w="100%" justifyContent={'space-between'} alignItems="center">
								<Flex direction='column' alignSelf='flex-start' w="50%">
									<Text fontSize='lg' color={"gray.700"} fontWeight='bold' mb='6px'>
									{title}
									</Text>
									<Text fontSize='md' fontWeight='medium' color='gray.400'>
									{subtitle}
									</Text>
								</Flex>
                                <Button w="40%" colorScheme='blue' onClick={onShow} my="10px" mr="5%">
                                    Load chart
                                </Button>
                            </Flex>
                        </CardHeader>
                    </Card>
                </ScaleFade>
            )}
		</>
	);
};

export default OrdersOverview;
