// React imports
import React, { useContext, useEffect } from 'react'
// Context imports
import { DataContext } from '../../../../contexts/dataContext'
// Chakra imports
import { Flex } from "@chakra-ui/react";
// Components
import AuxCoaches from './AuxCoaches'

function AuxCoachesTables({coaches}) {

	const myCaptionsCoach = [ "In App","Registered","UniqueID", "Added", "Removed", "First name", "Last name", "Position", "Contact email", "Contact phone", "Individual twitter", "Individual facebook", "Individual instagram"]
	const myCaptionsTeam = [ "Conference", "Division", "Sport", "Landing pages", "Team twitter", "Team facebook", "Team instagram", "Questionnaire", "Region"]
	const myCaptionsSchool = [ "State","School", "School 2", "State 2",  "School 3","City", "Size of city", "Private/public", "Religious", "Average GPA", "SAT reading", "SAT math", "ACT composite", "Acceptance rate", "Total yearly cost", "Majors offered", "No undergrads", "US ranking", "US arts ranking", "IPEDS"]

	useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
	
	return (
		<Flex direction='column' pt={{ base: "120px", md: "75px" }}>
			<AuxCoaches
				title={"Aditional information about coaches"}
				captions={[...myCaptionsCoach,...myCaptionsTeam,...myCaptionsSchool]}
				data={coaches}
			/>
		</Flex>
	);
}

export default AuxCoachesTables;
