import React, {useState} from 'react'

import { Text, useColorModeValue, Spacer, Flex, Divider } from "@chakra-ui/react";
import {
    Slider,
    SliderTrack,
    SliderFilledTrack,
    SliderThumb,
    SliderMark,
    Tooltip
  } from '@chakra-ui/react'

export const Education = ({athlete}) => {

    const bgProfile = useColorModeValue(
		"hsla(0,0%,100%,.8)",
		"linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
	);
	const textColor = useColorModeValue("gray.700", "white");
	const emailColor = useColorModeValue("gray.400", "gray.300","royalblue.700");

    const [showTooltip, setShowTooltip] = useState(false)


    const getSlider = (min, max, value) => {

        return (
            <Slider
                id='slider'
                value={value}
                min={min}
                max={max}
                colorScheme={athlete.sport === 'Soccer' ? 'blue' : 'green'}
                isReadOnly={true}
                mt="15px"
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
            >
                <SliderMark value={min} mt='2' ml='-2.5' fontSize='sm'>
                    {min}
                </SliderMark>
                <SliderMark value={max} mt='2' ml='-2.5' fontSize='sm'>
                    {max}
                </SliderMark>
                <SliderTrack>
                    <SliderFilledTrack />
                </SliderTrack>
                <SliderMark value={value} mt='2'  fontSize='sm'>
                    {value}
                </SliderMark>
                <Tooltip
                    hasArrow
                    bg={athlete.sport === 'Soccer' ? 'royalblue.700' : 'green'}
                    color='white'
                    placement='top'
                    isOpen={showTooltip}
                    label={`${value}`}
                >
                    <SliderThumb />
                </Tooltip>
            </Slider>

        )


    }

    return (
        <>
            {/* 1 row */}
            <Flex direction={{ sm: "column", lg: "row" }} w="100%">
                <Flex direction="column" w={{ sm: "100%", lg: "45%" }} mt="20px">
                    <Flex direction="column">
                        <Text fontSize={{ sm: "sm", lg: "md" }} color={emailColor} >
                            Start date
                        </Text>
                        {
                            athlete.start_date
                            ? (<Text fontSize={{ sm: "md", lg: "lg" }} color={textColor} mb={{ sm: "8px", md: "15px" }}>
                                    {athlete.start_date}
                            </Text>)
                            : (<Text fontSize="xs" color={textColor} mb={{ sm: "8px", md: "15px" }}>
                                No Start date information provided
                            </Text>)
                        }
                    </Flex>                    
                </Flex>
                <Spacer />
                <Flex direction="column" w={{ sm: "100%", lg: "45%" }} mt="20px">
                    <Flex direction="column">
                        <Text fontSize={{ sm: "sm", lg: "md" }} color={emailColor} >
                            High School Graduation Date
                        </Text>
                        {
                            athlete.high_school_graduation_date
                            ? (<Text fontSize={{ sm: "md", lg: "lg" }} color={textColor} mb={{ sm: "8px", md: "15px" }}>
                                {athlete.high_school_graduation_date}
                            </Text>)
                            : (<Text fontSize="xs" color={textColor} mb={{ sm: "8px", md: "15px" }}>
                                No  High School Graduation Date information provided
                            </Text>)
                        }
                    </Flex>
                </Flex>
            </Flex>

            {/* 2 row */}
            <Flex direction={{ sm: "column", lg: "row" }} w="100%">
                <Flex direction="column" w={{ sm: "100%", lg: "45%" }} mt="10px">
                    <Flex direction="column">
                        <Text fontSize={{ sm: "sm", lg: "md" }} color={emailColor} >
                            Intended Field of Studies
                        </Text>
                        {
                            athlete.intended_field_of_studies
                            ? (<Text fontSize={{ sm: "md", lg: "lg" }} color={textColor} mb={{ sm: "8px", md: "15px" }}>
                                    {athlete.intended_field_of_studies}
                            </Text>)
                            : (<Text fontSize="xs" color={textColor} mb={{ sm: "8px", md: "15px" }}>
                                No Intended Field of Studies information provided
                            </Text>)
                        }
                    </Flex>                    
                </Flex>
                <Spacer />
                <Flex direction="column" w={{ sm: "100%", lg: "45%" }} mt="10px">
                    <Flex direction="column">
                        <Text fontSize={{ sm: "sm", lg: "md" }} color={emailColor} >
                            Intended degree
                        </Text>
                        {
                            athlete.intended_degree
                            ? (<Text fontSize={{ sm: "md", lg: "lg" }} color={textColor} mb={{ sm: "8px", md: "15px" }}>
                                {athlete.intended_degree}
                            </Text>)
                            : (<Text fontSize="xs" color={textColor} mb={{ sm: "8px", md: "15px" }}>
                                No  Intended degree information provided
                            </Text>)
                        }
                    </Flex>
                </Flex>
            </Flex>

            <Divider />

            {/* 3 row */}
            <Flex direction={{ sm: "column", lg: "row" }} w="100%">
                <Flex direction="column" w={{ sm: "100%", lg: "45%" }} mt="10px">
                    <Flex direction="column">
                        <Text fontSize={{ sm: "sm", lg: "md" }} color={emailColor} >
                            SAT Score
                        </Text>
                        {
                            athlete.sat_score
                            ? (getSlider(800,1600,athlete.sat_score))
                            : (<Text fontSize="xs" color={textColor} mb={{ sm: "8px", md: "15px" }}>
                                No SAT score information provided
                            </Text>)
                        }
                    </Flex>                    
                </Flex>
                <Spacer />
                <Flex direction="column" w={{ sm: "100%", lg: "45%" }} mt="10px">
                    <Flex direction="column">
                        <Text fontSize={{ sm: "sm", lg: "md" }} color={emailColor} >
                            SAT Date
                        </Text>
                        {
                            athlete.sat_date
                            ? (<Text fontSize={{ sm: "md", lg: "lg" }} color={textColor} mb={{ sm: "8px", md: "15px" }}>
                                {athlete.sat_date}
                            </Text>)
                            : (<Text fontSize="xs" color={textColor} mb={{ sm: "8px", md: "15px" }}>
                                No SAT Date information provided
                            </Text>)
                        }
                    </Flex>
                </Flex>
            </Flex>

            {/* 4 row */}
            <Flex direction={{ sm: "column", lg: "row" }} w="100%">
                <Flex direction="column" w={{ sm: "100%", lg: "45%" }} mt="10px">
                    <Flex direction="column">
                        <Text fontSize={{ sm: "sm", lg: "md" }} color={emailColor} >
                            ACT Score
                        </Text>
                        {
                            athlete.act_score
                            ? (getSlider(1,36,athlete.act_score))
                            : (<Text fontSize="xs" color={textColor} mb={{ sm: "8px", md: "15px" }}>
                                No ACT score information provided
                            </Text>)
                        }
                    </Flex>                    
                </Flex>
                <Spacer />
                <Flex direction="column" w={{ sm: "100%", lg: "45%" }} mt="10px">
                    <Flex direction="column">
                        <Text fontSize={{ sm: "sm", lg: "md" }} color={emailColor} >
                            ACT Date
                        </Text>
                        {
                            athlete.act_date
                            ? (<Text fontSize={{ sm: "md", lg: "lg" }} color={textColor} mb={{ sm: "8px", md: "15px" }}>
                                {athlete.act_date}
                            </Text>)
                            : (<Text fontSize="xs" color={textColor} mb={{ sm: "8px", md: "15px" }}>
                                No ACT Date information provided
                            </Text>)
                        }
                    </Flex>
                </Flex>
            </Flex>

            {/* 4 row */}
            <Flex direction={{ sm: "column", lg: "row" }} w="100%">
                <Flex direction="column" w={{ sm: "100%", lg: "45%" }} mt="10px">
                    <Flex direction="column">
                        <Text fontSize={{ sm: "sm", lg: "md" }} color={emailColor} >
                            Gemat Gre Score
                        </Text>
                        {
                            athlete.gemat_gre_score
                            ? (getSlider(0,800,athlete.gemat_gre_score))
                            : (<Text fontSize="xs" color={textColor} mb={{ sm: "8px", md: "15px" }}>
                                No Gemat Gre score information provided
                            </Text>)
                        }
                    </Flex>                    
                </Flex>
                <Spacer />
                <Flex direction="column" w={{ sm: "100%", lg: "45%" }} mt="10px">
                    <Flex direction="column">
                        <Text fontSize={{ sm: "sm", lg: "md" }} color={emailColor} >
                            Gemat Gre Date
                        </Text>
                        {
                            athlete.gemat_gre_date
                            ? (<Text fontSize={{ sm: "md", lg: "lg" }} color={textColor} mb={{ sm: "8px", md: "15px" }}>
                                {athlete.gemat_gre_date}
                            </Text>)
                            : (<Text fontSize="xs" color={textColor} mb={{ sm: "8px", md: "15px" }}>
                                No Gemat Gre Date information provided
                            </Text>)
                        }
                    </Flex>
                </Flex>
            </Flex>
            
            {/* 5 row */}
            <Flex direction={{ sm: "column", lg: "row" }} w="100%">
                <Flex direction="column" w={{ sm: "100%", lg: "45%" }} mt="10px">
                    <Flex direction="column">
                        <Text fontSize={{ sm: "sm", lg: "md" }} color={emailColor} >
                            Toefl Score
                        </Text>
                        {
                            athlete.toefl_score
                            ? (getSlider(0,120,athlete.toefl_score))
                            : (<Text fontSize="xs" color={textColor} mb={{ sm: "8px", md: "15px" }}>
                                No Toefl score information provided
                            </Text>)
                        }
                    </Flex>                    
                </Flex>
                <Spacer />
                <Flex direction="column" w={{ sm: "100%", lg: "45%" }} mt="10px">
                    <Flex direction="column">
                        <Text fontSize={{ sm: "sm", lg: "md" }} color={emailColor} >
                            Toefl Date
                        </Text>
                        {
                            athlete.toefl_date
                            ? (<Text fontSize={{ sm: "md", lg: "lg" }} color={textColor} mb={{ sm: "8px", md: "15px" }}>
                                {athlete.toefl_date}
                            </Text>)
                            : (<Text fontSize="xs" color={textColor} mb={{ sm: "8px", md: "15px" }}>
                                No Toefl Date information provided
                            </Text>)
                        }
                    </Flex>
                </Flex>
            </Flex>

            {/* 6 row */}
            <Flex direction={{ sm: "column", lg: "row" }} w="100%">
                <Flex direction="column" w={{ sm: "100%", lg: "45%" }} mt="10px">
                    <Flex direction="column">
                        <Text fontSize={{ sm: "sm", lg: "md" }} color={emailColor} >
                            Duolingo Score
                        </Text>
                        {
                            athlete.duolingo
                            ? (getSlider(0,160,athlete.duolingo))
                            : (<Text fontSize="xs" color={textColor} mb={{ sm: "8px", md: "15px" }}>
                                No Duolingo score information provided
                            </Text>)
                        }
                    </Flex>                    
                </Flex>
                <Spacer />
                <Flex direction="column" w={{ sm: "100%", lg: "45%" }} mt="10px">
                    <Flex direction="column">
                        <Text fontSize={{ sm: "sm", lg: "md" }} color={emailColor} >
                            Duolingo Date
                        </Text>
                        {
                            athlete.duolingo_date
                            ? (<Text fontSize={{ sm: "md", lg: "lg" }} color={textColor} mb={{ sm: "8px", md: "15px" }}>
                                {athlete.duolingo_date}
                            </Text>)
                            : (<Text fontSize="xs" color={textColor} mb={{ sm: "8px", md: "15px" }}>
                                No Duolingo Date information provided
                            </Text>)
                        }
                    </Flex>
                </Flex>
            </Flex>

            {/* 7 row */}
            <Flex direction={{ sm: "column", lg: "row" }} w="100%">
                <Flex direction="column" w={{ sm: "100%", lg: "45%" }} mt="10px">
                    <Flex direction="column">
                        <Text fontSize={{ sm: "sm", lg: "md" }} color={emailColor} >
                            Ielts Score
                        </Text>
                        {
                            athlete.ielts
                            ? (getSlider(0,9,athlete.ielts))
                            : (<Text fontSize="xs" color={textColor} mb={{ sm: "8px", md: "15px" }}>
                                No Ielts score information provided
                            </Text>)
                        }
                    </Flex>                    
                </Flex>
                <Spacer />
                <Flex direction="column" w={{ sm: "100%", lg: "45%" }} mt="10px">
                    <Flex direction="column">
                        <Text fontSize={{ sm: "sm", lg: "md" }} color={emailColor} >
                            Ielts Date
                        </Text>
                        {
                            athlete.ielts_date
                            ? (<Text fontSize={{ sm: "md", lg: "lg" }} color={textColor} mb={{ sm: "8px", md: "15px" }}>
                                {athlete.ielts_date}
                            </Text>)
                            : (<Text fontSize="xs" color={textColor} mb={{ sm: "8px", md: "15px" }}>
                                No Ielts Date information provided
                            </Text>)
                        }
                    </Flex>
                </Flex>
            </Flex>

            <Divider mt="20px"/>

            {/* 8 row */}
            <Flex direction={{ sm: "column", lg: "row" }} w="100%">
                <Flex direction="column" w={{ sm: "100%", lg: "45%" }} mt="10px">
                    <Flex direction="column">
                        <Text fontSize={{ sm: "sm", lg: "md" }} color={emailColor} >
                            Estimated GPA
                        </Text>
                        {
                            athlete.estimated_gpa
                            ? (<Text fontSize={{ sm: "md", lg: "lg" }} color={textColor} mb={{ sm: "8px", md: "15px" }}>
                                    {athlete.estimated_gpa}
                            </Text>)
                            : (<Text fontSize="xs" color={textColor} mb={{ sm: "8px", md: "15px" }}>
                                No Estimated GPA information provided
                            </Text>)
                        }
                    </Flex>                    
                </Flex>
                <Spacer />
                <Flex direction="column" w={{ sm: "100%", lg: "45%" }} mt="10px">
                    <Flex direction="column">
                        <Text fontSize={{ sm: "sm", lg: "md" }} color={emailColor} >
                            Estimated budget
                        </Text>
                        {
                            athlete.budget
                            ? (<Text fontSize={{ sm: "md", lg: "lg" }} color={textColor} mb={{ sm: "8px", md: "15px" }}>
                                Up to US $<strong>{athlete.budget}</strong>
                            </Text>)
                            : (<Text fontSize="xs" color={textColor} mb={{ sm: "8px", md: "15px" }}>
                                No estimated budget information provided
                            </Text>)
                        }
                    </Flex>
                </Flex>
            </Flex>
        
        </>
    )
}
