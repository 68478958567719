import { API } from 'aws-amplify';
import { updateAgent, createAgent, deleteAgent } from '../custom_graphql_queries/agents'

const updateAgentService = async (agent) => {
    try {
        API.graphql({query: updateAgent, variables: { input: agent }})
        .catch(error => {console.log(error)})
    } catch (error) {
        throw new Error(error.message);
    }
}
const createAgentService = async (agent) => {
    try {
        API.graphql({query: createAgent, variables: { input: agent }})
        .catch(error => {console.log(error)})
    } catch (error) {
        throw new Error(error.message);
    }
}

const deleteAgentService = async (agentId) => {
    try {
        API.graphql({query: deleteAgent, variables: { input: {id: agentId }}})
        .catch(error => {console.log(error)})
    } catch (error) {
        throw new Error(error.message);
    }
}

export { updateAgentService, createAgentService, deleteAgentService };