import { API } from 'aws-amplify';
import { createSubscriptionPeriod } from 'graphql/mutations';
import { updateTeam, createTeam, deleteTeam } from '../custom_graphql_queries/teams'

const updateTeamService = async (team) => {
    try {
        API.graphql({query: updateTeam, variables: { input: team }})
        .catch(error => {console.log(error)})
    } catch (error) {
        throw new Error(error.message);
    }
}
const createTeamService = async (team) => {
    try {
        API.graphql({query: createTeam, variables: { input: team }})
        .catch(error => {console.log(error)})
    } catch (error) {
        throw new Error(error.message);
    }
}

const deleteTeamService = async (teamId) => {
    try {
        const response = await API.graphql({query: deleteTeam, variables: { input: {id: teamId }}})
        return response
    } catch (error) {
        throw new Error(error.message);
    }
}

const addSubscriptionTeamPeriod = async (period) => {
    try {
        const response = await API.graphql({query: createSubscriptionPeriod, variables: { input: period }})
        return response
    } catch (error) {
        console.log("error:",error)
        throw new Error(error.message);
    }
}

export { updateTeamService, createTeamService, deleteTeamService, addSubscriptionTeamPeriod };