// React imports
import React, { useContext, useState, useEffect} from 'react'
import { useParams, useNavigate } from 'react-router-dom';
// Context imports
import { DataContext } from "../../../../contexts/dataContext";
// React-select imports
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
// Chackra Components
import { Flex, useColorModeValue, Text, FormControl,FormErrorMessage, Input,Spacer, Box, Button, ButtonGroup } from "@chakra-ui/react";
import { Alert, AlertIcon, AlertTitle, AlertDescription, Tooltip, Icon } from '@chakra-ui/react'
import { BsQuestionCircleFill } from "react-icons/bs";

// Auxiliary imports
import { uploadImageToCloudinary } from "../../../../helpers/uploadImageToCloudinary";
import { updateAgentService } from "../../../../services/agentServices";
import validator from "validator";
// Component 
import { ImageDropzone } from "../../../../components/Dropzone/ImageDropzone";
import { agentRoles } from 'helpers';
import { changeAgencyIdToUserGroup } from 'helpers';
import { removeUserFromGroupCognito } from 'services/cognitoServices';
import { addUserToGroupCognito } from 'services/cognitoServices';


export const EditAgent = () => {

    // Chakra color mode
    const textColor = useColorModeValue("gray.700", "white");
	const animatedComponents = makeAnimated();

    // Get the id of the agency from the url
	const { agentId } = useParams();
	// Navigation hook
    let navigate = useNavigate()

    // Take the context data needed in this component
    const dataContext = useContext(DataContext)
    const { agents, setAgents, agencies } = dataContext
    const agent = agents.find(agent => agent.id === agentId)
    // Needed to show the agency name in the select
    const agencyOfAgent = agencies.find(agency => agency.id === agent.agencyID)


    // The initial state of the form with the data of the agency
	const initialState = {
		id: agent.id,
		name: agent.name,
		profile_pic: agent.profile_pic,
		birthdate: agent.birthdate ? agent.birthdate : '',
		contact_email: agent.contact_email ? agent.contact_email : '',
		contact_phone: agent.contact_phone ? agent.contact_phone : '',
		gender: agent.gender,
        agencyID: agent.agencyID,
        agent_role: agent.agent_role ? agent.agent_role : '',
	}

    
    // State that controls the form values
	const [formValues, setFormValues] = useState(initialState)
	// State to check if form has changed and enable submit button
	const [formHasChanged, setFormHasChanged] = useState(false)
	// State that controls if the submit is loading
	const [isLoading, setIsLoading] = useState(false)
	// State with form errors 
	const [errors, setErrors] = useState({
		email: '',
		phone: '',
		birthdate: '',
	})

    // When the form values change, check that the mandatory fields are filled and enable the submit button
	useEffect(() => {
		formValues.name !== '' && formValues.agencyID !== '' && formValues.agent_role !== '' && JSON.stringify(formValues) !== JSON.stringify(initialState) ? setFormHasChanged(true) : setFormHasChanged(false)
	}, [formValues])


    // Function that handles the change of the agency select
	const handleChangeAgency = (selectedOptions) => {
		setFormValues(()=>({...formValues, agencyID: selectedOptions.value}))
	}
    // Function that handles the change of the gender select
    const handleChangeGender = (selectedOptions) => {
		setFormValues(()=>({...formValues, gender: selectedOptions.value}))
	}

	const onChange = (e) => {
        e.persist()
        setFormValues(() => ({ ...formValues, [e.target.name]: e.target.value }))
    }

    const handleEdit = () => {
        setIsLoading(true)
		let thereAreErrors = {
			email: '',
			phone: '',
			birthdate: '',
		}

		// Validate data (contact email, contact phone, website)
		if('contact_email' in formValues && formValues.contact_email !== "" && !validator.isEmail(formValues.contact_email)){
			setIsLoading(false)
			thereAreErrors = {...thereAreErrors, email: 'Please enter a valid email'}
		}
		
		if('contact_phone' in formValues && formValues.contact_phone !== "" && !validator.isMobilePhone(formValues.contact_phone, 'any',{ strictMode: true })){
			setIsLoading(false)
			thereAreErrors = {...thereAreErrors, phone: 'Please enter a valid phone number'}
		}
		
		if('birthdate'in formValues && formValues.birthdate !== "" && !validator.isDate(formValues.birthdate, {format: 'YYYY-MM-DD',delimiters: ['-']})){
			setIsLoading(false)
			thereAreErrors = {...thereAreErrors, birthdate: 'Please enter a valid birthdate'}
		}

        setErrors(thereAreErrors)
		// If there aren't errors, check if there is an image to upload, if so, upload it to cloudinary and create agency
		if(thereAreErrors.email === "" && thereAreErrors.phone === "" && thereAreErrors.birthdate === ""){
            
            const agentWithoutEmptyValues = Object.keys(formValues).reduce((obj, key) => {
				formValues[key] !== "" ? obj[key] = formValues[key] : obj[key] = null
				return obj
			}, {})

            // Check if agencyID has changed, if so, remove the agent from the previous agency and add it to the new one
            if(formValues.agencyID !== initialState.agencyID){
                // Remove agent from previous agency
                const previousAgencyGroup = changeAgencyIdToUserGroup(initialState.agencyID)
                removeUserFromGroupCognito(formValues.contact_email, previousAgencyGroup)
                // Add agent to new agency
                const newAgencyGroup = changeAgencyIdToUserGroup(formValues.agencyID)
                addUserToGroupCognito(formValues.contact_email, newAgencyGroup)
            }

            if(formValues.profile_pic !== initialState.profile_pic){
                uploadImageToCloudinary({image:formValues.profile_pic, directory: 'agents_profile_pics'})
				.then((url) => {
					updateAgentService({ ...agentWithoutEmptyValues, profile_pic: url })
					setAgents(
                        agents.map(agent => {
                            if(agent.id === agentWithoutEmptyValues.id){
                                return {...agentWithoutEmptyValues, profile_pic: url}
                            }
                            return agent
                        })
                    )
                })
			}else{
				updateAgentService({ ...agentWithoutEmptyValues })
				setAgents(
                    agents.map(agent => {
                        if(agent.id === agentWithoutEmptyValues.id){
                            return { ...agentWithoutEmptyValues }
                        }
                        return agent
                    })
                )
			}


			setIsLoading(false)
			navigate(`/admin/agents/`)
		}

    }



    return (
        <>
            <Alert status='warning' mt={{ base: "120px", md: "90px" }} borderRadius="10px">
                <AlertIcon />
                <AlertTitle>Email is unique.</AlertTitle>
                <AlertDescription>Note that email address provided cannot be changed.</AlertDescription>
            </Alert>
            <Box mt="10px" borderRadius="md" boxShadow="md" bg="white" >
                {/* Header */}
                <Flex justifyContent='left' p='15px 0px 10px 20px' >
                    <Text fontSize='xl' color={textColor} fontWeight='bold' mr="200px">
                        Edit agent 
                    </Text>
                </Flex>

                {/* Form of their attributes */}
                <Flex direction='column' w="100%" alignContent="center" px="20px"  > 
                    
                    {/* First row */}
                    <Flex direction={{ sm: "column", lg: "row" }} w="100%">
                        <Flex w={{ sm: "100%", lg: "45%" }} mt="20px">
                            <FormControl isInvalid={formValues.name === ""}>
                                <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mr="15px" fontWeight='semibold' textAlign="justify">
                                    Name
                                </Text>
                                <Input isRequired={true} onChange={onChange} variant='flushed' placeholder='Name' size='sm' value={formValues.name} name="name"/>
                                {formValues.name === "" ? <FormErrorMessage>This field is mandatory</FormErrorMessage> : null}
                            </FormControl>
                        </Flex>
                        <Spacer />
                        <Flex alignItems='left' direction={'column'} w={{ sm: "100%", lg: "45%" }} mt="20px">
                            <FormControl isInvalid={errors.email !== ''}>
                                <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mr="15px" fontWeight='semibold' textAlign="justify">
                                    Email
                                </Text>
                                <Input onChange={onChange} variant='flushed' placeholder='Contact email' size='sm' value={formValues.contact_email} name="contact_email" isDisabled/>
                                {errors.email !== '' ? <FormErrorMessage>{errors.email}</FormErrorMessage> : null}
                            </FormControl>
                        </Flex>
                    </Flex>


                    {/* Second row */}
                    <Flex direction={{ sm: "column", lg: "row" }} w="100%" >
                        <Flex alignItems='left' direction={'column'} w={{ sm: "100%", lg: "45%" }} mt="20px" >
                            <FormControl isInvalid={errors.phone !== ''}>
                                <Flex direction="row" alignItems="center">
                                    <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mr="15px" fontWeight='semibold' textAlign="justify">
                                        Phone
                                    </Text>
                                    <Tooltip label={`Enter a phone number using the format: [+][country code][phone number]`} bg='royalblue.700'  placement='auto' >
                                        <span><Icon as={BsQuestionCircleFill} color="royalblue.700" /></span>
                                    </Tooltip>
                                </Flex>
                                <Input onChange={onChange} variant='flushed' placeholder='+16175551212' size='sm' value={formValues.contact_phone} name="contact_phone"/>
                                {errors.phone !== '' ? <FormErrorMessage>{errors.phone}</FormErrorMessage> : null}
                            </FormControl>
                        </Flex>
                        <Spacer />
                        <Flex alignItems='left' direction={'column'} w={{ sm: "100%", lg: "45%" }} mt="20px">
                            <FormControl isInvalid={errors.birthdate !== ''}>
                                <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mr="15px" fontWeight='semibold' textAlign="justify">
                                    Birthdate
                                </Text>
                                <Input onChange={onChange} variant='flushed' placeholder='YYYY-MM-DD' size='sm' value={formValues.birthdate} name="birthdate"/>
                                {errors.birthdate !== '' ? <FormErrorMessage>{errors.birthdate}</FormErrorMessage> : null}
                            </FormControl>
                        </Flex>
                    </Flex>


                    {/* Third row with country selector */}
                    <Flex direction={{ sm: "column", lg: "row" }} w="100%" >
                        <Flex alignItems='left' direction={'column'} w={{ sm: "100%", lg: "45%" }} mt="20px" >
                            
                            <FormControl isInvalid={formValues.agencyID === ""}>
                                <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mb="5px" fontWeight='semibold' textAlign="justify">
                                    Agency
                                </Text>
                                <Select
                                    closeMenuOnSelect={true}
                                    components={animatedComponents}
                                    placeholder="Select agency"
                                    isSearchable={true}
                                    defaultValue={{ value: formValues.agencyID, label: agencyOfAgent.name }}
                                    options={(agencies.map(agency => ({ value: agency.id, label: agency.name })))}
                                    onChange={(selectedOptions) => handleChangeAgency(selectedOptions)}
                                />
                                {formValues.agencyID === "" ? <FormErrorMessage>This field is mandatory</FormErrorMessage> : null}
                            </FormControl>
                           
                        </Flex>
                        <Spacer />
                        <Flex alignItems='left' direction={'column'} w={{ sm: "100%", lg: "45%" }} mt="20px">
                            <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mb="5px" fontWeight='semibold' textAlign="justify">
                                Gender
                            </Text>
                            <Select
                                closeMenuOnSelect={true}
                                components={animatedComponents}
                                placeholder="Select your gender"
                                isSearchable={true}
                                defaultValue={{value:formValues.gender,label:formValues.gender === 'male' ? 'Male':'Female'}}
                                options={[{value:"male",label:"Male"},{value:"female",label:"Female"}]}
                                onChange={(selectedOptions) => handleChangeGender(selectedOptions)}
                                
                            />
                        </Flex>
                        
                    </Flex>

                    {/* Fourth row with country selector */}
                    <Flex direction={{ sm: "column", lg: "row" }} w="100%" >
                        <Flex alignItems='left' direction={'column'} w={{ sm: "100%", lg: "45%" }} mt="20px" >
                            
                            <FormControl isInvalid={formValues.agent_role === ""}>
                                <Flex direction="row" alignItems="center">
                                    <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mb="5px" mr="15px" fontWeight='semibold' textAlign="justify">
                                        Agent role
                                    </Text>
                                    <Tooltip label={`Leader: Agent that are allow to create/modify/delete his agency agents, create/modify/delete his agency athletes and see more charts.\nAssociate: Agent that are only allow to create/modify/delete his athletes and see some charts.`} bg='royalblue.700'  placement='auto' >
                                        <span><Icon as={BsQuestionCircleFill} color="royalblue.700" /></span>
                                    </Tooltip>
                                </Flex>
                                <Select
                                    closeMenuOnSelect={true}
                                    components={animatedComponents}
                                    placeholder="Select role"
                                    defaultValue={formValues.agent_role && { value: formValues.agent_role?.toUpperCase(), label: formValues.agent_role }}
                                    options={(agentRoles.map(rol => rol))}
                                    onChange={(selectedOptions) => setFormValues(()=>({...formValues, agent_role: selectedOptions.value}))}
                                />
                                {formValues.agent_role === "" ? <FormErrorMessage>This field is mandatory</FormErrorMessage> : null}
                            </FormControl>
                           
                        </Flex>                        
                    </Flex>


                    {/* Fifth row with drag and drop for logo*/}
                    <Flex direction="column" w="100%" mt="30px">
                        <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mb="5px" fontWeight='semibold' textAlign="justify">
                            Profile picture
                        </Text>
                        
                        {/* Component with dropzone for images */}
                        <ImageDropzone values={formValues} updateValues={setFormValues} propChanged="profile_pic"/>
                    </Flex>
                </Flex> 


                {/* Confirm and cancel buttons */}
                <Flex justifyContent='right' p='10px 40px 10px 0px' >
                    <Flex direction="column" my={5}>
                        <ButtonGroup spacing="6" justifyContent='right' mb="10px">

                            <Button
                                isLoading={isLoading}
                                loadingText='Submitting'
                                colorScheme='green'
                                variant='outline'
                                onClick={handleEdit}
                                isDisabled={!formHasChanged}
                            >
                                Submit
                            </Button>
                            <Button
                                colorScheme='red'
                                variant='outline'
                                onClick={()=>(navigate(`/admin/agents/`))}
                                >
                                Cancel
                            </Button>

                        </ButtonGroup>
                        <Text fontSize={{ sm: "xs", lg: "sm" }} color={"gray.400"}>
                            <i>You must modify some fields to enable submit button*</i>
                        </Text>
                    </Flex>
                </Flex>
            </Box>
        </>
    )
}
