/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateNotificationRelationByCoachId = /* GraphQL */ `
  subscription OnCreateNotificationRelationByCoachId($coachID: ID!) {
    onCreateNotificationRelationByCoachId(coachID: $coachID) {
      id
      is_viewed
      status
      notificationID
      coachID
      notification {
        id
        title
        text
        status
        type
        pic
        attachment
        coaches_notified {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
          __typename
        }
        my_filters {
          nextToken
          __typename
        }
        my_favorite_folders {
          nextToken
          __typename
        }
        my_notifications {
          nextToken
          __typename
        }
        showcases_interested_in {
          nextToken
          __typename
        }
        my_athlete_tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          had_trial
          collegeID
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateNotificationRelationByCoachId = /* GraphQL */ `
  subscription OnUpdateNotificationRelationByCoachId($coachID: ID!) {
    onUpdateNotificationRelationByCoachId(coachID: $coachID) {
      id
      is_viewed
      status
      notificationID
      coachID
      notification {
        id
        title
        text
        status
        type
        pic
        attachment
        coaches_notified {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
          __typename
        }
        my_filters {
          nextToken
          __typename
        }
        my_favorite_folders {
          nextToken
          __typename
        }
        my_notifications {
          nextToken
          __typename
        }
        showcases_interested_in {
          nextToken
          __typename
        }
        my_athlete_tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          had_trial
          collegeID
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateAthleteFavoritesByCoachId = /* GraphQL */ `
  subscription OnCreateAthleteFavoritesByCoachId($coachID: ID!) {
    onCreateAthleteFavoritesByCoachId(coachID: $coachID) {
      id
      athleteID
      coachID
      rating
      athlete {
        id
        agencyID
        agentID
        name
        isVisible
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        nationality
        country_of_competition
        target_division
        compliance
        card_pic
        other_nice_pics
        agency_assesment
        elegibility_notes
        current_club
        current_status
        start_availability_date
        end_availability_date
        destination_college
        ncaa_id
        type_of_admission
        weight
        height
        sport
        tennis_profile {
          utr
          national_ranking
          international_ranking
          national_ranking_adult
          international_ranking_adult
          DominantHand
          __typename
        }
        position
        second_position
        dominant_foot
        featured_video {
          url
          metadata
          __typename
        }
        other_videos {
          url
          metadata
          __typename
        }
        level_of_competition
        sports_background
        showcase_participation {
          showcase_name
          city
          country
          date
          __typename
        }
        start_date
        high_school_graduation_date
        sat_score
        sat_date
        act_score
        act_date
        gemat_gre_score
        gemat_gre_date
        toefl_score
        toefl_date
        duolingo
        duolingo_date
        ielts
        ielts_date
        estimated_gpa
        intended_field_of_studies
        intended_degree
        budget
        category
        facebook_url
        twitter_user
        linkedin_url
        reason_commitment
        date_commitment
        agency {
          id
          name
          logo
          country
          contact_email
          contact_phone
          website
          sport
          createdAt
          updatedAt
          __typename
        }
        agent {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          agent_role
          agencyID
          createdAt
          updatedAt
          __typename
        }
        favorited {
          nextToken
          __typename
        }
        enquiries {
          nextToken
          __typename
        }
        tags {
          nextToken
          __typename
        }
        showcases_participation {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
          __typename
        }
        my_filters {
          nextToken
          __typename
        }
        my_favorite_folders {
          nextToken
          __typename
        }
        my_notifications {
          nextToken
          __typename
        }
        showcases_interested_in {
          nextToken
          __typename
        }
        my_athlete_tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          had_trial
          collegeID
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateCoachAthleteFavoritesByCoachId = /* GraphQL */ `
  subscription OnUpdateCoachAthleteFavoritesByCoachId($coachID: ID!) {
    onUpdateCoachAthleteFavoritesByCoachId(coachID: $coachID) {
      id
      athleteID
      coachID
      rating
      athlete {
        id
        agencyID
        agentID
        name
        isVisible
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        nationality
        country_of_competition
        target_division
        compliance
        card_pic
        other_nice_pics
        agency_assesment
        elegibility_notes
        current_club
        current_status
        start_availability_date
        end_availability_date
        destination_college
        ncaa_id
        type_of_admission
        weight
        height
        sport
        tennis_profile {
          utr
          national_ranking
          international_ranking
          national_ranking_adult
          international_ranking_adult
          DominantHand
          __typename
        }
        position
        second_position
        dominant_foot
        featured_video {
          url
          metadata
          __typename
        }
        other_videos {
          url
          metadata
          __typename
        }
        level_of_competition
        sports_background
        showcase_participation {
          showcase_name
          city
          country
          date
          __typename
        }
        start_date
        high_school_graduation_date
        sat_score
        sat_date
        act_score
        act_date
        gemat_gre_score
        gemat_gre_date
        toefl_score
        toefl_date
        duolingo
        duolingo_date
        ielts
        ielts_date
        estimated_gpa
        intended_field_of_studies
        intended_degree
        budget
        category
        facebook_url
        twitter_user
        linkedin_url
        reason_commitment
        date_commitment
        agency {
          id
          name
          logo
          country
          contact_email
          contact_phone
          website
          sport
          createdAt
          updatedAt
          __typename
        }
        agent {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          agent_role
          agencyID
          createdAt
          updatedAt
          __typename
        }
        favorited {
          nextToken
          __typename
        }
        enquiries {
          nextToken
          __typename
        }
        tags {
          nextToken
          __typename
        }
        showcases_participation {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
          __typename
        }
        my_filters {
          nextToken
          __typename
        }
        my_favorite_folders {
          nextToken
          __typename
        }
        my_notifications {
          nextToken
          __typename
        }
        showcases_interested_in {
          nextToken
          __typename
        }
        my_athlete_tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          had_trial
          collegeID
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteCoachAthleteFavoritesByCoachId = /* GraphQL */ `
  subscription OnDeleteCoachAthleteFavoritesByCoachId($coachID: ID!) {
    onDeleteCoachAthleteFavoritesByCoachId(coachID: $coachID) {
      id
      athleteID
      coachID
      rating
      athlete {
        id
        agencyID
        agentID
        name
        isVisible
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        nationality
        country_of_competition
        target_division
        compliance
        card_pic
        other_nice_pics
        agency_assesment
        elegibility_notes
        current_club
        current_status
        start_availability_date
        end_availability_date
        destination_college
        ncaa_id
        type_of_admission
        weight
        height
        sport
        tennis_profile {
          utr
          national_ranking
          international_ranking
          national_ranking_adult
          international_ranking_adult
          DominantHand
          __typename
        }
        position
        second_position
        dominant_foot
        featured_video {
          url
          metadata
          __typename
        }
        other_videos {
          url
          metadata
          __typename
        }
        level_of_competition
        sports_background
        showcase_participation {
          showcase_name
          city
          country
          date
          __typename
        }
        start_date
        high_school_graduation_date
        sat_score
        sat_date
        act_score
        act_date
        gemat_gre_score
        gemat_gre_date
        toefl_score
        toefl_date
        duolingo
        duolingo_date
        ielts
        ielts_date
        estimated_gpa
        intended_field_of_studies
        intended_degree
        budget
        category
        facebook_url
        twitter_user
        linkedin_url
        reason_commitment
        date_commitment
        agency {
          id
          name
          logo
          country
          contact_email
          contact_phone
          website
          sport
          createdAt
          updatedAt
          __typename
        }
        agent {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          agent_role
          agencyID
          createdAt
          updatedAt
          __typename
        }
        favorited {
          nextToken
          __typename
        }
        enquiries {
          nextToken
          __typename
        }
        tags {
          nextToken
          __typename
        }
        showcases_participation {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
          __typename
        }
        my_filters {
          nextToken
          __typename
        }
        my_favorite_folders {
          nextToken
          __typename
        }
        my_notifications {
          nextToken
          __typename
        }
        showcases_interested_in {
          nextToken
          __typename
        }
        my_athlete_tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          had_trial
          collegeID
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateFavoritesFolderByCoachId = /* GraphQL */ `
  subscription OnCreateFavoritesFolderByCoachId($coachID: ID!) {
    onCreateFavoritesFolderByCoachId(coachID: $coachID) {
      id
      folder_name
      status
      coachID
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
          __typename
        }
        my_filters {
          nextToken
          __typename
        }
        my_favorite_folders {
          nextToken
          __typename
        }
        my_notifications {
          nextToken
          __typename
        }
        showcases_interested_in {
          nextToken
          __typename
        }
        my_athlete_tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          had_trial
          collegeID
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      athletes_in_folder {
        items {
          id
          athleteID
          favoritesFolderID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateFavoritesFolderByCoachId = /* GraphQL */ `
  subscription OnUpdateFavoritesFolderByCoachId($coachID: ID!) {
    onUpdateFavoritesFolderByCoachId(coachID: $coachID) {
      id
      folder_name
      status
      coachID
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
          __typename
        }
        my_filters {
          nextToken
          __typename
        }
        my_favorite_folders {
          nextToken
          __typename
        }
        my_notifications {
          nextToken
          __typename
        }
        showcases_interested_in {
          nextToken
          __typename
        }
        my_athlete_tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          had_trial
          collegeID
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      athletes_in_folder {
        items {
          id
          athleteID
          favoritesFolderID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteFavoritesFolderByCoachId = /* GraphQL */ `
  subscription OnDeleteFavoritesFolderByCoachId($coachID: ID!) {
    onDeleteFavoritesFolderByCoachId(coachID: $coachID) {
      id
      folder_name
      status
      coachID
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
          __typename
        }
        my_filters {
          nextToken
          __typename
        }
        my_favorite_folders {
          nextToken
          __typename
        }
        my_notifications {
          nextToken
          __typename
        }
        showcases_interested_in {
          nextToken
          __typename
        }
        my_athlete_tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          had_trial
          collegeID
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      athletes_in_folder {
        items {
          id
          athleteID
          favoritesFolderID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateFavoritesFolderAthleteRelationByFolderId = /* GraphQL */ `
  subscription OnCreateFavoritesFolderAthleteRelationByFolderId(
    $favoritesFolderID: ID!
  ) {
    onCreateFavoritesFolderAthleteRelationByFolderId(
      favoritesFolderID: $favoritesFolderID
    ) {
      id
      athleteID
      favoritesFolderID
      athlete {
        id
        agencyID
        agentID
        name
        isVisible
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        nationality
        country_of_competition
        target_division
        compliance
        card_pic
        other_nice_pics
        agency_assesment
        elegibility_notes
        current_club
        current_status
        start_availability_date
        end_availability_date
        destination_college
        ncaa_id
        type_of_admission
        weight
        height
        sport
        tennis_profile {
          utr
          national_ranking
          international_ranking
          national_ranking_adult
          international_ranking_adult
          DominantHand
          __typename
        }
        position
        second_position
        dominant_foot
        featured_video {
          url
          metadata
          __typename
        }
        other_videos {
          url
          metadata
          __typename
        }
        level_of_competition
        sports_background
        showcase_participation {
          showcase_name
          city
          country
          date
          __typename
        }
        start_date
        high_school_graduation_date
        sat_score
        sat_date
        act_score
        act_date
        gemat_gre_score
        gemat_gre_date
        toefl_score
        toefl_date
        duolingo
        duolingo_date
        ielts
        ielts_date
        estimated_gpa
        intended_field_of_studies
        intended_degree
        budget
        category
        facebook_url
        twitter_user
        linkedin_url
        reason_commitment
        date_commitment
        agency {
          id
          name
          logo
          country
          contact_email
          contact_phone
          website
          sport
          createdAt
          updatedAt
          __typename
        }
        agent {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          agent_role
          agencyID
          createdAt
          updatedAt
          __typename
        }
        favorited {
          nextToken
          __typename
        }
        enquiries {
          nextToken
          __typename
        }
        tags {
          nextToken
          __typename
        }
        showcases_participation {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      favorites_folder {
        id
        folder_name
        status
        coachID
        coach {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          disabledByUser
          is_active
          allow_whatsapp_contact
          deleteAppReason
          division
          role
          sport
          teamID
          createdAt
          updatedAt
          __typename
        }
        athletes_in_folder {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateFavoritesFolderAthleteRelationByFolderId = /* GraphQL */ `
  subscription OnUpdateFavoritesFolderAthleteRelationByFolderId(
    $favoritesFolderID: ID!
  ) {
    onUpdateFavoritesFolderAthleteRelationByFolderId(
      favoritesFolderID: $favoritesFolderID
    ) {
      id
      athleteID
      favoritesFolderID
      athlete {
        id
        agencyID
        agentID
        name
        isVisible
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        nationality
        country_of_competition
        target_division
        compliance
        card_pic
        other_nice_pics
        agency_assesment
        elegibility_notes
        current_club
        current_status
        start_availability_date
        end_availability_date
        destination_college
        ncaa_id
        type_of_admission
        weight
        height
        sport
        tennis_profile {
          utr
          national_ranking
          international_ranking
          national_ranking_adult
          international_ranking_adult
          DominantHand
          __typename
        }
        position
        second_position
        dominant_foot
        featured_video {
          url
          metadata
          __typename
        }
        other_videos {
          url
          metadata
          __typename
        }
        level_of_competition
        sports_background
        showcase_participation {
          showcase_name
          city
          country
          date
          __typename
        }
        start_date
        high_school_graduation_date
        sat_score
        sat_date
        act_score
        act_date
        gemat_gre_score
        gemat_gre_date
        toefl_score
        toefl_date
        duolingo
        duolingo_date
        ielts
        ielts_date
        estimated_gpa
        intended_field_of_studies
        intended_degree
        budget
        category
        facebook_url
        twitter_user
        linkedin_url
        reason_commitment
        date_commitment
        agency {
          id
          name
          logo
          country
          contact_email
          contact_phone
          website
          sport
          createdAt
          updatedAt
          __typename
        }
        agent {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          agent_role
          agencyID
          createdAt
          updatedAt
          __typename
        }
        favorited {
          nextToken
          __typename
        }
        enquiries {
          nextToken
          __typename
        }
        tags {
          nextToken
          __typename
        }
        showcases_participation {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      favorites_folder {
        id
        folder_name
        status
        coachID
        coach {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          disabledByUser
          is_active
          allow_whatsapp_contact
          deleteAppReason
          division
          role
          sport
          teamID
          createdAt
          updatedAt
          __typename
        }
        athletes_in_folder {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteFavoritesFolderAthleteRelationByFolderId = /* GraphQL */ `
  subscription OnDeleteFavoritesFolderAthleteRelationByFolderId(
    $favoritesFolderID: ID!
  ) {
    onDeleteFavoritesFolderAthleteRelationByFolderId(
      favoritesFolderID: $favoritesFolderID
    ) {
      id
      athleteID
      favoritesFolderID
      athlete {
        id
        agencyID
        agentID
        name
        isVisible
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        nationality
        country_of_competition
        target_division
        compliance
        card_pic
        other_nice_pics
        agency_assesment
        elegibility_notes
        current_club
        current_status
        start_availability_date
        end_availability_date
        destination_college
        ncaa_id
        type_of_admission
        weight
        height
        sport
        tennis_profile {
          utr
          national_ranking
          international_ranking
          national_ranking_adult
          international_ranking_adult
          DominantHand
          __typename
        }
        position
        second_position
        dominant_foot
        featured_video {
          url
          metadata
          __typename
        }
        other_videos {
          url
          metadata
          __typename
        }
        level_of_competition
        sports_background
        showcase_participation {
          showcase_name
          city
          country
          date
          __typename
        }
        start_date
        high_school_graduation_date
        sat_score
        sat_date
        act_score
        act_date
        gemat_gre_score
        gemat_gre_date
        toefl_score
        toefl_date
        duolingo
        duolingo_date
        ielts
        ielts_date
        estimated_gpa
        intended_field_of_studies
        intended_degree
        budget
        category
        facebook_url
        twitter_user
        linkedin_url
        reason_commitment
        date_commitment
        agency {
          id
          name
          logo
          country
          contact_email
          contact_phone
          website
          sport
          createdAt
          updatedAt
          __typename
        }
        agent {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          agent_role
          agencyID
          createdAt
          updatedAt
          __typename
        }
        favorited {
          nextToken
          __typename
        }
        enquiries {
          nextToken
          __typename
        }
        tags {
          nextToken
          __typename
        }
        showcases_participation {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      favorites_folder {
        id
        folder_name
        status
        coachID
        coach {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          disabledByUser
          is_active
          allow_whatsapp_contact
          deleteAppReason
          division
          role
          sport
          teamID
          createdAt
          updatedAt
          __typename
        }
        athletes_in_folder {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateTeamById = /* GraphQL */ `
  subscription OnUpdateTeamById($id: ID!) {
    onUpdateTeamById(id: $id) {
      id
      name
      sport
      genre
      suscription_name
      suscription_recurrency
      suscription_status
      suscription_stripe_id
      suscription_premium_start_date
      suscription_premium_end_date
      team_logo
      conference
      division
      college_category
      url_domain
      had_trial
      collegeID
      college {
        id
        name
        country
        state
        address
        logo
        createdAt
        updatedAt
        teams {
          nextToken
          __typename
        }
        __typename
      }
      coaches {
        items {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          disabledByUser
          is_active
          allow_whatsapp_contact
          deleteAppReason
          division
          role
          sport
          teamID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      subscription_history {
        items {
          id
          start_date
          end_date
          suscription_name
          teamID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateCollege = /* GraphQL */ `
  subscription OnCreateCollege {
    onCreateCollege {
      id
      name
      country
      state
      address
      logo
      createdAt
      updatedAt
      teams {
        items {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          had_trial
          collegeID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateCollege = /* GraphQL */ `
  subscription OnUpdateCollege {
    onUpdateCollege {
      id
      name
      country
      state
      address
      logo
      createdAt
      updatedAt
      teams {
        items {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          had_trial
          collegeID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteCollege = /* GraphQL */ `
  subscription OnDeleteCollege {
    onDeleteCollege {
      id
      name
      country
      state
      address
      logo
      createdAt
      updatedAt
      teams {
        items {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          had_trial
          collegeID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreateSubscriptionPeriod = /* GraphQL */ `
  subscription OnCreateSubscriptionPeriod {
    onCreateSubscriptionPeriod {
      id
      start_date
      end_date
      suscription_name
      teamID
      createdAt
      updatedAt
      team {
        id
        name
        sport
        genre
        suscription_name
        suscription_recurrency
        suscription_status
        suscription_stripe_id
        suscription_premium_start_date
        suscription_premium_end_date
        team_logo
        conference
        division
        college_category
        url_domain
        had_trial
        collegeID
        college {
          id
          name
          country
          state
          address
          logo
          createdAt
          updatedAt
          __typename
        }
        coaches {
          nextToken
          __typename
        }
        subscription_history {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateSubscriptionPeriod = /* GraphQL */ `
  subscription OnUpdateSubscriptionPeriod {
    onUpdateSubscriptionPeriod {
      id
      start_date
      end_date
      suscription_name
      teamID
      createdAt
      updatedAt
      team {
        id
        name
        sport
        genre
        suscription_name
        suscription_recurrency
        suscription_status
        suscription_stripe_id
        suscription_premium_start_date
        suscription_premium_end_date
        team_logo
        conference
        division
        college_category
        url_domain
        had_trial
        collegeID
        college {
          id
          name
          country
          state
          address
          logo
          createdAt
          updatedAt
          __typename
        }
        coaches {
          nextToken
          __typename
        }
        subscription_history {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteSubscriptionPeriod = /* GraphQL */ `
  subscription OnDeleteSubscriptionPeriod {
    onDeleteSubscriptionPeriod {
      id
      start_date
      end_date
      suscription_name
      teamID
      createdAt
      updatedAt
      team {
        id
        name
        sport
        genre
        suscription_name
        suscription_recurrency
        suscription_status
        suscription_stripe_id
        suscription_premium_start_date
        suscription_premium_end_date
        team_logo
        conference
        division
        college_category
        url_domain
        had_trial
        collegeID
        college {
          id
          name
          country
          state
          address
          logo
          createdAt
          updatedAt
          __typename
        }
        coaches {
          nextToken
          __typename
        }
        subscription_history {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onCreateCoach = /* GraphQL */ `
  subscription OnCreateCoach {
    onCreateCoach {
      id
      name
      profile_pic
      birthdate
      gender
      contact_email
      contact_phone
      disabledByUser
      is_active
      allow_whatsapp_contact
      deleteAppReason
      division
      role
      sport
      teamID
      favorites {
        items {
          id
          athleteID
          coachID
          rating
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      my_filters {
        items {
          id
          name
          filter_body
          type
          sport
          source_app
          coachID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      my_favorite_folders {
        items {
          id
          folder_name
          status
          coachID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      my_notifications {
        items {
          id
          is_viewed
          status
          notificationID
          coachID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      showcases_interested_in {
        items {
          id
          showcaseID
          coachID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      my_athlete_tags {
        items {
          id
          coachID
          tagID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      team {
        id
        name
        sport
        genre
        suscription_name
        suscription_recurrency
        suscription_status
        suscription_stripe_id
        suscription_premium_start_date
        suscription_premium_end_date
        team_logo
        conference
        division
        college_category
        url_domain
        had_trial
        collegeID
        college {
          id
          name
          country
          state
          address
          logo
          createdAt
          updatedAt
          __typename
        }
        coaches {
          nextToken
          __typename
        }
        subscription_history {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateCoach = /* GraphQL */ `
  subscription OnUpdateCoach {
    onUpdateCoach {
      id
      name
      profile_pic
      birthdate
      gender
      contact_email
      contact_phone
      disabledByUser
      is_active
      allow_whatsapp_contact
      deleteAppReason
      division
      role
      sport
      teamID
      favorites {
        items {
          id
          athleteID
          coachID
          rating
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      my_filters {
        items {
          id
          name
          filter_body
          type
          sport
          source_app
          coachID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      my_favorite_folders {
        items {
          id
          folder_name
          status
          coachID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      my_notifications {
        items {
          id
          is_viewed
          status
          notificationID
          coachID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      showcases_interested_in {
        items {
          id
          showcaseID
          coachID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      my_athlete_tags {
        items {
          id
          coachID
          tagID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      team {
        id
        name
        sport
        genre
        suscription_name
        suscription_recurrency
        suscription_status
        suscription_stripe_id
        suscription_premium_start_date
        suscription_premium_end_date
        team_logo
        conference
        division
        college_category
        url_domain
        had_trial
        collegeID
        college {
          id
          name
          country
          state
          address
          logo
          createdAt
          updatedAt
          __typename
        }
        coaches {
          nextToken
          __typename
        }
        subscription_history {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteCoach = /* GraphQL */ `
  subscription OnDeleteCoach {
    onDeleteCoach {
      id
      name
      profile_pic
      birthdate
      gender
      contact_email
      contact_phone
      disabledByUser
      is_active
      allow_whatsapp_contact
      deleteAppReason
      division
      role
      sport
      teamID
      favorites {
        items {
          id
          athleteID
          coachID
          rating
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      my_filters {
        items {
          id
          name
          filter_body
          type
          sport
          source_app
          coachID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      my_favorite_folders {
        items {
          id
          folder_name
          status
          coachID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      my_notifications {
        items {
          id
          is_viewed
          status
          notificationID
          coachID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      showcases_interested_in {
        items {
          id
          showcaseID
          coachID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      my_athlete_tags {
        items {
          id
          coachID
          tagID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      team {
        id
        name
        sport
        genre
        suscription_name
        suscription_recurrency
        suscription_status
        suscription_stripe_id
        suscription_premium_start_date
        suscription_premium_end_date
        team_logo
        conference
        division
        college_category
        url_domain
        had_trial
        collegeID
        college {
          id
          name
          country
          state
          address
          logo
          createdAt
          updatedAt
          __typename
        }
        coaches {
          nextToken
          __typename
        }
        subscription_history {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const onCreateCoachMessage = /* GraphQL */ `
  subscription OnCreateCoachMessage {
    onCreateCoachMessage {
      id
      message
      agencyID
      agency {
        id
        name
        logo
        country
        contact_email
        contact_phone
        website
        sport
        athletes {
          nextToken
          __typename
        }
        agents {
          nextToken
          __typename
        }
        coach_messages {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      coachID
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
          __typename
        }
        my_filters {
          nextToken
          __typename
        }
        my_favorite_folders {
          nextToken
          __typename
        }
        my_notifications {
          nextToken
          __typename
        }
        showcases_interested_in {
          nextToken
          __typename
        }
        my_athlete_tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          had_trial
          collegeID
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateCoachMessage = /* GraphQL */ `
  subscription OnUpdateCoachMessage {
    onUpdateCoachMessage {
      id
      message
      agencyID
      agency {
        id
        name
        logo
        country
        contact_email
        contact_phone
        website
        sport
        athletes {
          nextToken
          __typename
        }
        agents {
          nextToken
          __typename
        }
        coach_messages {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      coachID
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
          __typename
        }
        my_filters {
          nextToken
          __typename
        }
        my_favorite_folders {
          nextToken
          __typename
        }
        my_notifications {
          nextToken
          __typename
        }
        showcases_interested_in {
          nextToken
          __typename
        }
        my_athlete_tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          had_trial
          collegeID
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteCoachMessage = /* GraphQL */ `
  subscription OnDeleteCoachMessage {
    onDeleteCoachMessage {
      id
      message
      agencyID
      agency {
        id
        name
        logo
        country
        contact_email
        contact_phone
        website
        sport
        athletes {
          nextToken
          __typename
        }
        agents {
          nextToken
          __typename
        }
        coach_messages {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      coachID
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
          __typename
        }
        my_filters {
          nextToken
          __typename
        }
        my_favorite_folders {
          nextToken
          __typename
        }
        my_notifications {
          nextToken
          __typename
        }
        showcases_interested_in {
          nextToken
          __typename
        }
        my_athlete_tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          had_trial
          collegeID
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateAgency = /* GraphQL */ `
  subscription OnCreateAgency {
    onCreateAgency {
      id
      name
      logo
      country
      contact_email
      contact_phone
      website
      sport
      athletes {
        items {
          id
          agencyID
          agentID
          name
          isVisible
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          nationality
          country_of_competition
          target_division
          compliance
          card_pic
          other_nice_pics
          agency_assesment
          elegibility_notes
          current_club
          current_status
          start_availability_date
          end_availability_date
          destination_college
          ncaa_id
          type_of_admission
          weight
          height
          sport
          position
          second_position
          dominant_foot
          level_of_competition
          sports_background
          start_date
          high_school_graduation_date
          sat_score
          sat_date
          act_score
          act_date
          gemat_gre_score
          gemat_gre_date
          toefl_score
          toefl_date
          duolingo
          duolingo_date
          ielts
          ielts_date
          estimated_gpa
          intended_field_of_studies
          intended_degree
          budget
          category
          facebook_url
          twitter_user
          linkedin_url
          reason_commitment
          date_commitment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      agents {
        items {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          agent_role
          agencyID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coach_messages {
        items {
          id
          message
          agencyID
          coachID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateAgency = /* GraphQL */ `
  subscription OnUpdateAgency {
    onUpdateAgency {
      id
      name
      logo
      country
      contact_email
      contact_phone
      website
      sport
      athletes {
        items {
          id
          agencyID
          agentID
          name
          isVisible
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          nationality
          country_of_competition
          target_division
          compliance
          card_pic
          other_nice_pics
          agency_assesment
          elegibility_notes
          current_club
          current_status
          start_availability_date
          end_availability_date
          destination_college
          ncaa_id
          type_of_admission
          weight
          height
          sport
          position
          second_position
          dominant_foot
          level_of_competition
          sports_background
          start_date
          high_school_graduation_date
          sat_score
          sat_date
          act_score
          act_date
          gemat_gre_score
          gemat_gre_date
          toefl_score
          toefl_date
          duolingo
          duolingo_date
          ielts
          ielts_date
          estimated_gpa
          intended_field_of_studies
          intended_degree
          budget
          category
          facebook_url
          twitter_user
          linkedin_url
          reason_commitment
          date_commitment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      agents {
        items {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          agent_role
          agencyID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coach_messages {
        items {
          id
          message
          agencyID
          coachID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteAgency = /* GraphQL */ `
  subscription OnDeleteAgency {
    onDeleteAgency {
      id
      name
      logo
      country
      contact_email
      contact_phone
      website
      sport
      athletes {
        items {
          id
          agencyID
          agentID
          name
          isVisible
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          nationality
          country_of_competition
          target_division
          compliance
          card_pic
          other_nice_pics
          agency_assesment
          elegibility_notes
          current_club
          current_status
          start_availability_date
          end_availability_date
          destination_college
          ncaa_id
          type_of_admission
          weight
          height
          sport
          position
          second_position
          dominant_foot
          level_of_competition
          sports_background
          start_date
          high_school_graduation_date
          sat_score
          sat_date
          act_score
          act_date
          gemat_gre_score
          gemat_gre_date
          toefl_score
          toefl_date
          duolingo
          duolingo_date
          ielts
          ielts_date
          estimated_gpa
          intended_field_of_studies
          intended_degree
          budget
          category
          facebook_url
          twitter_user
          linkedin_url
          reason_commitment
          date_commitment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      agents {
        items {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          agent_role
          agencyID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coach_messages {
        items {
          id
          message
          agencyID
          coachID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateAthlete = /* GraphQL */ `
  subscription OnCreateAthlete {
    onCreateAthlete {
      id
      agencyID
      agentID
      name
      isVisible
      profile_pic
      birthdate
      gender
      contact_email
      contact_phone
      nationality
      country_of_competition
      target_division
      compliance
      card_pic
      other_nice_pics
      agency_assesment
      elegibility_notes
      current_club
      current_status
      start_availability_date
      end_availability_date
      destination_college
      ncaa_id
      type_of_admission
      weight
      height
      sport
      tennis_profile {
        utr
        national_ranking
        international_ranking
        national_ranking_adult
        international_ranking_adult
        DominantHand
        __typename
      }
      position
      second_position
      dominant_foot
      featured_video {
        url
        metadata
        __typename
      }
      other_videos {
        url
        metadata
        __typename
      }
      level_of_competition
      sports_background
      showcase_participation {
        showcase_name
        city
        country
        date
        __typename
      }
      start_date
      high_school_graduation_date
      sat_score
      sat_date
      act_score
      act_date
      gemat_gre_score
      gemat_gre_date
      toefl_score
      toefl_date
      duolingo
      duolingo_date
      ielts
      ielts_date
      estimated_gpa
      intended_field_of_studies
      intended_degree
      budget
      category
      facebook_url
      twitter_user
      linkedin_url
      reason_commitment
      date_commitment
      agency {
        id
        name
        logo
        country
        contact_email
        contact_phone
        website
        sport
        athletes {
          nextToken
          __typename
        }
        agents {
          nextToken
          __typename
        }
        coach_messages {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      agent {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        agent_role
        agencyID
        agency {
          nextToken
          __typename
        }
        ahtletes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      favorited {
        items {
          id
          athleteID
          coachID
          rating
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      enquiries {
        items {
          id
          enquiry_text
          athleteID
          coachID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      tags {
        items {
          id
          athleteID
          tagID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      showcases_participation {
        items {
          id
          showcaseID
          athleteID
          color
          number
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateAthlete = /* GraphQL */ `
  subscription OnUpdateAthlete {
    onUpdateAthlete {
      id
      agencyID
      agentID
      name
      isVisible
      profile_pic
      birthdate
      gender
      contact_email
      contact_phone
      nationality
      country_of_competition
      target_division
      compliance
      card_pic
      other_nice_pics
      agency_assesment
      elegibility_notes
      current_club
      current_status
      start_availability_date
      end_availability_date
      destination_college
      ncaa_id
      type_of_admission
      weight
      height
      sport
      tennis_profile {
        utr
        national_ranking
        international_ranking
        national_ranking_adult
        international_ranking_adult
        DominantHand
        __typename
      }
      position
      second_position
      dominant_foot
      featured_video {
        url
        metadata
        __typename
      }
      other_videos {
        url
        metadata
        __typename
      }
      level_of_competition
      sports_background
      showcase_participation {
        showcase_name
        city
        country
        date
        __typename
      }
      start_date
      high_school_graduation_date
      sat_score
      sat_date
      act_score
      act_date
      gemat_gre_score
      gemat_gre_date
      toefl_score
      toefl_date
      duolingo
      duolingo_date
      ielts
      ielts_date
      estimated_gpa
      intended_field_of_studies
      intended_degree
      budget
      category
      facebook_url
      twitter_user
      linkedin_url
      reason_commitment
      date_commitment
      agency {
        id
        name
        logo
        country
        contact_email
        contact_phone
        website
        sport
        athletes {
          nextToken
          __typename
        }
        agents {
          nextToken
          __typename
        }
        coach_messages {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      agent {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        agent_role
        agencyID
        agency {
          nextToken
          __typename
        }
        ahtletes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      favorited {
        items {
          id
          athleteID
          coachID
          rating
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      enquiries {
        items {
          id
          enquiry_text
          athleteID
          coachID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      tags {
        items {
          id
          athleteID
          tagID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      showcases_participation {
        items {
          id
          showcaseID
          athleteID
          color
          number
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteAthlete = /* GraphQL */ `
  subscription OnDeleteAthlete {
    onDeleteAthlete {
      id
      agencyID
      agentID
      name
      isVisible
      profile_pic
      birthdate
      gender
      contact_email
      contact_phone
      nationality
      country_of_competition
      target_division
      compliance
      card_pic
      other_nice_pics
      agency_assesment
      elegibility_notes
      current_club
      current_status
      start_availability_date
      end_availability_date
      destination_college
      ncaa_id
      type_of_admission
      weight
      height
      sport
      tennis_profile {
        utr
        national_ranking
        international_ranking
        national_ranking_adult
        international_ranking_adult
        DominantHand
        __typename
      }
      position
      second_position
      dominant_foot
      featured_video {
        url
        metadata
        __typename
      }
      other_videos {
        url
        metadata
        __typename
      }
      level_of_competition
      sports_background
      showcase_participation {
        showcase_name
        city
        country
        date
        __typename
      }
      start_date
      high_school_graduation_date
      sat_score
      sat_date
      act_score
      act_date
      gemat_gre_score
      gemat_gre_date
      toefl_score
      toefl_date
      duolingo
      duolingo_date
      ielts
      ielts_date
      estimated_gpa
      intended_field_of_studies
      intended_degree
      budget
      category
      facebook_url
      twitter_user
      linkedin_url
      reason_commitment
      date_commitment
      agency {
        id
        name
        logo
        country
        contact_email
        contact_phone
        website
        sport
        athletes {
          nextToken
          __typename
        }
        agents {
          nextToken
          __typename
        }
        coach_messages {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      agent {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        agent_role
        agencyID
        agency {
          nextToken
          __typename
        }
        ahtletes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      favorited {
        items {
          id
          athleteID
          coachID
          rating
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      enquiries {
        items {
          id
          enquiry_text
          athleteID
          coachID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      tags {
        items {
          id
          athleteID
          tagID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      showcases_participation {
        items {
          id
          showcaseID
          athleteID
          color
          number
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateCoachAthleteFavorites = /* GraphQL */ `
  subscription OnCreateCoachAthleteFavorites {
    onCreateCoachAthleteFavorites {
      id
      athleteID
      coachID
      rating
      athlete {
        id
        agencyID
        agentID
        name
        isVisible
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        nationality
        country_of_competition
        target_division
        compliance
        card_pic
        other_nice_pics
        agency_assesment
        elegibility_notes
        current_club
        current_status
        start_availability_date
        end_availability_date
        destination_college
        ncaa_id
        type_of_admission
        weight
        height
        sport
        tennis_profile {
          utr
          national_ranking
          international_ranking
          national_ranking_adult
          international_ranking_adult
          DominantHand
          __typename
        }
        position
        second_position
        dominant_foot
        featured_video {
          url
          metadata
          __typename
        }
        other_videos {
          url
          metadata
          __typename
        }
        level_of_competition
        sports_background
        showcase_participation {
          showcase_name
          city
          country
          date
          __typename
        }
        start_date
        high_school_graduation_date
        sat_score
        sat_date
        act_score
        act_date
        gemat_gre_score
        gemat_gre_date
        toefl_score
        toefl_date
        duolingo
        duolingo_date
        ielts
        ielts_date
        estimated_gpa
        intended_field_of_studies
        intended_degree
        budget
        category
        facebook_url
        twitter_user
        linkedin_url
        reason_commitment
        date_commitment
        agency {
          id
          name
          logo
          country
          contact_email
          contact_phone
          website
          sport
          createdAt
          updatedAt
          __typename
        }
        agent {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          agent_role
          agencyID
          createdAt
          updatedAt
          __typename
        }
        favorited {
          nextToken
          __typename
        }
        enquiries {
          nextToken
          __typename
        }
        tags {
          nextToken
          __typename
        }
        showcases_participation {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
          __typename
        }
        my_filters {
          nextToken
          __typename
        }
        my_favorite_folders {
          nextToken
          __typename
        }
        my_notifications {
          nextToken
          __typename
        }
        showcases_interested_in {
          nextToken
          __typename
        }
        my_athlete_tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          had_trial
          collegeID
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateCoachAthleteFavorites = /* GraphQL */ `
  subscription OnUpdateCoachAthleteFavorites {
    onUpdateCoachAthleteFavorites {
      id
      athleteID
      coachID
      rating
      athlete {
        id
        agencyID
        agentID
        name
        isVisible
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        nationality
        country_of_competition
        target_division
        compliance
        card_pic
        other_nice_pics
        agency_assesment
        elegibility_notes
        current_club
        current_status
        start_availability_date
        end_availability_date
        destination_college
        ncaa_id
        type_of_admission
        weight
        height
        sport
        tennis_profile {
          utr
          national_ranking
          international_ranking
          national_ranking_adult
          international_ranking_adult
          DominantHand
          __typename
        }
        position
        second_position
        dominant_foot
        featured_video {
          url
          metadata
          __typename
        }
        other_videos {
          url
          metadata
          __typename
        }
        level_of_competition
        sports_background
        showcase_participation {
          showcase_name
          city
          country
          date
          __typename
        }
        start_date
        high_school_graduation_date
        sat_score
        sat_date
        act_score
        act_date
        gemat_gre_score
        gemat_gre_date
        toefl_score
        toefl_date
        duolingo
        duolingo_date
        ielts
        ielts_date
        estimated_gpa
        intended_field_of_studies
        intended_degree
        budget
        category
        facebook_url
        twitter_user
        linkedin_url
        reason_commitment
        date_commitment
        agency {
          id
          name
          logo
          country
          contact_email
          contact_phone
          website
          sport
          createdAt
          updatedAt
          __typename
        }
        agent {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          agent_role
          agencyID
          createdAt
          updatedAt
          __typename
        }
        favorited {
          nextToken
          __typename
        }
        enquiries {
          nextToken
          __typename
        }
        tags {
          nextToken
          __typename
        }
        showcases_participation {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
          __typename
        }
        my_filters {
          nextToken
          __typename
        }
        my_favorite_folders {
          nextToken
          __typename
        }
        my_notifications {
          nextToken
          __typename
        }
        showcases_interested_in {
          nextToken
          __typename
        }
        my_athlete_tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          had_trial
          collegeID
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteCoachAthleteFavorites = /* GraphQL */ `
  subscription OnDeleteCoachAthleteFavorites {
    onDeleteCoachAthleteFavorites {
      id
      athleteID
      coachID
      rating
      athlete {
        id
        agencyID
        agentID
        name
        isVisible
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        nationality
        country_of_competition
        target_division
        compliance
        card_pic
        other_nice_pics
        agency_assesment
        elegibility_notes
        current_club
        current_status
        start_availability_date
        end_availability_date
        destination_college
        ncaa_id
        type_of_admission
        weight
        height
        sport
        tennis_profile {
          utr
          national_ranking
          international_ranking
          national_ranking_adult
          international_ranking_adult
          DominantHand
          __typename
        }
        position
        second_position
        dominant_foot
        featured_video {
          url
          metadata
          __typename
        }
        other_videos {
          url
          metadata
          __typename
        }
        level_of_competition
        sports_background
        showcase_participation {
          showcase_name
          city
          country
          date
          __typename
        }
        start_date
        high_school_graduation_date
        sat_score
        sat_date
        act_score
        act_date
        gemat_gre_score
        gemat_gre_date
        toefl_score
        toefl_date
        duolingo
        duolingo_date
        ielts
        ielts_date
        estimated_gpa
        intended_field_of_studies
        intended_degree
        budget
        category
        facebook_url
        twitter_user
        linkedin_url
        reason_commitment
        date_commitment
        agency {
          id
          name
          logo
          country
          contact_email
          contact_phone
          website
          sport
          createdAt
          updatedAt
          __typename
        }
        agent {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          agent_role
          agencyID
          createdAt
          updatedAt
          __typename
        }
        favorited {
          nextToken
          __typename
        }
        enquiries {
          nextToken
          __typename
        }
        tags {
          nextToken
          __typename
        }
        showcases_participation {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
          __typename
        }
        my_filters {
          nextToken
          __typename
        }
        my_favorite_folders {
          nextToken
          __typename
        }
        my_notifications {
          nextToken
          __typename
        }
        showcases_interested_in {
          nextToken
          __typename
        }
        my_athlete_tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          had_trial
          collegeID
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateFavoritesFolder = /* GraphQL */ `
  subscription OnCreateFavoritesFolder {
    onCreateFavoritesFolder {
      id
      folder_name
      status
      coachID
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
          __typename
        }
        my_filters {
          nextToken
          __typename
        }
        my_favorite_folders {
          nextToken
          __typename
        }
        my_notifications {
          nextToken
          __typename
        }
        showcases_interested_in {
          nextToken
          __typename
        }
        my_athlete_tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          had_trial
          collegeID
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      athletes_in_folder {
        items {
          id
          athleteID
          favoritesFolderID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateFavoritesFolder = /* GraphQL */ `
  subscription OnUpdateFavoritesFolder {
    onUpdateFavoritesFolder {
      id
      folder_name
      status
      coachID
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
          __typename
        }
        my_filters {
          nextToken
          __typename
        }
        my_favorite_folders {
          nextToken
          __typename
        }
        my_notifications {
          nextToken
          __typename
        }
        showcases_interested_in {
          nextToken
          __typename
        }
        my_athlete_tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          had_trial
          collegeID
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      athletes_in_folder {
        items {
          id
          athleteID
          favoritesFolderID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteFavoritesFolder = /* GraphQL */ `
  subscription OnDeleteFavoritesFolder {
    onDeleteFavoritesFolder {
      id
      folder_name
      status
      coachID
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
          __typename
        }
        my_filters {
          nextToken
          __typename
        }
        my_favorite_folders {
          nextToken
          __typename
        }
        my_notifications {
          nextToken
          __typename
        }
        showcases_interested_in {
          nextToken
          __typename
        }
        my_athlete_tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          had_trial
          collegeID
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      athletes_in_folder {
        items {
          id
          athleteID
          favoritesFolderID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateFavoritesFolderAthleteRelation = /* GraphQL */ `
  subscription OnCreateFavoritesFolderAthleteRelation {
    onCreateFavoritesFolderAthleteRelation {
      id
      athleteID
      favoritesFolderID
      athlete {
        id
        agencyID
        agentID
        name
        isVisible
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        nationality
        country_of_competition
        target_division
        compliance
        card_pic
        other_nice_pics
        agency_assesment
        elegibility_notes
        current_club
        current_status
        start_availability_date
        end_availability_date
        destination_college
        ncaa_id
        type_of_admission
        weight
        height
        sport
        tennis_profile {
          utr
          national_ranking
          international_ranking
          national_ranking_adult
          international_ranking_adult
          DominantHand
          __typename
        }
        position
        second_position
        dominant_foot
        featured_video {
          url
          metadata
          __typename
        }
        other_videos {
          url
          metadata
          __typename
        }
        level_of_competition
        sports_background
        showcase_participation {
          showcase_name
          city
          country
          date
          __typename
        }
        start_date
        high_school_graduation_date
        sat_score
        sat_date
        act_score
        act_date
        gemat_gre_score
        gemat_gre_date
        toefl_score
        toefl_date
        duolingo
        duolingo_date
        ielts
        ielts_date
        estimated_gpa
        intended_field_of_studies
        intended_degree
        budget
        category
        facebook_url
        twitter_user
        linkedin_url
        reason_commitment
        date_commitment
        agency {
          id
          name
          logo
          country
          contact_email
          contact_phone
          website
          sport
          createdAt
          updatedAt
          __typename
        }
        agent {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          agent_role
          agencyID
          createdAt
          updatedAt
          __typename
        }
        favorited {
          nextToken
          __typename
        }
        enquiries {
          nextToken
          __typename
        }
        tags {
          nextToken
          __typename
        }
        showcases_participation {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      favorites_folder {
        id
        folder_name
        status
        coachID
        coach {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          disabledByUser
          is_active
          allow_whatsapp_contact
          deleteAppReason
          division
          role
          sport
          teamID
          createdAt
          updatedAt
          __typename
        }
        athletes_in_folder {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateFavoritesFolderAthleteRelation = /* GraphQL */ `
  subscription OnUpdateFavoritesFolderAthleteRelation {
    onUpdateFavoritesFolderAthleteRelation {
      id
      athleteID
      favoritesFolderID
      athlete {
        id
        agencyID
        agentID
        name
        isVisible
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        nationality
        country_of_competition
        target_division
        compliance
        card_pic
        other_nice_pics
        agency_assesment
        elegibility_notes
        current_club
        current_status
        start_availability_date
        end_availability_date
        destination_college
        ncaa_id
        type_of_admission
        weight
        height
        sport
        tennis_profile {
          utr
          national_ranking
          international_ranking
          national_ranking_adult
          international_ranking_adult
          DominantHand
          __typename
        }
        position
        second_position
        dominant_foot
        featured_video {
          url
          metadata
          __typename
        }
        other_videos {
          url
          metadata
          __typename
        }
        level_of_competition
        sports_background
        showcase_participation {
          showcase_name
          city
          country
          date
          __typename
        }
        start_date
        high_school_graduation_date
        sat_score
        sat_date
        act_score
        act_date
        gemat_gre_score
        gemat_gre_date
        toefl_score
        toefl_date
        duolingo
        duolingo_date
        ielts
        ielts_date
        estimated_gpa
        intended_field_of_studies
        intended_degree
        budget
        category
        facebook_url
        twitter_user
        linkedin_url
        reason_commitment
        date_commitment
        agency {
          id
          name
          logo
          country
          contact_email
          contact_phone
          website
          sport
          createdAt
          updatedAt
          __typename
        }
        agent {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          agent_role
          agencyID
          createdAt
          updatedAt
          __typename
        }
        favorited {
          nextToken
          __typename
        }
        enquiries {
          nextToken
          __typename
        }
        tags {
          nextToken
          __typename
        }
        showcases_participation {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      favorites_folder {
        id
        folder_name
        status
        coachID
        coach {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          disabledByUser
          is_active
          allow_whatsapp_contact
          deleteAppReason
          division
          role
          sport
          teamID
          createdAt
          updatedAt
          __typename
        }
        athletes_in_folder {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteFavoritesFolderAthleteRelation = /* GraphQL */ `
  subscription OnDeleteFavoritesFolderAthleteRelation {
    onDeleteFavoritesFolderAthleteRelation {
      id
      athleteID
      favoritesFolderID
      athlete {
        id
        agencyID
        agentID
        name
        isVisible
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        nationality
        country_of_competition
        target_division
        compliance
        card_pic
        other_nice_pics
        agency_assesment
        elegibility_notes
        current_club
        current_status
        start_availability_date
        end_availability_date
        destination_college
        ncaa_id
        type_of_admission
        weight
        height
        sport
        tennis_profile {
          utr
          national_ranking
          international_ranking
          national_ranking_adult
          international_ranking_adult
          DominantHand
          __typename
        }
        position
        second_position
        dominant_foot
        featured_video {
          url
          metadata
          __typename
        }
        other_videos {
          url
          metadata
          __typename
        }
        level_of_competition
        sports_background
        showcase_participation {
          showcase_name
          city
          country
          date
          __typename
        }
        start_date
        high_school_graduation_date
        sat_score
        sat_date
        act_score
        act_date
        gemat_gre_score
        gemat_gre_date
        toefl_score
        toefl_date
        duolingo
        duolingo_date
        ielts
        ielts_date
        estimated_gpa
        intended_field_of_studies
        intended_degree
        budget
        category
        facebook_url
        twitter_user
        linkedin_url
        reason_commitment
        date_commitment
        agency {
          id
          name
          logo
          country
          contact_email
          contact_phone
          website
          sport
          createdAt
          updatedAt
          __typename
        }
        agent {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          agent_role
          agencyID
          createdAt
          updatedAt
          __typename
        }
        favorited {
          nextToken
          __typename
        }
        enquiries {
          nextToken
          __typename
        }
        tags {
          nextToken
          __typename
        }
        showcases_participation {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      favorites_folder {
        id
        folder_name
        status
        coachID
        coach {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          disabledByUser
          is_active
          allow_whatsapp_contact
          deleteAppReason
          division
          role
          sport
          teamID
          createdAt
          updatedAt
          __typename
        }
        athletes_in_folder {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateAthleteTag = /* GraphQL */ `
  subscription OnCreateAthleteTag {
    onCreateAthleteTag {
      id
      text
      type
      athletes {
        items {
          id
          athleteID
          tagID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coaches_owners {
        items {
          id
          coachID
          tagID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateAthleteTag = /* GraphQL */ `
  subscription OnUpdateAthleteTag {
    onUpdateAthleteTag {
      id
      text
      type
      athletes {
        items {
          id
          athleteID
          tagID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coaches_owners {
        items {
          id
          coachID
          tagID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteAthleteTag = /* GraphQL */ `
  subscription OnDeleteAthleteTag {
    onDeleteAthleteTag {
      id
      text
      type
      athletes {
        items {
          id
          athleteID
          tagID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      coaches_owners {
        items {
          id
          coachID
          tagID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateCoachTagsRelation = /* GraphQL */ `
  subscription OnCreateCoachTagsRelation {
    onCreateCoachTagsRelation {
      id
      coachID
      tagID
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
          __typename
        }
        my_filters {
          nextToken
          __typename
        }
        my_favorite_folders {
          nextToken
          __typename
        }
        my_notifications {
          nextToken
          __typename
        }
        showcases_interested_in {
          nextToken
          __typename
        }
        my_athlete_tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          had_trial
          collegeID
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      tag {
        id
        text
        type
        athletes {
          nextToken
          __typename
        }
        coaches_owners {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateCoachTagsRelation = /* GraphQL */ `
  subscription OnUpdateCoachTagsRelation {
    onUpdateCoachTagsRelation {
      id
      coachID
      tagID
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
          __typename
        }
        my_filters {
          nextToken
          __typename
        }
        my_favorite_folders {
          nextToken
          __typename
        }
        my_notifications {
          nextToken
          __typename
        }
        showcases_interested_in {
          nextToken
          __typename
        }
        my_athlete_tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          had_trial
          collegeID
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      tag {
        id
        text
        type
        athletes {
          nextToken
          __typename
        }
        coaches_owners {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteCoachTagsRelation = /* GraphQL */ `
  subscription OnDeleteCoachTagsRelation {
    onDeleteCoachTagsRelation {
      id
      coachID
      tagID
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
          __typename
        }
        my_filters {
          nextToken
          __typename
        }
        my_favorite_folders {
          nextToken
          __typename
        }
        my_notifications {
          nextToken
          __typename
        }
        showcases_interested_in {
          nextToken
          __typename
        }
        my_athlete_tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          had_trial
          collegeID
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      tag {
        id
        text
        type
        athletes {
          nextToken
          __typename
        }
        coaches_owners {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateAthleteTagsRelation = /* GraphQL */ `
  subscription OnCreateAthleteTagsRelation {
    onCreateAthleteTagsRelation {
      id
      athleteID
      tagID
      athlete {
        id
        agencyID
        agentID
        name
        isVisible
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        nationality
        country_of_competition
        target_division
        compliance
        card_pic
        other_nice_pics
        agency_assesment
        elegibility_notes
        current_club
        current_status
        start_availability_date
        end_availability_date
        destination_college
        ncaa_id
        type_of_admission
        weight
        height
        sport
        tennis_profile {
          utr
          national_ranking
          international_ranking
          national_ranking_adult
          international_ranking_adult
          DominantHand
          __typename
        }
        position
        second_position
        dominant_foot
        featured_video {
          url
          metadata
          __typename
        }
        other_videos {
          url
          metadata
          __typename
        }
        level_of_competition
        sports_background
        showcase_participation {
          showcase_name
          city
          country
          date
          __typename
        }
        start_date
        high_school_graduation_date
        sat_score
        sat_date
        act_score
        act_date
        gemat_gre_score
        gemat_gre_date
        toefl_score
        toefl_date
        duolingo
        duolingo_date
        ielts
        ielts_date
        estimated_gpa
        intended_field_of_studies
        intended_degree
        budget
        category
        facebook_url
        twitter_user
        linkedin_url
        reason_commitment
        date_commitment
        agency {
          id
          name
          logo
          country
          contact_email
          contact_phone
          website
          sport
          createdAt
          updatedAt
          __typename
        }
        agent {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          agent_role
          agencyID
          createdAt
          updatedAt
          __typename
        }
        favorited {
          nextToken
          __typename
        }
        enquiries {
          nextToken
          __typename
        }
        tags {
          nextToken
          __typename
        }
        showcases_participation {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      tag {
        id
        text
        type
        athletes {
          nextToken
          __typename
        }
        coaches_owners {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateAthleteTagsRelation = /* GraphQL */ `
  subscription OnUpdateAthleteTagsRelation {
    onUpdateAthleteTagsRelation {
      id
      athleteID
      tagID
      athlete {
        id
        agencyID
        agentID
        name
        isVisible
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        nationality
        country_of_competition
        target_division
        compliance
        card_pic
        other_nice_pics
        agency_assesment
        elegibility_notes
        current_club
        current_status
        start_availability_date
        end_availability_date
        destination_college
        ncaa_id
        type_of_admission
        weight
        height
        sport
        tennis_profile {
          utr
          national_ranking
          international_ranking
          national_ranking_adult
          international_ranking_adult
          DominantHand
          __typename
        }
        position
        second_position
        dominant_foot
        featured_video {
          url
          metadata
          __typename
        }
        other_videos {
          url
          metadata
          __typename
        }
        level_of_competition
        sports_background
        showcase_participation {
          showcase_name
          city
          country
          date
          __typename
        }
        start_date
        high_school_graduation_date
        sat_score
        sat_date
        act_score
        act_date
        gemat_gre_score
        gemat_gre_date
        toefl_score
        toefl_date
        duolingo
        duolingo_date
        ielts
        ielts_date
        estimated_gpa
        intended_field_of_studies
        intended_degree
        budget
        category
        facebook_url
        twitter_user
        linkedin_url
        reason_commitment
        date_commitment
        agency {
          id
          name
          logo
          country
          contact_email
          contact_phone
          website
          sport
          createdAt
          updatedAt
          __typename
        }
        agent {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          agent_role
          agencyID
          createdAt
          updatedAt
          __typename
        }
        favorited {
          nextToken
          __typename
        }
        enquiries {
          nextToken
          __typename
        }
        tags {
          nextToken
          __typename
        }
        showcases_participation {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      tag {
        id
        text
        type
        athletes {
          nextToken
          __typename
        }
        coaches_owners {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteAthleteTagsRelation = /* GraphQL */ `
  subscription OnDeleteAthleteTagsRelation {
    onDeleteAthleteTagsRelation {
      id
      athleteID
      tagID
      athlete {
        id
        agencyID
        agentID
        name
        isVisible
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        nationality
        country_of_competition
        target_division
        compliance
        card_pic
        other_nice_pics
        agency_assesment
        elegibility_notes
        current_club
        current_status
        start_availability_date
        end_availability_date
        destination_college
        ncaa_id
        type_of_admission
        weight
        height
        sport
        tennis_profile {
          utr
          national_ranking
          international_ranking
          national_ranking_adult
          international_ranking_adult
          DominantHand
          __typename
        }
        position
        second_position
        dominant_foot
        featured_video {
          url
          metadata
          __typename
        }
        other_videos {
          url
          metadata
          __typename
        }
        level_of_competition
        sports_background
        showcase_participation {
          showcase_name
          city
          country
          date
          __typename
        }
        start_date
        high_school_graduation_date
        sat_score
        sat_date
        act_score
        act_date
        gemat_gre_score
        gemat_gre_date
        toefl_score
        toefl_date
        duolingo
        duolingo_date
        ielts
        ielts_date
        estimated_gpa
        intended_field_of_studies
        intended_degree
        budget
        category
        facebook_url
        twitter_user
        linkedin_url
        reason_commitment
        date_commitment
        agency {
          id
          name
          logo
          country
          contact_email
          contact_phone
          website
          sport
          createdAt
          updatedAt
          __typename
        }
        agent {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          agent_role
          agencyID
          createdAt
          updatedAt
          __typename
        }
        favorited {
          nextToken
          __typename
        }
        enquiries {
          nextToken
          __typename
        }
        tags {
          nextToken
          __typename
        }
        showcases_participation {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      tag {
        id
        text
        type
        athletes {
          nextToken
          __typename
        }
        coaches_owners {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateAthleteEnquiry = /* GraphQL */ `
  subscription OnCreateAthleteEnquiry {
    onCreateAthleteEnquiry {
      id
      enquiry_text
      athleteID
      coachID
      athlete {
        id
        agencyID
        agentID
        name
        isVisible
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        nationality
        country_of_competition
        target_division
        compliance
        card_pic
        other_nice_pics
        agency_assesment
        elegibility_notes
        current_club
        current_status
        start_availability_date
        end_availability_date
        destination_college
        ncaa_id
        type_of_admission
        weight
        height
        sport
        tennis_profile {
          utr
          national_ranking
          international_ranking
          national_ranking_adult
          international_ranking_adult
          DominantHand
          __typename
        }
        position
        second_position
        dominant_foot
        featured_video {
          url
          metadata
          __typename
        }
        other_videos {
          url
          metadata
          __typename
        }
        level_of_competition
        sports_background
        showcase_participation {
          showcase_name
          city
          country
          date
          __typename
        }
        start_date
        high_school_graduation_date
        sat_score
        sat_date
        act_score
        act_date
        gemat_gre_score
        gemat_gre_date
        toefl_score
        toefl_date
        duolingo
        duolingo_date
        ielts
        ielts_date
        estimated_gpa
        intended_field_of_studies
        intended_degree
        budget
        category
        facebook_url
        twitter_user
        linkedin_url
        reason_commitment
        date_commitment
        agency {
          id
          name
          logo
          country
          contact_email
          contact_phone
          website
          sport
          createdAt
          updatedAt
          __typename
        }
        agent {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          agent_role
          agencyID
          createdAt
          updatedAt
          __typename
        }
        favorited {
          nextToken
          __typename
        }
        enquiries {
          nextToken
          __typename
        }
        tags {
          nextToken
          __typename
        }
        showcases_participation {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
          __typename
        }
        my_filters {
          nextToken
          __typename
        }
        my_favorite_folders {
          nextToken
          __typename
        }
        my_notifications {
          nextToken
          __typename
        }
        showcases_interested_in {
          nextToken
          __typename
        }
        my_athlete_tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          had_trial
          collegeID
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateAthleteEnquiry = /* GraphQL */ `
  subscription OnUpdateAthleteEnquiry {
    onUpdateAthleteEnquiry {
      id
      enquiry_text
      athleteID
      coachID
      athlete {
        id
        agencyID
        agentID
        name
        isVisible
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        nationality
        country_of_competition
        target_division
        compliance
        card_pic
        other_nice_pics
        agency_assesment
        elegibility_notes
        current_club
        current_status
        start_availability_date
        end_availability_date
        destination_college
        ncaa_id
        type_of_admission
        weight
        height
        sport
        tennis_profile {
          utr
          national_ranking
          international_ranking
          national_ranking_adult
          international_ranking_adult
          DominantHand
          __typename
        }
        position
        second_position
        dominant_foot
        featured_video {
          url
          metadata
          __typename
        }
        other_videos {
          url
          metadata
          __typename
        }
        level_of_competition
        sports_background
        showcase_participation {
          showcase_name
          city
          country
          date
          __typename
        }
        start_date
        high_school_graduation_date
        sat_score
        sat_date
        act_score
        act_date
        gemat_gre_score
        gemat_gre_date
        toefl_score
        toefl_date
        duolingo
        duolingo_date
        ielts
        ielts_date
        estimated_gpa
        intended_field_of_studies
        intended_degree
        budget
        category
        facebook_url
        twitter_user
        linkedin_url
        reason_commitment
        date_commitment
        agency {
          id
          name
          logo
          country
          contact_email
          contact_phone
          website
          sport
          createdAt
          updatedAt
          __typename
        }
        agent {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          agent_role
          agencyID
          createdAt
          updatedAt
          __typename
        }
        favorited {
          nextToken
          __typename
        }
        enquiries {
          nextToken
          __typename
        }
        tags {
          nextToken
          __typename
        }
        showcases_participation {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
          __typename
        }
        my_filters {
          nextToken
          __typename
        }
        my_favorite_folders {
          nextToken
          __typename
        }
        my_notifications {
          nextToken
          __typename
        }
        showcases_interested_in {
          nextToken
          __typename
        }
        my_athlete_tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          had_trial
          collegeID
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteAthleteEnquiry = /* GraphQL */ `
  subscription OnDeleteAthleteEnquiry {
    onDeleteAthleteEnquiry {
      id
      enquiry_text
      athleteID
      coachID
      athlete {
        id
        agencyID
        agentID
        name
        isVisible
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        nationality
        country_of_competition
        target_division
        compliance
        card_pic
        other_nice_pics
        agency_assesment
        elegibility_notes
        current_club
        current_status
        start_availability_date
        end_availability_date
        destination_college
        ncaa_id
        type_of_admission
        weight
        height
        sport
        tennis_profile {
          utr
          national_ranking
          international_ranking
          national_ranking_adult
          international_ranking_adult
          DominantHand
          __typename
        }
        position
        second_position
        dominant_foot
        featured_video {
          url
          metadata
          __typename
        }
        other_videos {
          url
          metadata
          __typename
        }
        level_of_competition
        sports_background
        showcase_participation {
          showcase_name
          city
          country
          date
          __typename
        }
        start_date
        high_school_graduation_date
        sat_score
        sat_date
        act_score
        act_date
        gemat_gre_score
        gemat_gre_date
        toefl_score
        toefl_date
        duolingo
        duolingo_date
        ielts
        ielts_date
        estimated_gpa
        intended_field_of_studies
        intended_degree
        budget
        category
        facebook_url
        twitter_user
        linkedin_url
        reason_commitment
        date_commitment
        agency {
          id
          name
          logo
          country
          contact_email
          contact_phone
          website
          sport
          createdAt
          updatedAt
          __typename
        }
        agent {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          agent_role
          agencyID
          createdAt
          updatedAt
          __typename
        }
        favorited {
          nextToken
          __typename
        }
        enquiries {
          nextToken
          __typename
        }
        tags {
          nextToken
          __typename
        }
        showcases_participation {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
          __typename
        }
        my_filters {
          nextToken
          __typename
        }
        my_favorite_folders {
          nextToken
          __typename
        }
        my_notifications {
          nextToken
          __typename
        }
        showcases_interested_in {
          nextToken
          __typename
        }
        my_athlete_tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          had_trial
          collegeID
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateFeedback = /* GraphQL */ `
  subscription OnCreateFeedback {
    onCreateFeedback {
      id
      feedback_text
      type
      coachID
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
          __typename
        }
        my_filters {
          nextToken
          __typename
        }
        my_favorite_folders {
          nextToken
          __typename
        }
        my_notifications {
          nextToken
          __typename
        }
        showcases_interested_in {
          nextToken
          __typename
        }
        my_athlete_tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          had_trial
          collegeID
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateFeedback = /* GraphQL */ `
  subscription OnUpdateFeedback {
    onUpdateFeedback {
      id
      feedback_text
      type
      coachID
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
          __typename
        }
        my_filters {
          nextToken
          __typename
        }
        my_favorite_folders {
          nextToken
          __typename
        }
        my_notifications {
          nextToken
          __typename
        }
        showcases_interested_in {
          nextToken
          __typename
        }
        my_athlete_tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          had_trial
          collegeID
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteFeedback = /* GraphQL */ `
  subscription OnDeleteFeedback {
    onDeleteFeedback {
      id
      feedback_text
      type
      coachID
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
          __typename
        }
        my_filters {
          nextToken
          __typename
        }
        my_favorite_folders {
          nextToken
          __typename
        }
        my_notifications {
          nextToken
          __typename
        }
        showcases_interested_in {
          nextToken
          __typename
        }
        my_athlete_tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          had_trial
          collegeID
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateSearchTerm = /* GraphQL */ `
  subscription OnCreateSearchTerm {
    onCreateSearchTerm {
      id
      search_term_text
      search_type
      sport
      source_app
      coachID
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
          __typename
        }
        my_filters {
          nextToken
          __typename
        }
        my_favorite_folders {
          nextToken
          __typename
        }
        my_notifications {
          nextToken
          __typename
        }
        showcases_interested_in {
          nextToken
          __typename
        }
        my_athlete_tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          had_trial
          collegeID
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateSearchTerm = /* GraphQL */ `
  subscription OnUpdateSearchTerm {
    onUpdateSearchTerm {
      id
      search_term_text
      search_type
      sport
      source_app
      coachID
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
          __typename
        }
        my_filters {
          nextToken
          __typename
        }
        my_favorite_folders {
          nextToken
          __typename
        }
        my_notifications {
          nextToken
          __typename
        }
        showcases_interested_in {
          nextToken
          __typename
        }
        my_athlete_tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          had_trial
          collegeID
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteSearchTerm = /* GraphQL */ `
  subscription OnDeleteSearchTerm {
    onDeleteSearchTerm {
      id
      search_term_text
      search_type
      sport
      source_app
      coachID
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
          __typename
        }
        my_filters {
          nextToken
          __typename
        }
        my_favorite_folders {
          nextToken
          __typename
        }
        my_notifications {
          nextToken
          __typename
        }
        showcases_interested_in {
          nextToken
          __typename
        }
        my_athlete_tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          had_trial
          collegeID
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateSearchFilter = /* GraphQL */ `
  subscription OnCreateSearchFilter {
    onCreateSearchFilter {
      id
      name
      filter_body
      type
      sport
      source_app
      coachID
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
          __typename
        }
        my_filters {
          nextToken
          __typename
        }
        my_favorite_folders {
          nextToken
          __typename
        }
        my_notifications {
          nextToken
          __typename
        }
        showcases_interested_in {
          nextToken
          __typename
        }
        my_athlete_tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          had_trial
          collegeID
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateSearchFilter = /* GraphQL */ `
  subscription OnUpdateSearchFilter {
    onUpdateSearchFilter {
      id
      name
      filter_body
      type
      sport
      source_app
      coachID
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
          __typename
        }
        my_filters {
          nextToken
          __typename
        }
        my_favorite_folders {
          nextToken
          __typename
        }
        my_notifications {
          nextToken
          __typename
        }
        showcases_interested_in {
          nextToken
          __typename
        }
        my_athlete_tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          had_trial
          collegeID
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteSearchFilter = /* GraphQL */ `
  subscription OnDeleteSearchFilter {
    onDeleteSearchFilter {
      id
      name
      filter_body
      type
      sport
      source_app
      coachID
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
          __typename
        }
        my_filters {
          nextToken
          __typename
        }
        my_favorite_folders {
          nextToken
          __typename
        }
        my_notifications {
          nextToken
          __typename
        }
        showcases_interested_in {
          nextToken
          __typename
        }
        my_athlete_tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          had_trial
          collegeID
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateNotification = /* GraphQL */ `
  subscription OnCreateNotification {
    onCreateNotification {
      id
      title
      text
      status
      type
      pic
      attachment
      coaches_notified {
        items {
          id
          is_viewed
          status
          notificationID
          coachID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateNotification = /* GraphQL */ `
  subscription OnUpdateNotification {
    onUpdateNotification {
      id
      title
      text
      status
      type
      pic
      attachment
      coaches_notified {
        items {
          id
          is_viewed
          status
          notificationID
          coachID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteNotification = /* GraphQL */ `
  subscription OnDeleteNotification {
    onDeleteNotification {
      id
      title
      text
      status
      type
      pic
      attachment
      coaches_notified {
        items {
          id
          is_viewed
          status
          notificationID
          coachID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateCoachNotificationRelation = /* GraphQL */ `
  subscription OnCreateCoachNotificationRelation {
    onCreateCoachNotificationRelation {
      id
      is_viewed
      status
      notificationID
      coachID
      notification {
        id
        title
        text
        status
        type
        pic
        attachment
        coaches_notified {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
          __typename
        }
        my_filters {
          nextToken
          __typename
        }
        my_favorite_folders {
          nextToken
          __typename
        }
        my_notifications {
          nextToken
          __typename
        }
        showcases_interested_in {
          nextToken
          __typename
        }
        my_athlete_tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          had_trial
          collegeID
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateCoachNotificationRelation = /* GraphQL */ `
  subscription OnUpdateCoachNotificationRelation {
    onUpdateCoachNotificationRelation {
      id
      is_viewed
      status
      notificationID
      coachID
      notification {
        id
        title
        text
        status
        type
        pic
        attachment
        coaches_notified {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
          __typename
        }
        my_filters {
          nextToken
          __typename
        }
        my_favorite_folders {
          nextToken
          __typename
        }
        my_notifications {
          nextToken
          __typename
        }
        showcases_interested_in {
          nextToken
          __typename
        }
        my_athlete_tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          had_trial
          collegeID
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteCoachNotificationRelation = /* GraphQL */ `
  subscription OnDeleteCoachNotificationRelation {
    onDeleteCoachNotificationRelation {
      id
      is_viewed
      status
      notificationID
      coachID
      notification {
        id
        title
        text
        status
        type
        pic
        attachment
        coaches_notified {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
          __typename
        }
        my_filters {
          nextToken
          __typename
        }
        my_favorite_folders {
          nextToken
          __typename
        }
        my_notifications {
          nextToken
          __typename
        }
        showcases_interested_in {
          nextToken
          __typename
        }
        my_athlete_tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          had_trial
          collegeID
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateAgent = /* GraphQL */ `
  subscription OnCreateAgent {
    onCreateAgent {
      id
      name
      profile_pic
      birthdate
      gender
      contact_email
      contact_phone
      agent_role
      agencyID
      agency {
        items {
          id
          name
          logo
          country
          contact_email
          contact_phone
          website
          sport
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      ahtletes {
        items {
          id
          agencyID
          agentID
          name
          isVisible
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          nationality
          country_of_competition
          target_division
          compliance
          card_pic
          other_nice_pics
          agency_assesment
          elegibility_notes
          current_club
          current_status
          start_availability_date
          end_availability_date
          destination_college
          ncaa_id
          type_of_admission
          weight
          height
          sport
          position
          second_position
          dominant_foot
          level_of_competition
          sports_background
          start_date
          high_school_graduation_date
          sat_score
          sat_date
          act_score
          act_date
          gemat_gre_score
          gemat_gre_date
          toefl_score
          toefl_date
          duolingo
          duolingo_date
          ielts
          ielts_date
          estimated_gpa
          intended_field_of_studies
          intended_degree
          budget
          category
          facebook_url
          twitter_user
          linkedin_url
          reason_commitment
          date_commitment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateAgent = /* GraphQL */ `
  subscription OnUpdateAgent {
    onUpdateAgent {
      id
      name
      profile_pic
      birthdate
      gender
      contact_email
      contact_phone
      agent_role
      agencyID
      agency {
        items {
          id
          name
          logo
          country
          contact_email
          contact_phone
          website
          sport
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      ahtletes {
        items {
          id
          agencyID
          agentID
          name
          isVisible
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          nationality
          country_of_competition
          target_division
          compliance
          card_pic
          other_nice_pics
          agency_assesment
          elegibility_notes
          current_club
          current_status
          start_availability_date
          end_availability_date
          destination_college
          ncaa_id
          type_of_admission
          weight
          height
          sport
          position
          second_position
          dominant_foot
          level_of_competition
          sports_background
          start_date
          high_school_graduation_date
          sat_score
          sat_date
          act_score
          act_date
          gemat_gre_score
          gemat_gre_date
          toefl_score
          toefl_date
          duolingo
          duolingo_date
          ielts
          ielts_date
          estimated_gpa
          intended_field_of_studies
          intended_degree
          budget
          category
          facebook_url
          twitter_user
          linkedin_url
          reason_commitment
          date_commitment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteAgent = /* GraphQL */ `
  subscription OnDeleteAgent {
    onDeleteAgent {
      id
      name
      profile_pic
      birthdate
      gender
      contact_email
      contact_phone
      agent_role
      agencyID
      agency {
        items {
          id
          name
          logo
          country
          contact_email
          contact_phone
          website
          sport
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      ahtletes {
        items {
          id
          agencyID
          agentID
          name
          isVisible
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          nationality
          country_of_competition
          target_division
          compliance
          card_pic
          other_nice_pics
          agency_assesment
          elegibility_notes
          current_club
          current_status
          start_availability_date
          end_availability_date
          destination_college
          ncaa_id
          type_of_admission
          weight
          height
          sport
          position
          second_position
          dominant_foot
          level_of_competition
          sports_background
          start_date
          high_school_graduation_date
          sat_score
          sat_date
          act_score
          act_date
          gemat_gre_score
          gemat_gre_date
          toefl_score
          toefl_date
          duolingo
          duolingo_date
          ielts
          ielts_date
          estimated_gpa
          intended_field_of_studies
          intended_degree
          budget
          category
          facebook_url
          twitter_user
          linkedin_url
          reason_commitment
          date_commitment
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateShowcase = /* GraphQL */ `
  subscription OnCreateShowcase {
    onCreateShowcase {
      id
      name
      description
      contry
      address
      date
      status
      coaches_interested {
        items {
          id
          showcaseID
          coachID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      featured_athletes {
        items {
          id
          showcaseID
          athleteID
          color
          number
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateShowcase = /* GraphQL */ `
  subscription OnUpdateShowcase {
    onUpdateShowcase {
      id
      name
      description
      contry
      address
      date
      status
      coaches_interested {
        items {
          id
          showcaseID
          coachID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      featured_athletes {
        items {
          id
          showcaseID
          athleteID
          color
          number
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteShowcase = /* GraphQL */ `
  subscription OnDeleteShowcase {
    onDeleteShowcase {
      id
      name
      description
      contry
      address
      date
      status
      coaches_interested {
        items {
          id
          showcaseID
          coachID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      featured_athletes {
        items {
          id
          showcaseID
          athleteID
          color
          number
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateShowcaseCoachRelation = /* GraphQL */ `
  subscription OnCreateShowcaseCoachRelation {
    onCreateShowcaseCoachRelation {
      id
      showcaseID
      coachID
      showcase {
        id
        name
        description
        contry
        address
        date
        status
        coaches_interested {
          nextToken
          __typename
        }
        featured_athletes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
          __typename
        }
        my_filters {
          nextToken
          __typename
        }
        my_favorite_folders {
          nextToken
          __typename
        }
        my_notifications {
          nextToken
          __typename
        }
        showcases_interested_in {
          nextToken
          __typename
        }
        my_athlete_tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          had_trial
          collegeID
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateShowcaseCoachRelation = /* GraphQL */ `
  subscription OnUpdateShowcaseCoachRelation {
    onUpdateShowcaseCoachRelation {
      id
      showcaseID
      coachID
      showcase {
        id
        name
        description
        contry
        address
        date
        status
        coaches_interested {
          nextToken
          __typename
        }
        featured_athletes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
          __typename
        }
        my_filters {
          nextToken
          __typename
        }
        my_favorite_folders {
          nextToken
          __typename
        }
        my_notifications {
          nextToken
          __typename
        }
        showcases_interested_in {
          nextToken
          __typename
        }
        my_athlete_tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          had_trial
          collegeID
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteShowcaseCoachRelation = /* GraphQL */ `
  subscription OnDeleteShowcaseCoachRelation {
    onDeleteShowcaseCoachRelation {
      id
      showcaseID
      coachID
      showcase {
        id
        name
        description
        contry
        address
        date
        status
        coaches_interested {
          nextToken
          __typename
        }
        featured_athletes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
          __typename
        }
        my_filters {
          nextToken
          __typename
        }
        my_favorite_folders {
          nextToken
          __typename
        }
        my_notifications {
          nextToken
          __typename
        }
        showcases_interested_in {
          nextToken
          __typename
        }
        my_athlete_tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          had_trial
          collegeID
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateShowcaseAthleteRelation = /* GraphQL */ `
  subscription OnCreateShowcaseAthleteRelation {
    onCreateShowcaseAthleteRelation {
      id
      showcaseID
      athleteID
      color
      number
      showcase {
        id
        name
        description
        contry
        address
        date
        status
        coaches_interested {
          nextToken
          __typename
        }
        featured_athletes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      athlete {
        id
        agencyID
        agentID
        name
        isVisible
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        nationality
        country_of_competition
        target_division
        compliance
        card_pic
        other_nice_pics
        agency_assesment
        elegibility_notes
        current_club
        current_status
        start_availability_date
        end_availability_date
        destination_college
        ncaa_id
        type_of_admission
        weight
        height
        sport
        tennis_profile {
          utr
          national_ranking
          international_ranking
          national_ranking_adult
          international_ranking_adult
          DominantHand
          __typename
        }
        position
        second_position
        dominant_foot
        featured_video {
          url
          metadata
          __typename
        }
        other_videos {
          url
          metadata
          __typename
        }
        level_of_competition
        sports_background
        showcase_participation {
          showcase_name
          city
          country
          date
          __typename
        }
        start_date
        high_school_graduation_date
        sat_score
        sat_date
        act_score
        act_date
        gemat_gre_score
        gemat_gre_date
        toefl_score
        toefl_date
        duolingo
        duolingo_date
        ielts
        ielts_date
        estimated_gpa
        intended_field_of_studies
        intended_degree
        budget
        category
        facebook_url
        twitter_user
        linkedin_url
        reason_commitment
        date_commitment
        agency {
          id
          name
          logo
          country
          contact_email
          contact_phone
          website
          sport
          createdAt
          updatedAt
          __typename
        }
        agent {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          agent_role
          agencyID
          createdAt
          updatedAt
          __typename
        }
        favorited {
          nextToken
          __typename
        }
        enquiries {
          nextToken
          __typename
        }
        tags {
          nextToken
          __typename
        }
        showcases_participation {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateShowcaseAthleteRelation = /* GraphQL */ `
  subscription OnUpdateShowcaseAthleteRelation {
    onUpdateShowcaseAthleteRelation {
      id
      showcaseID
      athleteID
      color
      number
      showcase {
        id
        name
        description
        contry
        address
        date
        status
        coaches_interested {
          nextToken
          __typename
        }
        featured_athletes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      athlete {
        id
        agencyID
        agentID
        name
        isVisible
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        nationality
        country_of_competition
        target_division
        compliance
        card_pic
        other_nice_pics
        agency_assesment
        elegibility_notes
        current_club
        current_status
        start_availability_date
        end_availability_date
        destination_college
        ncaa_id
        type_of_admission
        weight
        height
        sport
        tennis_profile {
          utr
          national_ranking
          international_ranking
          national_ranking_adult
          international_ranking_adult
          DominantHand
          __typename
        }
        position
        second_position
        dominant_foot
        featured_video {
          url
          metadata
          __typename
        }
        other_videos {
          url
          metadata
          __typename
        }
        level_of_competition
        sports_background
        showcase_participation {
          showcase_name
          city
          country
          date
          __typename
        }
        start_date
        high_school_graduation_date
        sat_score
        sat_date
        act_score
        act_date
        gemat_gre_score
        gemat_gre_date
        toefl_score
        toefl_date
        duolingo
        duolingo_date
        ielts
        ielts_date
        estimated_gpa
        intended_field_of_studies
        intended_degree
        budget
        category
        facebook_url
        twitter_user
        linkedin_url
        reason_commitment
        date_commitment
        agency {
          id
          name
          logo
          country
          contact_email
          contact_phone
          website
          sport
          createdAt
          updatedAt
          __typename
        }
        agent {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          agent_role
          agencyID
          createdAt
          updatedAt
          __typename
        }
        favorited {
          nextToken
          __typename
        }
        enquiries {
          nextToken
          __typename
        }
        tags {
          nextToken
          __typename
        }
        showcases_participation {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteShowcaseAthleteRelation = /* GraphQL */ `
  subscription OnDeleteShowcaseAthleteRelation {
    onDeleteShowcaseAthleteRelation {
      id
      showcaseID
      athleteID
      color
      number
      showcase {
        id
        name
        description
        contry
        address
        date
        status
        coaches_interested {
          nextToken
          __typename
        }
        featured_athletes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      athlete {
        id
        agencyID
        agentID
        name
        isVisible
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        nationality
        country_of_competition
        target_division
        compliance
        card_pic
        other_nice_pics
        agency_assesment
        elegibility_notes
        current_club
        current_status
        start_availability_date
        end_availability_date
        destination_college
        ncaa_id
        type_of_admission
        weight
        height
        sport
        tennis_profile {
          utr
          national_ranking
          international_ranking
          national_ranking_adult
          international_ranking_adult
          DominantHand
          __typename
        }
        position
        second_position
        dominant_foot
        featured_video {
          url
          metadata
          __typename
        }
        other_videos {
          url
          metadata
          __typename
        }
        level_of_competition
        sports_background
        showcase_participation {
          showcase_name
          city
          country
          date
          __typename
        }
        start_date
        high_school_graduation_date
        sat_score
        sat_date
        act_score
        act_date
        gemat_gre_score
        gemat_gre_date
        toefl_score
        toefl_date
        duolingo
        duolingo_date
        ielts
        ielts_date
        estimated_gpa
        intended_field_of_studies
        intended_degree
        budget
        category
        facebook_url
        twitter_user
        linkedin_url
        reason_commitment
        date_commitment
        agency {
          id
          name
          logo
          country
          contact_email
          contact_phone
          website
          sport
          createdAt
          updatedAt
          __typename
        }
        agent {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          agent_role
          agencyID
          createdAt
          updatedAt
          __typename
        }
        favorited {
          nextToken
          __typename
        }
        enquiries {
          nextToken
          __typename
        }
        tags {
          nextToken
          __typename
        }
        showcases_participation {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateTeam = /* GraphQL */ `
  subscription OnCreateTeam {
    onCreateTeam {
      id
      name
      sport
      genre
      suscription_name
      suscription_recurrency
      suscription_status
      suscription_stripe_id
      suscription_premium_start_date
      suscription_premium_end_date
      team_logo
      conference
      division
      college_category
      url_domain
      had_trial
      collegeID
      college {
        id
        name
        country
        state
        address
        logo
        createdAt
        updatedAt
        teams {
          nextToken
          __typename
        }
        __typename
      }
      coaches {
        items {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          disabledByUser
          is_active
          allow_whatsapp_contact
          deleteAppReason
          division
          role
          sport
          teamID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      subscription_history {
        items {
          id
          start_date
          end_date
          suscription_name
          teamID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      subscriberID
      subscriber {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
          __typename
        }
        my_filters {
          nextToken
          __typename
        }
        my_favorite_folders {
          nextToken
          __typename
        }
        my_notifications {
          nextToken
          __typename
        }
        showcases_interested_in {
          nextToken
          __typename
        }
        my_athlete_tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          had_trial
          collegeID
          subscriberID
          subscription_initial_date
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      subscription_initial_date
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateTeam = /* GraphQL */ `
  subscription OnUpdateTeam {
    onUpdateTeam {
      id
      name
      sport
      genre
      suscription_name
      suscription_recurrency
      suscription_status
      suscription_stripe_id
      suscription_premium_start_date
      suscription_premium_end_date
      team_logo
      conference
      division
      college_category
      url_domain
      had_trial
      collegeID
      college {
        id
        name
        country
        state
        address
        logo
        createdAt
        updatedAt
        teams {
          nextToken
          __typename
        }
        __typename
      }
      coaches {
        items {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          disabledByUser
          is_active
          allow_whatsapp_contact
          deleteAppReason
          division
          role
          sport
          teamID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      subscription_history {
        items {
          id
          start_date
          end_date
          suscription_name
          teamID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      subscriberID
      subscriber {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
          __typename
        }
        my_filters {
          nextToken
          __typename
        }
        my_favorite_folders {
          nextToken
          __typename
        }
        my_notifications {
          nextToken
          __typename
        }
        showcases_interested_in {
          nextToken
          __typename
        }
        my_athlete_tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          had_trial
          collegeID
          subscriberID
          subscription_initial_date
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      subscription_initial_date
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteTeam = /* GraphQL */ `
  subscription OnDeleteTeam {
    onDeleteTeam {
      id
      name
      sport
      genre
      suscription_name
      suscription_recurrency
      suscription_status
      suscription_stripe_id
      suscription_premium_start_date
      suscription_premium_end_date
      team_logo
      conference
      division
      college_category
      url_domain
      had_trial
      collegeID
      college {
        id
        name
        country
        state
        address
        logo
        createdAt
        updatedAt
        teams {
          nextToken
          __typename
        }
        __typename
      }
      coaches {
        items {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          disabledByUser
          is_active
          allow_whatsapp_contact
          deleteAppReason
          division
          role
          sport
          teamID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      subscription_history {
        items {
          id
          start_date
          end_date
          suscription_name
          teamID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      subscriberID
      subscriber {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
          __typename
        }
        my_filters {
          nextToken
          __typename
        }
        my_favorite_folders {
          nextToken
          __typename
        }
        my_notifications {
          nextToken
          __typename
        }
        showcases_interested_in {
          nextToken
          __typename
        }
        my_athlete_tags {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          suscription_stripe_id
          suscription_premium_start_date
          suscription_premium_end_date
          team_logo
          conference
          division
          college_category
          url_domain
          had_trial
          collegeID
          subscriberID
          subscription_initial_date
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      subscription_initial_date
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateAuxCollegeCoachInfo = /* GraphQL */ `
  subscription OnCreateAuxCollegeCoachInfo {
    onCreateAuxCollegeCoachInfo {
      id
      conference
      state
      division
      uniqueID
      sport
      added
      removed
      school
      first_name
      last_name
      position
      contact_email
      contact_phone
      landing_pages
      individual_twitter
      team_twitter
      individual_facebook
      team_facebook
      individual_instagram
      team_instagram
      questionnaire
      school2
      state2
      city
      region
      size_of_city
      private_or_public
      religious
      hbcu
      average_gpa
      sat_reading
      sat_math
      act_composite
      acceptance_rate
      total_yearly_cost
      school3
      majors_offered
      number_of_undergrads
      us_ranking
      us_arts_ranking
      ipeds
      college_category
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateAuxCollegeCoachInfo = /* GraphQL */ `
  subscription OnUpdateAuxCollegeCoachInfo {
    onUpdateAuxCollegeCoachInfo {
      id
      conference
      state
      division
      uniqueID
      sport
      added
      removed
      school
      first_name
      last_name
      position
      contact_email
      contact_phone
      landing_pages
      individual_twitter
      team_twitter
      individual_facebook
      team_facebook
      individual_instagram
      team_instagram
      questionnaire
      school2
      state2
      city
      region
      size_of_city
      private_or_public
      religious
      hbcu
      average_gpa
      sat_reading
      sat_math
      act_composite
      acceptance_rate
      total_yearly_cost
      school3
      majors_offered
      number_of_undergrads
      us_ranking
      us_arts_ranking
      ipeds
      college_category
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteAuxCollegeCoachInfo = /* GraphQL */ `
  subscription OnDeleteAuxCollegeCoachInfo {
    onDeleteAuxCollegeCoachInfo {
      id
      conference
      state
      division
      uniqueID
      sport
      added
      removed
      school
      first_name
      last_name
      position
      contact_email
      contact_phone
      landing_pages
      individual_twitter
      team_twitter
      individual_facebook
      team_facebook
      individual_instagram
      team_instagram
      questionnaire
      school2
      state2
      city
      region
      size_of_city
      private_or_public
      religious
      hbcu
      average_gpa
      sat_reading
      sat_math
      act_composite
      acceptance_rate
      total_yearly_cost
      school3
      majors_offered
      number_of_undergrads
      us_ranking
      us_arts_ranking
      ipeds
      college_category
      createdAt
      updatedAt
      __typename
    }
  }
`;
