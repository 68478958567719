import React from "react";
import {
	Avatar,
	AvatarGroup,
	Flex,
	Icon,
	Progress,
	Td,
	Text,
	Tr,
	useColorModeValue,
	Image
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";


function DashboardRankingTableRow({ athlete, coach, lastDate,count, porcentage }) {
	const textColor = useColorModeValue("gray.700", "white");
	let navigate = useNavigate();

	return (
		<Tr>
			<Td minWidth={{ sm: "250px" }} pl="0px">
				<Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
					<Avatar src={athlete?.profile_pic} h={"50px"} w={"50px"} mr="10px" />
					<Flex direction="column">
						<Text
							fontSize="md"
							color={textColor}
							fontWeight="bold"
							minWidth="100%"
						>
							{athlete?.name}
						</Text>
						<Text fontSize="sm" color="gray.400" fontWeight="normal">
							{athlete?.agency?.name}
						</Text>
						<Text fontSize="sm" color="gray.400" fontWeight="normal">
							{athlete?.sport}
						</Text>
					</Flex>
				</Flex>
			</Td>

			<Td>
				<Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
					<Flex direction="column">
						<Text
							fontSize="md"
							color={textColor}
							fontWeight="bold"
							minWidth="100%"
							cursor="pointer"
							onClick={()=>navigate(`/admin/coaches/activity/${coach.id}`)}
						>
							{coach?.name}
						</Text>
						<Text fontSize="sm" color="gray.400" fontWeight="normal">
							{coach?.contact_email}
						</Text>
						<Text fontSize="sm" color="gray.400" fontWeight="normal">
						{lastDate.toLocaleDateString()}
						</Text>
					</Flex>
				</Flex>
			</Td>
			<Td>
				<Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
				{count}
				</Text>
			</Td>
			<Td>
				<Flex direction="column">
					<Text
						fontSize="md"
						color="blue.300"
						fontWeight="bold"
						pb=".2rem"
					>{`${porcentage}%`}</Text>
					<Progress
						colorscheme={porcentage === 100 ? "blue" : "cyan"}
						size="xs"
						w="75%"
						value={porcentage*10}
						borderRadius="15px"
					/>
				</Flex>
			</Td>
		</Tr>
	);
}

export default DashboardRankingTableRow;
