export const listSearchTerms = /* GraphQL */ `
  query ListSearchTerms(
    $filter: ModelSearchTermFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSearchTerms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        search_term_text
        search_type
        source_app
        sport
        coachID
        coach {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          disabledByUser
          is_active
          allow_whatsapp_contact
          deleteAppReason
          division
          role
          sport
          teamID
          team {
            name
          } 
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;