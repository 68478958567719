import React, { useContext, useEffect } from 'react'
import { Routes, Route } from 'react-router-dom';
import AgentsTables from './components/AgentsTables';
import { DataContext } from '../../../contexts/dataContext'
import { ShowAgent } from './components/ShowAgent';
import { EditAgent } from './components/EditAgent';
import { CreateAgent } from './components/CreateAgent';

export const AgentsRoutes = () => {
    const dataContext = useContext(DataContext)
    const { agents } = dataContext

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <Routes>
                <Route path="/" element={<AgentsTables agents={agents} showAgency={true}/>} />
                <Route path="/show/:agentId" element={<ShowAgent />} />
                <Route path="/edit/:agentId" element={<EditAgent />} />
                <Route path="/create" element={<CreateAgent />} />

            </Routes>
        </>
    )
}
