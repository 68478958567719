import { API } from 'aws-amplify';
import { updateAgency, createAgency, deleteAgency } from '../custom_graphql_queries/agencies'

const updateAgencyService = async (agency) => {
    try {
        API.graphql({query: updateAgency, variables: { input: agency }})
        .catch(error => {console.log(error)})
    } catch (error) {
        throw new Error(error.message);
    }
}
const createAgencyService = async (agency) => {
    try {
        API.graphql({query: createAgency, variables: { input: agency }})
        .catch(error => {console.log(error)})
    } catch (error) {
        throw new Error(error.message);
    }
}

const deleteAgencyService = async (agencyId) => {
    try {
        const response = await API.graphql({query: deleteAgency, variables: { input: {id: agencyId }}})
        return response
    } catch (error) {
        throw new Error(error.message);
    }
}

export { updateAgencyService, createAgencyService, deleteAgencyService };