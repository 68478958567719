import React from 'react'
import { 
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Flex,
    ListItem,
    Text,
    UnorderedList,
} from '@chakra-ui/react'

export const CategoryInfoAccordion = ({gender, athletesWithSameCategory, categoryExampleTeams}) => {
    return (
        <Flex direction="column" w="50%">
            <Text fontSize={{ sm: "sm", lg: "md" }} color='gray.400' mb="15px">
                Why Category-system?
            </Text>
            <Accordion allowToggle>
                <AccordionItem>
                    <Text color='gray.600' >
                        <AccordionButton>
                            <Box as="span" flex='1' textAlign='left' fontSize='md'>Match-making</Box>
                            <AccordionIcon />
                        </AccordionButton>
                    </Text>
                    <AccordionPanel pb={4} fontSize='sm' color='gray.400'>
                        It is match-making tool where athletes are assigned several categories and should be marketed to programs in corresponding categories.
                    </AccordionPanel>
                </AccordionItem>

                <AccordionItem>
                    <Text color='gray.600' >
                        <AccordionButton>
                            <Box as="span" flex='1' textAlign='left' fontSize='md'>Recommend profiles</Box>
                            <AccordionIcon />
                        </AccordionButton>
                    </Text>
                    <AccordionPanel pb={4} fontSize='sm' color='gray.400'>
                        This is the "algorithm" in the Alliance Athletes App can help show the best suitable profiles first and get recommended profiles showing first.
                        {
                            athletesWithSameCategory.length > 0 && (
                                <UnorderedList>
                                    {athletesWithSameCategory?.map(a => (<ListItem>{a.name} - {a.destination_college}</ListItem>))}
                                </UnorderedList>
                            )
                        }
                    </AccordionPanel>
                </AccordionItem>
                
                <AccordionItem>
                    <Text color='gray.600' >
                        <AccordionButton>
                            <Box as="span" flex='1' textAlign='left' fontSize='md'>Common language</Box>
                            <AccordionIcon />
                        </AccordionButton>
                    </Text>
                    <AccordionPanel pb={4} fontSize='sm' color='gray.400'>
                        By implementing the Cateogry structure for the Alliance Athletes App and its users, we have a common "language" between the agencies for what types of schools can fit for the PSAs.
                    </AccordionPanel>
                </AccordionItem>
                
                <AccordionItem>
                    <Text color='gray.600' >
                        <AccordionButton>
                            <Box as="span" flex='1' textAlign='left' fontSize='md'>Example schools ({gender === 'male' ? 'Msoccer':'Wsoccer'})</Box>
                            <AccordionIcon />
                        </AccordionButton>
                    </Text>
                    <AccordionPanel pb={4} fontSize='sm' color='gray.400'>
                            {
                                categoryExampleTeams.length > 0 ? (
                                    <UnorderedList>
                                        {categoryExampleTeams.map(it => (<ListItem>{it.name}</ListItem>))}
                                    </UnorderedList>
                                ) : (
                                    'Select a category to show some examples'
                                )
                            }
                    </AccordionPanel>
                </AccordionItem>
                
            </Accordion>
        </Flex>
    )
}
