import React, { useContext, useEffect, useRef } from 'react';
import { DataContext } from './dataContext';
import { API } from 'aws-amplify'
import { onUpdateAthlete } from '../graphql/subscriptions'


const SubscriptionProvider = ({children}) => {

	const dataContext = useContext(DataContext);
	const { 
    user, 
    setUser, 
    athletes,
    setAthletes
   } = dataContext;
	
	const updateSubscriptionAthletes = () =>{ 
        try {
            API.graphql({query: onUpdateAthlete,})
            .subscribe({
                next:({ value }) => {
                    // console.log("[SUCCESS] Update athlete subscription:",value)
                    setAthletes((athletes) =>
                        athletes.map((item) => {
                        if(item.id === value.data?.onUpdateAthlete?.id){
                            return {...value.data?.onUpdateAthlete}
                        }
                        return item
                    })
                );
                },
                error: (error) => { throw new Error(error) }
            })
        } catch (error) {
            // console.log("[ERROR] Update athlete subscription:",error)
            throw new Error(error)
        }
	}

  const allAthletes = useRef();

	useEffect(() => {
    if(user && user !== 'notLoggedIn'){
        updateSubscriptionAthletes()
    }
	}, [user])

	return (
		<>
			{children}
		</>
	)
}

export default SubscriptionProvider