import LineChart from 'components/Charts/LineChart';
import React, { useEffect, useState } from 'react'
import { getlineChartOptionsWithColors } from "variables/charts";
import LineChartSummary from '../LineChartSummary';
import { Button, Flex, ScaleFade, Skeleton, Text } from '@chakra-ui/react';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';


const SearchesEnquiriesDone = ({searchTerms,athletesEnquirys, agents}) => {

    const [sportFilter, setSportFilter] = useState({'SearchesEnquiriesCreated':'Total'})
	
	const [hasRender, setRender] = useState(false);
	const [loaded, setLoaded] = useState(false);
  	const onShow = React.useCallback(() => setRender(true), []);

	let lineChartOptionsActivity = getlineChartOptionsWithColors("#81E6D9","#FBD38D")

	useEffect(() => {
		if(hasRender){
			searchTerms = searchTerms.filter(item=>!(agents.find(a=>a.contact_email === item.coach?.contact_email && item.coach?.is_active)))
			athletesEnquirys = athletesEnquirys.filter(item=>!(agents.find(a=>a.contact_email === item.coach?.contact_email && item.coach?.is_active)))
			setLoaded(true)
		}
	}, [hasRender])


    // Get coaches created per month
	const getActivityDonePerMonth = () => {
		const searchCount = []
		const enquiryCount = []
		const monthsNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
		
		searchTerms.filter(item => (item.coach?.sport === sportFilter.SearchesEnquiriesCreated || sportFilter.SearchesEnquiriesCreated === 'Total') && item.coach?.is_active).map(coach => {
			const date = new Date(coach.createdAt)
			const key = `${date.getDate()} ${monthsNames[date.getMonth()]} ${date.getFullYear()}`

			const exist = searchCount.find(item => item.date === key)
			exist ? exist.count += 1 : searchCount.push({date: key,count: 1})
		})

		athletesEnquirys.filter(item => (item.coach?.sport === sportFilter.SearchesEnquiriesCreated || sportFilter.SearchesEnquiriesCreated === 'Total') && item.coach?.is_active).map(athlete => {
			const date = new Date(athlete.createdAt)
			const key = `${date.getDate()} ${monthsNames[date.getMonth()]} ${date.getFullYear()}`

			const exist = enquiryCount.find(item => item.date === key)
			exist ? exist.count += 1 : enquiryCount.push({date: key,count: 1})
		})

		// Put 0 to the missing months
		while(searchCount.length !== enquiryCount.length){
			searchCount.length < enquiryCount.length ? (
				enquiryCount.map(item => {
					const exist = searchCount.find(item2 => item2.date === item.date)
					!exist && searchCount.push({date: item.date,count: 0})
				})
			)
			: (
				searchCount.map(coach => {
					const exist = enquiryCount.find(item => item.date === coach.date)
					!exist && enquiryCount.push({date: coach.date,count: 0})
				})
			)
		}
		// TODO
		lineChartOptionsActivity.xaxis.categories = searchCount.sort((item1,item2)=>new Date(`${item1.date}`) - new Date(`${item2.date}`)).map(item => item.date)
		lineChartOptionsActivity.xaxis.type = 'datetime'
		lineChartOptionsActivity.chart.zoom = {
			type : 'x',
			enabled : true,
			autoScaleYaxis : false
		}
		return [{
			name: "Searches",
			data: searchCount.sort((item1,item2)=>new Date(`${item1.date}`) - new Date(`${item2.date}`)).map(item => item.count)
		},
		{
			name: "Enquiries",
			data: enquiryCount.sort((item1,item2)=>new Date(`${item1.date}`) - new Date(`${item2.date}`)).map(item => item.count)
		}]
	}

    return (
        <>
			{ hasRender && (
				<Skeleton isLoaded={loaded}>
					<ScaleFade in={hasRender} >
						<LineChartSummary 
							title={"Searches & Enquiries done monthly"} 
							chart={<LineChart data={getActivityDonePerMonth()} options={lineChartOptionsActivity}/>} 
							amount={[{
									title: "Searches",
									color:"#81E6D9",
									total: searchTerms.filter(item=>item.coach?.sport === sportFilter.SearchesEnquiriesCreated || sportFilter.SearchesEnquiriesCreated === 'Total').length,
									totalThisYear: searchTerms.filter(item=>(item.coach?.sport === sportFilter.SearchesEnquiriesCreated || sportFilter.SearchesEnquiriesCreated === 'Total') && new Date(item.createdAt).getFullYear() === new Date().getFullYear()).length,
									totalThisMonth: searchTerms.filter(item=>(item.coach?.sport === sportFilter.SearchesEnquiriesCreated || sportFilter.SearchesEnquiriesCreated === 'Total') && new Date(item.createdAt).getMonth() === new Date().getMonth() && new Date(item.createdAt).getFullYear() === new Date().getFullYear()).length,
									totalToday: searchTerms.filter(item=>(item.coach?.sport === sportFilter.SearchesEnquiriesCreated || sportFilter.SearchesEnquiriesCreated === 'Total') && new Date(item.createdAt).toLocaleDateString() === new Date().toLocaleDateString()).length
								},{
									title: "Enquiries",
									color:"#FBD38D",
									total: athletesEnquirys.filter(item=>item.coach?.sport === sportFilter.SearchesEnquiriesCreated || sportFilter.SearchesEnquiriesCreated === 'Total').length,
									totalThisYear: athletesEnquirys.filter(item=>(item.coach?.sport === sportFilter.SearchesEnquiriesCreated || sportFilter.SearchesEnquiriesCreated === 'Total') && new Date(item.createdAt).getFullYear() === new Date().getFullYear()).length,
									totalThisMonth: athletesEnquirys.filter(item=>(item.coach?.sport === sportFilter.SearchesEnquiriesCreated || sportFilter.SearchesEnquiriesCreated === 'Total') && new Date(item.createdAt).getMonth() === new Date().getMonth() && new Date(item.createdAt).getFullYear() === new Date().getFullYear()).length,
									totalToday: athletesEnquirys.filter(item=>(item.coach?.sport === sportFilter.SearchesEnquiriesCreated || sportFilter.SearchesEnquiriesCreated === 'Total') && new Date(item.createdAt).toLocaleDateString() === new Date().toLocaleDateString()).length
								}
							]}
							dataToChange="SearchesEnquiriesCreated"
							sport={sportFilter}
							setSport={setSportFilter}

						/>
					</ScaleFade>
				</Skeleton>
			)}
			{ !hasRender && (
				<ScaleFade in={!hasRender} >
					<Card p='28px 10px 16px 0px'>
						<CardHeader pl='22px' w="100%">
							<Flex direction='row' alignSelf='flex-start' w="100%" justifyContent={'space-between'}>
								<Flex direction='column' alignSelf='flex-start' w="50%" >
									<Text fontSize='lg' color={"gray.700"} fontWeight='bold' mb='6px'>
										Searches & Enquiries done monthly 
									</Text>
									<Text fontSize='md' fontWeight='medium' color='gray.400'>
										Graph showing the distribution of searches and enquiries done by coaches monthly. 
									</Text>
								</Flex>
								<Button w="40%" colorScheme='blue' onClick={onShow} my="10px" mr="5%">
									Load chart
								</Button>
							</Flex>
						</CardHeader>
					</Card>
				</ScaleFade>
			)}
        </>
    )
}

const myPropsChanged = (prevProps, nextProps) => {
    if ( prevProps.searchTerms !== nextProps.searchTerms || prevProps.athletesEnquirys !== nextProps.athletesEnquirys || prevProps.coaches !== nextProps.coaches) {
        return false;
    }
    return true;
}

export default React.memo(SearchesEnquiriesDone, myPropsChanged);