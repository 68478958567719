// React imports
import React, { useContext,useEffect, useState} from 'react'
import { useParams, useNavigate } from "react-router-dom";
// Context imports
import { DataContext } from '../../../../contexts/dataContext'
// Chakra imports
import {
	Table,
	Tbody,
	Text,
	Th,
    Td,
	Thead,
	Tr,
	useColorModeValue,
	Flex,
	Select,
	Avatar,
	Icon,
    Spacer,
} from "@chakra-ui/react";
// Auxiliar functions
import { getSearchedData } from "helpers/getSearchedData";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { Pagination } from "components/Pagination/Pagination";
import {MdManageSearch, MdOutlineFilterList} from 'react-icons/md'
import { FaRegFutbol } from 'react-icons/fa'
import { GiTennisBall } from 'react-icons/gi'
import {BsArrowDown, BsArrowUp} from 'react-icons/bs'

export const Activity = () => {

    // Chakra color mode
	const textColor = useColorModeValue("gray.700", "white");

    // State that control if sort is ascending or descending
	const [ sortAscending, setSortAscending ] = useState(true)


    // Get the id of the coach from the url
	const { coachId } = useParams();
    const navigate = useNavigate();

    // Take the context data needed in this component
    const dataContext = useContext(DataContext)
    const { pagination,updatePagination,coaches, athletes,agencies, athletesEnquirys, feedbacks, coachMessages, coachAthleteFavorites, searchTerms} = dataContext
    const coach = coaches?.find(coach => coach.id === coachId)

	const coachEnquiries = athletesEnquirys?.filter(enq => enq.coachID === coach?.id).map(res => ({...res,typeOfAction: 'Enquiry'})).filter(it => athletes.find(ath => ath.id === it.athleteID))
    const coachFeedbacks = feedbacks?.filter(feed => feed.coachID === coach?.id).map(res => ({...res,typeOfAction: 'Feedback'}))
    const coachMsgs = coachMessages?.filter(msg => msg.coachID === coach?.id).map(res => ({...res,typeOfAction: 'Message'}))
    const coachFavs = coachAthleteFavorites?.filter(fav => fav.coachID === coach?.id).map(res => ({...res,typeOfAction: 'Favorite'})).filter(it => athletes.find(ath => ath.id === it.athleteID))
    const coachSearches = searchTerms?.filter(search => search.coachID === coach?.id).map(res => ({...res,typeOfAction: 'Search'})) 

    const allActions = [...coachEnquiries,...coachFeedbacks,...coachMsgs,...coachFavs, ...coachSearches]

    // console.log("athletes de las acciones:",new Set(allActions.map(it => athletes.find(item => item.id === it.athleteID)?.agency?.name)))
    
    // State with filters
    const [ filters, setFilters ] = useState({
		action:0,
		actionData: ['ALL', 'Enquiry', 'Feedback','Message','Favorite','Search'],
        agency: 0,
        agencyData: ['ALL', 'The Alliance Athletes', ...new Set(allActions.filter(a=>a.athleteID).map(it => athletes.find(item => item.id === it.athleteID)?.agency?.name))]
	})

    const filterData = (data) => {
		let newData = data
		if (filters.action === 0 && filters.agency === 0 ) {newData = data}
		if (filters.action>0){ newData = newData.filter(item => item.typeOfAction === filters.actionData[filters.action]) }
		if (filters.agency===1){ newData = newData.filter(item => !item.athleteID) }
		if (filters.agency>1){ newData = newData.filter(item => athletes.find(it => it.id === item.athleteID)?.agency?.name === filters.agencyData[filters.agency]) }

        return newData.sort((a,b) => {
            if(sortAscending){return new Date(a.createdAt) - new Date(b.createdAt)}
            else{return new Date(b.createdAt) - new Date(a.createdAt)}
        })
	}

	
    useEffect(() => {
		updatePagination({
			total:allActions.length,
			page: 1,
			rowsPerPage: 10,
			lower: 1})
	}, [])

    return (
        <>
            {/* Check if the context is loaded */}
			{(coaches.length + athletes.length + agencies.length + searchTerms.length + athletesEnquirys.length + feedbacks.length + coachMessages.length + coachAthleteFavorites.length) > 0 && (
				// Check if the coach is found
				coach ? (
					<>
					    <Flex pt={{ base: "130px", md: "65px" }}>
                            <Card overflowX={{ sm: "scroll", xl: "hidden" }} >
                                <CardHeader p='6px 0px 22px 0px'>
                                    <Flex direction='row' w="100%">
                                        <Flex justifyContent='left' w="100%">
                                            <Flex direction="column">
                                                <Text fontSize='lg' color={textColor} fontWeight='bold'>
                                                    {coach?.name} ({filterData(allActions).length} actions)
                                                </Text>
                                                <Text fontSize='sm' color="gray.400" >
                                                    {coach.contact_email}
                                                </Text>
                                                <Text fontSize='sm' color="gray.400" >
                                                    {coach.team?.name} | {coach.team?.genre}'s team
                                                </Text>
                                            </Flex>
                                            <Spacer />
                                            <Flex direction="column" alignItems="end" w="20%">
                                                <Text 
                                                    color='royalblue.700' 
                                                    m="10px 10% 0 0" 
                                                    cursor="pointer" 
                                                    onClick={()=>(setFilters({...filters, action:0}))}
                                                    fontSize="xs"
                                                ><i> Reset all filters </i></Text>
                                            </Flex>
                                        </Flex>
                                    </Flex>
                                </CardHeader>
                                <CardBody>
                                    <Table variant='simple' color={textColor}>
                                        <Thead>
                                            <Tr my='.8rem' pl='0px' color='gray.400'>
                                                <Th color='gray.400' cursor="pointer" onClick={()=>setSortAscending(!sortAscending)}>
                                                    Date {sortAscending ? <Icon as={BsArrowUp} color="gray.400" /> : <Icon as={BsArrowDown} color="gray.400" />}
                                                </Th>
                                                <Th color='gray.400' minW="170px">
                                                    Type of action
                                                    <Select variant='unstyled' size="xs" value={filters.action} onChange={(e)=>setFilters({...filters, action:parseInt(e.target.value)})}>
                                                        {filters.actionData.map((opt,index) => {return (<option key={index} value={index} color="royalblue">{opt}</option>)})}
                                                    </Select>
                                                </Th>
                                                <Th color='gray.400'>
                                                    Recipient
                                                    <Select variant='unstyled' size="xs" value={filters.agency} onChange={(e)=>setFilters({...filters, agency:parseInt(e.target.value)})}>
                                                        {filters.agencyData.map((opt,index) => {return (<option key={index} value={index} color="royalblue">{opt}</option>)})}
                                                    </Select>
                                                </Th>
                                                <Th color='gray.400'>
                                                    Text
                                                </Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                                {
                                                    filterData(allActions).slice(pagination.lower-1,pagination.lower+pagination.rowsPerPage-1).map( (action,idx) => {
                                                        return (
                                                            <Tr key={idx} my='.8rem' pl='0px'>
                                                                <Td w="10%" fontSize="sm" color="gray.400" fontWeight="normal">
                                                                    {new Date(action.createdAt).toLocaleDateString()}
                                                                </Td>
                                                                <Td w="10%" fontSize="sm" color="gray.400" fontWeight="normal">
                                                                    {action.typeOfAction}
                                                                </Td>
                                                                <Td w="20%" fontSize="sm" color="gray.400" fontWeight="normal">
                                                                    {/* {action.type === 'Enquiry' && (athletes.find(ath => ath.id === action.athleteID)?.name)} */}
                                                                    {action.typeOfAction === 'Message' && (
                                                                        <Flex direction="row" alignItems="center">
                                                                            <Avatar src={agencies.find(ag => ag.id === action.agencyID)?.logo} size="lg" me="18px" />
                                                                            <Flex direction="column">
                                                                                <Text fontSize="sm" color="gray.500" fontWeight="normal">{agencies.find(ag => ag.id === action.agencyID)?.name}</Text>
                                                                                <Text fontSize="sm" color="gray.400" fontWeight="normal">{agencies.find(ag => ag.id === action.agencyID)?.contact_email}</Text>
                                                                            </Flex>
                                                                        </Flex>
                                                                    
                                                                    )}
                                                                    {(action.typeOfAction === 'Favorite' || action.typeOfAction === 'Enquiry') && (
                                                                        <Flex direction="row" alignItems="center">
                                                                            <Avatar src={athletes.find(ath => ath.id === action.athleteID)?.profile_pic}size="lg" me="18px" />
                                                                            <Flex direction="column">
                                                                                <Text fontSize="sm" color="gray.500" fontWeight="normal">{athletes.find(ath => ath.id === action.athleteID)?.name}</Text>
                                                                                <Text fontSize="sm" color="gray.400" fontWeight="normal">{athletes.find(ath => ath.id === action.athleteID)?.agency?.name}</Text>
                                                                            </Flex>
                                                                        </Flex>
                                                                    )}
                                                                    {action.typeOfAction === 'Feedback' || action.typeOfAction === 'Search' && ('The Alliance Athletes')}
                                                                </Td>
                                                                <Td w="60%" fontSize="sm" color="gray.400" fontWeight="normal">
                                                                    {action.typeOfAction === 'Feedback' && (action.feedback_text ? action.feedback_text : 'No feedback text provided')}
                                                                    {action.typeOfAction === 'Enquiry' && (action.enquiry_text ? action.enquiry_text : 'No enquiry text provided')}
                                                                    {action.typeOfAction === 'Message' && (action.message ? action.message : 'No message text provided')}
                                                                    {action.typeOfAction === 'Favorite' && ("This action doesn't need text")}
                                                                    {action.typeOfAction === 'Search' && (
                                                                        <Flex direction="row" alignItems="center">
                                                                        <Icon as={action.sport === 'Tennis' ? GiTennisBall : FaRegFutbol} color={action.sport === 'Tennis' ? 'green' : 'blue'} w="50px" borderRadius="12px" me="5px" />
                                                                        <Flex direction="column">
                                                                            <Text fontSize="sm" color="gray.500" fontWeight="normal">{action.search_term_text ? action.search_term_text : 'No searc text provided'}</Text>
                                                                            <Text fontSize="sm" color="gray.400" fontWeight="normal">{action.search_type === 'FILTER_SEARCH' ? 'Filters' : 'Search text'}</Text>
                                                                            <Text fontSize="sm" color="gray.400" fontWeight="normal">{action.source_app === 'WebApp' ? 'Web version' : 'App version'}</Text>
                                                                        </Flex>
                                                                    </Flex>
                                                                        
                                                                    
                                                                    )}
                                                                </Td>
                                                            </Tr>       
                                                        )
                                                    })
                                                }
                                        </Tbody>
                                    </Table>
                                </CardBody>
                                <Pagination totalData={filterData(allActions).length} rowsPerPageValues={[10,20,30,40,50]} />
                            </Card>

                        </Flex>
					</>
				) :(
					navigate(-1)
				)
			)}
            
        </>

    )
}
