import React, { useContext, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
// Context imports
import { DataContext } from "../../../../contexts/dataContext";
// Chakra imports
import {
	Image,
	Box,
	Flex,
	Text,
	useColorModeValue,
} from "@chakra-ui/react";
// Assets
import backgroundHeader from "assets/img/ProfileBackground.png";


export const Header = ({agentProfile,coachProfile}) => {

    const borderProfileColor = useColorModeValue("white","rgba(255, 255, 255, 0.31)");
    const textColor = useColorModeValue("gray.700", "white");
    const emailColor = useColorModeValue("gray.500", "white");
    const backgroundProfile = useColorModeValue("hsla(0,0%,100%,.8)","linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)");

    return (
        <>
            <Box
				mb={{ sm: "150px", md: "100px", xl: "100px" }}
				borderRadius='15px'
				px='0px'
				display='flex'
				flexDirection='column'
				justifyContent='center'
				align='center'
			>
				<Box
				bgImage={backgroundHeader}
				bgSize='cover'
				w='100%'
				h='275px'
				borderRadius='25px'
				bgPosition='50%'
				bgRepeat='no-repeat'
				position='relative'
				display='flex'
				justifyContent='center'>
					<Flex
						direction={{ sm: "column", md: "row" }}
						mx='1.5rem'
						maxH='330px'
						w={{ sm: "90%", xl: "95%" }}
						justifyContent={{ sm: "center", md: "space-between" }}
						align='center'
						backdropFilter='saturate(200%) blur(50px)'
						position='absolute'
						boxShadow='0px 2px 5.5px rgba(0, 0, 0, 0.02)'
						border='2px solid'
						borderColor={borderProfileColor}
						bg={backgroundProfile}
						p='24px'
						borderRadius='20px'
						transform={{
                            sm: 'translateY(50%)',
							md: "translateY(100%)",
							lg: "translateY(100%)",
						}}
					>
						
						<Flex direction='column'>

							<Flex
								align='center'
								mb={{ sm: "10px", md: "0px" }}
								direction={{ sm: "column", md: "row" }}
								w={{ sm: "100%" }}
								textAlign={{ sm: "center", md: "start" }}
							>
                                <Image
                                    me={{ md: "22px" }}
                                    src={agentProfile?.profile_pic}
                                    w='120px'
                                    h='120px'
                                    borderRadius='15px'
                                    fit='cover'
                                />	
								<Flex direction='column' maxWidth='100%' my={{ sm: "10px" }} mx={{sm:"10px"}} >
									<Text
									fontSize={{ sm: "md", lg: "lg" }}
									color={textColor}
									fontWeight='bold'
									ms={{ sm: "8px", md: "0px" }}>
										{agentProfile?.name}
									</Text>
									<Text
									fontSize={{ sm: "sm", md: "md" }}
									color={emailColor}
									fontWeight='semibold'>
										{agentProfile?.contact_email}
									</Text>
									<Text
									fontSize={{ sm: "sm", md: "md" }}
									color={emailColor}
									fontWeight='semibold'>
                                        {agentProfile?.contact_phone}
									</Text>
									
								</Flex>
							</Flex>
						</Flex>
					</Flex>
				</Box>
			</Box>
        </>
    )
}
