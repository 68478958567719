import { Box, Flex, Spinner, Text, useColorModeValue } from '@chakra-ui/react';
import { DataContext } from 'contexts/dataContext';
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { getAthleteService } from 'services/athleteServices';

import UniqueAthleteInfo from './components/Athletes/UniqueAthleteInfo';
import ErrorPage from './ErrorPage';
import { API } from 'aws-amplify'
import { onUpdateAthlete } from '../../graphql/subscriptions'
import { onUpdateAthleteById } from 'custom_graphql_queries/athletes';


const ShowUniqueAthlete = () => {


    // get athlete id from url
    const { athleteId } = useParams();

    const dataContext = useContext(DataContext)

    const [athlete, setAthlete] = useState(null)
    const [userData, setUserData] = useState(null)
    const userGroup = userData?.signInUserSession.idToken.payload["cognito:groups"][0]

    const updateSubscriptionAthleteById = () =>{ 
        try {
            API.graphql({query: onUpdateAthleteById,variables: {id: athleteId}})
            .subscribe({
                next:({ value }) => {
                    // setAthlete({...value.data?.onUpdateAthleteById});
                    getAthleteService(athleteId).then(res => {setAthlete(res.data.getAthlete)})
                },
                error: (error) => { throw new Error(error) }
            })
        } catch (error) {
            // console.log("[ERROR] Update athlete subscription:",error)
            throw new Error(error)
        }
	}

    useEffect(() => {
        if(dataContext.user && !athlete){
            setUserData(dataContext.user)
        }
    }, [dataContext.user])

    useEffect(() => {
        if(athlete === null && athleteId){
            getAthleteService(athleteId).then(res => {setAthlete(res.data.getAthlete)})
        }
        if(athleteId){
            updateSubscriptionAthleteById()
        }
    }, [])

    return (
        athlete ? (
            <UniqueAthleteInfo athlete={athlete} allowUser={userGroup === athlete.agencyID || userGroup === "superadmins"} />
        ) : (
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100vh"
                width="100vw"
                backgroundColor="royalblue"
            >
                <Flex 
                    direction='column'
                    alignItems="center"
                >
                <Spinner size='xl' thickness="5px" color="white" />
                <Text
                    fontSize="xl"
                    fontWeight="bold"
                    color="white"
                    letterSpacing="wide"
                    margin={2}
                >
                    Loading athlete information...
                </Text>
                </Flex>
            </Box>
        )
    )
}

export default ShowUniqueAthlete