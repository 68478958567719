// React imports
import React, { useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom';
// Context imports
import { DataContext } from "../../../../contexts/dataContext";
// Chakra imports
import { Flex, useColorModeValue } from "@chakra-ui/react";
// Component and assets
import ProfileBgImage from "assets/img/ProfileBackground.png";
import Header from './Header'
import ParticipantsTable from './ParticipantsTable';


export const ShowShowcase = () => {
	// Chakra color mode
	const bgProfile = useColorModeValue(
		"hsla(0,0%,100%,.8)",
		"linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
	);

	// Get the id of the showcase from the url					
	const { showcaseID } = useParams();

  	// Take the context data needed in this component
    const dataContext = useContext(DataContext)
    const { showcases, showcasesAthleteRelation,showcasesCoachRelation, athletes,agencies,agents } = dataContext
    // Showcase that we're showing
    const showcase = showcases.find(show => show.id === showcaseID)
    // Associated athletes to the showcase
    const athletesOfShowcase = athletes.filter(ath => showcasesAthleteRelation.find(showAth => showAth.athleteID === ath.id && showAth.showcaseID === showcase.id))
    // const coachesOfShowcase = coaches.filter(coach => showcasesCoachRelation.find(showCoach => showCoach.coachID === coach.id && showCoach.showcaseID === showcase.id))

	useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

	return (
		<Flex direction='column' pt={{ base: "120px", md: "75px" }}>
			{/* Check if the context is loaded */}
			{(showcases.length+showcasesAthleteRelation.length+showcasesCoachRelation.length) > 0 && (
                <>
                    <Header
                        backgroundHeader={ProfileBgImage}
                        backgroundProfile={bgProfile}
                        id={showcase.id}
                        name={showcase.name}
                        description={showcase.description}
                        country={showcase.contry}
                        address={showcase.address}
                        date={showcase.date}
                    />

                    <Flex mt={{sm:"20px",md:"30px",lg:"40px"}}>

                        <ParticipantsTable
                            title='Athletes'
                            subtitle='These are the athletes who will go to the showcase.'
                            typeOfParticipant='Athlete'
                            showcase={showcase}
                            participants={athletesOfShowcase.map(it => { return {
                                id:it.id,
                                name:it.name,
                                profile_pic:it.profile_pic,
                                contact_email:it.contact_email,
                                agency:it.agency?.name ? it.agency?.name : agencies.find(ag => ag.id === it.agencyID),
                                agencyID:it.agencyID,
                                agentID:it.agentID,
                                agent:agents.find(a => a.id === it.agentID),
                                sport:it.sport,
                                isVisible:it.isVisible,
                            }})}
                        />
                    </Flex>
                    
                </>
			)}
		
		</Flex>
		
	);
}
