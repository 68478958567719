// React imports
import React, { useContext, useEffect } from "react";
import { useParams, Navigate } from "react-router-dom";
// Context imports
import { DataContext } from "../../../../contexts/dataContext";
// Chakra imports
import { Flex, SimpleGrid, Text, useColorModeValue } from "@chakra-ui/react";
// Components and assets
import ProfileBgImage from "assets/img/ProfileBackground.png";
import TeamsTables from "../../Teams/components/TeamsTables";
import Header from "./Header";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";

export const ShowCollege = () => {
  const bgProfile = useColorModeValue(
    "hsla(0,0%,100%,.8)",
    "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
  );
  // Get the id of the college from the url
  const { collegeId } = useParams();
  // Take the context data needed in this component
  const dataContext = useContext(DataContext);
  const { colleges, auxCoaches } = dataContext;
  const college = colleges.find((college) => college.id === collegeId);
  const auxDataCollege = auxCoaches.find((it) =>
    college.name.includes(it.school)
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      {/* Check if the context is loaded */}
      {colleges?.length > 0 &&
        // Check if the college is found
        (college ? (
          <>
            <Header
              id={college.id}
              backgroundHeader={ProfileBgImage}
              backgroundProfile={bgProfile}
              name={college.name}
              logo={college.logo}
              state={college.state}
              teams={college.teams?.items}
              country={college.country}
            />

            {auxDataCollege && (
              <Card
                overflowX={{ sm: "scroll", xl: "hidden" }}
                mt={{ base: "100px", md: "20px" }}
              >
                <CardHeader p="6px 0px 15px 0px">
                  <Flex direction="row" w="100%">
                    <Text
                      fontSize="xl"
                      color={useColorModeValue("gray.700", "white")}
                      fontWeight="bold"
                    >
                      Aditional information
                    </Text>
                  </Flex>
                </CardHeader>
                <CardBody>
                  <SimpleGrid
                    columns={{ sm: 1, md: 2, lg: 3 }}
                    spacing="24px"
                    w="100%"
                  >
                    <Flex direction="column">
                      <Text fontSize="md" color="gray.400" fontWeight="normal">
                        State
                      </Text>
                      <Text fontSize="md" color="gray.700" fontWeight="normal">
                        {auxDataCollege.state !== "<empty>"
                          ? auxDataCollege.state
                          : "Not specified"}
                      </Text>
                    </Flex>
                    <Flex direction="column">
                      <Text fontSize="md" color="gray.400" fontWeight="normal">
                        City
                      </Text>
                      <Text fontSize="md" color="gray.700" fontWeight="normal">
                        {auxDataCollege.city !== "<empty>"
                          ? auxDataCollege.city
                          : "Not specified"}
                      </Text>
                    </Flex>
                    <Flex direction="column">
                      <Text fontSize="md" color="gray.400" fontWeight="normal">
                        Size of city
                      </Text>
                      <Text fontSize="md" color="gray.700" fontWeight="normal">
                        {auxDataCollege.size_of_city !== "<empty>"
                          ? auxDataCollege.size_of_city
                          : "Not specified"}
                      </Text>
                    </Flex>
                    <Flex direction="column">
                      <Text fontSize="md" color="gray.400" fontWeight="normal">
                        Public/Private
                      </Text>
                      <Text fontSize="md" color="gray.700" fontWeight="normal">
                        {auxDataCollege.private_or_public !== "<empty>"
                          ? auxDataCollege.private_or_public
                          : "Not specified"}
                      </Text>
                    </Flex>
                    <Flex direction="column">
                      <Text fontSize="md" color="gray.400" fontWeight="normal">
                        Religious affiliation
                      </Text>
                      <Text fontSize="md" color="gray.700" fontWeight="normal">
                        {auxDataCollege.religious !== "<empty>"
                          ? auxDataCollege.religious
                          : "Not specified"}
                      </Text>
                    </Flex>
                    <Flex direction="column">
                      <Text fontSize="md" color="gray.400" fontWeight="normal">
                        Average GPA
                      </Text>
                      <Text fontSize="md" color="gray.700" fontWeight="normal">
                        {auxDataCollege.average_gpa !== "<empty>"
                          ? auxDataCollege.average_gpa
                          : "Not specified"}
                      </Text>
                    </Flex>
                    <Flex direction="column">
                      <Text fontSize="md" color="gray.400" fontWeight="normal">
                        SAT-Reading (25th-75th percentile)
                      </Text>
                      <Text fontSize="md" color="gray.700" fontWeight="normal">
                        {auxDataCollege.sat_reading !== "<empty>"
                          ? auxDataCollege.sat_reading
                          : "Not specified"}
                      </Text>
                    </Flex>
                    <Flex direction="column">
                      <Text fontSize="md" color="gray.400" fontWeight="normal">
                        SAT-Math
                      </Text>
                      <Text fontSize="md" color="gray.700" fontWeight="normal">
                        {auxDataCollege.sat_math !== "<empty>"
                          ? auxDataCollege.sat_math
                          : "Not specified"}
                      </Text>
                    </Flex>
                    <Flex direction="column">
                      <Text fontSize="md" color="gray.400" fontWeight="normal">
                        ACT composite
                      </Text>
                      <Text fontSize="md" color="gray.700" fontWeight="normal">
                        {auxDataCollege.act_composite !== "<empty>"
                          ? auxDataCollege.act_composite
                          : "Not specified"}
                      </Text>
                    </Flex>
                    <Flex direction="column">
                      <Text fontSize="md" color="gray.400" fontWeight="normal">
                        Acceptance rate
                      </Text>
                      <Text fontSize="md" color="gray.700" fontWeight="normal">
                        {auxDataCollege.acceptance_rate !== "<empty>"
                          ? auxDataCollege.acceptance_rate
                          : "Not specified"}
                      </Text>
                    </Flex>
                    <Flex direction="column">
                      <Text fontSize="md" color="gray.400" fontWeight="normal">
                        Total yearly cost
                      </Text>
                      <Text fontSize="md" color="gray.700" fontWeight="normal">
                        {auxDataCollege.total_yearly_cost !== "<empty>"
                          ? auxDataCollege.total_yearly_cost
                          : "Not specified"}
                      </Text>
                    </Flex>
                    <Flex direction="column">
                      <Text fontSize="md" color="gray.400" fontWeight="normal">
                        No of undergrads
                      </Text>
                      <Text fontSize="md" color="gray.700" fontWeight="normal">
                        {auxDataCollege.number_of_undergrads !== "<empty>"
                          ? auxDataCollege.number_of_undergrads
                          : "Not specified"}
                      </Text>
                    </Flex>
                    <Flex direction="column">
                      <Text fontSize="md" color="gray.400" fontWeight="normal">
                        U.S. News ranking
                      </Text>
                      <Text fontSize="md" color="gray.700" fontWeight="normal">
                        {auxDataCollege.us_ranking !== "<empty>"
                          ? auxDataCollege.us_ranking
                          : "Not specified"}
                      </Text>
                    </Flex>
                    <Flex direction="column">
                      <Text fontSize="md" color="gray.400" fontWeight="normal">
                        U.S. Liberal arts News ranking
                      </Text>
                      <Text fontSize="md" color="gray.700" fontWeight="normal">
                        {auxDataCollege.us_arts_ranking !== "<empty>"
                          ? auxDataCollege.us_arts_ranking
                          : "Not specified"}
                      </Text>
                    </Flex>
                  </SimpleGrid>
                </CardBody>
              </Card>
            )}

            {college?.teams?.items?.length > 0 && (
              <TeamsTables
                teams={college?.teams?.items}
                showMoreInfo={false}
                withMargin={auxDataCollege ? true : false}
              />
            )}
          </>
        ) : (
          <Navigate to={"/admin/colleges"} replace />
        ))}
    </Flex>
  );
};
