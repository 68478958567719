import React, { useContext, useEffect } from 'react'
import { Routes, Route } from 'react-router-dom';
import AthletesTables from './components/AthletesTables';
import { DataContext } from '../../../contexts/dataContext'
import { ShowAthlete } from './components/ShowAthlete';
import { EditAthlete } from './components/EditAthlete';
import { CreateAthlete } from './components/CreateAthlete';

export const AthletesRoutes = () => {
    const dataContext = useContext(DataContext)
    const { athletes } = dataContext

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <Routes>
                <Route path="/" element={<AthletesTables athletes={athletes} showMoreInfo={true} />} />
                <Route path="/show/:athleteId" element={<ShowAthlete />} />
                <Route path="/edit/:athleteId" element={<EditAthlete navigatePath="/admin/athletes/show"/>} />
                <Route path="/create" element={<CreateAthlete />} />
            </Routes>
        </>
    )
}
