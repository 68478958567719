// React imports
import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom'
// Chakra imports
import { Image,	Flex,Td,Text,Tr,useColorModeValue,Checkbox, Avatar } from "@chakra-ui/react";
import { positionHumanize } from "helpers";
import { useState } from "react";


function AthletesTableRow({ id, profile_pic, name, start_date, sport, position, currentStatus,agency,agent, selectItem, setSelectItem, visible }) {
	// Chakra color mode
	const textColor = useColorModeValue("gray.700", "white");
	// navigate hook
	let navigate = useNavigate()

	// Function that handles the selection of an item
	const handleSelectItem = (e) => {
		setSelectItem(e.target.checked ? [...selectItem, {id, name, agencyID: agency.id}] : selectItem.filter(item => item.id !== id))
	}

	const [isSelected, setIsSelected] = useState(false)
	useEffect(() => {
		selectItem?.find((item) => item.id === id) ? setIsSelected(true) : setIsSelected(false)
	}, [selectItem])
	
	

	return (
		<Tr>
			{
				setSelectItem && (
					<Td>
						<Checkbox colorScheme='blue' onChange={(e)=>(handleSelectItem(e))} isChecked={isSelected} />
					</Td>
				)
			}
			{/* First column */}
			{/* They want to open the athlete in other tab */}
			<Td minWidth={{ sm: "100px" }} pl="0px" onClick={()=>navigate(`/admin/athletes/show/${id}`)} style={{cursor:"pointer"}}>
				<Flex align="center" py=".2rem" minWidth="100%" flexWrap="nowrap">
					<Avatar src={profile_pic} size="xl" bg="royalblue.700" />
				</Flex>
			</Td>
			{/* Second column */}
			{/* They want to open the athlete in other tab */}
			{/* onClick={()=>(navigate(`/admin/athletes/show/${id}`))} */}
			<Td maxWidth={{ sm: "170px" }} onClick={()=>navigate(`/admin/athletes/show/${id}`)} style={{cursor:"pointer"}}>
				<Text
					fontSize="sm"
					color={textColor}
					fontWeight="bold"
					minWidth="100%"
				>
					{name}
				</Text>
			</Td>
			<Td maxWidth={{ sm: "150px" }} onClick={()=>(navigate(`/admin/agencies/show/${agency.id}`))} style={{cursor:"pointer"}}>
				<Text fontSize="sm" color="gray.400" fontWeight="normal">
					{agency.name}
				</Text>
			</Td>

			{
				agent || start_date || sport || position || currentStatus || visible
				? (
					<>
						<Td maxWidth={{ sm: "100px" }} >
							<Text fontSize="sm" color="gray.400" fontWeight="normal">
								{agent?.name}
							</Text>
						</Td>
						<Td maxWidth={{ sm: "150px" }} >
							<Text fontSize="sm" color="gray.400" fontWeight="normal">
								{start_date}
							</Text>
						</Td>
						<Td maxWidth={{ sm: "150px" }}>
							<Text fontSize="sm" color="gray.400" fontWeight="normal">
								{sport}
							</Text>
						</Td>
						<Td maxWidth={{ sm: "150px" }}>
							<Text fontSize="sm" color="gray.400" fontWeight="normal">
								{positionHumanize(position)}
							</Text>
						</Td>
						<Td maxWidth={{ sm: "200px" }}>
							<Text fontSize="sm" color="gray.400" fontWeight="normal">
								{currentStatus}
							</Text>
						</Td>
						<Td maxWidth={{ sm: "150px" }}>
							<Text fontSize="sm" color="gray.400" fontWeight="normal">
								{visible}
							</Text>
						</Td>
					</>
				)
				: (
					<Td maxWidth={{ sm: "150px" }} onClick={()=>(navigate(`/admin/agents/show/${agent.id}`))} style={{cursor:"pointer"}}>
						<Text fontSize="sm" color="gray.400" fontWeight="normal">
							{agent?.name}
						</Text>
					</Td>
				)
			}
			

		</Tr>
	);
}

export default AthletesTableRow;
