import React from 'react'
// Chackra Components
import { Flex,useColorModeValue, Text, InputGroup,Spacer, FormControl,FormErrorMessage, Select, Icon, Tooltip } from "@chakra-ui/react";
import { categories } from 'helpers';
import { GoAlert } from 'react-icons/go';
import { CategoryInfoAccordion } from '../components/CategoryInfoAccordion';

const CategorizationField = ({formValues, setFormValues, teams, athletes, gender}) => {
	const textColor = useColorModeValue("gray.700", "white");

    const categoryExampleTeams = teams.filter(it=>((it.college_category === formValues) && (gender && it.genre === gender === 'male' ? 'Men':'Women'))).slice(0,5)
    const athletesWithSameCategory = athletes.filter( it =>formValues!== '' && it.category === formValues && it.current_status === 'COMMITTED' && it.destination_college ).slice(0,5)


    return (
        <>
            <Flex direction="column" w="100%">
                <Flex direction="column" w="80%" mt="20px">
                    {/* <FormControl isInvalid={formValues === ""} mb="30px"> */}
                        <Flex direction="row" alignItems="center">
                            <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mb="5px" fontWeight='semibold' textAlign="justify" mr="5px">
                                Category
                            </Text>
                            {/* <Tooltip label={`This field is required.`} bg='orange.300'  placement='auto' >
                                <span><Icon as={GoAlert} color="orange"/></span>
                            </Tooltip> */}
                        </Flex>
                        <Text fontSize={{ sm: "sm", lg: "sm" }} color={"gray.400"}  textAlign="justify" mb="10px">
                            College sport programs are categorized based on their academic and athletic profile based on the experience the agencies have. This categorization can always be adjusted based on performance. Prospective student-athletes can be categorize based on their academics and athletics as well so that the platform can make their information more visible to the coaches from schools with a similar category. 
                            <br />
                            <br />
                            PSA categorization can also change during the process based on the academic and athletic performance on and off the field so it is important to always keep this field updated.
                        </Text>
                        
                        <InputGroup>
                            <Flex w="50%" direction="row" alignItems="center"> 
                                <Select placeholder='Select a category' variant='flushed' size="md" value={formValues} onChange={(e)=>setFormValues(e.target.value)}>
                                    {categories.map((opt,index) => {return (<option key={index} value={opt.value} color="royalblue">{opt.label}</option>)})}
                                </Select>
                            </Flex>
                        </InputGroup>
                        {/* {formValues === "" ? <FormErrorMessage>This field is mandatory</FormErrorMessage> : null} */}
                    {/* </FormControl> */}

                    <CategoryInfoAccordion gender={gender} athletesWithSameCategory={athletesWithSameCategory} categoryExampleTeams={categoryExampleTeams} />
                    
                </Flex>
            </Flex>
        </>
    )
}

const myPropsChanged = (prevProps, nextProps) => {
    if ( prevProps.formValues !== nextProps.formValues) {
        return false;
    }
    return true;
}

export default React.memo(CategorizationField, myPropsChanged);