// React imports
import React, { useContext, useEffect } from 'react'
import { Navigate, useParams } from 'react-router-dom';
// Context imports
import { DataContext } from "../../../../contexts/dataContext";
// Chackra imports
import { Flex, useColorModeValue } from "@chakra-ui/react";
// Component and assets
import Header from './Header'
import AgentsTables from '../../Agents/components/AgentsTables';
import ProfileBgImage from "assets/img/ProfileBackground.png";



export const ShowAgency = () => {

	const bgProfile = useColorModeValue(
		"hsla(0,0%,100%,.8)",
		"linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
	);
	
	// Get the id of the agency from the url 
	const { agencyId } = useParams();

  	// Take the context data needed in this component
	const dataContext = useContext(DataContext)

    const { agencies } = dataContext
    const agency = agencies.find(agency => agency.id === agencyId)

	useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


	return (
		<>
			<Flex direction='column' pt={{ base: "120px", md: "75px" }}>
				{/* Check if the context is loaded */}
				{ agencies.length > 0 && (
					// Check if the agency is found
					agency 
					? (
						<>
							<Header
								id={agency.id}
								backgroundHeader={ProfileBgImage}
								backgroundProfile={bgProfile}
								logo={agency.logo}
								name={agency.name}
								email={agency.contact_email}
								phone={agency.contact_phone}
								country={agency.country}
								web={agency.website}
							/>
							{
								agency?.agents?.items.length > 0 &&
								<AgentsTables agents={agency.agents.items} showAgency={false} />
							}
						</>
					):(
						<Navigate to={'/admin/agencies'} replace />
					)
				)}
				
			</Flex>
		</>
	);
}
