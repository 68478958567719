export const getCoach = /* GraphQL */ `
  query GetCoach($id: ID!) {
    getCoach(id: $id) {
	id
	name
	teamID
  contact_email
  is_active
  division
	team {
		id
		name
		sport
    suscription_name
    suscription_recurrency
    suscription_status
    suscription_premium_start_date
    suscription_premium_end_date
    college_category
    url_domain
    had_trial
		college {
			name
			country
			state
		}
	}
	favorites {
		items {
			id
			athleteID
			
		}
		nextToken
	}
	}
}
`
export const createCoach = /* GraphQL */ `
  mutation CreateCoach(
    $input: CreateCoachInput!
    $condition: ModelCoachConditionInput
  ) {
    createCoach(input: $input, condition: $condition) {
      id
      name
      profile_pic
      birthdate
      gender
      contact_email
      contact_phone
      disabledByUser
      deleteAppReason
      division
      role
      teamID
      createdAt
      updatedAt
    }
  }
`;
export const updateCoach = /* GraphQL */ `
  mutation UpdateCoach(
    $input: UpdateCoachInput!
    $condition: ModelCoachConditionInput
  ) {
    updateCoach(input: $input, condition: $condition) {
      id
      name
      profile_pic
      birthdate
      gender
      division
      contact_email
      contact_phone
      disabledByUser
      deleteAppReason
      role
      teamID
    }
  }
`;
export const deleteCoach = /* GraphQL */ `
  mutation DeleteCoach(
    $input: DeleteCoachInput!
    $condition: ModelCoachConditionInput
  ) {
    deleteCoach(input: $input, condition: $condition) {
      id
      name
      profile_pic
      birthdate
      gender
      division
      contact_email
      contact_phone
      disabledByUser
      deleteAppReason
      role
      teamID
      createdAt
      updatedAt
    }
  }
`;


// Methods for actions on the app
export const createCoachMessage = /* GraphQL */ `
  mutation CreateCoachMessage(
    $input: CreateCoachMessageInput!
  ) {
    createCoachMessage(input: $input) {
      id
	}
}
`
export const createCoachAthleteFavorites = /* GraphQL */ `
mutation CreateCoachAthleteFavorites(
  $input: CreateCoachAthleteFavoritesInput!
) {
  createCoachAthleteFavorites(input: $input) {
	id
	athleteID
	coachID
  }
}
`
export const deleteCoachAthleteFavorites = /* GraphQL */ `
  mutation DeleteCoachAthleteFavorites(
    $input: DeleteCoachAthleteFavoritesInput!
  ) {
    deleteCoachAthleteFavorites(input: $input) {
      id
      athleteID
      coachID
	}
}
`
export const createSearchTerm = /* GraphQL */ `
  mutation CreateSearchTerm(
    $input: CreateSearchTermInput!
  ) {
    createSearchTerm(input: $input) {
      id
	}
}
`

export const disableCoachAccount = /* GraphQL */ `
  mutation UpdateCoach(
    $input: UpdateCoachInput!
    $condition: ModelCoachConditionInput
  ) {
    updateCoach(input: $input, condition: $condition) {
		disabledByUser
		deleteAppReason
    }
  }
`;

export const listCoachMessages = /* GraphQL */ `
  query ListCoachMessages(
    $filter: ModelCoachMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCoachMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        message
        agencyID
        coachID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listCoachAthleteFavoritess = /* GraphQL */ `
  query ListCoachAthleteFavoritess(
    $filter: ModelCoachAthleteFavoritesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCoachAthleteFavoritess(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        athleteID
        coachID
        coach {
          id
          name
          gender
          is_active
          division
          contact_email
          contact_phone
          role
          teamID
          team {
            name
          }
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const createNotification = /* GraphQL */ `
  mutation CreateNotification(
    $input: CreateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    createNotification(input: $input, condition: $condition) {
      id
      text
      timestamp
      type
      pic
      attachment
      coachID
    }
  }
`;


export const listAuxCollegeCoachInfo = `
  query listAuxCollegeCoachInfos(
    $filter: ModelAuxCollegeCoachInfoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAuxCollegeCoachInfos(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        conference
        state
        division
        uniqueID
        sport
        added
        removed
        school
        first_name
        last_name
        position
        contact_email
        contact_phone
        landing_pages
        individual_twitter
        team_twitter
        individual_facebook
        team_facebook
        individual_instagram
        team_instagram
        questionnaire
        school2
        state2
        city
        region
        size_of_city
        private_or_public
        religious
        hbcu
        average_gpa
        sat_reading
        sat_math
        act_composite
        acceptance_rate
        total_yearly_cost
        school3
        majors_offered
        number_of_undergrads
        us_ranking
        us_arts_ranking
        ipeds
      }
      nextToken
    }
  }
`;

export const deleteCoachMessage = /* GraphQL */ `
  mutation DeleteCoachMessage(
    $input: DeleteCoachMessageInput!
    $condition: ModelCoachMessageConditionInput
  ) {
    deleteCoachMessage(input: $input, condition: $condition) {
      id
      message
      agencyID
      coachID
    }
  }
`;

export const deleteCoachNotificationRelation = /* GraphQL */ `
  mutation DeleteCoachNotificationRelation(
    $input: DeleteCoachNotificationRelationInput!
    $condition: ModelCoachNotificationRelationConditionInput
  ) {
    deleteCoachNotificationRelation(input: $input, condition: $condition) {
      id
      is_viewed
      status
      notificationID
      coachID
    }
  }
`;
export const deleteFeedback = /* GraphQL */ `
  mutation DeleteFeedback(
    $input: DeleteFeedbackInput!
    $condition: ModelFeedbackConditionInput
  ) {
    deleteFeedback(input: $input, condition: $condition) {
      id
      feedback_text
      type
      coachID
    }
  }
`;
export const deleteSearchTerm = /* GraphQL */ `
  mutation DeleteSearchTerm(
    $input: DeleteSearchTermInput!
    $condition: ModelSearchTermConditionInput
  ) {
    deleteSearchTerm(input: $input, condition: $condition) {
      id
      search_term_text
      search_type
      sport
      source_app
      coachID
    }
  }
`;
export const deleteAthleteEnquiry = /* GraphQL */ `
  mutation DeleteAthleteEnquiry(
    $input: DeleteAthleteEnquiryInput!
    $condition: ModelAthleteEnquiryConditionInput
  ) {
    deleteAthleteEnquiry(input: $input, condition: $condition) {
      id
      enquiry_text
      athleteID
      coachID
    }
  }
`;