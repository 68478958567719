
var AWS = require("aws-sdk");

// Send an email to verify the new identity
export const verifyNewSesIdentity = async (email) => {
  let response = {};
  let params = {
    EmailAddress: email,
  };

  let sesProvider = new AWS.SES({
    region: "eu-west-1",
    apiVersion: "2010-12-01",
  });
  try {
    response = await sesProvider.verifyEmailIdentity(params).promise();
    return response;
  } catch (e) {
    throw new Error(e);
  }
};

// Delete identity
export const deleteSesIdentity = async (email) => {
  let response = {};
  let params = {
    Identity: email,
  };

  let sesProvider = new AWS.SES({
    region: "eu-west-1",
    apiVersion: "2010-12-01",
  });
  try {
    response = await sesProvider.deleteIdentity(params).promise();
    return response;
  } catch (e) {
    throw new Error(e);
  }
};
