// React imports
import React, { useContext, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
// Context imports
import { DataContext } from "../../../../contexts/dataContext";
// Chakra imports
import {
	Image,
	Box,
	Button,
	Flex,
	Text,
	useColorModeValue,
	Icon,
	Stack,
	Badge,
	Alert,
	AlertIcon,
	CloseButton,
	useDisclosure,
} from "@chakra-ui/react";
// React-icons
import { FaPen } from "react-icons/fa";
// Assets
import LoginLogo from "assets/img/LoginLogo.png"
// Component 
import { ConfirmModal } from "../../../../components/ConfirmModal/ConfirmModal";
import { DeleteButton } from "../../../../components/Buttons/DeleteButton";
// Auxiliary functions
import { changeUserGroupToAgencyId } from "../../../../helpers";
import { deleteTeamService } from "../../../../services/teamServices";
import { MdHotelClass, MdSchool } from 'react-icons/md';
import { updateTeamService } from 'services/teamServices';
import { addSubscriptionTeamPeriod } from 'services/teamServices';
import { v4 as uuidv4 } from 'uuid';

const Header = ({
	id,
	backgroundHeader,
	backgroundProfile,
	name,
	sport,
	genre,
	suscription_name,
	suscription_recurrency,
	suscription_status,
	had_trial,
	team_logo,
	college,
	category
}) => {
	// Chakra color mode
	const textColor = useColorModeValue("gray.700", "white");
	const borderProfileColor = useColorModeValue("white","rgba(255, 255, 255, 0.31)");
	const emailColor = useColorModeValue("gray.400", "gray.300","royalblue.700");

	// Take the context data needed in this component
    const dataContext = useContext(DataContext)
    const { user, teams, setTeams, coaches, setCoaches } = dataContext
	// State that controls if the user has permission to do an action
	const [noPermission, setNoPermission] = useState(false)
	// Navigate hook
	let navigate = useNavigate()

	// Hook for the confirm modal
	const { isOpen, onOpen, onClose } = useDisclosure()
	const cancelRef = useRef()
	const trialModal = useDisclosure()
	const trialCancelRef = useRef()

	// State with action feedback
	const [ actionStatus, setActionStatus ] = useState({
		done: false,
		status: '',
		message: ''
	})

	// Function that handles the edit action
	const handleEdit = () => {
		const userGroup = changeUserGroupToAgencyId(user.signInUserSession.idToken.payload["cognito:groups"][0])

		userGroup === "superadmins" 
		? navigate(`/admin/teams/edit/${id}`) 
		: setNoPermission(true)

	}

	// Function that handles the delete action
	const handleDelete = () => {
		const userGroup = changeUserGroupToAgencyId(user.signInUserSession.idToken.payload["cognito:groups"][0])

		userGroup === "superadmins" 
		? onOpen()
		: setNoPermission(true)
	}
	// Function that handles the confirm delete action
	const handleConfirmDelete = () => {
		onClose()
		deleteTeamService(id)
		setTeams(teams.filter(team => team.id !== id))
		navigate(`/admin/teams/`)
	}

	const handleGiveTrial = () => {
		const userGroup = changeUserGroupToAgencyId(user.signInUserSession.idToken.payload["cognito:groups"][0])

		userGroup === "superadmins"
		? trialModal.onOpen()
		: setNoPermission(true)
	}

	// Function that handles the confirm delete action
	const handleConfirmTrial = () => {
		trialModal.onClose()

		const start = new Date();
		const now = new Date();
		const next = now.setDate(now.getDate() + 15);
		const nextEndDate = new Date(next).toISOString().split('T')[0]
		// const {college, coaches, subscription_history,  ...teamWithouCollege} = team
		const newID = uuidv4()

		updateTeamService({ id: id, had_trial: true, suscription_name:'SEASON_PASS', suscription_premium_start_date : start.toISOString().split('T')[0], suscription_premium_end_date : nextEndDate })
		.then(() => {
			addSubscriptionTeamPeriod({id: newID, teamID: id, suscription_name: 'SEASON_PASS', start_date : start.toISOString().split('T')[0], end_date : nextEndDate}).then(()=>{
				setTeams(
					teams?.map(it => {
						if(it.id === id){
							return { 
								...it,  
								had_trial: true, 
								suscription_name:'SEASON_PASS', 
								suscription_premium_start_date : start.toISOString().split('T')[0], 
								suscription_premium_end_date : nextEndDate,
								subscription_history: {
									items: [
										...teams.find(it=>it.id === id)?.subscription_history?.items,
										{
											id: newID, 
											teamID: id, 
											suscription_name: 'SEASON_PASS', 
											start_date : start.toISOString().split('T')[0], 
											end_date : nextEndDate,
										}
									]
								}
							}
						}
						return it
					})
				);
				setCoaches(
					coaches?.map(coach => {
						if(coach.teamID === id){
							return { 
								...coach, 
								team: {
									...coach.team,  
									had_trial: true, 
									suscription_name:'SEASON_PASS', 
									suscription_premium_start_date : start.toISOString().split('T')[0], 
									suscription_premium_end_date : nextEndDate,
									subscription_history: {
										items: [
											...teams.find(it=>it.id === id)?.subscription_history?.items,
											{
												id: newID, 
												teamID: id, 
												suscription_name: 'SEASON_PASS', 
												start_date : start.toISOString().split('T')[0], 
												end_date : nextEndDate,
											}
										]
									}
								}
							}
						}
						return coach
					})
				)
				setActionStatus({
					done: true,
					status: 'success',
					message: 'TRIAL PERIOD Enabled corretly.'
				})	
			}).catch(err=>{
				setActionStatus({
					done: true,
					status: 'error',
					message: `An error has ocurred while saving trial period into subscription history: ${err}`
				})
			})
		}).catch(err=>
			setActionStatus({
				done: true,
				status: 'error',
				message: `An error has ocurred while granting trial period: ${err}`
			})
		)
	}

	return (
		<>
			<ConfirmModal
				title={`Delete ${name}`}
				description="Are you sure? You can't undo this action afterwards."
				button1="Cancel"
				button2="Delete"
				isOpen={isOpen}
				onClose={onClose}
				cancelRef={cancelRef}
				handleDelete={handleConfirmDelete}
			/>	
			<ConfirmModal
				title={`Give trial period to ${name}`}
				description="This action gives the team coaches access to premium features for 15 days. Are you sure?"
				button1="Cancel"
				button2="Confirm"
				color='blue'
				isOpen={trialModal.isOpen}
				onClose={trialModal.onClose}
				cancelRef={trialCancelRef}
				handleDelete={handleConfirmTrial}
			/>	
			<Flex justifyContent='right' m="0px 10px 10px 0">
				<DeleteButton handleDelete={handleDelete} />
			</Flex>
			{
				actionStatus.done && (
					<Alert status={actionStatus.status} borderRadius="10px" mb="20px" >
						<AlertIcon />
						{actionStatus.message}
						<CloseButton position='absolute' right='8px' top='8px' onClick={()=>(setActionStatus({...actionStatus,done:false}))} />
					</Alert>
				)
			}
			{
				noPermission
				? (
					<Alert status='error' borderRadius="10px" mb="20px">
						<AlertIcon />
						You don't have enough permissions to perform this action on this team.
						<CloseButton position='absolute' right='8px' top='8px' onClick={()=>(setNoPermission(false))} />
					</Alert>
				)
				: null
			}
			<Box
				mb={{ sm: "20px", md: "30px", xl: "40px" }}
				borderRadius='15px'
				px='0px'
				display='flex'
				flexDirection='column'
				justifyContent='center'
				align='center'
			>
				<Box
				bgImage={backgroundHeader}
				w='100%'
				h='200px'
				borderRadius='25px'
				bgPosition='50%'
				bgRepeat='no-repeat'
				position='relative'
				display='flex'
				justifyContent='center'>
					<Flex
						direction={{ sm: "column", md: "row" }}
						mx='1.5rem'
						maxH='330px'
						w={{ sm: "90%", xl: "95%" }}
						justifyContent={{ sm: "center", md: "space-between" }}
						align='center'
						backdropFilter='saturate(200%) blur(50px)'
						position='absolute'
						boxShadow='0px 2px 5.5px rgba(0, 0, 0, 0.02)'
						border='2px solid'
						borderColor={borderProfileColor}
						bg={backgroundProfile}
						p='24px'
						borderRadius='20px'
						transform={{
							md: team_logo ? "translateY(17%)" : "translateY(55%)",
							lg: team_logo ? "translateY(20%)" : "translateY(60%)",
						}}
					>
						
						<Flex direction='column'>

							<Flex
								align='center'
								mb={{ sm: "10px", md: "0px" }}
								direction={{ sm: "column", md: "row" }}
								w={{ sm: "100%" }}
								textAlign={{ sm: "center", md: "start" }}
							>
								{
									team_logo
									? (
										<Image
											me={{ md: "22px" }}
											src={team_logo}
											w='200px'
											borderRadius='15px'
											fit='cover'
										/>
									)
									: (
										<Image
											me={{ md: "22px" }}
											src={LoginLogo}
											w='200px'
											borderRadius='15px'
											fit='cover'
										/>	
									)
								}
								
								<Flex direction='column' maxWidth='100%' my={{ sm: "10px" }} mx={{sm:"10px"}} >
									<Text
									fontSize={{ sm: "md", lg: "lg" }}
									color={textColor}
									fontWeight='bold'
									ms={{ sm: "8px", md: "0px" }}>
										{name}
									</Text>
									<Text
									fontSize={{ sm: "sm", md: "md" }}
									color={emailColor}
									fontWeight='semibold'
									onClick={()=>(navigate(`/admin/colleges/show/${college.id}`))} style={{cursor:'pointer'}}
									>
										<Icon as={MdSchool} size="xs" mr="5px" />{college.name}
									</Text>
									<Text
									fontSize={{ sm: "sm", md: "md" }}
									color={emailColor}
									fontWeight='semibold'>
										{genre}'s {sport} Team
									</Text>
									<Text
									fontSize={{ sm: "sm", md: "md" }}
									color={emailColor}
									fontWeight='semibold'>
										Category - <strong>{category ? category : 'Not specified'}</strong>
									</Text>
									
								</Flex>
								<Flex direction='column' maxWidth='100%' my={{ sm: "10px" }} ml="70px">
									<Stack direction='column'>
										<Badge variant='solid' colorScheme='yellow' >
											{suscription_name.replace('_',' ')}
										</Badge>
										<Badge variant='subtle' colorScheme='yellow'>
											{suscription_name === 'PREMIUM_PASS' ? 'Premium since:' : suscription_name === 'SEASON_PASS' && 'Trial since:'} {suscription_recurrency}
										</Badge>
										<Badge variant='subtle' colorScheme='yellow'>
											{suscription_name === 'PREMIUM_PASS' ? 'Premium until:' : suscription_name === 'SEASON_PASS' && 'Trial until:'} {suscription_status}
										</Badge>
										{
											(had_trial !== null && !had_trial) && (
												<Badge variant='subtle' colorScheme='red'>
													TRIAL PERIOD EXPIRED
												</Badge>
											)
										}
									</Stack>
									
								</Flex>
							</Flex>
						</Flex>


						<Flex
							direction="column"
							w={{ sm: "100%", md: "50%", lg: "auto" }}
							
						>	
							<Button p='0px' bg='transparent' _hover={{ bg: "none" }} onClick={handleEdit}>
								<Flex
								align='center'
								w={{ sm: "100%", lg: "170px" }}
								bg='hsla(0,0%,100%,.3)'
								borderRadius='15px'
								justifyContent='center'
								py='10px'
								boxShadow='inset 0 0 1px 1px hsl(0deg 0% 100% / 90%), 0 20px 27px 0 rgb(0 0 0 / 5%)'
								border='1px solid gray.200'
								cursor='pointer'>
									<FaPen w='100%' h='100%' />
									<Text
										fontSize='xs'
										color={textColor}
										fontWeight='bold'
										ms='6px'>
										EDIT
									</Text>
								</Flex>
							</Button>

							{
								(suscription_name === 'FREE_PERIOD' && had_trial === null) && (
									<Button p='0px' m='3px' bg='transparent' _hover={{ bg: "gold" }} onClick={handleGiveTrial}>
										<Flex
										align='center'
										w={{ sm: "100%",md:"50%",lg: "170px" }}
										bg='gold'
										borderRadius='15px'
										justifyContent='center'
										py='10px'
										boxShadow='inset 0 0 1px 1px hsl(0deg 0% 100% / 90%), 0 20px 27px 0 rgb(0 0 0 / 5%)'
										border='1px solid gray.200'
										cursor='pointer'>
											<MdHotelClass w='100%' h='100%' />
											<Text
												fontSize='xs'
												color={textColor}
												fontWeight='bold'
												ms='6px'>
												GIVE TRIAL PERIOD
											</Text>
										</Flex>
									</Button>
								)
							}
							
						</Flex>
					</Flex>
				</Box>
			</Box>
		</>
	);
};
  
  export default Header;
  