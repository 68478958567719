// React imports
import React, { useContext, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { FaPen,FaGlobe } from "react-icons/fa";
// Context imports
import { DataContext } from "../../../../contexts/dataContext";

// Chakra imports
import {
	Image,
	Box,
	Button,
	Flex,
	Text,
	useColorModeValue,
	Alert,
	AlertIcon,
	CloseButton,
	useDisclosure,
} from "@chakra-ui/react";
// Component 
import { ConfirmModal } from "../../../../components/ConfirmModal/ConfirmModal";
import { DeleteButton } from "../../../../components/Buttons/DeleteButton";
// Auxiliary imports
import { changeUserGroupToAgencyId, countryListAlpha2 } from "../../../../helpers";
import { deleteAgencyService } from "../../../../services/agencyServices";

  
const Header = ({
	id,
	backgroundHeader,
	backgroundProfile,
	logo,
	name,
	email,
	phone,
	country,
	web,
}) => {
	// Chakra color mode
	const textColor = useColorModeValue("gray.700", "white");
	const borderProfileColor = useColorModeValue("white","rgba(255, 255, 255, 0.31)");
	const emailColor = useColorModeValue("gray.400", "gray.300");

	// Take the context data needed in this component
    const dataContext = useContext(DataContext)
    const { user, userCoachData, agencies, setAgencies } = dataContext
	// State that controls if the user has permission to do an action
	const [noPermission, setNoPermission] = useState(false)
	// Navigation hook
	let navigate = useNavigate()
	
	// Hook for the confirm modal
	const { isOpen, onOpen, onClose } = useDisclosure()
	const cancelRef = useRef()
	// COGNITO user group
	const userGroup = changeUserGroupToAgencyId(user.signInUserSession.idToken.payload["cognito:groups"][0])
	// Function that handles the edit action
	const handleEdit = () => {

		userGroup === "superadmins" || (userGroup === id && userCoachData.agent_role==="LEADER")
		? navigate(`/admin/agencies/edit/${id}`) 
		: setNoPermission(true)

	}
	// Function that handles the delete action
	const handleDelete = () => {
		// Disable this functionality by default
		userGroup === "godadmin" 
		? onOpen()
		: setNoPermission(true)
	}
	// Function that handles the confirm delete action
	const handleConfirmDelete = () => {
		onClose()
		deleteAgencyService(id)
		setAgencies(agencies.filter(agency => agency.id !== id))
		navigate(`/admin/agencies/`)
	}

	return (
		<>
			<ConfirmModal
				title={`Delete ${name} agency`}
				description="Are you sure? You can't undo this action afterwards."
				button1="Cancel"
				button2="Delete"
				isOpen={isOpen}
				onClose={onClose}
				cancelRef={cancelRef}
				handleDelete={handleConfirmDelete}
			/>
			<Flex justifyContent='right' m="0px 10px 10px 0">
				<DeleteButton handleDelete={handleDelete} />
			</Flex>
			{
				noPermission
				&& (
					<Alert status='error' borderRadius="10px" mb="20px">
						<AlertIcon />
						You don't have enough permissions to perform this action on this agency.
						<CloseButton position='absolute' right='8px' top='8px' onClick={()=>(setNoPermission(false))} />
					</Alert>
				)
			}
			<Box
				mb={{ sm: "20px", md: "30px", xl: "40px" }}
				borderRadius='15px'
				px='0px'
				display='flex'
				flexDirection='column'
				justifyContent='center'
				align='center'>
				<Box
				bgImage={backgroundHeader}
				bgSize='cover'
				w='100%'
				h='200px'
				borderRadius='25px'
				bgPosition='50%'
				bgRepeat='no-repeat'
				position='relative'
				display='flex'
				justifyContent='center'>

					<Flex
						direction={{ sm: "column", md: "row" }}
						mx='1.5rem'
						maxH='330px'
						w={{ sm: "90%", xl: "95%" }}
						justifyContent={{ sm: "center", md: "space-between" }}
						align='center'
						backdropFilter='saturate(200%) blur(50px)'
						position='absolute'
						boxShadow='0px 2px 5.5px rgba(0, 0, 0, 0.02)'
						border='2px solid'
						borderColor={borderProfileColor}
						bg={backgroundProfile}
						p='24px'
						borderRadius='20px'
						transform={{
						md: "translateY(40%)",
						lg: "translateY(45%)",
						}}
					>
						
						<Flex direction='column'>

							<Flex
							align='center'
							mb={{ sm: "10px", md: "0px" }}
							direction={{ sm: "column", md: "row" }}
							w={{ sm: "100%" }}
							textAlign={{ sm: "center", md: "start" }}>
								<Image
									me={{ md: "22px" }}
									src={logo}
									w='100px'
									h='80px'
									borderRadius='15px'
								/>
								<Flex direction='column' maxWidth='100%' my={{ sm: "10px" }}>
									<Text
									fontSize={{ sm: "lg", lg: "xl" }}
									color={textColor}
									fontWeight='bold'
									ms={{ sm: "8px", md: "0px" }}>
										{name}
									</Text>
									<Text
									fontSize={{ sm: "sm", md: "md" }}
									color={emailColor}
									fontWeight='semibold'>
										{email}
									</Text>
									<Text
									fontSize={{ sm: "sm", md: "md" }}
									color={emailColor}
									fontWeight='semibold'>
										{phone}
									</Text>
								</Flex>
							</Flex>
							{/* Remember: check out responsive style */}
							<Flex
								direction={{ sm:"row", md:"row", lg: "row" }}
								w={{ sm: "100%", md: "50%", lg: "auto" }}
								>
								{
									country?.map((c, idx) => {
										return (
											<Image key={idx} src={`https://www.flagcdn.com/w40/${countryListAlpha2[c]?.toLowerCase()}.png`} h='17px' m='7px' color="inherit" title={c}/>
										)
									})
								}
							</Flex>
						</Flex>
						<Flex
							direction={{ sm: "column", lg: "row" }}
							w={{ sm: "100%", md: "50%", lg: "auto" }}
							
						>	
							<a href={web} target="blank">
								<Button p='0px' bg='transparent' _hover={{ bg: "none" }} >
									<Flex
									align='center'
									w={{ sm: "100%", lg: "135px" }}
									bg='hsla(0,0%,100%,.3)'
									borderRadius='15px'
									justifyContent='center'
									py='10px'
									boxShadow='inset 0 0 1px 1px hsl(0deg 0% 100% / 90%), 0 20px 27px 0 rgb(0 0 0 / 5%)'
									border='1px solid gray.200'
									mr='5px'
									cursor='pointer'>
										<FaGlobe w='100%' h='100%' />
										<Text
											fontSize='xs'
											color={textColor}
											fontWeight='bold'
											ms='6px'>
											WEBSITE
										</Text>
									</Flex>
								</Button>
							</a>
							
							<Button p='0px' bg='transparent' _hover={{ bg: "none" }} onClick={handleEdit}>
								<Flex
								align='center'
								w={{ sm: "100%", lg: "135px" }}
								bg='hsla(0,0%,100%,.3)'
								borderRadius='15px'
								justifyContent='center'
								py='10px'
								boxShadow='inset 0 0 1px 1px hsl(0deg 0% 100% / 90%), 0 20px 27px 0 rgb(0 0 0 / 5%)'
								border='1px solid gray.200'
								cursor='pointer'>
									<FaPen w='100%' h='100%' />
									<Text
										fontSize='xs'
										color={textColor}
										fontWeight='bold'
										ms='6px'>
										EDIT
									</Text>
								</Flex>
							</Button>
						</Flex>
					</Flex>
				</Box>
			</Box>
		</>
	);
};
  
  export default Header;
  