import React, { useState }  from "react";
// Chakra imports
import {
    Flex,
    SimpleGrid,
    Image,
    Text,
    useColorModeValue,
    RadioGroup,
    Radio,
    Tooltip,
    Icon,
    ScaleFade,
    Button,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import  BarChart  from "components/Charts/BarChart";
import { stackedBarChartAgenciesOptions } from "variables/charts";
import { GoAlert } from "react-icons/go";
import { getActualSeason } from "helpers";
import CardHeader from "components/Card/CardHeader";


  
const AgencySummaryWithChart = ({ title, agencies,athletes }) => {
    const textColor = useColorModeValue("gray.700", "white");
    

    const [sportFilter, setSportFilter] = useState({'AgencySummary':'Total'})

    const [hasRender, setRender] = useState(false);
  	const onShow = React.useCallback(() => setRender(true), []);


    const getAthleteStatus = () => {
		let status = [{name: "AVAILABLE",data: []},{name: "ON_HOLD",data: []},{name: "COMMITTED",data: []},{name:"NO STATUS",data:[]}]
        const season = getActualSeason()
		agencies?.map(agency => {
			const ahtletesOfAgency = athletes.filter(athlete => athlete.agencyID === agency.id).filter(item => season === item.start_date)
            
            status[0].data.push(ahtletesOfAgency.filter(item => item.current_status === "AVAILABLE" && (item.sport === sportFilter.AgencySummary || sportFilter.AgencySummary === 'Total')).length)
            status[1].data.push(ahtletesOfAgency.filter(item => item.current_status === "ON_HOLD" && (item.sport === sportFilter.AgencySummary || sportFilter.AgencySummary === 'Total')).length)
            status[2].data.push(ahtletesOfAgency.filter(item => item.current_status === "COMMITTED" && (item.sport === sportFilter.AgencySummary || sportFilter.AgencySummary === 'Total')).length)
            status[3].data.push(ahtletesOfAgency.filter(item => !item.current_status && (item.sport === sportFilter.AgencySummary || sportFilter.AgencySummary === 'Total')).length)
		})

		return status
	}

    
    // const athleteInProgress = (actualSeason, athleteSeason) => {
    //     if(!athleteSeason){return false}
    //     const infoActual = actualSeason?.split("_")
    //     const infoAthlete = athleteSeason?.split("_")
    //     // X_2022 > X_2021
    //     if(infoActual[1] > infoAthlete[1]){return false}
    //     else {return true}
	// }

    return (
        <>
            { hasRender && (
				<ScaleFade in={hasRender} >
                    <Card p='16px'>
                        <CardBody>
                            <Flex direction='column' w='100%' >
                                <Flex direction='column' mt='24px' alignSelf='flex-start'>
                                    <Flex alignItems='center'>
                                        <Text fontSize='lg' color={textColor} fontWeight='bold' mb='6px'>
                                            {title} 
                                        </Text>
                                        <Tooltip label={`Chart shows available prospective student-athletes (in-progress) for the next recruiting period (${getActualSeason().replace('_',' ')}).`} bg='orange.300'  placement='auto' >
                                            <span><Icon as={GoAlert} color="orange" ml='10px'/></span>
                                        </Tooltip>
                                    </Flex>
                                    <Text fontSize='md' fontWeight='medium' color='gray.400'>
                                    This chart shows a distribution of the athletes in progress according to their current states.
                                    </Text>
                                    <RadioGroup onChange={(e)=>setSportFilter({...sportFilter,'AgencySummary':e})} value={sportFilter && sportFilter['AgencySummary']}>
                                        <Flex direction='row'>
                                            <Radio value='Total' mr="20px">
                                                <Text fontSize='md' fontWeight='medium' color='gray.400'>
                                                Total
                                                </Text>
                                            </Radio>
                                            <Radio value='Soccer' mr="20px">
                                                <Text fontSize='md' fontWeight='medium' color='gray.400'>
                                                Soccer
                                                </Text>
                                            </Radio>
                                            <Radio value='Tennis' mr="20px">
                                                <Text fontSize='md' fontWeight='medium' color='gray.400'>
                                                Tennis
                                                </Text>
                                            </Radio>
                                        </Flex>
                                    </RadioGroup>
                                </Flex>
                                <BarChart data={getAthleteStatus()} options={stackedBarChartAgenciesOptions}/>


                                <SimpleGrid gap={{ sm: "12px" }} columns={{ sm: 3, md: 4}} mt="20px" > 
                                    {
                                        agencies?.map((item, index) => {
                                            return (
                                                <Flex direction='row' key={index} m="10px 50px 0 10px" alignItems="center">
                                                    <Image src={item.logo} h={"40px"} w={"40px"}  borderRadius="12px" />
                                                    <Flex direction='column' ml='12px'>
                                                        <Text fontSize='lg' color={textColor} fontWeight='bold'>
                                                            {item.agents.items.length} {item.agents.items.length>1 ? 'Agents' : 'Agent'}
                                                        </Text>
                                                        <Text fontSize='md' fontWeight='medium' color='gray.400'>
                                                            {athletes.filter(athlete => athlete.agencyID === item.id).length} Athletes (Total)
                                                        </Text>
                                                        <Text fontSize='md' fontWeight='medium' color='gray.400'>
                                                            {
                                                                athletes.filter(athlete => athlete.agencyID === item.id && (sportFilter['AgencySummary'] === 'Total' || athlete.sport === sportFilter['AgencySummary'])).filter(item => getActualSeason() === item.start_date).length
                                                            } Athletes (Progress)
                                                        </Text>
                                                        <Text fontSize='md' fontWeight='medium' color='gray.400'>
                                                            {
                                                                athletes.filter(athlete => athlete.agencyID === item.id && (sportFilter['AgencySummary'] === 'Total' || athlete.sport === sportFilter['AgencySummary']) && !athlete.current_status).filter(item => getActualSeason() === item.start_date).length
                                                            } Athletes (No status)
                                                        </Text>
                                                    </Flex>
                                                </Flex>
                                            )
                                        })

                                    }
                                </SimpleGrid>
                            </Flex>
                        </CardBody>
                    </Card>
                </ScaleFade>
            )}
            
            { !hasRender && (
				<ScaleFade in={!hasRender} >
					<Card p='28px 10px 16px 0px'>
						<CardHeader pl='22px' w="100%">
                            <Flex direction='row' alignSelf='flex-start' w="100%" justifyContent={'space-between'} alignItems="center">
								<Flex direction='column' alignSelf='flex-start' w="50%">
                                    <Text fontSize='lg' color={"gray.700"} fontWeight='bold' mb='6px'>
                                        Agency summary 
                                    </Text>
                                    <Text fontSize='md' fontWeight='medium' color='gray.400'>
                                        This chart shows a distribution of the athletes in progress according to their current states group by their agencies.  
                                    </Text>
                                </Flex>
								<Button w="40%" colorScheme='blue' onClick={onShow} my="10px" mr="5%">
									Load chart
								</Button>
							</Flex>
						</CardHeader>
					</Card>
				</ScaleFade>
			)}
        </>
    );
};

const myPropsChanged = (prevProps, nextProps) => {
    if ( prevProps.agencies !== nextProps.agencies || prevProps.athletes !== nextProps.athletes) {
        return false;
    }
    return true;
}

export default React.memo(AgencySummaryWithChart, myPropsChanged);
  