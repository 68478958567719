// React imports
import React, { useContext, useEffect } from 'react'
import { Routes, Route } from 'react-router-dom';
// Component imports
import CoachesTables from './components/CoachesTables';
import { DataContext } from '../../../contexts/dataContext'
import { ShowCoach } from './components/ShowCoach';
import { EditCoach } from './components/EditCoach';
import { CreateCoach } from './components/CreateCoach';
import { Activity } from './components/Activity';

export const CoachesRoutes = () => {
    // Get data from context
    const dataContext = useContext(DataContext)
    const { coaches } = dataContext

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <Routes>
                <Route path="/" element={<CoachesTables coaches={coaches} showMoreInfo={true} />} />
                <Route path="/show/:coachId" element={<ShowCoach />} />
                <Route path="/edit/:coachId" element={<EditCoach />} />
                <Route path="/activity/:coachId" element={<Activity />} />
                <Route path="/create" element={<CreateCoach />} />
            </Routes>
        </>
    )
}
