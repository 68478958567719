// React imports
import React, { useContext,useState, useEffect, useRef } from 'react'
import { useNavigate } from "react-router-dom";
// Context imports
import { DataContext } from '../../../../contexts/dataContext'
// Chakra imports
import {
	Table,
	Tbody,
	Text,
	Th,Td,
	Thead,
	Tr,
	useColorModeValue,
	Flex,
	Image,
	useDisclosure,
	Alert,
	AlertIcon,
	CloseButton,
	Select,Spacer,Icon,Button, Avatar
} from "@chakra-ui/react";
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
  } from '@chakra-ui/react'
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { Pagination } from "components/Pagination/Pagination";
import { SearchBar } from "components/SearchBar/SearchBar";
// Auxiliary functions
import { getSearchedData } from "helpers/getSearchedData";
import {getAgenciesUtilities} from "helpers"
import { changeUserGroupToAgencyId } from "../../../../helpers";
import { updateAthleteService } from "../../../../services/athleteServices";
import {positionAcronym,startDate} from "helpers"
import {BsArrowDown, BsArrowUp} from 'react-icons/bs'
import { BiTransferAlt } from 'react-icons/bi'


const AthletesWithTransfer = ({ title, captions, data,showMoreInfo}) => {
	// Chakra color mode
	const textColor = useColorModeValue("gray.700", "white");

	// Take the context data needed in this component
	const dataContext = useContext(DataContext)
	const { pagination, updatePagination, user, athletes, setAthletes, agencies, userCoachData, agents } = dataContext
	// State with the searched text
	const [ searchState, updateSearchState ] = useState({searchText: ''})
	// State that handles the selection of items
	const [ selectItem, setSelectItem ] = useState({})
	// State that controls if the user has permission to do an action
	const [ noPermission, setNoPermission ] = useState(false)
	// State that control if sort is ascending or descending
	const [ sortAscending, setSortAscending ] = useState(true)
	const [ filters, setFilters ] = useState({
		visible:0,
		visibleData: ['ALL', 'Active', 'Hidden'],
		status:0,
		statusData:['ALL','AVAILABLE','ON_HOLD','COMMITTED'],
		sport: 0,
		sportData: ['ALL','Soccer', 'Tennis'],
		semester: 0,
		semesterData: ['ALL', ...startDate.map(it => it.label)]

	})
	// State with action feedback
	const [ actionStatus, setActionStatus ] = useState({
        done: false,
        status: '',
        message: ''
    })

	// Hook for the confirm modal
	const { isOpen, onOpen, onClose } = useDisclosure()
	const cancelRef = useRef()
	// Navigation hook 
	let navigate = useNavigate()

	useEffect(() => {
		updatePagination({
			total:getSearchedData(data,searchState.searchText).length,
			page: 1,
			rowsPerPage: 10,
			lower: 1})
	}, [])

	const filterData = (data) => {
		let newData = data
		if (filters.visible === 0 && filters.status===0 && filters.sport === 0 && filters.semester === 0) {newData = data}
		if (filters.visible > 0) {newData = newData.filter(item => item.isVisible === (filters.visible === 1 ? true : false))}
		if (filters.status>0){ newData = newData.filter(item => item.current_status === filters.statusData[filters.status]) }
		if (filters.sport > 0){newData = newData.filter(item => item.sport === filters.sportData[filters.sport])}
		if (filters.semester > 0 ){newData = newData.filter(item => item.start_date === startDate.find(it => it.label === filters.semesterData[filters.semester]).value)}
		return newData.sort((a,b) => {
            if(sortAscending){return a.name.toLowerCase().localeCompare(b.name.toLowerCase())}
            else{return b.name.toLowerCase().localeCompare(a.name.toLowerCase())}
        })
	}

	// Function that handles the transfer action
	const handleTransfer = () => {
		const userGroup = changeUserGroupToAgencyId(user.signInUserSession.idToken.payload["cognito:groups"][0]);
		// If the user is the leader of this agency or superadmin can confirm the action
		(userGroup === data[0].agencyID  && userCoachData.agent_role === "LEADER") || userGroup === "superadmins" 
		? onOpen()
		: setNoPermission(true)
	}

	// Function that handles the confirm delete action
	const handleConfirmTransfer = () => {
		onClose()
		let hasFailed = false
		selectItem.psa.map( it => 
			updateAthleteService({
				id:it,
				agentID: selectItem.agent,
				agencyID: agencies.find(ag => ag.id === agents.find(agt => agt.id === selectItem.agent).agencyID).id
			})
			.then(() => {
				setAthletes(
					athletes.map(athlete => {
						if(athlete.id === it) {
							return {
								...athlete,
								agentID: selectItem.agent,
								agencyID: agencies.find(ag => ag.id === agents.find(agt => agt.id === selectItem.agent).agencyID).id
							}
						} 	
						return athlete
					})
				)
			})
			.catch(()=>
				hasFailed=true
			)
		)

		hasFailed ? (
			setActionStatus({
				done: true,
				status: 'error',
				message: 'An error has ocurred while tranfer the athlete'
			})
		) : (
			setActionStatus({
				done: true,
				status: 'success',
				message: 'Athlete transfer successfully'
			})
		)
	}

	
	return (
		<>
			<Modal
				isCentered
				onClose={onClose}
				isOpen={isOpen}
				motionPreset='slideInBottom'
			>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Transfer PSA to </ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						With this action you will transfer the selected PSA to the desired agent from your agency or other agency.<br /><br />
						<strong>Select one of the following agents: </strong>
						<Select placeholder="Select agent" my="10px" bg="#EBF5FB" variant='Filled' value={selectItem.agent} onChange={(e)=>setSelectItem({...selectItem, agent:e.target.value})}>
							{agents?.map((opt,index) => {return (<option key={index} value={opt.id} color="royalblue">{opt.name}</option>)})}
						</Select> 
					</ModalBody>
					<ModalFooter>
						<Button colorScheme='red' mr={3} onClick={()=>onClose()}>
							Cancel
						</Button>
						<Button variant='ghost' colorScheme='blue' onClick={handleConfirmTransfer} isDisabled={!selectItem.agent}>Confirm transfer</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
			<Flex justifyContent='right' m="0px 10px 10px 0">
				<Button
					colorScheme='blue'
					variant='ghost'
					leftIcon={<Icon as={BiTransferAlt} />}
					size='sm'
					onClick={()=>(handleTransfer(),setSelectItem({psa:data.map(it => it.id)}))}
					me="10px"
					>
					Transfer all PSAs
				</Button>
			</Flex>
			{
				noPermission
				? (
					<Alert status='error' borderRadius="10px" mb="20px">
						<AlertIcon />
						You don't have enough permissions to perform this action on athletes.
						<CloseButton position='absolute' right='8px' top='8px' onClick={()=>(setNoPermission(false))} />
					</Alert>
				)
				: null
			}
			{
				actionStatus.done && (
					<Alert status={actionStatus.status} borderRadius="10px" mb="20px" >
						<AlertIcon />
						{actionStatus.message}
						<CloseButton position='absolute' right='8px' top='8px' onClick={()=>(setActionStatus({...actionStatus,done:false}))} />
					</Alert>
				)
			}
			<Card overflowX={{ sm: "scroll", xl: "hidden" }}>
				<CardHeader p='6px 0px 15px 0px'>
					<Flex direction='row' w="100%">
						<Flex justifyContent='left' direction="column" w="50%" >
							<Text fontSize='xl' color={textColor} fontWeight='bold'>
								{title} ({searchState.searchText!== '' ? `Filtered result ${filterData(getSearchedData(data,searchState.searchText)).length}` : filterData(data).length} PSAs)
							</Text>
						</Flex>
						<Spacer />
						<Flex direction="column" alignItems="end">
							<SearchBar text={searchState} update={updateSearchState}/>
							<Text 
								color='royalblue.700' 
								m="10px 10% 0 0" 
								cursor="pointer" 
								onClick={()=>(setFilters({...filters, visible:0,sport:0,status:0,position:0,agency:0, semester:0}))}
								fontSize="xs"
							><i> Reset all filters </i></Text>
						</Flex>
					</Flex>
				</CardHeader>
				<CardBody>
					<Table variant='simple' color={textColor}>
						<Thead>
							<Tr my='.8rem' pl='0px' color='gray.400'>
								
								<Th color='gray.400'>
									Profile pic
								</Th>
								<Th color='gray.400' cursor="pointer" onClick={()=>setSortAscending(!sortAscending)}>
									Name {sortAscending ? <Icon as={BsArrowUp} color="gray.400" /> : <Icon as={BsArrowDown} color="gray.400" />}
								</Th>
								<Th color='gray.400'>
									Status
									<Select variant='unstyled' size="xs" value={filters.status} onChange={(e)=>setFilters({...filters, status:parseInt(e.target.value)})}>
										{filters.statusData.map((opt,index) => {return (<option key={index} value={index} color="royalblue">{opt}</option>)})}
									</Select>
								</Th>
								<Th color='gray.400'>
									Transfer
								</Th>
							</Tr>
						</Thead>
						<Tbody>
							{filterData(getSearchedData(data,searchState.searchText)).slice(pagination.lower-1,pagination.lower+pagination.rowsPerPage-1).map((row) => {
								return (
									<Tr>
										<Td minWidth={{ sm: "100px" }} pl="0px" onClick={()=>(navigate(`/admin/athletes/show/${row.id}`))} style={{cursor:"pointer"}}>
											<Flex justify="center" py=".2rem" minWidth="100%" flexWrap="nowrap">
												<Avatar src={row?.profile_pic} size="xl" bg="royalblue.700" />
											</Flex>
										</Td>
										<Td maxWidth={{ sm: "170px" }} onClick={()=>(navigate(`/admin/athletes/show/${row.id}`))} style={{cursor:"pointer"}}>
											<Flex direction="column">
												<Text
													fontSize="md"
													color={textColor}
													fontWeight="bold"
													minWidth="100%"
												>
													{row?.name}
												</Text>
												<Text fontSize="sm" color="gray.400" fontWeight="normal">
													{row?.contact_email}
												</Text>
												<Text fontSize="sm" color="gray.400" fontWeight="normal">
													{row?.sport}
												</Text>
											</Flex>
										</Td>
										<Td maxWidth={{ sm: "150px" }} >
											<Text fontSize="sm" color="gray.400" fontWeight="normal">
												{row?.current_status}
											</Text>
										</Td>
										<Td maxWidth={{ sm: "150px" }} onClick={()=>(handleTransfer(),setSelectItem({psa:[row.id]}))} style={{cursor:"pointer"}}>
											<Button leftIcon={<BiTransferAlt />} colorScheme='blue' variant='outline'>
												Transfer PSA
											</Button>
										</Td>
									</Tr>
								);
							})}
						</Tbody>
					</Table>
				</CardBody>
				<Pagination totalData={filterData(getSearchedData(data,searchState.searchText)).length} rowsPerPageValues={[10,20,30,40,50]} />
			</Card>
		</>
	);
};

export default AthletesWithTransfer;
