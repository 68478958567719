// React imports
import React, { useContext, useState, useEffect} from 'react'
import { useParams, useNavigate } from 'react-router-dom';
// Context imports
import { DataContext } from "../../../../contexts/dataContext";
// React-select imports
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
// Chackra Components
import { Flex, useColorModeValue, Text, FormControl,FormErrorMessage, Input,Spacer, Box, Button, ButtonGroup } from "@chakra-ui/react";
import { Alert, AlertIcon, AlertTitle, AlertDescription, Tooltip, Icon } from '@chakra-ui/react'
import { BsQuestionCircleFill } from "react-icons/bs";
// Auxiliary imports
import { uploadImageToCloudinary } from "../../../../helpers/uploadImageToCloudinary";
import { updateCoachService } from "../../../../services/coachServices";
import validator from "validator";
import { roles, division } from 'helpers';
// Component 
import { ImageDropzone } from "../../../../components/Dropzone/ImageDropzone";

export const EditCoach = () => {
    // Chakra color mode
    const textColor = useColorModeValue("gray.700", "white");
	const animatedComponents = makeAnimated();

    // Get the id of the agency from the url
	const { coachId } = useParams();
	// Navigation hook
    let navigate = useNavigate()

    // Take the context data needed in this component
    const dataContext = useContext(DataContext)
    const { coaches, setCoaches, teams } = dataContext
    const coach = coaches.find(coach => coach.id === coachId)
    const teamOfCoach = teams.find(team => team.id === coach.teamID)

    // The initial state of the form with the data of the agency
	const initialState = {
		id: coach.id,
		name: coach.name,
		profile_pic: coach.profile_pic,
		birthdate: coach.birthdate ? coach.birthdate : '',
		contact_email: coach.contact_email ? coach.contact_email : '',
		contact_phone: coach.contact_phone ? coach.contact_phone : '',
		gender: coach.gender,
        role: coach.role,
        sport:coach.sport,
        is_active:coach.is_active,
        teamID: coach.teamID,
        division:coach.division,
        favorites:coach.favorites,
        createdAt: coach.createdAt,
        updateAt: coach.updateAt,
	}

    // State that controls the form values
	const [formValues, setFormValues] = useState(initialState)
	// State to check if form has changed and enable submit button
	const [formHasChanged, setFormHasChanged] = useState(false)
	// State that controls if the submit is loading
	const [isLoading, setIsLoading] = useState(false)
	// State with form errors 
	const [errors, setErrors] = useState({
		email: '',
		phone: '',
		birthdate: '',
	})

    // When the form values change, check that the mandatory fields are filled and enable the submit button
	useEffect(() => {
        JSON.stringify(formValues) !== JSON.stringify(initialState) && formValues.name !== '' && formValues.teamID !== '' ? setFormHasChanged(true) : setFormHasChanged(false)
	}, [formValues])

    const onChange = (e) => {
        e.persist()
        setFormValues(() => ({ ...formValues, [e.target.name]: e.target.value }))
    }

    const handleEdit = () => {
        setIsLoading(true)
		let thereAreErrors = {
			email: '',
			phone: '',
			birthdate: '',
		}

		// Validate data (contact email, contact phone, website)
		if('contact_email' in formValues && formValues.contact_email !== "" && !validator.isEmail(formValues.contact_email)){
			setIsLoading(false)
			thereAreErrors = {...thereAreErrors, email: 'Please enter a valid email'}
		}
		
		if('contact_phone' in formValues && formValues.contact_phone !== "" && !validator.isMobilePhone(formValues.contact_phone, 'any',{ strictMode: true })){
			setIsLoading(false)
			thereAreErrors = {...thereAreErrors, phone: 'Please enter a valid phone number'}
		}
		
		if('birthdate'in formValues && formValues.birthdate !== "" && !validator.isDate(formValues.birthdate, {format: 'YYYY-MM-DD',delimiters: ['-']})){
			setIsLoading(false)
			thereAreErrors = {...thereAreErrors, birthdate: 'Please enter a valid birthdate'}
		}

        setErrors(thereAreErrors)
		// If there aren't errors, check if there is an image to upload, if so, upload it to cloudinary and create agency
		if(thereAreErrors.email === "" && thereAreErrors.phone === "" && thereAreErrors.birthdate === ""){
            
            const {createdAt, updatedAt,favorites, ...coachWithoutEmptyValues} = Object.keys(formValues).reduce((obj, key) => {
				formValues[key] !== "" ? obj[key] = formValues[key] : obj[key] = null
				return obj
			}, {})

            if(formValues.profile_pic !== initialState.profile_pic){
                uploadImageToCloudinary({image:formValues.profile_pic, directory: 'devtests'})
				.then((url) => {
					updateCoachService({ ...coachWithoutEmptyValues, profile_pic: url })
					setCoaches(
                        coaches.map(coach => {
                            if(coach.id === coachWithoutEmptyValues.id){
                                return {...coachWithoutEmptyValues, profile_pic: url, createdAt:createdAt,updatedAt:updatedAt,favorites:favorites, team: coach.team, totalActions: coach.totalActions}
                            }
                            return coach
                        })
                    )
                })
			}else{
				updateCoachService({ ...coachWithoutEmptyValues })
                .catch(err => console.log(err))
				setCoaches(
                    coaches.map(coach => {
                        if(coach.id === coachWithoutEmptyValues.id){
                            return { ...coachWithoutEmptyValues, createdAt:createdAt,updatedAt:updatedAt,favorites:favorites, team: coach.team, totalActions: coach.totalActions }
                        }
                        return coach
                    })
                )
			}


			setIsLoading(false)
			navigate(`/admin/coaches/`)
		}

    }


    return (
        <>
            <Alert status='warning' mt={{ base: "120px", md: "90px" }} borderRadius="10px">
                <AlertIcon />
                <AlertTitle>Email is unique.</AlertTitle>
                <AlertDescription>Note that email address provided cannot be changed.</AlertDescription>
            </Alert>
            <Box mt="10px" borderRadius="md" boxShadow="md" bg="white" >
                {/* Header */}
                <Flex justifyContent='left' p='15px 0px 10px 20px' >
                    <Text fontSize='xl' color={textColor} fontWeight='bold' mr="200px">
                        Edit coach 
                    </Text>
                </Flex>

                {/* Form of their attributes */}
                <Flex direction='column' w="100%" alignContent="center" px="20px"  > 
                    
                    {/* First row */}
                    <Flex direction={{ sm: "column", lg: "row" }} w="100%">
                        <Flex w={{ sm: "100%", lg: "30%" }} mt="20px">
                            <FormControl isInvalid={formValues.name === ""}>
                                <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mr="15px" fontWeight='semibold' textAlign="justify">
                                    Name
                                </Text>
                                <Input isRequired={true} onChange={onChange} variant='flushed' placeholder='Name' size='sm' value={formValues.name} name="name"/>
                                {formValues.name === "" ? <FormErrorMessage>This field is mandatory</FormErrorMessage> : null}
                            </FormControl>
                        </Flex>
                        <Spacer />
                        <Flex alignItems='left' direction={'column'} w={{ sm: "100%", lg: "30%" }} mt="20px">
                            <FormControl isInvalid={(errors.email !== '' || formValues.contact_email === "")}>
                                <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mr="15px" fontWeight='semibold' textAlign="justify">
                                    Contact email
                                </Text>
                                <Input isRequired={true} onChange={onChange} variant='flushed' placeholder='Contact email' size='sm' value={formValues.contact_email} name="contact_email" isDisabled/>
                                {(errors.email !== '' || formValues.contact_email === "") ? <FormErrorMessage>{errors.email !== '' ? errors.email : 'This field is mandatory'}</FormErrorMessage> : null}
                            </FormControl>
                        </Flex>
                        <Spacer />
                        <Flex alignItems='left' direction={'column'} w={{ sm: "100%", lg: "30%" }} mt="20px" >
                            <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mb="5px" fontWeight='semibold' textAlign="justify">
                                Sport
                            </Text>
                            <Select
                                closeMenuOnSelect={true}
                                components={animatedComponents}
                                placeholder="Select sport"
                                isSearchable={true}
                                defaultValue={{value:formValues.sport,label:formValues.sport}}
                                options={[{value:"Soccer",label:"Soccer"},{value:"Tennis",label:"Tennis"}]}
                                onChange={(selectedOptions) => setFormValues(()=>({...formValues, sport: selectedOptions.value}))}
                            />
                        </Flex>
                    </Flex>
                    
                    {/* Second row */}
                    <Flex direction={{ sm: "column", lg: "row" }} w="100%" alignItems='center'>
                        {/* <Flex alignItems='left' direction={'column'} w={{ sm: "100%", lg: "30%" }} mt="20px" >
                            
                                <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mb="5px" fontWeight='semibold' textAlign="justify">
                                    Gender
                                </Text>
                                <Select
                                    closeMenuOnSelect={true}
                                    components={animatedComponents}
                                    placeholder="Select gender"
                                    isSearchable={true}
                                    defaultValue={{value:formValues.gender,label:formValues.gender === 'male' ? 'Male':'Female'}}
                                    options={[{value:"male",label:"Male"},{value:"female",label:"Female"}]}
                                    onChange={(selectedOptions) => setFormValues(()=>({...formValues, gender: selectedOptions.value}))}
                                />
                           
                        </Flex> */}
                        {/* <Spacer /> */}
                        <Flex alignItems='left' direction={'column'} w={{ sm: "100%", lg: "45%" }} mt="20px">
                            <FormControl isInvalid={errors.birthdate !== ''}>
                                <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mr="15px" fontWeight='semibold' textAlign="justify">
                                    Birthdate
                                </Text>
                                <Input onChange={onChange} variant='flushed' placeholder='YYYY-MM-DD' size='sm' value={formValues.birthdate} name="birthdate"/>
                                {errors.birthdate !== '' ? <FormErrorMessage>{errors.birthdate}</FormErrorMessage> : null}
                            </FormControl>
                        </Flex>
                        <Spacer />
                        <Flex alignItems='left' direction={'column'} w={{ sm: "100%", lg: "45%" }} mt="20px">
                            <FormControl isInvalid={errors.phone !== ''}>
                                <Flex direction="row" alignItems="center">
                                    <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mr="15px" fontWeight='semibold' textAlign="justify">
                                        Contact phone
                                    </Text>
                                    <Tooltip label={`Enter a phone number using the format: [+][country code][phone number]`} bg='royalblue.700'  placement='auto' >
                                        <span><Icon as={BsQuestionCircleFill} color="royalblue.700" /></span>
                                    </Tooltip>
                                </Flex>
                                <Input onChange={onChange} variant='flushed' placeholder='+16175551212' size='sm' value={formValues.contact_phone} name="contact_phone"/>
                                {errors.phone !== '' ? <FormErrorMessage>{errors.phone}</FormErrorMessage> : null}
                            </FormControl>
                        </Flex>
                        
                    </Flex>
                    {/* Third row */}
                    <Flex direction={{ sm: "column", lg: "row" }} w="100%" >
                        <Flex alignItems='left' direction={'column'} w={{ sm: "100%", lg: "30%" }} mt="20px" >
                            <FormControl isInvalid={formValues.teamID === ''}>
                                <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mb="5px" fontWeight='semibold' textAlign="justify">
                                    Team
                                </Text>
                                <Select
                                    closeMenuOnSelect={true}
                                    components={animatedComponents}
                                    placeholder="Select team"
                                    isSearchable={true}
                                    defaultValue={{value:formValues.teamID,label:`${teamOfCoach.name} - ${teamOfCoach.genre}`}}
                                    options={teams.map(team => ({value:team.id,label:`${team.name} - ${team.genre}` }))}
                                    onChange={(selectedOptions) =>  setFormValues(()=>({...formValues, teamID: selectedOptions.value}))}
                                />
                                {formValues.teamID === '' ? <FormErrorMessage>This field is mandatory</FormErrorMessage> : null}
                            </FormControl>
                           
                        </Flex>
                        <Spacer />
                        <Flex alignItems='left' direction={'column'} w={{ sm: "100%", lg: "30%" }} mt="20px">
                            <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mb="5px" fontWeight='semibold' textAlign="justify">
                                Role
                            </Text>
                            <Select
                                closeMenuOnSelect={true}
                                components={animatedComponents}
                                placeholder="Select role"
                                isSearchable={true}
                                defaultValue={roles.find(role => role.value === formValues.role)}
                                options={roles}
                                onChange={(selectedOptions) => setFormValues(()=>({...formValues, role: selectedOptions.value}))}
                            />
                        </Flex>
                        <Spacer />
                        <Flex alignItems='left' direction={'column'} w={{ sm: "100%", lg: "30%" }} mt="20px">
                            <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mb="5px" fontWeight='semibold' textAlign="justify">
                                Division
                            </Text>
                            <Select
                                closeMenuOnSelect={true}
                                components={animatedComponents}
                                placeholder="Select division"
                                isSearchable={true}
                                defaultValue={division.find(div => div.value === formValues.division)}
                                options={division}
                                onChange={(selectedOptions) => setFormValues(()=>({...formValues, division: selectedOptions.value}))}
                            />
                        </Flex>
                        
                    </Flex>
                     {/* Fourth row with drag and drop for logo*/}
                     <Flex direction="column" w="100%" mt="30px">
                        <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mb="5px" fontWeight='semibold' textAlign="justify">
                            Profile picture
                        </Text>
                        
                        {/* Component with dropzone for images */}
                        <ImageDropzone values={formValues} updateValues={setFormValues} propChanged="profile_pic"/>
                    </Flex>
                </Flex> 


                {/* Confirm and cancel buttons */}
                <Flex justifyContent='right' p='10px 40px 10px 0px' >
                    <Flex direction="column" my={5}>
                        <ButtonGroup spacing="6" justifyContent='right' mb="10px">

                            <Button
                                isLoading={isLoading}
                                loadingText='Submitting'
                                colorScheme='green'
                                variant='outline'
                                onClick={handleEdit}
                                isDisabled={!formHasChanged}
                            >
                                Submit
                            </Button>
                            <Button
                                colorScheme='red'
                                variant='outline'
                                onClick={()=>(navigate(`/admin/coaches/`))}
                                >
                                Cancel
                            </Button>

                        </ButtonGroup>
                        <Text fontSize={{ sm: "xs", lg: "sm" }} color={"gray.400"}>
                            <i>You must modify some fields to enable submit button*</i>
                        </Text>
                    </Flex>
                </Flex>
            </Box>
        </>
    )
}
