import React, { useContext } from 'react'
import {  Text, useColorModeValue, Flex, Spacer } from "@chakra-ui/react";
import { DataContext } from 'contexts/dataContext';

export const ShowCaseInfo = ({athlete}) => {

    const bgProfile = useColorModeValue(
		"hsla(0,0%,100%,.8)",
		"linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
	);
	const textColor = useColorModeValue("gray.700", "white");
	const emailColor = useColorModeValue("gray.400", "gray.300","royalblue.700");

    // Take the context data needed in this component
    const dataContext = useContext(DataContext)
    const { showcases, showcasesAthleteRelation } = dataContext

    const athleteShowcases = showcasesAthleteRelation.filter(it => it.athleteID === athlete.id)
    
    return (
        <>
            {
                athleteShowcases.length > 0
                ? (
                    <>
                    {
                        athleteShowcases.map((item, index) => (

                            item.showcase ? (
                            <>
                                <Text fontSize={{ sm: "sm", lg: "md" }} color={emailColor} >
                                <strong>Showcase participation {index + 1}</strong>
                                </Text>
                                <Flex mb={{ sm: "8px", md: "15px" }} direction="row" m="0 20px 0 10px">
                                    <Flex direction="column" >
                                        <Text fontSize={{ sm: "sm", lg: "md" }} color={emailColor}>
                                            Name
                                        </Text>
                                        <Flex direction="row">
                                        {
                                            item.showcase?.name
                                            ? (
                                                <Text fontSize={{ sm: "sm", lg: "md" }} color={textColor} mb={{ sm: "8px", md: "10px" }}>
                                                    {item.showcase?.name}
                                                </Text>
                                                )
                                                : (<Text fontSize="xs" color={textColor} mb={{ sm: "8px", md: "10px" }}>
                                                No Showcase name provided
                                            </Text>)
                                        }
                                        </Flex>
                                    </Flex>
                                    <Spacer />
                                    <Flex direction="column">
                                        <Text fontSize={{ sm: "sm", lg: "md" }} color={emailColor}>
                                            Country
                                        </Text>
                                        <Flex direction="row">
                                        {
                                            item.showcase?.contry
                                            ? (
                                                <Text fontSize={{ sm: "sm", lg: "md" }} color={textColor} mb={{ sm: "8px", md: "10px" }}>
                                                    {item.showcase?.contry}
                                                </Text>
                                                )
                                                : (<Text fontSize="xs" color={textColor} mb={{ sm: "8px", md: "10px" }}>
                                                No Showcase country provided
                                            </Text>)
                                        }
                                        </Flex>
                                    </Flex>
                                    <Spacer />
                                    <Flex direction="column">
                                        <Text fontSize={{ sm: "sm", lg: "md" }} color={emailColor}>
                                            Address
                                        </Text>
                                        <Flex direction="row">
                                        {
                                            item.showcase?.address
                                            ? (
                                                <Text fontSize={{ sm: "sm", lg: "md" }} color={textColor} mb={{ sm: "8px", md: "10px" }}>
                                                    {item.showcase?.address}
                                                </Text>
                                                )
                                                : (<Text fontSize="xs" color={textColor} mb={{ sm: "8px", md: "10px" }}>
                                                No Showcase address provided
                                            </Text>)
                                        }
                                        </Flex>
                                    </Flex>
                                    <Spacer />
                                    <Flex direction="column">
                                        <Text fontSize={{ sm: "sm", lg: "md" }} color={emailColor}>
                                            Date
                                        </Text>
                                        <Flex direction="row">
                                        {
                                            item.showcase?.date
                                            ? (
                                                <Text fontSize={{ sm: "sm", lg: "md" }} color={textColor} mb={{ sm: "8px", md: "10px" }}>
                                                    {item.showcase?.date}
                                                </Text>
                                                )
                                                : (<Text fontSize="xs" color={textColor} mb={{ sm: "8px", md: "10px" }}>
                                                No Showcase date provided
                                            </Text>)
                                        }
                                        </Flex>
                                    </Flex>
                                    <Spacer />
                                    <Flex direction="column">
                                        <Text fontSize={{ sm: "sm", lg: "md" }} color={emailColor}>
                                            Showcase team
                                        </Text>
                                        <Flex direction="row">
                                        {
                                            item.color
                                            ? (
                                                <Text fontSize={{ sm: "sm", lg: "md" }} color={textColor} mb={{ sm: "8px", md: "10px" }}>
                                                    Team {item.color}
                                                </Text>
                                                )
                                                : (<Text fontSize="xs" color={textColor} mb={{ sm: "8px", md: "10px" }}>
                                                No Showcase team provided
                                            </Text>)
                                        }
                                        </Flex>
                                    </Flex>
                                    <Spacer />
                                    <Flex direction="column">
                                        <Text fontSize={{ sm: "sm", lg: "md" }} color={emailColor}>
                                            Showcase jersey
                                        </Text>
                                        <Flex direction="row">
                                        {
                                            item.number
                                            ? (
                                                <Text fontSize={{ sm: "sm", lg: "md" }} color={textColor} mb={{ sm: "8px", md: "10px" }}>
                                                    Jersey #{item.number}
                                                </Text>
                                                )
                                                : (<Text fontSize="xs" color={textColor} mb={{ sm: "8px", md: "10px" }}>
                                                No Showcase team provided
                                            </Text>)
                                        }
                                        </Flex>
                                    </Flex>
                                </Flex>
                            </>
                            )
                            : (<Text fontSize={{ sm: "xs" }} color={textColor} mb={{ sm: "8px", md: "15px" }}>
                                No showcase participation provided
                            </Text>)
                        ))
                    }
                    </>
                
                )
                : (<Text fontSize={{ sm: "xs" }} color={textColor} mb={{ sm: "8px", md: "15px" }}>
                    No showcase participation provided
                </Text>)

            }
        </>
    )
}
