const createCollege = /* GraphQL */ `
  mutation CreateCollege(
    $input: CreateCollegeInput!
    $condition: ModelCollegeConditionInput
  ) {
    createCollege(input: $input, condition: $condition) {
      id
      name
      country
      state
      address
      logo
      createdAt
      updatedAt
    }
  }
`;
const updateCollege = /* GraphQL */ `
  mutation UpdateCollege(
    $input: UpdateCollegeInput!
    $condition: ModelCollegeConditionInput
  ) {
    updateCollege(input: $input, condition: $condition) {
      id
      name
      country
      state
      address
      logo
      createdAt
      updatedAt
    }
  }
`;
const deleteCollege = /* GraphQL */ `
  mutation DeleteCollege(
    $input: DeleteCollegeInput!
    $condition: ModelCollegeConditionInput
  ) {
    deleteCollege(input: $input, condition: $condition) {
      id
      name
      country
      state
      address
      logo
      createdAt
      updatedAt
    }
  }
`;

export { createCollege, updateCollege, deleteCollege };