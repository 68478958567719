import React, { useState } from 'react'
import { Flex, Icon, Select, Table, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue } from '@chakra-ui/react'
import {BsArrowDown, BsArrowUp} from 'react-icons/bs'
import { MdStar, MdStarOutline } from 'react-icons/md';

export const ActionsReceivedByCoaches = ({favorites, enquiries, tags, coachTagsRel}) => {

    // Chakra color mode
	const textColor = useColorModeValue("gray.700", "white");

    const tagsWithCoach = tags?.map(tag =>( 
        {
            ...tag, 
            ...(coachTagsRel?.find(rel => rel.tagID === tag.tagID)?.coach && {coach: coachTagsRel?.find(rel => rel.tagID === tag.tagID)?.coach})
        }
    ))
    
    let allActions = []
    if((favorites?.length + enquiries?.length + tags?.length + tagsWithCoach?.length) > 0){
        allActions = [...favorites?.map(it=>({...it,typeOfAction:'Favorite'})), ...enquiries?.map(it=>({...it,typeOfAction:'Enquiry'})), ...tagsWithCoach?.map(it=>({...it,typeOfAction:'Tag'}))]
    }
    // State that control if sort is ascending or descending
	const [ sortAscending, setSortAscending ] = useState(true)

    // State with filters
    const [ filters, setFilters ] = useState({
		action:0,
		actionData: ['ALL', 'Enquiry','Favorite','Tag'],
        university: 0,
        universityData: ['ALL',...new Set(allActions.map(it=>it.coach?.team?.name))],
	})

    const filterData = (data) => {
		let newData = data
		if (filters.action === 0 && filters.university === 0) {newData = data}
		if (filters.action>0){ newData = newData.filter(item => item.typeOfAction === filters.actionData[filters.action]) }
		if (filters.university > 0){ newData = newData.filter(item => item.coach?.team?.name === filters.universityData[filters.university]) }

        return newData.sort((a,b) => {
            if(sortAscending){return new Date(a.createdAt) - new Date(b.createdAt)}
            else{return new Date(b.createdAt) - new Date(a.createdAt)}
        })
	}


    return (
        <>
            <Table variant='simple' color={textColor}>
                <Thead>
                    <Tr my='.8rem' pl='0px' color='gray.400'>
                        <Th color='gray.400' cursor="pointer" onClick={()=>setSortAscending(!sortAscending)}>
                            Date {sortAscending ? <Icon as={BsArrowUp} color="gray.400" /> : <Icon as={BsArrowDown} color="gray.400" />}
                        </Th>
                        <Th color='gray.400' minW="170px">
                            Type of action
                            <Select variant='unstyled' size="xs" value={filters.action} onChange={(e)=>setFilters({...filters, action:parseInt(e.target.value)})}>
                                {filters.actionData.map((opt,index) => {return (<option key={index} value={index} color="royalblue">{opt}</option>)})}
                            </Select>
                        </Th>
                        <Th color='gray.400'>
                            Team
                            <Select variant='unstyled' size="xs" value={filters.university} onChange={(e)=>setFilters({...filters, university:parseInt(e.target.value)})}>
                                {filters.universityData.map((opt,index) => {return (<option key={index} value={index} color="royalblue">{opt}</option>)})}
                            </Select>
                        </Th>
                        <Th color='gray.400'>
                            Additional info
                        </Th>
                    </Tr>
                </Thead>
                <Tbody>
                        {
                            filterData(allActions).map((action,idx) => {
                                return (
                                    <Tr key={idx} my='.8rem' pl='0px'>
                                        <Td w="10%" fontSize="sm" color="gray.400" fontWeight="normal">
                                            {new Date(action.createdAt).toLocaleDateString()}
                                        </Td>
                                        <Td w="10%" fontSize="sm" color="gray.400" fontWeight="normal">
                                            {action.typeOfAction}
                                        </Td>
                                        <Td w="30%" fontSize="sm" color="gray.400" fontWeight="normal">
                                            <Flex direction="column">
                                                <Text fontSize='sm' color='gray.600' fontWeight={"bold"} >
                                                    {action.coach?.name} 
                                                </Text>
                                                <Text fontSize='sm' color='gray.400' >
                                                    Email: <i><strong>{action.coach?.contact_email}</strong></i>
                                                </Text>
                                                <Text fontSize='sm' color='gray.400'  >
                                                    University: <i><strong>{action.coach?.team?.name}</strong></i>
                                                </Text>
                                                {action.coach?.division && (<Text fontSize='sm' color='gray.400'  >
                                                    Division: <i><strong>{action.coach?.division}</strong></i>
                                                </Text>)}
                                               
                                            </Flex>
                                        </Td>
                                        <Td w="70%" fontSize="sm" color="gray.400" fontWeight="normal">
                                            { (action.typeOfAction === 'Favorite') && (
                                                // If there is rating (PREMIUM)
                                                action.rating ? (
                                                    // Show filled star and/or outline star
                                                    action.rating === 5 ? (
                                                        Array.from(Array(5)).map(it=><Icon as={MdStar}/>)
                                                    ) : (
                                                        <>
                                                            {Array.from(Array(action.rating)).map(it=><Icon as={MdStar}/>) }
                                                            {Array.from(Array(5-action.rating)).map(it=><Icon as={MdStarOutline}/>)}
                                                        </>
                                                    )
                                                ) : ('No rating provided')
                                            ) }
                                            {action.typeOfAction === 'Enquiry' && (action.enquiry_text ? action.enquiry_text : 'No enquiry text provided')}
                                            {action.typeOfAction === 'Tag' && (action.tag ? action.tag.text : 'No tag provided')}
                                        </Td>
                                    </Tr>       
                                )
                            })
                        }
                </Tbody>
            </Table>
        </>
    )
}
