// React imports
import React, { useContext, useState, useEffect, useRef} from 'react'
import { useNavigate } from 'react-router-dom';
// Context imports
import { DataContext } from "../../../../contexts/dataContext";
// Chackra Components
import { Flex, Text, Box, Button, ButtonGroup, AlertDialogCloseButton } from "@chakra-ui/react";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import { Alert,AlertTitle,AlertDescription,List,ListItem,AlertIcon, CloseButton, useDisclosure, UnorderedList} from '@chakra-ui/react'
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
} from "@chakra-ui/react";
// Auxiliary imports
import { uploadImageToCloudinary } from "../../../../helpers/uploadImageToCloudinary";
import { uploadVideoToCloudinary } from 'helpers/uploadVideoToCloudinary';
import { v4 as uuidv4 } from 'uuid';
import validator from "validator";
import { createAthleteService } from "../../../../services/athleteServices";
// Components 
import Card from "components/Card/Card.js";
import SocialNetworksFields from "../AthleteEditableFields/SocialNetworksFields";
import ShowcaseFields from "../AthleteEditableFields/ShowcaseFields";
import PersonalInfoFields from "../AthleteEditableFields/PersonalInfoFields";
import AthleticInfoFields from "../AthleteEditableFields/AthleticInfoFields";
import EducationFields from "../AthleteEditableFields/EducationFields";
import { athleteHasAllRequiredFields } from 'helpers';
import { whichFieldsAreNotFilledFromAthlete } from 'helpers';
import { changeNameFieldToString } from 'helpers';
import CategorizationField from '../AthleteEditableFields/CategorizationField';

export const CreateAthlete = () => {

	// Hook for the confirm modal
	const { isOpen, onOpen, onClose } = useDisclosure()
	const cancelRef = useRef()

	// navigation hook
	let navigate = useNavigate()

	// Take the context data needed in this component
    const dataContext = useContext(DataContext)
    const { athletes, setAthletes, teams } = dataContext

	// State that controls the form errors
	// errores que tengo que comprobar al hacer submit y que comparten todos los componentes
	const [formErrors, setFormErrors] = useState({
		birthdate: '',
		contact_email: '',
		contact_phone: '',
		other_videos: '',
		// showcase_participation: '',
		high_school_graduation_date: '',
		sat_date: '',
		act_date: '',
		gemat_gre_date: '',
		toefl_date: '',
		duolingo_date: '',
		ielts_date: '',
		facebook_url: '',
		twitter_user: '',
		linkedin_url: '',
	})
	// State with the mandatory fields that are empty
	const [emptyFields, setEmptyFields] = useState([])

	// State with social networks section form values
	const initialValuesPersonalInfo = {	name: '',agencyID: '',agentID: '' }
	const [personalInfoValues, setPersonalInfoValues] = useState(initialValuesPersonalInfo)

	// State with social networks section form values
	const [athleticInfoValues, setAthleticInfoValues] = useState({})
	// State with social networks section form values
	// const [showcaseValues, setShowcaseValues] = useState([])
	// State with social networks section form values
	const [educationValues, setEducationValues] = useState([])
	// State with social networks section form values
	const [socialNetworksValues, setSocialNetworksValues] = useState({})
	// State with social networks section form values
	const [categoryValue, setCategoryValue] = useState('')

	const [aditionalInfo,setAditionalInfo] = useState({gender: personalInfoValues.gender && personalInfoValues.gender})


	// State to check if form has changed and enable submit button
	const [formHasChanged, setFormHasChanged] = useState(false)
	// State that controls if the submit is loading
	const [isLoading, setIsLoading] = useState(false)
	// State that allow to show the error message
	const [showError, setShowError] = useState(false)

	// When the form values change, check that the mandatory fields are filled and enable the submit button
	useEffect(() => {
		// If there is a change in one tab, enable the submit button
		(personalInfoValues.name !== '' && personalInfoValues.agencyID !== '' && personalInfoValues.agentID !== '') && (categoryValue !== '' || JSON.stringify(personalInfoValues) !== JSON.stringify(initialValuesPersonalInfo) || JSON.stringify(athleticInfoValues) !== JSON.stringify({}) || JSON.stringify(educationValues) !== JSON.stringify({}) || JSON.stringify(socialNetworksValues) !== JSON.stringify({}) ) ? setFormHasChanged(true) : setFormHasChanged(false)
	}, [personalInfoValues, athleticInfoValues, educationValues, socialNetworksValues,categoryValue])


	const handleCanCreate = () => {

		setIsLoading(true)

		let checkErrors = {
			birthdate: '',
			contact_email: '',
			contact_phone: '',
			other_videos: '',
			// showcase_participation: '',
			high_school_graduation_date: '',
			sat_date: '',
			act_date: '',
			gemat_gre_date: '',
			toefl_date: '',
			duolingo_date: '',
			ielts_date: '',
			facebook_url: '',
			twitter_user: '',
			linkedin_url: '',
		}

		// Check if there is some error in the form
		// Personal info
		personalInfoValues.contact_email && !validator.isEmail(personalInfoValues.contact_email)
		? checkErrors.contact_email = 'Please enter a valid email' : checkErrors.contact_email = ''
		
		personalInfoValues.contact_phone && !validator.isMobilePhone(personalInfoValues.contact_phone, 'any',{ strictMode: true })
		? checkErrors.contact_phone = 'Please enter a valid phone number' : checkErrors.contact_phone = ''

		personalInfoValues.birthdate && !validator.isDate(personalInfoValues.birthdate, {format: 'YYYY-MM-DD',delimiters: ['-']})
		? checkErrors.birthdate = 'Please use YYYY-MM-DD' : checkErrors.birthdate = ''

		// Athletic info
		athleticInfoValues.other_videos && athleticInfoValues.other_videos.map(video => {
			!validator.isURL(video.url) 
			? checkErrors.other_videos = 'Please enter a valid url in all videos'
			: checkErrors.other_videos = ''
		})
		
		if(athleticInfoValues.current_status === 'COMMITTED'){
			athleticInfoValues.date_commitment && !validator.isDate(athleticInfoValues.date_commitment, {format: 'YYYY-MM-DD',delimiters: ['-']})
			? checkErrors.date_commitment = 'Please use YYYY-MM-DD' : checkErrors.date_commitment = ''
		}

		// Showcase info
		// showcaseValues.showcase_participation && showcaseValues.showcase_participation.map(showcase => {
		// 	!validator.isDate(showcase.date, {format: 'YYYY-MM-DD',delimiters: ['-']})
		// 	? checkErrors.showcase_participation = 'Please enter a valid date in all showcases'
		// 	: checkErrors.showcase_participation = ''
		// })

		// Education info
		educationValues.high_school_graduation_date && !validator.isDate(educationValues.high_school_graduation_date, {format: 'YYYY-MM-DD',delimiters: ['-']})
		? checkErrors.high_school_graduation_date = 'Please use YYYY-MM-DD' : checkErrors.high_school_graduation_date = ''

		educationValues.sat_date && !validator.isDate(educationValues.sat_date, {format: 'YYYY-MM-DD',delimiters: ['-']})
		? checkErrors.sat_date = 'Please use YYYY-MM-DD' : checkErrors.sat_date = ''

		educationValues.act_date && !validator.isDate(educationValues.act_date, {format: 'YYYY-MM-DD',delimiters: ['-']})
		? checkErrors.act_date = 'Please use YYYY-MM-DD' : checkErrors.act_date = ''

		educationValues.gemat_gre_date && !validator.isDate(educationValues.gemat_gre_date, {format: 'YYYY-MM-DD',delimiters: ['-']})
		? checkErrors.gemat_gre_date = 'Please use YYYY-MM-DD' : checkErrors.gemat_gre_date = ''

		educationValues.toefl_date && !validator.isDate(educationValues.toefl_date, {format: 'YYYY-MM-DD',delimiters: ['-']})
		? checkErrors.toefl_date = 'Please use YYYY-MM-DD' : checkErrors.toefl_date = ''

		educationValues.duolingo_date && !validator.isDate(educationValues.duolingo_date, {format: 'YYYY-MM-DD',delimiters: ['-']})
		? checkErrors.duolingo_date = 'Please use YYYY-MM-DD' : checkErrors.duolingo_date = ''

		educationValues.ielts_date && !validator.isDate(educationValues.ielts_date, {format: 'YYYY-MM-DD',delimiters: ['-']})
		? checkErrors.ielts_date = 'Please use YYYY-MM-DD' : checkErrors.ielts_date = ''

		// social network info
		socialNetworksValues.facebook_url && !validator.isURL(socialNetworksValues.facebook_url)
		? checkErrors.facebook_url = 'Please enter a valid url' : checkErrors.facebook_url = ''

		socialNetworksValues.twitter_user && !validator.isURL(socialNetworksValues.twitter_user)
		? checkErrors.twitter_user = 'Please enter a valid url' : checkErrors.twitter_user = ''

		socialNetworksValues.linkedin_url && !validator.isURL(socialNetworksValues.linkedin_url)
		? checkErrors.linkedin_url = 'Please enter a valid url' : checkErrors.linkedin_url = ''

		// Reduce the object to check if there is at least one error in the form.
		const errorsWithoutEmptyValues = Object.keys(checkErrors).reduce((obj, key) => {
            checkErrors[key] !== "" ? obj[key] = checkErrors[key] : null
            return obj
        }, {})

		Object.keys(errorsWithoutEmptyValues).length > 0 
		? (setFormErrors({...formErrors,...checkErrors}), setShowError(true), setIsLoading(false))
		: handleWillCreate()
	}

	const handleWillCreate = () => {
		var checkAthlete = Object.assign({}, personalInfoValues,athleticInfoValues,educationValues,socialNetworksValues,{category:categoryValue});

		for (const key in checkAthlete) {
			if (checkAthlete[key] === null || checkAthlete[key] === "") {
				delete checkAthlete[key];
			}
		}

		whichFieldsAreNotFilledFromAthlete(checkAthlete).length > 0 
		? (onOpen(),setEmptyFields(whichFieldsAreNotFilledFromAthlete(checkAthlete)))
		: handleCreate()
	}
	const handleCreate = async() => {

		var newAthlete = Object.assign({}, personalInfoValues,athleticInfoValues,educationValues,socialNetworksValues,{category:categoryValue});

		for (const key in newAthlete) {
			if (newAthlete[key] === null || newAthlete[key] === "") {
				delete newAthlete[key];
			}
		}
		// If they edit a PSA from COMMITED to another state, this field is not used.
		if(newAthlete.current_status !== 'COMMITTED'){
			newAthlete['destination_college'] && delete newAthlete['destination_college']
			newAthlete['reason_commitment'] && delete newAthlete['reason_commitment']
			newAthlete['date_commitment'] && delete newAthlete['date_commitment']
		}

		// To keep the same id for the new element
		const newId = uuidv4()

		const url_profile_pic = newAthlete.profile_pic 
					? await uploadImageToCloudinary({image:newAthlete.profile_pic,directory:'psas_profile_pics'}).then((url) => url)
					: null

		const url_card_pic = newAthlete.card_pic 
							? await uploadImageToCloudinary({image:newAthlete.card_pic,directory:'psas_card_pictures'}).then((url) => url)
							: null

		if('featured_video' in newAthlete){
			const url = newAthlete['featured_video']['url']
			if(url.search('q_auto') === -1){
				const splited = url.split('upload')
				newAthlete['featured_video']['url'] = splited[0]+'upload/q_auto'+splited[1]
			}
		}
		
		createAthleteService({
			id: newId,
			...newAthlete,
			isVisible: athleteHasAllRequiredFields(newAthlete) ? (newAthlete.current_status === 'ON_HOLD' ? false : newAthlete.isVisible) : false,
			...(url_profile_pic && {profile_pic: url_profile_pic}), 
			...(url_card_pic && {card_pic: url_card_pic}),
			...((newAthlete.current_status && newAthlete.current_status === 'AVAILABLE') && {start_availability_date: `${new Date().getFullYear()}-${(new Date().getMonth()+1)<10 ? '0'+(new Date().getMonth()+1) : new Date().getMonth()+1}-${new Date().getDate()<10 ? '0'+new Date().getDate() : new Date().getDate()}`} ),
			...((newAthlete.current_status && newAthlete.current_status === 'COMMITTED') && {end_availability_date: `${new Date().getFullYear()}-${(new Date().getMonth()+1)<10 ? '0'+(new Date().getMonth()+1) : new Date().getMonth()+1}-${new Date().getDate()<10 ? '0'+new Date().getDate() : new Date().getDate()}`} ),	
	})
		setAthletes([
			...athletes, 
			{
				id: newId,
				...newAthlete,
				isVisible: athleteHasAllRequiredFields(newAthlete) ? (newAthlete.current_status === 'ON_HOLD' ? false : newAthlete.isVisible) : false,
				...(url_profile_pic && {profile_pic: url_profile_pic}), 
				...(url_card_pic && {card_pic: url_card_pic}),
				...((newAthlete.current_status && newAthlete.current_status === 'AVAILABLE') && {start_availability_date: `${new Date().getFullYear()}-${(new Date().getMonth()+1)<10 ? '0'+(new Date().getMonth()+1) : new Date().getMonth()+1}-${new Date().getDate()<10 ? '0'+new Date().getDate() : new Date().getDate()}`} ),
				...((newAthlete.current_status && newAthlete.current_status === 'COMMITTED') && {end_availability_date: `${new Date().getFullYear()}-${(new Date().getMonth()+1)<10 ? '0'+(new Date().getMonth()+1) : new Date().getMonth()+1}-${new Date().getDate()<10 ? '0'+new Date().getDate() : new Date().getDate()}`} ),	
			}
		])

		setIsLoading(false)
		navigate(`/admin/athletes/show/${newId}`)

	}
	const onCloseCustom = () => {
		onClose()
		setIsLoading(false)
	}

	return (
		<>
			{
				showError
				? (
                    <Alert status='error' mt="60px" mb="10px" borderRadius="10px">
						<AlertIcon />
                        <Box flex='1'>
						<AlertTitle>The following fields have some error:</AlertTitle>
                            <AlertDescription display='block'>
                                <List spacing={2} ml="15px">
                                    {
										Object.keys(formErrors).map((key, index) => {
											if (formErrors[key] !== '') {
												return (
													<ListItem key={index}>
														Field <i>{key}</i>
													</ListItem>
												)
											}
										})
									}
                                </List>
                            </AlertDescription>
                        </Box>
						<CloseButton position='absolute' right='8px' top='8px' onClick={()=>(setShowError(false))} />
                    </Alert>
				)
				: null
			}
			<Alert status='warning' mt={!showError && { base: "120px", md: "90px" }} borderRadius="10px">
                <AlertIcon />
                <AlertTitle>Some fields are required.</AlertTitle>
                <AlertDescription>If any of the required fields is not filled in, the athlete will be created but will not be visible in the app.</AlertDescription>
            </Alert>

			{/* For the user */}
			<AlertDialog
				isOpen={isOpen}
				leastDestructiveRef={cancelRef}
				onClose={onCloseCustom}
				motionPreset='scale'
				closeOnEsc={false}
				closeOnOverlayClick={false}
			>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize='lg' fontWeight='bold'>
						    Create athlete
						</AlertDialogHeader>

						<AlertDialogCloseButton />

						<AlertDialogBody>
							There are requires fields that have not been filled in yet so the profile will be created but it will not be visible to the Coaches. Content quality is key for the platform so please fill out the following fields:	
							<UnorderedList>
								{
									emptyFields?.map((f,idx) => (
										<ListItem key={idx}>{changeNameFieldToString(f)}</ListItem>
									))
								}
							</UnorderedList>
						</AlertDialogBody>

						<AlertDialogFooter>
						<Button colorScheme={"red"} onClick={handleCreate} ml={3}>
							Got it! Create
						</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
			{/* ----- */}

			<Card overflowX={{ sm: "scroll", xl: "hidden" }}  mt="10px">
				<Tabs variant='soft-rounded' colorScheme={athleticInfoValues?.sport === "Tennis" ? "green" : "blue"} >
					<TabList>
						<Tab>Personal information</Tab>
						<Tab>Athletic information</Tab>
						<Tab>Education</Tab>
						{/* <Tab>Showcase participation</Tab> */}
						<Tab>Social networks</Tab>
						<Tab>Category</Tab>
					</TabList>

					<TabPanels>
						<TabPanel>
							<PersonalInfoFields formValues={personalInfoValues} setFormValues={setPersonalInfoValues} formErrors={formErrors} aditionalInfo={aditionalInfo} setAditionalInfo={setAditionalInfo}/>
						</TabPanel>

						<TabPanel>
							<AthleticInfoFields formValues={athleticInfoValues} setFormValues={setAthleticInfoValues} formErrors={formErrors} aditionalInfo={{gender:personalInfoValues.gender}}/>
						</TabPanel>

						<TabPanel>
							<EducationFields formValues={educationValues} setFormValues={setEducationValues} formErrors={formErrors}/>							
						</TabPanel>

						{/* <TabPanel>
							<ShowcaseFields formValues={showcaseValues} setFormValues={setShowcaseValues} formErrors={formErrors}/>
						</TabPanel> */}
								
						<TabPanel>
							<SocialNetworksFields formValues={socialNetworksValues} setFormValues={setSocialNetworksValues} formErrors={formErrors}/>
						</TabPanel>
						
						<TabPanel>
							{/* <SocialNetworksFields formValues={socialNetworksValues} setFormValues={setSocialNetworksValues} formErrors={formErrors}/> */}
							<CategorizationField formValues={categoryValue} setFormValues={setCategoryValue} teams={teams} athletes={athletes} gender={personalInfoValues.gender}/>
						</TabPanel>
					</TabPanels>
				</Tabs>
				{/* Confirm and cancel buttons */}
                <Flex justifyContent='right' p='10px 40px 10px 0px' >
                    <Flex direction="column" >
                        <ButtonGroup spacing="6" justifyContent='right' mb="10px">

                            <Button
                                isLoading={isLoading}
                                loadingText='Submitting'
                                colorScheme='green'
                                variant='outline'
                                onClick={handleCanCreate}
                                isDisabled={!formHasChanged}
                            >
                                Submit
                            </Button>
                            <Button
                                colorScheme='red'
                                variant='outline'
                                onClick={()=>(navigate(`/admin/athletes/`))}
                                >
                                Cancel
                            </Button>

                        </ButtonGroup>
                        <Text fontSize={{ sm: "xs", lg: "sm" }} color={"gray.400"}>
                            <i>You must modify some fields to enable submit button*</i>
                        </Text>
                    </Flex>
                </Flex>
			</Card>
		</>
	)
}
