// React imports
import React, { useContext, useState, useEffect} from 'react'
// Context imports
import { DataContext } from "../../../../contexts/dataContext";
// React-select imports
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
// Chackra Components
import { Flex, useColorModeValue, Text, FormControl,FormErrorMessage, Input } from "@chakra-ui/react";
import { Switch, Divider, Spacer, Icon, Tooltip } from '@chakra-ui/react'
import { GoAlert } from 'react-icons/go';
import { BsQuestionCircleFill } from "react-icons/bs";

// Auxiliary imports
import { countries } from "../../../../helpers"
// Components 
import { ImageDropzone } from "../../../../components/Dropzone/ImageDropzone";


const PersonalInfoFields = ({formValues,setFormValues, formErrors, aditionalInfo,setAditionalInfo}) => {
    const textColor = useColorModeValue("gray.700", "white");
    const animatedComponents = makeAnimated();

    // Take the context data needed in this component
    const dataContext = useContext(DataContext)
    const { agencies, agents } = dataContext

    // By default, the athlete will be hidden if there is no isVisible property 
    useEffect(() => {!formValues.isVisible && setFormValues(()=>({...formValues, isVisible: false}))}, [])

	const handleVisibleApp = (e) => {
		setFormValues(()=>({...formValues, isVisible: e.target.checked}))
	}
    
	const onChange = (e) => {
        e.persist()
        setFormValues(() => ({ ...formValues, [e.target.name]: e.target.value }))
    }

    const getAgentsOptions = (agencyID) => {
		const agentsOptions = agents.filter(agent => agent.agencyID === agencyID)
		return agentsOptions.map(agent => ({ value: agent.id, label: agent.name }))
	}

    const handleChangeGender = (selectedOptions) => {
        setAditionalInfo(()=>({...aditionalInfo, gender: selectedOptions.value}))
		setFormValues(()=>({...formValues, gender: selectedOptions.value}))
	}


    return (
        <>
            {/* First row */}
            <Flex direction="column" w="100%">
                <Text fontSize={{ sm: "md", md: "md" }} color={textColor} mt="10px" fontWeight='semibold' >
                Athlete visible in the app
                </Text>
                <Flex w={{ sm: "100%", lg: "45%" }} mt="5px" alignItems="center">
                    Hidden
                    <Switch mx="5px" size='md' defaultChecked={formValues.isVisible || false} value={formValues.isVisible || false} onChange={handleVisibleApp}/>
                    Visible
                </Flex>
            </Flex>
            
            {/* Second row */}
            <Flex direction={{ sm: "column", lg: "row" }} w="100%">
                <Flex w={{ sm: "100%", lg: "30%" }} mt="20px" alignItems="center">
                    <FormControl isInvalid={formValues.name === ""}>
                        <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mr="15px" fontWeight='semibold' textAlign="justify">
                            Complete name
                        </Text>
                        <Input isRequired={true} onChange={onChange} variant='flushed' placeholder='Name' size='sm' value={formValues.name || ''} name="name"/>
                        {formValues.name === "" ? <FormErrorMessage>This field is mandatory</FormErrorMessage> : null}
                    </FormControl>
                </Flex>
                <Spacer />
                <Flex alignItems='left' w={{ sm: "100%", lg: "30%" }} mt="20px" >
                    <FormControl isInvalid={formValues.agencyID === ''}>
                        <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mb="5px" fontWeight='semibold' textAlign="justify">
                            Agency
                        </Text>
                        <Select
                            closeMenuOnSelect={true}
                            components={animatedComponents}
                            placeholder="Select agency"
                            isSearchable={true}
                            defaultValue= {formValues.agencyID && { value: formValues.agencyID, label: agencies.find(agency => agency.id === formValues.agencyID).name } }
                            options={agencies.map(agency => ({value:agency.id,label:agency.name}))}
                            onChange={(selectedOptions) =>  setFormValues(()=>({...formValues, agencyID: selectedOptions.value}))}
                        />
                        {formValues.agencyID === '' ? <FormErrorMessage>This field is mandatory</FormErrorMessage> : null}
                    </FormControl>
                </Flex>
                <Spacer />
                <Flex alignItems='left' w={{ sm: "100%", lg: "30%" }} mt="20px" >
                    <FormControl isInvalid={formValues.agentID === ''}>
                        <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mb="5px" fontWeight='semibold' textAlign="justify">
                            Agent
                        </Text>
                        <Select
                            closeMenuOnSelect={true}
                            components={animatedComponents}
                            placeholder={formValues.agencyID === '' ? "First select an agency" : "Select agent"}
                            isSearchable={true}
                            isDisabled={formValues.agencyID === ''}
                            defaultValue= {formValues.agentID && { value: formValues.agentID, label: agents.find(agent => agent.id === formValues.agentID).name }}
                            options={getAgentsOptions(formValues.agencyID)}
                            onChange={(selectedOptions) =>  setFormValues(()=>({...formValues, agentID: selectedOptions.value}))}
                        />
                        {formValues.agentID === '' ? <FormErrorMessage>This field is mandatory</FormErrorMessage> : null}
                    </FormControl>
                </Flex>
            </Flex>
            {/* Second row */}
            <Flex direction={{ sm: "column", lg: "row" }} w="100%" alignItems="center">
                <Flex alignItems='left' w={{ sm: "100%", lg: "30%" }} mt="20px" >
                    <FormControl isInvalid={formErrors.contact_email !== ''}>
                        <Flex direction="row" alignItems="center">
                            <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mr="15px" fontWeight='semibold' textAlign="justify">
                                Email
                            </Text>
                            <Tooltip label={`This field is required.`} bg='orange.300'  placement='auto' >
                                <span><Icon as={GoAlert} color="orange"/></span>
                            </Tooltip>
                        </Flex>
                        <Input onChange={onChange} variant='flushed' placeholder='Contact email' size='sm' value={formValues.contact_email || ''} name="contact_email"/>
                        {formErrors.contact_email !== '' ? <FormErrorMessage>{formErrors.contact_email}</FormErrorMessage> : null}
                    </FormControl>
                </Flex>
                <Spacer />
                <Flex alignItems='left' w={{ sm: "100%", lg: "30%" }} mt="20px" >
                    <FormControl isInvalid={formErrors.contact_phone !== ''}>
                        <Flex direction="row" alignItems="center">
                            <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mr="15px" fontWeight='semibold' textAlign="justify">
                                Phone
                            </Text>
                            <Tooltip label={`Enter a phone number using the format: [+][country code][phone number]`} bg='royalblue.700'  placement='auto' >
                                <span><Icon as={BsQuestionCircleFill} color="royalblue.700" /></span>
                            </Tooltip>
                        </Flex>
                        <Input onChange={onChange} variant='flushed' placeholder='+16175551212' size='sm' value={formValues.contact_phone || ''} name="contact_phone"/>
                        {formErrors.contact_phone !== '' ? <FormErrorMessage>{formErrors.contact_phone}</FormErrorMessage> : null}
                    </FormControl>
                </Flex>
                <Spacer />
                <Flex w={{ sm: "100%", lg: "30%" }} mt="20px" alignItems="center">
                    <FormControl isInvalid={formErrors.birthdate && formErrors.birthdate !== ''}>
                        <Flex direction="row" alignItems="center">
                            <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mr="15px" fontWeight='semibold' textAlign="justify">
                                Birthdate
                            </Text>
                            <Tooltip label={`This field is required.`} bg='orange.300'  placement='auto' >
                                <span><Icon as={GoAlert} color="orange"/></span>
                            </Tooltip>
                        </Flex>
                        <Input onChange={onChange} variant='flushed' placeholder='YYYY-MM-DD' size='sm' value={formValues.birthdate || ''} name="birthdate"/>
                        {formErrors.birthdate && formErrors.birthdate !== '' ? <FormErrorMessage>{formErrors.birthdate}</FormErrorMessage> : null}
                    </FormControl>
                </Flex>								
            </Flex>
            <Divider mt="25px" />

            {/* Third row */}
            <Flex direction={{ sm: "column", lg: "row" }} w="100%" alignItems="center">
                
                <Flex w={{ sm: "100%", lg: "45%" }} direction='column' mt="20px" alignItems="left">
                    <Flex direction="row" alignItems="center">
                        <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mb="5px" mr="10px" fontWeight='semibold' textAlign="justify">
                            Nationality
                        </Text>
                        <Tooltip label={`This field is required.`} bg='orange.300'  placement='auto' >
                            <span><Icon as={GoAlert} color="orange"/></span>
                        </Tooltip>
                    </Flex>
                    <Select
                        closeMenuOnSelect={true}
                        components={animatedComponents}
                        placeholder="Select nationality"
                        isSearchable={true}
                        defaultValue={formValues.nationality && {value:formValues.nationality,label:formValues.nationality}}
                        options={countries}
                        onChange={(selectedOptions) =>  setFormValues(()=>({...formValues, nationality: selectedOptions.value}))}
                    />
                </Flex>
                <Spacer />
                <Flex alignItems='left' direction='column' w={{ sm: "100%", lg: "45%" }} mt="20px">
                    <Flex direction="row" alignItems="center">
                        <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mb="5px" mr="10px" fontWeight='semibold' textAlign="justify">
                            Gender
                        </Text>
                        <Tooltip label={`This field is required.`} bg='orange.300'  placement='auto' >
                            <span><Icon as={GoAlert} color="orange"/></span>
                        </Tooltip>
                    </Flex>
                    <Select
                        closeMenuOnSelect={true}
                        components={animatedComponents}
                        placeholder="Select your gender"
                        isSearchable={true}
                        defaultValue={formValues.gender && {value:formValues.gender,label:formValues.gender[0].toUpperCase()+formValues.gender.slice(1)}}
                        options={[{value:"male",label:"Male"},{value:"female",label:"Female"}]}
                        onChange={(selectedOptions) => handleChangeGender(selectedOptions)}
                        
                    />
                </Flex>
            </Flex>
            {/* Fourth row with drag and drop for logo*/}
            <Flex direction="column" w="100%" mt="30px">
                <Flex direction="row" alignItems="center">
                    <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mb="5px" mr="10px" fontWeight='semibold' textAlign="justify">
                        Profile picture
                    </Text>
                    <Tooltip label={`This field is required.`} bg='orange.300'  placement='auto' >
                        <span><Icon as={GoAlert} color="orange"/></span>
                    </Tooltip>
                </Flex>
                
                {/* Component with dropzone for images */}
                <ImageDropzone values={formValues} updateValues={setFormValues} propChanged="profile_pic"/>
            </Flex>
        </>
    )
}

const myPropsChanged = (prevProps, nextProps) => {
    if ( prevProps.formValues !== nextProps.formValues || prevProps.formErrors !== nextProps.formErrors ) {
        return false;
    }
    return true;
}

export default React.memo(PersonalInfoFields, myPropsChanged);