export const getAthlete = /* GraphQL */ `
  query GetAthlete($id: ID!) {
    getAthlete(id: $id) {
      id
      agencyID
      agentID
      name
      isVisible
      profile_pic
      birthdate
      gender
      contact_email
      contact_phone
      nationality
      country_of_competition
      target_division
      compliance
      card_pic
      other_nice_pics
      agency_assesment
      elegibility_notes
      current_club
      current_status
      start_availability_date
      end_availability_date
      destination_college
      ncaa_id
      type_of_admission
      weight
      height
      sport
      tennis_profile {
        utr
        national_ranking
        international_ranking
        national_ranking_adult
        international_ranking_adult
        DominantHand
      }
      position
      second_position
      dominant_foot
      featured_video {
        url
        metadata
      }
      other_videos {
        url
        metadata
      }
      level_of_competition
      sports_background
      showcase_participation {
        showcase_name
        city
        country
        date
      }
      start_date
      high_school_graduation_date
      sat_score
      sat_date
      act_score
      act_date
      gemat_gre_score
      gemat_gre_date
      toefl_score
      toefl_date
      duolingo
      duolingo_date
      ielts
      ielts_date
      estimated_gpa
      intended_field_of_studies
      intended_degree
      budget
      category
      facebook_url
      twitter_user
      linkedin_url
      reason_commitment
      date_commitment
      agency {
        id
        name
        logo
        country
        contact_email
        contact_phone
        website
        sport
        athletes {
          nextToken
        }
        agents {
          nextToken
        }
        coach_messages {
          nextToken
        }
        createdAt
        updatedAt
      }
      agent {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        agent_role
        agencyID
        agency {
          nextToken
        }
        ahtletes {
          nextToken
        }
        createdAt
        updatedAt
      }
      favorited {
        items {
          id
          athleteID
          coachID
          coach {
            id
            name
            gender
            contact_email
            contact_phone
            division
            is_active
            role
            teamID
            team {
              name
            }
          }
          rating
          createdAt
          updatedAt
        }
        nextToken
      }
      enquiries {
        items {
          id
          enquiry_text
          athleteID
          coachID
          coach {
            id
            name
            gender
            contact_email
            contact_phone
            division
            is_active
            role
            teamID
            team {
              name
            }
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      tags {
        items {
          id
          athleteID
          tagID
          createdAt
          updatedAt
        }
        nextToken
      }
      showcases_participation {
        items {
          id
          showcaseID
          athleteID
          color
          number
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listAthletes = /* GraphQL */ `
  query ListAthletes(
    $filter: ModelAthleteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAthletes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        agencyID
        agentID
        isVisible
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        nationality
        country_of_competition
        target_division
        compliance
        card_pic
        other_nice_pics
        agency_assesment
        elegibility_notes
        current_club
        current_status
        start_availability_date
        end_availability_date
        destination_college
        ncaa_id
        type_of_admission
        category
        weight
        height
        sport
        position
        second_position
        dominant_foot
        tennis_profile {
          utr
          national_ranking
          international_ranking
          DominantHand
        }
        featured_video {
          url
          metadata
        }
        other_videos {
          url
          metadata
        }
        level_of_competition
        showcase_participation {
          showcase_name
          city
          country
          date
        }
        start_date
        high_school_graduation_date
        sat_score
        sat_date
        act_score
        act_date
        gemat_gre_score
        gemat_gre_date
        toefl_score
        toefl_date
        duolingo
        duolingo_date
        ielts
        ielts_date
        estimated_gpa
        intended_field_of_studies
        intended_degree
        budget
        facebook_url
        twitter_user
        linkedin_url
        reason_commitment
        date_commitment
        sports_background
        agency {
          id
          name
          logo
          country
          contact_email
          contact_phone
          website
          createdAt
          updatedAt
        }
        agent {
          id
          name
          profile_pic
          contact_email
          contact_phone
          agencyID
        }
        favorited {
          items {
            id
            athleteID
            coachID
            coach {
              id
              name
              gender
              is_active
              division
              contact_email
              contact_phone
              role
              teamID
              team {
                name
              }
            }
            rating
            createdAt
            updatedAt
          }
          nextToken
        }
        enquiries {
          items {
            id
            enquiry_text
            athleteID
            coachID
            coach {
              id
              name
              gender
              is_active
              division
              contact_email
              contact_phone
              role
              teamID
              team {
                name
              }
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        tags {
          items {
            id
            athleteID
            tagID
            tag {
              text
              type
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        showcases_participation {
          items {
            id
            showcaseID
            athleteID
            color
            number
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const createAthlete = /* GraphQL */ `
  mutation CreateAthlete(
    $input: CreateAthleteInput!
    $condition: ModelAthleteConditionInput
  ) {
    createAthlete(input: $input, condition: $condition) {
      id
      agencyID
      agentID
      name
      isVisible
      profile_pic
      birthdate
      gender
      contact_email
      contact_phone
      nationality
      country_of_competition
      target_division
      compliance
      card_pic
      other_nice_pics
      agency_assesment
      elegibility_notes
      current_club
      current_status
      start_availability_date
      end_availability_date
      destination_college
      ncaa_id
      type_of_admission
      category
      weight
      height
      sport
      position
      second_position
      dominant_foot
      tennis_profile {
        utr
        national_ranking
        international_ranking
        DominantHand
      }
      featured_video {
        url
        metadata
      }
      other_videos {
        url
        metadata
      }
      level_of_competition
      sports_background
      showcase_participation {
        showcase_name
        city
        country
        date
      }
      start_date
      high_school_graduation_date
      sat_score
      sat_date
      act_score
      act_date
      gemat_gre_score
      gemat_gre_date
      toefl_score
      toefl_date
      duolingo
      duolingo_date
      ielts
      ielts_date
      estimated_gpa
      intended_field_of_studies
      intended_degree
      budget
      facebook_url
      twitter_user
      linkedin_url
      reason_commitment
      date_commitment
      createdAt
      updatedAt
    }
  }
`;
export const updateAthlete = /* GraphQL */ `
  mutation UpdateAthlete(
    $input: UpdateAthleteInput!
    $condition: ModelAthleteConditionInput
  ) {
    updateAthlete(input: $input, condition: $condition) {
      id
      agencyID
      agency {
        id
        name
        logo
        country
        contact_email
        contact_phone
        website
        sport
        createdAt
        updatedAt
      }
      agentID
      name
      isVisible
      profile_pic
      birthdate
      gender
      contact_email
      contact_phone
      nationality
      country_of_competition
      target_division
      compliance
      card_pic
      other_nice_pics
      agency_assesment
      elegibility_notes
      current_club
      current_status
      start_availability_date
      end_availability_date
      destination_college
      ncaa_id
      type_of_admission
      category
      weight
      height
      sport
      position
      second_position
      dominant_foot
      tennis_profile {
        utr
        national_ranking
        international_ranking
        DominantHand
      }
      featured_video {
        url
        metadata
      }
      other_videos {
        url
        metadata
      }
      level_of_competition
      sports_background
      showcase_participation {
        showcase_name
        city
        country
        date
      }
      start_date
      high_school_graduation_date
      sat_score
      sat_date
      act_score
      act_date
      gemat_gre_score
      gemat_gre_date
      toefl_score
      toefl_date
      duolingo
      duolingo_date
      ielts
      ielts_date
      estimated_gpa
      intended_field_of_studies
      intended_degree
      budget
      facebook_url
      twitter_user
      linkedin_url
      reason_commitment
      date_commitment
      createdAt
      updatedAt
    }
  }
`;
export const deleteAthlete = /* GraphQL */ `
  mutation DeleteAthlete(
    $input: DeleteAthleteInput!
    $condition: ModelAthleteConditionInput
  ) {
    deleteAthlete(input: $input, condition: $condition) {
      id
      agencyID
      agentID
      name
      isVisible
      profile_pic
      birthdate
      gender
      contact_email
      contact_phone
      nationality
      country_of_competition
      target_division
      compliance
      card_pic
      other_nice_pics
      agency_assesment
      elegibility_notes
      current_club
      current_status
      start_availability_date
      end_availability_date
      destination_college
      ncaa_id
      type_of_admission
      category
      weight
      height
      sport
      position
      second_position
      dominant_foot
      tennis_profile {
        utr
        national_ranking
        international_ranking
        DominantHand
      }
      featured_video {
        url
        metadata
      }
      other_videos {
        url
        metadata
      }
      level_of_competition
      sports_background
      showcase_participation {
        showcase_name
        city
        country
        date
      }
      start_date
      high_school_graduation_date
      sat_score
      sat_date
      act_score
      act_date
      gemat_gre_score
      gemat_gre_date
      toefl_score
      toefl_date
      duolingo
      duolingo_date
      ielts
      ielts_date
      estimated_gpa
      intended_field_of_studies
      intended_degree
      budget
      facebook_url
      twitter_user
      linkedin_url
      reason_commitment
      date_commitment
      createdAt
      updatedAt
    }
  }
`;
export const createAthleteEnquiry = /* GraphQL */ `
  mutation CreateAthleteEnquiry(
    $input: CreateAthleteEnquiryInput!
  ) {
    createAthleteEnquiry(input: $input) {
      id
	}
}
`;
export const listAthleteEnquirys = /* GraphQL */ `
  query ListAthleteEnquirys(
    $filter: ModelAthleteEnquiryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAthleteEnquirys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        enquiry_text
        athleteID
        athlete {
          id
          agencyID
          agentID
          name
          isVisible
        }
        coachID
        coach {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          disabledByUser
          is_active
          allow_whatsapp_contact
          deleteAppReason
          division
          role
          sport
          teamID
          team {
            name
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

// 
// FAVORITE FOLDER - ATHLETE RELATION 
// 
export const getFavoritesFolder = /* GraphQL */ `
  query GetFavoritesFolder($id: ID!) {
    getFavoritesFolder(id: $id) {
      id
      folder_name
      status
      coachID
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
        }
        my_filters {
          nextToken
        }
        my_favorite_folders {
          nextToken
        }
        my_notifications {
          nextToken
        }
        showcases_interested_in {
          nextToken
        }
        my_athlete_tags {
          nextToken
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          team_logo
          conference
          division
          college_category
          url_domain
          collegeID
          createdAt
          updatedAt
        }
      }
      athletes_in_folder {
        items {
          id
          athleteID
          favoritesFolderID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listFavoritesFolders = /* GraphQL */ `
  query ListFavoritesFolders(
    $filter: ModelFavoritesFolderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFavoritesFolders(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        folder_name
        status
        coachID
        coach {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          disabledByUser
          is_active
          allow_whatsapp_contact
          deleteAppReason
          division
          role
          sport
          teamID
          createdAt
          updatedAt
        }
        athletes_in_folder {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFavoritesFolderAthleteRelation = /* GraphQL */ `
  query GetFavoritesFolderAthleteRelation($id: ID!) {
    getFavoritesFolderAthleteRelation(id: $id) {
      id
      athleteID
      favoritesFolderID
      athlete {
        id
        agencyID
        agentID
        name
        isVisible
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        nationality
        country_of_competition
        target_division
        compliance
        card_pic
        other_nice_pics
        agency_assesment
        elegibility_notes
        current_club
        current_status
        start_availability_date
        end_availability_date
        destination_college
        ncaa_id
        type_of_admission
        weight
        height
        sport
        tennis_profile {
          utr
          national_ranking
          international_ranking
          DominantHand
        }
        position
        second_position
        dominant_foot
        featured_video {
          url
          metadata
        }
        other_videos {
          url
          metadata
        }
        level_of_competition
        sports_background
        showcase_participation {
          showcase_name
          city
          country
          date
        }
        start_date
        high_school_graduation_date
        sat_score
        sat_date
        act_score
        act_date
        gemat_gre_score
        gemat_gre_date
        toefl_score
        toefl_date
        duolingo
        duolingo_date
        ielts
        ielts_date
        estimated_gpa
        intended_field_of_studies
        intended_degree
        budget
        facebook_url
        twitter_user
        linkedin_url
        reason_commitment
        date_commitment
        agency {
          id
          name
          logo
          country
          contact_email
          contact_phone
          website
          sport
          createdAt
          updatedAt
        }
        agent {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          agent_role
          agencyID
          createdAt
          updatedAt
        }
        favorited {
          nextToken
        }
        enquiries {
          nextToken
        }
        tags {
          nextToken
        }
        showcases_participation {
          nextToken
        }
        createdAt
        updatedAt
      }
      favorites_folder {
        id
        folder_name
        status
        coachID
        coach {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          disabledByUser
          is_active
          allow_whatsapp_contact
          deleteAppReason
          division
          role
          sport
          teamID
          createdAt
          updatedAt
        }
        athletes_in_folder {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listFavoritesFolderAthleteRelations = /* GraphQL */ `
  query ListFavoritesFolderAthleteRelations(
    $filter: ModelFavoritesFolderAthleteRelationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFavoritesFolderAthleteRelations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        athleteID
        favoritesFolderID
        athlete {
          id
          agencyID
          agentID
          name
          isVisible
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          nationality
          country_of_competition
          target_division
          compliance
          card_pic
          other_nice_pics
          agency_assesment
          elegibility_notes
          current_club
          current_status
          start_availability_date
          end_availability_date
          destination_college
          ncaa_id
          type_of_admission
          weight
          height
          sport
          position
          second_position
          dominant_foot
          level_of_competition
          sports_background
          start_date
          high_school_graduation_date
          sat_score
          sat_date
          act_score
          act_date
          gemat_gre_score
          gemat_gre_date
          toefl_score
          toefl_date
          duolingo
          duolingo_date
          ielts
          ielts_date
          estimated_gpa
          intended_field_of_studies
          intended_degree
          budget
          facebook_url
          twitter_user
          linkedin_url
          reason_commitment
          date_commitment
          createdAt
          updatedAt
        }
        favorites_folder {
          id
          folder_name
          status
          coachID
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const createFavoritesFolder = /* GraphQL */ `
  mutation CreateFavoritesFolder(
    $input: CreateFavoritesFolderInput!
    $condition: ModelFavoritesFolderConditionInput
  ) {
    createFavoritesFolder(input: $input, condition: $condition) {
      id
      folder_name
      status
      coachID
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
        }
        my_filters {
          nextToken
        }
        my_favorite_folders {
          nextToken
        }
        my_notifications {
          nextToken
        }
        showcases_interested_in {
          nextToken
        }
        my_athlete_tags {
          nextToken
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          team_logo
          conference
          division
          college_category
          url_domain
          collegeID
          createdAt
          updatedAt
        }
      }
      athletes_in_folder {
        items {
          id
          athleteID
          favoritesFolderID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateFavoritesFolder = /* GraphQL */ `
  mutation UpdateFavoritesFolder(
    $input: UpdateFavoritesFolderInput!
    $condition: ModelFavoritesFolderConditionInput
  ) {
    updateFavoritesFolder(input: $input, condition: $condition) {
      id
      folder_name
      status
      coachID
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
        }
        my_filters {
          nextToken
        }
        my_favorite_folders {
          nextToken
        }
        my_notifications {
          nextToken
        }
        showcases_interested_in {
          nextToken
        }
        my_athlete_tags {
          nextToken
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          team_logo
          conference
          division
          college_category
          url_domain
          collegeID
          createdAt
          updatedAt
        }
      }
      athletes_in_folder {
        items {
          id
          athleteID
          favoritesFolderID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteFavoritesFolder = /* GraphQL */ `
  mutation DeleteFavoritesFolder(
    $input: DeleteFavoritesFolderInput!
    $condition: ModelFavoritesFolderConditionInput
  ) {
    deleteFavoritesFolder(input: $input, condition: $condition) {
      id
      folder_name
      status
      coachID
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        favorites {
          nextToken
        }
        my_filters {
          nextToken
        }
        my_favorite_folders {
          nextToken
        }
        my_notifications {
          nextToken
        }
        showcases_interested_in {
          nextToken
        }
        my_athlete_tags {
          nextToken
        }
        createdAt
        updatedAt
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          team_logo
          conference
          division
          college_category
          url_domain
          collegeID
          createdAt
          updatedAt
        }
      }
      athletes_in_folder {
        items {
          id
          athleteID
          favoritesFolderID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;

export const createFavoritesFolderAthleteRelation = /* GraphQL */ `
  mutation CreateFavoritesFolderAthleteRelation(
    $input: CreateFavoritesFolderAthleteRelationInput!
    $condition: ModelFavoritesFolderAthleteRelationConditionInput
  ) {
    createFavoritesFolderAthleteRelation(input: $input, condition: $condition) {
      id
      athleteID
      favoritesFolderID
      athlete {
        id
        agencyID
        agentID
        name
        isVisible
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        nationality
        country_of_competition
        target_division
        compliance
        card_pic
        other_nice_pics
        agency_assesment
        elegibility_notes
        current_club
        current_status
        start_availability_date
        end_availability_date
        destination_college
        ncaa_id
        type_of_admission
        weight
        height
        sport
        tennis_profile {
          utr
          national_ranking
          international_ranking
          DominantHand
        }
        position
        second_position
        dominant_foot
        featured_video {
          url
          metadata
        }
        other_videos {
          url
          metadata
        }
        level_of_competition
        sports_background
        showcase_participation {
          showcase_name
          city
          country
          date
        }
        start_date
        high_school_graduation_date
        sat_score
        sat_date
        act_score
        act_date
        gemat_gre_score
        gemat_gre_date
        toefl_score
        toefl_date
        duolingo
        duolingo_date
        ielts
        ielts_date
        estimated_gpa
        intended_field_of_studies
        intended_degree
        budget
        facebook_url
        twitter_user
        linkedin_url
        reason_commitment
        date_commitment
        agency {
          id
          name
          logo
          country
          contact_email
          contact_phone
          website
          sport
          createdAt
          updatedAt
        }
        agent {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          agent_role
          agencyID
          createdAt
          updatedAt
        }
        favorited {
          nextToken
        }
        enquiries {
          nextToken
        }
        tags {
          nextToken
        }
        showcases_participation {
          nextToken
        }
        createdAt
        updatedAt
      }
      favorites_folder {
        id
        folder_name
        status
        coachID
        coach {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          disabledByUser
          is_active
          allow_whatsapp_contact
          deleteAppReason
          division
          role
          sport
          teamID
          createdAt
          updatedAt
        }
        athletes_in_folder {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateFavoritesFolderAthleteRelation = /* GraphQL */ `
  mutation UpdateFavoritesFolderAthleteRelation(
    $input: UpdateFavoritesFolderAthleteRelationInput!
    $condition: ModelFavoritesFolderAthleteRelationConditionInput
  ) {
    updateFavoritesFolderAthleteRelation(input: $input, condition: $condition) {
      id
      athleteID
      favoritesFolderID
      athlete {
        id
        agencyID
        agentID
        name
        isVisible
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        nationality
        country_of_competition
        target_division
        compliance
        card_pic
        other_nice_pics
        agency_assesment
        elegibility_notes
        current_club
        current_status
        start_availability_date
        end_availability_date
        destination_college
        ncaa_id
        type_of_admission
        weight
        height
        sport
        tennis_profile {
          utr
          national_ranking
          international_ranking
          DominantHand
        }
        position
        second_position
        dominant_foot
        featured_video {
          url
          metadata
        }
        other_videos {
          url
          metadata
        }
        level_of_competition
        sports_background
        showcase_participation {
          showcase_name
          city
          country
          date
        }
        start_date
        high_school_graduation_date
        sat_score
        sat_date
        act_score
        act_date
        gemat_gre_score
        gemat_gre_date
        toefl_score
        toefl_date
        duolingo
        duolingo_date
        ielts
        ielts_date
        estimated_gpa
        intended_field_of_studies
        intended_degree
        budget
        facebook_url
        twitter_user
        linkedin_url
        reason_commitment
        date_commitment
        agency {
          id
          name
          logo
          country
          contact_email
          contact_phone
          website
          sport
          createdAt
          updatedAt
        }
        agent {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          agent_role
          agencyID
          createdAt
          updatedAt
        }
        favorited {
          nextToken
        }
        enquiries {
          nextToken
        }
        tags {
          nextToken
        }
        showcases_participation {
          nextToken
        }
        createdAt
        updatedAt
      }
      favorites_folder {
        id
        folder_name
        status
        coachID
        coach {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          disabledByUser
          is_active
          allow_whatsapp_contact
          deleteAppReason
          division
          role
          sport
          teamID
          createdAt
          updatedAt
        }
        athletes_in_folder {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteFavoritesFolderAthleteRelation = /* GraphQL */ `
  mutation DeleteFavoritesFolderAthleteRelation(
    $input: DeleteFavoritesFolderAthleteRelationInput!
    $condition: ModelFavoritesFolderAthleteRelationConditionInput
  ) {
    deleteFavoritesFolderAthleteRelation(input: $input, condition: $condition) {
      id
      athleteID
      favoritesFolderID
      athlete {
        id
        agencyID
        agentID
        name
        isVisible
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        nationality
        country_of_competition
        target_division
        compliance
        card_pic
        other_nice_pics
        agency_assesment
        elegibility_notes
        current_club
        current_status
        start_availability_date
        end_availability_date
        destination_college
        ncaa_id
        type_of_admission
        weight
        height
        sport
        tennis_profile {
          utr
          national_ranking
          international_ranking
          DominantHand
        }
        position
        second_position
        dominant_foot
        featured_video {
          url
          metadata
        }
        other_videos {
          url
          metadata
        }
        level_of_competition
        sports_background
        showcase_participation {
          showcase_name
          city
          country
          date
        }
        start_date
        high_school_graduation_date
        sat_score
        sat_date
        act_score
        act_date
        gemat_gre_score
        gemat_gre_date
        toefl_score
        toefl_date
        duolingo
        duolingo_date
        ielts
        ielts_date
        estimated_gpa
        intended_field_of_studies
        intended_degree
        budget
        facebook_url
        twitter_user
        linkedin_url
        reason_commitment
        date_commitment
        agency {
          id
          name
          logo
          country
          contact_email
          contact_phone
          website
          sport
          createdAt
          updatedAt
        }
        agent {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          agent_role
          agencyID
          createdAt
          updatedAt
        }
        favorited {
          nextToken
        }
        enquiries {
          nextToken
        }
        tags {
          nextToken
        }
        showcases_participation {
          nextToken
        }
        createdAt
        updatedAt
      }
      favorites_folder {
        id
        folder_name
        status
        coachID
        coach {
          id
          name
          profile_pic
          birthdate
          gender
          contact_email
          contact_phone
          disabledByUser
          is_active
          allow_whatsapp_contact
          deleteAppReason
          division
          role
          sport
          teamID
          createdAt
          updatedAt
        }
        athletes_in_folder {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateAthleteById = /* GraphQL */ `
  subscription OnUpdateAthleteById($id: ID!) {
    onUpdateAthleteById(id: $id) {
      id
      agencyID
      agentID
      agent {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        agent_role
        agencyID
        createdAt
        updatedAt
      }
      name
      isVisible
      profile_pic
      birthdate
      gender
      contact_email
      contact_phone
      nationality
      country_of_competition
      target_division
      compliance
      card_pic
      other_nice_pics
      agency_assesment
      elegibility_notes
      current_club
      current_status
      start_availability_date
      end_availability_date
      destination_college
      ncaa_id
      type_of_admission
      weight
      height
      sport
      tennis_profile {
        utr
        national_ranking
        international_ranking
        national_ranking_adult
        international_ranking_adult
        DominantHand
      }
      position
      second_position
      dominant_foot
      featured_video {
        url
        metadata
      }
      other_videos {
        url
        metadata
      }
      level_of_competition
      sports_background
      showcase_participation {
        showcase_name
        city
        country
        date
      }
      start_date
      high_school_graduation_date
      sat_score
      sat_date
      act_score
      act_date
      gemat_gre_score
      gemat_gre_date
      toefl_score
      toefl_date
      duolingo
      duolingo_date
      ielts
      ielts_date
      estimated_gpa
      intended_field_of_studies
      intended_degree
      budget
      category
      facebook_url
      twitter_user
      linkedin_url
      reason_commitment
      date_commitment
      agency {
        id
        name
        logo
        country
        contact_email
        contact_phone
        website
        sport
        athletes {
          nextToken
        }
        agents {
          nextToken
        }
        coach_messages {
          nextToken
        }
        createdAt
        updatedAt
      }
      favorited {
        items {
          id
          athleteID
          coachID
          rating
          createdAt
          updatedAt
        }
        nextToken
      }
      enquiries {
        items {
          id
          enquiry_text
          athleteID
          coachID
          createdAt
          updatedAt
        }
        nextToken
      }
      tags {
        items {
          id
          athleteID
          tagID
          createdAt
          updatedAt
        }
        nextToken
      }
      showcases_participation {
        items {
          id
          showcaseID
          athleteID
          color
          number
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;