import React, { useContext }  from "react";
import { DataContext } from '../../../contexts/dataContext'

import { changeUserGroupToAgencyId } from "helpers";
import { DashboardForAgents } from "./DashboardForAgents";
import DashboardForAdmins from "./DashboardForAdmins";

export default function Dashboard() {

	const dataContext = useContext(DataContext)
	const { user } = dataContext

	const userGroup = changeUserGroupToAgencyId(user?.signInUserSession.idToken.payload["cognito:groups"][0])

	return (
		<>
		{
			userGroup === "superadmins" ? (
				// Some test to avoid lag when render
					<DashboardForAdmins />
				):(
					<DashboardForAgents />
				)
					
		}
		</>
	);
}
