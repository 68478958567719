// React imports
import React, { useContext, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
// Component imports
import PremiumCoachesTables from "./components/PremiumCoachesTables";
import { DataContext } from "../../../contexts/dataContext";
import { ShowCoach } from "./components/ShowCoach";
import { EditCoach } from "./components/EditCoach";
import { CreateCoach } from "./components/CreateCoach";
import { Activity } from "./components/Activity";

const PremiumCoaches = () => {
  // Get data from context
  const dataContext = useContext(DataContext);
  const { premiumCoaches } = dataContext;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Routes>
        <Route
          path="/"
          element={<PremiumCoachesTables coaches={premiumCoaches} />}
        />
        {/* <Route path="/show/:coachId" element={<ShowCoach />} />
        <Route path="/edit/:coachId" element={<EditCoach />} />
        <Route path="/activity/:coachId" element={<Activity />} />
        <Route path="/create" element={<CreateCoach />} /> */}
      </Routes>
    </>
  );
};

export default PremiumCoaches;
