// React imports
import React, { useContext, useEffect } from 'react'
// Context imports
import { DataContext } from '../../../../contexts/dataContext'
// Chakra imports
import { Flex } from "@chakra-ui/react";
// Components
import Colleges from './Colleges'

function CollegesTables() {

	// Take the context data needed in this component
	const dataContext = useContext(DataContext)
	const { colleges } = dataContext

	useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

	return (
		<Flex direction='column' pt={{ base: "120px", md: "75px" }}>
			<Colleges
				title={"Colleges"}
				captions={["Logo", "Name", "State", "Teams"]}
				data={colleges}
			/>
		</Flex>
	);
}

export default CollegesTables;
