// import of the different views
import Dashboard from "views/Dashboard/Dashboard";
import { AgenciesRoutes } from "views/Dashboard/Agencies/AgenciesRoutes";
import { AgentsRoutes } from "views/Dashboard/Agents/AgentsRoutes";
import { CollegesRoutes } from "views/Dashboard/Colleges/CollegesRoutes";
import { TeamsRoutes } from "views/Dashboard/Teams/TeamsRoutes";
import { CoachesRoutes } from "views/Dashboard/Coaches/CoachesRoutes";
import { AthletesRoutes } from "views/Dashboard/Athletes/AthletesRoutes";
import { PendingCoaches } from "views/Dashboard/PendingCoaches/PendingCoaches";
import { Tutorials } from "views/Dashboard/Tutorials/Tutorials";
import SignIn from "views/Auth/SignIn.js";
import SignUp from "views/Auth/SignUp.js";
import ForgotPassword from "views/Auth/ForgotPassword.js";
import ResetPassword from "views/Auth/ResetPassword.js";
import FirstChangePassword from "views/Auth/FirstChangePassword.js";
import Profile from "views/Dashboard/Profile";
import { MdManageAccounts, MdNotifications } from "react-icons/md";
import { MdEmojiEvents } from "react-icons/md";

// import icons that are used in the sidebar
import {
  HomeIcon,
  DocumentIcon,
  RocketIcon,
  ProfileIcon,
  PremiumIcon,
} from "components/Icons/Icons";

import {
  MdAccountBalance,
  MdSchool,
  MdRecordVoiceOver,
  MdDirectionsRun,
} from "react-icons/md";

import { FaRegFutbol, FaUserFriends } from "react-icons/fa";
import { FiClock } from "react-icons/fi";
import { IoDocumentText } from "react-icons/io5";
import { Icon } from "@chakra-ui/react";
import { ManageTemplates } from "views/Dashboard/Templates/ManageTemplates";
import { AuxCoachesRoutes } from "views/Dashboard/AuxCoaches/AuxCoachesRoutes";
import { ShowcasesRoutes } from "views/Dashboard/Showcases/ShowcasesRoutes";
import PremiumCoaches from "views/Dashboard/Coaches/PremiumCoaches";

// Variable declaring the routes used in the navigation of the application
// Also used to define the content of side bar

var dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color="inherit" />,
    component: Dashboard,
    layout: "/admin",
    superadmin: true,
  },
  {
    path: "/athletes",
    name: "Athletes",
    rtlName: "لوحة القيادة",
    icon: <Icon as={MdDirectionsRun} color="inherit" />,
    component: AthletesRoutes,
    layout: "/admin",
    subroutes: true,
  },
  {
    path: "/agencies",
    name: "Agencies",
    rtlName: "لوحة القيادة",
    icon: <Icon as={MdAccountBalance} color="inherit" />,
    component: AgenciesRoutes,
    layout: "/admin",
    subroutes: true,
  },
  {
    path: "/agents",
    name: "Agents",
    rtlName: "لوحة القيادة",
    icon: <ProfileIcon color="inherit" />,
    component: AgentsRoutes,
    layout: "/admin",
    subroutes: true,
  },
  {
    path: "/colleges",
    name: "Colleges",
    rtlName: "لوحة القيادة",
    icon: <Icon as={MdSchool} color="inherit" />,
    component: CollegesRoutes,
    layout: "/admin",
    subroutes: true,
    superadmin: true,
  },
  {
    path: "/teams",
    name: "Teams",
    rtlName: "لوحة القيادة",
    icon: <Icon as={FaRegFutbol} color="inherit" />,
    component: TeamsRoutes,
    layout: "/admin",
    subroutes: true,
    superadmin: true,
  },
  {
    path: "/coaches",
    name: "Coaches",
    rtlName: "لوحة القيادة",
    icon: <Icon as={MdRecordVoiceOver} color="inherit" />,
    component: CoachesRoutes,
    layout: "/admin",
    subroutes: true,
  },
  {
    path: "/showcases",
    name: "Showcases",
    rtlName: "لوحة القيادة",
    icon: <Icon as={MdEmojiEvents} color="inherit" />,
    component: ShowcasesRoutes,
    layout: "/admin",
    subroutes: true,
  },
  {
    path: "/aux-coaches",
    name: "Aux Coaches",
    rtlName: "لوحة القيادة",
    icon: <Icon as={FaUserFriends} color="inherit" />,
    component: AuxCoachesRoutes,
    layout: "/admin",
    subroutes: true,
    superadmin: true,
  },
  {
    path: "/pending-coaches",
    name: "Pending coaches",
    rtlName: "لوحة القيادة",
    icon: <Icon as={FiClock} color="inherit" />,
    component: PendingCoaches,
    layout: "/admin",
    subroutes: true,
    superadmin: true,
  },
  {
    path: "/profile",
    name: "Profile",
    rtlName: "لوحة القيادة",
    icon: <Icon as={MdManageAccounts} color="inherit" />,
    component: Profile,
    secondaryNavbar: true,
    layout: "/admin",
    subroutes: true,
  },
  {
    path: "/tutorials",
    name: "Tutorials",
    rtlName: "لوحة القيادة",
    icon: <Icon as={IoDocumentText} color="inherit" />,
    component: Tutorials,
    layout: "/admin",
  },
  {
    path: "/templates",
    name: "Templates",
    rtlName: "لوحة القيادة",
    icon: <Icon as={MdNotifications} color="inherit" />,
    component: ManageTemplates,
    layout: "/admin",
    superadmin: true,
  },
  {
    path: "/premium-coaches",
    name: "Premium Coaches",
    rtlName: "لوحة القيادة",
    icon: <PremiumIcon color="inherit" />,
    component: PremiumCoaches,
    layout: "/admin",
    superadmin: true,
  },
  {
    name: "ACCOUNT PAGES",
    category: "account",
    rtlName: "صفحات",
    state: "pageCollapse",
    views: [
      {
        path: "/signin",
        name: "Sign In",
        rtlName: "لوحة القيادة",
        icon: <DocumentIcon color="inherit" />,
        component: SignIn,
        layout: "/auth",
      },
      {
        path: "/signup",
        name: "Sign Up",
        rtlName: "لوحة القيادة",
        icon: <RocketIcon color="inherit" />,
        secondaryNavbar: true,
        component: SignUp,
        layout: "/auth",
      },
      {
        path: "/forgot-password",
        name: "Forgot Password",
        rtlName: "لوحة القيادة",
        icon: <RocketIcon color="inherit" />,
        secondaryNavbar: true,
        component: ForgotPassword,
        layout: "/auth",
      },
      {
        path: "/reset-password",
        name: "Reset Password",
        rtlName: "لوحة القيادة",
        icon: <RocketIcon color="inherit" />,
        secondaryNavbar: true,
        component: ResetPassword,
        layout: "/auth",
      },
      {
        path: "/change-password",
        name: "Change Password",
        rtlName: "لوحة القيادة",
        icon: <RocketIcon color="inherit" />,
        secondaryNavbar: true,
        component: FirstChangePassword,
        layout: "/auth",
      },
    ],
  },
];
export default dashRoutes;
