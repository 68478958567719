import { Box, Button, Flex, ScaleFade, Text } from '@chakra-ui/react'
import Card from 'components/Card/Card'
import CardHeader from 'components/Card/CardHeader'
import BarChart from 'components/Charts/BarChart'
import React, { useState } from 'react'
import { stackedBarChartAgenciesOptions } from 'variables/charts'

const EnquiriesByAgencies = ({agencies,athletesEnquirys,athletes}) => {

    const [hasRender, setRender] = useState(false);
  	const onShow = React.useCallback(() => setRender(true), []);

    const getCoachMessagesStatistics = () => {
		let stats = [{ name: "Today",data: [] },{ name: "This month", data: [] },{ name: "This year", data: [] },{ name: "Total", data: [] }]

		agencies?.map(agency => {
			const enquirysOfAgency = athletesEnquirys.filter(enq => athletes.find(ath => ath.id === enq.athleteID)?.agencyID === agency.id)

			stats[0].data.push(enquirysOfAgency.filter(item => new Date(item.createdAt).toLocaleDateString() === new Date().toLocaleDateString()).length)
			stats[1].data.push(enquirysOfAgency.filter(item => new Date(item.createdAt).getMonth() === new Date().getMonth() && new Date(item.createdAt).getFullYear() === new Date().getFullYear()).length)
			stats[2].data.push(enquirysOfAgency.filter(item => new Date(item.createdAt).getFullYear() === new Date().getFullYear()).length)
			stats[3].data.push(enquirysOfAgency.length)
		})

		return stats
	}


    return (
        <>
            { hasRender && (
                <ScaleFade in={hasRender} >
                    <Card p='28px 10px 0px 0px' mb={{ sm: "26px", lg: "0px" }} >
                        <CardHeader  pl='22px'>
                            <Flex direction='column' alignSelf='flex-start'>
                                <Text fontSize='lg' color="gray.700" fontWeight='bold' mb='6px'>
                                    No enquiries received by agencies
                                </Text>
                                <Text fontSize='md' fontWeight='medium' color='gray.400'>
                                    This chart shows a distribution of the athletes enquiries by the different agencies.
                                </Text>
                            </Flex>
                        </CardHeader>
                        <Box w='100%' h={{ sm: "350px" }}>
                            <BarChart data={getCoachMessagesStatistics()} options={{...stackedBarChartAgenciesOptions,plotOptions: {bar: {horizontal: true,borderRadius: 10}}}}/>
                        </Box>
                    </Card> 
                </ScaleFade>
            )}
            { !hasRender && (
                <ScaleFade in={!hasRender} >
                    <Card p='28px 10px 16px 0px'>
                        <CardHeader pl='22px' w="100%">
                            <Flex direction='row' alignSelf='flex-start' w="100%" justifyContent={'space-between'} alignItems="center">
								<Flex direction='column' alignSelf='flex-start' w="50%">
                                    <Text fontSize='lg' color={"gray.700"} fontWeight='bold' mb='6px'>
                                    No. Enquiries received by agencies 
                                    </Text>
                                    <Text fontSize='md' fontWeight='medium' color='gray.400'>
                                    This chart shows a distribution of the athletes enquiries by the different agencies.
                                    </Text>
                                </Flex>
                                <Button w="40%" colorScheme='blue' onClick={onShow} my="10px" mr="5%">
                                    Load chart
                                </Button>
                            </Flex>
                        </CardHeader>
                    </Card>
                </ScaleFade>
            )}
        </>
    )
}

const myPropsChanged = (prevProps, nextProps) => {
    if ( prevProps !== nextProps ) {
        return false;
    }
    return true;
}

export default React.memo(EnquiriesByAgencies, myPropsChanged);