// Chakra Icons
import { BellIcon, SearchIcon } from "@chakra-ui/icons";
// Chakra Imports
import {
	Button,
	Flex,
	IconButton,
	Input,
	InputGroup,
	InputLeftElement,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Text,
	Image,
	useColorModeValue,
	Avatar,
} from "@chakra-ui/react";
// Assets
import avatar1 from "assets/img/avatars/avatar1.png";
import avatar2 from "assets/img/avatars/avatar2.png";
import avatar3 from "assets/img/avatars/avatar3.png";
// Custom Icons
import { ProfileIcon, SettingsIcon } from "components/Icons/Icons";
// Custom Components
import { ItemContent } from "components/Menu/ItemContent";
import SidebarResponsive from "components/Sidebar/SidebarResponsive";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import { DataContext } from "../../contexts/dataContext"
import { Navigate, useNavigate } from "react-router-dom";
import routes from "routes.js";

export default function HeaderLinks(props) {
	const { variant, children, fixed, secondary, onOpen, ...rest } = props;

	// Chakra Color Mode
	let mainTeal = useColorModeValue("teal.300", "teal.300");
	let inputBg = useColorModeValue("white", "gray.800");
	let mainText = useColorModeValue("gray.700", "gray.200");
	let navbarIcon = useColorModeValue("gray.500", "gray.200");
	let searchIcon = useColorModeValue("gray.700", "gray.200");

	let navigate = useNavigate();

	if (secondary) {
		navbarIcon = "white";
		mainText = "white";
	}
	const settingsRef = React.useRef();

	const dataContext = useContext(DataContext)
	const { authSignOut, userCoachData, agents } = dataContext

	const currentAgent = agents.find(agent => agent.contact_email === userCoachData.contact_email)

	const handleLogout = () => {
		if(window.confirm("Are you sure youn want to log out?")){
            authSignOut()
        }
	}

	return (
		<Flex
			pe={{ sm: "0px", md: "16px" }}
			w={{ sm: "100%", md: "auto" }}
			alignItems="center"
			flexDirection="row"
		>
			

			<Button
				ms="0px"
				px="0px"
				me={{ sm: "2px", md: "16px" }}
				color={navbarIcon}
				variant="transparent-with-icon"
				onClick={handleLogout}
			>
				<Text display={{ sm: "none", md: "flex" }}>Logout</Text>
			</Button>


			

			{/* Profile button */}
			<Avatar 
				src={currentAgent?.profile_pic} 
				rounded="full" 
				w="50px"
				h="50px"
				mr={{ sm: "20px", lg:"0px" }}
				onClick={() => {navigate('/admin/profile')}}
				cursor="pointer"
			/>

			<SidebarResponsive
				logoText={props.logoText}
				secondary={props.secondary}
				routes={routes}
				// logo={logo}
				{...rest}
			/>
			{/* <SettingsIcon
				cursor="pointer"
				ms={{ base: "16px", xl: "0px" }}
				me="16px"
				ref={settingsRef}
				onClick={props.onOpen}
				color={navbarIcon}
				w="18px"
				h="18px"
			/>
			<Menu>
				<MenuButton>
					<BellIcon color={navbarIcon} w="18px" h="18px" />
				</MenuButton>
				<MenuList p="16px 8px">
					<Flex flexDirection="column">
						<MenuItem borderRadius="8px" mb="10px">
							<ItemContent
								time="13 minutes ago"
								info="from Alicia"
								boldInfo="New Message"
								aName="Alicia"
								aSrc={avatar1}
							/>
						</MenuItem>
						<MenuItem borderRadius="8px" mb="10px">
							<ItemContent
								time="2 days ago"
								info="by Josh Henry"
								boldInfo="New Album"
								aName="Josh Henry"
								aSrc={avatar2}
							/>
						</MenuItem>
						<MenuItem borderRadius="8px">
							<ItemContent
								time="3 days ago"
								info="Payment succesfully completed!"
								boldInfo=""
								aName="Kara"
								aSrc={avatar3}
							/>
						</MenuItem>
					</Flex>
				</MenuList>
			</Menu> */}
		</Flex>
	);
}

HeaderLinks.propTypes = {
	variant: PropTypes.string,
	fixed: PropTypes.bool,
	secondary: PropTypes.bool,
	onOpen: PropTypes.func,
};
