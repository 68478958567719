import React, {useState} from 'react'
import {
	InputGroup,
	IconButton,
	InputLeftElement,
	Input,
    useColorModeValue
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";

export const SearchBar = (props) => {

	const onChange = (e) => {
        e.persist()
        props.update(() => ({ ...props.text, [e.target.name]: e.target.value }))
    }

    let mainTeal = useColorModeValue("teal.300", "teal.300");
	let inputBg = useColorModeValue("white", "gray.800");
	let mainText = useColorModeValue("gray.700", "gray.200");
	let searchIcon = useColorModeValue("gray.700", "gray.200");

    return (
        <>
            <InputGroup
				justifyContent='right'
                bg={inputBg}
                borderRadius="15px"
                w={{
                sm: "128px",
                md: "400px",
                }}
                me={{ sm: "auto", md: "20px" }}
                _focus={{
                borderColor: { mainTeal },
                }}
                _active={{
                borderColor: { mainTeal },
                }}
            >
                <InputLeftElement
                    children={
                        <IconButton
                        bg="inherit"
                        borderRadius="inherit"
                        _hover="none"
                        _active={{
                            bg: "inherit",
                            transform: "none",
                            borderColor: "transparent",
                        }}
                        _focus={{
                            boxShadow: "none",
                        }}
                        icon={<SearchIcon color={searchIcon} w="15px" h="15px" />}
                        ></IconButton>
                    }
                />
					<Input
                        fontSize="xs"
                        py="11px"
                        color={mainText}
                        placeholder="Type here..."
                        borderRadius="inherit"
                        onChange={onChange}
                        name="searchText"
					/>
            </InputGroup>
        
        </>
    )
}
