import React, {useEffect,useContext, useMemo} from 'react'
import { DataContext } from '../../contexts/dataContext'
import {
	Table,
	Tbody,
	Text,
	Th,
	Thead,
	Tr,
	useColorModeValue,
	Flex,
	Select,
	Icon
} from "@chakra-ui/react";
import {MdNavigateNext, MdNavigateBefore, MdLastPage, MdFirstPage} from 'react-icons/md'
import {calculateRange} from './helpers/CalculateRange'

export const Pagination = ({totalData, rowsPerPageValues}) => {
    
    const dataContext = useContext(DataContext)

	const { pagination, updatePagination } = dataContext

    useEffect(() => {
        updatePagination({
            ...pagination,
            total: totalData,
            rowsPerPage: rowsPerPageValues[0],
        })
    }, [totalData])
    

    const handleChangedRows = (e) => {
		updatePagination({...pagination,rowsPerPage: Number(e.target.value)})
	}
	const handleNext = () => {
		updatePagination({...pagination,page: pagination.page + 1,lower: pagination.lower + pagination.rowsPerPage})
	}
	const handlePrevious = () => {
		updatePagination({...pagination,page: pagination.page - 1,lower: pagination.lower - pagination.rowsPerPage})
	}
	const handleFirst = () => {
		updatePagination({...pagination,page: 1,lower: 1})
	}
	const handleLast = () => {
		updatePagination({...pagination,page: Math.ceil(pagination.total / pagination.rowsPerPage),lower: pagination.total-1})
	}

    const paginationRange = calculateRange(pagination.total, pagination.rowsPerPage, 1, pagination.page);

    return (
        <>
            <Flex direction='row'>

                <Flex justifyContent='left' mt="15px" alignItems="center" w="30%">
                    
                    <Text fontSize='md' color='gray.400' align="center" mr="5px">
                        Rows per page: 
                    </Text>
                    <Select variant='flushed' size="sm" w="70px" onChange={handleChangedRows} mr="30px">
                        {
                            rowsPerPageValues.map((rowsPerPage) => (
                                <option key={rowsPerPage} value={rowsPerPage} >{rowsPerPage}</option>
                            ))
                        }
                    </Select>
                </Flex>
                <Flex justifyContent='center' mt="15px" alignItems="center" w="30%">
                    <Icon 
                        as={MdFirstPage} 
                        color="gray.400" 
                        onClick={handleFirst} 
                        display={pagination.page === 1 ? 'none':'' } 
                        style={{cursor: 'pointer'}}
                    /> 
                    <Icon as={MdNavigateBefore} color="gray.400" onClick={handlePrevious} display={pagination.page === 1 ? 'none':'' } style={{cursor: 'pointer'}}/>
                    {
                        paginationRange.map((page,idx) => {
                            return(
                                <Text 
                                    fontSize={page === pagination.page ? 'xl': 'md'} 
                                    color='gray.400' 
                                    align="center" 
                                    mr="10px" 
                                    fontWeight={page === pagination.page ? 'bold': null}
                                    key={idx}
                                >
                                    {page}
                                </Text> 
                            )
                        })
                    }
                    <Icon as={MdNavigateNext} style={{cursor: 'pointer'}} color="gray.400" onClick={handleNext} display={pagination.page === Math.ceil(pagination.total / pagination.rowsPerPage)? 'none':''}/>
                    <Icon as={MdLastPage} style={{cursor: 'pointer'}} color="gray.400" onClick={handleLast} display={pagination.page === Math.ceil(pagination.total / pagination.rowsPerPage)? 'none':''}/>
                </Flex>
                <Flex justifyContent='right' mt="15px" alignItems="center" w="30%">
                    <Text fontSize='md' color='gray.400'>
                        Shown {pagination.lower} to {
                            pagination.lower + pagination.rowsPerPage > pagination.total ? pagination.total : pagination.lower + pagination.rowsPerPage
                        } of {pagination.total}
                    </Text>
                </Flex>
            </Flex>
        </>
    )
}
