// React imports
import React, { useContext, useState,useEffect, useRef } from 'react'
import { useNavigate } from "react-router-dom";
// Context imports
import { DataContext } from '../../../../contexts/dataContext'

// Chakra imports
import {
	Table,
	Tbody,
	Text,
	Th,
	Thead,
	Tr,
	useColorModeValue,
	Flex,
	useDisclosure,
	Checkbox,
	Alert,
	AlertIcon,
	CloseButton,
	Icon
} from "@chakra-ui/react";
// Auxiliary functions
import { getSearchedData } from "helpers/getSearchedData";
import { changeUserGroupToAgencyId } from "../../../../helpers";
import { deleteAgentService } from "../../../../services/agentServices";
import { deleteCoachRelations, deleteCoachService } from "../../../../services/coachServices";
import { deleteUserCognito } from "../../../../services/cognitoServices";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { Pagination } from "components/Pagination/Pagination";
import { SearchBar } from "components/SearchBar/SearchBar";
import AgentsTableRow from "./AgentsTableRow";
import { DeleteButton } from "../../../../components/Buttons/DeleteButton";
import { CreateButton } from "../../../../components/Buttons/CreateButton";
import { ConfirmModal } from "../../../../components/ConfirmModal/ConfirmModal";
import {BsArrowDown, BsArrowUp} from 'react-icons/bs'
import { deleteSesIdentity } from 'services/sesServices';


const Agents = ({ title, captions, data, supData, showAgency}) => {
	// Chakra color mode
	const textColor = useColorModeValue("gray.700", "white");
	
	// Take the context data needed in this component    
    const dataContext = useContext(DataContext)
	const { pagination,updatePagination,user,setAgents, agents, athletes, coaches, setCoaches, coachesCognito, setCoachesCognito } = dataContext
	// Cognito user group
	const userGroup = changeUserGroupToAgencyId(user?.signInUserSession.idToken.payload["cognito:groups"][0])
	// State with the searched text
	const [searchState, updateSearchState] = useState({searchText: ''})
	// State that handles the selection of items
	const [ selectItem, setSelectItem ] = useState([])
	// State that controls if the user has permission to do an action
	const [ noPermission, setNoPermission ] = useState(false)
	// State that control if sort is ascending or descending
	const [ sortAscending, setSortAscending ] = useState(true)
	// State that controls if the agent can be deleted or not.
	const [canDelete, setCanDelete] = useState(true)
	// State with action feedback
	const [ actionStatus, setActionStatus ] = useState({
		done: false,
		status: '',
		message: ''
	})

	// Hook for the confirm modal
	const { isOpen, onOpen, onClose } = useDisclosure()
	const cancelRef = useRef()
	// Navigation hook 
	let navigate = useNavigate()
	
	// When the component is mounted, the pagination is reseted
	useEffect(() => {
		updatePagination({
			total:getSearchedData(data,searchState.searchText).length,
			page: 1,
			rowsPerPage: 10,
			lower: 1})
		setSelectItem([])
	}, [])
		
	// Function that find the agency of given agent
	const getAgency = (id) => {
		return supData.find(agency => agency.id === id)
	}

	
	// Function that handles the selection of all items
	const handleSelectAll = (e) => {
		e.target.checked ? setSelectItem(data.map(item => item)) : setSelectItem([])
	}

	// Function that handles the delete action
	const handleDelete = () => {
		let foundPSAs = false
		selectItem.forEach( ( item ) => {
			if(athletes.find(ath => ath.agentID === item.id)){foundPSAs = true}
		})
		if(foundPSAs){
			setCanDelete(false)
		}else{
			// Only if the user want to delete an agent of his agency or the user is admin
			userGroup === "superadmins" 
			? onOpen()
			: setNoPermission(true)
		}
	}

	// Function that handles the confirm delete action
	const handleConfirmDelete = () => {
		onClose()
		let hasFailed = false
		selectItem.forEach((item) => {
			deleteAgentService(item.id)
			.then(res => {
				let associatedCoach = coaches.find(coach => coach.contact_email === item.contact_email)
				deleteCoachService(associatedCoach.id)
				.then(res => {
					deleteUserCognito(coachesCognito.find(it=>it.Attributes.find(it => it['Name']==="email")['Value'] === associatedCoach.contact_email).Username)
					deleteCoachRelations(dataContext, associatedCoach.id).then(res=>res && (hasFailed = true))
				})
				.catch(err => hasFailed = true)
				setCoaches(coaches.filter(coach => coach.id !== associatedCoach.id))
				setCoachesCognito(coachesCognito.filter(item => item.Attributes.at(-2).Value !== associatedCoach.id))
				deleteSesIdentity(associatedCoach.contact_email)
					.then(()=>console.log("[Delete agent]: SES identity deleted."))
					.catch((err)=>console.log(`[Delete agent]: SES deletion error. ${err}`),hasFailed=true)
			})
			.catch(err => hasFailed = true)
		})
		setAgents(agents.filter(item => !selectItem.find(selectedItem => selectedItem.id === item.id)))
		setSelectItem([])
		!hasFailed ? setActionStatus({
			done: true,
			status: 'success',
			message: 'Agent(s) deleted successfully'
		}) : setActionStatus({
			done: true,
			status: 'error',
			message: 'An error has ocurred while deleting the agent(s)'
		})
		navigate(`/admin/agents/`)
	}
		
	return (
		<>
			<ConfirmModal
				title={selectItem.length === 1 ? "Delete Agent" : "Delete Agents"}
				description="Are you sure? This action cannot be undone afterwards and the agent(s) will lose access to both the dashboard and the application."
				button1="Cancel"
				button2="Delete"
				isOpen={isOpen}
				onClose={onClose}
				cancelRef={cancelRef}
				handleDelete={handleConfirmDelete}
			/>
			{
				!canDelete
				&& (
					<Alert status='error' borderRadius="10px" mb="20px">
						<AlertIcon />
						Warning! One of the agents you wish to delete still has PSAs associated with it and this action is going to be canceled.
						<CloseButton position='absolute' right='8px' top='8px' onClick={()=>(setCanDelete(true))} />
					</Alert>
				)
			}
			{
				showAgency && (
					<Flex justifyContent='right' m="0px 10px 10px 0">
						
						<CreateButton handleCreate={() => {  userGroup==="superadmins" ? navigate(`/admin/agents/create`) : setNoPermission(true)}}/>
						{
							selectItem.length > 0 
							? (<DeleteButton handleDelete={handleDelete} />)
							: null
						}
					</Flex>
				)
			}
			{
				noPermission
				? (
					<Alert status='error' borderRadius="10px" mb="20px">
						<AlertIcon />
						You don't have enough permissions to perform this action on agents.
						<CloseButton position='absolute' right='8px' top='8px' onClick={()=>(setNoPermission(false))} />
					</Alert>
				)
				: null
			}
			{
				actionStatus.done && (
					<Alert status={actionStatus.status} borderRadius="10px" mb="20px" >
						<AlertIcon />
						{actionStatus.message}
						<CloseButton position='absolute' right='8px' top='8px' onClick={()=>(setActionStatus({...actionStatus,done:false}))} />
					</Alert>
				)
			}
			<Card overflowX={{ sm: "scroll", xl: "hidden" }}>
				<CardHeader p='6px 0px 22px 0px'>
					<Flex direction='row' w="100%">
						<Flex justifyContent='left' w="40%">
							<Text fontSize='xl' color={textColor} fontWeight='bold'>
								{title} ({searchState.searchText!== '' ? `Filtered result ${getSearchedData(data,searchState.searchText).length}` : data.length} - {selectItem.length} selected)
							</Text>
						</Flex>
						<Flex justifyContent='right' w="60%">
							<SearchBar text={searchState} update={updateSearchState}/>
						</Flex>
					</Flex>
				</CardHeader>
				<CardBody>
					<Table variant='simple' color={textColor}>
						<Thead>
							<Tr my='.8rem' pl='0px' color='gray.400'>
								{
									showAgency && (
										<Th color='gray.400' ><Checkbox colorScheme='blue' onChange={(e)=>(handleSelectAll(e))} /></Th>
									)
								}
								{captions.map((caption, idx) => {
									return (
										caption === 'Name' ? (
											<Th key={idx} color='gray.400' cursor="pointer" onClick={()=>setSortAscending(!sortAscending)}>
												Name {sortAscending ? <Icon as={BsArrowUp} color="gray.400" /> : <Icon as={BsArrowDown} color="gray.400" />}
											</Th>
										) : (
											<Th color='gray.400' key={idx} ps={idx === 0 ? "0px" : null}>
												{caption}
											</Th>
										)
									);
								})}
							</Tr>
						</Thead>
						<Tbody>
							{getSearchedData(data, searchState.searchText).sort((a,b) => {
								if(sortAscending){return a.name.toLowerCase().localeCompare(b.name.toLowerCase())}
								else{return b.name.toLowerCase().localeCompare(a.name.toLowerCase())}
							}).slice(pagination.lower-1,pagination.lower+pagination.rowsPerPage-1).map((row) => {
								return (
									showAgency
									? (
										<AgentsTableRow
											key={`${row.email}-${row.name}`}
											id={row.id}
											name={row.name}
											profile_pic={row.profile_pic}
											contact_email={row.contact_email}
											contact_phone={row.contact_phone}
											gender={row.gender}
											agency={getAgency(row.agencyID)}
											selectItem={selectItem}
											setSelectItem={setSelectItem}
										/>
									)
									: (
										<AgentsTableRow
											key={`${row.email}-${row.name}`}
											id={row.id}
											name={row.name}
											profile_pic={row.profile_pic}
											contact_email={row.contact_email}
											contact_phone={row.contact_phone}
											gender={row.gender}
										/>
									)

								);
							})}
						</Tbody>
					</Table>
				</CardBody>
				<Pagination totalData={getSearchedData(data,searchState.searchText).length} rowsPerPageValues={[10,20,30,40,50]} />
				
			</Card>
		</>
	);
};

export default Agents;
