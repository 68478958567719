import React, {useState,useEffect} from 'react'
import { Badge, Flex, SimpleGrid } from '@chakra-ui/react'
import { TutorialEntry } from './components/TutorialEntry'
import { tutorialsData } from './data/tutorialsData'
import { SearchBar } from "components/SearchBar/SearchBar";


export const Tutorials = () => {
    // State with the searched text
	const [searchState, updateSearchState] = useState({searchText: ''})

    const getFilteredData = () => {
        return tutorialsData.filter(tutorial => {
            return tutorial?.title.toLowerCase().includes(searchState.searchText.toLowerCase()) || tutorial?.description.toLowerCase().includes(searchState.searchText.toLowerCase())
        });
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <Flex mt="80px" justifyContent='right'>
                <SearchBar text={searchState} update={updateSearchState}/>
            </Flex>
            <Flex mt="20px" direction="column">
                <SimpleGrid columns={{ sm: 1, md: 1, xl: 2 }}  spacing={8}>
                    {
                        getFilteredData().map((tutorial, index) => {
                            return (
                                <TutorialEntry
                                    key={index}
                                    title={tutorial.title}
                                    description={tutorial.description}
                                    category={tutorial.category}
                                    type={tutorial.type}
                                    item={tutorial.item}
                                    date={tutorial.date}
                                    author={tutorial.author}
                                    format={tutorial.format}
                                />
                            )
                        })
                    }
                </SimpleGrid>
            </Flex>
        </>
    )
}
