// React imports
import React, { useContext, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
// Context imports
import { DataContext } from "../../../../contexts/dataContext";
// React-select imports
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
// Chackra Components
import { Flex, useColorModeValue, Text, FormControl,FormErrorMessage, Input,Spacer, Box, Button, ButtonGroup } from "@chakra-ui/react";
// Auxiliary imports
import { uploadImageToCloudinary } from "../../../../helpers/uploadImageToCloudinary";
import { v4 as uuidv4 } from 'uuid';
import { createTeamService } from "../../../../services/teamServices";
import { division,suscription_name_values,suscription_status_values,suscription_recurrency_values } from 'helpers';
// Components 
import { ImageDropzone } from "../../../../components/Dropzone/ImageDropzone";



export const CreateTeam = () => {

    // Chakra color mode
    const textColor = useColorModeValue("gray.700", "white");
	const animatedComponents = makeAnimated();
    // Navigate hook
    let navigate = useNavigate()

    // Take the context data needed in this component
    const dataContext = useContext(DataContext)
    const { teams, setTeams, colleges } = dataContext

	// Initial state with mandatory fields
	const initialState = {
		id: uuidv4(),
		name: '',
        collegeID: '',
        genre: '',
        suscription_name:'FREE_PERIOD',
        suscription_recurrency:'NONE',
        suscription_status:'ACTIVE',
	}
    
    // State that controls the form values
	const [formValues, setFormValues] = useState(initialState)
	// State to check if form has changed and enable submit button
	const [formHasChanged, setFormHasChanged] = useState(false)
	// State that controls if the submit is loading
	const [isLoading, setIsLoading] = useState(false)
    
    // When the form values change, check that the mandatory fields are filled and enable the submit button
	useEffect(() => {
		JSON.stringify(formValues) !== JSON.stringify(initialState) ? setFormHasChanged(true) : setFormHasChanged(false)
		formValues.id !== '' && formValues.name !== '' && formValues.collegeID !== '' && formValues.genre !== '' && formValues.suscription_name !== '' && formValues.suscription_recurrency !== '' && formValues.suscription_status !== '' ? setFormHasChanged(true) : setFormHasChanged(false)
	}, [formValues])


	const onChange = (e) => {
        e.persist()
        setFormValues(() => ({ ...formValues, [e.target.name]: e.target.value }))
    }

    // Handle submit, validate data, upload image to cloudinary and create team
	const handleCreate = () => {
        setIsLoading(true)

        // If the suscription changes from FREE_PERIOD to PREMIUM_PASS MANUALLY give 1 month
        if ('suscription_name' in formValues && formValues['suscription_name']==='PREMIUM_PASS'){
            const now = new Date()
            let next;
            formValues['suscription_premium_start_date'] = now.toISOString().split('T')[0]
            if (now.getMonth() === 11){
                next = new Date(now.getFullYear() + 1, 0, 1);
            } else {
                next = new Date(now.getFullYear(), now.getMonth() + 1, now.getDate());
            }
            formValues['suscription_premium_end_date'] = next.toISOString().split('T')[0]
        }
        
        if(formValues.team_logo !== initialState.team_logo){
            uploadImageToCloudinary({image:formValues.team_logo, directory: 'devtests'})
            .then((url) => {
                createTeamService({ ...formValues, team_logo: url })
                .then(() => {
                    setIsLoading(false)
                    setTeams([...teams, { ...formValues, team_logo: url }])
                    navigate('/admin/teams')
                })
                
            })
        }else{
            createTeamService({ ...formValues })
            .then(() => {
                setIsLoading(false)
                setTeams([...teams, formValues])
                navigate('/admin/teams')
            })
        }

    }

    return (
        <>
            <Box mt={{ base: "120px", md: "90px" }} borderRadius="md" boxShadow="md" bg="white" >
                {/* Header */}
                <Flex justifyContent='left' p='15px 0px 10px 20px' >
                    <Text fontSize='xl' color={textColor} fontWeight='bold' mr="200px">
                        Create team 
                    </Text>
                </Flex>

                {/* Form of their attributes */}
                <Flex direction='column' w="100%" alignContent="center" px="20px"  > 
                    
                    {/* First row */}
                    <Flex direction={{ sm: "column", lg: "row" }} w="100%">
                        <Flex w={{ sm: "100%", lg: "30%" }} mt="20px">
                            <FormControl isInvalid={formValues.name === ""}>
                                <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mr="15px" fontWeight='semibold' textAlign="justify">
                                    Name
                                </Text>
                                <Input isRequired={true} onChange={onChange} variant='flushed' placeholder='Name' size='sm' value={formValues.name} name="name"/>
                                {formValues.name === "" ? <FormErrorMessage>This field is required</FormErrorMessage> : null}
                            </FormControl>
                        </Flex>
                        <Spacer />
                        <Flex alignItems='left' direction={'column'} w={{ sm: "100%", lg: "30%" }} mt="20px">
                            <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mr="15px" fontWeight='semibold' textAlign="justify">
                                Conference
                            </Text>
                            <Input onChange={onChange} variant='flushed' placeholder='Conference' size='sm' value={formValues.conference} name="conference"/>
                        </Flex>
                        <Spacer />
                        <Flex alignItems='left' direction={'column'} w={{ sm: "100%", lg: "30%" }} mt="20px">
                            <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mr="15px" fontWeight='semibold' textAlign="justify">
                                Sport
                            </Text>
                            <Input onChange={onChange} variant='flushed' placeholder='Sport' size='sm' value={formValues.sport} name="sport"/>
                        </Flex>
                    </Flex>
                    
                    {/* Second row */}
                    <Flex direction={{ sm: "column", lg: "row" }} w="100%" >
                        <Flex alignItems='left' direction={'column'} w={{ sm: "100%", lg: "30%" }} mt="20px" >
                            
                            <FormControl isInvalid={formValues.collegeID === ""}>
                                <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mb="5px" fontWeight='semibold' textAlign="justify">
                                    College
                                </Text>
                                <Select
                                    closeMenuOnSelect={true}
                                    components={animatedComponents}
                                    placeholder="Select college"
                                    isSearchable={true}
                                    options={(colleges.map(college => ({ value: college.id, label: college.name })))}
                                    onChange={(selectedOptions) => setFormValues(()=>({...formValues, collegeID: selectedOptions.value}))}
                                />
                                {formValues.collegeID === "" ? <FormErrorMessage>This field is required</FormErrorMessage> : null}
                            </FormControl>
                           
                        </Flex>
                        <Spacer />
                        <Flex alignItems='left' direction={'column'} w={{ sm: "100%", lg: "30%" }} mt="20px">
                            <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mb="5px" fontWeight='semibold' textAlign="justify">
                                Division
                            </Text>
                            <Select
                                closeMenuOnSelect={true}
                                components={animatedComponents}
                                placeholder="Select divison"
                                isSearchable={true}
                                options={division}
                                onChange={(selectedOptions) => setFormValues(()=>({...formValues, division: selectedOptions.value}))}
                            />
                        </Flex>
                        <Spacer />
                        <Flex alignItems='left' direction={'column'} w={{ sm: "100%", lg: "30%" }} mt="20px">
                            <FormControl isInvalid={formValues.genre === ""}>
                                <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mb="5px" fontWeight='semibold' textAlign="justify">
                                    Genre
                                </Text>
                                <Select
                                    closeMenuOnSelect={true}
                                    components={animatedComponents}
                                    placeholder="Select the team genre"
                                    isSearchable={true}
                                    options={[{value:"Men",label:"Men"},{value:"Women",label:"Women"}]}
                                    onChange={(selectedOptions) => setFormValues(()=>({...formValues, genre: selectedOptions.value}))}
                                />
                                {formValues.genre === "" ? <FormErrorMessage>This field is required</FormErrorMessage> : null}
                            </FormControl>

                        </Flex>
                        
                    </Flex>
                    {/* Third row */}
                    <Flex direction={{ sm: "column", lg: "row" }} w="100%" >
                        <Flex alignItems='left' direction={'column'} w={{ sm: "100%", lg: "30%" }} mt="20px" >
                            <FormControl isInvalid={formValues.suscription_name === ""}>
                                <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mb="5px" fontWeight='semibold' textAlign="justify">
                                    Suscription name
                                </Text>
                                <Select
                                    closeMenuOnSelect={true}
                                    components={animatedComponents}
                                    placeholder="Select suscription"
                                    isSearchable={true}
                                    isDisabled
                                    options={suscription_name_values}
                                    defaultValue={suscription_name_values.find(item => item.value === formValues.suscription_name)}
                                    onChange={(selectedOptions) =>  setFormValues(()=>({...formValues, suscription_name: selectedOptions.value}))}
                                />
                                {formValues.suscription_name === "" ? <FormErrorMessage>This field is required</FormErrorMessage> : null}
                            </FormControl>
                        </Flex>
                        <Spacer />
                        <Flex alignItems='left' direction={'column'} w={{ sm: "100%", lg: "30%" }} mt="20px">
                            <FormControl isInvalid={formValues.suscription_recurrency === ""}>
                                <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mb="5px" fontWeight='semibold' textAlign="justify">
                                    Suscription recurrency
                                </Text>
                                <Select
                                    closeMenuOnSelect={true}
                                    components={animatedComponents}
                                    placeholder="Select suscription recurrency"
                                    isSearchable={true}
                                    options={suscription_recurrency_values}
                                    defaultValue={suscription_recurrency_values.find(item => item.value === formValues.suscription_recurrency)}
                                    onChange={(selectedOptions) => setFormValues(()=>({...formValues, suscription_recurrency: selectedOptions.value}))}
                                />
                                {formValues.suscription_recurrency === "" ? <FormErrorMessage>This field is required</FormErrorMessage> : null}
                            </FormControl>
                        </Flex>
                        <Spacer />
                        <Flex alignItems='left' direction={'column'} w={{ sm: "100%", lg: "30%" }} mt="20px">
                            <FormControl isInvalid={formValues.suscription_status === ""}>
                                <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mb="5px" fontWeight='semibold' textAlign="justify">
                                    Suscription status
                                </Text>
                                <Select
                                    closeMenuOnSelect={true}
                                    components={animatedComponents}
                                    placeholder="Select suscription status"
                                    isSearchable={true}
                                    options={suscription_status_values}
                                    defaultValue={suscription_status_values.find(item => item.value === formValues.suscription_status)}
                                    onChange={(selectedOptions) => setFormValues(()=>({...formValues, suscription_status: selectedOptions.value}))}
                                />
                                {formValues.suscription_status === "" ? <FormErrorMessage>This field is required</FormErrorMessage> : null}
                            </FormControl>
                        </Flex>
                        
                    </Flex>
                    {/* Fourth row with drag and drop for logo*/}
                    <Flex direction="column" w="50%" mt="30px">
                        <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mb="5px" fontWeight='semibold' textAlign="justify">
                            Category
                        </Text>
                        
                        <Input onChange={(e)=>setFormValues({ ...formValues, 'college_category': e.target.value.toUpperCase() })} variant='flushed' placeholder='Category' size='sm' value={formValues.college_category} name="college_category"/>
                    </Flex>
                    {/* Fourth row with drag and drop for logo*/}
                    <Flex direction="column" w="100%" mt="30px">
                        <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mb="5px" fontWeight='semibold' textAlign="justify">
                            Team logo
                        </Text>
                        
                        {/* Component with dropzone for images */}
                        <ImageDropzone values={formValues} updateValues={setFormValues} propChanged="team_logo"/>
                    </Flex>
                </Flex> 


                {/* Confirm and cancel buttons */}
                <Flex justifyContent='right' p='10px 40px 10px 0px' >
                    <Flex direction="column" my={5}>
                        <ButtonGroup spacing="6" justifyContent='right' mb="10px">

                            <Button
                                isLoading={isLoading}
                                loadingText='Submitting'
                                colorScheme='green'
                                variant='outline'
                                onClick={handleCreate}
                                isDisabled={!formHasChanged}
                            >
                                Submit
                            </Button>
                            <Button
                                colorScheme='red'
                                variant='outline'
                                onClick={()=>(navigate(`/admin/teams/`))}
                                >
                                Cancel
                            </Button>

                        </ButtonGroup>
                        <Text fontSize={{ sm: "xs", lg: "sm" }} color={"gray.400"}>
                            <i>You must modify some fields to enable submit button*</i>
                        </Text>
                    </Flex>
                </Flex>
            </Box>
        </>
    )
}
