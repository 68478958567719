// React imports
import React, { useEffect } from 'react'
// Chakra imports
import { Flex } from "@chakra-ui/react";
// Components
import Coaches from './Coaches'

function CoachesTables({coaches, showMoreInfo,withMargin}) {

	let myCaptions = []
	// If we are showing the coaches of a team, we do not need to show more info
	showMoreInfo
	? myCaptions = ["Information", "Actions (last 30 days)", "Sport", "Team","Category", "Creation date","User status"]
	: myCaptions = ["Name", "Email", "Role", "Actions (last 30 days)"]

	useEffect(() => {
       window.scrollTo(0, 0)
    }, [])
		
	return (
		<Flex direction='column' pt={{ base: withMargin ? "30px" : "120px", md: withMargin ? "20px" : "75px" }}>
			<Coaches
				title={"Coaches"}
				captions={myCaptions}
				data={coaches}
				showMoreInfo={showMoreInfo}
			/>
		</Flex>
	);
}


const myPropsChanged = (prevProps, nextProps) => {
	if ( prevProps.coaches !== nextProps.coaches) {
			return false;
	}
	return true;
}

export default React.memo(CoachesTables, myPropsChanged);
