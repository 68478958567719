import { API } from 'aws-amplify';
import { deleteAthleteTagsRelation } from 'custom_graphql_queries/tags';
import { updateAthlete, createAthlete, deleteAthlete, deleteFavoritesFolderAthleteRelation, getAthlete } from '../custom_graphql_queries/athletes'

const updateAthleteService = async (athlete) => {
    try {
        const response = API.graphql({query: updateAthlete, variables: { input: athlete }})
        return response
    } catch (error) {
        throw new Error(error.message);
    }
}
const createAthleteService = async (athlete) => {
    try {
        API.graphql({query: createAthlete, variables: { input: athlete }})
        .catch(error => {console.log(error)})
    } catch (error) {
        throw new Error(error.message);
    }
}

const deleteAthleteService = async (athleteId) => {
    try {
        const response = await API.graphql({query: deleteAthlete, variables: { input: {id: athleteId }}})
        return response
    } catch (error) {
        throw new Error(error.message);
    }
}

const getAthleteService = async (athleteId) => {
    try {
        const response = await API.graphql({query: getAthlete, variables: { id: athleteId }})
        return response
    } catch (error) {
        throw new Error(error.message);
    }
}

const deleteAthleteTagsRelationService = async (relationId) => {
    try {
        const response = await API.graphql({query: deleteAthleteTagsRelation, variables: { input: {id: relationId }}})
        return response
    } catch (error) {
        throw new Error(error.message);
    }
}

const deleteFavoritesFolderAthleteTagsRelationService = async (relationId) => {
    try {
        const response = await API.graphql({query: deleteFavoritesFolderAthleteRelation, variables: { input: {id: relationId }}})
        return response
    } catch (error) {
        throw new Error(error.message);
    }
}

export { updateAthleteService, createAthleteService, deleteAthleteService, getAthleteService, deleteAthleteTagsRelationService,deleteFavoritesFolderAthleteTagsRelationService };