import React, { useContext, useEffect, useState }  from "react";
import { DataContext } from "contexts/dataContext";

// Chakra imports
import {
    Avatar,
    Flex,
    Icon,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    Button,
    Alert,
    AlertIcon,
    CloseButton,
    Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
    useDisclosure,
    Select,
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	NumberIncrementStepper,
	NumberDecrementStepper,
    Spacer,
    Input
} from "@chakra-ui/react";
// React icons
import { MdDelete } from "react-icons/md";
// Custom components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
// Helpers
import { changeUserGroupToAgencyId } from "helpers";
import { deleteShowcaseAthleteRelationService } from "services/showcaseServices";
import { FaPen } from "react-icons/fa";
import { HiDownload } from "react-icons/hi";
import { colorsOptions } from "helpers";
import { updateShowcaseAthleteRelationService } from "services/showcaseServices";
import { CSVLink } from "react-csv";
import { Pagination } from "components/Pagination/Pagination";
import { SearchBar } from "components/SearchBar/SearchBar";
import { getSearchedData } from "helpers/getSearchedData";

  
const ParticipantsTable = ({title, subtitle, typeOfParticipant, showcase, participants}) => {

    const textColor = useColorModeValue("gray.700", "white");  

    const dataContext = useContext(DataContext)
    const { pagination,updatePagination, user,userCoachData, showcasesAthleteRelation,setShowcasesAthleteRelation,agents } = dataContext
	const userGroup = changeUserGroupToAgencyId(user?.signInUserSession.idToken.payload["cognito:groups"][0])

    // Add participant modal actions
	const addParticipantModal = useDisclosure()

    // State that handles the selection of items (athlete, number and color)
	const initialStateAdd = {
		athlete:'',
		number:'',
		color:''
	}
	const [ selectParticipant, setSelectParticipant ] = useState(initialStateAdd)

    // State with action feedback
	const [ actionStatus, setActionStatus ] = useState({
        done: false,
        status: '',
        message: ''
    })

    // State with the searched text
	const [searchState, updateSearchState] = useState({searchText: ''})
    
    useEffect(() => {
		updatePagination({
			total:getSearchedData(participants,searchState.searchText).length,
			page: 1,
			rowsPerPage: 10,
			lower: 1})
	}, [])

    // Remove participant from showcase, deleting athlete-showcase relation
    const handleDeleteRelationAthleteShowcase = (participant) => {
        const relationToDelete = showcasesAthleteRelation.find(rel=> rel.showcaseID === showcase.id && rel.athleteID === participant.id)
		deleteShowcaseAthleteRelationService(relationToDelete?.id).then(()=>{
			setShowcasesAthleteRelation(showcasesAthleteRelation.filter(rel=> rel.id !== relationToDelete?.id))
			// console.log("[OK] Delete showcase athlete relation done.")
			setActionStatus({
				done: true,
				status: 'success',
				message: 'Participant deleted successfully'
			})
		}).catch((err)=>{
			console.log("[ERROR Delete showcase athlete relation]:",err)
			setActionStatus({
				done: true,
				status: 'error',
				message: 'An error has ocurred while deleting participant to the showcase'
			})
		})
    }

    const handleEditRelationAthleteShowcase = () => {
        addParticipantModal.onClose()
        // we need to exlude showcase and athlete objects from GraphQL query
        const newRelation = {
            id: selectParticipant?.id,
            athleteID:selectParticipant?.athleteID,
            showcaseID:selectParticipant?.showcaseID,
            color:selectParticipant?.color,
            number:selectParticipant?.number,
        }
        // Create and store in context the new athlete-showcase relation
        updateShowcaseAthleteRelationService(newRelation).then(()=>{
            setShowcasesAthleteRelation(showcasesAthleteRelation.map(it=> it.id === selectParticipant.id ? {...selectParticipant} : it))
            setActionStatus({
				done: true,
				status: 'success',
				message: 'Participant/s updated successfully'
			})
        }).catch((err)=>{
            console.log("[ERROR] Update showcase athlete relation:",err)
            setActionStatus({
				done: true,
				status: 'error',
				message: 'An error has ocurred while updating participant/s to the showcase'
			})
        })
        
    }

    const handleDeleteInfoRelationAthleteShowcase = () => {
        addParticipantModal.onClose()
        // we need to exlude showcase and athlete objects from GraphQL query
        const newRelation = {
            id: selectParticipant?.id,
            athleteID:selectParticipant?.athleteID,
            showcaseID:selectParticipant?.showcaseID,
            color: null,
            number: null,
        }
        // Aux
        const {color,number, ...participant} = selectParticipant

        // Create and store in context the new athlete-showcase relation
        updateShowcaseAthleteRelationService(newRelation).then(()=>{
            setShowcasesAthleteRelation(showcasesAthleteRelation.map(it=> it.id === selectParticipant.id ? {...participant} : it))
            setActionStatus({
				done: true,
				status: 'success',
				message: "Athlete's information deleted successfully"
			})
        }).catch((err)=>{
            console.log("[ERROR] Deleting info showcase athlete relation:",err)
            setActionStatus({
				done: true,
				status: 'error',
				message: 'An error has ocurred while deleting athlete information'
			})
        })
        
    }
    
    const findParticipantRelationToEdit = (participant) => {
        addParticipantModal.onOpen()
        const relationToEdit = showcasesAthleteRelation.find(rel => rel.showcaseID === showcase.id && rel.athleteID === participant.id)
        setSelectParticipant({...relationToEdit})
    }

    const getDownloadData = () => {
        // Add the number and team from the relation to the participants
        return participants.map(it => {
            return {
                "Name":it.name,
                "Profile url":`https://app.allianceathletes.com/app/athlete_${it.id}`,
                "Contact email":it.contact_email,
                "Agency":it.agency,
                "Agent":it.agent?.name,
                "Showcase Team": showcasesAthleteRelation.find(rel => rel.showcaseID === showcase.id && rel.athleteID === it.id)?.color && 'Team '+showcasesAthleteRelation.find(rel => rel.showcaseID === showcase.id && rel.athleteID === it.id)?.color,
                "Showcase Jersey #": showcasesAthleteRelation.find(rel => rel.showcaseID === showcase.id && rel.athleteID === it.id)?.number && "Jersey #"+showcasesAthleteRelation.find(rel => rel.showcaseID === showcase.id && rel.athleteID === it.id)?.number
            }
        })
    }

    const onChange = (e) => {
        e.persist()
        setSelectParticipant({...selectParticipant, number:e.target.value})
    }

    return (
        <>
            <Modal
				isCentered
				onClose={addParticipantModal.onClose}
				isOpen={addParticipantModal.isOpen}
				motionPreset='slideInBottom'
			>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Edit participant</ModalHeader>
					<ModalCloseButton onClick={()=>{addParticipantModal.onClose();setSelectParticipant(initialStateAdd)}}/>
					<ModalBody>
						Complete the required information for this athlete.<br/>
						{/* <strong>Select one of the following athletes: </strong>
						<Select placeholder="Select PSAs" my="10px" bg="#EBF5FB" variant='Filled' value={selectParticipant.athlete} onChange={(e)=>setSelectParticipant({...selectParticipant, athlete:e.target.value})}>
							{userGroup === 'superadmins' && athletes?.filter(it => !showcasesAthleteRelation.find(rel=>rel.athleteID === it.id)).map((opt,index) => {return (<option key={index} value={opt.id} color="royalblue">{opt.name}</option>)})}
							{userCoachData.agent_role==="LEADER" && athletes?.filter(ath=>ath.agencyID === userGroup && !showcasesAthleteRelation.find(rel=>rel.athleteID === ath.id) ).map((opt,index) => {return (<option key={index} value={opt.id} color="royalblue">{opt.name}</option>)})}
							{userCoachData.agent_role==="ASSOCIATE" && athletes?.filter(ath=>ath.agentID === agents.find(it=>it.contact_email === userCoachData.contact_email)?.id  && !showcasesAthleteRelation.find(rel=>rel.athleteID === ath.id) ).map((opt,index) => {return (<option key={index} value={opt.id} color="royalblue">{opt.name}</option>)})}
						</Select>  */}

                        <strong>{participants.find(it=>it.id === selectParticipant?.athleteID)?.name}</strong>
                        <br />
                        <br />

						<strong>Enter the number that the athlete will wear: </strong>
						{/* <NumberInput min={1} max={300} value={selectParticipant.number || ""} onChange={(value)=>setSelectParticipant({...selectParticipant, number:Number(value)})}>
							<NumberInputField />
							<NumberInputStepper>
								<NumberIncrementStepper />
								<NumberDecrementStepper />
							</NumberInputStepper>
						</NumberInput> */}
                        <Input
                            variant='flushed'
                            fontSize='sm'
                            placeholder='Athlete jersey #'
                            size='md'
                            value={selectParticipant.number || ""}
                            onChange={onChange}
                            name='number'
                            mb="10px"
                        />

						<strong>Enter the color that the player will wear: </strong>
						<Select placeholder="Select color" my="10px" bg="#EBF5FB" variant='Filled' value={selectParticipant.color} onChange={(e)=>setSelectParticipant({...selectParticipant, color:e.target.value})}>
							{colorsOptions.map((opt,index) => {return (<option key={index} value={opt.value} color="royalblue">{opt.label}</option>)})}
						</Select>

					</ModalBody>
					<ModalFooter>
						<Button colorScheme='red' mr={3} onClick={()=>{addParticipantModal.onClose();setSelectParticipant(initialStateAdd)}}>
							Cancel
						</Button>
						<Button variant='ghost' colorScheme='red' onClick={handleDeleteInfoRelationAthleteShowcase} >Delete information</Button>
						<Button variant='ghost' colorScheme='blue' onClick={handleEditRelationAthleteShowcase} isDisabled={!selectParticipant.color || !selectParticipant.number}>Confirm</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>

            <Flex direction="column" w="100%" mt="20px">

                {
                    actionStatus.done && (
                        <Alert status={actionStatus.status} borderRadius="10px" mb="20px" >
                            <AlertIcon />
                            {actionStatus.message}
                            <CloseButton position='absolute' right='8px' top='8px' onClick={()=>(setActionStatus({...actionStatus,done:false}))} />
                        </Alert>
                    )
                }
                {
                    participants.length > 0 && (
                        <>
                            <Flex justifyContent="right" m="0px 10px 10px 0">
                                <CSVLink 
                                    data={getDownloadData()}
                                    filename={`${showcase.name} - Athletes`}
                                >
                                    <Button
                                        colorScheme='green'
                                        variant='ghost'
                                        leftIcon={<Icon as={HiDownload}/>}
                                        size='sm'
                                    >
                                        Export to CSV
                                    </Button>
                                </CSVLink>
                            </Flex>
                        </>
                    )
                }
                <Card p='16px' overflowX={{ sm: "scroll", xl: "hidden" }}>
                    <CardHeader p='12px 0px 15px 0px'>
                        <Flex direction={"row"} w="100%">
                            <Flex direction='column'>
                                <Text fontSize='lg' color={textColor} fontWeight='bold' pb='.5rem'>
                                {title} ({searchState.searchText!== '' ? `Filtered result ${getSearchedData(participants,searchState.searchText).length}` : participants.length})
                                </Text>
                                <Text fontSize='md' fontWeight='medium' color='gray.400'>
                                {subtitle}
                                </Text>
                            </Flex>
                            <Spacer />
                            <Flex justifyContent='right'>
                                <SearchBar text={searchState} update={updateSearchState}/>
                            </Flex>
                        </Flex>
                    </CardHeader>
                    <Table variant='simple' color={textColor}>
                        <Thead>
                            <Tr my='.4rem' ps='0px'>
                                <Th color='gray.400'>
                                    Photo
                                </Th>
                                <Th color='gray.400'>
                                    Athlete
                                </Th>
                                <Th color='gray.400'>
                                    More info
                                </Th>
                                <Th color='gray.400'>
                                    Athlete visible in app
                                </Th>
                                <Th color='gray.400'>
                                    Athlete info
                                </Th>
                                <Th color='gray.400'>
                                    Edit Athlete
                                </Th>
                                <Th color='gray.400'>
                                    Remove Athlete
                                </Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {
                                getSearchedData(participants,searchState.searchText).slice(pagination.lower-1,pagination.lower+pagination.rowsPerPage-1).map((participant,idx) => {
                                    return (
                                    <Tr key={idx}>
                                        <Td w="10%" alignContent="center" >
                                            <Avatar src={participant.profile_pic} size="lg"/>
                                        </Td>
                                        <Td w="35%">
                                            <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
                                                <Flex direction="column">
                                                    <Text
                                                        fontSize="md"
                                                        color={textColor}
                                                        fontWeight="bold"
                                                        minWidth="100%"
                                                    >
                                                        {participant.name}
                                                    </Text>
                                                    <Text fontSize="sm" color="gray.400" fontWeight="normal">
                                                        {participant.contact_email}
                                                    </Text>
                                                </Flex>
                                            </Flex>
                                        </Td>

                                        <Td w="30%">
                                            <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
                                                <Flex direction="column">
                                                    <Text
                                                        fontSize="md"
                                                        color={textColor}
                                                        fontWeight="bold"
                                                        minWidth="100%"
                                                        >
                                                        {typeOfParticipant === 'Athlete' ? participant.agency : participant.teamID}
                                                    </Text>
                                                    <Text fontSize="sm" color="gray.400" fontWeight="normal">
                                                        {typeOfParticipant === 'Athlete' ? participant.sport : participant.genre}
                                                    </Text>
                                                </Flex>
                                            </Flex>
                                        </Td>

                                        <Td w="30%">
                                            <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
                                                <Text
                                                    fontSize="md"
                                                    color="gray.400"
                                                    minWidth="100%"
                                                    >
                                                    { participant.isVisible ? "Visible": "Hidden"}
                                                </Text>
                                            </Flex>
                                        </Td>
                                        <Td w="20%">
                                            <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
                                                <Flex direction="column">
                                                    <Text
                                                        fontSize="md"
                                                        color={textColor}
                                                        fontWeight="bold"
                                                        minWidth="100%"
                                                    >
                                                        {showcasesAthleteRelation.find(rel => rel.showcaseID === showcase.id && rel.athleteID === participant.id)?.color && 'Team '+showcasesAthleteRelation.find(rel => rel.showcaseID === showcase.id && rel.athleteID === participant.id)?.color}
                                                    </Text>
                                                    <Text fontSize="sm" color="gray.400" fontWeight="normal">
                                                        {showcasesAthleteRelation.find(rel => rel.showcaseID === showcase.id && rel.athleteID === participant.id)?.number && "Jersey #"+showcasesAthleteRelation.find(rel => rel.showcaseID === showcase.id && rel.athleteID === participant.id)?.number}
                                                    </Text>
                                                </Flex>
                                            </Flex>
                                        </Td>
                                        <Td w="15%">
                                            {
                                                ((userGroup === 'superadmins') ||  (userCoachData.agent_role==="LEADER" && participant.agencyID === userGroup) || (userCoachData.agent_role==="ASSOCIATE" && participant.agentID === agents.find(it=>it.contact_email === userCoachData.contact_email)?.id)) && (
                                                    <Button
                                                        colorScheme='green'
                                                        variant='ghost'
                                                        leftIcon={<Icon as={FaPen} />}
                                                        size='sm'
                                                        onClick={()=>findParticipantRelationToEdit(participant)}
                                                        >
                                                        EDIT
                                                    </Button>
                                                )
                                            }
                                        </Td>
                                        <Td w="15%">
                                            {
                                                ((userGroup === 'superadmins') ||  (userCoachData.agent_role==="LEADER" && participant.agencyID === userGroup) || (userCoachData.agent_role==="ASSOCIATE" && participant.agentID === agents.find(it=>it.contact_email === userCoachData.contact_email)?.id)) && (
                                                    <Button
                                                        colorScheme='red'
                                                        variant='ghost'
                                                        leftIcon={<Icon as={MdDelete} />}
                                                        size='sm'
                                                        onClick={()=>handleDeleteRelationAthleteShowcase(participant)}
                                                        >
                                                        DELETE
                                                    </Button>
                                                )
                                            }
                                        </Td>
                                    </Tr>
                                    )
                                })
                            }
                        </Tbody>
                    </Table>
                    {participants.length > 0 && (<Pagination totalData={getSearchedData(participants,searchState.searchText).length} rowsPerPageValues={[10,20,30,40,50]} />)}
                </Card>
            </Flex>
        </>
    )
}

export default ParticipantsTable