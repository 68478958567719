import { API } from 'aws-amplify';
import { updateCollege, createCollege, deleteCollege } from '../custom_graphql_queries/colleges'

const updateCollegeService = async (college) => {
    try {
        API.graphql({query: updateCollege, variables: { input: college }})
        .catch(error => {console.log(error)})
    } catch (error) {
        throw new Error(error.message);
    }
}
const createCollegeService = async (college) => {
    try {
        API.graphql({query: createCollege, variables: { input: college }})
        .catch(error => {console.log(error)})
    } catch (error) {
        throw new Error(error.message);
    }
}

const deleteCollegeService = async (collegeId) => {
    try {
        const response = await API.graphql({query: deleteCollege, variables: { input: {id: collegeId }}})
        return response
    } catch (error) {
        throw new Error(error.message);
    }
}

export { updateCollegeService, createCollegeService, deleteCollegeService };