// React imports
import React, { useContext, useState, useEffect} from 'react'
import { useParams, useNavigate } from 'react-router-dom';
// Context imports
import { DataContext } from "../../../../contexts/dataContext";
// React-select imports
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
// Chackra Components
import { Flex, useColorModeValue, Text, FormControl,FormErrorMessage, Input,Spacer, Box, Button, ButtonGroup } from "@chakra-ui/react";
// Auxiliary imports
import { countries } from "../../../../helpers"
import { uploadImageToCloudinary } from "../../../../helpers/uploadImageToCloudinary";
import { updateCollegeService } from "../../../../services/collegeServices";
import validator from "validator";
// Component 
import { ImageDropzone } from "../../../../components/Dropzone/ImageDropzone";

// id !
// name !
// country ok
// state
// address
// logo

export const EditCollege = () => {
    // Chakra color mode
    const textColor = useColorModeValue("gray.700", "white");
	const animatedComponents = makeAnimated();
    // Get the id of the college from the url
	const { collegeId } = useParams();
    // Navigation hook
	let navigate = useNavigate()

	// Take the context data needed in this component
    const dataContext = useContext(DataContext)
    const { colleges, setColleges } = dataContext
    const college = colleges.find(college => college.id === collegeId)

	// The initial state of the form with the data of the agency
	const initialState = {
		id: college.id,
		name: college.name,
		logo: college.logo,
		state: college.state ? college.state : '',
		address: college.address ? college.address : '',
		country: college.country,
	}

    // State that controls the form values
	const [formValues, setFormValues] = useState(initialState)
	// State to check if form has changed and enable submit button
	const [formHasChanged, setFormHasChanged] = useState(false)
	// State that controls if the submit is loading
	const [isLoading, setIsLoading] = useState(false)

    // When the form values change, check that the mandatory fields are filled and enable the submit button
	useEffect(() => {
		JSON.stringify(formValues) !== JSON.stringify(initialState) && formValues.name !== '' ? setFormHasChanged(true) : setFormHasChanged(false)
	}, [formValues])

    // Function that handles the change of the country select
	const handleChange = (selectedOptions) => {
		setFormValues(()=>({...formValues, country: selectedOptions.value}))
	}

	const onChange = (e) => {
        e.persist()
        setFormValues(() => ({ ...formValues, [e.target.name]: e.target.value }))
    }

    // Function that submit the form. It checks if the user has provided a new logo and upload it to cloudinary
	const handleEdit = () => {

		setIsLoading(true)
		
        // If the user leaves fields blank, we set it to null so that the dynamo update is correct. 
        const collegeWithoutEmptyValues = Object.keys(formValues).reduce((obj, key) => {
            formValues[key] !== "" ? obj[key] = formValues[key] : obj[key] = null
            return obj
        }, {})

			
        if(formValues.logo !== initialState.logo){
            uploadImageToCloudinary({image:formValues.logo,directory:'devtests'})
            .then((url) => {
                updateCollegeService({ ...collegeWithoutEmptyValues, logo: url })
                setColleges(
                    colleges.map(college => {
                        if(college.id === collegeWithoutEmptyValues.id){
                            return{ ...collegeWithoutEmptyValues, logo: url }
                        }
                        return college
                    })
                )
            })
            
        }
        else{
            updateCollegeService({ ...collegeWithoutEmptyValues})
            setColleges(
                colleges.map(college => {
                    if(college.id === collegeWithoutEmptyValues.id){
                        return {...collegeWithoutEmptyValues }
                    }
                    return college
                })
            )
        }

        setIsLoading(false)
        navigate(`/admin/colleges/show/${collegeId}`)
		
		
	}


    return (
        <Box mt={{ base: "120px", md: "90px" }} borderRadius="md" boxShadow="md" bg="white" >
			{/* Header */}
			<Flex justifyContent='left' p='15px 0px 10px 20px' >
				<Text fontSize='xl' color={textColor} fontWeight='bold' mr="200px">
					Edit college - <i>{college.name}</i>
				</Text>
			</Flex>

			{/* Form of their attributes */}
			<Flex direction='column' w="100%" alignContent="center" px="20px"  > 
				
				{/* First row */}
				<Flex direction={{ sm: "column", lg: "row" }} w="100%">
					<Flex w={{ sm: "100%", lg: "45%" }} mt="20px">
						<FormControl isInvalid={formValues.name === ""}>
							<Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mr="15px" fontWeight='semibold' textAlign="justify">
								Name
							</Text>
							<Input isRequired={true} onChange={onChange} variant='flushed' placeholder='Name' size='sm' value={formValues.name} name="name"/>
							{formValues.name === "" ? <FormErrorMessage>This field is required</FormErrorMessage> : null}
						</FormControl>
					</Flex>
					<Spacer />
					<Flex alignItems='left' direction={'column'} w={{ sm: "100%", lg: "45%" }} mt="20px">
                        <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mr="15px" fontWeight='semibold' textAlign="justify">
                            State
                        </Text>
                        <Input onChange={onChange} variant='flushed' placeholder='State' size='sm' value={formValues.state} name="state"/>
					</Flex>
				</Flex>


				{/* Second row with country selector */}
				<Flex direction="column" w="100%" mt="20px">
					<Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mb="5px" fontWeight='semibold' textAlign="justify">
						Country
					</Text>
					<Select
						closeMenuOnSelect={true}
						components={animatedComponents}
						placeholder="Select country"
						isSearchable={true}
                        defaultValue={formValues.country && {value:formValues.country,label:formValues.country}}
						options={countries}
						onChange={(selectedOptions) => handleChange(selectedOptions)}
					/>
				</Flex>


				{/* Third row */}
				<Flex direction={{ sm: "column", lg: "row" }} w="100%" >
					<Flex alignItems='left' direction={'column'} w="100%" mt="20px" >
                        <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mr="15px" fontWeight='semibold' textAlign="justify">
                            Address
                        </Text>
                        <Input onChange={onChange} variant='flushed' placeholder='Address' size='sm' value={formValues.address} name="address"/>
					</Flex>
				</Flex>

				{/* Fourth row with drag and drop for logo*/}
				<Flex direction="column" w="100%" mt="30px">
					<Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mb="5px" fontWeight='semibold' textAlign="justify">
						College Logo
					</Text>
					
					{/* Component with dropzone for images */}
					<ImageDropzone values={formValues} updateValues={setFormValues} propChanged="logo"/>
				</Flex>
			</Flex> 


			{/* Confirm and cancel buttons */}
			<Flex justifyContent='right' p='10px 40px 10px 0px' >
				<Flex direction="column" my={5}>
					<ButtonGroup spacing="6" justifyContent='right' mb="10px">

						<Button
							isLoading={isLoading}
							loadingText='Submitting'
							colorScheme='green'
							variant='outline'
							onClick={handleEdit}
							isDisabled={!formHasChanged}
						>
							Submit
						</Button>
						<Button
							colorScheme='red'
							variant='outline'
							onClick={()=>(navigate(`/admin/colleges/show/${collegeId}`))}
							>
							Cancel
						</Button>

					</ButtonGroup>
					<Text fontSize={{ sm: "xs", lg: "sm" }} color={"gray.400"}>
						<i>You must modify some fields to enable submit button*</i>
					</Text>
				</Flex>
			</Flex>
		</Box>
    )
}
