import React, { useContext }  from "react";
import { DataContext } from '../../../../contexts/dataContext'


// Chakra imports
import {
	Flex, AspectRatio, Image, SimpleGrid, useColorModeValue, Icon, Divider, Box, Text, Badge,
} from "@chakra-ui/react";
// Custom icons
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";


export const TutorialEntry = ({title, description, category, type, item, date, author, format}) => {

    return (
        <>
            <Card>
                <CardHeader>
                    <Text fontSize="lg" fontWeight="bold">{title}</Text>
                    {
                        category && (
                            <Badge
                                variant='solid' colorScheme={type}
                                p="5px"
                                borderRadius="8px"
                                ml="20px"
                            >
                                {category}
                            </Badge>
                        )
                    }
                    
                </CardHeader>
                <CardBody my="10px">
                    <Flex direction="column" w="100%" h="400px" >
                        <Text fontSize="md" mb="5px">{description}</Text>
                        <Flex h="100%" w="100%" margin="auto">
                            {
                                format === 'pdf' ? (
                                    <iframe src={item} width="100%" />
                                ) : (
                                    <video 
                                        controls
                                        width="100%"
                                        frameBorder="0"
                                    >
                                        <source src={item} />
                                    </video>
                                )
                            }
                        </Flex>
                    </Flex>
                </CardBody>
                <Flex justifyContent="right"  direction="row" mt={{sm:"25px",md:"15px",lg:"7px"}}>
                    <Text fontSize="sm" color="gray.400" fontWeight="normal" mr="20px">{author}</Text>
                    <Text fontSize="sm" color="gray.400" fontWeight="normal">{date}</Text>
                </Flex>
            </Card>     
        
        
        </>
    )
}
