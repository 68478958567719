// Chakra imports
import {
	Box,
	Button,
	Flex,
	FormControl,
	Input,
	InputGroup,
	InputRightElement,
	IconButton,
	FormErrorMessage ,
	Text,
	useColorModeValue,
	Alert,
	AlertIcon,
	AlertTitle,
	AlertDescription,
} from "@chakra-ui/react";
import {ViewIcon, ViewOffIcon} from "@chakra-ui/icons";
// Assets
import BgSignUp from "assets/img/BgSignUp.png";
import React, {useContext, useState, useEffect} from "react";
import { DataContext } from '../../contexts/dataContext';
import { useNavigate, useLocation } from 'react-router-dom'

const initialFormState = {
    old_password: '',
    new_password: '',
    confirm_password: '',
}

function FirstChangePassword() {
	
	let navigate = useNavigate();
	const email = useLocation().state.email

	const bgColor = useColorModeValue("white", "gray.700");

	const dataContext = useContext(DataContext)
    const { authCompleteNewPassword, authSignIn, error, updateError, updateUser} = dataContext
	const [formValues , updateFormState] = useState(initialFormState)
	// State that controls if the submit is loading
	const [isLoading, setIsLoading] = useState(false)
	// State with show/hide password
	const [showPassword, setShowPassword] = useState({
		old_password: false,
		new_password: false,
		confirm_password: false,
	})


	useEffect(() => {
		updateError('')
		if (!email) {
			navigate('/auth', { replace: true })
		}
	}, [])

	const onChange = (e) => {
        e.persist()
        updateFormState(() => ({ ...formValues , [e.target.name]: e.target.value }))
    }

	const handleResetPassword = (e) => {
		e.preventDefault();

		setIsLoading(true)
	
		authSignIn(email, 'Alliance2021!')
		.then( (res) => {
			authCompleteNewPassword(res, formValues.new_password)
			.then(res => {
				setIsLoading(false)
				updateUser(res)
			})
		})
	}

	return (
		<Flex
			direction='column'
			alignSelf='center'
			justifySelf='center'
			overflow='hidden'
		>

			<Box
				position='absolute'
				minH={{ base: "70vh", md: "50vh" }}
				w={{ md: "calc(100vw - 50px)" }}
				borderRadius={{ md: "15px" }}
				left='0'
				right='0'
				bgRepeat='no-repeat'
				overflow='hidden'
				zIndex='-1'
				top='0'
				bgImage={BgSignUp}
				bgSize='cover'
				mx={{ md: "auto" }}
				mt={{ md: "14px" }} />


			<Flex
				direction='column'
				textAlign='center'
				justifyContent='center'
				align='center'
				mt='3rem'
			>

				<Text fontSize='4xl' color='white' fontWeight='bold'>
					Change password
				</Text>

				<Text
					fontSize='md'
					color='white'
					fontWeight='normal'
					mt='5px'
					w={{ base: "90%", sm: "60%", lg: "50%", xl: "40%" }}>
					Please, use the fields below to change your password. <br />We recommend you use a strong password to protect your account. This may include caps, symbols and a combination of letters and numbers. Once you complete the process, you will remain logged in with your new password.<br />Please, use your new password to log in at <strong>www.allianceathletes.com</strong>
				</Text>

			</Flex>

			<Flex alignItems='center' justifyContent='center' mb='20px' mt='10px'>

				<Flex
					direction='column'
					w='445px'
					background='transparent'
					borderRadius='15px'
					p='40px'
					mx={{ base: "100px" }}
					bg={bgColor}
					boxShadow='0 20px 27px 0 rgb(0 0 0 / 5%)'
				>
					
					{error && (
					<Alert status='error' mb="15px" borderRadius="10px">
						<AlertIcon />
						<Box flex='1'>
							<AlertTitle>Sorry!</AlertTitle>
							<AlertDescription display='block'>
								{error}
							</AlertDescription>
						</Box>
					</Alert>
					)}
						<FormControl isInvalid={formValues.old_password!== '' && formValues.old_password !== 'Alliance2021!'}>
							<Text fontSize='sm' fontWeight='normal'>
								Old password
							</Text>
							<InputGroup>
								<Input
									variant='flushed'
									fontSize='sm'
									ms='4px'
									type={showPassword.old_password ? 'text' : 'password'}
									placeholder='Your old password'
									size='lg'
									value={formValues.old_password || ''}
									onChange={onChange}
									name='old_password'
								/>
								<InputRightElement>
									<IconButton
									colorScheme='gray'
									aria-label='Search database'
									icon={ showPassword.old_password ? <ViewOffIcon /> : <ViewIcon /> }
									onClick={() => setShowPassword({...showPassword, old_password: !showPassword.old_password})}
									/>
								</InputRightElement>
							</InputGroup>
							{formValues.old_password!== '' && formValues.old_password !== 'Alliance2021!' ? <FormErrorMessage>Actual password is incorrect</FormErrorMessage> : null}
						</FormControl>

						<FormControl isInvalid={formValues.new_password!== '' && formValues.new_password.length < 8}>
							<Text mt='10px' fontSize='sm' fontWeight='normal'>
								New password
							</Text>
							<InputGroup>
								<Input
									variant='flushed'
									fontSize='sm'
									type={showPassword.new_password ? 'text' : 'password'}
									placeholder='Your new password'
									size='lg'
									value={formValues.new_password || ''}
									onChange={onChange}
									name='new_password'
								/>
								<InputRightElement>
									<IconButton
									colorScheme='gray'
									aria-label='Search database'
									icon={ showPassword.new_password ? <ViewOffIcon /> : <ViewIcon /> }
									onClick={() => setShowPassword({...showPassword, new_password: !showPassword.new_password})}
									/>
								</InputRightElement>
							</InputGroup>
							{formValues.new_password!== '' && formValues.new_password.length < 8 ? <FormErrorMessage>Password must be at least 8 characters long</FormErrorMessage> : null}
						</FormControl>

						<FormControl isInvalid={formValues.confirm_password !== '' && formValues.confirm_password !== formValues.new_password}>
							<Text mt='10px' fontSize='sm' fontWeight='normal'>
								Confirm new password
							</Text>
							<InputGroup>
								<Input
									variant='flushed'
									fontSize='sm'
									type={showPassword.confirm_password ? 'text' : 'password'}
									placeholder='Confirm your new password'
									size='lg'
									value={formValues.confirm_password || ''}
									onChange={onChange}
									name='confirm_password'
								/>
								<InputRightElement>
									<IconButton
									colorScheme='gray'
									aria-label='Search database'
									icon={ showPassword.confirm_password ? <ViewOffIcon /> : <ViewIcon /> }
									onClick={() => setShowPassword({...showPassword, confirm_password: !showPassword.confirm_password})}
									/>
								</InputRightElement>
							</InputGroup>

							{formValues.confirm_password !== '' && formValues.confirm_password !== formValues.new_password ? <FormErrorMessage>Passwords do not match</FormErrorMessage> : null}
						</FormControl>
						
						<Button
							type='submit'
							bg='royalblue.700'
							fontSize='14px'
							color='white'
							mt='20px'
							fontWeight='bold'
							w='100%'
							h='45'
							isDisabled={formValues.old_password === '' || formValues.new_password === '' || formValues.confirm_password === '' || formValues.new_password !== formValues.confirm_password || formValues.new_password.length < 8 || formValues.old_password !== 'Alliance2021!'}
							isLoading={isLoading}
							loadingText='Success. Redirecting to dashboard...'
							_hover={{
								bg: "blue.200",
							}}
							_active={{
								bg: "blue.400",
							}}
							onClick={handleResetPassword}
						>
							RESET PASSWORD
						</Button>
						
					
				</Flex>
			</Flex>
		</Flex>
	);
}

export default FirstChangePassword;
