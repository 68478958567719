export const createCoachNotificationRelation = /* GraphQL */ `
  mutation CreateCoachNotificationRelation(
    $input: CreateCoachNotificationRelationInput!
    $condition: ModelCoachNotificationRelationConditionInput
  ) {
    createCoachNotificationRelation(input: $input, condition: $condition) {
      id
      notificationID
      coachID
      is_viewed
      status
      notification {
        id
        title
        text
        status
        type
        pic
        attachment
        coaches_notified {
          nextToken
        }
      }
      coach {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        disabledByUser
        is_active
        allow_whatsapp_contact
        deleteAppReason
        division
        role
        sport
        teamID
        team {
          id
          name
          sport
          genre
          suscription_name
          suscription_recurrency
          suscription_status
          team_logo
          conference
          had_trial
          division
          collegeID
        }
        favorites {
          nextToken
        }
        my_filters {
          nextToken
        }
        my_favorite_folders {
          nextToken
        }
        my_notifications {
          nextToken
        }
        showcases_interested_in {
          nextToken
        }
      }
    }
  }
`;