export const getSearchedData = (data, searchText= '', type = '') => {
    // if the type is coach return is_active === true

    return data?.filter(it => {
        if (type === 'coach') {
            return it ? it?.name?.toLowerCase().includes(searchText.toLowerCase()) && it?.is_active === true : false;
        } else {
            return it ? it?.name?.toLowerCase().includes(searchText.toLowerCase()) : false;
        }
    });
}