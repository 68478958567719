/*eslint-disable*/
// chakra imports
import {
		Box,
		Button, Flex,
		Stack,
		Text,
		useColorModeValue,
} from "@chakra-ui/react";
import IconBox from "components/Icons/IconBox";
import React, { useContext } from "react";
import { NavLink, useLocation } from "react-router-dom";
// Context imports
import { DataContext } from '../../contexts/dataContext'


// this function creates the links and collapses that appear in the sidebar (left menu)


const SidebarContent = ({ logoText, routes }) => {

	// to check for active links and opened collapses
	let location = useLocation();
	// this is for the rest of the collapses
	const [state, setState] = React.useState({});

	// verifies if routeName is the one active (in browser input)
	const activeRoute = (routeName) => {
		// Here, .split is used to get the main part of the path.
		// For example, if the path is /admin/agencies/<agencyId>, we want to select Agencies from the sidebar
		return location.pathname.split("/")[2] === routeName.split("/")[2] ? "active" : "";
	};

	// Take the context data needed in this component
	const dataContext = useContext(DataContext)
	const { user,userCoachData } = dataContext

	
	const createLinks = (routes) => {
		// Chakra Color Mode
		const activeBg = useColorModeValue("white", "gray.700");
		const inactiveBg = useColorModeValue("white", "gray.700");
		const activeColor = useColorModeValue("gray.700", "white");
		const inactiveColor = useColorModeValue("gray.400", "gray.400");

		const filterRoutes = routes.filter((route) => {
			if(route.superadmin){
				return (route.name === "Pending coaches" && userCoachData?.agent_role === "LEADER") || user?.signInUserSession.idToken.payload["cognito:groups"][0]==='superadmins' ? true : false
			} 
			else{
				return true
			}
		});
		
		return filterRoutes.map((prop, key) => {
			if (prop.redirect) {
				return null;
			}
			if (prop.category) {
				return null;
			}
			return (
				<NavLink to={prop.layout + prop.path} key={prop.name}>
					{activeRoute(prop.layout + prop.path) === "active" ? (
						<Button
							boxSize="initial"
							justifyContent="flex-start"
							alignItems="center"
							bg={activeBg}
							mb={{
								xl: "20px",
							}}
							mx={{
								xl: "auto",
							}}
							ps={{
								sm: "10px",
								xl: "16px",
							}}
							py="12px"
							borderRadius="15px"
							_hover="none"
							w="100%"
							_active={{
								bg: "inherit",
								transform: "none",
								borderColor: "transparent",
							}}
							_focus={{
								boxShadow: "none",
							}}
						>
							<Flex>
								{typeof prop.icon === "string" ? (
									<Icon>{prop.icon}</Icon>
								) : (
									<IconBox
										bg="royalblue.700"
										color="white"
										h="30px"
										w="30px"
										me="12px"
									>
										{prop.icon}
									</IconBox>
								)}
								<Text color={activeColor} my="auto" fontSize="sm">
									{document.documentElement.dir === "rtl"
										? prop.rtlName
										: prop.name}
								</Text>
							</Flex>
						</Button>
					) : (
						<Button
							boxSize="initial"
							justifyContent="flex-start"
							alignItems="center"
							bg="transparent"
							mb={{
								xl: "12px",
							}}
							mx={{
								xl: "auto",
							}}
							py="12px"
							ps={{
								sm: "10px",
								xl: "16px",
							}}
							borderRadius="15px"
							_hover="none"
							w="100%"
							_active={{
								bg: "inherit",
								transform: "none",
								borderColor: "transparent",
							}}
							_focus={{
								boxShadow: "none",
							}}
						>
							<Flex>
								{typeof prop.icon === "string" ? (
									<Icon>{prop.icon}</Icon>
								) : (
									<IconBox
										bg={inactiveBg}
										color="royalblue.700"
										h="30px"
										w="30px"
										me="12px"
									>
										{prop.icon}
									</IconBox>
								)}
								<Text color={inactiveColor} my="auto" fontSize="sm">
									{document.documentElement.dir === "rtl"
										? prop.rtlName
										: prop.name}
								</Text>
							</Flex>
						</Button>
					)}
				</NavLink>
			);
		});
	};

	const links = <>{createLinks(routes)}</>;

	return (
		<>
			<Stack direction="column" mb={{sm:"110px",md:"130px",lg:"150px"}}>
				<Box>{links}</Box>
			</Stack>
			{/* <SidebarHelp /> */}
		</>
	)
}

export default SidebarContent