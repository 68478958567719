import { Button, ScaleFade, Flex, Heading, Icon, Text } from '@chakra-ui/react';
import LineChart from 'components/Charts/LineChart';
import React, { useState } from 'react'
import { CSVLink } from 'react-csv';
import { HiDownload } from 'react-icons/hi';
import { getlineChartOptionsWithColors } from "variables/charts";
import LineChartSummary from '../LineChartSummary';
import CardBody from 'components/Card/CardBody';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';


const CoachesAthletesCreated = ({athletes,coaches}) => {
    // console.log("[CoachesAthletesCreated]: Me renderizo")

	const [hasRender, setRender] = useState(false);
  	const onShow = React.useCallback(() => setRender(true), []);

    const [sportFilter, setSportFilter] = useState({'CoachesAthletesCreated':'Total'})

    let lineChartOptionsCoachAthlete = getlineChartOptionsWithColors("#2049DA","#E03047")


    // Get coaches created per month
	const prepareDataCoachAthletesCreatedPerMonth = () => {
		const coachesCount = []
		const athletesCount = []
		const monthsNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
		
		coaches.filter(item=>item.sport === sportFilter.CoachesAthletesCreated || sportFilter.CoachesAthletesCreated === 'Total').map(coach => {
			const date = new Date(coach.createdAt)
			const key = `${date.getDate()} ${monthsNames[date.getMonth()]} ${date.getFullYear()}`

			const exist = coachesCount.find(item => item.date === key)
			exist ? exist.count += 1 : coachesCount.push({date: key,count: 1})
		})

		athletes.filter(item=>item.sport === sportFilter.CoachesAthletesCreated || sportFilter.CoachesAthletesCreated === 'Total').map(athlete => {
			const date = new Date(athlete.createdAt)
			const key = `${date.getDate()} ${monthsNames[date.getMonth()]} ${date.getFullYear()}`

			const exist = athletesCount.find(item => item.date === key)
			exist ? exist.count += 1 : athletesCount.push({date: key,count: 1})
		})

		// Put 0 to the missing months
		while (coachesCount.length !== athletesCount.length){
			coachesCount.length < athletesCount.length ?(
				athletesCount.map(item => {
					const exist = coachesCount.find(item2 => item2.date === item.date)
					!exist && coachesCount.push({date: item.date,count: 0})
				})
			):(
				coachesCount.map(coach => {
					const exist = athletesCount.find(item => item.date === coach.date)
					!exist && athletesCount.push({date: coach.date,count: 0})
				})
			)
		}
		// TODO
		lineChartOptionsCoachAthlete.xaxis.categories = coachesCount.sort((item1,item2)=>new Date(`${item1.date}`) - new Date(`${item2.date}`)).map(item => item.date)
		lineChartOptionsCoachAthlete.xaxis.type = 'datetime'
		lineChartOptionsCoachAthlete.chart.zoom = {
			type : 'x',
			enabled : true,
			autoScaleYaxis : false
		}

		return [{
			name: "Coaches",
			data: [0,...coachesCount.sort((item1,item2)=>new Date(`${item1.date}`) - new Date(`${item2.date}`)).map(item => item.count)]
		},
		{
			name: "Athletes",
			data: [0,...athletesCount.sort((item1,item2)=>new Date(`${item1.date}`) - new Date(`${item2.date}`)).map(item => item.count)]
		}]
	}

	const downloadCoachesCreatedPerMonth = () => {
		let coachesCount = []
		const monthsNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
		
		// Total count
		coaches.sort((a,b)=>(new Date(a.createdAt) - new Date(b.createdAt))).filter(item=>(item.sport !== 'Both')).map(coach => {
			const date = new Date(coach.createdAt)
			const key = `${monthsNames[date.getMonth()]} ${date.getFullYear()}`
			
			const exist = coachesCount.find(item => item.date === key)
			exist ? exist.count += 1 : coachesCount.push({date: key,count: 1})
		})
		const totalCount = coachesCount
		const totalMonths = totalCount.map(it=>it.date)
		coachesCount = []

		// Men's Soccer
		coaches.sort((a,b)=>(new Date(a.createdAt) - new Date(b.createdAt))).filter(item=>(item.sport === 'Soccer') && item.gender !== 'female').map(coach => {
			const date = new Date(coach.createdAt)
			const key = `${monthsNames[date.getMonth()]} ${date.getFullYear()}`

			const exist = coachesCount.find(item => item.date === key)
			exist ? exist.count += 1 : coachesCount.push({date: key,count: 1})
		})
		const menSoccer = totalMonths.map( d => ({date: d,count: coachesCount.find(cc => cc.date === d)?.count || 0}))
		coachesCount = []
		
		// Women's Soccer
		coaches.sort((a,b)=>(new Date(a.createdAt) - new Date(b.createdAt))).filter(item=>(item.sport === 'Soccer') && item.gender === 'female').map(coach => {
			const date = new Date(coach.createdAt)
			const key = `${monthsNames[date.getMonth()]} ${date.getFullYear()}`
			
			const exist = coachesCount.find(item => item.date === key)
			exist ? exist.count += 1 : coachesCount.push({date: key,count: 1})
		})
		const womenSoccer = totalMonths.map( d => ({date: d,count: coachesCount.find(cc => cc.date === d)?.count || 0}))
		coachesCount = []

		// Total Soccer
		coaches.sort((a,b)=>(new Date(a.createdAt) - new Date(b.createdAt))).filter(item=>(item.sport === 'Soccer')).map(coach => {
			const date = new Date(coach.createdAt)
			const key = `${monthsNames[date.getMonth()]} ${date.getFullYear()}`
			
			const exist = coachesCount.find(item => item.date === key)
			exist ? exist.count += 1 : coachesCount.push({date: key,count: 1})
		})
		const totalSoccer = totalMonths.map( d => ({date: d,count: coachesCount.find(cc => cc.date === d)?.count || 0}))
		coachesCount = []
		
		// /////////////////////////////

		// Men's Tennis
		coaches.sort((a,b)=>(new Date(a.createdAt) - new Date(b.createdAt))).filter(item=>(item.sport === 'Tennis') && item.gender !== 'female').map(coach => {
			const date = new Date(coach.createdAt)
			const key = `${monthsNames[date.getMonth()]} ${date.getFullYear()}`

			const exist = coachesCount.find(item => item.date === key)
			exist ? exist.count += 1 : coachesCount.push({date: key,count: 1})
		})
		const menTennis = totalMonths.map( d => ({date: d,count: coachesCount.find(cc => cc.date === d)?.count || 0}))
		coachesCount = []

		// Women's Tennis
		coaches.sort((a,b)=>(new Date(a.createdAt) - new Date(b.createdAt))).filter(item=>(item.sport === 'Tennis') && item.gender === 'female').map(coach => {
			const date = new Date(coach.createdAt)
			const key = `${monthsNames[date.getMonth()]} ${date.getFullYear()}`

			const exist = coachesCount.find(item => item.date === key)
			exist ? exist.count += 1 : coachesCount.push({date: key,count: 1})
		})
		const womenTennis = totalMonths.map( d => ({date: d,count: coachesCount.find(cc => cc.date === d)?.count || 0}))
		coachesCount = []

		// Women's Tennis
		coaches.sort((a,b)=>(new Date(a.createdAt) - new Date(b.createdAt))).filter(item=>(item.sport === 'Tennis')).map(coach => {
			const date = new Date(coach.createdAt)
			const key = `${monthsNames[date.getMonth()]} ${date.getFullYear()}`

			const exist = coachesCount.find(item => item.date === key)
			exist ? exist.count += 1 : coachesCount.push({date: key,count: 1})
		})
		const totalTennis = totalMonths.map( d => ({date: d,count: coachesCount.find(cc => cc.date === d)?.count || 0}))
		coachesCount = []

		let result = []
		totalCount.forEach((item, index) => {
			result.push({
				'Month-Year':item.date,
				'Total Mens Soccer Registrations':menSoccer[index].count,
				'Total Womens Soccer Registrations':womenSoccer[index].count,
				'Total Soccer Registrations':totalSoccer[index].count,
				'Total Mens Tennis Registrations':menTennis[index].count,
				'Total Womens Tennis Registrations':womenTennis[index].count,
				'Total Tennis Registrations':totalTennis[index].count,
				'Total Registrations':item.count,
			})
		});

		return result
	}


    return (
        <>
			{ hasRender && (
				<ScaleFade in={hasRender} >
					<Flex justifyContent='right' m="0px 10px 10px 0">
						<CSVLink
							data={downloadCoachesCreatedPerMonth()}
							filename={"CoachMonthlyRegistrationReport.csv"}
						>
							<Button
								colorScheme='green'
								variant='ghost'
								leftIcon={<Icon as={HiDownload}/>}
								size='sm'
							>
								Download the monthly registration report here
							</Button>
						</CSVLink>
					</Flex>
					<LineChartSummary 
						title={"Coaches & Athletes created monthly"} 
						chart={<LineChart data={prepareDataCoachAthletesCreatedPerMonth()} options={lineChartOptionsCoachAthlete}/>} 
						amount={[{
								title: "Coaches",
								color:"#2049DA",
								total: coaches.filter(item=>item.sport === sportFilter.CoachesAthletesCreated || sportFilter.CoachesAthletesCreated === 'Total').length,
								totalThisYear: coaches.filter(item=>(item.sport === sportFilter.CoachesAthletesCreated || sportFilter.CoachesAthletesCreated === 'Total') && new Date(item.createdAt).getFullYear() === new Date().getFullYear()).length,
								totalThisMonth: coaches.filter(item=>(item.sport === sportFilter.CoachesAthletesCreated || sportFilter.CoachesAthletesCreated === 'Total') && new Date(item.createdAt).getMonth() === new Date().getMonth() && new Date(item.createdAt).getFullYear() === new Date().getFullYear()).length,
								totalToday: coaches.filter(item=>(item.sport === sportFilter.CoachesAthletesCreated || sportFilter.CoachesAthletesCreated === 'Total') && new Date(item.createdAt).toLocaleDateString() === new Date().toLocaleDateString()).length
							},{
								title: "Athletes",
								color:"#E03047",
								total: athletes.filter(item=>item.sport === sportFilter.CoachesAthletesCreated || sportFilter.CoachesAthletesCreated === 'Total').length,
								totalThisYear: athletes.filter(item=>(item.sport === sportFilter.CoachesAthletesCreated || sportFilter.CoachesAthletesCreated === 'Total') && new Date(item.createdAt).getFullYear() === new Date().getFullYear()).length,
								totalThisMonth: athletes.filter(item=>(item.sport === sportFilter.CoachesAthletesCreated || sportFilter.CoachesAthletesCreated === 'Total') && new Date(item.createdAt).getMonth() === new Date().getMonth() && new Date(item.createdAt).getFullYear() === new Date().getFullYear()).length,
								totalToday: athletes.filter(item=>(item.sport === sportFilter.CoachesAthletesCreated || sportFilter.CoachesAthletesCreated === 'Total') && new Date(item.createdAt).toLocaleDateString() === new Date().toLocaleDateString()).length
							}
						]}
						dataToChange="CoachesAthletesCreated"
						sport={sportFilter}
						setSport={setSportFilter}

					/>
				</ScaleFade>
			)}

			{ !hasRender && (
				<ScaleFade in={!hasRender} >
					<Card p='28px 10px 16px 0px'>
						<CardHeader pl='22px' w="100%">
							<Flex direction='row' alignSelf='flex-start' w="100%" justifyContent={'space-between'} alignItems="center">
								<Flex direction='column' alignSelf='flex-start' w="50%">
									<Text fontSize='lg' color={"gray.700"} fontWeight='bold' mb='6px'>
										Coaches & Athletes created monthly
									</Text>
									<Text fontSize='md' fontWeight='medium' color='gray.400'>
										Graph showing the distribution of coaches and players created monthly. 
									</Text>
								</Flex>
								<Button w="40%" colorScheme='blue' onClick={onShow} my="10px" mr="5%">
									Load chart
								</Button>
							</Flex>
						</CardHeader>
					</Card>
				</ScaleFade>
			)}
        </>
    )
}

const myPropsChanged = (prevProps, nextProps) => {
    if ( prevProps.athletes !== nextProps.athletes || prevProps.coaches !== nextProps.coaches) {
        return false;
    }
    return true;
}

export default React.memo(CoachesAthletesCreated, myPropsChanged);