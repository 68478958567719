// React imports
import React, { useContext, useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
// Context imports
import { DataContext } from "../../../../contexts/dataContext";
// Chakra imports
import {
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Flex,
  Checkbox,
  Alert,
  AlertIcon,
  CloseButton,
  Icon,
  Button,
} from "@chakra-ui/react";
// Auxiliar functions
import { getSearchedData } from "helpers/getSearchedData";
import { changeUserGroupToAgencyId } from "../../../../helpers";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { Pagination } from "components/Pagination/Pagination";
import { SearchBar } from "components/SearchBar/SearchBar";
import PremiumCoachesTableRow from "./PremiumCoachesTableRow";
import { BsArrowDown, BsArrowUp } from "react-icons/bs";
import { CSVLink } from "react-csv";
import { HiDownload } from "react-icons/hi";

const Coaches = ({ title, captions, data }) => {
  // Chakra color mode
  const textColor = useColorModeValue("gray.700", "white");

  // Take the context data needed in this component
  const dataContext = useContext(DataContext);
  const {
    pagination,
    updatePagination,
    user,
    teams,
    coachesCognito,
    premiumCoaches,
  } = dataContext;

  // Cognito user group
  const userGroup = changeUserGroupToAgencyId(
    user?.signInUserSession.idToken.payload["cognito:groups"][0]
  );
  // State with the searched text
  const [searchState, updateSearchState] = useState({ searchText: "" });
  // State that handles the selection of items
  const [selectItem, setSelectItem] = useState([]);
  // State that controls if the user has permission to do an action
  const [noPermission, setNoPermission] = useState(false);
  // State that control if sort is ascending or descending
  const [sortAscending, setSortAscending] = useState({
    Information: true,
    "Subscription date": true,
    "Subscription period": true,
  });

  const prevSortStatus = useRef();
  useEffect(() => {
    //assign the ref's current value to the sort Hook
    prevSortStatus.current = sortAscending;
  }, [sortAscending]);

  const uniqueValuesCategories = [
    ...new Set(
      teams
        .filter((it) => it.college_category)
        .map((team) => {
          return team.college_category;
        })
    ),
  ].sort((a, b) => {
    return a.toLowerCase().localeCompare(b.toLowerCase());
  });

  const [filters, setFilters] = useState({
    teamGenderData: ["ALL", "Male", "Female", "Team Base"],
    category: "0",
    categoryData: [
      { value: "0", label: "ALL" },
      ...uniqueValuesCategories.map((it) => {
        return { value: it, label: it };
      }),
    ],
  });

  // State with action feedback
  const [actionStatus, setActionStatus] = useState({
    done: false,
    status: "",
    message: "",
  });

  useEffect(() => {
    updatePagination({
      total: getSearchedData(data, searchState.searchText).length,
      page: 1,
      rowsPerPage: 10,
      lower: 1,
    });
    setSelectItem([]);
  }, []);

  // Function that handles the selection of all items
  const handleSelectAll = (e) => {
    e.target.checked
      ? setSelectItem(data.map((item) => item.id))
      : setSelectItem([]);
  };

  const filterData = (data) => {
    let newData = data;
    if (
      filters.sport === 0 ||
      filters.teamGender === 0 ||
      filters.category === "0" ||
      filters.status === "0"
    ) {
      newData = data;
    }
    if (filters.sport > 0) {
      newData = newData.filter(
        (item) => item.sport === filters.sportData[filters.sport]
      );
    }
    if (filters.teamGender > 0 && filters.teamGender < 3) {
      newData = newData.filter(
        (item) =>
          item.gender ===
          filters.teamGenderData[filters.teamGender].toLowerCase()
      );
    }
    if (filters.teamGender === 3) {
      newData = newData.filter(
        (item) =>
          teams.find((team) => team.id === item.teamID)?.name === "Team Base"
      );
    }
    if (filters.category !== "0") {
      newData = newData.filter(
        (item) => item.team?.college_category === filters.category
      );
    }

    if (prevSortStatus?.current) {
      if (
        prevSortStatus?.current["Creation date"] !==
        sortAscending["Creation date"]
      ) {
        newData = newData.sort((a, b) => {
          if (sortAscending["Creation date"]) {
            return new Date(a.createdAt) - new Date(b.createdAt);
          } else {
            return new Date(b.createdAt) - new Date(a.createdAt);
          }
        });
      }
      if (
        prevSortStatus?.current["Information"] !== sortAscending["Information"]
      ) {
        newData = newData.sort((a, b) => {
          if (sortAscending["Information"]) {
            return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
          } else {
            return b.name.toLowerCase().localeCompare(a.name.toLowerCase());
          }
        });
      }
    }
    return newData;
  };

  const downloadData = () => {
    return premiumCoaches
      ?.filter((it) => it.teamID !== "0118da1d-2951-4fa0-8fd2-840e9e833b33")
      .map((item) => {
        return {
          Name: item.name ? item.name : "Not provided",
          "Contact email": item.contact_email
            ? item.contact_email
            : "Not provided",
          "Contact phone": item.contact_phone
            ? item.contact_phone
            : "Not provided",
          University: item.team?.name ? item.team?.name : "Not provided",
          Division: item.division ? item.division : "Not provided",
          Role: item.role ? item.role : "Not provided",
          Sport: item.sport ? item.sport : "Not provided",
          "Subscription date": item.subscription_initial_date
            ? item.subscription_initial_date.split("T")[0]
            : "Not provided",
          "Subscription period": item.suscription_recurrency,
        };
      });
  };

  return (
    <>
      <Flex justifyContent="right" m="0px 10px 10px 0">
        <CSVLink data={downloadData()} filename={"AA-CoachesData.csv"}>
          <Button
            colorScheme="green"
            variant="ghost"
            leftIcon={<Icon as={HiDownload} />}
            size="sm"
          >
            Download Coaches Data
          </Button>
        </CSVLink>
      </Flex>

      {noPermission && (
        <Alert status="error" borderRadius="10px" mb="20px">
          <AlertIcon />
          You don't have enough permissions to perform this action on coaches.
          <CloseButton
            position="absolute"
            right="8px"
            top="8px"
            onClick={() => setNoPermission(false)}
          />
        </Alert>
      )}
      {actionStatus.done && (
        <Alert status={actionStatus.status} borderRadius="10px" mb="20px">
          <AlertIcon />
          {actionStatus.message}
          <CloseButton
            position="absolute"
            right="8px"
            top="8px"
            onClick={() => setActionStatus({ ...actionStatus, done: false })}
          />
        </Alert>
      )}

      <Card overflowX={{ sm: "scroll", xl: "hidden" }}>
        <CardHeader p="6px 0px 22px 0px">
          <Flex direction="row" w="100%">
            <Flex justifyContent="left" w="40%">
              <Text fontSize="xl" color={textColor} fontWeight="bold">
                {title} (
                {searchState.searchText !== ""
                  ? `Filtered result ${
                      filterData(getSearchedData(data, searchState.searchText))
                        .length
                    }`
                  : filterData(data).length}{" "}
                - {selectItem.length} selected)
              </Text>
            </Flex>
            <Flex justifyContent="right" w="60%">
              <SearchBar text={searchState} update={updateSearchState} />
            </Flex>
          </Flex>
        </CardHeader>
        <CardBody>
          <Table variant="simple" color={textColor}>
            <Thead>
              <Tr my=".8rem" pl="0px" color="gray.400">
                <Th color="gray.400" key="selector">
                  <Checkbox
                    colorScheme="blue"
                    onChange={(e) => handleSelectAll(e)}
                  />
                </Th>

                {captions.map((caption, idx) => {
                  return caption === "Information" ||
                    caption === "Subscription date" ? (
                    <Th
                      key={idx}
                      color="gray.400"
                      cursor="pointer"
                      onClick={() =>
                        setSortAscending({
                          ...sortAscending,
                          [caption]: !sortAscending[caption],
                        })
                      }
                    >
                      {caption}{" "}
                      {sortAscending[caption] ? (
                        <Icon as={BsArrowUp} color="gray.400" />
                      ) : (
                        <Icon as={BsArrowDown} color="gray.400" />
                      )}
                    </Th>
                  ) : (
                    <Th color="gray.400" key={idx}>
                      {caption}
                    </Th>
                  );
                })}
              </Tr>
            </Thead>
            <Tbody>
              {filterData(getSearchedData(data, searchState.searchText))
                .slice(
                  pagination.lower - 1,
                  pagination.lower + pagination.rowsPerPage - 1
                )
                .map((row) => {
                  return (
                    <PremiumCoachesTableRow
                      key={`${row.id}`}
                      id={row.id}
                      name={row.name}
                      contact_email={row.contact_email}
                      role={row.role}
                      division={row.division ? row.division : "Not especified"}
                      team={teams.find((team) => team.id === row.teamID)}
                      gender={
                        teams.find((team) => team.id === row.teamID)?.genre
                      }
                      createdAt={
                        row.subscription_initial_date
                          ? row.subscription_initial_date
                          : "Not especified"
                      }
                      selectItem={selectItem}
                      setSelectItem={setSelectItem}
                      suscription_recurrency={row.suscription_recurrency}
                    />
                  );
                })}
            </Tbody>
          </Table>
        </CardBody>
        <Pagination
          totalData={
            filterData(getSearchedData(data, searchState.searchText)).length
          }
          rowsPerPageValues={[10, 20, 30, 40, 50]}
        />
      </Card>
    </>
  );
};

const myPropsChanged = (prevProps, nextProps) => {
  if (
    prevProps.data !== nextProps.data ||
    prevProps.captions !== nextProps.caption
  ) {
    return false;
  }
  return true;
};

export default React.memo(Coaches, myPropsChanged);
