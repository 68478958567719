import React, { useContext, useEffect } from "react";
// Chakra imports
import { Flex, SimpleGrid } from "@chakra-ui/react";
import { Header } from "./components/Header";
// Context imports
import { DataContext } from "../../../contexts/dataContext";
import { LittleList } from "./components/LittleList";
import InformationCard from "components/Card/InformationCard";

const Profile = () => {
  // Get info from context
  const {
    user,
    userCoachData,
    agents,
    athletes,
    agencies,
    athletesEnquirys,
    coaches,
  } = useContext(DataContext);

  const myAgent = agents.find(
    (agent) => agent.contact_email === userCoachData.contact_email
  );
  const myAgency = agencies.find((agency) => agency.id === myAgent?.agencyID);
  const myAthletes = athletes.filter(
    (athlete) => athlete.agentID === myAgent?.id
  );
  // Get the enquiries of my athletes
  const myAthletesEnquirys = athletesEnquirys
    .filter((enquiry) =>
      myAthletes?.find((athlete) => athlete.id === enquiry.athleteID)
    )
    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

  return (
    <Flex direction="column">
      <Header
        agentProfile={myAgent && myAgent}
        coachProfile={userCoachData && userCoachData}
      />
      <SimpleGrid columns={{ sm: 1, md: 2, xl: 3 }} spacing={5}>
        <InformationCard
          title={"Personal information"}
          text={
            "Profile associated with a sports agent of an agency, in charge of managing and reviewing the athletes available in his agency."
          }
          captions={["Name:", "Phone:", "Email:", "Agency:", "No PSAs:"]}
          values={[
            myAgent?.name,
            myAgent?.contact_phone ? myAgent?.contact_phone : "Not available",
            myAgent?.contact_email,
            myAgency?.name,
            myAthletes?.length,
          ]}
          cardColor={"white"}
        />
        <LittleList title={"PSAs"} arrayOfAthletes={myAthletes} />
        <LittleList
          title={"Last contacts"}
          arrayOfAthletes={myAthletesEnquirys?.map((enquiry) =>
            athletes.find((athlete) => athlete.id === enquiry.athleteID)
          )}
          arrayOfDescs={myAthletesEnquirys?.map(
            (enquiry) =>
              `${
                coaches.find((coach) => coach.id === enquiry.coachID)?.name
              } - ${
                coaches.find((coach) => coach.id === enquiry.coachID)?.team
                  ?.name
              } - ${new Date(enquiry.createdAt).toLocaleDateString()}`
          )}
        />
      </SimpleGrid>
    </Flex>
  );
};

export default Profile;
