// React imports
import React, { useContext, useEffect } from 'react'
// Context imports
import { DataContext } from '../../../../contexts/dataContext'
// Chakra imports
import { Flex } from "@chakra-ui/react";
// Components
import Agencies from "./Agencies";


function AgenciesTables() {

	// Take the context data needed in this component
	const dataContext = useContext(DataContext)
	const { agencies } = dataContext

	useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

	return (
		<Flex direction='column' pt={{ base: "120px", md: "75px" }}>
			<Agencies
				title={"Agencies"}
				captions={["Agency", "Countries", "Website"]}
				data={agencies}
			/>
		</Flex>
	);
}

export default AgenciesTables;
