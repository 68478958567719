export const listAgents = /* GraphQL */ `
  query ListAgents(
    $filter: ModelAgentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAgents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        profile_pic
        birthdate
        gender
        contact_email
        contact_phone
        agencyID
        agent_role
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const createAgent = /* GraphQL */ `
  mutation CreateAgent(
    $input: CreateAgentInput!
    $condition: ModelAgentConditionInput
  ) {
    createAgent(input: $input, condition: $condition) {
      id
      name
      profile_pic
      birthdate
      gender
      contact_email
      contact_phone
      agencyID
      agent_role
      createdAt
      updatedAt
    }
  }
`;

export const updateAgent = /* GraphQL */ `
  mutation UpdateAgent(
    $input: UpdateAgentInput!
    $condition: ModelAgentConditionInput
  ) {
    updateAgent(input: $input, condition: $condition) {
      id
      name
      profile_pic
      birthdate
      gender
      contact_email
      contact_phone
      agent_role
      agencyID
      createdAt
      updatedAt
    }
  }
`;
export const deleteAgent = /* GraphQL */ `
  mutation DeleteAgent(
    $input: DeleteAgentInput!
    $condition: ModelAgentConditionInput
  ) {
    deleteAgent(input: $input, condition: $condition) {
      id
      name
      profile_pic
      birthdate
      gender
      contact_email
      contact_phone
      agencyID
      agent_role
      createdAt
      updatedAt
    }
  }
`;