// Chakra imports
import {
  Flex,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import IconBox from "components/Icons/IconBox";
import React from "react";

const MiniStatistics = ({ title, amount,secondaryAmount,percentage, icon, side="right" }) => {
	const iconTeal = useColorModeValue("royalblue.700", "royalblue.700");
	const textColor = useColorModeValue("gray.700", "white");

	return (
		<Card minH='83px'>
			<CardBody>
				<Flex flexDirection='row' align='center' justify='center' w='100%'>
					{
						side === "left" && (
							<IconBox h={"45px"} w={"45px"} bg={iconTeal} me="20px">
								{icon}
							</IconBox>)
					}
					<Stat me='auto'>
						<StatLabel
						fontSize='sm'
						color='gray.400'
						fontWeight='bold'
						pb='.1rem'>
						{title}
						</StatLabel>
						<Flex >
							<StatNumber fontSize='lg' color={textColor}>
								{amount} {secondaryAmount && ` of ${secondaryAmount}` }
							</StatNumber>
							{
								(percentage || percentage === 0) && (
									<StatHelpText
										alignSelf='flex-end'
										justifySelf='flex-end'
										color={percentage >= 0 ? "#2049DA" : "#E03047"}
										fontWeight='bold'
										ps='5px'
										fontSize='lg'>
										{percentage >= 0 ? `+ ${percentage}% than previous month` : `${percentage}% than previous month`}
									</StatHelpText>
								)	
							}
						</Flex>
					</Stat>
					{
						side === "right" && (
							<IconBox h={"45px"} w={"45px"} bg={iconTeal}>
								{icon}
							</IconBox>)
					}
					
				</Flex>
			</CardBody>
		</Card>
	);
};

export default MiniStatistics;
