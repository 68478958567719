// React imports
import React, { useState, useEffect, useContext } from 'react'
// Context imports
import { DataContext } from "../../../../contexts/dataContext";
// React-select imports
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
// Chackra Components
import { Flex, useColorModeValue, Text, FormControl,FormLabel,FormErrorMessage, Input, InputGroup,Spacer, Button } from "@chakra-ui/react";
import { Tag, Image, Icon, Textarea, Divider,ListItem,ListIcon } from '@chakra-ui/react'
import { NumberInput,NumberInputField,NumberInputStepper,NumberIncrementStepper,NumberDecrementStepper, Tooltip, List  } from '@chakra-ui/react'
import { AddIcon } from "@chakra-ui/icons";
// Auxiliary imports
import { countries, division, position, athleteStatus, levelOfCompetitionsByCountryGender, defaultLevelOfCompetition} from "../../../../helpers"
// Components 
import { ImageDropzone } from "../../../../components/Dropzone/ImageDropzone";
import VideoDropzone from "../../../../components/Dropzone/VideoDropzone";
import { MdVideoLibrary,MdDelete } from 'react-icons/md';
import { BsQuestionCircleFill } from "react-icons/bs";
import { GoAlert } from 'react-icons/go';
import { IoHandLeft, IoHandRight }   from 'react-icons/io5';


// Assets
import active2x from "assets/img/foot/active2x.png";
import foot2x from "assets/img/foot/foot2x.png";



const AthleticInfoFields = ({formValues,setFormValues, formErrors, aditionalInfo}) => {

    const [uploadedVideo, setUploadedVideo] = useState('')

    const widget = window.cloudinary.createUploadWidget(
        {
            cloudName: "dsvz8cktr",
            uploadPreset: "hgxk9yff",
            folder:'psas_featured_videos',
            showUploadMoreButton: false,
            multiple: false,
            maxFiles: 1,
            maxVideoFileSize:734000000,
            clientAllowedFormats: ['video'],
            clientMaxSize: 734000000,
            singleUploadAutoClose:false,
        },
        (error, result) => {
            if (!error && result && result.event === "success") {
                console.log("Done! Here is the video info: ", result.info);
                console.log("Here is your url: ", result.info.secure_url);
                setFormValues({...formValues, featured_video: {metadata:"Featured video", url: result.info.secure_url}})
                setUploadedVideo(result.info.original_filename)
            }
        }
    );


    const textColor = useColorModeValue("gray.700", "white");
	const animatedComponents = makeAnimated();

    // Take the context data needed in this component
    const dataContext = useContext(DataContext)
    const { colleges } = dataContext

    const onChange = (e) => {
        e.persist()
        setFormValues(() => ({ ...formValues, [e.target.name]: e.target.value }))
    }


    const onChangeOther = (e,index) => {
		e.persist()
        let videos = formValues.other_videos
        videos[index][e.target.name] = e.target.value
		setFormValues({...formValues, other_videos: videos})
	}

    const handleAddNewVideo = () => {
        let videos = formValues.other_videos || []
        videos.push({url: '', metadata: ''})
        setFormValues(() => ({...formValues, other_videos: videos}))
    }

    const handleClickHandFoot = (side) => {
        const oposites = {
            'left':'right',
            'right':'left'
        }
        if(formValues.sport === 'Soccer'){
            formValues.dominant_foot === side ? (setFormValues({...formValues,dominant_foot: null}))
            : (
                formValues.dominant_foot === 'both' ? (setFormValues({...formValues, dominant_foot:oposites[side]})) 
                : (
                    formValues.dominant_foot === oposites[side] ? (setFormValues({...formValues, dominant_foot: 'both'}))
                    : (setFormValues({...formValues, dominant_foot: side}))
                )
            ) 
        }
        else{
            formValues.tennis_profile?.DominantHand === side ? (
                    setFormValues({...formValues, tennis_profile:{...(formValues.tennis_profile && {...formValues.tennis_profile,DominantHand:null})}})
                )
                : (
                    formValues.tennis_profile?.DominantHand === 'both' ? (
                        setFormValues({...formValues, tennis_profile:{...(formValues.tennis_profile && {...formValues.tennis_profile,DominantHand:oposites[side]})}})
                    ) 
                    : (
                        formValues.tennis_profile?.DominantHand === oposites[side] ? (
                        setFormValues({...formValues, tennis_profile:{...(formValues.tennis_profile && {...formValues.tennis_profile,DominantHand:'both'})}})
                    )
                    : (setFormValues({...formValues, tennis_profile:{...(formValues.tennis_profile ? {...formValues.tennis_profile,DominantHand:side} : { DominantHand:side } )}}))
                )
            )
        }

    }

    return (
        <>
            {/* 1 row */}
            <Flex direction={{ sm: "column", lg: "row" }} w="100%">
                <Flex direction="column" w={{ sm: "100%", lg: "30%" }} mt="20px">
                    <Flex direction="row" alignItems="center">
                        <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mb="5px" fontWeight='semibold' textAlign="justify" mr="10px">
                            Sport
                        </Text>
                        <Tooltip label={`This field is required.`} bg='orange.300'  placement='auto' >
                            <span><Icon as={GoAlert} color="orange"/></span>
                        </Tooltip>
                    </Flex>
                    <Select
                        closeMenuOnSelect={true}
                        components={animatedComponents}
                        placeholder="Select sport"
                        defaultValue={formValues.sport && { label: formValues.sport, value: formValues.sport }}
                        options={[{value: 'Soccer', label: 'Soccer'}, {value: 'Tennis', label: 'Tennis'}]}
                        onChange={(selectedOptions) =>  setFormValues(()=>({...formValues, sport: selectedOptions.value}))}
                    />
                </Flex>
                <Spacer />
                <Flex direction="column" w={{ sm: "100%", lg: "30%" }} mt="20px">
                    <Flex direction="row" alignItems="center">
                        <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mb="5px" fontWeight='semibold' textAlign="justify" mr="10px">
                            Competition country
                        </Text>
                        <Tooltip label={`This field is required.`} bg='orange.300'  placement='auto' >
                            <span><Icon as={GoAlert} color="orange"/></span>
                        </Tooltip>
                    </Flex>
                    <Select
                        closeMenuOnSelect={true}
                        components={animatedComponents}
                        placeholder="Select competition country"
                        isSearchable={true}
                        defaultValue={formValues.country_of_competition && {value:formValues.country_of_competition,label:formValues.country_of_competition}}
                        options={countries}
                        onChange={(selectedOptions) =>  setFormValues(()=>({...formValues, country_of_competition: selectedOptions.value}))}
                    />
                </Flex>
                <Spacer />
                <Flex alignItems='left' direction="column" w={{ sm: "100%", lg: "30%" }} mt="20px" >
                    <Flex direction="row" alignItems="center">
                        <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} fontWeight='semibold' textAlign="justify" mr="10px">
                            Current club
                        </Text>
                        {/* This field is not required (25-10-2022) */}
                        {/* <Tooltip label={`This field is required.`} bg='orange.300'  placement='auto' >
                            <span><Icon as={GoAlert} color="orange"/></span>
                        </Tooltip> */}
                    </Flex>
                    <Input onChange={onChange} variant='flushed' placeholder='Current club' size='sm' value={formValues.current_club || ''} name="current_club"/>
                </Flex>
            </Flex>
            {/* 2 row */}
            <Flex direction={{ sm: "column", lg: "row" }} w="100%">
                {
                    formValues.sport && (
                        <>
                        <Flex direction="column" w={{ sm: "100%", lg: "30%" }} mt="20px">
                            <Flex direction="row" alignItems="center">
                                <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mb="5px" fontWeight='semibold' textAlign="justify" mr="10px">
                                    Dominant {formValues.sport==="Soccer" ? "foot" : "hand"}
                                </Text>
                                <Tooltip label={`This field is required.`} bg='orange.300'  placement='auto' >
                                    <span><Icon as={GoAlert} color="orange" size="lg"/></span>
                                </Tooltip>
                            </Flex>
                            <Flex direction="row" mb={{ sm: "8px", md: "15px" }} alignItems="center">
                                Left
                                {
                                    formValues.sport === "Soccer" && (
                                        <>
                                            <Tag variant="solid" colorScheme={formValues.dominant_foot === 'left' || formValues.dominant_foot === 'both' ? "blue" : "blackAlpha"} p="10px" mx="5px" onClick={()=>handleClickHandFoot('left')} cursor="pointer">
                                                <Image src={(formValues.dominant_foot === 'left' || formValues.dominant_foot === 'both' )? active2x : foot2x} w="35px"/>
                                            </Tag>
                                            <Tag variant="solid" colorScheme={formValues.dominant_foot === 'right' || formValues.dominant_foot === 'both' ? "blue" : "blackAlpha"} p="10px" mx="5px" onClick={()=>handleClickHandFoot('right')} cursor="pointer">
                                                <Image src={(formValues.dominant_foot === 'right' || formValues.dominant_foot === 'both') ? active2x : foot2x} w="35px" style={{transform:"scaleX(-1)"}}/>
                                            </Tag>
                                        </>
                                    )
                                }
                                {
                                    
                                    formValues.sport === "Tennis" && (
                                        <>
                                            <Tag variant="solid" colorScheme={formValues.tennis_profile?.DominantHand === 'left' || formValues.tennis_profile?.DominantHand === 'both' ? "green" : "blackAlpha"} p="10px" mx="5px" onClick={()=>handleClickHandFoot('left')} cursor="pointer">
                                                <Icon as={IoHandLeft} boxSize={5} />
                                            </Tag>
                                            <Tag variant="solid" colorScheme={formValues.tennis_profile?.DominantHand === 'right' || formValues.tennis_profile?.DominantHand === 'both' ? "green" : "blackAlpha"} p="10px" mx="5px" onClick={()=>handleClickHandFoot('right')} cursor="pointer">
                                                <Icon as={IoHandRight} boxSize={5} />
                                            </Tag>
                                        </>
                                    )
                                }
                                Right
                            </Flex>
                        </Flex>
                        {
                            formValues.sport === 'Soccer' && (
                                <>
                                    <Spacer />
                                    <Flex direction="column" w={{ sm: "100%", lg: "30%" }} mt="20px">
                                        <Flex direction="row" alignItems="center">
                                            <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mb="5px" mr="10px" fontWeight='semibold' textAlign="justify">
                                                Position
                                            </Text>
                                            <Tooltip label={`This field is required.`} bg='orange.300'  placement='auto' >
                                                <span><Icon as={GoAlert} color="orange"/></span>
                                            </Tooltip>
                                        </Flex>
                                        <Select
                                            closeMenuOnSelect={true}
                                            components={animatedComponents}
                                            placeholder="Select position"
                                            isSearchable={true}
                                            defaultValue={ formValues.position && position.find(p => p.value === formValues.position) }
                                            options={position}
                                            onChange={(selectedOptions) =>  setFormValues(()=>({...formValues, position: selectedOptions.value}))}
                                        />
                                    </Flex>
                                    <Spacer />
                                    <Flex direction="column" w={{ sm: "100%", lg: "30%" }} mt="20px">
                                        <Flex direction="row" alignItems="center">
                                            <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mb="5px" mr="10px" fontWeight='semibold' textAlign="justify">
                                                Second Position
                                            </Text>
                                            <Tooltip label={`This field is required.`} bg='orange.300'  placement='auto' >
                                                <span><Icon as={GoAlert} color="orange"/></span>
                                            </Tooltip>
                                        </Flex>
                                        <Select
                                            closeMenuOnSelect={true}
                                            components={animatedComponents}
                                            placeholder="Select second position"
                                            isSearchable={true}
                                            defaultValue={ formValues.second_position && position.find(p => p.value === formValues.second_position) }
                                            options={position}
                                            onChange={(selectedOptions) =>  setFormValues(()=>({...formValues, second_position: selectedOptions.value}))}
                                        />
                                    </Flex>
                                </>
                            )
                        }
                        
                        
                        </>
                    )
                }
            </Flex>


            {
                formValues.sport === "Tennis" && (

                    <Flex direction={{ sm: "column", lg: "row" }} w="100%">
                        <Flex direction="column" w={{ sm: "100%", lg: "30%" }} mt="20px">
                            <Flex direction="row" alignItems="center">
                                <Text fontSize={{ sm: "sm", lg: "sm" }} color="green" mb="5px" mr="10px" fontWeight='semibold' textAlign="justify">
                                Tennis profile - UTR 
                                </Text>
                                <Tooltip label={`This field is required.`} bg='orange.300'  placement='auto' >
                                    <span><Icon as={GoAlert} color="orange"/></span>
                                </Tooltip>
                            </Flex>
                            <NumberInput defaultValue={formValues.tennis_profile?.utr && formValues.tennis_profile?.utr} precision={2} step={0.2} min={0} max={250} onChange={(value)=>setFormValues({...formValues,tennis_profile: {...(formValues.tennis_profile ? {...formValues.tennis_profile,utr:Number(value)} : {utr:Number(value)})}})} name="utr">
                                <NumberInputField />
                                <NumberInputStepper>
                                    <NumberIncrementStepper />
                                    <NumberDecrementStepper />
                                </NumberInputStepper>
                            </NumberInput>
                        </Flex>
                        <Spacer />
                        <Flex direction="column" w={{ sm: "100%", lg: "30%" }} mt="20px">
                            <Flex direction="row" alignItems="center">
                                <Text fontSize={{ sm: "sm", lg: "sm" }} color="green" mb="5px" fontWeight='semibold' textAlign="justify" mr="10px">
                                    Tennis profile - National ranking 
                                </Text>
                                <Tooltip label={`This field is required.`} bg='orange.300'  placement='auto' >
                                    <span><Icon as={GoAlert} color="orange"/></span>
                                </Tooltip>
                            </Flex>
                            <NumberInput defaultValue={(formValues.tennis_profile?.national_ranking && formValues.tennis_profile?.national_ranking > 0) && formValues.tennis_profile?.national_ranking} step={1} min={0} max={10000} onChange={(value)=>setFormValues({...formValues,tennis_profile: {...(formValues.tennis_profile ? {...formValues.tennis_profile,national_ranking:Number(value)} : {national_ranking:Number(value)})}})} name="national_ranking">
                                <NumberInputField />
                                <NumberInputStepper>
                                    <NumberIncrementStepper />
                                    <NumberDecrementStepper />
                                </NumberInputStepper>
                            </NumberInput>
                        </Flex>
                        <Spacer />
                        <Flex direction="column" w={{ sm: "100%", lg: "30%" }} mt="20px">
                            <Flex direction="row" alignItems="center">
                                <Text fontSize={{ sm: "sm", lg: "sm" }} color="green" mb="5px" fontWeight='semibold' textAlign="justify" mr="10px">
                                    Tennis profile - International ranking
                                </Text>
                                <Tooltip label={`This field is required.`} bg='orange.300'  placement='auto' >
                                    <span><Icon as={GoAlert} color="orange"/></span>
                                </Tooltip>
                            </Flex>
                            <NumberInput defaultValue={(formValues.tennis_profile?.international_ranking && formValues.tennis_profile?.international_ranking > 0) && formValues.tennis_profile?.international_ranking} step={1} min={0} max={10000} onChange={(value)=>setFormValues({...formValues,tennis_profile: {...(formValues.tennis_profile ? {...formValues.tennis_profile,international_ranking:Number(value)} : {international_ranking:Number(value)})}})} name="international_ranking">
                                <NumberInputField />
                                <NumberInputStepper>
                                    <NumberIncrementStepper />
                                    <NumberDecrementStepper />
                                </NumberInputStepper>
                            </NumberInput>
                        </Flex>
                    </Flex>

                )
            }

            {/* 3 column */}
            <Flex direction={{ sm: "column", lg: "row" }} w="100%">
                <Flex direction="column" w={{ sm: "100%", lg: "45%" }} mt="20px">
                    <Flex direction="row" alignItems="center">
                        <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mb="5px" mr="10px" fontWeight='semibold' textAlign="justify">
                        Height ( cm )
                        </Text>
                        <Tooltip label={`This field is required.`} bg='orange.300'  placement='auto' >
                            <span><Icon as={GoAlert} color="orange"/></span>
                        </Tooltip>
                    </Flex>
                    <NumberInput defaultValue={formValues.height && formValues.height} step={1} min={100} max={250} onChange={(value)=>setFormValues({...formValues,height:parseInt(value,10)})} name="height">
                        <NumberInputField />
                        <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                        </NumberInputStepper>
                    </NumberInput>
                </Flex>
                <Spacer />
                <Flex direction="column" w={{ sm: "100%", lg: "45%" }} mt="20px">
                    <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mb="5px" fontWeight='semibold' textAlign="justify">
                        Weight ( kg )
                    </Text>
                    <NumberInput defaultValue={formValues.weight && formValues.weight} step={1} min={0} max={200} onChange={(value)=>setFormValues({...formValues,weight:parseInt(value,10)})} name="weight">
                        <NumberInputField />
                        <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                        </NumberInputStepper>
                    </NumberInput>
                </Flex>
            </Flex>
            <Divider mt="25px" />

            {/* 4 column */}
            <Flex direction={{ sm: "column", lg: "row" }} w="100%">
                <Flex direction="column" w={{ sm: "100%", lg: "45%" }} mt="20px">
                    <Flex direction="row" alignItems="center">
                        <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mb="5px" fontWeight='semibold' textAlign="justify" mr="10px">
                            Target division
                        </Text>
                        <Tooltip label={`This field is required.`} bg='orange.300'  placement='auto' >
                            <span><Icon as={GoAlert} color="orange" size="lg"/></span>
                        </Tooltip>
                    </Flex>
                    <Select
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        placeholder="Select division"
                        isSearchable={true}
                        isMulti
                        defaultValue={formValues.target_division && formValues.target_division.map(c => ({value: c,label: c}))}
                        options={division}
                        onChange={(selectedOptions) =>  setFormValues(()=>({...formValues, target_division:  selectedOptions.map(c => c.value)}))}
                    />
                </Flex>
                <Spacer />
                <Flex direction="column" w={{ sm: "100%", lg: "45%" }} mt="20px">
                    <Flex direction="row" alignItems="center">
                        <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mb="5px" fontWeight='semibold' textAlign="justify" mr="10px">
                            Compliance
                        </Text>
                        <Tooltip label={`This field is required.`} bg='orange.300'  placement='auto' >
                            <span><Icon as={GoAlert} color="orange" size="lg"/></span>
                        </Tooltip>
                    </Flex>
                    <Select
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        placeholder="Select compliance"
                        isSearchable={true}
                        isMulti
                        defaultValue={formValues.compliance && formValues.compliance.map(c => ({value: c,label: c}))}
                        options={division}
                        onChange={(selectedOptions) =>  setFormValues(()=>({...formValues, compliance:  selectedOptions.map(c => c.value)}))}
                    />
                </Flex>
            </Flex>
            {/* 5 column */}
            <Flex direction={{ sm: "column", lg: "row" }} w="100%">
                <Flex direction="column" w={{ sm: "100%", lg: "45%" }} mt="20px">
                    <Flex direction="row" alignItems="center">
                        <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mb="5px" fontWeight='semibold' textAlign="justify" mr="10px">
                            Athlete status
                        </Text>
                        <Tooltip label={`This field is required.`} bg='orange.300'  placement='auto' >
                            <span><Icon as={GoAlert} color="orange" size="lg"/></span>
                        </Tooltip>
                        {
                            formValues.current_status === 'COMMITTED' && (
                                <Tooltip label={`Please notice once the status is committed and you include the reason and the school the PSA is committed to other coaches may know this student is committed and to whom. We recommend you or the PSA communicate the decision to the coaches interested first.`} bg='red.500' placement='auto' >
                                    <span><Icon ml='10px' as={GoAlert} color="red" size="lg"/></span>
                                </Tooltip>
                            )
                        }
                    </Flex>
                    <Select
                        closeMenuOnSelect={true}
                        components={animatedComponents}
                        placeholder="Select status"
                        isSearchable={true}
                        defaultValue={formValues.current_status && athleteStatus.find(s => s.value === formValues.current_status)}
                        options={athleteStatus}
                        onChange={(selectedOptions) =>  setFormValues(()=>({...formValues, current_status: selectedOptions.value}))}
                    />
                </Flex>
                <Spacer />
                <Flex direction="column" w={{ sm: "100%", lg: "45%" }} mt="20px">
                    <Flex direction="row" alignItems="center">
                        <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mb="5px" fontWeight='semibold' textAlign="justify" mr="10px">
                            Level of competition
                        </Text>
                        {/* This field is not required (25-10-2022) */}
                        {/* <Tooltip label={`This field is required.`} bg='orange.300'  placement='auto' >
                            <span><Icon as={GoAlert} color="orange" size="lg"/></span>
                        </Tooltip> */}
                    </Flex>
                    {
                        formValues.sport === 'Soccer' ? (
                            <Select
                                closeMenuOnSelect={true}
                                components={animatedComponents}
                                placeholder="Select Tier / League"
                                isSearchable={true}
                                isDisabled={!formValues.country_of_competition || !aditionalInfo.gender}
                                defaultValue={formValues.level_of_competition && {value:formValues.level_of_competition, label:formValues.level_of_competition}}
                                options={
                                    levelOfCompetitionsByCountryGender.filter(opt => (opt.country === formValues.country_of_competition && opt.gender === aditionalInfo.gender) && {value:opt.value,label:opt.label}).length > 0
                                    ? levelOfCompetitionsByCountryGender.filter(opt => (opt.country === formValues.country_of_competition && opt.gender === aditionalInfo.gender) && {value:opt.value,label:opt.label})
                                    : defaultLevelOfCompetition
                                }
                                onChange={(selectedOptions) =>  setFormValues(()=>({...formValues, level_of_competition: selectedOptions.value}))}
                                noOptionsMessage={()=>("No results. Contact admin to check your league.")}
                            />
                        ) : (
                            <Input onChange={onChange} variant='flushed' placeholder='Current level of competition' size='sm' value={formValues.level_of_competition || ''} name="level_of_competition"/> 
                        )
                    }
                </Flex>
            
            </Flex>
            
            {/* 6 column */}
            <Flex direction={{ sm: "column", lg: "row" }} w="100%">
                <Flex direction="column" w={{ sm: "100%", lg: "45%" }} mt="20px">
                    <Flex direction="row" alignItems="center">
                        <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mb="5px" fontWeight='semibold' textAlign="justify" mr="10px">
                            Destination college
                        </Text>
                        <Tooltip label={`This field is only available when the PSA is committed.`} bg='royalblue.700'  placement='auto' >
                            <span><Icon as={BsQuestionCircleFill} color="royalblue.700" /></span>
                        </Tooltip>
                    </Flex>
                    <Select
                        closeMenuOnSelect={true}
                        components={animatedComponents}
                        isDisabled={formValues.current_status !== 'COMMITTED'}
                        placeholder="Select destination college"
                        isSearchable={true}
                        defaultValue = {formValues.current_status === 'COMMITTED' ? [{value:colleges.find(c => c.name === formValues.destination_college)?.name,label:colleges.find(c => c.name === formValues.destination_college)?.name}] : ''}
                        options={(colleges.map(college => ({ value: college.name, label: college.name })))}
                        onChange={(selectedOptions) => setFormValues(()=>({...formValues, destination_college: selectedOptions.value}))}
                    />
                </Flex>
                <Spacer />
                <Flex direction="column" w={{ sm: "100%", lg: "45%" }} mt="20px">
                    <Flex direction="row" alignItems="center">
                        <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mb="5px" fontWeight='semibold' textAlign="justify" mr="10px">
                            Reason for commitment
                        </Text>
                        <Tooltip label={`How the client committed.`} bg='royalblue.700'  placement='auto' >
                            <span><Icon as={BsQuestionCircleFill} color="royalblue.700" /></span>
                        </Tooltip>
                    </Flex>
                    <Select
                        closeMenuOnSelect={true}
                        components={animatedComponents}
                        isDisabled={formValues.current_status !== 'COMMITTED'}
                        placeholder="Select a reason for commitment"
                        isSearchable={true}
                        defaultValue = {formValues.current_status === 'COMMITTED' ? [{value:formValues.reason_commitment,label:formValues.reason_commitment}] : ''}
                        options={[
                            {value:'Coach found directly on the App',label:'Coach found directly on the App'},
                            {value:'Agent presented to coach',label:'Agent presented to coach'},
                            {value:'Referred by a colleague',label:'Referred by a colleague'},     
                            {value:'Seen at showcase',label:'Seen at showcase'},     
                        ]}
                        onChange={(selectedOptions) => setFormValues(()=>({...formValues, reason_commitment: selectedOptions.value}))}
                    />
                </Flex>
                
            </Flex>
            <Flex direction={{ sm: "column", lg: "row" }} w="100%">
                <Flex direction="column" w={{ sm: "100%", lg: "45%" }} mt="20px">
                    <Flex direction="row" alignItems="center">
                        <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mb="5px" fontWeight='semibold' textAlign="justify" mr="10px">
                            Date of commitment
                        </Text>
                        <Tooltip label={`This field is only available when the PSA is committed.`} bg='royalblue.700'  placement='auto' >
                            <span><Icon as={BsQuestionCircleFill} color="royalblue.700" /></span>
                        </Tooltip>
                    </Flex>
                    <Input onChange={onChange} isDisabled={formValues.current_status !== 'COMMITTED'} variant='flushed' placeholder='YYYY-MM-DD' size='sm' value={formValues.date_commitment || ''} name="date_commitment"/>
                </Flex>
                <Spacer />                
            </Flex>

            {/* 7 column */}
            <Flex direction={{ sm: "column", lg: "row" }} w="100%">
                <Flex direction="column" w={{ sm: "100%", lg: "45%" }} mt="20px">
                    <Flex direction="row" alignItems="center">
                        <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mb="5px" fontWeight='semibold' textAlign="justify" mr="10px">
                            NCAA ID
                        </Text>
                    </Flex>
                    <Input onChange={onChange} variant='flushed' placeholder='NCAA ID' size='sm' value={formValues.ncaa_id || ''} name="ncaa_id"/>
                </Flex>
                <Spacer />
                <Flex direction="column" w={{ sm: "100%", lg: "45%" }} mt="20px">
                    <Flex direction="row" alignItems="center">
                        <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mb="5px" fontWeight='semibold' textAlign="justify" mr="10px">
                            Sport Background
                        </Text>
                        {/* This field is not required (25-10-2022) */}
                        {/* <Tooltip label={`This field is required.`} bg='orange.300'  placement='auto' >
                            <span><Icon as={GoAlert} color="orange"/></span>
                        </Tooltip> */}
                    </Flex>
                    <Textarea placeholder='Write some sport background' w="100%" h="100px" resize="none" onChange={onChange} value={formValues.sports_background || ''} name="sports_background"/>
                </Flex> 
            
            </Flex>
            {/* 8 column */}
            <Flex direction={{ sm: "column", lg: "row" }} w="100%">
                <Flex direction="column" w={{ sm: "100%", lg: "45%" }} mt="20px">
                    <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mb="5px" fontWeight='semibold' textAlign="justify">
                        Eligibility Notes 
                    </Text>
                    <Textarea placeholder='Write some eligibility notes' w="100%" h="150px" resize="none" onChange={onChange} value={formValues.elegibility_notes || ''} name="elegibility_notes"/>
                </Flex>
                <Spacer />    
                <Flex direction="column" w={{ sm: "100%", lg: "45%" }} mt="20px">
                    <Flex direction="row" alignItems="center">
                        <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mb="5px" fontWeight='semibold' textAlign="justify" mr="10px">
                            Agency Assessment
                        </Text>
                        {/* This field is not required (25-10-2022) */}
                        {/* <Tooltip label={`This field is required.`} bg='orange.300'  placement='auto' >
                            <span><Icon as={GoAlert} color="orange"/></span>
                        </Tooltip> */}
                    </Flex>
                    <Textarea placeholder='Write some agency assessment' w="100%" h="150px" resize="none" onChange={onChange} value={formValues.agency_assesment || ''} name="agency_assesment"/>
                </Flex>  
                     
            </Flex>
            <Divider mt="25px" />
            {/* 9 column */}
            <Flex direction="column" w="100%" mt="30px">
                <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mb="5px" fontWeight='semibold' textAlign="justify">
                    Card picture
                </Text>
                
                {/* Component with dropzone for images */}
                <ImageDropzone values={formValues} updateValues={setFormValues} propChanged="card_pic"/>
            </Flex>
            {/* 10 column */}
            <Flex direction="column" w="100%" mt="30px">
                <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mb="5px" fontWeight='semibold' textAlign="justify">
                    Featured video
                </Text>
                <Flex 
                    borderRadius="sm" 
                    border="3px dashed #bdc3c7"
                    boxShadow="sm" 
                    bg="#f2f3f4" 
                    justifyContent={"center"} 
                    alignItems="center" 
                    h="70px" 
                    cursor="pointer"
                    onClick={() => widget.open()}
                    m="10px"
                    direction="column"
                >
                    <Text fontSize='sm' fontWeight='semibold'><i>Click here</i> to browse from your computer</Text>
                    <Text fontSize='xs'>Max file size: 700MB</Text>
                </Flex>
                {
                    formValues.featured_video?.url !== '' && uploadedVideo !== ''
                    && (
                        <>
                            <Text fontSize={{ sm: "sm", lg: "sm" }} mb="5px" fontWeight='semibold' textAlign="justify">
                                Uploaded file
                            </Text>
                            <List spacing={4}>
                                <ListItem key={uploadedVideo}>
                                    <ListIcon as={MdVideoLibrary} color='blue.500' />
                                    {uploadedVideo}
                                </ListItem>
                            </List>
                        </>
                    )
                }
                {
                    formValues.featured_video && uploadedVideo === ''
                    && (
                        <>
                            <Text fontSize={{ sm: "sm", lg: "sm" }} mb="5px" fontWeight='semibold' textAlign="justify">
                                Actual featured video
                            </Text>
                            <List spacing={4}>
                                <ListItem key={formValues.featured_video?.url}>
                                    <ListIcon as={MdVideoLibrary} color='gray.300' />
                                    {formValues.featured_video?.url}
                                    <Button
                                        colorScheme='red'
                                        variant='ghost'
                                        leftIcon={<Icon as={MdDelete} />}
                                        size='lg'
                                        onClick={() => {setFormValues({...formValues,featured_video: null})}}
                                    />
                                </ListItem>
                            </List>
                        </>
                    )
                }
        
                {/* Component with dropzone for images */}
                {/* <VideoDropzone values={formValues} updateValues={setFormValues}/> */}
                {
                    formValues.other_videos?.length > 0 &&
                    (
                        formValues.other_videos.map((video, index) => {
                            return (
                                <Flex direction={{ sm: "column", lg: "row" }} w="100%" alignItems="center" key={index}>
                    
                                    <Flex direction="column" w={{ sm: "100%", lg: "25%" }} mt="20px">
                                        <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mb="5px" fontWeight='semibold' textAlign="justify">
                                            Title
                                        </Text>
                                        <InputGroup>
                                            <Input onChange={(e)=>onChangeOther(e,index)} variant='flushed' placeholder='Titlte' size='sm' value={video.metadata || ''} name="metadata" />
                                        </InputGroup>
                                    </Flex>
                                    <Spacer />
                                    <Flex direction="column" w={{ sm: "100%", lg: "65%" }} mt="20px">
                                        <Text fontSize={{ sm: "sm", lg: "sm" }} color={textColor} mb="5px" fontWeight='semibold' textAlign="justify">
                                            Other platform video url
                                        </Text>
                                        <FormControl isInvalid={formErrors.other_videos !== ''}> 
                                            <InputGroup>
                                                <Flex w="100%" direction="row" alignItems="center"> 
                                                    <Icon as={MdVideoLibrary} color='gray.300' mr="10px" />
                                                    <Input onChange={(e)=>onChangeOther(e,index)} variant='flushed' placeholder='Other platform video url' size='sm' value={video.url || ''} name="url" />
                                                </Flex>
                                            </InputGroup>
                                            {formErrors.other_videos !== '' ? <FormErrorMessage>{formErrors.other_videos}</FormErrorMessage> : null}
                                        </FormControl>
                                            
                                    </Flex>
                                    <Flex direction="column" w={{ sm: "100%", lg: "5%" }} mt="20px">
                                        <Button
                                            colorScheme='red'
                                            variant='ghost'
                                            leftIcon={<Icon as={MdDelete} />}
                                            size='lg'
                                            onClick={() => {setFormValues({...formValues, other_videos: formValues.other_videos.filter((video, idx) => idx !== index)})}}
                                        />
                                    </Flex>
                                    
                                </Flex>
                            )
                        })
                    )

                }

                <Button
                    colorScheme='blue'
                    variant='ghost'
                    leftIcon={<AddIcon />}
                    size='sm'
                    onClick={handleAddNewVideo}
                    mt="10px"
                >
                    Add other video
                </Button>
            </Flex>
        </>
    )
}


const myPropsChanged = (prevProps, nextProps) => {
    if ( prevProps.formValues !== nextProps.formValues || prevProps.aditionalInfo.gender !== nextProps.aditionalInfo.gender ||prevProps.formErrors.other_videos !== nextProps.formErrors.other_videos ) {
        return false;
    }
    return true;
}

export default React.memo(AthleticInfoFields, myPropsChanged);