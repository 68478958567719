// React imports
import React from "react";
import { useNavigate } from 'react-router-dom'
// Chakra imports
import {
	Image,
	Flex,
	Td,
	Text,
	Tr,
	useColorModeValue,
	Link,
	Checkbox,
} from "@chakra-ui/react";
import { countryListAlpha2 } from "helpers";


function AgenciesTableRow({ id, logo, name, email,phone, country, website, selectItem, setSelectItem }) {
	// Chakra color mode
	const textColor = useColorModeValue("gray.700", "white");
	const titleColor = useColorModeValue('royalblue.700');
	// navigate hook
	let navigate = useNavigate()

	// Function that handles the selection of an item
	const handleSelectItem = (e) => {
		setSelectItem(e.target.checked ? [...selectItem, id] : selectItem.filter(item => item !== id))
	}

	return (
		<Tr>
			{/* First column */}
			<Td>
				<Checkbox colorScheme='blue' onChange={(e)=>(handleSelectItem(e))} isChecked={selectItem.includes(id)} />
			</Td>

			{/* Second column */}
			<Td minWidth={{ sm: "250px" }} pl="0px" onClick={()=>(navigate(`/admin/agencies/show/${id}`))} style={{cursor:'pointer'}}>
				<Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
					<Image src={logo} w="70px" borderRadius="12px" me="18px" />
					<Flex direction="column">
						<Text
							fontSize="md"
							color={textColor}
							fontWeight="bold"
							minWidth="100%"
						>
							{name}
						</Text>
						<Text fontSize="sm" color="gray.400" fontWeight="normal">
							{email}
						</Text>
						<Text fontSize="sm" color="gray.400" fontWeight="normal">
							{phone}
						</Text>
					</Flex>
				</Flex>
			</Td>
			{/* Third column */}
			<Td>
				{
					country?.map((c, idx) => {
						return (
							<Flex justifyContent='space-between' mb='21px' key={idx}>
								<Flex align='center'>
									<Image src={`https://www.flagcdn.com/w40/${countryListAlpha2[c]?.toLowerCase()}.png`} w='20px' me='10px' color="inherit" />
									<Flex direction='column'>
										<Text fontSize='sm' color={textColor} fontWeight='bold'>
										{c}
										</Text>
									</Flex>
								</Flex>
							</Flex>
						)
					})
				}
			</Td>
			{/* Fourth column */}
			<Td>
				<Link color={titleColor} as='span' ms='5px'>
					<a href={website} target="blank">{website}</a>
				</Link>
			</Td>
		</Tr>
	);
}

export default AgenciesTableRow;
