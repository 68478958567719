import React from "react";
import {
	Avatar,
	AvatarGroup,
	Flex,
	Icon,
	Progress,
	Td,
	Text,
	Tr,
	useColorModeValue,
	Image
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";


function DashboardRankingCoachTableRow({ coach, messages,favorites, searches }) {
	const textColor = useColorModeValue("gray.700", "white");
	let navigate = useNavigate();

	return (
		<Tr>
			<Td maxWidth={{ sm: "200px" }} pl="0px">
				<Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
					<Flex direction="column">
						<Text
							fontSize="md"
							color={textColor}
							fontWeight="bold"
							minWidth="100%"
							cursor="pointer"
							onClick={()=>navigate(`/admin/coaches/activity/${coach.id}`)}
						>
							{coach?.name}
						</Text>
						<Text fontSize="sm" color="gray.400" fontWeight="normal">
							{coach?.contact_email}
						</Text>
						<Text fontSize="sm" color="gray.400" fontWeight="normal">
							{coach?.team?.name}
						</Text>
						<Text fontSize="sm" color="gray.400" fontWeight="normal">
							{coach?.team?.genre}'s Team
						</Text>
					</Flex>
				</Flex>
			</Td>

			<Td>
				<Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
					<Flex direction="column">
						<Text
							fontSize="md"
							color={textColor}
							fontWeight="bold"
							minWidth="100%"
						>
							{messages}
						</Text>
						<Text fontSize="sm" color="gray.400" fontWeight="normal">
							Messages
						</Text>
					</Flex>
				</Flex>
			</Td>
			<Td>
				<Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
					<Flex direction="column">
						<Text
							fontSize="md"
							color={textColor}
							fontWeight="bold"
							minWidth="100%"
						>
							{favorites}
						</Text>
						<Text fontSize="sm" color="gray.400" fontWeight="normal">
							Favorites
						</Text>
					</Flex>
				</Flex>
			</Td>
			<Td>
				<Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
					<Flex direction="column">
						<Text
							fontSize="md"
							color={textColor}
							fontWeight="bold"
							minWidth="100%"
						>
							{searches}
						</Text>
						<Text fontSize="sm" color="gray.400" fontWeight="normal">
							Searches
						</Text>
					</Flex>
				</Flex>
			</Td>
		</Tr>
	);
}

export default DashboardRankingCoachTableRow;
